import React from 'react';
import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';
import LOGO_IMAGE from './Compreglogo2.png';

const useStyles = makeStyles((theme) => ({
  h3: {
    ...typography.h3,
    color: colors.ternary.color,
    textAlign: 'left',
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '19px',
    textTransform: 'uppercase',
  },
  h6: {
    color: 'red',
  },
  logoSec: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    borderBottom: '1px solid #333',
    marginLeft: '10px',
  },

  img: {
    maxWidth: '200px',
  },
  main: {},
}));

function Logo() {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div className={classes.logoSec}>
        <img src={LOGO_IMAGE} className={classes.img} alt="logo" />
        {/* <h3 className={classes.h3}>Compreg</h3> */}
      </div>
    </div>
  );
}

export default Logo;
