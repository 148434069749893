import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import OrganizerListing from '../../Containers/Organizers/OrganizerListing/OrganizerListing';

function Organizers() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('organizers.Organisers')} />

        <OrganizerListing />

        {/* <BreadCrumbs/> */}
      </Dashboard>
    </div>
  );
}

export default Organizers;
