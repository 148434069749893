import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  deleteIcon: {
    color: '#E40909',
    fontSize: '20px',
    cursor: 'pointer',
  },
  wrapperActions: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  addbtnWrapper: {
    '@media only screen and (max-width: 650px)': {
      width: '335px',
      marginTop: '18px',
    },
    '@media only screen and (min-width: 650px)': {
      paddingLeft: '10px',
      display: 'flex',
      height: '54px',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  addNew: {
    '@media only screen and (max-width: 650px)': {
      paddingLeft: '22px',
      width: '253px',
    },
  },
}));
