import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  table: {
    border: '2px solid black;',
    display: 'grid',
    // gridTemplateColumns: `repeat(2, 1fr)`,
  },

  tableTd: {
    border: '2px solid black;',
    textAlign: 'left',
    padding: '16px 20px',
    minWidth: '100px',
  },
  tableWrapper: {
    // background: '#fff',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },

  tableTHead: {
    display: 'contents',
  },
  tableTBody: {
    display: 'contents',
  },
  tableTr: {
    display: 'contents',
  },
  tableTh: {
    position: 'relative',
    fontSize: '18px',
    textAlign: 'left',
    padding: '16px 20px',
    minWidth: '100px',
    border: '2px solid black;',
  },

  tableSpan: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },

  resizeHandle: {
    display: 'block',
    position: 'absolute',
    cursor: 'col-resize',
    width: '40px',
    right: '-20px',
    top: '0',
    zIndex: 1,
    borderRight: '2px solid transparent',
    '&:hover': {
      borderColor: 'red !important',
    },
  },

  resizeHandleActive: {
    borderColor: '#517ea5 !important',
  },

  removeResizeHandle: {
    display: 'block',
    position: 'absolute',
    cursor: 'col-resize',
  },
}));
