import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';
import FormStrokedButton from './FormStrokedButton';
import {
  getVersionDetails,
  getVersionPdf,
} from '../../Services/Auth/Actions/UserActions';

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '0px',
    height: '23px',
    width: '43px',
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overFlow: 'auto',
    overFlowX: 'hidden',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '60%',
    height: '80%',
    padding: '30px 0',
    '@media all and (max-width: 1200px)': {
      width: '30%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    float: 'left',
    overflow: 'auto',
    position: 'relative',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
}));

const ITEM_HEIGHT = 30;

function VersionUpdates() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showModal, setShowModal] = React.useState(false);
  const [versionData, setVersionData] = React.useState<any>('');
  useEffect(() => {
    getVersionDetails().then((response) => {
      setVersionData(response.data);
    });
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showModalPopup = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };
  const [pdfLoad, setPdfLoad] = useState(false);
  const downloadPdf = () => {
    setPdfLoad(true);
    getVersionPdf()
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `release note.pdf`);
        document.body.appendChild(link);
        link.click();
        setPdfLoad(false);
      })
      .catch((err) => {
        setPdfLoad(false);
        toast.error(t('General settings.Pdf download failed'), {
          position: 'top-center',
          autoClose: 5000,
        });
      });
  };

  return (
    <div>
      {/* <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton> */}

      <div
        className="center-text"
        onClick={() => {
          showModalPopup();
        }}
        style={{ cursor: 'pointer' }}
      >
        {/* Version: 1.2.4 02.05.2022 13.25 */}
        Version: {versionData.version}
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '12ch',
            padding: 0,
          },
        }}
      />
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className={classes.paper}>
              {/* <button 
          name="Download List"
          onClick={downloadPdf}
          className={classes.btn} >hallo</button>  */}

              <div className={classes.wrapper}>
                <h2 style={{ textAlign: 'center', width: '100%' }}>
                  {t('General settings.Release Notes')}
                </h2>

                <FormStrokedButton
                  name="Print"
                  onClick={downloadPdf}
                  className={classes.btn}
                />
              </div>
              <div style={{ textAlign: 'center', wordSpacing: '5px' }}>
                Version:{versionData.version} Date:{' '}
                {moment(versionData.updateAt).format('DD/MM/YYYY')}
              </div>
              {versionData?.note?.map((data: any) => (
                <li key={data} style={{ margin: '20px' }}>
                  {data}
                </li>
              ))}
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
export default VersionUpdates;
