import React from 'react';
import { makeStyles } from '@material-ui/core';
import AVATAR from './AvatarImage.svg';

const useStyles = makeStyles({
  paper: {
    backgroundColor: '#f1f1f1',
    border: '2px solid transparent',
    boxShadow: '0px 10px 10px rgb(0 0 0 / 30%)',
    borderRadius: '20px',
    width: 'auto',
    height: '50%',
    alignItems: 'center',
  },
  fullImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    borderRadius: '20px',
  },
});

function PreviewImage({ image }) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <img src={image || AVATAR} className={classes.fullImage} alt="AVATAR" />
    </div>
  );
}

export default PreviewImage;
