import axios from 'axios';
import store from '../../../Store';
import { baseUrl } from '../../Constants';

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const GetEventTypes = ({
  sortBy = 'type',
  sort = 'asc',
  search = '',
  page = 0,
  limit = 10,
}) =>
  axios.get(
    `${baseUrl}/api/event/types/event-types?sortBy=${sortBy}&sort=${sort}&search=${search}&page=${
      page + 1
    }&limit=${limit}`,
    getHeaders(),
  );

export const DeleteEventType = (id) =>
  axios.delete(`${baseUrl}/api/event/delete/event-type/${id}`, getHeaders());

export const FindEventTypeById = (id) =>
  axios.get(`${baseUrl}/api/event/event-type/${id}`, getHeaders());

export const AddEventType = (data) =>
  axios.post(`${baseUrl}/api/event/create/event-type`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const UpdateEventType = (data, id) =>
  axios.put(`${baseUrl}/api/event/update/event-type/${id}`, data, getHeaders());
