import { makeStyles } from '@material-ui/core';
import theme from 'src/Theme';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    zIndex: 0,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
    cursor: 'pointer',
  },
  arrowIconActive: {
    position: 'relative',
    top: '2px',
    left: '5px',
    cursor: 'pointer',
    color: colors.primary.color,
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  boxType: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  submitButton: {
    display: 'flex',
    marginTop: '6px',
    width: '100%',
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  tbodyName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px 40px',
    position: 'relative',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  dPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px',
    position: 'relative',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },

  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '400px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'left',
    maxWidth: '230px',
    margin: '0  32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    color: '#9FA2B4',
    flex: 1,
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  wrapper12: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
