import { useTranslation } from 'react-i18next';
import { Menu } from '@mui/material';
import { MenuItem } from '@material-ui/core';
// eslint-disable-next-line import/named
import { ContextMenuState, menuItems } from 'src/Hooks/useDynamicFormContextMenu';
import useStyles from '../../../newEventFormStyle';

type CustomContextMenuProps = {
  x: number;
  y: number;
  handleOptionClick: (value:string) => void;
  contextMenu: ContextMenuState | null;
};

function CustomContextMenu({ x, y, handleOptionClick, contextMenu }: CustomContextMenuProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Menu
        id="context-menu"
        keepMounted
        open={Boolean(contextMenu)}
        onClick={(e) => e.stopPropagation()}
        anchorReference="anchorPosition"
        anchorPosition={{ top: y, left: x }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleOptionClick(item.value)}
            className={classes.menuButton}
          >{t('others.Add pin')}
          </MenuItem>
        ))}
      </Menu>
   </div>
  );
};

export default CustomContextMenu;