import { useState, useEffect, useCallback } from 'react';

type UseCreateUpdateFormParams<T> = {
  updateId?: string;
  fetchById?: Function;
  createFn: Function;
  updateFn: Function;
  onSuccess: (response: T) => void;
  onFailure: (error: any) => void;
  initialFormState: {
    [key: string]: string;
  };
};

const useCreateUpdateForm = <T>({
  updateId,
  fetchById,
  createFn,
  updateFn,
  onSuccess,
  onFailure,
  initialFormState,
}: UseCreateUpdateFormParams<T>) => {
  const [formData, setFormData] = useState(initialFormState);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchAndUpdateFormData = useCallback(async () => {
    if (updateId && fetchById) {
      setLoading(true);
      try {
        const response = await fetchById(updateId);
        setFormData(response?.data);
      } catch (error) {
        onFailure(error);
      } finally {
        setLoading(false);
      }
    }
  }, [updateId]); // onFailure,fetchById

  useEffect(() => {
    fetchAndUpdateFormData();
  }, [fetchAndUpdateFormData]);

  const handleSubmit = async (
    values: T,
    actions: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setLoading(true);
    try {
      const response = updateId
        ? await updateFn(updateId, values)
        : await createFn(values);
      onSuccess(response);
    } catch (error) {
      onFailure(error);
    } finally {
      setLoading(false);
      actions.setSubmitting(false);
    }
  };

  return {
    formData,
    setFormData,
    isLoading,
    handleSubmit,
  };
};

export default useCreateUpdateForm;
