const initialState = {
    user: null,
};

// eslint-disable-next-line default-param-last
export default function userReducer(state = initialState, action?) {
    switch (action.type) {
        case 'SET_USER': {
            return action.payload;
        }
        case 'RESET_USER': {
            return initialState;
        }
        default:
            return state;
    }
}
  