import { Field, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { formFieldMainGroups } from 'src/Services/Constants';
import {
  AddFormFieldGroup,
  UpdateFormFieldGroup,
} from '../../Services/Auth/Actions/FormFieldActions';
import { commonResponseHandler, Loader } from '../../Services/Utils';
import { ErrorMessageCustom, FormButton, FormLabel } from '../Utils';
import makeStyles from './AddFieldGroupStyle';
import SuccessToast from '../Utils/SuccessToast';
import ErrorToast from '../Utils/ErrorToast';

function AddFieldGroup({ item, reload, setOpenPopup }) {
  const classes = makeStyles();
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState(item?.groupName);
  const [parentName, setParentName] = useState(item?.parentName);
  const id = item?._id;
  const [title, setTitle] = useState('New');
  const formFieldSchema = Yup.object().shape({
    parentName: Yup.string().required(t('formFields.ParentName is required')),
    groupName: Yup.string().required(t('formFields.GroupName is required')),
  });
  const optionValues = [
    ...formFieldMainGroups,
    {
      value: 'FormHeader',
      label: 'FormHeader',
    },
  ];

  useEffect(() => {
    if (!isEmpty(item)) {
      setTitle('Edit');
    }
  }, []);

  const handleParentNameChange = (event) => {
    setParentName(event.target.value);
  };
  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const onCloseHandle = () => {
    reload();
    setOpenPopup(false);
  };
  const onSubmit = (values, setSubmitting) => {
    setSubmitting(false);
    onCloseHandle();
    if (title === 'New') {
      commonResponseHandler(AddFormFieldGroup(values), () => {
        toast.success(t('toast.Form Field Group Added Successfully'), {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
        setSubmitting(false);
        onCloseHandle();
      });
    }
    if (title === 'Edit') {
      UpdateFormFieldGroup({ id, data: values })
        .then(() => {
          SuccessToast(t('toast.Sucessfully updated'));
          setSubmitting(false);
          reload();
          onCloseHandle();
        })
        .catch(() => {
          ErrorToast(t('others.Something went wrong'));
          setSubmitting(false);
        });
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          parentName: parentName || '',
          groupName: groupName || '',
        }}
        enableReinitialize
        validationSchema={formFieldSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        {({ isSubmitting, handleBlur, values, errors, touched }) => (
          <Form>
            <div className={classes.titleWrapper}>
              <h3 id="transition-modal-description">
                {t(`formFields.${title} Form Field Group`)}
              </h3>
              <Loader isLoading={isSubmitting} type="ThreeDots">
                <FormButton
                  className=""
                  name={t('forms.Submit')}
                  type="submit"
                  disabled={isSubmitting}
                />
              </Loader>
            </div>
            <div className={classes.form}>
              <FormLabel name={t('formFields.ParentName')} require />
              <select
                name="parentName"
                value={values.parentName}
                onChange={(event) => {
                  handleParentNameChange(event);
                }}
                onBlur={handleBlur}
              >
                <option value={parentName} selected>
                  {parentName}
                </option>
                {optionValues.map((option) => (
                  <option value={option.value} label={option.label} />
                ))}
              </select>
              <ErrorMessageCustom errors={errors} field="parentName" touched={touched} />

              <FormLabel name={t('formFields.GroupName')} require />
              <Field
                className={classes.input}
                text="key"
                type="text"
                name="groupName"
                value={values.groupName}
                onChange={(event) => {
                  handleGroupNameChange(event);
                }}
              />
              <ErrorMessageCustom errors={errors} field="groupName" touched={touched} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddFieldGroup;
