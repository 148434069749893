import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import {
  FindRallyTypeById,
  UpdateRallyType,
  AddRallyType,
} from 'src/Services/Auth/Actions/RallyTypeActions';
import { CommonTranslatedResponseHandler } from 'src/Services/Utils';
import * as Yup from 'yup';
import useStyles from './AddEditRallyTypeStyle';

const rallyTypeSchema = Yup.object().shape({
  type: Yup.string().required('Rally type is required').trim(),
});

function AddEditRallyType({ updateId, reload, setOpenPopup }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (updateId) {
      FindRallyTypeById(updateId)
        .then((res) => {
          setData(res.data.rallyType);
        })
        .catch((err) => console.error(err));
    }
  }, [updateId]);

  return (
    <div>
      <Formik
        initialValues={{
          type: data?.type || '',
        }}
        enableReinitialize
        validationSchema={rallyTypeSchema}
        onSubmit={(values, { setSubmitting }) => {
          const updateData: any = {
            ...values,
          };
          updateData.key = updateData.type.toLowerCase().split(' ').join('_');

          if (updateId) {
            const responseHandlerProps = {
              promise: UpdateRallyType(updateData, updateId),
              successCb: () => {
                setOpenPopup(false);
                reload();
              },
              errorCb: () => setSubmitting(false),
              successToast: t('rallyType.updated'),
              errorToast: t('rallyType.existingTypeError'),
            };
            CommonTranslatedResponseHandler(responseHandlerProps);
          } else {
            const responseHandlerProps = {
              promise: AddRallyType(updateData),
              successCb: () => {
                setOpenPopup(false);
                reload();
              },
              errorCb: () => setSubmitting(false),
              successToast: t('rallyType.created'),
              errorToast: t('rallyType.existingTypeError'),
            };
            CommonTranslatedResponseHandler(responseHandlerProps);
          }
        }}
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form>
            <div className={classes.titleWrapper}>
              <h3 id="transition-modal-description">
                {updateId
                  ? t('events.Update Rally Type')
                  : t('rallyTypes.Add New Rally Type')}
              </h3>
              <FormButton
                className=""
                name={t('forms.Submit')}
                isLoading={isSubmitting}
              />
            </div>
            <div>
              <FormLabel name={t('forms.Type')} require />
              <Field
                className={classes.input}
                text="type"
                type="text"
                name="type"
                value={values.type}
                onChange={handleChange}
              />
              <ErrorMessageCustom errors={errors} field="type" touched={touched} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddEditRallyType;
