import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import EventListing from '../../Containers/Events/EventListing/EventListing';

function Events() {
  const { t } = useTranslation();
  const role = useSelector((store: RootState) => store.role.role);
  return (
    <div>
      <Dashboard>
        <>
          <Header title={t('events.Events')} />
          <EventListing showMoreOptions />
        </>
      </Dashboard>
    </div>
  );
}

export default Events;
