import { useState } from 'react';
import { imageUpload } from 'src/Services/Auth/Actions/EventActions';

const useFileUploader = () => {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const uploadImage = async (formFields) => {
    setIsImageLoading(true);
    const fileFields = formFields.filter((field) => field.fileKey);
    const fileUploadPromise = fileFields.map(async (field) => {
      const fileBinarys = new FormData();
      fileBinarys.append('file', field.fileKey);
      // eslint-disable-next-line no-param-reassign
      delete field.fileKey;
      return (await imageUpload(fileBinarys)).data.key;
    });

    if (fileFields.length > 0) {
      await Promise.all(fileUploadPromise).then((items) => {
        fileFields.forEach((field: any, index: number) => {
          fileFields[index].values = items[index];
        });
        setIsImageLoading(false);
      });
    }
  };

  return { uploadImage, isImageLoading };
};

export default useFileUploader;
