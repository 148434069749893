import { Box, Grid, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import { addParticipant } from 'src/Services/Auth/Actions/EventActions';
import theme from 'src/Theme';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import _ from 'lodash';
import * as Yup from 'yup';
import { validator } from 'src/Services/Auth/Validations';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { lowercaseValue } from './utils';

const useStyles = makeStyles(() => ({
  outerbox: {
    position: 'relative',
  },
  btn: {
    width: '100% !important',
    marginBottom: '15px',
  },
  formBtn: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: '7px 6px',
    width: '100%',
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '1.6',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 6px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '5px',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  FormButton: {
    width: '100% !important',
  },
}));

function AddNewParticipant({
  selectedRallys,
  participants,
  eventId,
  rallyId,
  reload,
  onClose,
  rallyOptions,
  handleChanges,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const animatedComponents = makeAnimated();
  const initialValues = {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    username: '',
  };
  const EventParticipantsSchema = Yup.object().shape({
    firstName: validator.FIRSTNAME,
    email: validator.EMAIL,
    username: validator.USERNAME,
  });
  const onSubmit = async (values, setSubmitting) => {
    const rallyIds = _.uniq(selectedRallys);
    let data: any = {};
    if (participants) {
      data = {
        ...values,
        eventId,
        rallyId: rallyIds.map((r: any) => r.id),
      };
    }
    if (!participants) {
      data = {
        ...values,
        eventId,
        rallyId: [rallyId],
      };
    }
    data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
    data.email = data.email && data.email.trim().toLowerCase();
    if (data.rallyId.length <= 0) {
      setSubmitting(false);
      toast.error(t('rally.Select atleast one Race'));
      return;
    }
    addParticipant(data)
      .then(() => {
        SuccessToast(t('rally.rallyShared'));
        setSubmitting(false);
        reload();
        onClose();
      })
      .catch(() => {
        ErrorToast(t('validationErrors.Username already exist'));
        setSubmitting(false);
        reload();
      });
  };

  return (
    <Formik
      key={1}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={EventParticipantsSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting, handleChange, values, errors, touched, setFieldValue }) => (
        <Form>
          <Box sx={{ flexGrow: 1 }} className={classes.outerbox}>
            <>
              {participants && (
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <FormLabel name={t('forms.Select a rally')} require />
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={rallyOptions[0]}
                      isMulti
                      onChange={handleChanges}
                      options={rallyOptions}
                      name="rallyName"
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="rallyName"
                      touched={touched}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <FormLabel name={t('forms.First Name')} require />
                  <Field
                    className={classes.input}
                    text="firstName"
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="firstName"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <FormLabel name={t('forms.Last Name')} />
                  <Field
                    className={classes.input}
                    text="lastName"
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="lastName"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <FormLabel name={t('forms.Username')} require />
                  <Field
                    className={classes.input}
                    text="username"
                    type="text"
                    name="username"
                    value={values.username.trim().toLowerCase().replace(/ +/g, '')}
                    onChange={(e) => {
                      const lowercase = lowercaseValue(e.target.value);
                      handleChange(setFieldValue('username', lowercase));
                    }}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="username"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <FormLabel name={t('forms.Email')} />
                  <Field
                    className={classes.input}
                    text="email"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="email" touched={touched} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <FormLabel name={t('forms.Phone')} />
                  <Field
                    className={classes.input}
                    text="phone"
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="phone" touched={touched} />
                </Grid>
              </Grid>
            </>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <FormButton
                  name={t('forms.Submit')}
                  isLoading={isSubmitting}
                  className={classes.FormButton}
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default AddNewParticipant;
