import React from 'react';
import './Style.css';
import AdminMenu from './AdminMenu';

function Admin() {
  return (
    <div className="super-admin-area">
      <div className="admin-area">
        <AdminMenu />
        {/* <img src={AVATAR_IMAGE} alt="avatar"></img> */}
      </div>
    </div>
  );
}

export default Admin;
