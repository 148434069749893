/* eslint-disable react/jsx-props-no-spreading */
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { FormStrokedButton } from 'src/Components/Utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { Loader } from 'src/Services/Utils';
import { t } from 'i18next';
import EditableHeading from 'src/Components/Utils/EditableHeading';
import useStyles from '../style';
import { DragAndDropProps } from '../types/docType';
// eslint-disable-next-line import/no-named-as-default
import useDragAndDrop from '../hooks/useDragDrop';
import DragAndDropForm from './Form/DragAndDropForm';
import DraggableColumn from './Column/DraggableColumn';
import { camelCaseToTitleCase, columnType, findDefaultSourceColumn } from '../util';

function DragAndDrop({ exportDetails }: DragAndDropProps) {
  const initialSort = useSelector(
    (state: RootState) => state.table[`${exportDetails?.resource}Exporter`],
  );

  const classes = useStyles();
  const {
    defaultFields,
    eventId,
    resource,
    formListing,
    refreshDefaultFields,
    organizerId,
    initialSortApi,
    id,
    reportName,
    reload,
    formId,
    rallyId,
  } = exportDetails;

  // import drag and drop functionalities from the customHook
  const {
    columns,
    destinationColumnData,
    isLoading,
    onDragEnd,
    onSubmit,
    dropDownValues,
    setDropDownValues,
    selectAllFields,
    sourceColumnData,
    components,
    setColumns,
    onReportSubmit,
    updateReportSubmit,
    setReportName,
  } = useDragAndDrop({
    defaultFields,
    eventId,
    resource,
    formListing,
    refreshDefaultFields,
    organizerId,
    initialSortApi,
    reload,
    formId,
    rallyId,
    tableReportName: reportName,
  });
  // In the case of event participants exporter we are fetching the formFieds data form the api and append them with the default fields.
  // Such occations, we need to update the column data.(Whenever the default fields change)
  // In the case of tabelReport we are fetcing the sort , type form initialSortApi and append it to the particular exporter.
  useEffect(() => {
    setColumns({
      sourceColumn: {
        name: columnType.source,
        items: findDefaultSourceColumn(
          exportDetails.defaultFields,
          initialSortApi?.sourceColumn || initialSort?.sourceColumn || [],
          initialSortApi?.destinationColumn || initialSort?.destinationColumn || [],
        ),
      },
      destinatioColumn: {
        name: columnType.destination,
        items: initialSortApi?.destinationColumn || initialSort?.destinationColumn || [],
      },
    });
    if (initialSortApi) {
      setDropDownValues({
        ...dropDownValues,
        sortOrder: initialSortApi.sort,
        sortBy: initialSortApi.sortBy,
        type: initialSortApi.exportDocType,
      });
    }
  }, [exportDetails.defaultFields, initialSortApi, initialSort]);

  return (
    <div className={classes.outerContainer}>
      {initialSortApi ? (
        <EditableHeading initialValue={reportName} onUpdate={setReportName} />
      ) : (
        <h2 className={classes.exporterHeading}>
          {t(`Exporter.${camelCaseToTitleCase(exportDetails.resource)}`)}
        </h2>
      )}
      <div className={classes.flex}>
        <Loader isLoading={exportDetails.isData}>
          <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result)}>
            {Object.entries(columns).map(([columnId, column]) => (
              <DraggableColumn column={column} columnId={columnId} key={columnId} />
            ))}
          </DragDropContext>
        </Loader>
        <DragAndDropForm
          destinationColumnData={destinationColumnData}
          dropDownValues={dropDownValues}
          isLoading={isLoading}
          onSubmit={onSubmit}
          setDropDownValues={setDropDownValues}
          formListing={exportDetails.formListing}
          components={components}
          resource={resource}
          onReportSubmit={onReportSubmit}
          sourceColumnData={sourceColumnData}
          id={id}
          reportName={reportName}
          updateReportSubmit={updateReportSubmit}
          reload={reload}
        />
      </div>
      <div className={classes.btnWrap}>
        {sourceColumnData.length > 0 && (
          <FormStrokedButton
            className={classes.selectAll}
            name={t('others.Select All')}
            onClick={() => selectAllFields(false)}
          />
        )}
        {destinationColumnData.length > 0 && (
          <FormStrokedButton
            className={classes.selectAll}
            name={t('others.Unselect All')}
            onClick={() => selectAllFields(true)}
          />
        )}
      </div>
    </div>
  );
}

export default DragAndDrop;
