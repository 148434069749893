import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormButton, ErrorMessageCustom, FormLabel } from 'src/Components/Utils';
import {
  AddEventType,
  FindEventTypeById,
  UpdateEventType,
} from 'src/Services/Auth/Actions/EventTypeActions';
import * as Yup from 'yup';
import { CommonTranslatedResponseHandler } from 'src/Services/Utils';
import { useTranslation } from 'react-i18next';
import useStyles from './AddEditEventTypeStyle';

const eventTypeSchema = Yup.object().shape({
  type: Yup.string().required('validationErrors.Event Type is Required').trim(),
});

function AddEditEventType({ updateId, reload, setOpenPopup }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (updateId) {
      FindEventTypeById(updateId)
        .then((res) => {
          setData(res.data.eventType);
        })
        .catch((err) => console.error(err));
    }
  }, [updateId]);
  return (
    <Formik
      initialValues={{
        type: data?.type || '',
      }}
      enableReinitialize
      validationSchema={eventTypeSchema}
      onSubmit={(values, { setSubmitting }) => {
        const formvaluse: any = {
          ...values,
        };
        formvaluse.key = formvaluse.type.toLowerCase().split(' ').join('_');
        if (updateId) {
          const responseHandlerProps = {
            promise: UpdateEventType(formvaluse, updateId),
            successCb: () => {
              setOpenPopup(false);
              reload();
            },
            errorCb: () => setSubmitting(false),
            successToast: t('eventType.updated'),
            errorToast: t('eventType.existingTypeError'),
          };
          CommonTranslatedResponseHandler(responseHandlerProps);
        } else {
          const responseHandlerProps = {
            promise: AddEventType(formvaluse),
            successCb: () => {
              setOpenPopup(false);
              reload();
            },
            errorCb: () => setSubmitting(false),
            successToast: t('eventType.created'),
            errorToast: t('eventType.existingTypeError'),
          };

          CommonTranslatedResponseHandler(responseHandlerProps);
        }
      }}
    >
      {({ isSubmitting, handleChange, values, errors, touched }) => (
        <Form>
          <div className={classes.titleWrapper}>
            <h3 id="transition-modal-description">
              {updateId ? t('events.Update Event Type') : t('events.Add New Event Type')}
            </h3>
            <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
          </div>
          <div>
            <FormLabel name={t('forms.Type')} require />
            <Field
              className={classes.input}
              text="type"
              type="text"
              name="type"
              value={values.type}
              onChange={handleChange}
            />
            <ErrorMessageCustom errors={errors} field="type" touched={touched} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddEditEventType;
