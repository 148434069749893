/* eslint-disable react/require-default-props */
import { Icon } from '@iconify/react';
import DragAndDrop from './DragAndDrop/DragAndDrop';
import useStyles from './style';
import { DocModalProps } from './types/docType';

function DocModal({ exportDetails }: DocModalProps) {
  const classes = useStyles();

  const handleCloseClick = () => {
    exportDetails.onCancel();
  };

  return (
    <div className={classes.paper}>
      <Icon
        icon="carbon:close-outline"
        onClick={() => {
          handleCloseClick();
        }}
        className={classes.icons}
      />
      <div className={classes.mainContainer}>
        <DragAndDrop exportDetails={exportDetails} />
      </div>
    </div>
  );
}

export default DocModal;
