/* eslint-disable react/require-default-props */
import { Icon } from '@iconify/react';
import { FC } from 'react';
import useStyles from '../DocDownload/style';

export type IconButtonProps = {
  onClick: Function;
  icon: string;
  colour?: string;
};
const IconButton: FC<IconButtonProps> = ({ onClick, icon, colour = '' }) => {
  const classes = useStyles();
  return (
    <div>
      <Icon
        icon={icon}
        onClick={(event) => onClick(event)}
        className={classes.icon}
        style={{ color: `${colour}` }}
      />
    </div>
  );
};

export default IconButton;
