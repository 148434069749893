import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
}));
