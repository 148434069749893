import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    fontFamily: typography.fonts.secondary,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  tbodyName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    width: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
