import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import theme from 'src/Theme';

const useStyles = makeStyles(() => ({
  dPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px',

    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    display: 'flex',
    alignItems: 'center',
  },
}));

function ImageLoader() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.dPaper}>
      {t(`Images are uploading`)}
      <Loader
        type="ThreeDots"
        color={theme.palette.primary.main}
        height={30}
        width={30}
      />
    </div>
  );
}

export default ImageLoader;
