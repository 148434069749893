import React, { useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import FilterList from 'src/Components/Utils/FilterList';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import { Loader } from 'src/Services/Utils';
import { FormType } from 'src/Types/FormListType';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import WarningToast from 'src/Components/Utils/WarningToast';
import FormPopup from './FormPopup';
import { deleteSingleFormByEventId } from '../../../Services/Auth/Actions/EventActions';
import { SubTitle, FormStrokedButton } from '../../../Components/Utils';
import useStyles from './RallyFormsStyle';
import EventFormPreview from '../Rally/EventFormPreview';
import EventFormsListing, {
  FormList,
  GroupedDataArray,
} from '../EventParticipants/EventFormsListing';

function previewForm({
  eventId,
  formId,
  rallyIds,
  setOpenPopup,
}: {
  eventId: String;
  formId: String;
  rallyIds: string;
  setOpenPopup: Function;
}) {
  return (
    <EventFormPreview
      eventId={eventId}
      formId={formId}
      setOpenPopup={setOpenPopup}
      rallyIds={rallyIds}
    />
  );
}

function RallyForms({ eventStatus }: { eventStatus: EnumEventStatus }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [list, setList] = useState<GroupedDataArray[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { id }: any = useParams();
  const user = useSelector((store: RootState) => store.role.user);
  const [allList, setAllList] = useState<Array<FormList>>([]);
  const [rallyFilterId, setRallyFilterId] = useState('all');
  const modal = useModalDialog();
  const previewFormSetOpen = useMemo(() => modal(previewForm, 'preview-form'), []);
  const NewFormSetOpen = useMemo(() => modal(FormPopup, 'New-Form-Popup'), []);
  const CloneFormSetOpen = useMemo(() => modal(FormPopup, 'Clone-Form-Popup'), []);
  const confirm: any = useConfirm();

  const showFormPreview = (eventId, formId, rallyIds) => {
    previewFormSetOpen(true, {
      eventId,
      formId,
      rallyIds,
      overrideBody: true,
    });
  };

  const deleteEventClick = (deleteId, reload) => {
    confirm({
      description: t('forms.Form Delete Warning'),
    }).then(() => {
      deleteSingleFormByEventId(id, deleteId)
        .then(async () => {
          SuccessToast(t('forms.Form Deleted Successfully'));
          reload();
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            position: 'top-center',
            autoClose: 5000,
          });
        });
    });
  };

  const handleNewFormClick = () => {
    if (eventStatus === EnumEventStatus.EventFinished) {
      WarningToast(t('popup.eventFinished'));
      return;
    }
    NewFormSetOpen(true, { details: id, formFor: 'Add New Form', overrideBody: true });
  };

  const handleCloneFormClick = () => {
    if (eventStatus === EnumEventStatus.EventFinished) {
      WarningToast(t('popup.eventFinished'));
      return;
    }
    CloneFormSetOpen(true, { details: id, formFor: 'Clone Form', overrideBody: true });
  };

  const formActionButtons = ({
    form,
    listForms,
  }: {
    form: FormType;
    listForms: () => void;
  }) => (
    <>
      <Icon
        icon="feather:eye"
        className={classes.viewIcon}
        onClick={() => {
          showFormPreview(form.eventId, form._id, form.rallyId);
        }}
      />
      <Icon
        icon="feather:edit-3"
        className={classes.editIcon}
        onClick={() =>
          history.push({
            pathname: `/events/forms/editForm/${form.eventId}/${form._id}`,
            state: { showGoBack: true, rallyID: form.rallyId },
          })
        }
      />
      <Icon
        icon="ant-design:delete-twotone"
        className={classes.deleteIcon}
        onClick={() => deleteEventClick(form._id, listForms)}
      />
    </>
  );

  return (
    <div id="Element">
      <div className={classes.boxType}>
        <div className={classes.wrapper}>
          <SubTitle title={t('events.Manage Forms')} />
          <div className={classes.formActionButtons}>
            <FormStrokedButton
              name={t('formFields.New Form')}
              className={classes.btn}
              onClick={handleNewFormClick}
            />
            <FormStrokedButton
              name={t('formFields.Clone Form')}
              className={classes.btn}
              onClick={handleCloneFormClick}
            />
            <FilterList
              allList={allList}
              setLoading={setLoading}
              setList={setList}
              setRallyFilterId={setRallyFilterId}
            />
          </div>
        </div>
        <Loader isLoading={isLoading} type="circular">
          <EventFormsListing
            eventId={id}
            userId={user._id}
            actionButtons={formActionButtons}
            listProp={list}
            setAllList={setAllList}
            rallyForm
          />
        </Loader>
      </div>
    </div>
  );
}

export default RallyForms;
