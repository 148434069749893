import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../Components/BreadCrumbs.jsx/BreadCrumbs';
import Header from '../../Components/Header/Header';
import DashboardCard from '../../Containers/OverView/DashboardCard/DashboardCard';
import Dashboard from '../../Layouts/Dashboard/Dashboard';

function OverView() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('overview.Overview')} />
        <DashboardCard />

        {/* <Loader /> */}
        <BreadCrumbs />
      </Dashboard>
    </div>
  );
}

export default OverView;
