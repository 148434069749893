import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createHeaders, MIN_CELL_WIDTH } from '../../util';
import { TableHeaders, UseTableFieldProps } from '../types/table';

const useTableField = ({
  cols,
  rows,
  editData,
  setEditData,
  submitEdit,
}: UseTableFieldProps) => {
  const [tableHeight, setTableHeight] = useState<string>('auto');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const tableElement = useRef(null);

  const headerArray: string[] = Array.from({ length: cols }, (_) => uuidv4());
  const headerRef: MutableRefObject<any[]> = useRef([]);

  const columns: TableHeaders[] = createHeaders(headerArray, headerRef);

  useEffect(() => {
    headerArray.map((header: string, index: number) => {
      headerRef.current.push({});
    });
  }, [headerArray]);

  useEffect(() => {
    // @ts-ignore: Object is possibly 'null'
    setTableHeight(tableElement.current?.offsetHeight);
  }, [headerArray]);

  const mouseDown = (index: number) => {
    setActiveIndex(index);
    setEditData(editData);
  };

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex) {
          // @ts-ignore: Object is possibly 'null'
          const width: number = e.clientX - col?.ref?.current.getBoundingClientRect().x;

          if (width >= MIN_CELL_WIDTH) {
            return `${width}px`;
          }
        }
        // @ts-ignore: Object is possibly 'null'
        return `${col?.ref?.current.offsetWidth}px`;
      });

      // @ts-ignore: Object is possibly 'null'
      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns, MIN_CELL_WIDTH],
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();

    submitEdit({
      ...editData,
      styles: {
        ...editData?.styles,
        // @ts-ignore
        gridTemplateColumns: tableElement.current?.style.gridTemplateColumns,
      },
    });
  }, [setActiveIndex, removeListeners]);

  useEffect(() => () => {
      removeListeners();
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  return {
    tableHeight,
    activeIndex,
    tableElement,
    columns,
    mouseDown,
    mouseMove,
    mouseUp,
  };
};

export default useTableField;
