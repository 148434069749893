import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { fileResource } from 'src/Components/DocDownload/util';
import theme from 'src/Theme';
import TableContainer from '@material-ui/core/TableContainer/TableContainer';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import EnumSubmitActions from 'src/Types/Enums/EnumSubmitActions';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import DocButton from 'src/Components/DocDownload/DocButton';
import AVATAR from './AvatarImage.svg';
import OrganizerContactDelete from '../OrganizerListing/OrganizerContactDelete';
import { getOrganizer } from '../../../Services/Auth/Actions/OrganizerActions';
import OrganizerContactAddEdit from './OrganizerContactAddEdit';
import { FormStrokedButton } from '../../../Components/Utils';
import useStyles from './OrganizerContactStyle';

function OrganizerContact({ details }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const role = useSelector((store: RootState) => store.role.role);
  const { id }: any = useParams();
  const [contact, setContact] = useState([]);
  const modal = useModalDialog();

  const setAddEditModelContOpen = useMemo(
    () => modal(OrganizerContactAddEdit, 'AddEditOrganiserContact'),
    [],
  );

  const reload = () => {
    getOrganizer(id)
      .then((response) => {
        const data = response.data.organizer.eventOrganizers;

        data.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });
        setContact(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddContactPopup = () => {
    setAddEditModelContOpen(true, {
      tittle: 'Add New Organiser Contact',
      details: id,
      reload,
      isModal: true,
      submitAction: EnumSubmitActions.create,
    });
  };

  const handleOpenEditPopup = (contId) => {
    setAddEditModelContOpen(true, {
      tittle: 'Edit Organiser Contact',
      details: contId,
      reload,
      isModal: true,
      submitAction: EnumSubmitActions.update,
    });
  };

  useEffect(() => {
    reload();
  }, [id]);

  const isSuperAdmin = role === EnumUserRoles.superadmin;
  const isOrganizer = role === EnumUserRoles.organizer;

  return (
    <div className={classes.wrapper}>
      <div className={classes.topWrap}>
        <h3 className={classes.h3}>{t('organizers.Organiser Contact')}</h3>
        {details.isActive && (isSuperAdmin || isOrganizer) && (
          <div className={classes.buttonGroup}>
            <FormStrokedButton
              name={t('events.Add New')}
              onClick={handleAddContactPopup}
              className={classes.btn}
            />
            <DocButton
              name={t('events.Export')}
              resource={fileResource.organiserContact}
              color={theme.palette.primary.main}
              textColor="#fff"
              organizerId={id}
            />
          </div>
        )}
      </div>
      {contact ? (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>{t('organizers.Image')}</TableCell>
                <TableCell>{t('organizers.Name')}</TableCell>
                <TableCell>{t('organizers.Phone')}</TableCell>
                <TableCell>{t('organizers.Email')}</TableCell>
                {isSuperAdmin || isOrganizer ? (
                  <TableCell>{t('organizers.Actions')}</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {contact.map((item: any) => (
                <TableRow
                  onDoubleClick={() => {
                    if (isSuperAdmin || isOrganizer) {
                      handleOpenEditPopup(item._id);
                    }
                  }}
                  key={item._id}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell>
                    <div>
                      <img
                        src={item.profileImage || AVATAR}
                        className={classes.img}
                        alt="avatar"
                      />
                    </div>
                  </TableCell>
                  <TableCell>{`${item.lastName} ${item.firstName}`}</TableCell>
                  <TableCell>{item.phone}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  {isSuperAdmin || isOrganizer ? (
                    <TableCell>
                      <div>
                        <div>
                          <OrganizerContactDelete
                            handleOpenEditPopup={() => handleOpenEditPopup(item._id)}
                            reload={reload}
                            item={item}
                          />
                        </div>
                      </div>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.content}>
          <p>{t('others.Nothing to show')}</p>
        </div>
      )}
    </div>
  );
}

export default OrganizerContact;
