import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';
import { RootState } from 'src/Store';
import { lowercaseValue } from 'src/Containers/Events/EventParticipants/utils';
import { commonResponseHandler } from '../../../Services/Utils';
import { FormLabel, ErrorMessageCustom, FormButton } from '../../../Components/Utils';
import {
  loggedUser,
  logoutUser,
  updateUserById,
} from '../../../Services/Auth/Actions/UserActions';
import AvatarUpload from '../../../Components/AvatarUpload/AvatarUpload';
import { validator } from '../../../Services/Auth/Validations';

const useStyles = makeStyles(() => ({
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    fontFamily: typography.fonts.secondary,
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  btnWrap: {
    textAlign: 'left',
  },
  btn: {
    float: 'left',
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  wrapper: {
    backgroundColor: '#fff',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:767px)': {
      padding: 0,
      margin: '30px 0px 20px',
    },
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 30px 15px',
    background: '#dfe0eb40',
    alignItems: 'center',
  },
  box: {
    padding: '15px 30px 15px',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '25px',
  },
  h3: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
  iconBack: {
    fontSize: '30px',
    color: '#343a40',
    marginRight: '5px',
    cursor: 'pointer',
  },
}));

function EditUser({ details, type, reload, showGoBack }) {
  const { t } = useTranslation();
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const AddNewUserSchema = Yup.object().shape({
    firstName: Yup.string().when('role', {
      is: 'individual',
      then: validator.FIRSTNAME,
      otherwise: Yup.string(),
    }),

    lastName: Yup.string().when('role', {
      is: 'individual',
      then: validator.LASTNAME,
      otherwise: Yup.string(),
    }),

    username: Yup.string().when('role', {
      is: 'individual',
      then: validator.USERNAME,
      otherwise: validator.USERNAME,
    }),

    companyname: Yup.string().when('role', {
      is: 'organizer',
      then: Yup.string().required('validationErrors.Company name is Required'),
      otherwise: Yup.string(),
    }),
    phone: validator.PHONE,
    address1: Yup.string(),
    street: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    postcode: Yup.string(),
    facebookUrl: Yup.string(),
    instagramUrl: Yup.string(),
  });

  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState({ binary: '', fileName: '' });
  const userRole = useSelector((store: RootState) => store.role.user.role);

  const initialValues = {
    firstName: details.firstName || '',
    lastName: details.lastName || '',
    username: details.username || '',
    email: details.email || '',
    phone: details.phone || '',
    address1: details.address1 || '',
    city: details.city || '',
    street: details.street || '',
    state: details.state || '',
    country: details.country || '',
    postcode: details.postcode || '',
    facebookUrl: details.facebookUrl || '',
    instagramUrl: details.instagramUrl || '',
    companyname: details.companyname || '',
  };

  const submitFun = (values, setSubmitting) => {
    const data: any = {
      ...values,
    };
    if (data.username) {
      data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
    }
    if (data.email) {
      data.email = data.email.toLowerCase();
    }
    if (details.companyname) {
      delete data.firstName;
      delete data.lastName;
    } else {
      delete data.companyname;
    }
    const formData = new FormData();
    if (image.binary) {
      formData.append('file', image.binary, image.fileName);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
    commonResponseHandler(
      updateUserById(details._id, formData),
      () => {
        reload();
        setSubmitting(false);
        if (details.username !== data.username) {
          logoutUser(details._id).then(() => {
            loggedUser(userRole);
          });
        }
      },
      () => setSubmitting(false),
    );
  };

  return (
    <div className={classes.wrapper}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={AddNewUserSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          submitFun(values, setSubmitting);
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form>
            <div className={classes.topWrap}>
              <h3 className={classes.h3}>{t('overview.PARTICIPANT DETAILS')}</h3>
              <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
            </div>

            <Box sx={{ flexGrow: 1 }} className={classes.box}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <AvatarUpload
                    image={image.binary}
                    setImage={setImage}
                    defaultImage={details.profileImage}
                    page="participant profile"
                  />
                </Grid>
              </Grid>
              {!details.companyname ? (
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <FormLabel name={t('forms.First Name')} />
                    <Field
                      className={classes.input}
                      text="firstName"
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="firstName"
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <FormLabel name={t('forms.Last Name')} />
                    <Field
                      className={classes.input}
                      text="lastName"
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="lastName"
                      touched={touched}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item lg={12} xs={12}>
                    <FormLabel name={t('forms.Company Name')} />
                    <Field
                      className={classes.input}
                      text="companyname"
                      type="text"
                      name="companyname"
                      value={values.companyname}
                      onChange={handleChange}
                    />
                    <ErrorMessageCustom
                      errors={errors}
                      field="companyname"
                      touched={touched}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Username')} />
                  <Field
                    className={classes.input}
                    text="username"
                    type="text"
                    name="username"
                    value={values.username.trim().toLowerCase().replace(/ +/g, '')}
                    onChange={(e) => {
                      const lowercase = lowercaseValue(e.target.value);
                      handleChange(setFieldValue('username', lowercase));
                    }}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="username"
                    touched={touched}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Email')} />
                  <Field
                    className={classes.input}
                    text="email"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="email" touched={touched} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Phone')} />
                  <Field
                    className={classes.input}
                    text="phone"
                    type="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="phone" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Address Line 1')} />
                  <Field
                    className={classes.input}
                    text="address1"
                    type="text"
                    name="address1"
                    value={values.address1}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="address1"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Street')} />
                  <Field
                    className={classes.input}
                    text="street"
                    type="text"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="street" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Postcode')} />
                  <Field
                    className={classes.input}
                    text="postcode"
                    type="text"
                    name="postcode"
                    value={values.postcode}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="postcode"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.City')} />
                  <Field
                    className={classes.input}
                    text="city"
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="city" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.State')} />
                  <Field
                    className={classes.input}
                    text="state"
                    type="text"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="state" touched={touched} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Country')} />
                  <Field
                    className={classes.input}
                    text="country"
                    type="text"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom errors={errors} field="country" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Facebook URL')} />
                  <Field
                    className={classes.input}
                    text="facebookUrl"
                    type="text"
                    name="facebookUrl"
                    value={values.facebookUrl}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="facebookUrl"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Instagram URL')} />
                  <Field
                    className={classes.input}
                    text="instagramUrl"
                    type="text"
                    name="instagramUrl"
                    value={values.instagramUrl}
                    onChange={handleChange}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="instagramUrl"
                    touched={touched}
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default EditUser;
