import { Icon } from '@iconify/react';
import React, { useMemo } from 'react';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import useStyles from '../../../newEventFormStyle';

let imageSelect;

const ImagePreviewPopupWrapper = ({ imagePreviewUrl }) => {
  const classes = useStyles();
  function ImagePreviewPopupComponent() {
    return (
      <div className={classes.paperpopup}>
        <img src={imageSelect} className={classes.fullImage} alt="event_AVATAR" />
      </div>
    );
  }
  function ImagePreviewPopup() {
    const modal = useModalDialog();

    const PreviewModal = useMemo(() => modal(ImagePreviewPopupComponent, "preview-modal"), []);

    imageSelect = imagePreviewUrl;

    return (
      <div>
        <Icon
          icon="feather:eye"
          className={classes.eyeIcon}
          onClick={() => {
            PreviewModal(true, { overrideBody: true });
          }}
        />
      </div>
    );
  }
  return ImagePreviewPopup();
};

export default ImagePreviewPopupWrapper;
