/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import ReactCrop, { Crop, makeAspectCrop, centerCrop, PixelCrop } from 'react-image-crop';
import { makeStyles, Switch } from '@material-ui/core';
import canvasPreview from './canvasPreview';
import 'react-image-crop/dist/ReactCrop.css';
import useDebounceEffect from './useDebounceEffect';
import { FormStrokedButton } from '../Utils';
  
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '20px',
  },
  input: {
    width: '40px',
    height: '20px',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    height: '20px',
  },
}));

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

function AvatarCrop({ imgSrc, setImagePreviewUrl, setImage, name, setOpenPopup }) {
  const classes = useStyles();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);
  const [croppedImg, setCroppedImg] = useState({ binaryData: '', urlPreview: '' });
  const [checked, setChecked] = useState(true);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleToggleAspectClick = () => {
    if (aspect) {
      setAspect(undefined);
      setChecked(false);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setChecked(true);
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        const { binaryData, urlPreview } = await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          setCroppedImg,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  const SaveClickhandle = (saveImage) => {
    setImage({ binary: saveImage.binaryData, fileName: `${name.split('.')[0]}.png` });
    setImagePreviewUrl(saveImage.urlPreview);
    setOpenPopup(false);
  };

  return (
    <div className={classes.root}>
      <div>
        {!!imgSrc && (
          <div className={classes.buttonsWrapper}>
            <div>
              <label htmlFor="scale-input" className={classes.label}>
                Scale:{' '}
              </label>
              <input
                id="scale-input"
                type="number"
                step="0.1"
                value={scale}
                disabled={!imgSrc}
                onChange={(e) => setScale(Number(e.target.value))}
                className={classes.input}
              />
            </div>
            <div>
              <label htmlFor="rotate-input" className={classes.label}>
                Rotate:{' '}
              </label>
              <input
                id="rotate-input"
                type="number"
                value={rotate}
                disabled={!imgSrc}
                onChange={(e) =>
                  setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
                }
                className={classes.input}
              />
            </div>
            <div>
              Aspect ratio
              <Switch
                checked={checked}
                onChange={handleToggleAspectClick}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div>
              {!!completedCrop && (
                <FormStrokedButton
                  className=""
                  name="save"
                  onClick={() => {
                    SaveClickhandle(croppedImg);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default AvatarCrop;
