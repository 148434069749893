import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  iconCancel: {
    color: '#E40909',
    fontSize: '20px',
  },
  iconExport: {
    color: '#ADAEB0',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    height: 'auto',
    padding: '30px 0',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    position: 'relative',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '20px',
    maxWidth: 'auto',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    color: '#9FA2B4',
    position: 'absolute',
    top: '0',
    right: '0',
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  shareIcon: {
    color: '#43A047',
    fontSize: '20px',
    cursor: 'pointer',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  mainWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    marginLeft: '12px',
  },
  tables: {
    paddingLeft: '25px',
    paddingRight: '25px',
    maxHeight: '100px',
    width: '100%',
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 500,
    backgroundColor: '#fff',
    fontFamily: typography.fonts.secondary,
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  container: {
    overflowY: 'scroll',
    maxHeight: '200px',
  },
  count: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    color: colors.primary.color,
    margin: '0',
    cursor: 'pointer',
    wordBreak: 'break-word',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '25px',
    cursor: 'pointer',
  },
  name: {
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  root: {
    width: '70%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  saveIcon: {
    position: 'absolute',
    fontSize: '20px',
    cursor: 'pointer',
    top: '0',
    right: '20px',
  },
  saveButton: {
    color: '#fff',
    border: '1px solid #A7ACB1',
    cursor: 'pointer',
    fontFamily: typography.fonts.secondary,
    borderRadius: '6px',
    backgroundColor: theme.palette.primary.main,
    margin: '0px 30px 0px 0px',
    height: 'min-content',
  },
}));
