import axios from 'axios';
import { sortingOptionFornest, sortingOptions } from 'src/Services/Utils';
import store from '../../../Store';
import { baseUrl, baseUrlV2 } from '../../Constants';

export const login = (credentials, language) =>
  axios.post(`${baseUrl}/api/user/login`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      language,
    },
  });

export const register = (credentials) =>
  axios.post(`${baseUrl}/api/user/signup`, credentials, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const registerValidation = (credentials) =>
  axios.post(`${baseUrl}/api/user/signup-validation`, credentials, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getRole = () => {
  const { role } = store.store.getState().role;
  return role;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const loggedUser = (userRole) =>
  axios.get(`${baseUrl}/api/user/me/${userRole}`, getHeaders());

export const changeUsername = (credentials) =>
  axios.patch(`${baseUrl}/api/user/change-username`, credentials, getHeaders());

export const changeUserPassword = (credentials) =>
  axios.patch(`${baseUrl}/api/user/change-password`, credentials, getHeaders());

export const forgotPassword = (credentials, language) =>
  axios.post(`${baseUrl}/api/user/forgotPassword`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      language,
    },
  });

export const switchProfile = (mode) =>
  axios.post(`${baseUrl}/api/user/switch-mode`, mode, getHeaders());

export const passwordReset = ({ values, id, token, language }) =>
  axios.patch(`${baseUrl}/api/user/password-reset/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      language,
    },
  });

export const resetPassword = (credentials) =>
  axios.patch(`${baseUrl}/api/user/change-username`, credentials, getHeaders());

export const updateUser = (credentials) =>
  axios.put(`${baseUrl}/api/user/me`, credentials, getHeaders());

export const userListing = () => axios.get(`${baseUrl}/api/user/list`, getHeaders());

export const createParticipant = (credentials) =>
  axios.post(`${baseUrl}/api/user/createParticipant`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const deleteUser = (id) => axios.delete(`${baseUrl}/api/user/${id}`, getHeaders());

export const getUser = (id, role) =>
  axios.get(`${baseUrl}/api/user/${id}/${role}`, getHeaders());

export const updateUserById = (id, data) =>
  axios.put(`${baseUrl}/api/user/${id}`, data, getHeaders());

export const updateUserLanguage = (id, lang) =>
  axios.patch(`${baseUrl}/api/user/language/${id}`, lang, getHeaders());

export const blockUser = (id, credentials) =>
  axios.patch(`${baseUrl}/api/user/${id}`, credentials, getHeaders());

export const userListingSort = ({
  role = 'superadmin',
  sortBy = 'firstName',
  sort = 'asc',
  search = '',
  page = 0,
  pageSize = 10,
  isPublic = false,
  isAllUsers = false,
} = {}) => {
  const params = new URLSearchParams({
    sortBy: encodeURIComponent(sortBy),
    sort: encodeURIComponent(sort),
    search: encodeURIComponent(search),
    page: encodeURIComponent(page + 1),
    limit: encodeURIComponent(pageSize),
    isPublic: encodeURIComponent(isPublic),
    isAllUsers: encodeURIComponent(isAllUsers),
  });
  const url = `${baseUrl}/api/user/list/${role}?${params.toString()}`;
  return axios.get(url, getHeaders());
};

export const listingAllUser = ({ search = '' }) =>
  axios.get(`${baseUrl}/api/user/all-user/?search=${search}`, getHeaders());

export const userOverview = ({ role = 'superadmin' }) =>
  axios.get(`${baseUrl}/api/user/latest-users/${role}`, getHeaders());

export const organizerOverview = () =>
  axios.get(`${baseUrl}/api/user/overview`, getHeaders());

export const userOverviewListing = ({
  userRole = 'superadmin',
  role = 'organizer',
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(
    `${baseUrl}/api/user/latest-users/${userRole}?role=${role}&${options}`,
    getHeaders(),
  );
};
// upcoming and past events
export const upcomingOverview = ({ role = 'superadmin', pageSize = 6, page = 1 }) =>
  axios.get(
    `${baseUrl}/api/event/upcoming/${role}?pageSize=${pageSize}&page=${page}`,
    getHeaders(),
  );

export const pastOverview = ({ role = 'superadmin', pageSize = 6, page = 1 }) =>
  axios.get(
    `${baseUrl}/api/event/past/${role}?pageSize=${pageSize}&page=${page}`,
    getHeaders(),
  );

export const logoutUser = (id) =>
  axios.post(`${baseUrl}/api/user/logout/${id}`, {}, getHeaders());
export const getVersionDetails = () =>
  axios.get(`${baseUrl}/api/setting/get`, getHeaders());
export const updateVersion = (data) =>
  axios.post(`${baseUrl}/api/setting/update`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer${getToken()}`,
    },
  });
export const getVersionPdf = () =>
  axios.get(`${baseUrl}/api/setting/pdf`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getEventsForAutoSelect = (role) =>
  axios.get(`${baseUrl}/api/event/auto-select/${role}`, getHeaders());

export const inviteAllParticipants = (data, role) =>
  axios.post(`${baseUrl}/api/event/invite-all/${role}`, data, getHeaders());

export const importParticipant = (eventId, formData) =>
  axios.post(`${baseUrl}/api/rally/csv-import-users/${eventId}/event`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const TablereportListing = ({
  sortBy = 'createdAt',
  sort = 'ASC',
  page = 0,
  pageSize = 10,
  search = '',
}) => {
  const options = sortingOptionFornest({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
  });
  return axios.get(`${baseUrlV2}/api/v2/table-report?&${options}`, getHeaders());
};

export const deleteTableReports = (id) =>
  axios.delete(`${baseUrlV2}/api/v2/table-report/${id}`, getHeaders());
