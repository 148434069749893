import './style.css';
import AddParticipantFormWrapper from './AddParticipantFormWrapper';
import AddExistingParticipant from './AddExistingParticipant';
import AddNewParticipant from './AddNewParticipant';

function AddEventParticipantsPopup({
  participants = false,
  details,
  reload,
  rally = '',
  setOpenPopup,
}) {
  const closeHandel = () => {
    setOpenPopup(false);
  };
  return (
    <AddParticipantFormWrapper
      participants={participants}
      details={details}
      reload={reload}
      onClose={closeHandel}
      rally={rally}
    >
      {(props) =>
        props.currentForm === 'existingParticipants' ? (
          <AddExistingParticipant
            selectedRallys={props.selectedRallys}
            participants={props.participants}
            eventId={props.details}
            rallyId={props.rally}
            reload={props.reload}
            onClose={props.onClose}
            rallyOptions={props.rallyOptions}
            handleChanges={props.handleChanges}
            participantList={props.participantList}
          />
        ) : (
          <AddNewParticipant
            selectedRallys={props.selectedRallys}
            participants={props.participants}
            eventId={props.details}
            rallyId={props.rally}
            reload={props.reload}
            onClose={props.onClose}
            rallyOptions={props.rallyOptions}
            handleChanges={props.handleChanges}
          />
        )
      }
    </AddParticipantFormWrapper>
  );
}

export default AddEventParticipantsPopup;
