import React, { useContext } from 'react';
import { Field } from 'formik';
import FormError from 'src/Components/FormError/FormError';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FieldLabel from './Fields/FieldLabel';
import fieldStyles from './FieldStyle';
import HintField from './Fields/HintField';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetTextField({
  isEditable = false,
  disabled = true,
  onChange,
  error,
  value,
  fieldData,
  mode,
  aditionalProps,
}: FormFieldType) {
  const classes = fieldStyles();
  const { isPreviewDisabled, onHandleChange, findTextFields } = useContext(
    ParticipantFormPreviewContext,
  );

  const { label, type, hint, styles, index, _id } = fieldData;
  const isPreviewMode = mode === 'preview';
  const readOnlyPreview = mode === 'readOnlyPreview';
  const isNotEditable = !isEditable;

  let fieldValue = aditionalProps.values;
  if (isPreviewMode) {
    if (findTextFields) {
      const result = findTextFields(index);
      if (result !== undefined) fieldValue = result;
    } else if (value !== undefined) {
      fieldValue = value;
    }
  }

  const handleChange = (event) => {
    if (isPreviewMode) {
      onHandleChange({ event, index, type, id: _id });
    } else {
      onChange(event);
    }
  };

  return (
    <>
      <FieldLabel name={label} styles={styles} />
      {isNotEditable ? (
        <Field
          className={classes.input}
          text={label}
          value={fieldValue}
          name={label}
          readOnly
        />
      ) : (
        <>
          <Field
            className={classes.input}
            text={label}
            type={type}
            name={label}
            value={fieldValue}
            disabled={isPreviewMode || readOnlyPreview ? isPreviewDisabled : disabled}
            onChange={handleChange}
          />
          <FormError error={error} />
        </>
      )}
      {hint && <HintField hint={hint} />}
    </>
  );
}

export default GetTextField;
