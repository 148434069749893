import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import typography from 'src/Theme/typography';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    padding: theme.spacing(2, 4, 2),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
}));

export default function Popup({ title, children }: any) {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = React.useState(false);

  // const handleOpenPopup = () => {
  //   setOpenPopup(true);
  // };

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <div>
      {/* <button type="button" onClick={handleOpenPopup}>
        react-transition-group
  </button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPopup}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPopup}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className={classes.title}>
              {title}
            </h2>
            <p id="transition-modal-description">{children}</p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
