import { useContext } from 'react';
import { Field } from 'formik';
import FormError from 'src/Components/FormError/FormError';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FieldLabel from './Fields/FieldLabel';
import fieldStyles from './FieldStyle';
import HintField from './Fields/HintField';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';
import { getCheckBoxValue } from './util';

function GetCheckboxField({
  isEditable = false,
  disabled = true,
  onChange,
  error,
  value,
  fieldData,
  checked,
  mode,
  aditionalProps,
}: FormFieldType & { checked?: any }) {
  const { isPreviewDisabled, checkBoxValue, onHandleChangeCheckbox } = useContext(
    ParticipantFormPreviewContext,
  );
  const classes = fieldStyles();
  const { label, type, hint, styles, index, id, _id } = fieldData;

  const renderCheckbox = () => (
    <Field
      className={classes.checkboxInput}
      text={label}
      type={type}
      name={label}
      value={value}
      checked={
        mode === 'preview'
          ? checkBoxValue && checkBoxValue(index, id, fieldData)
          : checked || getCheckBoxValue(fieldData)
      }
      disabled={mode === 'preview' ? isPreviewDisabled : disabled}
      onChange={mode === 'preview' ? onHandleChangeCheckbox(index, type, _id) : onChange}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex' }}>
        <FieldLabel name={label} styles={styles} />
        {!isEditable ? (
          <Field
            className={classes.checkboxInput}
            text={label}
            type={type}
            name={label}
            checked={aditionalProps?.values === 'checked' ? 'checked' : ''}
            readOnly
          />
        ) : (
          <>
            {renderCheckbox()}
            <FormError error={error} />
          </>
        )}
      </div>
      <HintField hint={hint} />
    </div>
  );
}

export default GetCheckboxField;
