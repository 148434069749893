/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Formik, Form, Field } from 'formik';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// react grid layout for dragging and resizing
import { Responsive, WidthProvider } from 'react-grid-layout';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';
import clsx from 'clsx';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { renderFields } from 'src/Libs/DynamicForm/config/renderDynamicFormFields';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';

import {
  getEventsByRole,
  getAllFormsByEventId,
  createNewEventForm,
  getFormsDataById,
  getEvent,
} from '../../../Services/Auth/Actions/EventActions';
import theme from '../../../Theme';
import { SubTitle, FormLabel, FormButton } from '../../../Components/Utils';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { formLayout } from './utils';
import {
  GRID_LAYOUT_COLS,
  GRID_LAYOUT_ROWHEIGHT,
  GRID_LAYOUT_WIDTH,
  GRID_UNIT_WIDTH,
  fieldWrapperExtraStyles,
} from './components/DynamicForm/util';
import EventFormPreview from './EventFormPreview';
import { FormFieldAutoPickType } from 'src/Types/FormFieldAutoPickType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { getRally } from 'src/Services/Auth/Actions/RallyActions';
import { GetRallyTypes } from 'src/Services/Auth/Actions/RallyTypeActions';
import {
  fillAutoPickedFormFieldValues,
  loadAutoPickValues,
} from './AutoPicKFormFieldValues';
import { filterAddedFieldsByParentWidgetIndex } from 'src/Components/DynamicTable/utils';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import CloneFormPreviewContext from 'src/Libs/DynamicForm/context/CloneContext';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 500,
    backgroundColor: '#fff',
    fontFamily: typography.fonts.secondary,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
    height: '300px',
    margin: '0 auto',
    textAlign: 'center',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
  },

  link: {
    color: '#007AFF',
    textDecoration: 'none',
    fontWeight: 600,
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  boxType: {
    // padding: "20px",
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    // boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '900px',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
    '@media all and (min-width: 1440px)': {
      height: '80vh !important',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
    },
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  btnSub: {
    display: 'flex',
    marginTop: '6px',
    width: '100%',
    justifyContent: 'center',
    boxShadow: 'none',
  },
  cancel: {
    display: 'flex',
    marginTop: '6px',
    background: '#fff',
    color: '#E40909',
    border: '1px solid #E40909',
    width: '100%',
    justifyContent: 'center',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  icon: {
    fontSize: '35px',
    marginRight: '10px ',
  },
  leftWrap: {
    display: 'flex',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      justifyContent: 'flex-start',
      width: '100%',
    },
  },
  rightWrap: {
    display: 'flex',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
    },
  },
  customButton: {
    border: '2px solid #DFE0EB',
    color: '#333',
    minWidth: '150px',
    boxShadow: 'none',
    hover: {
      background: 'none',
      width: '100%',
    },
  },
  subwrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  forms: {
    marginTop: '10px',
    width: formLayout.width,
    height: '400px',
    border: '1px solid #d4d5de',
    borderRadius: '5px',
    // boxShadow: "10px 10px 15px #ddd",
    overflow: 'auto',
    overflowX: 'hidden',
    padding: '30px',
    '@media all and (max-width: 1300px)': {
      marginTop: '20px',
      marginBottom: '20px',
      minWidth: '100%',
      width: formLayout.width,
    },
    '@media all and (max-width: 960px)': {
      marginTop: '10px',
      marginBottom: '20px',
      width: formLayout.width,
    },
    '&::-webkit-scrollbar': {
      width: 3,
      marginRight: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: '1px solid slategrey',
      backgroundColor: '#bf2331',
      borderRadius: '3px',
      border: 'none',
      right: '10px',
    },
    '@media all and (min-width: 1440px)': {
      height: '54vh',
    },
    '@media all and (min-width: 2000px)': {
      height: '60vh',
    },
    '@media all and (min-height: 805px)': {
      height: '60vh',
    },
  },
  subforms: {
    width: '200px',
    height: '150px',
    border: '1px solid #d4d5de',
    borderRadius: '15px',
    boxShadow: '10px 10px 15px #ddd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      marginTop: '10px',
      marginBottom: '20px',
    },
  },
  mainWrap: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: "25px",
    alignItems: 'center',
    padding: '30px',
    justifyContent: 'space-between',
    '@media all and (max-width: 1300px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  mainSub: {
    '@media all and (max-width: 1300px)': {
      // flexDirection: "column",
      width: 'auto%',
    },
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  inputlabel: {
    // marginLeft: "13px",
    cursor: 'pointer',
    width: '100%',
    lineHeight: 'normal',
    padding: '0px !important',
  },
  selected: {
    background: '#DFE0EB7D',
    marginLeft: '13px',
    border: '1px solid #DFE0EB',
    transition: 'all .2s ease-in-out',
    '&:input': {
      border: '1px solid #DFE0EB',
    },
  },
  arrows: {
    height: '50px',
    width: '50px',
    transition: 'all .3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.21)',
      color: '#342635',
    },
    '&:active': {
      transform: 'scale(0.98)',
    },
  },
  inputName: {
    width: '94%',
    border: '1.5px solid #DFE0EB',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    color: 'red',
    '&:focus': {
      outline: 'none !important',
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#333',
    },
  },
  inputDiv: {
    marginTop: '20px',
  },
  typeSelect: {
    background: '#2148C0',
    color: '#fff',
  },
  inputlabel1: {
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    color: colors.primary.color,
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'underline',
    borderRight: '1px solid #DFE0EB',
    paddingRight: '10px',
    height: '30px',
    fontFamily: typography.fonts.secondary,
    float: 'right',
  },
  formSelectArea: {
    display: 'flex',
    alignItems: 'center',
    '@media all and (max-width: 1300px)': {
      flexDirection: 'column',
    },
  },
  select: {
    display: 'block',
    marginBottom: '0px',
    '@media all and (max-width: 1300px)': {
      marginBottom: '10px',
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  hintText: {
    textAlign: 'left',
    marginLeft: '5px',
    fontSize: '12px',
    color: '#6c757d',
  },
  formHeaderHorizontalLine: {
    border: '.5px solid #03071e',
    backgroundColor: '#03071e',
  },
  paragraph2: {
    margin: 0,
    overflowWrap: 'anywhere',
    textAlign: 'left',
  },
});

function previewForm({ eventId, formId, setOpenPopup }) {
  return (
    <EventFormPreview eventId={eventId} formId={formId} setOpenPopup={setOpenPopup} />
  );
}

function CloneEventForm() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [formFields, setFormFields] = React.useState<Array<any>>([]);
  const [formArray, setFormArray] = React.useState([]);
  const [eventList, setEventList] = useState([]);
  const [currentEventId, setCurrentEventId] = useState('');
  const [formDataArray, setFormDataArray] = React.useState<Array<FormFieldEditType>>([]);
  const [formId, setFormId] = useState('');
  const [formError, setFormError] = useState(false);
  const [formFeildError, setFormFeildError] = useState(false);
  const [eventFeildError, setEventFeildError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [autoPickValues, setAutoPickValues] = useState<FormFieldAutoPickType>();
  const { eventId }: { eventId: ObjectIdType } = useParams();
  const location = useLocation<any>();
  const [formName, setFormName] = useState(location.state?.formName);
  const rallyId = location.state?.rallyID;
  const modal = useModalDialog();
  const previewFormSetOpen = useMemo(() => modal(previewForm, 'preview'), []);
  const role: string = useSelector((store: RootState) => store.role.role);
  const collectAutoFillValues = async () => {
    const eventResponse = await getEvent(eventId);
    const eventData = eventResponse.data.event;
    if (rallyId?.length === 1) {
      const rallyResponse = await getRally(rallyId);
      const rallyData = rallyResponse.data.Rally;
      const rallyTypesResponse = await GetRallyTypes({ sortBy: 'type', sort: 'asc' });
      const rallyTypesData = rallyTypesResponse.data.rallyType.rallyTypes;
      setAutoPickValues(
        loadAutoPickValues(formName, eventData, rallyData, rallyTypesData),
      );
    } else {
      setAutoPickValues(loadAutoPickValues(formName, eventData));
    }
  };

  const showFormPreview = (eventid, formid) => {
    previewFormSetOpen(true, {
      eventId: eventid,
      formId: formid,
      overrideBody: true,
    });
  };

  useEffect(() => {
    if (eventId) {
      collectAutoFillValues();
    }
  }, [eventId]);

  useEffect(() => {
    if (role) {
      getEventsByRole({ role })
        .then((response) => {
          const { data } = response;
          setEventList(data);
        })
        .catch((err) => {
          // @ts-ignore
          console.log(err);
        });
    }
  }, [role]);

  useEffect(() => {
    if (currentEventId)
      getAllFormsByEventId(currentEventId)
        .then((res) => {
          setFormArray(res.data.forms);
          setFormDataArray([]);
        })
        .catch((err) => {
          setFormArray([]);
          setFormDataArray([]);
        });
  }, [currentEventId]);

  useEffect(() => {
    if (currentEventId && formId)
      getFormsDataById(currentEventId, formId)
        .then((res) => {
          const newArray = res.data.formFields.filter((cv) => {
            const foundCoordinates = res.data.coordinates.find((e) => cv.index === e.i);
            if (foundCoordinates) {
              cv.coordinates = foundCoordinates;
              return true;
            } else {
              cv.coordinates = cv.coordinate;
              return true;
            }
          });
          setFormDataArray(
            autoPickValues
              ? fillAutoPickedFormFieldValues(newArray, autoPickValues)
              : newArray,
          );
          setCoordinates(res.data.coordinates);
          setFormFeildError(false);
        })
        .catch((err) => {
          setFormDataArray([]);
        });
  }, [formId]);

  const handleChange = (e) => {
    setCurrentEventId(e.target.value);
    setEventFeildError(false);
  };
  const handleChangeForm = (e) => {
    setFormId(e.target.value);
    const forms: Array<any> = formArray.filter(
      (item: any) => item._id === e.target.value,
    );
    if (forms.length !== 0) {
      setFormFields(
        autoPickValues
          ? fillAutoPickedFormFieldValues(forms[0].formFields, autoPickValues)
          : forms[0].formFields,
      );
    } else {
      setFormFields([]);
    }
  };
  const handelChangeName = (e) => {
    setFormName(e.target.value);
    if (e.target.value.length === 0) {
      setFormError(!formError);
    }
  };
  const cloneForm = (e) => {
    e.preventDefault();
    if (formFields.length === 0) {
      setFormFeildError(true);
      return;
    }
    if (!currentEventId) {
      setEventFeildError(true);
      return;
    }
    if (Object.keys(coordinates).length === 0) return;
    setSubmitting(true);
    const data: any = {
      formName,
      formFields,
      eventId,
      coordinates,
      rallyId,
    };
    createNewEventForm(data)
      .then((response) => {
        toast.success(t('forms.Form cloned successfully'), {
          position: 'top-center',
          autoClose: 2000,
        });
        setSubmitting(false);
        showFormPreview(response.data.eventId, response.data._id);
        history.push({
          pathname: `/events/details/${eventId}/forms`,
          state: { rallyID: rallyId, formName },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: 'top-center',
          autoClose: 5000,
        });
        setSubmitting(false);
      });
  };

  function Forms() {
    return (
      <>
        {formDataArray.length === 0 ? (
          <>{t('forms.Form Preview')}</>
        ) : (
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              <ResponsiveGridLayout
                className="layout"
                rowHeight={GRID_LAYOUT_ROWHEIGHT}
                margin={[0, 0]}
                containerPadding={[0, 0]}
                width={GRID_UNIT_WIDTH}
                breakpoints={{ md: GRID_LAYOUT_WIDTH }}
                cols={{ md: GRID_LAYOUT_COLS }}
                isDraggable={false}
                isResizable={false}
                verticalCompact={false}
                allowOverlap
              >
                {filterAddedFieldsByParentWidgetIndex(formDataArray).map(
                  (data: any, i) => (
                    <div
                      data-grid={{
                        x: data.coordinates.x,
                        y: data.coordinates.y,
                        w: data.coordinates.w,
                        h: data.coordinates.h,
                        minW: 2,
                        minH: 2,
                      }}
                      key={data.index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        width: '100%',
                        ...fieldWrapperExtraStyles(data.styles),
                      }}
                    >
                      <Grid
                        item
                        className={clsx([
                          `grid-item-${data.type}-custom-class ${classes.inputlabel}`,
                        ])}
                      >
                        {renderFields(data, 'readOnlyPreview')}
                      </Grid>
                    </div>
                  ),
                )}
              </ResponsiveGridLayout>
            </Form>
          </Formik>
        )}
      </>
    );
  }
  const contextValue = useMemo(
    () => ({
      cloneAdded: formDataArray,
    }),
    [formDataArray],
  );
  console.log(formDataArray, 'formDataArray');
  return (
    <CloneFormPreviewContext.Provider value={contextValue}>
      <div>
        <div className={classes.boxType}>
          <div className={classes.wrapper}>
            <div className={classes.leftWrap}>
              <div
                onClick={() =>
                  history.push({
                    pathname: `/events/details/${eventId}/forms`,
                    state: { rallyID: rallyId, formName },
                  })
                }
                style={{ cursor: 'pointer' }}
              >
                <Icon icon="mdi:keyboard-backspace" className={classes.icon} />
              </div>

              <SubTitle title={t('formFields.Clone Form')} />
            </div>
          </div>
          <div className={classes.mainWrap}>
            <div
              style={{ width: '100%', marginTop: '-30px' }}
              className={classes.mainSub}
            >
              <Formik initialValues={{}} onSubmit={() => {}}>
                <Form className={classes.formSelectArea}>
                  {/* <Box > */}
                  <Grid container spacing={3}>
                    <Grid item lg={10} xs={12}>
                      <FormLabel name={t('events.Select event Name')} require />
                      <select
                        name="eventName"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className={classes.select}
                      >
                        <option value="" label={t('forms.Select')} />
                        {eventList.map((item: any) => (
                          <option value={item._id} label={`${item.eventName}`} />
                        ))}
                      </select>
                      {eventFeildError ? (
                        <div
                          style={{
                            color: 'red',
                            textAlign: 'left',
                            marginTop: '5px',
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={10} xs={12}>
                      <FormLabel name={t('events.Source form name')} require />
                      <select
                        name="eventName"
                        onChange={(e) => {
                          handleChangeForm(e);
                        }}
                        className={classes.select}
                        disabled={formArray.length === 0}
                      >
                        {formArray.length > 0 ? (
                          <option value="1" label={t('forms.Select')} />
                        ) : (
                          <option
                            value="1"
                            label={t('General settings.No Forms Available')}
                          />
                        )}
                        {formArray.map((item: any) => (
                          <option value={item._id} label={`${item.formName}`} />
                        ))}
                      </select>
                      {formFeildError ? (
                        <div
                          style={{
                            color: 'red',
                            textAlign: 'left',
                            marginTop: '5px',
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={10} xs={12}>
                      <FormLabel name={t('events.New form name')} require />
                      <Field
                        className={classes.input}
                        text="formName"
                        type="text"
                        name="Form Name"
                        value={formName}
                        // disabled
                        onChange={(e) => {
                          setFormError(false);
                          handelChangeName(e);
                        }}
                      />
                      {formError ? (
                        <div
                          style={{
                            color: 'red',
                            textAlign: 'left',
                            marginTop: '5px',
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} style={{ marginTop: '30px' }}>
                    <Grid item lg={5} xs={12}>
                      {!isSubmitting ? (
                        <FormButton
                          className={classes.btnSub}
                          style={{ width: '100% !important' }}
                          name={t('forms.Clone')}
                          type="submit"
                          onClick={cloneForm}
                        />
                      ) : (
                        <Loader
                          type="ThreeDots"
                          color={theme.palette.primary.main}
                          height={50}
                          width={50}
                          // className={classes.loader}
                        />
                      )}
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <Link
                        to={`/events/details/${eventId}/forms`}
                        style={{ textDecoration: 'none' }}
                      >
                        <FormButton className={classes.cancel} name={t('popup.Cancel')} />
                      </Link>
                    </Grid>
                  </Grid>
                  {/* </Box> */}
                </Form>
              </Formik>
            </div>
            <div className={classes.forms}>{Forms()}</div>
          </div>
        </div>
      </div>
    </CloneFormPreviewContext.Provider>
  );
}

export default CloneEventForm;
