/* eslint-disable import/no-cycle */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TableContent from 'src/Components/Table/TableContent';
import {
  getAllFormsByRallyIdAndUserId,
  getFormsByEventId,
} from 'src/Services/Auth/Actions/EventActions';
import { getToken, getUser } from 'src/Services/Auth/Actions/UserActions';
import { RootState } from 'src/Store';
import { ParticipantFormFieldType } from 'src/Types/FormFieldAutoPickType';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { SetStateSetterType } from 'src/Types/UseFormTypes';
import useStyles from './EventFormsListingStyle';
import { LoadAutoPickFormFieldValuesForParticipant } from '../Rally/AutoPicKFormFieldValues';
import RallyFormsListing from './RallyFormsListing';
import { groupDataByRallyId, joinClassNames } from './utils';

type FormData = {
  _id: string;
  data: {
    values: string;
    formFieldId: string;
    type: string;
    id: string;
  }[];
  isSubmitted: boolean;
  formId: string;
  eventId: string;
  rallyId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  rallyName: string;
};

export type FormList = {
  _id: string;
  eventId: string;
  formData: FormData[];
  formName: string;
  isSubmitted: boolean;
  rallyId: string;
  rallyName: string;
};

export type GroupedDataArray = [string, FormList[]];

function EventFormsListing({
  eventId,
  userId,
  actionButtons,
  listProp = null,
  setAllList,
  status = false,
  rallyForm = false,
}: {
  eventId: string;
  userId: string;
  actionButtons: Function;
  listProp?: GroupedDataArray[] | null;
  setAllList?: SetStateSetterType<FormList[]> | undefined;
  status?: boolean;
  rallyForm?: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<GroupedDataArray[]>([]);
  const role = useSelector((store: RootState) => store.role.role);
  const [autoPickValues, setAutoPickValues] = useState<ParticipantFormFieldType>();
  const AuthUser: EnumUserRoles[] = [
    EnumUserRoles.superadmin,
    EnumUserRoles.organizer,
    EnumUserRoles.eventOrganizer,
  ];

  useEffect(() => {
    if (AuthUser.includes(role) && listProp) {
      setList([...listProp]);
    }
  }, [listProp]);

  const errorList = () => {
    setLoading(false);
    setList([]);
    if (setAllList) setAllList([]);
  };

  const listForms = async () => {
    setLoading(true);
    try {
      const user = await getUser(userId, 'participant');
      if (user) {
        setAutoPickValues(LoadAutoPickFormFieldValuesForParticipant(user.data.user));
      }

      if (rallyForm) {
        const formsData1 = await getFormsByEventId(eventId);
        const { data } = formsData1.data;
        setList(groupDataByRallyId(data));
        if (setAllList) {
          setAllList(data);
        }
        setLoading(false);
      } else {
        const allForm = await getAllFormsByRallyIdAndUserId(eventId, userId);
        if (allForm) {
          const { data } = allForm.data;
          setList(groupDataByRallyId(data));
          if (setAllList) {
            setAllList(data);
          }
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      errorList();
    }
  };

  const reload = () => {
    if (getToken()) {
      listForms();
    } else {
      getFormsByEventId(eventId)
        .then((response) => {
          const { data } = response.data;
          setList(groupDataByRallyId(data));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    reload();
  }, []);

  return (
    <div className={classes.viewForm}>
      <div className={classes.viewFormWrapper}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={joinClassNames(classes.thead, classes.theadLeft)}>
                  {t('forms.Rally Forms')}
                </TableCell>
                {status && (
                  <TableCell className={classes.thead}>{t('forms.Status')}</TableCell>
                )}
                <TableCell className={`${classes.thead} ${classes.theadEnd}`}>
                  {t('forms.Actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody />
          </Table>
        </TableContainer>
        <TableContent
          loading={loading}
          list={list}
          emptyText={t('others.No items found')}
        >
          {list &&
            list.map(([rallyId, objectsWithForms]) => (
              <div key={`item${rallyId}`} className={classes.form}>
                <h3 className={classes.rallyName}>{objectsWithForms[0].rallyName}</h3>
                <RallyFormsListing
                  forms={objectsWithForms}
                  eventId={eventId}
                  rallyId={rallyId}
                  status={status}
                  listForms={listForms}
                  actionButtons={actionButtons}
                  autoPickValues={autoPickValues}
                />
              </div>
            ))}
        </TableContent>
      </div>
    </div>
  );
}

export default EventFormsListing;
