import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';

import translationEN from './locales/en';
import translationFR from './locales/fr';
import translationDU from './locales/du';

// the translations
// const resources = {
//  en: {
//    translation: translationEN
//  }
// };
// console.log(translationEN,"translationEN")
i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources: {
        en: {
          translation: translationEN,
        },
        fr: {
          translation: translationFR,
        },
        du: {
          translation: translationDU,
        },
      },
      lng: 'en',
      fallbackLng: 'en',
      ns: ['translation'],
      defaultNS: 'translation',

      keySeparator: '.', // we do not use keys in form messages.welcome
      debug: false,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    // eslint-disable-next-line consistent-return
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      // t('key'); // -> same as i18next.t
    },
  );

export default i18n;
