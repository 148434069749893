import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  errorClass: {
    color: 'red',
    fontSize: '12px',
    marginBottom: '10px',
    textAlign: 'left',
    fontWeight: 500,
  },
}));

function FormError({ error }) {
  const classes = useStyles();
  return error ? <div className={classes.errorClass}>{error}</div> : null;
}

export default FormError;
