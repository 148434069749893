/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import DynamicTable from 'src/Components/DynamicTable';
import useTableField from './hooks/useTableField';
import FieldLabel from '../Fields/FieldLabel';
import fieldStyles from '../FieldStyle';
import tableStyle from './tableStyle';

function GetTableField({ fieldData, aditionalProps, mode }: FormFieldType) {
  // showTable = true,
  // disableResizeHandler = true,
  const { label, styles } = fieldData;
  const { tableData, setEditData, submitEdit } = useContext(DynamicFormContext);
  const tableCols = fieldData?.tableCols ? fieldData?.tableCols : tableData.cols;
  const tableRows = fieldData?.tableRows ? fieldData?.tableRows : tableData.rows;
  const classes = fieldStyles();
  const tableClasses = tableStyle();
  useTableField({
    cols: tableCols,
    rows: tableRows,
    editData: fieldData,
    // @ts-ignore
    setEditData,
    // @ts-ignore
    submitEdit,
  });

  return (
    <div style={{ height: '100%' }}>
      {aditionalProps?.showTable ? (
        <div className={tableClasses.tableWrapper}>
          <DynamicTable tableIndex={fieldData.index} tableMode={mode || ''} />
        </div>
      ) : (
        <FieldLabel
          name={label}
          styles={styles}
          deafultClassName={classes.heading}
          type="table"
        />
      )}
    </div>
  );
}

export default GetTableField;
