import { ComponentType, FC, useMemo } from 'react';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import Button, { ButtonProps } from './Button';

export type PopupButtonProps = ButtonProps & {
  component: ComponentType<any>;
  modalName: string;
  sampleCsvHandleClick?;
  participant?;
};

const PopupButton: FC<PopupButtonProps> = ({
  onClick,
  label,
  component,
  modalName,
  loading,
  sampleCsvHandleClick,
  participant,
}) => {
  const modal = useModalDialog();
  const modalSetOpen = useMemo(() => modal(component, modalName), []);
  return (
    <Button
      loading={loading}
      onClick={() => modalSetOpen(true, { onClick, sampleCsvHandleClick, participant })}
      label={label}
    />
  );
};

export default PopupButton;
