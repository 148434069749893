/* eslint-disable radix */
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import { Grid } from '@material-ui/core';
import FieldLabel from 'src/Libs/DynamicForm/components/FormField/Fields/FieldLabel';
import { FormButton, FormLabel } from '../../../../../../Components/Utils';
import useStyles from '../../../newEventFormStyle';
import TextEditor from '../TextEditor/TextEditor';

function EditPopup({
  submitEdit,
  editData,
  added,
  tableData,
  setTableData,
  setOpenPopup,
}: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState(editData);

  const dateOptions = [
    {
      label: 'select',
    },
    {
      label: 'today',
    },
    {
      label: 'tomorrow',
    },
  ];

  const totalCountFieldsArray = added.filter(
    // eslint-disable-next-line no-shadow
    (editData) => editData.type === 'totalField',
  );
  if (!data) {
    return null;
  }

  // eslint-disable-next-line consistent-return
  function showFormFields() {
    if (data.category === 'Other') {
      switch (data.type) {
        case 'textarea':
          return (
            <>
              <FormLabel name={t('Default Values')} className={classes.inputlabel} />
              <Field
                className={classes.popupInput}
                text="defaultValue"
                type="text"
                name="defaultValue"
                value={data ? data.values : ''}
                onChange={(event) => {
                  // changeDefaultValue
                  setData({
                    ...editData,
                    values: event.target.value < 0 ? [0] : [event.target.value],
                  });
                }}
              />
            </>
          );
        case 'dropdown':
          return (
            <>
              <FormLabel name={t('Default Dropdown')} />
              <select
                name="defaultdropdown"
                value={data.defaultDropDownValue}
                defaultValue={data.defaultDropDownValue}
                onChange={(event) => {
                  // changeDefaultValue
                  setData({
                    ...data,
                    defaultDropDownValue: event.target.value,
                  });
                }}
              >
                <option value="Select" label={t('forms.Select')} />
                {data.values.map((item: any) => (
                  <option value={item} label={`${item}`} />
                ))}
              </select>
            </>
          );
        case 'checkbox':
          return (
            <div className={classes.EditCheckbox}>
              <FieldLabel name="Default Value" className={classes.inputlabel} />
              <Field
                className={classes.checkboxInput}
                text={data.label}
                type={data.type}
                name={data.label}
                checked={data.values && data.values[0] === 'checked' ? 'checked' : ''}
                disabled={false}
                onChange={(event) => {
                  // changeDefaultValue
                  setData({
                    ...data,
                    values: event.target.checked === true ? ['checked'] : [''],
                  });
                }}
              />
            </div>
          );
        case 'date':
          return (
            <>
              <FormLabel name={t('Default Date')} />
              <select
                name="defaultDate"
                value={data ? data.values : ''}
                onChange={(event) => {
                  // changeDefaultValue
                  // setData({
                  //   ...editData,
                  //   values: event.target.value < 0 ? [0] : [event.target.value],
                  // });
                }}
              >
                {dateOptions.map((option) => (
                  <option value={option.label} label={option.label} />
                ))}
              </select>
            </>
          );
        case 'number':
          return (
            <>
              <FormLabel name={t('Default Values')} className={classes.inputlabel} />
              <Field
                className={classes.popupInput}
                text="defaultValue"
                type="number"
                min="0"
                name="defaultValue"
                value={data ? data.values : ''}
                onInput={(event) => {
                  // changeDefaultValue
                  setData({
                    ...data,
                    values: event.target.value < 0 ? [0] : [event.target.value],
                  });
                }}
              />

              {totalCountFieldsArray.map((d) => (
                <div className={classes.totalCount}>
                  <FormLabel name={d.label} className={classes.inputlabel} />
                  <div className={classes.totalCountCheck}>
                    <Field
                      className={classes.popupInput}
                      text="total"
                      type="checkbox"
                      name="total"
                      checked={data?.totalCount?.includes(d.index)}
                      onChange={(event) => {
                        // totalCountCheckbox(event,d.index)
                        let { totalCount = [] } = data;
                        if (event.target.checked === true) {
                          totalCount.push(d.index);
                        } else {
                          totalCount = totalCount.filter(
                            (index: string) => index !== d.index,
                          );
                        }
                        setData({
                          ...data,
                          totalCount,
                        });
                      }}
                      id={d.index}
                    />
                  </div>
                </div>
              ))}
            </>
          );
        default:
      }
    }
    return null;
  }

  const OnClose = () => {
    setOpenPopup(false);
  };

  return (
    <div
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          submitEdit(e);
        }
      }}
    >
      <div className={classes.wrapper3} />
      <h3 id="transition-modal-description" className={classes.desc}>
        {t(`events.Edit form fields`)}
      </h3>
      <TextEditor editData={data} setEditData={setData} tableData={tableData} />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Grid item lg={11} xs={11}>
          <>
            {data.type !== 'table' && (
              <>
                <FormLabel name={t('Label')} className={classes.inputlabel} />
                <Field
                  className={classes.popupInput}
                  text="label"
                  type="text"
                  name="label"
                  value={data ? data.label : ''}
                  onChange={(event) => {
                    // changeLabel
                    setData({ ...data, label: event.target.value });
                  }}
                />
              </>
            )}

            {showFormFields()}

            {!data.isMultiple && !data.isCloned && (
              <>
                <FormLabel name={t('Hint')} className={classes.inputlabel} />
                <Field
                  className={classes.popupInput}
                  text="hint"
                  type="text"
                  value={data ? data.hint : ''}
                  onChange={(event) => {
                    // changeHint
                    setData({ ...data, hint: event.target.value });
                  }}
                />
              </>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontSize: '15px',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h4 style={{ marginRight: '5px' }}>{t(`formFields.Category`)} -</h4>
                    <h4 style={{ color: '#495057' }}>{data.category}</h4>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h4 style={{ marginRight: '5px', marginTop: '0px' }}>
                      {t(`formFields.Group`)} -
                    </h4>
                    <h4 style={{ color: '#495057', marginTop: '0px' }}>
                      {data.group ? data.fieldGroup?.groupName : 'No group selected'}
                    </h4>
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h4 style={{ marginRight: '5px' }}>{t(`forms.Type`)} -</h4>
                  <h4 style={{ color: '#495057' }}>{data.type}</h4>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h4 style={{ marginRight: '5px' }}>Id -</h4>
                <h4 style={{ color: '#495057' }}>{data.key}</h4>
              </div>
            </div>
          </>
        </Grid>
      </Formik>
      <div className={classes.sign}>
        <FormButton
          className={classes.btn}
          name={t(`others.Change`)}
          type="submit"
          onClick={() => {
            submitEdit({
              ...data,
              label: data.label.trim(),
            });
            OnClose();
          }}
        />
        <FormButton
          className={classes.btn}
          name={t('popup.Cancel')}
          type="submit"
          onClick={OnClose}
        />
      </div>
    </div>
  );
}

export default EditPopup;
