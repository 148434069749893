import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  lang: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '26px',
    height: '30px',
    marginRight: '8px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    color: colors.primary.color,
    fontSize: '14px',
    fontWeight: 500,
    // textDecoration: 'underline',
    borderRight: '1px solid #DFE0EB',
    height: '35px',
    fontFamily: typography.fonts.secondary,
    outline: 'none',
  },
  selectFields: {
    '&__control': {},

    '&__value-container': {
      padding: '1px',
      cursor: 'pointer',
      width: '110px',
    },
    '&__indicators': {
      display: 'none',
    },
  },
}));
