/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, Fragment } from 'react';
import { Icon } from '@iconify/react';
import Moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@material-ui/core';
import { RootState } from 'src/Store';
import { pastOverview, upcomingOverview } from '../../Services/Auth/Actions/UserActions';
import { CircularLoader } from '../Utils';
import useStyles from './style';
import CAR1_IMAGE from './compregLogo.png';
import InfiniteScroll from '../Utils/InfiniteScroll';

function EventListing({ eventType }) {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [eventTotalCount, setEventTotalCount] = useState(0);
  const { t } = useTranslation();
  const role = useSelector((state: RootState) => state.role.role);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const fetchEvents = (page) => {
    let overviewFunction;

    if (eventType === 'upcoming') {
      overviewFunction = upcomingOverview;
    } else if (eventType === 'past') {
      overviewFunction = pastOverview;
    }

    if (overviewFunction) {
      return overviewFunction({ role, page })
        .then((res) => {
          setEvents(res.data.events);
          setEventTotalCount(res.data.count);
        })
        .catch(() => {
          setEvents([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchEvents(1);
  }, []);

  const eventStatus = (item) => {
    if (item.isCancelled) {
      return <p className={classes.red}>{t('events.Cancelled')}</p>;
    }
    if (eventType === 'upcoming') {
      return <p className={classes.green}>{t('events.Active')}</p>;
    }
    return <p className={classes.green}>{t('events.Completed')}</p>;
  };

  const rallyParticipantCount = (item) => (
    <div className={classes.iconCountContainer}>
      <div className={classes.iconCountWrapper}>
        <h3>{item.rallies.length}</h3>
        <Icon
          icon="clarity:flag-solid"
          className={classes.icon}
          onClick={() =>
            !item.isCancelled &&
            history.push({
              pathname: `events/details/${item._id}/rallies`,
              state: { showGoBack: true },
            })
          }
        />
      </div>
      <div className={classes.iconCountWrapper}>
        <h3>{item.participants}</h3>
        <Icon
          icon="akar-icons:people-group"
          className={classes.icon}
          onClick={() =>
            !item.isCancelled &&
            history.push({
              pathname: `events/details/${item._id}/participants`,
              state: { showGoBack: true },
            })
          }
        />
      </div>
    </div>
  );

  const eventRallyOrganizerDetails = (item) => {
    const detailLinks = item.isCancelled
      ? [
          {
            label: t('overview.Event Details'),
            style: { borderBottomLeftRadius: '15px' },
          },
          { label: t('overview.Rally Details') },
          {
            label: t('overview.Organiser Details'),
            style: { borderBottomRightRadius: '15px' },
          },
        ]
      : [
          {
            path: `events/details/${item._id}/details`,
            label: t('overview.Event Details'),
            style: { borderBottomLeftRadius: '15px' },
          },
          {
            path: `events/details/${item._id}/rallies`,
            label: t('overview.Rally Details'),
          },
          {
            path: `organizers/details/${item.organizer}/profile`,
            label: t('overview.Organiser Details'),
            style: { borderBottomRightRadius: '15px' },
          },
        ];

    return (
      <div className={classes.detailsContainer}>
        {detailLinks.map((link) => (
          <Fragment key={link}>
            {link.path ? (
              <Link to={link.path} style={{ textDecoration: 'none', color: '#fff' }}>
                <div className={classes.detailsWrapper} style={link.style}>
                  <h6>{link.label}</h6>
                </div>
              </Link>
            ) : (
              <div className={classes.detailsWrapper} style={link.style}>
                <h6>{link.label}</h6>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <div>
      <h3 className={classes.title}>
        {eventType === 'upcoming'
          ? t('overview.Upcoming Events')
          : t('overview.Past Events')}
      </h3>
      {!loading ? (
        <>
          {events?.length > 0 && role ? (
            <InfiniteScroll
              classes={classes.cardContainer}
              list={events}
              totalLength={eventTotalCount}
              fetchList={fetchEvents}
            >
              {events.map((item: any) => (
                <div className={classes.cardWrapper} key={item._id}>
                  <div className={classes.bodyWrapper}>
                    <div
                      className={classes.avatar}
                      style={{
                        background: `url(${
                          item.eventImage ? item.eventImage : CAR1_IMAGE
                        })`,
                      }}
                    />
                    <div className={classes.contentWrapper}>
                      <p className={classes.eventName}>{item.eventName}</p>
                      <p className={classes.dateWrapper}>
                        {Moment(item.startDateTime).format('DD.MM.YYYY')}-
                        {Moment(item.endDateTime).format('DD.MM.YYYY')}
                      </p>
                      {eventStatus(item)}
                      <p className={classes.dateWrapper}>{item.venue}</p>
                      {rallyParticipantCount(item)}
                    </div>
                  </div>
                  {eventRallyOrganizerDetails(item)}
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            <div className={classes.emptyMessage}>
              {eventType === 'upcoming'
                ? `--${t('overview.There are no events today')}--`
                : `--${t('overview.No events found')}--`}
            </div>
          )}
        </>
      ) : (
        <TableRow>
          <TableCell style={{ borderBottom: 'none' }} />
          <TableCell style={{ borderBottom: 'none' }} />
          <CircularLoader />
          <TableCell style={{ borderBottom: 'none' }} />
          <TableCell style={{ borderBottom: 'none' }} />
          <TableCell style={{ borderBottom: 'none' }} />
        </TableRow>
      )}
    </div>
  );
}

export default EventListing;
