import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { FilterOptionType } from 'src/Types/FilterOptionType';
import { SetStateType } from 'src/Types/SetStateType';
import useStyles from './SettingsTableStyle';
import IconButton from '../Button/IconButton';

const ITEM_HEIGHT = 60;
type SetIdType = SetStateType<string>;

type TableFilterType = {
  id: string;
  setId: SetIdType;
  filterOptions: Array<FilterOptionType>;
  colour?: string;
};

function TableFilter({ id, setId, filterOptions, colour = '' }: TableFilterType) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (menuSelection) => {
    setId(menuSelection);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton icon="bx:bx-filter" onClick={handleClick} colour={colour} />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
            padding: 0,
            lineHeight: '10px',
          },
        }}
      >
        <p className={classes.titleFilter}>{t('others.Filter')}</p>
        <MenuItem
          key=""
          onClick={() => handleMenuClick('')}
          selected={id === ''}
          classes={{ selected: classes.selected }}
        >
          <p className={classes.option}>{t('others.Filter all')}</p>
        </MenuItem>
        {filterOptions &&
          filterOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleMenuClick(option.value)}
              selected={id === option.value}
              classes={{ selected: classes.selected }}
            >
              <p className={classes.option}>{option.label}</p>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default TableFilter;
