import React, { createContext } from 'react';

interface ModalContextFunction {
  (ChildrenComponent: React.ComponentType<any>, modalKey: string): (
    isOpen: boolean,
    newProps?: any,
  ) => void;
}

const defaultModalContext: ModalContextFunction =
  (ChildrenComponent, modalKey) => () => {};

const ModalPopupContext = createContext<ModalContextFunction>(defaultModalContext);

export default ModalPopupContext;
