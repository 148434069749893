import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/Store';
import ProfileHeader from 'src/Components/ProfileHeader/ProfileHeader';
import OrganizerContactAddEdit from 'src/Containers/Organizers/OrganizerDetails/OrganizerContactAddEdit';
import EnumSubmitActions from 'src/Types/Enums/EnumSubmitActions';
import CanAccessByRole from 'src/Components/Utils/CanAccessByRole';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import EditOrganizer from '../../Containers/Organizers/OrganizerDetails/EditOrganizer';
import {
  getOrganizer,
  getOrganizerContact,
} from '../../Services/Auth/Actions/OrganizerActions';
import OrganizerContact from '../../Containers/Organizers/OrganizerDetails/OrganizerContact';
import OrganizerEventListing from '../../Containers/Organizers/OrganizerDetails/OrganizerEventListing';

function OrganizerDetails({ isEventOrganizers = false }) {
  const dispatch = useDispatch();
  const role = useSelector((store: RootState) => store.role.role);
  let userId = useSelector((store: RootState) => store.role.user._id);
  const { t } = useTranslation();
  const [details, setDetails] = useState<any>([]);
  const [type, setType] = useState<string>('');
  const {
    id,
    eventOrgId,
    tab,
  }: { id: ObjectIdType; eventOrgId: ObjectIdType; tab: string } = useParams();
  const [currentPage, setCurrentPage] = useState(tab);
  const [orgDetails, setOrgDetails] = useState<any>({});
  const history = useHistory();
  let profileRole = role;

  if (isEventOrganizers) {
    profileRole = EnumUserRoles.eventOrganizer;
    userId = eventOrgId;
  }

  const organizerDetailsById = () => {
    getOrganizer(id)
      .then((response) => {
        const data = response.data.organizer.userId;
        if (role === 'organizer') {
          dispatch({
            type: 'SAVE_USER',
            payload: data,
          });
        }
        setDetails(data);
        setType(response.data.organizer.organizerType);
      })
      .catch(() => {});
  };

  const organizerContactDetailsById = () => {
    getOrganizerContact(userId)
      .then((response) => {
        const data = response.data.EventOrganizer;
        if (role === 'eventOrganizer') {
          dispatch({
            type: 'SAVE_USER',
            payload: data,
          });
        }
        setOrgDetails(data);
      })
      .catch(() => {});
  };

  const navTabOnClick = (pageName) => {
    history.push({
      pathname: isEventOrganizers
        ? `/eventorganizers/details/${id}/${userId}/${pageName}`
        : `/organizers/details/${id}/${pageName}`,
      state: { showGoBack: true, goBackpath: history.location.pathname },
    });
    setCurrentPage(pageName);
  };

  useEffect(() => {
    if (id) {
      organizerDetailsById();
    }
    if (
      role === EnumUserRoles.eventOrganizer ||
      profileRole === EnumUserRoles.eventOrganizer
    ) {
      organizerContactDetailsById();
    }
  }, [id]);

  return (
    <div>
      <Dashboard>
        <Header
          title={
            profileRole === 'eventOrganizer'
              ? t('organizers.Organizer Contact')
              : t('organizers.Organisers')
          }
        />
        <ProfileHeader
          details={profileRole === 'eventOrganizer' ? orgDetails : details}
          navTabOnClick={navTabOnClick}
          currentPage={currentPage}
        />

        {currentPage === 'profile' ? (
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <EditOrganizer
                reload={organizerDetailsById}
                details={details}
                type={type}
                isEventOrganizers={isEventOrganizers}
              />
            </Grid>

            <Grid item lg={6} xs={12}>
              <CanAccessByRole
                roles={['organizer', 'superadmin', 'participant']}
                forceRole={profileRole}
              >
                <OrganizerContact details={details} />
              </CanAccessByRole>
              <CanAccessByRole roles={['eventOrganizer']} forceRole={profileRole}>
                <OrganizerContactAddEdit
                  tittle="Organiser Contact Details"
                  details={userId}
                  reload={organizerContactDetailsById}
                  isModal={false}
                  submitAction={EnumSubmitActions.update}
                />
              </CanAccessByRole>
            </Grid>
          </Grid>
        ) : (
          <OrganizerEventListing
            details={profileRole === 'eventOrganizer' ? userId : id}
            forceRole={profileRole}
          />
        )}
      </Dashboard>
    </div>
  );
}

export default OrganizerDetails;
