/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { commonResponseHandler } from 'src/Services/Utils';
import {
  AddFormField,
  FindFormFieldById,
  UpdateFormField,
} from 'src/Services/Auth/Actions/FormFieldActions';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import { useTranslation } from 'react-i18next';
import { formFieldMainGroups } from 'src/Services/Constants';
import useStyles from './AddUpdateFormFieldStyle';
import CategoryGroup from './CategoryGroup';

const formFieldSchema = Yup.object().shape({
  type: Yup.string().required('formFields.Form Field is required'),
  key: Yup.string()
    .trim()
    .matches(
      /^[a-z-0-9!@#\$%\^\&*\)\(+=._-]+$/g,
      'General settings.No space and Uppercase letters allowed',
    )
    .required('formFields.Id Field is required'),

  // label: Yup.string().required('formFields.Label Field is required').trim(),

  hint: Yup.string().trim(),
  isMultiple: Yup.string(), // This is a boolean field. But In formik radio values must be strings. So, that is why we initialized this field as a string.
  category: Yup.string().required('formFields.Category is required'),
  order: Yup.number()
    .positive('formFields.An order number must be a positive value')
    .integer('formFields.An order number must be an integer')
    .required('formFields.An order number is required'),
  values: Yup.string().when('type', (type): any => {
    if (type === 'dropdown') {
      return Yup.string().required('formFields.Value field is required').nullable();
    }

    return Yup.string().nullable();
  }),
});

const optionValues = [
  {
    key: 'text',
    label: 'Text Box',
  },
  {
    key: 'textarea',
    label: 'Text Area',
  },
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'file',
    label: 'File Upload',
  },
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'checkbox',
    label: 'Check Box',
  },
  {
    key: 'heading',
    label: 'Heading',
  },
  {
    key: 'paragraph',
    label: 'Paragraph',
  },
  {
    key: 'dropdown',
    label: 'Drop down',
  },
  {
    key: 'totalField',
    label: 'Total Field',
  },
  {
    key: 'image',
    label: 'Image',
  },
  {
    key: 'table',
    label: 'Table',
  },
  {
    key: 'pin',
    label: 'Pin',
  },
  {
    key: 'empty',
    label: 'Empty',
  },
];

type AddUpdateFormFieldprop = {
  updateId?: string | null;
  reload: Function;
  setOpenPopup: Function;
};

function AddUpdateFormField({ updateId, reload, setOpenPopup }: AddUpdateFormFieldprop) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>({});
  const categoryOptions = [...formFieldMainGroups];

  useEffect(() => {
    if (updateId) {
      FindFormFieldById(updateId)
        .then((res) => {
          setData(res.data.formFeild);
        })
        .catch(() => {});
    }
  }, [updateId]);

  return (
    <Formik
      initialValues={{
        type: data?.type || 'text',
        key: data?.key || '',
        label: data?.label || '',
        hint: data?.hint || '',
        values: data?.values?.toString(),
        isMultiple: data?.isMultiple === true ? 'true' : 'false' || 'false', // In formik radio values must be strings.
        category: data?.category || 'Pilot',
        group: data?.group || '',
        order: data?.order || '',
      }}
      enableReinitialize
      validationSchema={formFieldSchema}
      onSubmit={(values, { setSubmitting }) => {
        const formValues: any = {
          ...values,
        };
        if (formValues.isMultiple === 'true') {
          formValues.isMultiple = true;
        }
        if (formValues.isMultiple === 'false') {
          formValues.isMultiple = false;
        }
        if (formValues.type === 'heading' || formValues.type === 'paragraph') {
          formValues.isMultiple = true;
        }
        formValues.options = ['Required'];
        formValues.validations = ['Required'];
        if (formValues.type === 'dropdown') {
          formValues.values = formValues.values.split(',');
        }
        if (values.type !== 'dropdown') {
          formValues.values = [];
        }
        if (formValues.group === '') {
          delete formValues.group;
        }
        if (updateId) {
          commonResponseHandler(
            UpdateFormField(formValues, updateId),
            () => {
              reload('updatedAt', 'desc');
              setSubmitting(false);
              setOpenPopup(false);
            },
            setSubmitting(false),
          );
        } else {
          commonResponseHandler(
            AddFormField(formValues),
            () => {
              reload('updatedAt', 'desc');
              setSubmitting(false);
              setOpenPopup(false);
            },
            setSubmitting(false),
          );
        }
      }}
    >
      {({ isSubmitting, handleChange, handleBlur, values, errors, touched }) => (
        <Form>
          <div className={classes.titleWrapper}>
            <h3 id="transition-modal-description">
              {updateId ? t('forms.Update Form Field') : t('formFields.Add New')}
            </h3>
            <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
          </div>
          <div>
            <FormLabel name={t('formFields.Select Form Field')} require />
            <select
              name="type"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {optionValues.map((option) => (
                <option value={option.key} label={option.label} key={option.key} />
              ))}
            </select>
            <ErrorMessageCustom errors={errors} field="type" touched={touched} />
          </div>
          <div>
            <FormLabel name="Id" require />
            <Field
              className={classes.input}
              text="key"
              type="text"
              name="key"
              value={values.key}
              onChange={handleChange}
            />
            <ErrorMessageCustom errors={errors} field="key" touched={touched} />
          </div>
          <div>
            <FormLabel name={t('forms.Label')} />
            <Field
              className={classes.input}
              text="label"
              type="text"
              name="label"
              value={values.label}
              onChange={handleChange}
            />
            <ErrorMessageCustom errors={errors} field="label" touched={touched} />
          </div>
          <div>
            <FormLabel name={t('forms.Hint')} />
            <Field
              className={classes.input}
              text="hint"
              type="text"
              name="hint"
              value={values.hint}
              onChange={handleChange}
            />
            <ErrorMessageCustom errors={errors} field="hint" touched={touched} />
          </div>
          {values.type === 'dropdown' ? (
            <div>
              <FormLabel name="Values " require />
              <Field
                className={classes.input}
                text="values"
                type="text"
                name="values"
                value={values.values}
                onChange={handleChange}
              />
              <ErrorMessageCustom errors={errors} field="values" touched={touched} />
            </div>
          ) : null}
          {values.category === 'Other' && (
            <div>
              <FormLabel name="Multiple" />
              <div
                className={classes.radioContainer}
                role="group"
                aria-labelledby="my-radio-group"
              >
                <div className={classes.radioLeft}>
                  <label htmlFor="true" style={{ cursor: 'pointer' }}>
                    True
                  </label>
                  <Field type="radio" name="isMultiple" value="true" id="true" />
                </div>
                <div className={classes.radioRight}>
                  <label htmlFor="false" style={{ cursor: 'pointer' }}>
                    False
                  </label>
                  <Field type="radio" name="isMultiple" value="false" id="false" />
                </div>
              </div>
            </div>
          )}

          <div>
            <FormLabel name={t('formFields.Select Category')} require />
            <select
              name="category"
              value={values.category}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value={values.category} label={values.category} />
              {categoryOptions.map((option) => (
                <option value={option.value} label={option.label} key={option.value} />
              ))}
            </select>
            <ErrorMessageCustom errors={errors} field="formField" touched={touched} />
          </div>
          <CategoryGroup
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            isEdit
          />
        </Form>
      )}
    </Formik>
  );
}

export default AddUpdateFormField;
