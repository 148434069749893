import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Backdrop, Fade, Grid, Modal } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocationType } from 'src/Types/UseLocationType';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import { Button } from '../../../Components/Utils';
import AVATAR from '../eventimgAvatar.svg';
import PROFILE_BG from '../Profilebg.svg';
import useStyles from './styles';
import EditEvent from '../../../Containers/Events/EventDetails/EditEvent';
import { getEvent } from '../../../Services/Auth/Actions/EventActions';
import RallyListing from '../../../Containers/Events/Rally/RallyListing';
import EventParticipants from '../../../Containers/Events/EventParticipants/EventParticipants';
import RallyForms from '../../../Containers/Events/Forms/RallyForms';
import AdminOrganiserCounts from './AdminOrganiserCounts';
import ContactSectionWrap from './ContactSectionWrap';

function AdminOrganizerView({ id, page = 'details' }) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(page);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<useLocationType>();
  const eventOrganizerId = (details.organizer && details.organizer._id) || '';
  const eventOrganizerName =
    details.organizer?.userId?.firstName || details.organizer?.userId?.companyname || '';

  const showModalPopup = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const eventDetailsById = async () => {
    await getEvent(id)
      .then((response) => {
        const { event } = response.data;
        const { organizerType } = response.data.event;
        setDetails(event);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      eventDetailsById();
    }
  }, [id, currentPage]);

  const navTabOnClick = (pageName) => {
    if ([EnumEventStatus.Cancelled].includes(details.status) && pageName !== 'details') {
      toast.warning(t('toast.Denied for Cancelled Event '));
      return;
    }
    history.push({
      pathname: `/events/details/${details._id}/${pageName}`,
      state: {
        showGoBack: true,
        goBackpath: location.state?.goBackpath ? location.state.goBackpath : '/events',
      },
    });
    setCurrentPage(pageName);
  };
  /**
   * @function websiteNavigate
   * @description Used for webcite link naviage next page
   */
  const websiteNavigate = () => {
    const website = details?.website || '';
    let websiteCondtion;
    try {
      if (website.startsWith('https://') || website.startsWith('http://')) {
        websiteCondtion = (
          <a href={details?.website} target="_blank" rel="noopener noreferrer">
            <h6 className={classes.email}>{details?.website}</h6>
          </a>
        );
        return websiteCondtion;
      }
      if (website) {
        const https = 'https://';
        websiteCondtion = (
          <a href={https.concat(website)} target="_blank" rel="noopener noreferrer">
            <h6 className={classes.email}>{https.concat(website)}</h6>
          </a>
        );
        return websiteCondtion;
      }
      // websiteCondtion = (
      //   <a href="" target="_blank" rel="noopener noreferrer">
      //     <h6 className={classes.email} />
      //   </a>
      // );
      return null;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  const tabClickView = () => {
    switch (currentPage) {
      case 'rallies':
        return (
          <RallyListing
            event={details}
            eventDetailsById={eventDetailsById}
            eventStatus={details.status}
          />
        );
      case 'participants':
        return <EventParticipants event={details} />;
      case 'forms':
        return <RallyForms eventStatus={details.status} />;

      default:
        return (
          <>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12}>
                <EditEvent
                  reload={eventDetailsById}
                  details={details}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>{' '}
          </>
        );
    }
  };
  return (
    <>
      <div className={classes.wrapper} style={{ background: `url(${PROFILE_BG})` }}>
        <div className={classes.img} onClick={showModalPopup}>
          <img
            src={details.eventImage || AVATAR}
            className={classes.avatar}
            alt="event_AVATAR"
          />
        </div>
        <div className={classes.conatiner}>
          <div className={classes.left}>
            <h5 className={classes.h5}>{details.eventName} </h5>
            <p className={classes.p2}>
              {t('events.Organized By')}{' '}
              <Link
                to={`/organizers/details/${eventOrganizerId}/profile`}
                className={classes.linkText}
              >
                <span className={classes.span}>{eventOrganizerName}</span>
              </Link>
            </p>
            <div className={classes.countSec}>
              <AdminOrganiserCounts
                details={moment(details.startDateTime).format('DD MMM YYYY')}
                icon="fa-regular:calendar-check"
              />
              {details.venue && (
                <AdminOrganiserCounts
                  details={details.venue.split(',')[0]}
                  venue={details.venue}
                  icon="akar-icons:location"
                />
              )}
              {details.rallies && details.rallies.length > 0 && (
                <AdminOrganiserCounts
                  details={details.rallies.length}
                  icon="emojione-monotone:racing-car"
                />
              )}
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.contactSec}>
              <div className={classes.contactSecWrap}>
                <ContactSectionWrap
                  details={details.enquiryEmail}
                  icon="dashicons:email"
                  linkto={`mailto:${details.email}`}
                />
                <ContactSectionWrap
                  details={details.enquiryPhone}
                  icon="bytesize:telephone"
                  linkto={`tel:${details.enquiryPhone}`}
                />
                <ContactSectionWrap
                  details={details.website}
                  icon="fa:globe"
                  linkto=""
                  extraprops="website"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.btnSec}>
          <Button
            active={currentPage === 'rallies'}
            name={t('events.Races / Rallies')}
            onClick={() => navTabOnClick('rallies')}
            style={{ margin: '5px !important' }}
            className="btnResponse"
          />
          <Button
            active={currentPage === 'details'}
            name={t('events.Details')}
            onClick={() => navTabOnClick('details')}
            style={{ margin: '5px !important' }}
            className="btnResponse"
          />
          <Button
            active={currentPage === 'forms'}
            name={t('events.Forms')}
            onClick={() => navTabOnClick('forms')}
            style={{ margin: '5px !important' }}
            className="btnResponse"
          />
          <Button
            style={{ margin: '5px !important' }}
            active={currentPage === 'participants'}
            name={t('users.Participants')}
            onClick={() => navTabOnClick('participants')}
            className="btnResponse"
          />
        </div>
      </div>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showModal}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModal}>
            <div className={classes.paper}>
              <img
                src={details.eventImage ? details.eventImage : AVATAR}
                className={classes.fullImage}
                alt="event_AVATAR"
              />
            </div>
          </Fade>
        </Modal>
      </div>
      <div>{tabClickView()}</div>
    </>
  );
}
export default AdminOrganizerView;
