/* eslint-disable react/require-default-props */
import clsx from 'clsx';
import { Icon } from '@iconify/react';
import useFetchExportDetails from './hooks/useFetchExportDetails';
import useStyles from './style';
import { DocButtonProps } from './types/docType';

function DocButton({
  name,
  color = '#ffffff',
  textColor = '#000000',
  resource,
  eventId = '',
  rallyId = '',
  organizerId = '',
  className = '',
  initialSortApi,
  id = '',
  reportName = '',
  reload,
}: DocButtonProps) {
  const classes = useStyles();
  // import export designer data from the hook via a reducer
  const { handleClick } = useFetchExportDetails(
    eventId,
    rallyId,
    resource,
    organizerId,
    initialSortApi,
    id,
    reportName,
    reload,
  );
  // eslint-disable-next-line react/function-component-definition
  const IconButton = () => {
    if (className === 'report') {
      return (
        <div>
          <Icon
            icon="feather:edit-3"
            onClick={() => handleClick()}
            className={classes.icon}
          />
        </div>
      );
    }
    return (
      <button
        type="button"
        className={clsx(classes.button, className)}
        style={{ color: textColor, backgroundColor: color }}
        onClick={() => handleClick()}
      >
        {name}
      </button>
    );
  };

  return IconButton();
}

export default DocButton;
