import { Field } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageCustom, FormLabel } from '../../Components/Utils';
import { FormFieldGroupListing } from '../../Services/Auth/Actions/FormFieldActions';
import useStyles from './FormFieldListingStyle';

function CategoryGroup({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  isEdit = false,
}: {
  values: any;
  handleChange: any;
  handleBlur: any;
  touched: any;
  errors: any;
  isEdit: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [groupList, setGroupList] = useState([]);
  const [defaultValue, setdefaultValue] = useState<any>(null);

  const filterGroupName = (category) => {
    FormFieldGroupListing({ category, page: 0, rowsPerPage: 999 }).then((response) => {
      setGroupList(response.data.data);

      setdefaultValue(
        values?.group?.length > 0
          ? response.data.data.find((data) => data._id === values.group)
          : 'select',
      );
    });
  };

  useEffect(() => {
    filterGroupName(values.category);
  }, [values]);

  return (
    <div>
      <div>
        <FormLabel name={t('formFields.Select Group')} />
        <select
          name="group"
          value={values.group}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {isEdit ? (
            <option
              value={values.group}
              label={
                defaultValue && Object.keys(defaultValue).length > 0
                  ? defaultValue?.groupName
                  : defaultValue
              }
              selected
            >
              {t('Select')}
            </option>
          ) : (
            <option value="" label="Select" selected />
          )}
          {groupList.map((option: any) => (
            <option value={option._id} label={option.groupName} />
          ))}
        </select>
        <ErrorMessageCustom errors={errors} field="group" touched={touched} />
      </div>
      <div>
        <FormLabel name={t('forms.Order')} require />
        <Field
          className={classes.input}
          text="key"
          type="number"
          name="order"
          onkeypress="return onlyNumberKey"
          value={values.order}
          onChange={handleChange}
        />
        <ErrorMessageCustom errors={errors} field="order" touched={touched} />
      </div>
    </div>
  );
}

export default CategoryGroup;
