import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EmailRestriction from './EmailRestriction';
import SMTP from './SMTP';
import MultiTabs from '../MultiTabs/MultiTabs';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
  },
}));


function GeneralSettingsTabs() {
  const { t } = useTranslation();
  const classes = useStyles();

  const tabs = [
    {
        id: '1',
        title: (t('General settings.Email Restrictions')),
        component: <EmailRestriction />
    },
    {
        id: '2',
        title: (t('General settings.SMTP')),
        component: <SMTP />
    }
]

  return (
      <div className={classes.paper}>
      <MultiTabs tabs={tabs} />
      </div>
        );
}

export default GeneralSettingsTabs;
