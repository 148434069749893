import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '85px',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  outerbox: {
    position: 'relative',
  },
  input1: {
    backgroundColor: 'transparent',
    padding: '6px 6px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    border: '1px solid #D4D5DE',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
    fontFamily: typography.fonts.secondary,
  },
}));
