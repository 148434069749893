/* eslint-disable import/no-cycle */
import axios from 'axios';
import { ApiData } from 'src/Components/DocDownload/types/docType';
import { fileResource } from 'src/Components/DocDownload/util';
import store from '../../../Store';
import { baseUrl, baseUrlV2 } from '../../Constants';
import { sortingOptions } from '../../Utils';

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const organizerListing = () =>
  axios.get(`${baseUrl}/api/organiser/list`, getHeaders());

export const organizerListingSort = ({
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });

  return axios.get(`${baseUrl}/api/organiser/list?&${options}`, getHeaders());
};
export const autocomplete = ({ sortBy = 'firstName', sort = 'asc' }, name = '') =>
  axios.get(
    `${baseUrl}/api/organiser/autocomplete?sortBy=${sortBy}&sort=${sort}&search=${name}`,
    getHeaders(),
  );
export const getOrganisersWithMembersById = (id) =>
  axios.get(`${baseUrl}/api/organiser/getOrganisersWithMembersById/${id}`, getHeaders());

export const getMyOrganisersWithMembers = () =>
  axios.get(`${baseUrl}/api/organiser/getMyOrganisersWithMembers`, getHeaders());

export const organizerListingSearch = ({ name = '' }) =>
  axios.get(`${baseUrl}/api/organiser/list?search=${name}`, getHeaders());

export const addNewOrganizer = (credentials) =>
  axios.post(`${baseUrl}/api/organiser/create`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const OrganiserBackup = (id) =>
  axios.get(`${baseUrlV2}/api/v2/user/backup/${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
export const OrganiserRestore = (credentials) =>
  axios.post(`${baseUrlV2}/api/v2/user/import`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });
export const deleteOrganizer = (id) =>
  axios.delete(`${baseUrl}/api/organiser/${id}`, getHeaders());

export const getOrganizer = (id) =>
  axios.get(`${baseUrl}/api/organiser/${id}`, getHeaders());

export const updateOrganizer = (id, credentials) =>
  axios.put(`${baseUrl}/api/organiser/${id}`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const blockOrganizer = (id, credentials) =>
  axios.patch(`${baseUrl}/api/organiser/${id}`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const organizerReset = (values, id, token) =>
  axios.patch(`${baseUrl}/api/organiser/user-credentials/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

export const AddNewOrganizerContact = (credentials) =>
  axios.post(`${baseUrl}/api/organiser/createEventOrganizer`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getOrganizerContact = (id) =>
  axios.get(`${baseUrl}/api/organiser/EventOrg/${id}`, getHeaders());

export const updateOrganizerContact = (id, credentials) =>
  axios.put(`${baseUrl}/api/organiser/updateEventOrg/${id}`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const deleteOrganizerContact = (id) =>
  axios.delete(`${baseUrl}/api/organiser/EventOrg/${id}`, getHeaders());

export const newOrganiserListing = ({
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(`${baseUrl}/api/organiser/latest-organizer?&${options}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const newOrganiserContactListing = ({
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(
    `${baseUrl}/api/organiser/latest-organizerContact?&${options}`,
    getHeaders(),
  );
};

export const sampleOrganiserCSV = () =>
  // let data = {
  //     token: getToken(),
  // };
  // const formData = new FormData();
  // Object.keys(data).map(k => formData.append(k, data[k]));

  axios.get(`${baseUrl}/api/event/csv-sample/organizer`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const exportOrganiserCSV = ({
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const data: any = {
    token: getToken(),
  };
  const formData = new FormData();
  Object.keys(data).map((k) => formData.append(k, data[k]));
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: 100,
  });
  return axios.post(`${baseUrl}/api/organiser/csv-export?${options}`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
    },
  });
};

export const importOrganiserCSV = ({ formData }: { formData: FormData }) =>
  axios.post(`${baseUrl}/api/organiser/csv-import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getDocDetails = ({
  sortBy = 'createdAt',
  sort = 'desc',
  page = 0,
  rowsPerPage = 100,
  resource,
  eventId = '',
  rallyId = '',
  formId,
  organizerId = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    page,
    rowsPerPage,
  });
  return axios.get(
    `${baseUrl}/api/file-export/list/${resource}?${options}&${eventId}&${rallyId}&formId=${JSON.stringify(
      formId,
    )}&organizerId=${organizerId}`,
    getHeaders(),
  );
};

export const downloadDocumentPdfOrCsv = ({
  sortBy = 'createdAt',
  sort = 'desc',
  page = 0,
  rowsPerPage = 100,
  selectedFields,
  resource,
  type,
  eventId,
  formId = [],
  organizerId = '',
  rallyId = '',
  orientation = 'landscape',
}) => {
  const data: any = {
    selectedFields,
    resource,
    page,
    pageSize: rowsPerPage,
    sortOrder: sort,
    sortBy,
    type,
    eventId,
    filter: 'all',
    formId,
    organizerId,
    rallyId,
    orientation,
  };

  return axios.post(`${baseUrl}/api/file-export`, data, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const submitTableReport = ({ data, name }) => {
  const apiData: any = {
    data,
    name,
  };
  return axios.post(`${baseUrlV2}/api/v2/table-report`, apiData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
};
export const updateReport = ({ data, name, id }) => {
  const apiData: any = {
    data,
    name,
  };
  return axios.patch(`${baseUrlV2}/api/v2/table-report/${id}`, apiData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const saveExportlog = ({ data, name, eventId }: ApiData) => {
  const apiData: ApiData = {
    data,
    name,
  };
  if (name === fileResource.eventParticipant) {
    apiData.eventId = eventId;
  }
  return axios.post(`${baseUrlV2}/api/v2/export-log`, apiData, getHeaders());
};

export const findExportlog = (resource: string, eventId: string) => {
  let url = `${baseUrlV2}/api/v2/export-log/${resource}`;
  if (resource === fileResource.eventParticipant) {
    url += `?eventId=${eventId}`;
  }
  return axios.get(url, getHeaders());
};
