import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
  },
}));

function InputField({ text, type }: any) {
  const classes = useStyles();
  return <input className={classes.input} type={type} placeholder={text} />;
}

export default InputField;
