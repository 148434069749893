import { Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { validator } from '../../Services/Auth/Validations';
import { FormButton, FormLabel } from '../Utils';
import {
  getVersionDetails,
  updateVersion,
} from '../../Services/Auth/Actions/UserActions';
import PasswordField from '../PasswordField/PasswordField';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
    // marginLeft: "10px",
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textboxwrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  Checkboxwrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '29px',
  },
  container: {
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function SMTP() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [checkBox, setCheckBox] = useState({ checkedB: true });

  const smtpSchema = Yup.object().shape({
    username: validator.USERNAME,
    password: validator.PASSWORD,
    host: Yup.string().required('Host is Required'),
    port: Yup.string().required('Port is Required'),
    from: Yup.string().required('From is Required'),
  });

  const versionDetails = () => {
    getVersionDetails().then((response) => {
      setData(response.data.smtp);
      setCheckBox({ checkedB: response.data.smtp.secure });
    });
  };

  const handleChangeCheckBox = (event) => {
    setCheckBox({ ...checkBox, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    versionDetails();
  }, []);

  return (
    <div>
      <div className={classes.wrapper}>
        <Formik
          initialValues={{
            username: data?.user,
            password: data?.pass,
            host: data?.host,
            port: data?.port,
            from: data?.from,
            secure: data?.secure,
          }}
          enableReinitialize
          validationSchema={smtpSchema}
          onSubmit={(values, { setSubmitting }) => {
            const datas = {
              ...values,
              user: values.username,
              pass: values.password,
              secure: checkBox.checkedB,
            };
            delete data.username;
            delete data.password;
            const smtp = {
              ...datas,
            };
            delete smtp.username;
            delete smtp.password;
            updateVersion({ smtp }).then(() => {
              setSubmitting(false);
              toast.success(t('General settings.Submitted successfully'));
            });
          }}
        >
          {({ errors, touched, isSubmitting, values, handleChange }) => (
            <div className={classes.container}>
              <Form>
                <div className={classes.titleWrapper}>
                  <h2 style={{ textAlign: 'center', width: '100%' }}>
                    {t('General settings.SMTP')}
                  </h2>
                  <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
                </div>
                <Grid container>
                  <Grid item lg={6} xs={6}>
                    <div className={classes.textboxwrapper}>
                      <FormLabel name={t('forms.Username')} require />

                      <Field
                        className={classes.input}
                        name="username"
                        type="text"
                        text="username"
                        onChange={handleChange}
                        value={values.username}
                      />
                      {errors.username && touched.username ? (
                        <div>{errors.username}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <div className={classes.textboxwrapper}>
                      <FormLabel name={t('forms.Password')} require />
                      <Field
                        className={classes.input}
                        name="password"
                        component={PasswordField}
                        text="password"
                        onChange={handleChange}
                        value={values.password}
                      />
                      {errors.password && touched.password ? (
                        <div>{errors.password}</div>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <div className={classes.textboxwrapper}>
                      <FormLabel name={t('General settings.Host')} require />
                      <Field
                        className={classes.input}
                        name="host"
                        type="text"
                        text="host"
                        value={values.host}
                        onChange={handleChange}
                      />
                      {errors.host && touched.host ? <div>{errors.host}</div> : null}
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <div className={classes.textboxwrapper}>
                      <FormLabel name={t('General settings.Port')} require />
                      <Field
                        className={classes.input}
                        name="port"
                        type="text"
                        text="port"
                        value={values.port}
                        onChange={handleChange}
                      />
                      {errors.port && touched.port ? <div>{errors.port}</div> : null}
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div className={classes.textboxwrapper}>
                      <FormLabel name={t('General settings.From')} require />
                      <Field
                        className={classes.input}
                        name="from"
                        type="text"
                        text="from"
                      />
                      {errors.from && touched.from ? <div>{errors.from}</div> : null}
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <div className={classes.Checkboxwrapper}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkBox.checkedB}
                            onChange={handleChangeCheckBox}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={t('General settings.Secure')}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SMTP;
