import { makeStyles } from '@material-ui/core';
import theme from 'src/Theme';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';
import { formLayout } from '../Rally/utils';

export default makeStyles(() => ({
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  iconCancel: {
    color: '#E40909',
    fontSize: '20px',
  },
  iconExport: {
    color: '#ADAEB0',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainFormContainer: {
    '@media all and (max-width: 900px)': {
      width: '100%',
      overflow: 'scroll',
      height: '690px',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#bf2331',
        borderRadius: '3px',
        border: 'none',
      },
    },
    width: '820px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    borderRadius: '17px',
    width: formLayout.width,
    height: '500px',
    position: 'fixed',
    top: '130px',
    '@media all and (max-width: 900px)': {
      position: 'relative',
      overflow: 'hidden',
    },
    '@media all and (max-width: 1200px)': {
      width: formLayout.width,
      marginTop: '50px',
    },
    '@media all and (max-width: 650px)': {
      width: formLayout.width,
    },
    '@media all and (min-width: 1400px)': {
      height: '80%',
      width: '820px',
    },
    '@media all and (min-width: 2560px)': {
      height: '89%',
      width: '60%',
    },
    overflow: 'auto',
    overflowX: 'hidden',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    position: 'fixed',
    top: '10px',
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF',
    padding: '20px',
    // margin: '50px 20px 20px',
    borderRadius: '18px',
    width: 'inherit',
    // maxWidth: formLayout.width,
    justifyContent: 'space-between',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
    },
    '@media all and (max-width: 900px)': {
      width: '100%',
    },
    // fontWeight: 700,
    // textAlign: 'center',
    // maxWidth: '300x',
    // margin: '0 auto 32px',

    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-around',
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // right: 0,
    // zIndex: 99,

    // backgroundColor: 'aliceblue',
    // '&a': {
    //   color: colors.primary.color,
    // },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
  link: {
    float: 'right',
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: '0 10px',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  shareIcon: {
    color: '#43A047',
    fontSize: '20px',
    cursor: 'pointer',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  mainWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  eventName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: '#252733',
    marginTop: '5px',
  },
  time: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    color: '#ADAEB0',
    marginTop: '5px',
  },
  venue: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  btn: {
    display: 'flex',
    alignItems: 'right',
    boxShadow: 'none',
  },
  loader: {
    marginLeft: '12px',
  },
  tables: {
    paddingLeft: '25px',
    paddingRight: '25px',
    maxHeight: '100px',
    width: '100%',
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 500,
    backgroundColor: '#fff',
    fontFamily: typography.fonts.secondary,
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  container: {
    overflowY: 'scroll',
    maxHeight: '200px',
  },
  avatar: {
    width: '70px',
    height: '50px',
    margin: 'auto',
  },
  avatarTwo: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginLeft: '30px',
    position: 'relative',
    top: '-48px',
    bottom: '0px',
  },
  avatarTwoCopy: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginLeft: '30px',
    position: 'relative',
    top: '-65px',
    bottom: '0px',
  },
  imgCls: {
    opacity: '0',
    width: '300px',
    height: '75px',
  },
  formViewEdit: {
    cursor: 'pointer',
    color: 'blue',
  },
  editIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    cursor: 'pointer',
  },
  heading2: {
    margin: '0',
  },
  paragraph2: {
    margin: 0,
    overflowWrap: 'anywhere',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  check: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  marginBtm0: {
    marginBottom: '10px',
  },
  marginBtm75: {
    marginBottom: '-75px',
  },
  inputlabel: {
    cursor: 'pointer',
    width: '100%',
    lineHeight: 'normal',
  },
  editButton: {
    display: 'flex',
    alignItems: 'right',
    boxShadow: 'none',
  },
  hintText: {
    textAlign: 'left',
    marginLeft: '5px',
    fontSize: '12px',
    color: '#6c757d',
  },
  formHeaderHorizontalLine: {
    border: '.5px solid #03071e',
    backgroundColor: '#03071e',
  },
  closeIcon: {
    padding: '5px',
    backgroundColor: '#edf2f4',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0 10px',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },
  },
  submitCloseContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  forms: {
    background: '#FFFFFF',
    height: '100%',
    borderRadius: '18px',
    overflow: 'scroll',
    overflowX: 'hidden',
    '@media all and (max-width: 650px)': {
      marginTop: '10px',
      marginBottom: '20px',
      width: '100%',
    },
    '@media all and (min-width: 1400px)': {
      height: '100%',
      width: '100%',
    },
    '@media all and (min-width: 2560px)': {
      height: '100%',
      width: '100%',
    },
    // '&::-webkit-scrollbar': {
    //   width: 3,
    //   marginRight: '20px',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#DFE0EB',
    //   borderRadius: '3px',
    //   border: 'none',
    //   right: '10px',
    // },
  },
  modalLoadImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',

    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
}));
