import { makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageCustom, FormLabel } from 'src/Components/Utils';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    padding: '6px 6px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
}));

function TextField({
  label,
  name,
  value,
  type,
  handleChange,
  errors,
  touched,
  require = false,
  component,
  rows = '',
}: {
  label: string;
  name: string;
  value: string;
  type: string;
  handleChange: (e: React.ChangeEvent<any>) => void;
  errors: { [field: string]: string };
  touched: { [field: string]: boolean };
  require?: boolean;
  component?: 'textarea';
  rows?: string;
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <FormLabel name={t(`${label}`)} require={require} />
      <Field
        className={classes.input}
        text={name}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        component={component}
        rows={rows}
      />
      <ErrorMessageCustom errors={errors} field={name} touched={touched} />
    </>
  );
}

export default TextField;
