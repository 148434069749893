import React from 'react';
import useStyles from './ProfileHeaderStyle';

function ProfileCounts({ details, name }: { details: number; name: string }) {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      {details !== 0 && !!details && (
        <>
          <h4 className={classes.count}>{details}</h4>
          <h6 className={classes.text}>{name}</h6>
        </>
      )}
    </div>
  );
}

export default ProfileCounts;
