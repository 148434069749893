import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    padding: theme.spacing(2, 4, 2),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    padding: '6px 6px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-50px',
    marginRight: '-20px',
    color: '#9FA2B4',
  },
  btnWrap: {
    textAlign: 'center',
  },
}));
