import React, { useState, useCallback } from 'react';
import ModalPopup from './ModalPopup';
import ModalPopupContext from './ModalPopupContext';

type ModalProps = {
  modalKey: string;
  ChildrenComponent: React.ReactNode;
  newProps: any;
};

function ModalPopupProvider({ children }) {
  const [modals, setModals] = useState<Array<ModalProps>>([]);

  const handleOpenModal = useCallback(
    (modalKey: string, ChildrenComponent, newProps) => {
      setModals((prevModals) => [
        ...prevModals,
        {
          modalKey,
          ChildrenComponent,
          newProps,
        },
      ]);
    },
    [setModals],
  );

  const handleCloseModal = useCallback(
    (modalKey: string) => {
      setModals((prevModals) =>
        prevModals.filter((modal) => modal.modalKey !== modalKey),
      );
    },
    [setModals],
  );

  const confirm = useCallback(
    (ChildrenComponent, modalKey) => (isOpen, newProps) => {
      if (isOpen) {
        handleOpenModal(modalKey, ChildrenComponent, newProps);
      } else {
        handleCloseModal(modalKey);
      }
    },
    [handleOpenModal, handleCloseModal],
  );

  const renderCurrentModals = () =>
    modals.map(({ modalKey, ChildrenComponent, newProps }) => (
      <ModalPopup
        key={modalKey}
        showModel
        onCancel={() => handleCloseModal(modalKey)}
        description={ChildrenComponent}
        modalProps={newProps}
        setOpenPopup={(newState) =>
          confirm(ChildrenComponent, modalKey)(newState, newProps)
        }
      />
    ));

  return (
    <ModalPopupContext.Provider value={confirm}>
      {children}
      {renderCurrentModals()}
    </ModalPopupContext.Provider>
  );
}

export default ModalPopupProvider;
