import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  title: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
    margin: 0,
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'spaceBetween',
    flexWrap: 'wrap',
    alignItems: 'center',
    maxHeight: '50vh',
    overflow: 'auto',
    overflowX: 'hidden',
    '@media all and (max-width:1200px)': {
      justifyContent: 'center',
    },
    '@media all and (max-width:604px)': {
      justifyContent: 'spaceBetween',
      height: '100%',
      flexWrap: 'nowrap',
      overflow: 'auto',
      overflowY: 'hidden',
    },
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '75vh !important',
    },
  },
  cardWrapper: {
    width: '350px',
    height: '225px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    flexWrap: 'wrap',
    margin: '20px',
    '@media all and (max-width:604px)': {
      flex: 1,
    },
    position: 'relative',
  },
  bodyWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '150px',
  },
  avatar: {
    flex: '1',
    margin: '0',
    height: '100%',
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
    borderTopLeftRadius: '15px !important',
    objectFit: 'cover',
  },
  contentWrapper: {
    flex: '2',
    textAlign: 'left',
    padding: '15px 15px 0 20px',
    height: '100%',
    width: '100%',
  },
  eventName: {
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    width: '200px',
    height: '40',
    textOverflow: 'ellipsis',
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    color: colors.secondary.color,
    margin: '0 0 10px',
  },
  dateWrapper: {
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#00000075',
    margin: 0,
  },
  red: {
    backgroundColor: '#F12B2C',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: 'max-content',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#29CC97',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: 'max-content',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  iconCountContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
  },
  iconCountWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    marginRight: '35px',
  },
  icon: {
    color: colors.primary.color,
    fontSize: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  detailsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '0px',
  },
  detailsWrapper: {
    border: '1px solid #D4D5DE',
    flex: '1',
    backgroundColor: '#dfe0ebb5',
    color: colors.ternary.color,
    fontSize: '18px',
    fontFamily: typography.fonts.secondary,
    padding: '15px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#fff',
      backgroundColor: colors.primary.color,
      cursor: 'pointer',
    },
  },
  emptyMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    height: '200px',
    width: '100%',
    fontSize: '15px',
    lineHeight: '19px',
    fontFamily: 'Mulish',
    color: '#9FA2B4',
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: '10px',
  },
}));
