import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { registerValidation, updateUser } from '../../Services/Auth/Actions/UserActions';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';

function Settings() {
  const { t } = useTranslation();

  // const [userData, setUserDate] = useState({});
  // const [image, setImage] = useState(null);
  // const userRole = useSelector((store:RootState) => store.role.user.role);

  // const sendDataToParent=(image)=>{
  //     setProfileImage(sendDataToParent)
  //     console.log("setProfile image",image)
  // }
  // useEffect(() => {
  //   loggedUser(userRole)
  //     .then((response) => {
  //       const data = response.data.user;
  //       setUserDate(data);
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.message, {
  //         position: 'bottom-right',
  //         autoClose: 5000,
  //       });
  //     });
  // }, []);
  return (
    <div>
      <Dashboard>
        <Header title="Settings" />
        {/* <div className={classes.wrapper}> 
            
             <Formik
              initialValues={{
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    username: userData.username,
                    email: userData.email,
                    phone: userData.phone,
                    facebookUrl: userData.facebookUrl,
                    instagramUrl: userData.instagramUrl
              }}
              enableReinitialize={true}
              validationSchema={UpdateUserSchema}
              onSubmit={(values,{setSubmitting, setFieldError, handleChange}) => {
                
                
                
                  console.log("values",values);
                  const data:any = {
                    ...values
                  }
                    const formData = new FormData();
                    if(image) {
                        formData.append("file", image);
                    }
                    
                    Object.keys(data)
                    .map(k=>formData.append(k, data[k]))
                    console.log("values",values);

                    commonResponseHandler(
                        updateUser(formData),
                        ()=>setSubmitting(false),
                        ()=>setSubmitting(false)
                    )

                    
              }}
            >
    
              {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                <Form >
                    <div class={classes.topWrap}>
                        <SubTitle title="Manage your Profile" />
                        <AvatarUpload image={image} setImage={setImage} />
                    </div>
                   
                   <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormLabel name="First Name"/>
                                  
                                    <Field className={classes.input} text="firstName" type="text" name="firstName"
                                    value={values.firstName} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="firstName" touched={touched}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel name="Last Name"/>
                                    <Field className={classes.input} text="lastName" type="text" name="lastName"
                                    value={values.lastName} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="lastName" touched={touched}/>
                                </Grid>
                                
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormLabel name="Username"/>
                                    <Field className={classes.input} text="username" type="text" name="username"
                                    value={values.username} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="username" touched={touched}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel name="Email"/>
                                    <Field className={classes.input} text="email" type="email" name="email"
                                    value={values.email} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="email" touched={touched}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel name="Phone"/>
                                    <Field className={classes.input} text="phone" type="phone" name="phone"
                                    value={values.phone} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="phone" touched={touched}/>
                                </Grid>
                                
                            </Grid>
                            
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <FormLabel name="Facebook URL"/>
                                    <Field className={classes.input} text="facebookUrl" type="text" name="facebookUrl"
                                    value={values.facebookUrl} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="facebookUrl" touched={touched}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel name="Instagram URL"/>
                                    <Field className={classes.input} text="instagramUrl" type="text" name="instagramUrl"
                                    value={values.instagramUrl} 
                                    onChange={handleChange}/>
                                    <ErrorMessageCustom errors={errors} field="instagramUrl" touched={touched}/>
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <div className={classes.btnWrap}>
                                        {!isSubmitting && (
                                            <FormButton className={classes.btn} name="Submit" type="submit" disabled={isSubmitting}/>
                                        )} 
                                        {isSubmitting && (
                                            <Loader
                                                type="ThreeDots"
                                                color="#2148C0"
                                                height={50}
                                                width={50}
                                            />    
                                        )} 
                                    </div>
                                </Grid>
                            </Grid>
                            
                    
                        </Box>
                </Form>

                          
                )}
            </Formik> 
            </div> */}
      </Dashboard>
    </div>
  );
}

export default connect(null, { registerValidation, updateUser })(Settings);
