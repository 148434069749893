import { makeStyles } from '@material-ui/core';
import theme from 'src/Theme';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  isErrorMsg: { color: 'red' },
  uploadTable: {
    position: 'absolute',
    top: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    left: '50px',
    width: '90%',
  },
  uplodButton: {
    width: '25%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  paper: {
    backgroundColor: '#ffffff',
    border: '2px solid transparent',
    boxShadow: '0px 10px 10px rgb(0 0 0 / 30%)',
    borderRadius: '20px',
    width: '70%',
    height: '90%',
    overflow: 'scroll',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  btnWrap: {
    width: '25%',
    '@media all and (max-width: 650px)': {
      width: '115px',
    },
  },
}));
