/* eslint-disable react/no-unstable-nested-components */
import { t } from 'i18next';
import { useMemo } from 'react';
import CustomTable, { TopActionNormalButtonType } from 'src/Components/Table/Table';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import {
  CommonTranslatedResponseHandler,
  formateDateWithoutTime,
} from 'src/Services/Utils';
import topActions from 'src/Types/Enums/EnumTopActions';
import { DeleteFileType, GetFileTypes } from 'src/Services/Auth/Actions/RallyTypeActions';
import { FileTypeArray } from 'src/Types/FileType';
import RowActions from 'src/Components/TableActions/RowActions';
import AddEditFileType from './AddEditFileType';
import useStyles from './FileTypeListingStyle';
import { joinClassNames } from '../Events/EventParticipants/utils';

type TopActionButtonType = TopActionNormalButtonType;

function FileTypeListing() {
  const modal = useModalDialog();
  const classes = useStyles();
  const confirm: any = useConfirm();

  const AddEditModelSetOpen = useMemo(() => modal(AddEditFileType, 'rally-type'), []);

  const showModalPopup = (reload) => {
    AddEditModelSetOpen(true, { reload });
  };
  const showEditModalPopup = (updateId, reload) => {
    AddEditModelSetOpen(true, { updateId, reload });
  };

  const reloadTable = async ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{
    data: FileTypeArray;
    totalCount: number;
  }> => {
    try {
      const response = await GetFileTypes({
        sortBy: sort.sortBy,
        sort: sort.sort === 'asc' ? 'ASC' : 'DESC',
        page,
        rowsPerPage,
        search,
      });
      const { data } = response;
      const totalCount = response.headers['x-total-count'];
      return {
        data,
        totalCount,
      };
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  };

  const deleteFileTypeById = (deleteId, reload) => {
    confirm({
      description: t('File Type.Delete'),
    }).then(() => {
      const responseHandlerProps = {
        promise: DeleteFileType(deleteId),
        successCb: () => {
          reload();
        },
        successToast: t('File Type.Deleted'),
      };
      CommonTranslatedResponseHandler(responseHandlerProps);
    });
  };

  const columns = [
    {
      title: t('File Type.File Type'),
      field: 'type',
      data: (row) => (
        <div className={classes.wrap}>
          <div>
            <div className={classes.tbodyName}>{row.type}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('rallyTypes.Actions'),
      field: 'actions',
      data: (row, reload) => (
        <RowActions
          actions={[
            {
              onClick: () => showEditModalPopup(row._id, reload),
              icon: 'feather:edit-3',
              className: joinClassNames(classes.editIcon, classes.rightVisible),
            },
            {
              onClick: () => deleteFileTypeById(row._id, reload),
              icon: 'ant-design:delete-twotone',
              className: joinClassNames(classes.icon, classes.rightAuto),
            },
          ]}
        />
      ),
      isSortable: false,
    },
  ];
  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('rallyTypes.Add New'),
        onClick: async ({ reload }) => showModalPopup(reload),
      },
    },
  ];
  return (
    <CustomTable
      tableName="fileType"
      columns={columns}
      topActionButtons={topActionButton}
      onDoubleClick={(item, reload) => showEditModalPopup(item._id, reload)}
      heading={t('File Type.File Type Listing')}
      reload={reloadTable}
    />
  );
}

export default FileTypeListing;
