import React from 'react';
import i18n from 'i18next';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/Store';
import { updateUserLanguage } from 'src/Services/Auth/Actions/UserActions';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Icon from './Images/icons2.png';
import Usa from './Images/icons1.png';
import Ger from './Images/icons3.png';
import useStyles from './LanguageSelectTabStyle';

const boxStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#bf2331' : '',
    '&:hover': {
      background: state.isSelected ? '#bf2331' : '#efefef',
    },
    borderRadius: 4,
    paddingTop: '1px',
    paddingBottom: 0,
  }),
  indicatorsContainer: (provided, state) =>
    state.isMulti
      ? {
          ...provided,
        }
      : null,
};

function LanguageSelectTab() {
  const url = window.location.pathname;
  const EditForm = '/events/forms/editForm';
  const Createform = '/events/forms/createForm';

  let form;

  if (url.includes(EditForm) || url.includes(Createform)) {
    const newUrl = url.replace(/\/[a-f\d]{24}/g, '');
    form = newUrl;
  }
  const classes = useStyles();
  const confirm: any = useConfirm();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state?.role?.user?._id);
  const language = useSelector((state: RootState) => state?.language);

  const languages = [
    {
      name: 'english',
      label: (
        <div className={classes.lang}>
          <img className={classes.img} src={Usa} alt="1" />
          <div>english</div>{' '}
        </div>
      ),
      value: 'en',
    },
    {
      name: 'français',
      label: (
        <div className={classes.lang}>
          <img className={classes.img} src={Icon} alt="2" /> français
        </div>
      ),
      value: 'fr',
    },
    {
      name: 'deutsch',
      label: (
        <div className={classes.lang}>
          <img className={classes.img} src={Ger} alt="3" /> deutsch
        </div>
      ),
      value: 'du',
    },
  ];

  const languageTrigger = async (e) => {
    const [{ name, value }] = languages.filter((el) => el.value === e.value);
    const data = { name, value };
    if (id) {
      updateUserLanguage(id, { language: e.value });
    }
    dispatch({
      type: 'CHANGE_LANG',
      payload: data,
    });
    i18n.changeLanguage(e.value);
  };

  const onChange = async (e) => {
    if (form) {
      confirm({
        description: t('popup.Unsaved changes will be discarded'),
      }).then(() => {
        languageTrigger(e);
        toast.success(t('toast.Language Changed Successfully'), {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }, 2000);
      });
    } else {
      languageTrigger(e);
    }
  };

  return (
    <Select
      isSearchable={false}
      onChange={onChange}
      options={languages}
      defaultValue={languages.filter((lan) => lan.value === language.value)}
      classNamePrefix={classes.selectFields}
      styles={boxStyles}
      value={languages.filter((lan) => lan.value === language.value)}
    />
  );
}
export default LanguageSelectTab;
