/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TableContent from 'src/Components/Table/TableContent';
import useTable from 'src/Hooks/useTable';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { EventType } from 'src/Types/EventType';
import { RallyType } from 'src/Types/RallyType';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import { SubTitle } from '../../../Components/Utils';
import AVATAR from './compregLogo.png';
import {
  authorizedUserRallyListing,
  rallyListing,
  rallySortOrder,
} from '../../../Services/Auth/Actions/RallyActions';
import { RallyStatusLabel } from '../../../Components/Utils/RallyStatusLabel';
import { AuthorizedUser, formateDate } from '../../../Services/Utils';
import RallyParticipantCount from './components/RallyParticipantCount';
import RallyFileUploadCount from './components/RallyFileUploadCount';
import useStyles from './RallyListingStyle';
import RallyListingActions from './components/RallyListingActions';
import RallyListingPanalButtons from './components/RallyListingPanalButtons';
import RallyListingUser from './RallyListingUser';
import RallyEditPopup from './RallyEditPopup';

type RallyListingType = {
  event: EventType;
  eventDetailsById?: Function;
  isEventEnrolled?: boolean;
  isApproved?: boolean | undefined;
  publicView?: boolean;
  eventStatus?: EnumEventStatus;
};

function RallyListing({
  event,
  eventDetailsById,
  isEventEnrolled = false,
  isApproved = undefined,
  publicView = false,
  eventStatus = EnumEventStatus.Preparation,
}: RallyListingType) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [list, setList] = useState<Array<RallyType>>([]);
  const [totalRows, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const id = event._id;
  const [editingItemId, setEditingItemId] = useState('');
  const userRole = useSelector((store: RootState) => store.role.role);
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    debouncedSearch,
    sort,
  } = useTable({
    tableName: 'rally',
    sortableFields: [],
  });
  const modal = useModalDialog();
  const setOpenEditRally = useMemo(() => modal(RallyEditPopup, 'Rally-Edit-Popup'), []);

  const getRallies = async () => {
    const apiEndpoint = [
      EnumUserRoles.participant,
      EnumUserRoles.organizer,
      EnumUserRoles.eventOrganizer,
      EnumUserRoles.superadmin,
    ].includes(userRole)
      ? authorizedUserRallyListing
      : rallyListing;
    try {
      const response = await apiEndpoint({
        id,
        sortBy: sort.sortBy,
        sort: sort.sort,
        search: debouncedSearch,
        page,
        rowsPerPage,
      });
      const { totalCount, Rallies } = response.data;
      const updatedRallies = Rallies.map((rally) => {
        if (!rally.attachment) {
          return { ...rally, attachment: [] };
        }
        return rally;
      });
      return { rallies: updatedRallies, totalCount };
    } catch (error) {
      console.log(error);
      return { rallies: [], totalCount: 0 };
    }
  };

  const fetchRallies = async () => {
    setLoading(true);
    const { rallies, totalCount } = await getRallies();
    setList(rallies);
    setTotalCount(totalCount || 0);
    setLoading(false);
  };

  const reload = () => {
    if (id) {
      fetchRallies();
    }
  };

  if (editingItemId) {
    setOpenEditRally(true, {
      event,
      editingItemId,
      setEditingItemId,
      reload,
      overrideBody: true,
    });
  }

  useEffect(() => {
    reload();
  }, [page, rowsPerPage, event]);

  const sortOrder = (items) => {
    const data: any = {};
    const datas = items.map((item: any, i) => {
      data[item._id] = i + 1;
      return data;
    });
    rallySortOrder({ rallyId: [datas[0]] }, event._id);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
    sortOrder(items);
  }

  return (
    <div>
      <div className={classes.boxType}>
        <div className={classes.wrapper}>
          <SubTitle title={t('events.Rally Listing')} />
          <RallyListingPanalButtons
            event={event}
            reload={reload}
            eventDetailsById={eventDetailsById}
            rallyOrgId={event?.organizer?._id}
            publicView={publicView}
          />
        </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.thead}>{t('forms.Rally')}</TableCell>
                <TableCell className={classes.thead}>
                  {t('forms.Start Date')}
                  {' / '}
                  {t('forms.End Date')}
                </TableCell>
                <TableCell className={classes.thead}>{t('forms.Participants')}</TableCell>
                <TableCell className={classes.thead}>{t('forms.Status')}</TableCell>
                <TableCell className={classes.thead}>
                  {t('forms.Uploaded Files')}
                </TableCell>
                <TableCell className={classes.thead}>
                  <AuthorizedUser
                    rallyOrgId={event?.organizer?._id}
                    success={<>{t('forms.Actions')}</>}
                    error={<>{t('forms.EnrollStatus')}</>}
                    publicView={publicView}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableContent
              loading={loading}
              list={list}
              emptyText={t('others.No items found')}
            >
              <AuthorizedUser
                rallyOrgId={event?.organizer?._id}
                success={
                  // eslint-disable-next-line react/jsx-no-bind
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="thead">
                      {(provideds: any) => (
                        <TableBody {...provideds.droppableProps} ref={provideds.innerRef}>
                          {list.map((item: any, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided: any) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onDoubleClick={() => {
                                    if (!item.isCancelled) {
                                      setEditingItemId(item._id);
                                    }
                                  }}
                                >
                                  <TableCell className={classes.tbody}>
                                    <div className={classes.wrap}>
                                      <div className={classes.imgWrap}>
                                        <img
                                          src={item.rallyImage || AVATAR}
                                          className={classes.img}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className={classes.rallyName}>
                                        {item.rallyName}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.tbody}>
                                    <p className={classes.grey}>
                                      {formateDate(item.startDateTime)}
                                      {' / '}
                                      {formateDate(item.endDateTime)}
                                    </p>
                                  </TableCell>
                                  <TableCell className={classes.tbody}>
                                    <p className={classes.participants}>
                                      <RallyParticipantCount
                                        item={item}
                                        id={id}
                                        rallyOrgId={event?.organizer?._id}
                                      />
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    <div />
                                    <RallyStatusLabel data={item} />
                                  </TableCell>
                                  <TableCell className={classes.tbody}>
                                    <p className={classes.participants}>
                                      <RallyFileUploadCount item={item} />
                                    </p>
                                  </TableCell>
                                  <RallyListingActions
                                    rallyOrgId={event?.organizer?._id}
                                    item={item}
                                    reload={reload}
                                    eventDetailsById={eventDetailsById}
                                    setEditingItemId={setEditingItemId}
                                    eventStatus={eventStatus}
                                  />
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                          {provideds.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  </DragDropContext>
                }
                error={
                  <RallyListingUser
                    list={list}
                    event={event}
                    reload={reload}
                    id={id}
                    isEventEnrolled={isEventEnrolled}
                    isApproved={isApproved}
                    eventStatus={eventStatus}
                  />
                }
                publicView={publicView}
              />
            </TableContent>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('others.Rows per page')}
        />
      </div>
    </div>
  );
}

export default RallyListing;
