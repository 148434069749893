import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    // alignItems: "center",
    // textAlign: "center",
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imgWrap: {
    borderRadius: '10px',
    height: '50px',
    width: '100px',
    marginRight: '15px',
  },
  tbody: {
    // padding: "15px",
    fontFamily: typography.fonts.secondary,
    height: '300px',
    margin: 'auto',
    // textAlign: "center",
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
  },

  link: {
    color: '#007AFF',
    textDecoration: 'none',
    fontWeight: 600,
  },
  boxType: {
    padding: '20px',
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 651px)': {
      flexWrap: 'wrap',
    },
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '10px',
    '@media only screen and (max-width: 651px)': {
      width: '100%',
      minWidth: '250px',
      justifyContent: 'center',
    },
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  button123: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      backgroundColor: '#007AFF',
      color: '#fff',
    },
  },
  participants: {
    padding: '5px 0',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
    textAlign: 'center',
  },
  csvButtons: {
    justifyContent: 'center',
    display: 'flex',
  },
  uploadButtons: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  sampleCsv: {
    cursor: 'pointer',
    color: '#2148C0',
  },
  importCsv: {
    color: '#2148C0',
    position: 'relative',
  },
  chooseCvsInput: {
    opacity: 0,
    position: 'absolute',
    width: 150,
    height: 50,
  },
  noItems: { alignItems: 'center', marginTop: '20px' },
  rallyName: {
    width: '250px',
    wordBreak: 'break-word',
  },
  lightgreen: {
    backgroundColor: '#43a047',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
    borderColor: 'white',
  },
  btnPanel: {
    '@media only screen and (max-width: 651px)': {
      width: '300px',
      display: 'block !important',
    },
  },
  btnSection1: {
    '@media only screen and (max-width: 651px)': {
      display: 'inline-grid',
      width: '50%',
      justifyContent: 'center',
    },
  },
}));
