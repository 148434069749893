/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext, MouseEvent, RefObject } from 'react';
import { calculateGridCoordinates } from 'src/Containers/Events/Rally/components/DynamicForm/util';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { SetStateSetterType } from 'src/Types/UseFormTypes';

export type ContextMenuState = {
  x: number;
  y: number;
  rect: { x: number; y: number };
};

type ContextMenuHook = {
  handleRightClick: (event: MouseEvent<HTMLDivElement>) => void;
  handleCloseContextMenu: () => void;
  contextMenu: ContextMenuState | null;
  setContextMenu: SetStateSetterType<ContextMenuState | null>;
  handleOptionClick: (type: string) => void;
};

type XY = { x: number; y: number };

export const menuItems = [{ id: 3, label: 'Dummy Item 3', value: 'pin' }];

const useDynamicFormContextMenu = ({
  elementRef,
}: {
  elementRef: RefObject<HTMLDivElement | null>;
}): ContextMenuHook => {
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null);
  const { fullData, pinSelected, setPinSelected, added, addSelected, addForm } =
    useContext(DynamicFormContext);

  const [selectedField, setSelectedField] = useState<XY | null>(null);

  useEffect(() => {
    if (contextMenu) {
      const timeout = setTimeout(() => {
        setContextMenu(null);
        setPinSelected(null);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [contextMenu]);

  const handleRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const x: number = e.clientX;
    const y: number = e.clientY;
    const element = elementRef.current;
    if (element) {
      const rect: DOMRect = element.getBoundingClientRect();
      setContextMenu({ x, y, rect });
      const { gridX, gridY } = calculateGridCoordinates(x, y, rect);
      setSelectedField({ x: gridX, y: gridY });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleOptionClick = (type: string) => {
    const getIndex: FormFieldEditType = fullData.find((data) => type === data.type);
    if (selectedField && selectedField.x !== undefined) {
      setPinSelected({ ...selectedField, ...getIndex });
    }
  };

  useEffect(() => {
    if (pinSelected) {
      addForm(pinSelected.index);
      addSelected();
      handleCloseContextMenu();
    }
  }, [pinSelected]);

  return {
    handleRightClick,
    handleCloseContextMenu,
    contextMenu,
    setContextMenu,
    handleOptionClick,
  };
};

export default useDynamicFormContextMenu;
