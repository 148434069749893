import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';
import theme from '../../Theme';

const useStyles = makeStyles({
  root: {
    borderRadius: '10px',
    border: '2px solid transparent',
    paddingBottom: '0',
    '@media all': {
      width: 400,
      height: 100,
    },
    '@media all and (max-width:2200px)': {
      width: 300,
      height: 100,
    },
    '@media all and (max-width:1700px)': {
      width: 200,
      height: 100,
    },
    '@media all and (max-width:1200px)': {
      width: 150,
      height: 140,
      marginBottom: '40px',
    },
    '@media all and (max-width:750px)': {
      width: 150,
      height: 140,
      marginBottom: '40px',
    },
    '&:hover': {
      boxShadow: '0px 4px 4px rgb(0 0 0 / 18%)',

      cursor: 'pointer',
      '& pos': {
        color: colors.primary.color,
      },
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    color: colors.ternary.color,
    margin: '0px',
  },
  count: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: 'auto',
    margin: '0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  active: {
    boxShadow: '0px 4px 4px rgb(0 0 0 / 18%)',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    cursor: 'pointer',
    '& p': {
      color: colors.primary.color,
    },
    '& h4': {
      color: colors.primary.color,
    },
  },
});

export default function SimpleCard({ count, active, title }: any) {
  const classes = useStyles();
  const username = count;
  return (
    <Card className={`${classes.root} ${active ? classes.active : ''}`}>
      <CardContent>
        <p className={classes.pos} color="textSecondary">
          {title}
        </p>
        {title === 'Change Username' ? (
          <h4 className={classes.count}>{username}</h4>
        ) : (
          <h4 className={classes.count}>{count}</h4>
        )}
      </CardContent>
    </Card>
  );
}
