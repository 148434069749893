import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  container: {
    height: '525px',
    borderRadius: '18px',
  },
  btn: {
    float: 'left',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  wrapper: {
    backgroundColor: '#fff',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:650px)': {
      padding: '0',
      margin: '30px 0px 20px',
    },
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    background: '#dfe0eb40',
    alignItems: 'center',
    height: '100px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    background: '#dfe0eb40',
    alignItems: 'center',
  },
  h3: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
  img: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  content: {
    minHeight: '300px',
  },
}));
