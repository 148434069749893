import { Icon } from '@iconify/react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import colors from 'src/Theme/colors';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { deleteParticipant } from 'src/Services/Auth/Actions/EventActions';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  icon: {
    color: colors.primary.color,
    fontSize: '25px',
    cursor: 'pointer',
  },
});
function ParticipantDelete({
  userId,
  eventId,
  reload,
  status,
}: {
  userId: String;
  eventId: String;
    reload: Function;
    status: Boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm: any = useConfirm();

  const deleteparticipantClick = () => {
    confirm({
  description: (status === true) ? 
    `${t("popup.It's an approved participant.")  } ${t('popup.Are you sure you want to delete the participant?')}` : 
    t('popup.Are you sure you want to delete the participant?'),
})
.then(() => {
      deleteParticipant(eventId, userId).then(() => {
        toast.success('Participant Deleted Successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };

  return (
    <Icon
      icon="ant-design:delete-twotone"
      className={classes.icon}
      onClick={() => deleteparticipantClick()}
    />
  );
}

export default ParticipantDelete;
