import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';

export default makeStyles(() => ({
  icon: {
    color: colors.primary.color,
    fontSize: '35px',
    marginRight: '10px ',
    cursor: 'pointer',
  },
}));
