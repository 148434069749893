import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { RallyType } from 'src/Types/RallyType';
import { EventType } from 'src/Types/EventType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import useStyles from './RallyListingStyle';
import AVATAR from './compregLogo.png';
import { formateDate } from '../../../Services/Utils';
import RallyParticipantCount from './components/RallyParticipantCount';
import { RallyStatusLabel } from '../../../Components/Utils/RallyStatusLabel';
import RallyFileUploadCount from './components/RallyFileUploadCount';
import EnrollStatusLabel from './components/EnrollStausLabel';

type RallyListingUserType = {
  list: Array<RallyType>;
  event: EventType;
  reload: Function;
  id: ObjectIdType;
  isEventEnrolled: boolean;
  isApproved: boolean | undefined;
  eventStatus: string;
};

function RallyListingUser({
  list,
  event,
  reload,
  id,
  isEventEnrolled,
  isApproved,
  eventStatus,
}: RallyListingUserType) {
  const classes = useStyles();
  return (
    <TableBody>
      {list.map((item: RallyType) => (
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell className={classes.tbody}>
            <div className={classes.wrap}>
              <div className={classes.imgWrap}>
                <img
                  src={item.rallyImage || AVATAR}
                  className={classes.img}
                  alt="avatar"
                />
              </div>
              <div className={classes.rallyName}>{item.rallyName}</div>
            </div>
          </TableCell>
          <TableCell className={classes.tbody}>
            <p className={classes.grey}>
              {formateDate(item.startDateTime)}
              {' / '}
              {formateDate(item.endDateTime)}
            </p>
          </TableCell>
          <TableCell className={classes.tbody}>
            <p className={classes.participants}>
              <RallyParticipantCount
                item={item}
                id={id}
                rallyOrgId={event?.organizer?._id}
              />
            </p>
          </TableCell>
          <TableCell className={classes.tbody}>
            <div />
            <RallyStatusLabel data={item} />
          </TableCell>
          <TableCell className={classes.tbody}>
            <p className={classes.participants}>
              <RallyFileUploadCount item={item} />
            </p>
          </TableCell>
          <TableCell className={classes.tbody}>
            {EnrollStatusLabel({
              item,
              isEventEnrolled,
              reload,
              isApproved,
              eventStatus,
            })}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
export default RallyListingUser;
