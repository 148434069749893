/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { getAllFormsByEventId } from 'src/Services/Auth/Actions/EventActions';
import { findExportlog, getDocDetails } from 'src/Services/Auth/Actions/OrganizerActions';
import { RootState } from 'src/Store';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import DocModal from '../DocModal';
import {
  DocField,
  ExportDetails,
  ExportProps,
  FetchExportDetails,
  RallyType,
} from '../types/docType';
import { fileResource } from '../util';

const useFetchExportDetails = (
  eventId: string,
  rallyId: string,
  resource: string,
  organizerId: string,
  initialSortApi: any,
  id: string,
  reportName: string,
  reload: any,
): FetchExportDetails & { handleClick: () => Promise<void> } => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await findExportlog(resource, eventId);
        if (response.data && typeof response.data === 'object') {
          const { data } = response;
          dispatch({
            type: 'TABLE_SORT',
            payload: {
              table: `${resource}Exporter`,
              sort: data.data,
            },
          });
          updateExportDetails({ formId: data?.data?.formId });
          refreshDefaultFields(data?.data?.formId);
          initialSort.formId = data?.data?.formId;
        } else {
          throw new Error('API error: Bad Request');
        }
      } catch (error) {
        const sort: ExportProps = {
          sort: 'desc',
          sortBy: 'createdAt',
          exportDocType: '',
          sourceColumn: [],
          destinationColumn: [],
          orientation: 'landscape',
        };
        if (resource === fileResource.eventParticipant) {
          sort.formId = [];
        }
        dispatch({
          type: 'TABLE_SORT',
          payload: {
            table: `${resource}Exporter`,
            sort,
          },
        });
        initialSort.formId = [];
        console.error('Network error:', error);
      }
    };
    if (!initialSortApi) {
      fetchData();
    }
  }, [resource, eventId]);

  const initialSort = useSelector(
    (state: RootState) => state.table[`${resource}Exporter`],
  );

  const refreshDefaultFields = async (formId: DocField[]) => {
    if (formId) {
      updateExportDetails({ formId });
    }
    const newField: DocField[] = [];
    const response = await getDocDetails({
      resource,
      eventId,
      formId,
      organizerId,
    });
    response.data?.data?.defaultFields.forEach((field: DocField) => {
      const results: DocField = {
        ...field,
        id: field._id,
        _id: uuidv4(),
      };
      newField.push(results);
    });

    const defaultFieldsArray: Array<DocField> = newField || [];
    updateExportDetails({ defaultFields: defaultFieldsArray });
    updateExportDetails({ isData: false });
  };

  const onCancel = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateExportDetails({ showModal: false });
  };

  // Onclick of the docDownload button (DocButton)
  const handleClick = async () => {
    updateExportDetails({ isData: true });
    const formId: DocField[] = initialSortApi
      ? initialSortApi.formId
      : initialSort?.formId || exportDetails.formId;
    if (resource === fileResource.eventParticipant) {
      try {
        updateExportDetails({ rallyId });
        const response = await getAllFormsByEventId(eventId);
        const formListingArray = response.data.forms;
        const formList = await formListingArray?.flatMap(
          (form: { _id: string; formName: string; rallyId: Array<RallyType> }) =>
            form?.rallyId?.map((rallyObj: RallyType) => ({
              _id: form._id,
              displayName: form.formName,
              label: form.formName,
              rallyId: rallyObj._id,
              rallyName: rallyObj.rallyName,
            })),
        );
        const formattedFormListing: DocField[] = _.uniqWith(formList, _.isEqual);
        updateExportDetails({ formListing: formattedFormListing });
      } catch (error) {
        updateExportDetails({ formListing: [] });
      }
    }
    refreshDefaultFields(formId);
    updateExportDetails({ showModal: true });
  };

  // This reducer is responsible for storing & updating data
  const [exportDetails, updateExportDetails] = useReducer(
    (data: ExportDetails, partialData: Partial<ExportDetails>) => ({
      ...data,
      ...partialData,
      eventId,
    }),
    {
      showModal: false,
      isData: false,
      defaultFields: [],
      formListing: [],
      resource,
      eventId,
      rallyId,
      refreshDefaultFields,
      onCancel,
      organizerId,
      initialSortApi,
      id,
      reportName,
      reload,
      formId: [],
    },
  );

  const modal = useModalDialog();
  const setOpenDocModal = useMemo(() => modal(DocModal, 'Doc-Modal'), []);
  useEffect(() => {
    setOpenDocModal(exportDetails.showModal, {
      exportDetails,
      overrideBody: true,
    });
  }, [exportDetails.isData, exportDetails.showModal, exportDetails.defaultFields]);

  return { exportDetails, handleClick };
};

export default useFetchExportDetails;
