/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from '@iconify/react';
import { Fade, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'src/Theme';
import colors from 'src/Theme/colors';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    padding: '30px 40px',
    position: 'relative',
    width: '40%',
    maxHeight: '650px',
    overflow: 'auto',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  wrapper12: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desc: {
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
  closeIcons: {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#bf2331',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
});

function ModalPopup({ showModel, onCancel, description, modalProps, setOpenPopup }) {
  const classes = useStyles();
  const ModalComponent = description;
  if (!description) {
    return null;
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModel}
        onClose={onCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModel}>
          {modalProps?.overrideBody ? (
            <ModalComponent {...modalProps} setOpenPopup={setOpenPopup} />
          ) : (
            <div className={classes.paper}>
              <Icon
                icon="carbon:close-outline"
                onClick={onCancel}
                className={classes.closeIcons}
              />
              <ModalComponent {...modalProps} setOpenPopup={setOpenPopup} />
            </div>
          )}
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalPopup;
