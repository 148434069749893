import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import EventTypeListing from '../../Containers/EventTypes/EventTypeListing';

function EventTypes() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('eventTypes.Event Type Management')} />

        <EventTypeListing />

        {/* <BreadCrumbs/> */}
      </Dashboard>
    </div>
  );
}

export default EventTypes;
