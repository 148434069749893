import { toast } from 'react-toastify';

function SuccessToast(toastMsg) {
  toast.success(toastMsg, {
    position: 'top-center',
    autoClose: 5000,
  });
}

export default SuccessToast;
