/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import usePageBar from 'src/Hooks/usePageBar';
import PageBarBase from './PageBarBase';

export default function PageBar({
  topActionButtons,
  showSearchBar,
  showTopActionButton,
}) {
  const {
    handleMobileMenuOpen,
    handleMobileMenuClose,
    mobileMoreAnchorEl,
    mobileMenuId,
    isMobileMenuOpen,
  } = usePageBar();

  const renderButton = (type: string) => showTopActionButton(type);

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {topActionButtons.map((buttonData, index) => (
        <MenuItem key={index}>{renderButton(buttonData)}</MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <PageBarBase
        popUp={false}
        leftContent={showSearchBar('large')}
        rightContent={topActionButtons.map((buttonData) => renderButton(buttonData))}
        handleMobileMenuOpen={handleMobileMenuOpen}
      />
      {renderMobileMenu}
    </>
  );
}
