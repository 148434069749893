import React, { useState } from 'react';
import BackArrow from 'src/Components/BackButton/BackArrow';
import { Loader } from 'src/Services/Utils';
import { Icon } from '@iconify/react';
import { FormStrokedButton, Search } from 'src/Components/Utils';
import { useTranslation } from 'react-i18next';
import useStyles from './editNewEventFormStyle';
import Tick from './ImageConfig/tick.png';

function FormMenu({
  setSearchForm,
  formName,
  setFormName,
  pathname,
  preview,
  isLoading,
  formPreviewParticipant,
}) {
  const [formNameEdit, setFormNameEdit] = useState<any>(false);
  const { t } = useTranslation();

  const classes = useStyles();
  const changeFormName = (e) => {
    setFormName(e.target.value);
  };
  const onFileNameChangeTick = () => {
    if (formName.length > 0) {
      setFormNameEdit(false);
    }
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.leftWrap}>
          <BackArrow />
          {formNameEdit === true ? (
            <div>
              <img
                className={classes.tick}
                src={Tick}
                onClick={onFileNameChangeTick}
                alt="tick"
              />
              <input
                type="text"
                className={classes.editFormName}
                name="fileName"
                value={formName}
                onChange={changeFormName}
              />
            </div>
          ) : (
            <>
              <Icon
                className={classes.editIconPen}
                icon="feather:edit-3"
                onClick={() => formName.length > 0 && setFormNameEdit(!formNameEdit)}
              />
              <p className={classes.formNameDefault}>{formName}</p>
            </>
          )}
        </div>
        <div className={classes.rightWrap}>
          <Search
            className=""
            onChange={(e) => {
              setSearchForm(e.target.value);
            }}
          />
          <FormStrokedButton
            name={t('addNewForm.Preview as Participant')}
            onClick={formPreviewParticipant}
            className=""
          />
          <Loader isLoading={isLoading} type="ThreeDots">
            <FormStrokedButton
              name={t('addNewForm.Save & Preview')}
              onClick={preview}
              className=""
            />
          </Loader>
        </div>
      </div>
    </div>
  );
}

export default FormMenu;
