import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  createEventStatus,
  getEventStatusById,
  updateEventStatus,
} from 'src/Services/Auth/Actions/EventActions';
import FormButton from 'src/Components/Utils/FormButton';
import { Field, Form, Formik } from 'formik';
import FormLabel from 'src/Components/Utils/FormLabel';
import ErrorMessageCustom from 'src/Components/Utils/ErrorMessageCustom';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import useCreateUpdateForm from 'src/Hooks/useCreateUpdateForm';
import { convertWordToKey } from 'src/Services/Utils';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { OpenPopupFunction } from 'src/Types/FileType';
import useStyles from '../FileTypes/FileTypeListingStyle';

function AddUpdateEventStatus({
  updateId,
  reload,
  setOpenPopup,
}: {
  updateId: ObjectIdType;
  reload: () => void;
  setOpenPopup: OpenPopupFunction;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const statusValidationSchema = Yup.object().shape({
    status: Yup.string().required(t('Event Status.Event status is required')),
  });

  const successResponseHandler = (response) => {
    setOpenPopup(false);
    reload();
    SuccessToast(updateId ? t('Event Status.updated') : t('Event Status.created'));
  };
  const errorResponseHandler = (error) => {
    const message = error.response.data.error;
    switch (message) {
      case 'Event Status is readonly':
        ErrorToast(t('Event Status.notEditable'));
        break;
      case 'Event Status already exists':
        ErrorToast(t('Event Status.existingError'));
        break;
      default:
        ErrorToast(t('other.error'));
    }
    console.error('Error occurred during add or update event status:', error);
  };
  const { formData, setFormData, isLoading, handleSubmit } = useCreateUpdateForm({
    updateId,
    fetchById: getEventStatusById,
    createFn: createEventStatus,
    updateFn: updateEventStatus,
    onSuccess: successResponseHandler,
    onFailure: errorResponseHandler,
    initialFormState: { status: '' },
  });
  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={statusValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const updateData = {
          ...values,
          key: convertWordToKey(values.status),
        };
        setSubmitting(true);
        handleSubmit(updateData, { setSubmitting });
      }}
    >
      {({ isSubmitting, values, handleChange, errors, touched }) => (
        <Form>
          <div className={classes.titleWrapper}>
            <h3>{updateId ? t('Event Status.update') : t('Event Status.addNew')}</h3>
            <FormButton
              className="btn"
              name={t('forms.Submit')}
              isLoading={isSubmitting}
            />
          </div>
          <div className="input-1">
            <FormLabel name={t('Event Status.Event Status')} require />
            <Field
              className={classes.input}
              text="status"
              type="text"
              name="status"
              value={values.status}
              onChange={handleChange}
            />
            <ErrorMessageCustom errors={errors} field="status" touched={touched} />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AddUpdateEventStatus;
