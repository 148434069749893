import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { RootState } from 'src/Store';
import Share from 'src/Components/Utils/Share';
import CanAccess from 'src/Components/Utils/CanAccess';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import CustomTable, { TopActionButtonType } from 'src/Components/Table/Table';
import TableFilter from 'src/Components/Table/TableFilter';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import topActions from 'src/Types/Enums/EnumTopActions';
import StatusLabel from 'src/Components/Utils/StatusLabel';
import UserType from 'src/Types/UserType';
import handleLongWords from 'src/Components/Utils/HandelLongWords';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { EventType } from 'src/Types/EventType';
import useAuthorizedUserClick from 'src/Hooks/useAuthorizedUserClick';
// import DragAndDrop from 'src/Components/DragAndDrop/DragAndDrop';
import useApiCall from 'src/Hooks/useApiCall';
import DragAndDrop from 'src/Components/DragAndDrop/DragAndDrop';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import WarningToast from 'src/Components/Utils/WarningToast';
import useFetchExportDetails from 'src/Components/DocDownload/hooks/useFetchExportDetails';
import { fileResource } from 'src/Components/DocDownload/util';
import AVATAR from './compregLogo.png';
import {
  eventListingSort,
  userEventListingSort,
  userEvents,
  sendUserEventInvitation,
  importEventsCSV,
  sampleEventCSV,
} from '../../../Services/Auth/Actions/EventActions';
import {
  axiosResponseToFileDownload,
  createErrorString,
  createFormDataFromFile,
  formateDate,
} from '../../../Services/Utils';
import useStyles from './EventListingStyle';
import ParticipantActions from './ParticipantActions';
import AuthActions from './AuthActions';
import EventDelete from './EventDelete';
import EventListRedirection from './EventListRedirection';

function EventsListing({
  profileDetails,
  showMoreOptions,
}: {
  profileDetails?: UserType;
  showMoreOptions: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState('');
  const role = useSelector((store: RootState) => store.role.role);
  const { id }: { id: ObjectIdType } = useParams();
  const [filterId, setFilterId] = useState<string>('all');
  const { authorizedUserClick } = useAuthorizedUserClick();
  const language = useSelector((state: RootState) => state.language.value);

  type SortOptions = {
    sortBy: string;
    sort: string;
  };
  const getUserEvents = async ({
    sort,
    page,
    rowsPerPage,
    search,
    filter,
  }: {
    sort: SortOptions;
    page: number;
    rowsPerPage: number;
    search: string;
    filter?: string;
  }) => {
    const params = {
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
      filter,
      id,
    };

    try {
      const response = await userEvents(params);
      const { eventData, totalCount } = response.data.response;
      setCurrentDate(response.data.currentDate);
      return { data: eventData, totalCount };
    } catch (error) {
      console.error('Failed to fetch user events:', error);
      throw error;
    }
  };

  // eslint-disable-next-line consistent-return
  const reloader = ({ sort, page, rowsPerPage, search }) => {
    if (role === 'participant') {
      return userEventListingSort({
        sortBy: sort.sortBy,
        sort: sort.sort,
        page,
        pageSize: rowsPerPage,
        search,
      }).then((response) => {
        const data = response.data.events;
        const totalCount = response.data.totalCount || response.data.count;
        setCurrentDate(response.data.currentDate);
        return {
          data,
          totalCount,
        };
      });
    }
    return getUserEvents({ sort, page, rowsPerPage, search });
  };

  const reload = async ({ sort, page, rowsPerPage, search }) => {
    if (filterId !== 'all') {
      return getUserEvents({
        sort,
        page,
        rowsPerPage,
        search,
        filter: filterId,
      });
    }
    if (role === 'participant' || profileDetails) {
      return reloader({ sort, page, rowsPerPage, search });
    }

    return eventListingSort({
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
      role,
    }).then((response) => {
      const data = response.data.events;
      const { totalCount } = response.data;
      setCurrentDate(response.data.currentDate);
      return {
        data,
        totalCount,
      };
    });
  };

  const options = [
    {
      value: 'upcoming',
      label: t('overview.Upcoming Events'),
    },
    {
      value: 'past',
      label: t('overview.Past Events'),
    },
  ];
  const [callApi, importLoader, data, error] = useApiCall();
  const exportDesignerData = {
    eventId: '',
    rallyId: '',
    resource: fileResource.event,
    organizerId: '',
    initialSortApi: '',
    id,
    reportName: '',
    reload,
  };
  const { handleClick } = useFetchExportDetails(
    exportDesignerData.eventId,
    exportDesignerData.rallyId,
    exportDesignerData.resource,
    exportDesignerData.organizerId,
    exportDesignerData.initialSortApi,
    exportDesignerData.id,
    exportDesignerData.reportName,
    exportDesignerData.reload,
  );
  const getTopActionButton = (): TopActionButtonType[] => {
    if (role === 'superadmin' || role === 'organizer' || role === 'eventOrganizer') {
      const topActionButton: TopActionButtonType[] = [
        {
          type: topActions.linkedButton,
          extraProps: {
            to: '/events/add-new',
            name: t('events.Add New'),
          },
        },
        {
          type: topActions.buttonPopup,
          extraProps: {
            label: 'others.Import CSV',
            loading: importLoader,
            component: DragAndDrop,
            onClick: async ({ selectedFile, setOpenPopup }) => {
              if (!selectedFile) {
                WarningToast(t('others.Please select a valid Excel file'));
                return;
              }
              const formData = createFormDataFromFile('file', selectedFile);
              await callApi(importEventsCSV({ formData, language }), {
                onSuccess: (response) => {
                  if (response.data.error.length !== 0) {
                    const error = createErrorString(response);
                    ErrorToast(error);
                  } else {
                    setOpenPopup(false);
                    SuccessToast(t('users.Organiser Imported'));
                  }
                },
                onError: (error) => {
                  ErrorToast(error || 'Failed to retrieve data!');
                },
              });
            },
            sampleCsvHandleClick: () => {
              sampleEventCSV()
                .then((response) => {
                  axiosResponseToFileDownload({
                    fileName: `Event list Sample.xlsx`,
                    response,
                  });
                })
                .catch(() => {
                  ErrorToast(t('others.Something went wrong'));
                });
            },
            modalName: 'eventImport',
          },
        },

        {
          type: topActions.filter,
          extraProps: {
            id: filterId,
            setId: setFilterId,
            filterOptions: options,
            component: TableFilter,
          },
        },
        {
          type: topActions.button,
          extraProps: {
            onClick: () => {
              handleClick();
            },
            name: t('events.Export'),
          },
        },
      ];
      if (profileDetails) {
        // if event listing is from profile event tab other than organizer profile
        return topActionButton.filter((item) => item.type === topActions.filter);
      }
      // if event listing is from main side nav
      return topActionButton.filter((item) => item.type !== topActions.filter);
    }

    return [];
  };

  // eslint-disable-next-line no-shadow
  const tableAction = (item, reload) => {
    if (role === EnumUserRoles.participant) {
      return <ParticipantActions item={item} />;
    }
    if (item.status === EnumEventStatus.Cancelled) {
      return role === EnumUserRoles.eventOrganizer ? (
        <div />
      ) : (
        <div className={classes.tableaction01}>
          <EventDelete reload={reload} id={item._id} item={item} />
        </div>
      );
    }
    return (
      <>
        <CanAccess permission={EnumPermissions.ShowTableActions}>
          <AuthActions item={item} />
        </CanAccess>
        {![EnumEventStatus.Cancelled, EnumEventStatus.EventFinished].includes(
          item.status,
        ) && (
          <Share item={item} load={reload} sendUserInvitation={sendUserEventInvitation} />
        )}
        {showMoreOptions ? <EventDelete reload={reload} id={item._id} /> : null}
      </>
    );
  };

  const countCell = (item: EventType) => {
    if ([EnumEventStatus.Cancelled].includes(item.status)) {
      return (
        <>
          <div>
            {t('events.rallies')}:{' '}
            {item.rallies && item.rallies.length > 0 ? item.rallies.length : `0`}
          </div>
          <div>
            {t('events.participants')}:{' '}
            {item.participants && item.participants > 0 ? item.participants : `0`}
          </div>
          <div>
            {t('events.forms')}: {item.forms && item.forms > 0 ? item.forms : `0`}
          </div>
        </>
      );
    }
    if (role === EnumUserRoles.participant && item.rallies && item.forms) {
      return (
        <>
          <div>
            <EventListRedirection
              pathname="rallies"
              item={item}
              count={item.rallies.length}
              icon={null}
              forceOpen
            />
          </div>
          <div>
            <EventListRedirection
              pathname="forms"
              item={item}
              count={item.forms}
              icon={null}
              forceOpen
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div>
          <EventListRedirection
            pathname="rallies"
            item={item}
            count={item.rallies.length}
            icon={null}
          />
        </div>
        <div>
          <EventListRedirection
            pathname="participants"
            item={item}
            count={item.participants}
            icon={null}
          />
        </div>
        <div>
          <EventListRedirection
            pathname="forms"
            item={item}
            count={item.forms}
            icon={null}
          />
        </div>
      </>
    );
  };

  const companyName = (item) => {
    if (item.status === EnumEventStatus.Cancelled) {
      return <div> {handleLongWords(item.companyname)}</div>;
    }
    return (
      <div
        className={classes.tablebodyorg}
        onClick={() =>
          history.push({
            pathname: `/organizers/details/${item?.organizer?._id}/profile`,
            state: { showGoBack: true },
          })
        }
      >
        {handleLongWords(item.companyname)}
      </div>
    );
  };

  const columns = [
    {
      title: t('events.Event'),
      field: 'eventName',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.wrap}>
          <div className={classes.imgWrap}>
            <img src={row.eventImage || AVATAR} className={classes.img} alt="avatar" />
          </div>
          <div className={classes.eventName}>{row.eventName}</div>
        </div>
      ),
      isSortable: true,
    },
    ...(role !== EnumUserRoles.organizer
      ? [
          {
            title: t('organizers.Organiser'),
            field: 'companyname',
            // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
            data: (row) => (
              <div>
                {row.companyname ? (
                  <Tooltip title={row.companyname}>{companyName(row)}</Tooltip>
                ) : (
                  <>_____________</>
                )}
              </div>
            ),
            isSortable: true,
          },
        ]
      : []),
    {
      title: t('others.count'),
      field: 'emailSrt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <div className={classes.counts}>{countCell(row)}</div>,
      isSortable: false,
    },
    {
      title: t('forms.Start Date'),
      field: 'startDateTime',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <p className={classes.grey}>{formateDate(row.startDateTime)}</p>,
      isSortable: true,
    },
    {
      title: t('forms.End Date'),
      field: 'endDateTime',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>
          <p className={classes.grey}>{formateDate(row.endDateTime)}</p>
        </p>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <p className={classes.grey}>{formateDate(row.createdAt)}</p>,
      isSortable: true,
    },
    {
      title: t('forms.Status'),
      field: 'Status',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <StatusLabel data={row} />,
      isSortable: false,
    },
    ...(role !== EnumUserRoles.participant
      ? [
          {
            title: t('forms.Actions'),
            field: 'actions',
            // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
            data: (
              row,
              // eslint-disable-next-line no-shadow
              reload,
            ) => <div className={classes.actions}>{tableAction(row, reload)}</div>,
            isSortable: false,
          },
        ]
      : []),
  ];

  const onTableDoubleClick = (item: EventType) => {
    const click = () =>
      history.push({
        pathname: `/events/details/${item._id}/details`,
        state: { showGoBack: true, goBackpath: history.location.pathname },
      });
    if (item.status && item.status !== EnumEventStatus.Cancelled) {
      authorizedUserClick(item, click, role === EnumUserRoles.participant);
    }
  };

  return (
    <div>
      <CustomTable
        forceReload={filterId}
        tableName="events"
        columns={columns}
        reload={reload}
        heading={t('events.Events Listing')}
        onDoubleClick={(item) => onTableDoubleClick(item)}
        role={role}
        topActionButtons={getTopActionButton()}
      />
    </div>
  );
}

export default EventsListing;
