import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import WarningToast from 'src/Components/Utils/WarningToast';
import { RootState } from 'src/Store';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { EventType } from 'src/Types/EventType';
import { RallyType } from 'src/Types/RallyType';

type ItemType = EventType | RallyType;
/**
 * Custom hook that checks user authorization to click on an item.
 * @returns {function} A function that accepts an item and a callback. If authorized, the callback is called, else an error is thrown.
 */
function useAuthorizedUserClick() {
  const { t } = useTranslation();
  const store = useSelector((store: RootState) => store.role);
  const { role } = store;
  const userRole = store.user;

  const authorizedUserClick = (
    item: ItemType,
    successCb: () => void,
    overrideAuthorizationCheck: boolean = false,
  ) => {
    if (
      role === EnumUserRoles.superadmin ||
      item.userId === userRole._id ||
      item.eventOrganizer === userRole._id ||
      overrideAuthorizationCheck
    ) {
      successCb();
    } else {
      WarningToast(t('toast.You do not have the authorization to access this'));
    }
  };
  return { authorizedUserClick };
}

export default useAuthorizedUserClick;
