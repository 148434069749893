import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  tbody: {
    padding: '20px 15px',
    fontFamily: typography.fonts.secondary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
  },
  arrowIconActive: {
    color: colors.primary.color,
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
  },
  link: {
    color: colors.secondary.color,
  },
  boxType: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '10px',
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  tbodyName: {
    overflowWrap: 'anywhere',
  },
}));
