import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 500,
    backgroundColor: '#fff',
    fontFamily: typography.fonts.secondary,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },

  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
  },
  editIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    margin: 0,
    marginLeft: '10px',
    cursor: 'pointer',
  },
  viewIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  deleteIcon: {
    fontSize: '20px',
    color: '#E40909',
    margin: 0,
    marginLeft: '10px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  boxType: {
    padding: '20px',
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 650px)': {
      display: 'block',
    },
  },
  wrapper1: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
    boxShadow: 'none',
    '@media only screen and (max-width: 450px)': {
      minWidth: '250px',
      display: 'block',
    },
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  tbody: {
    height: '300px',
    margin: '0 auto',
    textAlign: 'center',
    alignItems: 'center',
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: '17px',
    width: '30%',
    padding: '30px 0',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    fontWeight: 600,
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  btnFilter: {
    padding: '0px',
    marginLeft: '10px',
  },
  icon: {
    color: colors.primary.color,
    fontSize: '30px',
    cursor: 'pointer',
  },
  titleFilter: {
    fontSize: '14px',
    fontFamily: 'Mulish',
    padding: '0 15px 20px',
    fontWeight: 500,
    borderBottom: '1px solid #D4D5DE',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0',
    fontFamily: 'Mulish',
  },
  form: {
    width: '100%',
    border: '1px solid #e0e0e0',
    minHeight: 100,
    margin: 10,
    borderRadius: 10,
  },
  formActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media only screen and (max-width: 650px)': {
      justifyContent: 'end',
    },
    '@media only screen and (max-width: 450px)': {
      display: 'grid',
      justifyContent: 'center',
      padding: '0px 9px 7px 0px',
    },
  },
  rallyName: {
    textAlign: 'left',
    paddingLeft: '20px',
    width: '400px',
    wordBreak: 'break-word',
  },
  formName: {
    textAlign: 'left',
    paddingLeft: '20px',
    width: '400px',
    wordBreak: 'break-word',
    '@media only screen and (min-width: 450px)': {
      width: '100%',
    },
  },
}));
