import React, { useContext } from 'react';
import { Field } from 'formik';
import FormError from 'src/Components/FormError/FormError';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import {
  getTotalCountField,
  getTotalCountFieldForParticipant,
} from 'src/Containers/Events/Rally/components/DynamicForm/util';
import HintField from './Fields/HintField';
import fieldStyles from './FieldStyle';
import FieldLabel from './Fields/FieldLabel';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetTotalField({
  isEditable = false,
  disabled = true,
  onChange,
  error,
  fieldData,
  aditionalProps,
  mode,
}: FormFieldType) {
  const { added } = useContext(DynamicFormContext);
  const { rallyFormFieldLayout, userFormFeild, onHandleChange, isPreviewDisabled } =
    useContext(ParticipantFormPreviewContext);
  const classes = fieldStyles();
  const { label, hint, styles, index, type } = fieldData;

  const getValue = () => {
    if (mode === 'preview') {
      const value = getTotalCountFieldForParticipant(
        fieldData,
        rallyFormFieldLayout,
        userFormFeild,
      );
      return value?.result;
    }
    return aditionalProps?.values;
  };

  const handleChange = (event) => {
    if (mode === 'preview') {
      onHandleChange({
        event,
        index,
        type,
        id: fieldData._id,
      });
    } else {
      onChange();
    }
  };

  const getTotal = () => {
    const result = getTotalCountField(fieldData, added);
    return result.values;
  };

  return (
    <>
      <FieldLabel name={label} styles={styles} />
      {!isEditable ? (
        <Field
          className={classes.input}
          text={label}
          type="text"
          name={label}
          value={getTotal()}
          readOnly={disabled}
          placeholder={getTotal()}
        />
      ) : (
        <>
          <Field
            className={classes.input}
            text={label}
            type="number"
            name={label}
            value={getValue()}
            disabled={
              mode === 'preview' || mode === 'readOnlyPreview'
                ? isPreviewDisabled
                : disabled
            }
            onChange={handleChange}
            placeholder={getValue()}
          />
          <FormError error={error} />
        </>
      )}
      <HintField hint={hint} />
    </>
  );
}

export default GetTotalField;
