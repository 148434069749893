import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  wrapper: {
    height: 'auto',
    marginTop: '15px',
    width: '100%',
    position: 'relative',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    borderRadius: '15px',
  },
  profile: {
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    margin: '50px 20px 0',
  },
  img: {
    width: '75px',
    objectFit: 'cover',
    position: 'absolute',
    left: '10px',
    top: '-10px',
  },
  btnSec: {
    position: 'absolute',
    right: '0px',
    bottom: '-18px',
    '@media all and (max-width:580px)': {
      height: '200px',
      display: 'block',
      bottom: '0px',
      position: 'static',
    },
    '@media all and (min-width:580px) and (max-width: 767px)': {
      height: '90px',
      display: 'block',
      bottom: '0px',
      position: 'static',
    },
  },
  conatiner: {
    height: 'auto',
    display: 'flex',
    paddingTop: '30px',
    '@media all and (max-width:760px)': {
      display: 'inline-block',
    },
  },
  left: {
    width: '60%',
    padding: '15px',
    '@media all and (max-width:760px)': {
      width: '100%',
    },
  },
  right: {
    width: '40%',
    '@media all and (max-width:760px)': {
      width: '100%',
    },
  },
  countSec: {
    display: 'flex',
    width: '100%',
    wordBreak: 'break-all',
    '@media all and (max-width:760px)': {
      justifyContent: 'center',
    },
  },
  countSec11: {
    flex: 2,
    display: 'flex',
    alignItems: 'flex-end',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
    },
    width: '75%',
    wordBreak: 'break-all',
  },
  contactSec: {
    flex: 1,
    // height: '120px'
    // backgroundColor: 'red',
  },
  img1: {
    // border: "1px solid #DFE0EB",
    // borderRadius: "5px",
    width: '75px',
    // margin: "0 auto 10px",
    objectFit: 'cover',
  },
  // avatar: {
  //   width: '70px',
  //   // borderRadius: "50%",
  //   height: '70px',
  // },
  h5: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    color: colors.secondary.color,
    margin: '0',
    textTransform: 'capitalize',
    wordBreak: 'break-all',
  },
  p: {
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    textAlign: 'left' as const,
    marginTop: '-15px',
    '@media all and (max-width: 767px)': {
      marginBottom: '30px',
      // marginTop:'0'
    },
  },
  p2: {
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    maxWidth: '65%',
  },

  count: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    color: colors.primary.color,
    margin: '0',
  },
  text: {
    fontFamily: typography.fonts.secondary,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    textTransform: 'capitalize',
    width: '104px',
    wordBreak: 'break-word',
  },
  email: {
    fontSize: '12px',
    fontFamily: 'Mulish',
    margin: '0 5px 0',
    wordBreak: 'break-all',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  cntSec: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px',
  },
  wrap: {
    marginRight: '30px',
  },
  contactSecWrap: {
    marginLeft: '250px',
    //  position: 'absolute',
    // minHeight: '84px',
    // display: 'unset',
    // backgroundColor: 'red',

    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
      marginLeft: '0',
      paddingLeft: '25px',
      alignItems: 'center',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffffff1f',
    border: '2px solid transparent',
    boxShadow: '0px 10px 10px rgb(0 0 0 / 30%)',
    borderRadius: '20px',
    width: 'auto',
    height: '50%',
    alignItems: 'center',
    // padding: "30px 0",
    // ["@media all and (max-width: 1200px)"]: {
    //     width: "50%",
    // },
    // ["@media all and (max-width: 650px)"]: {
    //     width: "97%",
    // },
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  fullImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    borderRadius: '20px',
  },
  participantSection: {
    height: 'auto',
    backgroundColor: '#fff',
    marginTop: '40px',
    marginLeft: '-20px',
    marginRight: '-20px',
    display: 'flex',
    '@media all and (max-width: 767px)': {
      flexDirection: 'column',
      width: 'auto',
      margin: '0',
      height: 'auto',
    },
  },
  eventDetail: {
    width: '50%',
    padding: '20px 30px',
    '@media all and (max-width: 767px)': {
      width: '100%',
      padding: '10px 20px',
      // height:'auto'
    },
  },
  eventImage: {
    width: '50%',

    '@media all and (max-width: 767px)': {
      width: '100%',
      display: 'none',
    },
  },
  eventImageParticipant: {
    height: '370px',
    maxWidth: '100%',
    float: 'right',
    width: '100%',
    backgroundColor: '#ffffff00',
    objectFit: 'contain',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media all and (max-width: 430px)': {
      width: '100%',
      flexDirection: 'column-reverse',
      padding: '0',
    },
    '@media only screen and (max-width: 1200px)': {
      flexDirection: 'column',
    },
  },
  span: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    borderBottom: '1px solid #D8D8D8',
    color: '#9FA2B4',
    wordBreak: 'break-word',
  },
  icon: {
    fontSize: '25px',
    color: colors.primary.color,
    margin: 0,
  },
  iconClass: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    margin: '0 0 4px',
    left: '0',
    padding: '0 10px',
  },
  border: {
    display: 'flex',
    textAlign: 'left',
    '&:firt-of-type': {
      borderRight: '1px solid #9FA2B4',
    },
  },
  eventStatus: {
    display: 'flex',
    height: '40px',
  },
  eventStatusText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    marginTop: '8px',
  },
  eventStatusTextData: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    marginTop: '8px',
    '@media all and (min-width: 1492px)': {
      width: '134px',
    },
    wordBreak: 'break-word',
    width: '121px',
    height: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
  eventStatusCls: {
    width: 'auto',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    marginTop: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '@media all and (max-width: 767px)': {
      // marginLeft:'0',
      margin: 'auto',
    },
  },
  eventMoreDetails: {
    borderTop: '1px solid #DFE0EB',
    borderBottom: '1px solid #DFE0EB',
    marginTop: '10px',
    display: 'flex',
    padding: '10px',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    '@media all and (max-width: 430px)': {
      flexWrap: 'wrap',
      flexDirection: 'column',
      // display:'inline-block'
    },
  },
  eventTabDetails: {
    marginLeft: '-20px',
    marginRight: '-20px',
  },
  eventTabs: {
    padding: '20px',
    borderBottom: '1px solid #DFE0EB',
    height: '75px',
    width: '100%',
    '@media all and (max-width: 450px)': {
      // flexWrap:'wrap',
      // flexDirection:'column'
      // display:'inline-block',
      height: '100px',
    },
  },
  eventTab: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '21px',
    letterSpacing: '0.4px',
    paddingLeft: '10px',
    cursor: 'pointer',
    paddingRight: '20px',
    color: '#C4C4C4',
  },
  eventTabActive: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.primary.main,
    height: '36px',
    color: '#252733',
    '@media all and (max-width: 450px)': {
      height: '61px',
    },
  },
  enrollBtn: {
    '@media all and (max-width: 767px)': {
      width: 'auto',
    },
  },
  memoSect: {
    color: '#9FA2B4',
    textAlign: 'left',
    height: '60px',
    overflow: 'hidden',
    '@media all and (max-width: 767px)': {
      height: 'auto',
    },
  },
  avatar: {
    // width: "50px",
    height: '50px',
    // border: "2px solid #2148c0",
    borderRadius: '10px',
    objectFit: 'cover',
  },
  linkText: { textDecoration: 'none' },
  eventName: {
    width: '300px',
    wordBreak: 'break-word',
  },
  fromView: {
    fill: '',
  },
  panelf: {
    fill: '',
  },
  '@media only screen and (max-width: 651px)': {
    profile: {
      margin: '5.5px solid #dfe0eb',
      marginBottom: '31px',
    },
    wrap: {
      margin: '0px',
      marginLeft: '10px',
    },
    contactSecWrap: {
      display: 'inline-block',
    },
    panelf: {
      width: '50%',
      minWidth: '-webkit-fill-available',
    },
  },
  '@media only screen and (max-width: 768px)': {
    profile: {
      borderRight: '3.5px solid #dfe0eb',
      borderRadius: '43px',
    },
    h5: {
      textAlign: 'left',
    },
    p2: {
      textAlign: 'left',
    },
    wrap: {},
    text: {
      minWidth: '80px',
      width: 'fit-content',
      fontSize: '13px',
    },
  },
  '@media all and (min-width: 768px)': {
    profile: {
      width: '100%',
      margin: '0px !improtant',
      borderRight: '1.5px solid #dfe0eb',
      borderRadius: '31px',
    },
    h5: {
      textAlign: 'left',
    },
    p2: {
      textAlign: 'left',
    },
    fromView: {},
    contactSec: {
      position: 'absolute',
      right: '21px',
      paddingTop: '30px',
    },
  },
  eventOrganizerName: {
    '@media only screen and (max-width: 1200px)': {
      marginTop: '1.5rem',
    },
  },
}));
