export default {
  primary: {
    color: '#bf2331',
  },
  secondary: {
    color: '#252733',
  },
  ternary: {
    color: '#9FA2B4',
  },
};
