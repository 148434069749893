import { Icon } from '@iconify/react';
import React from 'react';
import useStyles from './styles';

function ContactSectionWrap({
  details,
  icon,
  linkto,
  extraprops = '',
}: {
  details: string;
  icon: string;
  linkto: any;
  // eslint-disable-next-line react/require-default-props
  extraprops?: string;
}) {
  const classes = useStyles();

  const websiteNavigate = () => {
    // eslint-disable-next-line no-shadow
    const website = details || '';
    let websiteCondtion;
    try {
      if (website.startsWith('https://') || website.startsWith('http://')) {
        websiteCondtion = (
          <a href={details} target="_blank" rel="noopener noreferrer">
            <h6 className={classes.email}>{details}</h6>
          </a>
        );
        return websiteCondtion;
      }
      if (website) {
        const https = 'https://';
        websiteCondtion = (
          <a href={https.concat(website)} target="_blank" rel="noopener noreferrer">
            <h6 className={classes.email}>{https.concat(website)}</h6>
          </a>
        );
        return websiteCondtion;
      }
      // websiteCondtion = (
      //   <a href="" target="_blank" rel="noopener noreferrer">
      //     <h6 className={classes.email} />
      //   </a>
      // );
      return null;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  const showContacts = () => {
    if (extraprops === 'website') {
      return (
        <div className={classes.cntSec}>
          <Icon icon={icon} className={classes.editIcon} />
          {websiteNavigate()}
        </div>
      );
    }
    return (
      <>
        <Icon icon={icon} className={classes.editIcon} />
        <a href={linkto}>
          <h6 className={classes.email}>{details}</h6>
        </a>
      </>
    );
  };
  return <div className={classes.cntSec}>{details && showContacts()}</div>;
}

export default ContactSectionWrap;
