import React, { useMemo } from 'react';
import moment from 'moment';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { useTranslation } from 'react-i18next';
import ContactSectionWrap from 'src/Views/Events/EventDetails/ContactSectionWrap';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import { Button } from '../Utils';
import PreviewImage from '../Utils/PreviewImage';
import useStyles from './ProfileHeaderStyle';
import AVATAR from './AvatarImage.svg';
import PROFILE_BG from './Profilebg.svg';
import ProfileCounts from './ProfileCounts';
import CanAccess from '../Utils/CanAccess';
import { CustomToolTip } from '../ToolTip/ToolTip';
import handleLongWords from '../Utils/HandelLongWords';

function profileImagePreview({ profileImage }) {
  return <PreviewImage image={profileImage} />;
}

function ProfileHeader({ details, navTabOnClick, currentPage }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const modal = useModalDialog();
  const SetImagePreviewModal = useMemo(
    () => modal(profileImagePreview, 'profile-image-preview'),
    [],
  );

  const showImagePreview = (profileImage) => {
    SetImagePreviewModal(true, { profileImage, overrideBody: true });
  };

  const getFullName = (details) => {
    if (details.companyname) {
      return `${handleLongWords(details.companyname)}`;
    }
    if (details.firstName || details.lastName) {
      return `${handleLongWords(details.lastName)} ${handleLongWords(details.firstName)}`;
    }
    return '';
  };

  return (
    <div className={classes.profile}>
      <div className={classes.wrapper} style={{ background: `url(${PROFILE_BG})` }}>
        <div className={classes.imageSec}>
          <div
            className={classes.img}
            onClick={() => showImagePreview(details.profileImage)}
          >
            <img
              src={details.profileImage || AVATAR}
              className={classes.avatar}
              alt="AVATAR"
            />
          </div>
          <CustomToolTip title={<div>{getFullName(details)}</div>}>
            <h5 className={classes.h5}>{getFullName(details)}</h5>
          </CustomToolTip>
          <p className={classes.p}>
            {t('users.Member since')} {moment(details.createdAt).format('D.MM.YYYY')}
          </p>
        </div>
        <div className={classes.countSec}>
          <ProfileCounts details={details.eventCount} name={t('users.Events')} />
          <ProfileCounts details={details.rallyCount} name={t('users.Rallies')} />
          <ProfileCounts
            details={details.participantCount}
            name={t('users.Participants')}
          />
        </div>
        <div className={classes.contactSec}>
          <div className={classes.contactSecWrap}>
            <ContactSectionWrap
              details={details.email}
              icon="dashicons:email"
              linkto={`mailto:${details.email}`}
            />
            <ContactSectionWrap
              details={details.phone}
              icon="bytesize:telephone"
              linkto={`tel:${details.phone}`}
            />
          </div>
          <div className={classes.btnSec}>
            <CanAccess permission={EnumPermissions.ShowProfileNavigationButton}>
              <>
                <Button
                  active={currentPage === 'events'}
                  name={t('events.Events')}
                  onClick={() => navTabOnClick('events')}
                  style={{ margin: '10px' }}
                />
                <Button
                  active={currentPage === 'profile'}
                  name={t('users.Profile')}
                  onClick={() => navTabOnClick('profile')}
                />
              </>
            </CanAccess>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
