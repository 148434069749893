import React, { useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import './Style.css';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import PasswordField from 'src/Components/PasswordField/PasswordField';
// eslint-disable-next-line import/no-cycle
import { Icon } from '@iconify/react';
import SubmitOnEnter from 'src/Components/Auth/SubmitOnEnter';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import useEventEnrollNow from 'src/Hooks/useEventEnrollNow';
import { login, getToken } from '../../Services/Auth/Actions/UserActions';
import { ErrorMessageCustom, FilledButton, FormLabel } from '../../Components/Utils';
import LOGIN_IMAGE from './Images/login.png';
import Auth from '../../Layouts/Auth/Auth';
import { validator } from '../../Services/Auth/Validations';
import theme from '../../Theme';

export const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: 'none !important',
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    '&:focus-visible': {
      border: 'none !important',
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  inputIcon: {
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    border: '1px solid #363740',
    padding: '10px 10px',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '20px',
    borderRadius: '5px',
    marginBottom: '10px',
    backgroundColor: 'transparent',
  },

  icon: {
    fontSize: '20px',
  },
  btnWrap: {
    width: '100%',
    clear: 'both',
  },
}));

function LoginView() {
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.language.value);
  const LoginSchema = Yup.object().shape({
    username: validator.USERNAME,
    password: validator.PASSWORD,
  });
  const location = useLocation<any>();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const eventId = params.get('eventId');
  const rallyId = params.get('rallyId');
  const { enrollNowToEvent } = useEventEnrollNow();

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (token) {
        history.push('/dashboard');
      }
    })();
  }, []);

  return (
    <div>
      <Auth heading="Compreg" varient="h1" image={LOGIN_IMAGE}>
        <Container maxWidth="xs" className="container-wrap">
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              // same shape as initial values
              if (values.username) {
                values.username.toLowerCase().replace(/ +/g, '');
              }
              login(values, language)
                .then((response) => {
                  const data = response;
                  const userData = data.data;
                  const { token, user, userPermissions } = userData;
                  const { role } = userData.user;
                  dispatch({
                    type: 'SAVE_TOKEN',
                    payload: token,
                  });
                  dispatch({
                    type: 'SAVE_ROLE',
                    payload: user.participantMode ? 'participant' : role,
                  });
                  dispatch({
                    type: 'SAVE_USER',
                    payload: user,
                  });
                  dispatch({
                    type: 'SAVE_USERPERMISSIONS',
                    payload: userPermissions,
                  });
                  SuccessToast(response.data.message);
                  if (eventId) {
                    enrollNowToEvent(user, eventId, rallyId);
                  } else {
                    history.push('/dashboard');
                  }
                })
                .catch((error) => {
                  ErrorToast(t(`login.${error.response.data.message}`));
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, handleSubmit, values, errors, touched }) => (
              <Form>
                <SubmitOnEnter onSubmit={handleSubmit}>
                  <FormLabel name={t('forms.Username')} />
                  <div className={classes.inputIcon}>
                    <Icon icon="bx:bx-user" className={classes.icon} />
                    <Field
                      className={classes.input}
                      text="Username"
                      type="text"
                      name="username"
                      placeholder={t('signUp.USERNAME')}
                      value={values.username.toLowerCase().replace(/ +/g, '')}
                      data-testid="login-input-username"
                    />
                  </div>
                  <ErrorMessageCustom
                    testid="login-error-username"
                    errors={errors}
                    field="username"
                    touched={touched}
                  />
                  <FormLabel name={t('forms.Password')} />
                  <div className={classes.inputIcon}>
                    <Icon icon="akar-icons:lock-on" className={classes.icon} />
                    <Field
                      className={classes.input}
                      text="password"
                      name="password"
                      placeholder={t('signUp.PASSWORD')}
                      value={values.password}
                      data-testid="login-input-password"
                      component={PasswordField}
                    />
                  </div>
                </SubmitOnEnter>
                <ErrorMessageCustom
                  testid="login-error-password"
                  errors={errors}
                  field="password"
                  touched={touched}
                />
                <p className="text">
                  <Link to="/forgot-password">{t('signUp.Forgot password?')}</Link>
                </p>
                <div className={classes.btnWrap}>
                  {!isSubmitting && (
                    <FilledButton
                      name={t('signUp.Login')}
                      type="submit"
                      disabled={isSubmitting}
                      testid="login-button-submit"
                    />
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={theme.palette.primary.main}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
                <div className="text-wrap">
                  <p>
                    {t('signUp.Don’t have an account?')}{' '}
                    <span>
                      <Link to="/register">{t('signUp.Sign up')}</Link>
                    </span>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Auth>
    </div>
  );
}

export default connect(null, {})(LoginView);
