import { makeStyles } from '@material-ui/core';
import theme from '../../Theme';

export default makeStyles(() => ({
  profile: {
    backgroundColor: '#f2f2f2',
    border: '1px solid #dfe0eb',
    borderRadius: '15px',
    margin: '50px 20px 0',
    '@media all and (max-width: 767px)': {
      margin: '50px 0 25px',
    },
  },
  wrapper: {
    display: 'flex',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    '@media all and (max-width: 767px)': {
      display: 'block',
    },
    borderRadius: '15px',
  },
  imageSec: {
    flex: 1,
    marginTop: '-30px',
  },
  countSec: {
    flex: 2,
    display: 'flex',
    alignItems: 'flex-end',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
    },
  },
  contactSec: {
    flex: 1,
  },
  img: {
    border: '1px solid #DFE0EB',
    borderRadius: '5px',
    width: '75px',
    margin: '0 auto 10px',
    objectFit: 'cover',
  },
  avatar: {
    width: '70px',
    borderRadius: '50%',
    height: '70px',
  },
  h5: {
    //   fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    //   color: colors.secondary.color,
    margin: '0',
    textTransform: 'capitalize',
  },
  p: {
    //   fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    textAlign: 'left' as const,
    // marginTop: "-20px",
    '@media all and (max-width: 767px)': {
      marginBottom: '30px',
      // marginTop:'0'
    },
  },
  p2: {
    //   fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    '@media all and (max-width: 767px)': {
      marginBottom: '30px',
      // marginTop:'0'
    },
  },

  count: {
    //   fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    //   color: colors.primary.color,
    margin: '0',
  },
  text: {
    //   fontFamily: typography.fonts.secondary,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    textTransform: 'capitalize',
  },
  email: {
    fontSize: '12px',
    fontFamily: 'Mulish',
    margin: '0 5px 0',
  },
  editIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    cursor: 'pointer',
  },
  cntSec: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px auto',
  },
  wrap: {
    marginRight: '30px',
  },
  btnSec: {
    position: 'relative',
    top: '30px',
    width: '500px',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      width: 0,
    },
  },
  contactSecWrap: {
    marginLeft: '250px',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
      marginLeft: '0',
      paddingLeft: '25px',
      alignItems: 'center',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    //   backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px 0',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  title: {
    //   ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      // color: colors.primary.color,
    },
  },
  link: {
    cursor: 'pointer',
    //   color: colors.secondary.color,
    '&:hover': {
      // color: colors.primary.color,
    },
  },
  fullImage: {
    height: '300px',
    width: '450px',
  },
  participantSection: {
    height: 'auto',
    backgroundColor: '#fff',
    marginTop: '40px',
    marginLeft: '-20px',
    marginRight: '-20px',
    display: 'flex',
    '@media all and (max-width: 767px)': {
      flexDirection: 'column',
      width: 'auto',
      margin: '0',
      height: 'auto',
    },
  },
  eventDetail: {
    width: '50%',
    padding: '20px 30px',
    '@media all and (max-width: 767px)': {
      width: '100%',
      padding: '10px 20px',
      // height:'auto'
    },
  },
  eventImage: {
    width: '50%',

    '@media all and (max-width: 767px)': {
      width: '100%',
      display: 'none',
    },
  },
  eventImageParticipant: {
    height: '370px',
    maxWidth: '100%',
    float: 'right',
    width: '100%',
    backgroundColor: 'rgb(0 0 0 / 0%)',
    objectFit: 'cover',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media all and (max-width: 430px)': {
      width: '100%',
      flexDirection: 'column-reverse',
      padding: '0',
    },
  },
  span: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    borderBottom: '1px solid #D8D8D8',
    color: '#9FA2B4',
    wordBreak: 'break-all',
  },
  icon: {
    fontSize: '25px',
    //   color: colors.primary.color,
    margin: 0,
  },
  iconClass: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B',
    margin: '0 0 4px',
    left: '0',
    padding: '0 10px',
  },
  border: {
    display: 'flex',
    textAlign: 'left',
    '&:firt-of-type': {
      borderRight: '1px solid #9FA2B4',
    },
  },
  eventStatus: {
    display: 'flex',
  },
  eventStatusText: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    marginTop: '8px',
    '@media all and (max-width: 767px)': {
      display: 'none',
    },
  },
  eventStatusTextData: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    marginTop: '8px',
    '@media all and (max-width: 767px)': {},
    width: '100px',
    wordBreak: 'break-all',
  },
  eventStatusCls: {
    width: 'auto',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    marginTop: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '@media all and (max-width: 767px)': {
      // marginLeft:'0',
      margin: 'auto',
    },
  },
  eventMoreDetails: {
    borderTop: '1px solid #DFE0EB',
    borderBottom: '1px solid #DFE0EB',
    marginTop: '10px',
    display: 'flex',
    padding: '10px',
    // justifyContent: 'space-between',
    '@media all and (max-width: 430px)': {
      flexWrap: 'wrap',
      flexDirection: 'column',
      // display:'inline-block'
    },
  },
  eventTabDetails: {
    marginLeft: '-20px',
    marginRight: '-20px',
  },
  eventTabs: {
    padding: '20px',
    borderBottom: '1px solid #DFE0EB',
    height: '75px',
    width: '100%',
    '@media all and (max-width: 450px)': {
      // flexWrap:'wrap',
      // flexDirection:'column'
      // display:'inline-block',
      height: '100px',
    },
  },
  eventTab: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '21px',
    letterSpacing: '0.4px',
    paddingLeft: '10px',
    cursor: 'pointer',
    paddingRight: '20px',
    color: '#C4C4C4',
  },
  eventTabActive: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.primary.main,
    height: '36px',
    color: '#252733',
    '@media all and (max-width: 450px)': {
      height: '61px',
    },
  },
  enrollBtn: {
    '@media all and (max-width: 767px)': {
      width: '100%',
    },
  },
  memoSect: {
    color: '#9FA2B4',
    textAlign: 'left',
    height: 'fit-content',
    overflow: 'hidden',
    '@media all and (max-width: 767px)': {
      height: 'auto',
    },
  },
  btnOne: {
    backgroundColor: theme.palette.primary.main,
    width: '136px',
    border: '1px solid #A7ACB1',
    padding: '10px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
  },
  head: {
    wordBreak: 'break-all',
  },
}));
