/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import useStyles from '../../style';
import { DraggableExportFieldProps } from '../../types/docType';
import { columnType } from '../../util';

function DraggableExportField({
  draggableProvided,
  draggableSnapshot,
  column,
  item,
}: DraggableExportFieldProps) {
  const classes = useStyles();

  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      style={{
        backgroundColor: draggableSnapshot.isDragging ? '#263B4A' : '#456C86',
        ...draggableProvided.draggableProps.style,
      }}
      className={classes.draggableContainer}
    >
      {column.name === columnType.destination ? (
        <input
          type="text"
          placeholder={item.displayName}
          onChange={(e) => {
            item.displayName = e.target.value;
          }}
          className={classes.inputField}
        />
      ) : (
        <div className={classes.field}>
          {item.formName && <small>{item.formName}</small>}
          {item.label}
        </div>
      )}
    </div>
  );
}

export default DraggableExportField;
