/* eslint-disable import/no-cycle */
import { FormFieldType } from 'src/Types/FormFieldType';
import DynamicormConstants from './DynamicormConstants';

type XY = {
  x: number;
  y: number;
};

export type FormFieldTypeWithCoordinatesType = FormFieldType & XY;

export type CoordinateType = {
  x: number;
  y: number;
  i: number;
};

export type NewCoordinate = {
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxH: number;
  i: string;
};

export type NewCoordinateType = {
  h?: number;
  i: string;
  isBounded?: boolean;
  isDraggable?: boolean;
  isResizable?: boolean;
  maxH?: number;
  maxW?: number;
  minH?: number;
  minW?: number;
  moved?: boolean;
  resizeHandles?: any;
  static?: boolean;
  w?: number;
  x: number;
  y: number;
};

export type PinType = {
  category: string;
  hint: string;
  id: string;
  index: string;
  isCloned: boolean;
  isMultiple: boolean;
  key: string;
  label: string;
  options: string[];
  order: number;
  type: string;
  validations: string[];
  values: any[];
};

export type contextMenuType = XY & { rect: XY };

export const getCoordinates = ({
  item,
  maxY,
  maxHeight = false,
  uuid,
}: {
  item?: any;
  maxY: number;
  maxHeight: boolean;
  uuid?: string;
}) => {
  const TABLE_FIELD_WIDTH = 15;
  const TABLE_FIELD_HEIGHT = 15;

  const FIELD_WIDTH = DynamicormConstants.w;
  const FIELD_HEIGHT = 4;

  const w = item?.type === 'table' ? TABLE_FIELD_WIDTH : FIELD_WIDTH;
  const h = item?.type === 'table' ? TABLE_FIELD_HEIGHT : FIELD_HEIGHT;

  const MIN_WIDTH = 2;
  const MIN_HEIGHT = 4;
  const MAX_HEIGHT = maxHeight ? 4 : 100;

  return {
    x: 1,
    y: maxY,
    w,
    h,
    minW: MIN_WIDTH,
    minH: MIN_HEIGHT,
    maxH: MAX_HEIGHT,
    i: uuid || item?.index,
  };
};

export const getCoordinatesPin = ({
  item,
  maxY,
  maxX,
  maxHeight = false,
  uuid,
}: {
  item?: PinType;
  maxY: number;
  maxX: number;
  maxHeight: boolean;
  uuid?: string;
}): NewCoordinate => ({
  x: maxX,
  y: maxY,
  w: DynamicormConstants.w,
  h: 4,
  minW: 2,
  minH: 4,
  maxH: maxHeight ? 4 : 100,
  i: `${uuid || item?.index}`,
});

export const formLayout = {
  width: '820px',
};

export const sortArray = (array: Array<any>): Array<any> => {
  const data = array.sort((a, b) => (a?.order || 0) - (b?.order || 0));
  return data;
};

export const formFieldsWithInfiniteHeight = [
  'heading',
  'paragraph',
  'image',
  'textarea',
  'table',
];

export const findType = (type: string) => type === 'pin';

type AddedType = { type: string };
export function isItemTypePresent(added: AddedType[]) {
  return added.some((item) => findType(item.type));
}

export const FIELD_HEGIHT = 5;

export const FIELD_HEGIHT_PADDED = 5.2;
