import { useTranslation } from 'react-i18next';
import Header from 'src/Components/Header/Header';
import EventStatusListing from 'src/Containers/EventStatusManagement/EventStatusListing';
import Dashboard from 'src/Layouts/Dashboard/Dashboard';

function EventStatusManagement() {
  const { t } = useTranslation();
  return (
    <Dashboard>
      <Header title={t('Event Status.Event Status Management')} />
      <EventStatusListing />
    </Dashboard>
  );
}

export default EventStatusManagement;
