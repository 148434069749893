/* eslint-disable react/no-unstable-nested-components */
import CustomTable, { TopActionNormalButtonType } from 'src/Components/Table/Table';
import { t } from 'i18next';
import {
  CommonTranslatedResponseHandler,
  formateDateWithoutTime,
} from 'src/Services/Utils';
import topActions from 'src/Types/Enums/EnumTopActions';
import { useMemo } from 'react';
import {
  deleteEventStatusById,
  getEventStatus,
} from 'src/Services/Auth/Actions/EventActions';
import { EventStatusTypesArray } from 'src/Types/EventStatusTypes';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import RowActions from 'src/Components/TableActions/RowActions';
import useStyles from '../FileTypes/FileTypeListingStyle';
import AddUpdateEventStatus from './AddUpdateEventStatus';
import { joinClassNames } from '../Events/EventParticipants/utils';

type TopActionButtonType = TopActionNormalButtonType;

function EventStatusListing() {
  const classes = useStyles();
  const modal = useModalDialog();
  const confirm: any = useConfirm();

  const StatusAddEditModal = useMemo(
    () => modal(AddUpdateEventStatus, 'event-status'),
    [],
  );

  const showAddModalPopup = (reload) => {
    StatusAddEditModal(true, { reload });
  };
  const showEditModalPopup = (reload, row) => {
    if (!row.isEditable) {
      ErrorToast(t('Event Status.notEditable'));
    } else {
      StatusAddEditModal(true, { reload, updateId: row._id });
    }
  };

  const reloadTable = async ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{
    data: EventStatusTypesArray;
    totalCount: number;
  }> => {
    try {
      const response = await getEventStatus({
        sortBy: sort.sortBy,
        sort: sort.sort === 'asc' ? 'ASC' : 'DESC',
        page,
        rowsPerPage,
        search,
      });
      const { data } = response;
      const totalCount = response.headers['x-total-count'];
      return {
        data,
        totalCount,
      };
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  };
  const deleteEventStatus = (row, reload) => {
    if (!row.isDeletable) {
      ErrorToast(t('Event Status.readOnly'));
    } else {
      confirm({
        description: t('Event Status.Delete'),
      }).then(() => {
        const responseHandlerProps = {
          promise: deleteEventStatusById(row._id),
          successCb: () => {
            reload();
          },
          errorCb: (error) => {
            if (error.data.error === 'Event Status is readonly') {
              ErrorToast(t('Event Status.readOnly'));
            }
          },
          successToast: t('Event Status.Deleted'),
        };
        CommonTranslatedResponseHandler(responseHandlerProps);
      });
    }
  };
  const columns = [
    {
      title: t('Event Status.Event Status'),
      field: 'status',
      data: (row) => (
        <div className={classes.wrap}>
          <div>
            <div className={classes.tbodyName}>{row.status}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('rallyTypes.Actions'),
      field: 'actions',
      data: (row, reload) => (
        <RowActions
          actions={[
            {
              onClick: () => showEditModalPopup(reload, row),
              icon: 'feather:edit-3',
              className: joinClassNames(classes.editIcon, classes.rightVisible),
              style: { color: `${!row.isEditable ? 'grey' : ''}` },
            },
            {
              onClick: () => deleteEventStatus(row, reload),
              icon: 'ant-design:delete-twotone',
              className: joinClassNames(classes.icon, classes.rightAuto),
              style: { color: `${!row.isDeletable ? 'grey' : ''}` },
            },
          ]}
        />
      ),
      isSortable: false,
    },
  ];

  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('rallyTypes.Add New'),
        onClick: async ({ reload }) => showAddModalPopup(reload),
      },
    },
  ];

  return (
    <CustomTable
      tableName="eventStatusListing"
      columns={columns}
      topActionButtons={topActionButton}
      heading={t('Event Status.Event Status Listing')}
      onDoubleClick={(item, reload) => showEditModalPopup(reload, item)}
      reload={reloadTable}
    />
  );
}

export default EventStatusListing;
