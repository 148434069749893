// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';

export default function Asynchronous({ search, selectValue, onChange }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const [val, setValue] = React.useState({});
  const loading = open && options.length === 0;
  const role = useSelector((state: RootState) => state.role.role);
  const [disabled, setDisabled] = useState(true);
  React.useEffect(() => {
    let active = true;

    if (!loading || !open) {
      return undefined;
    }

    (async () => {
      const response = await search(searchText);
      const organizers = await response.data.organizers;
      if (active) {
        setOptions(organizers);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchText, open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    setValue(selectValue);
  }, [selectValue]);

  React.useEffect(() => {
    if (role === 'superadmin') {
      setDisabled(false);
    }
  }, [role]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setSearchText('');
        setOpen(false);
      }}
      value={val}
      onChange={onChange}
      getOptionSelected={(option: any, value) => option._id === value._id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
