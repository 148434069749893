const initialState = {};

export default function sortReducer(state = initialState, action?) {
  switch (action.type) {
    case 'TABLE_SORT': {
      return {
        ...state,
        [action.payload.table]: action.payload.sort,
      };
    }

    default:
      return state;
  }
}
