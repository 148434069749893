import fileDefault from './file-blank-solid-240.png';
import fileCSS from './file-css-solid-240.png';
import filePdf from './file-pdf-solid-240.png';
import filePng from './file-png-solid-240.png';
import fileJpeg from './file-jpg-solid-240.png';
import fileWebp from './file-webp-solid-240.png';
import fileXls from './file-xls-solid-240.png';
import fileMp4 from './file-mp4-solid-240.png';
import fileXlsx from './file-xlsx-solid-240.png';
import fileGif from './file-gif-solid-240.png';
import fileCsv from './file-csv-solid-240.png';
import fileWord from './word.png';
import fileZip from './zip.png';
import fileSvg from './svg.png';
// eslint-disable-next-line import/prefer-default-export
export const ImageConfig = {
  default: fileDefault,
  pdf: filePdf,
  png: filePng,
  css: fileCSS,
  jpeg: fileJpeg,
  webp: fileWebp,
  'vnd.ms-excel': fileXls,
  'x-matroska': fileMp4,
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsx,
  'vnd.openxmlformats-officedocument.wordprocessingml.document': fileWord,
  gif: fileGif,
  csv: fileCsv,
  zip: fileZip,
  'x-zip-compressed': fileZip,
  'svg+xml': fileSvg,
};
