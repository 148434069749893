import { createContext } from 'react';

type PreviewContextType = {
  formData: any;
  eventDetails: any;
  formHeaderDetail: any;
  addedPreview: any;
  editClick: any;
  downloadPDF: any;
  pdfLoad: any;
};
const PreviewContext = createContext({} as PreviewContextType);

export default PreviewContext;
