import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'transparent',
    border: 'none !important',
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
    '&:focus-visible': {
      border: 'none !important',
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  inputIcon: {
    display: 'flex',
    height: '20px',
    border: '1px solid #363740',
    padding: '10px 10px',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '20px',
    borderRadius: '5px',
    marginBottom: '10px',
    backgroundColor: 'transparent',
  },

  icon: {
    fontSize: '20px',
  },
}));

function InputFieldWithIcon({ icon, text, type }) {
  const classes = useStyles();
  return (
    <div className={classes.inputIcon}>
      <Icon icon={icon} className={classes.icon} />
      <Field className={classes.input} text={text} type={type} placeholder={text} />
    </div>
  );
}

export default InputFieldWithIcon;
