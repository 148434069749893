import React, { useEffect, useState } from 'react';
import { Color, ColorResult } from 'react-color';
import { assignBorderNamesInitial, BorderType, ColorType } from '../../util';
import {
  MouseEvent,
  StyleSelectDropdownType,
  UseTextEditor,
} from '../types/textEditorTypes';

const useTextEditor = ({ editData, setEditData }: UseTextEditor) => {
  const [color, setColor] = useState<Color | undefined>();
  const [bgColor, setBgColor] = useState<Color | undefined>();
  const [borderColor, setBorderColor] = useState<Color | undefined>();
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const [bgAnchorEl, setBgAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const [borderAnchorEl, setBorderAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const [borderNames, setBorderNames] = useState<string[]>([]);
  const [borderRadius, setBorderRadius] = React.useState<number>(0);
  const [borderWidth, setBorderWidth] = React.useState<number>(0);

  useEffect(() => {
    // We need to fetch the border details from the editData when we open the edit popup window
    setBorderNames(assignBorderNamesInitial({ editData }));
    setBorderRadius(parseInt(editData?.styles?.borderRadius || '0', 10));
    setBorderWidth(parseInt(editData?.styles?.borderWidth || '0', 10));
  }, []);

  // Used to open the color picker
  const handleClick = (event: MouseEvent, type: ColorType) => {
    switch (type) {
      case ColorType.textColor:
        setAnchorEl(event.currentTarget);
        break;
      case ColorType.borderColor:
        setBorderAnchorEl(event.currentTarget);
        break;
      default:
        setBgAnchorEl(event.currentTarget);
        break;
    }
  };

  // Used to close the color picker
  const handleClose = () => {
    setAnchorEl(null);
    setBgAnchorEl(null);
    setBorderAnchorEl(null);
  };

  // To change the color of the text, background & border.
  const onHandleChangeColor = (colorChoosed: ColorResult, type: ColorType) => {
    if (!editData) {
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }

    switch (type) {
      case ColorType.textColor:
        setColor(colorChoosed.hex);
        setEditData({
          ...editData,
          styles: {
            ...editData.styles,
            color: colorChoosed.hex,
          },
        });
        break;
      case ColorType.borderColor:
        setBorderColor(colorChoosed.hex);
        setEditData({
          ...editData,
          styles: {
            ...editData.styles,
            borderColor: colorChoosed.hex,
          },
        });
        break;
      default:
        setBgColor(colorChoosed.hex);
        setEditData({
          ...editData,
          styles: {
            ...editData.styles,
            backgroundColor: colorChoosed.hex,
          },
        });
    }

    handleClose();
  };

  // To change the font size, boldness & alignment
  const onDropDownChange = (selectedOption: StyleSelectDropdownType) => {
    if (!editData) {
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }
    setEditData({
      ...editData,
      styles: {
        ...editData.styles,
        [selectedOption.name]: selectedOption.value,
      },
    });
  };

  // To change the border (Top, Right, Bottom, Left) for each field
  const handleChangeBorder = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!editData) {
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }

    const {
      target: { value },
    } = event;
    const borderNameArray = value as string[];
    setBorderNames(
      // On autofill we get a stringified value.
      event.target.value as string[],
    );

    setEditData({
      ...editData,
      styles: {
        ...editData.styles,
        borderTopStyle: borderNameArray.includes(BorderType.top) ? 'solid' : 'none',
        borderRightStyle: borderNameArray.includes(BorderType.right) ? 'solid' : 'none',
        borderBottomStyle: borderNameArray.includes(BorderType.bottom) ? 'solid' : 'none',
        borderLeftStyle: borderNameArray.includes(BorderType.left) ? 'solid' : 'none',
      },
    });
  };

  // To change the border radius or border width of the field
  const handleChangeSlider = (
    controllerName: string,
    newControllerValue: number | number[],
  ) => {
    if (!editData) {
      console.error('Nothing to change. Edit data is empty');
      return;
    }

    // Use a single setEditData at the end to update editData
    const updatedStyles = { ...editData.styles };

    switch (controllerName) {
      case 'Border Radius':
        setBorderRadius(newControllerValue as number);
        updatedStyles.borderRadius = `${newControllerValue}px`;
        break;
      case 'Border Width':
        setBorderWidth(newControllerValue as number);
        updatedStyles.borderWidth = `${newControllerValue}px`;
        break;
      default:
        console.error('Invalid controllerName');
        return;
    }

    setEditData({
      ...editData,
      styles: updatedStyles,
    });
  };

  return {
    onHandleChangeColor,
    handleClose,
    anchorEl,
    bgAnchorEl,
    borderAnchorEl,
    color,
    bgColor,
    borderColor,
    handleClick,
    onDropDownChange,
    handleChangeBorder,
    borderNames,
    handleChangeSlider,
    borderRadius,
    borderWidth,
  };
};

export default useTextEditor;
