import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'src/Components/Header/Header';
import Dashboard from 'src/Layouts/Dashboard/Dashboard';
import CanAccess from 'src/Components/Utils/CanAccess';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import RestoreView from './RestoreView';
import useStyles from './RestoreViewStyle';

function Restore() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      <Dashboard>
        <Header title={t('users.Restore')} />
        <div className={classes.boxType}>
          <CanAccess permission={EnumPermissions.RestoreEvent}>
            <RestoreView restoreType="Event" />
          </CanAccess>
          <CanAccess permission={EnumPermissions.RestoreOrganiser}>
            <RestoreView restoreType="Organiser" />
          </CanAccess>
        </div>
      </Dashboard>
    </div>
  );
}
export default Restore;
