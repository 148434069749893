import React from 'react';
import CloneEventForm from 'src/Containers/Events/Rally/CloneEventForm';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';

function CloneEventForms() {
  return (
    <div>
      <Dashboard>
        <>
          <Header title="Events" />
          <CloneEventForm />
        </>
      </Dashboard>
    </div>
  );
}

export default CloneEventForms;
