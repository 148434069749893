import moment from 'moment';
import EnumFormFieldAutoPick from 'src/Types/Enums/EnumFormFieldAutoPick';
import { EventRallytypesType } from 'src/Types/EventRallytypesType';
import { EventType } from 'src/Types/EventType';
import {
  AutopickFormType,
  FormFieldAutoPickType,
  ParticipantFormFieldType,
} from 'src/Types/FormFieldAutoPickType';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { RallyType } from 'src/Types/RallyType';
import UserType from 'src/Types/UserType';
import { eventOrganizerType } from 'src/Types/eventOrganizerType';

const defaultImage =
  'https://compreg-live.s3.amazonaws.com/7a309c03-8ada-4138-b68c-9a723fd562d9-compregLogo.png';

export const loadAutoPickValues = (
  formName: String,
  eventData: EventType,
  rallyData?: RallyType,
  rallyTypesData?: Array<EventRallytypesType>,
): FormFieldAutoPickType => {
  const eventOrgId = eventData.eventOrganizer;
  const organizer = eventData.organizer?.userId;
  const eventOrganizer = eventData.organizer?.eventOrganizers.filter(
    (item: eventOrganizerType) => item._id === eventOrgId,
  )[0];

  let autoPickValues: FormFieldAutoPickType = {
    [EnumFormFieldAutoPick.organiser_name]: organizer?.companyname || '',
    [EnumFormFieldAutoPick.organiser_logo]: organizer?.profileImage || defaultImage,
    [EnumFormFieldAutoPick.person_organiser_email]: eventOrganizer?.email || '',
    [EnumFormFieldAutoPick.person_organiser_Picture]:
      eventOrganizer?.profileImage || defaultImage,
    [EnumFormFieldAutoPick.person_organiser_mobile]: eventOrganizer?.phone || '',
    [EnumFormFieldAutoPick.person_organiser_company]: organizer?.companyname || '',
    [EnumFormFieldAutoPick.person_organiser_title]: eventOrganizer?.role || '',
    [EnumFormFieldAutoPick.person_organiser_last_name]: eventOrganizer?.lastName || '',
    [EnumFormFieldAutoPick.person_organiser_first_name]: eventOrganizer?.firstName || '',

    [EnumFormFieldAutoPick.Event_Name]: eventData?.eventName || '',
    [EnumFormFieldAutoPick.Event_Category]: eventData?.eventType?.type || '',
    [EnumFormFieldAutoPick.Event_Logo]: eventData?.eventImage || defaultImage,
    [EnumFormFieldAutoPick.Event_Venue]: eventData?.venue || '',
    [EnumFormFieldAutoPick.Event_Start_Date]: eventData?.startDateTime || '',
    [EnumFormFieldAutoPick.Event_End_Date]: eventData?.endDateTime || '',
    [EnumFormFieldAutoPick.Event_Registration_Last_Date]: eventData?.lastDateOfReg || '',

    [EnumFormFieldAutoPick.Enquiry_Phone]: eventData?.enquiryPhone || 'Enquiry Phone',
    [EnumFormFieldAutoPick.Enquiry_Email]: eventData?.enquiryEmail || 'Enquiry Email',
    [EnumFormFieldAutoPick.Form_Name]: formName || 'Form Name',
  };
  if (rallyData && rallyTypesData) {
    const rallyTypeOptions = rallyTypesData.map((item: EventRallytypesType) => item.type);
    const rallyType = rallyTypesData.find(
      (item: EventRallytypesType) => item._id === rallyData.rallyType,
    )?.type;
    const rallyTypes = {
      rallyType: rallyType || rallyTypeOptions[0],
      options: rallyTypeOptions,
    };
    autoPickValues = {
      ...autoPickValues,
      [EnumFormFieldAutoPick.rally_event_name]: eventData?.eventName || '',
      [EnumFormFieldAutoPick.rally_name]: rallyData?.rallyName || '',
      [EnumFormFieldAutoPick.rally_category]: rallyTypes || '',
      [EnumFormFieldAutoPick.rally_logo]: rallyData?.rallyImage || defaultImage,
      [EnumFormFieldAutoPick.rally_start_date]: rallyData?.startDateTime || '',
      [EnumFormFieldAutoPick.rally_end_date]: rallyData?.endDateTime || '',
      [EnumFormFieldAutoPick.rally_venue]: eventData?.venue || '',
    };
  }
  return autoPickValues;
};

export const LoadAutoPickFormFieldValuesForParticipant = (
  participantData: UserType,
): ParticipantFormFieldType => ({
  [EnumFormFieldAutoPick.pilot_title]: participantData.title || '',
  [EnumFormFieldAutoPick.pilot_name]:
    participantData.firstName || participantData.companyname || '',
  [EnumFormFieldAutoPick.pilot_person_lastname]: participantData.lastName || '',
  [EnumFormFieldAutoPick.pilot_company]: participantData.companyname || '',
  [EnumFormFieldAutoPick.pilot_address]: participantData.address1 || '',
  [EnumFormFieldAutoPick.pilot_address_2]: participantData.address2 || '',
  [EnumFormFieldAutoPick.pilot_postal_code]: participantData.postcode || '',
  [EnumFormFieldAutoPick.pilot_city]: participantData.city || '',
  [EnumFormFieldAutoPick.pilot_country]: participantData.country || '',
  [EnumFormFieldAutoPick.pilot_mobile]: participantData.phone || '',
  [EnumFormFieldAutoPick.pilot_email]: participantData.email || '',
  [EnumFormFieldAutoPick.pilot_web]: participantData.web || '',
  [EnumFormFieldAutoPick.pilot_Picture]: participantData.profileImage || defaultImage,
  [EnumFormFieldAutoPick.pilot_emergency_phone]: participantData.phone || '',
  [EnumFormFieldAutoPick.pilot_emergency_email]: participantData.email || '',
});

export const fillAutoPickedFormFieldValues = (
  formFieldArray: Array<FormFieldEditType>,
  autoPickValues: AutopickFormType,
) =>
  formFieldArray.map((field: FormFieldEditType) => {
    if (field.key in autoPickValues) {
      switch (field.type) {
        case 'dropdown':
          field = {
            ...field,
            defaultDropDownValue: autoPickValues[field.key].rallyType,
            values: autoPickValues[field.key].options,
          };
          break;
        case 'image':
          field = {
            ...field,
            isImage: true,
            imgUrl: autoPickValues[field.key],
            values: [autoPickValues[field.key]],
          };
          break;
        case 'date':
          field.values = [
            moment(new Date(autoPickValues[field.key])).format('YYYY-MM-DD'),
          ];
          break;

        default:
          field.values = [autoPickValues[field.key]];
          if (field.category === 'FormHeader') {
            field = {
              ...field,
              label: autoPickValues[field.key],
            };
          }
          break;
      }
    }
    return field;
  });
