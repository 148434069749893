import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Icon } from '@iconify/react';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import AttachmentTable from './AttachmentTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '75%',
    overflow: 'hidden',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  folderIcon: {
    color: '#007aff',
    fontSize: '20px',
    cursor: 'pointer',
  },
}));

function ViewUploads({ attachment, fileIcon = false }) {
  const classes = useStyles();
  const modal = useModalDialog();
  const AttachmentTablePreview = useMemo(
    () => modal(AttachmentTable, 'fileUploadList'),
    [],
  );

  const handelClick = () => {
    if (!attachment || attachment.length === 0) {
      return;
    }
    AttachmentTablePreview(true, {
      files: attachment,
      setFiles: '',
      setTotalFileSize: '',
      disabled: true,
      overrideBody: true,
    });
  };

  const showClick = () => {
    if (!fileIcon) {
      return attachment?.length || '0';
    }
    if (fileIcon && attachment?.length > 0) {
      return <Icon icon="akar-icons:folder" className={classes.folderIcon} />;
    }
    return '___________';
  };

  return <div onClick={handelClick}>{showClick()}</div>;
}
export default ViewUploads;
