import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    display:'flex',
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '10px',
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '200px',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  arrowIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
  },
  arrowIconActive: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
    color: colors.primary.color,
  },

  link: {
    textDecoration: 'none',
    fontWeight: 600,
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  img: {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imgWrap: {
    borderRadius: '10px',
    height: '50px',
    width: '100px',
    marginRight: '15px',
  },
  boxType: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  tbodyName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '200px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px 0',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },

  wrapper12: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  orgName: {
    wordBreak: 'break-word',
  },
  button123: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      backgroundColor: '#007AFF',
      color: '#fff',
    },
  },
  upload: {
    maxWidth: '165px',
  },
}));
