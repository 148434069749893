import { makeStyles } from '@material-ui/core';
import theme from '../../../Theme/index';

export default makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiGrid-root.MuiGrid-item *, & .MuiGrid-root.MuiGrid-item *::before, & .MuiGrid-root.MuiGrid-item *::after':
      {
        boxSizing: 'inherit',
      },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '75%',
    overflow: 'hidden',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    position: 'relative',
  },
  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    color: '#9FA2B4',
    position: 'absolute',
    top: '4px',
    right: '10px',
  },
  paperinner: {
    padding: theme.spacing(2, 4, 2),
    height: '90vh',
    overflowY: 'scroll',
  },
}));
