import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import FormStrokedButton from './FormStrokedButton';
import { typography } from '../../Theme/index';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
  },
  buttonInactive: {
    backgroundColor: '#fff',
    margin: '10px',
    border: '1px solid #A7ACB1',
    color: '#000',
    fontFamily: typography.fonts.secondary,
    fontSize: '14px',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  btnResponse: {
    '@media only screen and (max-width: 766px)': {  
      minWidth: '250px',
    },
    '@media only screen and (max-width: 480px)': {  
      minWidth: '250px',
    },
  },
}));

function Button(props) {
  const classes = useStyles();
  if (!props.active) {
    return <FormStrokedButton {...props} className={clsx(classes.buttonInactive, props.className === 'btnResponse' ? classes.btnResponse : '' )}  />;
  }
  return (
    <button
      type="submit"
      className={clsx(classes.button, props.className , props.className === 'btnResponse' ? classes.btnResponse : '')}
      onClick={props.onClick}
    >
      {props.name}
    </button>
  );
}

export default Button;
