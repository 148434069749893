/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { v4 as uuidv4 } from 'uuid';
import { NewCoordinateType } from 'src/Containers/Events/Rally/utils';
import { DynamicFormContextType } from 'src/Libs/DynamicForm/context/DynamicFormContext';

/**
 * Filters an array of form fields to remove duplicate fields with the same coordinates.
 * @param {FormFieldEditType[]} tableAddedData - The array of form fields to filter.
 * @returns {FormFieldEditType[]} - The filtered array containing only unique fields.
 */
export const tableFilterAddedEmptyFields = (tableAddedData: FormFieldEditType[]) =>
  tableAddedData.filter(
    (a) =>
      !tableAddedData.find(
        (b) =>
          b.coordinate?.i === a.coordinate?.i && a.type === 'empty' && b.type !== 'empty',
      ),
  );

/**
 * Gets the ID of the empty field from the full data.
 * @param {FormFieldEditType[]} fullData - The array of all form fields.
 * @returns {string} - The ID of the empty field.
 */
const getFieldId = (
  fullData: DynamicFormContextType['fullData'],
  fieldType: string,
): string => {
  const emptyField = fullData.find((d: FormFieldEditType) => d.type === fieldType);
  return emptyField ? emptyField.id : '';
};

/**
 * Removes the table inner fields.
 * @param {FormFieldEditType[]} formArray - The array of form fields to filter.
 * @returns {FormFieldEditType[]} - The filtered array containing form fields without inner fields.
 */
export const filterAddedFieldsByParentWidgetIndex = (
  formArray: FormFieldEditType[],
): FormFieldEditType[] =>
  formArray.filter((data: FormFieldEditType) => !data.parentWidgetIndex);

// Default number of cells in a table
const DEFAULT_TABLE_CELL_COUNT = 2;

/**
 * Default form field values.
 */
export const defaultFormFieldValues = {
  category: 'Other',
  fieldGroup: null,
  group: '',
  hint: '',
  _id: '',
  isCloned: false,
  isMultiple: false,
  label: '',
  options: [],
  order: 0,
  validations: [],
  defaultValues: [],
  defaultDropDownValue: '',
  totalCount: [],
  values: [],
  imgUrl: '',
  tableRows: 0,
  tableCols: 0,
};

/**
 * Generates default empty cells for a table.
 * @param {FormFieldEditType} selectedField - The selected table field.
 * @param {FormFieldEditType[]} fullData - The array of all form fields.
 * @returns {FormFieldEditType[]} - The array of default empty cells.
 */

export const defaultCells = (
  selectedField: FormFieldEditType,
  fullData: DynamicFormContextType['fullData'],
): FormFieldEditType[] => {
  const getCoordinateAndUuid = (
    rowIndex: number,
    colIndex: number,
  ): FormFieldEditType => {
    const uuid = uuidv4();
    const newCellCoordinates = (): NewCoordinateType => ({
      i: uuid,
      x: colIndex * 5,
      y: rowIndex * 5,
      w: 5,
      h: 5,
    });
    return {
      ...defaultFormFieldValues,
      coordinate: newCellCoordinates(),
      index: uuid,
      type: 'empty',
      key: 'empty',
      id: getFieldId(fullData, 'empty'),
      parentWidgetIndex: selectedField.index,
    };
  };

  const tableCells: FormFieldEditType[] = [];

  for (let rowIndex = 0; rowIndex < DEFAULT_TABLE_CELL_COUNT; rowIndex++) {
    for (let colIndex = 0; colIndex < DEFAULT_TABLE_CELL_COUNT; colIndex++) {
      tableCells.push(getCoordinateAndUuid(rowIndex, colIndex));
    }
  }

  return tableCells;
};

/**
 * Handles the addition of form fields, including default empty cells for table fields.
 * @param {FormFieldEditType[]} selectedFields - The array of form fields to be added.
 * @param {FormFieldEditType[]} fullData - The array of all form fields.
 * @returns {FormFieldEditType[]} - The array of form fields with unique indexes, including default empty cells for tables.
 */
export const addEmptyFieldsToFormFields = (
  selectedFields: FormFieldEditType[],
  fullData: DynamicFormContextType['fullData'],
): FormFieldEditType[] => {
  const newSelectedFields: FormFieldEditType[] = [];

  const multiSelected = (fields: FormFieldEditType[]) => {
    const updatedSelected = fields.map((e, index) => {
      if (index !== 0 && e.parentWidgetIndex) {
        const uuid = uuidv4();
        return {
          ...e,
          index: uuid,
          coordinate: { ...e.coordinate, i: uuid },
        };
      }
      return {
        ...e,
      };
    });
    return updatedSelected;
  };

  const updatedSelectedFields = multiSelected(selectedFields);
  updatedSelectedFields.forEach((field) => {
    const uuid = uuidv4();
    const newItem: FormFieldEditType = {
      ...field,
      index: field.isMultiple ? uuid : field.index,
      // ...(field.parentWidgetIndex
      //   ? {
      //       coordinate: { ...field.coordinate, i: field.isMultiple ? uuid : field.index },
      //     }
      //   : {}),
    };

    if (field.type === 'table') {
      // Generate default empty cells for tables.
      const newCellsForTable = defaultCells(newItem, fullData);
      newSelectedFields.push(newItem, ...newCellsForTable);
    } else {
      newSelectedFields.push(newItem);
    }
  });

  return newSelectedFields;
};

/**
 * Filters items to remove based on the index.
 * @param {string} index - The index to filter by.
 * @param {FormFieldEditType[]} added - The array of added form fields.
 * @param {FormFieldEditType[]} removed - The array of removed form fields.
 * @returns {Object} - An object containing the filtered arrays.
 */
export const filterItemsToRemove = (
  index: string,
  added: DynamicFormContextType['added'],
  removed: DynamicFormContextType['removed'],
) => {
  let itemsToRemove = removed.slice();

  const deletingData = added.find((item) => item.index === index);

  // Table inner cell delete
  if (deletingData?.parentWidgetIndex) {
    const currentTable = added.find(
      (item) => item.type === 'table' && item.index === deletingData.parentWidgetIndex,
    );
    if (currentTable) {
      itemsToRemove = itemsToRemove.filter((item) => item !== currentTable);
    }
  }

  // Table delete
  if (deletingData?.type === 'table') {
    const deletedTableCells = added.filter((d) => d.parentWidgetIndex === index);
    if (deletedTableCells.length > 0) {
      itemsToRemove = [...itemsToRemove, ...deletedTableCells];
    }
  }

  const deleteFields = added.filter((val) => !itemsToRemove.includes(val));
  return { deleteFields, itemsToRemove };
};

/**
 * Adds a new cell to a table.
 * @param {string} tableIndex - The index of the table.
 * @param {FormFieldEditType[]} added - The array of added form fields.
 * @param {Function} setAdded - A function to set the updated added array.
 * @param {FormFieldEditType[]} fullData - The array of all form fields.
 */
export const tableOnAddCell = (
  tableIndex: string,
  added: DynamicFormContextType['added'],
  setAdded: DynamicFormContextType['setAdded'],
  fullData: DynamicFormContextType['fullData'],
  width?: number,
  height?: number,
  y?: number,
  x?: number,
) => {
  const uuid = uuidv4();

  const newCellCoordinates = {
    i: uuid,
    x: x || 0,
    y: y || 0,
    w: width || 6,
    h: height || 6,
  };
  const newCellData: FormFieldEditType = {
    ...defaultFormFieldValues,
    coordinate: newCellCoordinates,
    type: 'empty',
    key: 'empty',
    id: getFieldId(fullData, 'empty'),
    parentWidgetIndex: tableIndex,
    index: uuid,
  };
  setAdded([...added, newCellData]);
};
