/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@material-ui/core';
import { FormStrokedButton } from 'src/Components/Utils';
import useStyles from '../../../newEventFormStyle';
import useShowImagePopup from './hooks/useShowImagePopup';
import ImagePreviewPopupWrapper from './ImagePreviewPopup';

function ShowImagePopup({ editData, submitEdit, setOpenPopup }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState(editData);
  const { base64String, onCloseImage, selectImage, submitImage, imagePreviewUrl } =
    useShowImagePopup({
      setShowImageModal: setOpenPopup,
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      styles: {
        ...data.styles,
        objectFit: event.target.checked ? 'contain' : 'inherit',
      },
    });
  };

  return (
    <div>
      <div className={classes.wrapper3} />
      <h3 id="transition-modal-description" className={classes.desc}>
        {t(`formFields.Add A New Image`)}
      </h3>

      <form className={classes.imgPopupFlexCol}>
        <input
          type="file"
          name="uploadfile"
          id="img"
          multiple={false}
          accept="image/*"
          style={{ opacity: 0 }}
          onChange={(event) => {
            selectImage(event);
          }}
        />
        <div className={classes.preview}>
          <label htmlFor="img" className={classes.chooseFile}>
            {t(`others.Choose File`)}
          </label>
          {base64String && <ImagePreviewPopupWrapper imagePreviewUrl={imagePreviewUrl} />}
        </div>
        <div className={classes.imgPopupToggleContainer}>
          <label>{t(`others.Aspect ratio`)}</label>
          <Switch
            checked={data?.styles?.objectFit === 'contain'}
            onChange={handleChange}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
        <div className={classes.imgPopupBottomContainer}>
          <FormStrokedButton
            name={t('forms.Submit')}
            onClick={async (e) => {
              e.preventDefault();
              if (base64String) {
                submitImage(e, data);
              }
              setOpenPopup(false);
              submitEdit({ ...data });
            }}
            className={classes.imgPopupButton}
          />
          <p style={{ cursor: 'pointer' }} onClick={onCloseImage}>
            {t('popup.Cancel')}
          </p>
        </div>
      </form>
    </div>
  );
}

export default ShowImagePopup;
