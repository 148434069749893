import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import {
  changeUsername,
  loggedUser,
  logoutUser,
} from '../../Services/Auth/Actions/UserActions';
import { FormLabel, FilledButton, SimpleCard } from '../Utils';
import useStyles from './ChangeUserNameStyle';
import { validator } from '../../Services/Auth/Validations';
import theme from '../../Theme';

function ChangeUserNameWrapper({ user, from = '' }: { user?: any; from?: string }) {
  const { t } = useTranslation();
  const showFields = () => {
    if (from) {
      return (
        <SimpleCard
          title={`${t('signUp.Change Username')}`}
          count={user.username || 'Username'}
        />
      );
    }
    return <p> {t('signUp.Change Username')}</p>;
  };

  function ChangeUserNameComponent() {
    const ChangeUsernameSchema = Yup.object().shape({
      username: validator.USERNAME,
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    // eslint-disable-next-line no-shadow
    const user = useSelector((store: RootState) => store.role.user);
    const userRole = useSelector((store: RootState) => store.role.user.role);
    return (
      <div className={classes.paper}>
        <div className={classes.wrapper}>
          <h2 id="transition-modal-title" className={classes.title}>
            {t('signUp.Change Username')}
          </h2>
        </div>
        <div id="transition-modal-description">
          <Formik
            initialValues={{
              username: user.username,
            }}
            enableReinitialize
            validationSchema={ChangeUsernameSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              if (user.username === values.username) {
                setFieldError('username', 'Please change username');
                setSubmitting(false);
                return;
              }
              // same shape as initial values
              setSubmitting(true);
              const data: any = {
                ...values,
              };
              if (data.username) {
                data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
              }

              changeUsername(data)
                .then((response) => {
                  dispatch({
                    type: 'SAVE_USERNAME',
                    payload: values.username,
                  });
                  toast.success(response.data.message, {
                    position: 'top-center',
                    autoClose: 2000,
                  });
                  setSubmitting(false);
                  logoutUser(user._id).then(() => {
                    loggedUser(userRole);
                  });
                })
                .catch((error) => {
                  console.log('change username error:', error.response.data.message);

                  toast.error(error.response.data.message, {
                    position: 'top-center',
                    autoClose: 5000,
                  });
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, handleChange, values, errors }) => (
              <Form>
                <FormLabel name={t('signUp.Username')} require />

                <Field
                  className={classes.input}
                  text="username"
                  type="text"
                  name="username"
                  value={values.username.trim().toLowerCase().replace(/ +/g, '')}
                  onChange={handleChange}
                />
                {errors.username ? (
                  <div className="error-code">{t(errors.username)}</div>
                ) : null}
                {/* <ErrorMessage name="username" />  */}

                <div className={classes.btnWrap}>
                  {!isSubmitting && (
                    <FilledButton
                      className={classes.FilledButton}
                      name={t('forms.Submit')}
                      type="submit"
                      disabled={isSubmitting}
                    />
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={theme.palette.primary.main}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  function ChangeUsername() {
    const modal = useModalDialog();

    const ChangeUsernameSetOpen = useMemo(
      () => modal(ChangeUserNameComponent, 'changeuserName'),
      [],
    );

    return (
      <div
        onClick={() => {
          ChangeUsernameSetOpen(true, { overrideBody: true });
        }}
      >
        {showFields()}
      </div>
    );
  }
  return ChangeUsername();
}
export default ChangeUserNameWrapper;
