import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { ColorResult, TwitterPicker } from 'react-color';
import { defaultColorPalette } from '../../util';
import { ColorPickerType, MouseEvent } from '../types/textEditorTypes';
import useStyles from '../style';

export const ColorPicker = ({
  handleClose,
  color,
  onHandleChangeColor,
  handleClick,
  anchorEl,
  title,
}: ColorPickerType) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(e: MouseEvent) => handleClick(e, title)}
        className={classes.colorPickerButton}
        style={{
          backgroundColor: color,
          color: color === '#000000' ? '#FFFFFF' : '#000000',
        }}
      >
        {title}
      </Button>
      <Menu
        id={title}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: 'auto', height: '100%' }}
        classes={{ list: classes.menu }}
      >
        <div style={{ width: '100%', padding: '0' }}>
          <p className={classes.colorPickerTitle}>{`Choose ${title}`}</p>
          <TwitterPicker
            styles={{ default: { card: { boxShadow: 'none' } } }}
            color={color}
            colors={defaultColorPalette}
            triangle="hide"
            onChange={(e: ColorResult) => onHandleChangeColor(e, title)}
          />
        </div>
      </Menu>
    </div>
  );
};
