/* eslint-disable import/prefer-default-export */
import React from 'react';

const ThemeColor = 'grey';
export const removeButtonStyle: React.CSSProperties = {
  position: 'absolute',
  right: '5px',
  top: 0,
  cursor: 'pointer',
  fontSize: '20px',
  color: ThemeColor,
};
export const cloneButtonStyle: React.CSSProperties = {
  position: 'absolute',
  left: '5px',
  top: '5px',
  cursor: 'pointer',
  fontSize: '20px',
  color: ThemeColor,
};
export const addButtonStyle: React.CSSProperties = {
  color: ThemeColor,
};

export const cellStyle = (
  BorderColor: string,
  backgroundColor: string,
  borderWidth: string,
): React.CSSProperties => ({
  border: `1px solid ${BorderColor}`,
  position: 'relative',
  backgroundColor,
  borderWidth,
});

export const mainContainer: React.CSSProperties = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100%',
};
