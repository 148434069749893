import { useContext } from 'react';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FormError from 'src/Components/FormError/FormError';
import FieldLabel from './Fields/FieldLabel';
import HintField from './Fields/HintField';
import fieldStyles from './FieldStyle';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetTextAreaField({
  isEditable = false,
  disabled = true,
  onChange,
  error,
  fieldData,
  aditionalProps,
  mode,
}: FormFieldType) {
  const { isPreviewDisabled, findTextFieldsGeneric, onHandleChange } = useContext(
    ParticipantFormPreviewContext,
  );

  const classes = fieldStyles();
  const { label, hint, styles } = fieldData;

  const valueInPreviewMode =
    mode === 'preview'
      ? findTextFieldsGeneric(fieldData.index, fieldData._id, fieldData) || ''
      : '';

  return (
    <>
      <FieldLabel name={label} styles={styles} />
      <div style={{ width: '100%', height: '60%' }}>
        {isEditable ? (
          <>
            <textarea
              className={classes.textAreaInput}
              name={label}
              value={mode === 'preview' ? valueInPreviewMode : aditionalProps?.values}
              disabled={
                mode === 'preview' || mode === 'readOnlyPreview'
                  ? isPreviewDisabled
                  : disabled
              }
              onChange={
                mode === 'preview'
                  ? (e) =>
                      onHandleChange({
                        event: e,
                        index: fieldData.index,
                        type: fieldData.type,
                        id: fieldData._id,
                      })
                  : onChange
              }
              placeholder={valueInPreviewMode || aditionalProps?.values}
            />
            <FormError error={error} />
          </>
        ) : (
          <textarea
            id={label}
            name={label}
            className={classes.textAreaInput}
            readOnly
            placeholder={aditionalProps?.values}
          />
        )}
        <HintField hint={hint} />
      </div>
    </>
  );
}

export default GetTextAreaField;
