import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: '10px 10px',
    width: '100%',
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '1.6',
    textTransform: 'uppercase',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '4px',
    cursor: 'pointer',
  },
}));
function StrokeButton(props) {
  const classes = useStyles();
  return (
    <button type="submit" className={classes.button}>
      {props.name}
    </button>
  );
}

export default StrokeButton;
