import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '60%',
    padding: '30px',
    position: 'relative',
    '@media all and (max-width: 1200px)': {
      width: '60%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  mainContainer: {
    width: '100%',
    minHeight: '300px',
  },
  droppableContainer: {
    padding: 4,
    width: 250,
    height: '400px',
    overflow: 'auto',
  },
  draggableContainer: {
    userSelect: 'none',
    padding: 16,
    margin: '0 0 8px 0',
    minHeight: '50px',
    color: 'white',
  },
  icons: {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#bf2331',
    flex: 1,
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  disabledBtn: {
    backgroundColor: '#fff',
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    pointerEvents: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  editView: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '64px',
  },
  editing: {
    height: '64px',
  },
  exporterHeading: {
    color: theme.palette.primary.main,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  btnWrap: {
    width: 'auto',
    position: 'relative',
    left: '-98px',
    display: 'flex',
    justifyContent: 'center',
  },
  selectAll: {
    backgroundColor: '#fff',
    border: '1px solid #A7ACB1',
    padding: '8px 30px',
    maxWidth: '100%',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  head: {
    paddingLeft: '61px',
  },
  actions: {
    position: 'absolute',
    top: '542px',
    left: '438px',
    width: '375px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: '24px',
    alignItems: 'flex-end',
    height: '90px',
    '@media all and (min-width: 2560px)': {
      left: '953px',
    },
  },
  options: {
    height: '91px',
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  paperPopup: {
    border: '2px solid transparent',
    boxShadow: '0px 10px 10px rgb(0 0 0 / 30%)',
    borderRadius: '20px',
    width: '25%',
    padding: '0px !important',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '300px',
    position: 'relative',
  },
  inputname: {
    height: '35px',
    width: '320px',
    position: 'relative',
    borderRadius: '4px',
  },
  reportSubmit: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  EditWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '35px',
  },
  name: {
    wordBreak: 'break-all',
    fontWeight: 600,
    fontStyle: 'oblique',
    fontSize: '20px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 'inherit',
  },
  editReportName: {
    height: '42px',
    // width: '170px',
    fontWeight: 600,
    fontStyle: 'oblique',
    fontSize: '20px',
    marginLeft: '20px',
    borderRadius: '6px',
  },
  editIconPen: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  tick: {
    marginLeft: '25px',
    cursor: 'pointer',
  },
  inputField: {
    width: '100%',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
