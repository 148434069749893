import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';

function CanAccessByRole({
  roles,
  children,
  switchRole = false,
  forceRole = undefined,
}: {
  roles: Array<String>;
  children: JSX.Element;
  switchRole?: Boolean;
  forceRole?: EnumUserRoles | undefined;
}) {
  const getUserRole = () => {
    let role: EnumUserRoles;
    if (forceRole !== undefined) {
      role = forceRole;
    } else {
      role = useSelector((store: RootState) =>
        switchRole ? store.role.user.role : store.role.role,
      );
    }
    return role;
  };

  const userRole = getUserRole();

  if (roles.includes(userRole)) {
    return children;
  }
  return null;
}

export default CanAccessByRole;
