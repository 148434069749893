import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';

const commonStyle: CSSProperties = {
  backgroundColor: '#FEC400',
  color: '#fff',
  padding: '4px 15px',
  borderRadius: '25px',
  maxWidth: '172px',
  textTransform: 'uppercase',
  fontSize: '11px',
  fontWeight: 700,
  textAlign: 'center',
};

const useStyles = makeStyles({
  yellow: {
    ...commonStyle,
    backgroundColor: '#FEC400',
  },
  red: {
    ...commonStyle,
    backgroundColor: '#F12B2C',
  },
  ash: {
    ...commonStyle,
    backgroundColor: '#C4C4C4',
  },
  green: {
    ...commonStyle,
    backgroundColor: '#29CC97',
  },
});

function ParticipantStatus({ statusData, participant }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (statusData?.isApproved === true) {
    return <p className={classes.green}>{t('events.Approved')}</p>;
  }

  if (statusData?.isApproved === false) {
    return <p className={classes.red}>{t('events.Declined')}</p>;
  }

  if (participant?.isAccept === true) {
    return <p className={classes.green}>{t('events.Pending approval')}</p>;
  }

  return <p className={classes.yellow}>{t('events.Enroll to Participate')}</p>;
}

export default ParticipantStatus;
