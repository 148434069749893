import axios from 'axios';
import store from '../../../Store';
import { baseUrl, baseUrlV2 } from '../../Constants';
import { sortingOptionFornest, sortingOptions } from '../../Utils';

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const eventSearch = ({ name = '' }) =>
  axios.get(`${baseUrl}/api/event/getOrganizers?search=${name}`, getHeaders());

export const createNewEvent = (credentials) =>
  axios.post(`${baseUrl}/api/event/createEvent`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const eventListing = (role) =>
  axios.get(`${baseUrl}/api/event/list/${role}`, getHeaders());

export const eventListingSort = ({
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
  role,
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(`${baseUrl}/api/event/list/${role}?&${options}`, getHeaders());
};
export const eventParticipantSort = () => axios.get(`${baseUrl}`);

export const eventListingSearch = ({ name = '', role }) =>
  axios.get(`${baseUrl}/api/event/list/${role}?search=${name}`, getHeaders());

export const deleteEvent = (id) =>
  axios.delete(`${baseUrl}/api/event/${id}`, getHeaders());

export const cancelEvent = (id) =>
  axios.patch(
    `${baseUrl}/api/event/cancelEvent/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const getEvent = (id) => axios.get(`${baseUrl}/api/event/${id}`, getHeaders());

export const updateEvent = (id, credentials) =>
  axios.put(`${baseUrl}/api/event/${id}`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const blockEvent = (id, credentials) =>
  axios.patch(`${baseUrl}/api/event/${id}`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const organizerReset = (values, id, token) =>
  axios.patch(`${baseUrl}/api/event/user-credentials/${id}`, values, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

export const AddNewOrganizerContact = (credentials) =>
  axios.post(`${baseUrl}/api/event/createEventOrganizer`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const OrganizerEventsListing = ({
  role = 'organizer',
  id = 'id',
  sortBy = 'eventName',
  sort = 'asc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage,
  });
  return axios.get(
    `${baseUrl}/api/organiser/listEvents/${id}?${options}&role=${role}`,
    getHeaders(),
  );
};

export const getForms = () => axios.get(`${baseUrl}/api/form/formFields`, getHeaders());

export const createNewEventForm = (data) =>
  axios.post(`${baseUrl}/api/event-form/create-form`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const imageUpload = (data) =>
  axios.post(`${baseUrl}/api/setting/file-upload`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getAllFormsByEventId = (id) =>
  axios.get(`${baseUrl}/api/event-form/get-forms/${id}`, getHeaders());

export const getFormsByEventId = (eventId, filter = 'all') =>
  axios.get(`${baseUrl}/api/event-form/forms/${eventId}?filter=${filter}`, getHeaders());

export const checkUserEnroll = (eventId) =>
  axios.get(`${baseUrl}/api/event-form/enroll/${eventId}`, getHeaders());

export const getSingleFormByEventId = (eventId, formId) =>
  axios.get(`${baseUrl}/api/event-form/form/${eventId}/${formId}`, getHeaders());

export const deleteSingleFormByEventId = (eventId, formId) =>
  axios.delete(`${baseUrl}/api/event-form/form/${eventId}/${formId}`, getHeaders());

export const currentEvents = () =>
  axios.get(`${baseUrl}/api/event/overview/now`, getHeaders());

export const sendEmailInvitation = (data) =>
  axios.post(`${baseUrl}/api/event-form/event-invite`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const rallyInvite = (data) =>
  axios.post(`${baseUrl}/api/rally/rally-invite`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const sendUserEventInvitation = (data) =>
  axios.post(`${baseUrl}/api/event/event-invite`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const updateSingleFormByEventId = (eventId, formId, data) =>
  axios.put(`${baseUrl}/api/event-form/form/${eventId}/${formId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getEventTypes = (organizer) =>
  axios.get(`${baseUrl}/api/event/types/event-types/${organizer}`, getHeaders());

// https://compreg-backend.herokuapp.com/api/event/event-share/list
export const userEventListingSort = ({
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(`${baseUrl}/api/event/event-share/list?&${options}`, getHeaders());
};

// /list-participants/:eventId

export const eventParticipantsListSort = ({
  sortBy = 'createdAt',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  id,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(
    `${baseUrl}/api/event-form/list-participants/${id}?${options}`,
    getHeaders(),
  );
};

export const participantFormSubmit = (data: any) =>
  axios.post(`${baseUrl}/api/event-form/form-data/create`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Access-Control': 'Allow-Origin',
    },
  });

// http://localhost:9000/api/event-form/approve-participant/event/619b1c004d366c2a207f6227/participant/6196296483c55b32fc7a1dd4

export const approveUser = (eventId, userId, credentials) =>
  axios.patch(
    `${baseUrl}/api/event-form/approve-participant/event/${eventId}/participant/${userId}`,
    credentials,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const eventSharedUsers = (eventId) =>
  axios.get(`${baseUrl}/api/event-form/event-shared-users/${eventId}`, getHeaders());

export const getFormsDataById = (eventId, formId) =>
  axios.get(`${baseUrl}/api/event-form/form/${eventId}/${formId}`, getHeaders());

export const getParticipantDetailsById = (eventId, participantId, formId) =>
  axios.get(
    `${baseUrl}/api/event-form/form-data-participant/event/${eventId}/participant/${participantId}/form/${formId}`,
    getHeaders(),
  );

export const updateParticipantFormDetailsById = (eventId, formId, data) =>
  axios.post(`${baseUrl}/api/event-form/form/${eventId}/${formId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const updateFormDataByParticipant = (eventId, participantId, formId, formData) =>
  axios.put(
    `${baseUrl}/api/event-form/form-data-participant/event/${eventId}/participant/${participantId}/form/${formId}`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const getAllFormsByRallyIdAndUserId = (eventId, userId) =>
  axios.get(
    `${baseUrl}/api/event-form/forms/participant/${eventId}/${userId}`,
    getHeaders(),
  );

export const getFormsByRallyIdAndUserId = (eventId, userId, formId) =>
  axios.get(
    `${baseUrl}/api/event-form/list-forms/participant/${eventId}/${userId}/${formId}`,
    getHeaders(),
  );

export const listParticipantsByEventId = (eventId, userId) =>
  axios.get(
    `${baseUrl}/api/event-form/get-participant/${eventId}/${userId}`,
    getHeaders(),
  );

export const updateRacingNumber = (eventId, userId, data) =>
  axios.patch(
    `${baseUrl}/api/event-form/racing-number/event/${eventId}/${userId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const updateFolderNumber = (eventId, userId, data) =>
  axios.patch(
    `${baseUrl}/api/event-form/folder-number/event/${eventId}/${userId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const downloadPDFDoc = (eventId, formId) =>
  axios.get(`${baseUrl}/api/event-form/pdf-download/${eventId}/${formId}`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const downloadPDFList = ({
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
  eventId,
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(
    `${baseUrl}/api/event-form/download-participants-list/${eventId}?${options}`,
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
};

export const shareEvent = (data) =>
  axios.post(`${baseUrl}/api/user/participant/create-and-share/event`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const addParticipant = (data) =>
  axios.post(`${baseUrl}/api/user/participant/add`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getFormListByEventId = (id) =>
  axios.get(`${baseUrl}/api/event-form/form-list/${id}`, getHeaders());

export const enrollNow = (id) =>
  axios.post(
    `${baseUrl}/api/event-form/event-add-users/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const updateEventParticipant = (data, eventId, userId) =>
  axios.patch(
    `${baseUrl}/api/event-form/event-participant/${eventId}/${userId}`,
    data,
    getHeaders(),
  );

export const listSubmittedFormsByOfParticipant = (participantId) =>
  axios.get(
    `${baseUrl}/api/event-form/list-all-forms/participant/${participantId}`,
    getHeaders(),
  );

export const cloneForm = (id) =>
  axios.post(
    `${baseUrl}/api/form/clone/form-field/${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const downloadFormDataPDF = (eventId, formId, userId, rallyId) =>
  axios.get(
    `${baseUrl}/api/event-form/pdf-download/submitted-form/${eventId}/${formId}/${userId}/${rallyId}`,
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

// export const downloadZip = (eventId, rallyId, userId) => {
//     return axios.post(baseUrl + `/api/event-form/zip/${eventId}/${rallyId}/${userId}`, {
//         responseType: "application/zip",
//         headers: {
//             Accept: "application/zip",
//             Authorization: "Bearer " + getToken(),
//         },
//     });
// };

export const downloadZip = (eventId, rallyId, userId) =>
  axios.get(`${baseUrl}/api/event-form/zip/${eventId}/${rallyId}/${userId}`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/zip',
      Authorization: `Bearer ${getToken()}`,
    },
  });
// /api/event-form/zip/6204b22d130f6228d8ea15d7/6204b7bb2ba56020182488cf/620607471fe58c0d84dc5f73

export const userEvents = ({
  sortBy = 'firstName',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
  id,
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(`${baseUrl}/api/event/all-events/${id}?&${options}`, getHeaders());
};

export const LatestEvent = ({
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) =>
  axios.get(
    `${baseUrl}/api/event/latest-events?sortBy=${sortBy}&sort=${sort}&search=${search}&page=${
      page + 1
    }&pageSize=${rowsPerPage}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const currentEventsNow = () => axios.get(`${baseUrl}/api/event/now`, getHeaders());

export const participantGetEvent = (id) =>
  axios.get(`${baseUrl}/api/event-form/${id}`, getHeaders());

export const sampleEventCSV = () =>
  axios.get(`${baseUrl}/api/event/csv-sample/event`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const exportEventCSV = ({
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
}) => {
  const data: any = {
    token: getToken(),
  };
  const formData = new FormData();
  Object.keys(data).map((k) => formData.append(k, data[k]));
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: 100,
  });
  return axios.post(`${baseUrl}/api/event/csv-export?${options}`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
    },
  });
};

export const importEventsCSV = ({
  formData,
  language,
}: {
  formData: FormData;
  language: string;
}) =>
  axios.post(`${baseUrl}/api/event/csv-import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
      language,
    },
  });

export const importEventCSV = ({
  formData,
  eventId,
  language,
}: {
  formData: FormData;
  eventId: string;
  language: string;
}) =>
  axios.post(`${baseUrl}/api/event/csv-import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
      language,
    },
    params: {
      eventId,
    },
  });

export const enrollRallyNow = (eventId, rallyIds) =>
  axios.post(
    `${baseUrl}/api/rally/add-users/${eventId}`,
    {
      rallyIds,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

// http://localhost:9000/api/event/csv-export?limit=40
export const enrollEventByParticipant = (id) =>
  axios.post(
    `${baseUrl}/api/event-form/event-invite/${id}`,
    {
      message: 'message',
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );

export const eventClone = (id) =>
  axios.post(
    `${baseUrl}/api/event/clone/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
export const eventBackup = (id) =>
  axios.post(
    `${baseUrlV2}/api/v2/event/backup`,
    { Event: [id] },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
export const eventRestore = (credentials) =>
  axios.post(`${baseUrlV2}/api/v2/event/import`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });
export const deleteParticipant = (eventId, participantId) =>
  axios.delete(
    `${baseUrl}/api/event-form/remove-participant/${eventId}/${participantId}`,
    getHeaders(),
  );

export const reinstateParticipant = (
  eventId: string,
  participantId: string,
  credentials?: object,
) =>
  axios.patch(
    `${baseUrl}/api/event-form/reinstate-participant/event/${eventId}/participant/${participantId}`,
    credentials,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
export const getAllForms = ({
  role = 'superadmin',
  sortBy = 'createdAt',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(`${baseUrl}/api/form/list/${role}?&${options}`, getHeaders());
};

export const getEventsByRole = ({ role }: { role: string }) =>
  axios.get(`${baseUrlV2}/api/v2/event/list/${role}`, getHeaders());

export const getEventStatus = ({
  sortBy = 'createdAt',
  sort = 'ASC',
  page = 0,
  rowsPerPage = 10,
  search,
}) => {
  const options = sortingOptionFornest({
    sortBy,
    sort,
    search: search === undefined ? '' : search,
    page,
    rowsPerPage,
    field: 'key',
  });
  return axios.get(`${baseUrlV2}/api/v2/event-status?&${options}`, getHeaders());
};

export const deleteEventStatusById = (id) =>
  axios.delete(`${baseUrlV2}/api/v2/event-status/${id}`, getHeaders());

export const getEventStatusById = (id) =>
  axios.get(`${baseUrlV2}/api/v2/event-status/${id}`, getHeaders());

export const updateEventStatus = (updateId, data) =>
  axios.patch(`${baseUrlV2}/api/v2/event-status/${updateId}`, data, getHeaders());

export const createEventStatus = (data) =>
  axios.post(`${baseUrlV2}/api/v2/event-status`, data, getHeaders());
