import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFileUploader from 'src/Hooks/useFileUploader';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { toast } from 'react-toastify';
import { FormButton } from 'src/Components/Utils';
import { Loader } from 'src/Services/Utils';
import { isEmpty, uniqBy, includes } from 'lodash';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import UserType from 'src/Types/UserType';
import { AutopickFormType } from 'src/Types/FormFieldAutoPickType';
import { fileSizeToMB } from 'src/Components/Utils/DisplayFileSize';
import WarningToast from 'src/Components/Utils/WarningToast';
import {
  UserFormFeild,
  checkFormFieldExistence,
  findFormFieldValues,
  checkSubmittedForms,
  ItemType,
} from 'src/Containers/Events/EventParticipants/utils';
import { getGenericDateValue } from 'src/Containers/Events/Rally/components/DynamicForm/util';
import { getCheckBoxValue } from 'src/Libs/DynamicForm/components/FormField/util';
import DocumentIcon from '@material-ui/icons/VisibilityRounded';
import {
  listSubmittedFormsByOfParticipant,
  participantFormSubmit,
  updateFormDataByParticipant,
} from '../../../Services/Auth/Actions/EventActions';
import useStyles from '../EventParticipants/FromPreviewStyle';
import { fillAutoPickedFormFieldValues } from '../Rally/AutoPicKFormFieldValues';

const useForms = ({
  item,
  user,
  rallyId,
  listForms,
  autoPickValues,
  setOpenPopup,
}: {
  item: ItemType;
  user: UserType;
  rallyId: ObjectIdType;
  listForms: () => void;
  autoPickValues: AutopickFormType;
  setOpenPopup: (Boolean: Boolean) => {};
}) => {
  const [rallyFormFieldLayout, setRallyFormFieldsLayout] = useState<
    Array<FormFieldEditType>
  >([]);
  const { t } = useTranslation();

  type HandleOnchangeType = {
    event?: any;
    index: string;
    type: string;
    id: string;
  };

  const DECLINED_DOCUMENT_TYPES = ['image/gif'];
  const formTotalFileSizeLimit = 300;
  const formFileSizeLimit = 150;
  const [userFormFeild, setUserFormFeild] = useState<Array<UserFormFeild>>([]);
  const [memmorySize, setMemorySize] = useState<any>();
  const [hasFilesToLoad, setHasFilesToLoad] = useState(false);
  const DOCUMENT_TYPES = [
    'image/',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  const classes = useStyles();

  const onImageChange =
    ({ index, type, id }: HandleOnchangeType) =>
    async (event) => {
      const file = event.target.files[0];

      if (DECLINED_DOCUMENT_TYPES.find((fileType) => fileType === file.type)) {
        WarningToast(`${file.type} ${t('toast.file is not acceptable')}`);
        return;
      }

      if (
        includes(file.type, 'image/', 0) ||
        DOCUMENT_TYPES.find((fileType) => fileType === file.type)
      ) {
        const fileSize: any = fileSizeToMB(file.size);
        if (fileSize > formFileSizeLimit) {
          WarningToast(`choose less than ${formFileSizeLimit}mb file`);
          return;
        }
        const totalSize = parseFloat(memmorySize) + parseFloat(fileSize);

        if (totalSize > formTotalFileSizeLimit) {
          WarningToast(`Upload total file size up to ${formTotalFileSizeLimit}mb`);
          return;
        }
        setMemorySize(totalSize);
        setHasFilesToLoad(true);
        const field = checkFormFieldExistence(userFormFeild, index);
        if (!field) {
          setUserFormFeild([
            ...userFormFeild,
            {
              values: URL.createObjectURL(file),
              fileKey: file,
              formFieldId: index,
              type,
              contentType: file.type,
              contentName: file.name,
              id,
            },
          ]);
        } else {
          setUserFormFeild(
            userFormFeild.map((o: any) => {
              if (o.formFieldId === index) {
                return {
                  ...o,
                  values: URL.createObjectURL(file),
                  fileKey: file,
                  formFieldId: index,
                  type,
                  contentType: file.type,
                  contentName: file.name,
                  id,
                };
              }
              return o;
            }),
          );
        }
      } else {
        WarningToast('choose any document or an image file');
      }
    };

  const findTextFieldsGeneric = (index, id, data) => {
    const field = checkFormFieldExistence(userFormFeild, index);
    if (field) {
      const textData = findFormFieldValues(userFormFeild, index);
      return textData?.values;
    }
    return data?.values[0];
  };

  const handleDropDownChange = (option, index, type, id) => {
    const field = checkFormFieldExistence(userFormFeild, index);
    if (!field) {
      setUserFormFeild([
        ...userFormFeild,
        {
          values: option.label,
          formFieldId: index,
          type,
          id,
        },
      ]);
    } else {
      setUserFormFeild(
        userFormFeild.map((o: any) => {
          if (o.formFieldId === index) {
            return {
              ...o,
              values: option.label,
              formFieldId: index,
              type,
              id,
            };
          }
          return o;
        }),
      );
    }
  };

  const checkBoxValue = (index, id, data) => {
    const field = checkFormFieldExistence(userFormFeild, index);
    if (field) {
      const checkboxDataArray = findFormFieldValues(userFormFeild, index);
      return !!getCheckBoxValue(checkboxDataArray);
    }
    return getCheckBoxValue(data);
  };

  const findTextFields = (index: string) => {
    const textData = findFormFieldValues(userFormFeild, index);
    return textData?.values;
  };

  const findDropDownData = (index: string) => {
    const data = findFormFieldValues(userFormFeild, index);
    return data?.values;
  };
  const getValue = (index, data) => {
    const field = checkFormFieldExistence(userFormFeild, index);
    if (field) {
      return findTextFields(data.index);
    }
    const dateValue = getGenericDateValue(data);
    return dateValue;
  };
  // Utility function to update or add form field
  const updateOrAddFormField = (userFormFeild, index, value, type, id) => {
    const fieldExists = checkFormFieldExistence(userFormFeild, index);

    const newField = {
      values: value,
      formFieldId: index,
      type,
      id,
    };

    if (fieldExists) {
      return userFormFeild.map((field) =>
        field.formFieldId === index ? newField : field,
      );
    }
    return [...userFormFeild, newField];
  };

  const onHandleChangeDate = (event, values, index, type, id) => {
    const updatedFormFields = updateOrAddFormField(
      userFormFeild,
      index,
      event.target.value,
      type,
      id,
    );

    setUserFormFeild(updatedFormFields);
  };

  const onHandleChangeCheckbox = (index, type, id) => (event) => {
    const value = event.target.checked ? ['checked'] : [''];
    const updatedFormFields = updateOrAddFormField(userFormFeild, index, value, type, id);

    setUserFormFeild(updatedFormFields);
  };

  const onHandleChange = ({ event, index, type, id }) => {
    const value = event.target.value < 0 ? [0] : event.target.value;
    const updatedFormFields = updateOrAddFormField(userFormFeild, index, value, type, id);

    setUserFormFeild(updatedFormFields);
  };
  const onHandleAutoFillChange = (
    tempUserFormFeild,
    index,
    type,
    values,
    id,
    contentType,
    contentName,
  ) => {
    const field = checkFormFieldExistence(tempUserFormFeild, index);
    const updatedField = {
      values,
      formFieldId: index,
      type,
      id,
      contentType: type === 'file' ? contentType : null,
      contentName: type === 'file' ? contentName : null,
    };

    if (!field) {
      return [...tempUserFormFeild, updatedField];
    }

    return tempUserFormFeild.map((o) =>
      o.formFieldId === index ? { ...o, ...updatedField } : o,
    );
  };

  const showIcon = (index, hint) => {
    const viewDocument = (index) => {
      const data = findFormFieldValues(userFormFeild, index);
      const pdfWindow: any = window.open(data?.values);
      pdfWindow?.document?.write(
        `<iframe width='100%' height='100%' src='${data?.values}'></iframe>`,
      );
    };
    const data = findFormFieldValues(userFormFeild, index);
    if (
      includes(data?.contentType, 'image/', 0) ||
      DOCUMENT_TYPES.find((type) => type === data?.contentType)
    ) {
      return (
        <h6 className={hint ? classes.avatarTwoCopy : classes.avatarTwo}>
          <div onClick={() => viewDocument(index)}>
            <DocumentIcon style={{ color: 'blue' }} />
          </div>
        </h6>
      );
    }
    return null;
  };
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [isAutoFill, setAutoFill] = useState(true);
  const [isRallyFormSubmited, setIsRallyFormSubmited] = useState(false);
  const { isImageLoading, uploadImage } = useFileUploader();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [rallyIds, setRallyIds] = useState<string | undefined>('');

  const showFormFeild = async () => {
    if (user) {
      const submittedForms = await checkSubmittedForms(user?._id);
      submittedForms.forEach((form) => {
        if (form.isSubmitted) {
          setIsRallyFormSubmited(true);
        }
      });
      setRallyIds(rallyId);
    }
    if (item && item.form.length > 0 && item.isSubmitted) {
      try {
        setRallyFormFieldsLayout(item.form.sort((a, b) => a.order - b.order));

        const userFormField: UserFormFeild[] = item.formData.map((fieldItem) => ({
          formFieldId: fieldItem.formFieldId,
          values: fieldItem.values,
          type: fieldItem.type,
          contentName: fieldItem.contentName,
          contentType: fieldItem.contentType,
          id: fieldItem.id,
        }));
        setUserFormFeild(userFormField);
        setDisabled(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    } else {
      setUserFormFeild([]);
      const formFieldsLayout: any[] = item.form.map((el) => ({
        category: el.category,
        key: el.key,
        label: el.label,
        options: el.options,
        type: el.type,
        validations: el.validations,
        _id: el._id,
        hint: el.hint,
        coordinates: el?.coordinates,
        borderBottom: el.borderBottom,
        borderLeft: el.borderLeft,
        borderRight: el.borderRight,
        borderTop: el.borderTop,
        index: el.index,
        imgUrl: el.imgUrl,
        isImage: el.isImage,
        values: el.values,
        defaultDropDownValue: el.defaultDropDownValue,
        totalCount: el.totalCount,
        tableRows: el.tableRows,
        tableCols: el.tableCols,
        parentWidgetIndex: el.parentWidgetIndex,
        coordinate: el.coordinate,
        styles: {
          color: el.styles?.color,
          fontSize: el.styles?.fontSize,
          fontWeight: el.styles?.fontWeight,
          textAlign: el.styles?.textAlign,
          backgroundColor: el.styles?.backgroundColor,
          borderTopStyle: el.styles?.borderTopStyle,
          borderLeftStyle: el.styles?.borderLeftStyle,
          borderRightStyle: el.styles?.borderRightStyle,
          borderBottomStyle: el.styles?.borderBottomStyle,
          borderColor: el.styles?.borderColor,
          borderRadius: el.styles?.borderRadius,
          objectFit: el.styles?.objectFit,
          gridTemplateColumns: el.styles?.gridTemplateColumns,
        },
      }));
      setRallyFormFieldsLayout(
        autoPickValues
          ? fillAutoPickedFormFieldValues(formFieldsLayout, autoPickValues)
          : formFieldsLayout,
      );
    }
  };

  const handleDefaultValues = (tempFormFields) => {
    rallyFormFieldLayout.forEach((element: any) => {
      if (element.values && element.values[0]?.length > 0) {
        const field = checkFormFieldExistence(userFormFeild, element.index);
        if (!field) {
          switch (element.type) {
            case 'dropdown':
              tempFormFields.push({
                values: element.defaultDropDownValue,
                formFieldId: element.index,
                type: element.type,
                id: element._id,
              });
              break;
            case 'date':
              tempFormFields.push({
                values: getGenericDateValue(element),
                formFieldId: element.index,
                type: element.type,
                id: element._id,
              });
              break;

            default:
              tempFormFields.push({
                values: element.values[0],
                formFieldId: element.index,
                type: element.type,
                id: element._id,
              });
              break;
          }
        }
      }
    });
  };

  const formAutoFill = () => {
    let autoFillFields: Array<any> = [];
    listSubmittedFormsByOfParticipant(user?._id).then((response) => {
      response.data?.data.forEach((form) => {
        if (form.rallyId === rallyIds) {
          autoFillFields = [...autoFillFields, ...form.data];
        }
      });
      autoFillFields = uniqBy(autoFillFields, (e) => e.id);
      let tempUserFormFeild = [...userFormFeild];
      rallyFormFieldLayout.forEach((feild: any) => {
        autoFillFields.forEach((autoFill: any) => {
          if (feild._id === autoFill.id) {
            tempUserFormFeild = onHandleAutoFillChange(
              tempUserFormFeild,
              feild.index,
              autoFill.type,
              autoFill.values,
              autoFill.id,
              autoFill.contentType,
              autoFill.contentName,
            );
          }
        });
      });
      tempUserFormFeild.forEach((autoField) => {
        rallyFormFieldLayout.forEach((field: any) => {
          if (field._id === autoField.id) {
            // eslint-disable-next-line no-param-reassign
            field.values = autoField.values;
          }
        });
      });
      setUserFormFeild([...tempUserFormFeild]);
      setAutoFill(false);
    });
  };

  const onClose = () => {
    setOpenPopup(false);
    if (!item.isSubmitted) {
      setErrors({});
      setUserFormFeild([]);
    }
    setAutoFill(true);
  };

  useEffect(() => {
    showFormFeild();
  }, [item]);

  // eslint-disable-next-line consistent-return
  const onSubmit = (tempFormFields) => {
    let hasErrors = false;
    // eslint-disable-next-line array-callback-return
    Object.keys(errors).map((k) => {
      if (errors[k] != null) {
        hasErrors = true;
      }
    });

    if (!hasErrors) {
      const wholeData = {
        data: tempFormFields,
        formId: item._id,
        eventId: item.eventId,
        rallyId,
        userId: user?._id,
      };

      if (isEmpty(tempFormFields)) {
        setIsFormSubmitting(false);
        return toast.warning(t('forms.Cannot submit an empty form'));
      }

      const formData = new FormData();
      Object.keys(wholeData).map((k) => formData.append(k, wholeData[k]));
      if (edit) {
        updateFormDataByParticipant(item.eventId, user?._id, item._id, wholeData)
          .then(() => {
            toast.success(t('forms.Form updated successfully'), {
              position: 'top-center',
              autoClose: 2000,
            });
            setTimeout(() => {
              onClose();
            }, 1500);
            listForms();
            setIsFormSubmitting(false);
            setDisabled(true);
          })
          .catch((error) => {
            setIsFormSubmitting(false);
            toast.error(error.response.data.error, {
              position: 'top-center',
              autoClose: 5000,
            });
            onClose();
            setIsFormSubmitting(false);
            setDisabled(true);
          });
      } else {
        participantFormSubmit(wholeData)
          .then(() => {
            toast.success(t('forms.Form Submitted Successfully'), {
              position: 'top-center',
              autoClose: 2000,
            });
            // eslint-disable-next-line no-param-reassign
            item.isSubmitted = true;
            setTimeout(() => {
              onClose();
            }, 1500);
            listForms();
            setIsFormSubmitting(false);
            setDisabled(true);
          })
          .catch(() => {
            toast.error(t('forms.Form submission failed'), {
              position: 'top-center',
              autoClose: 5000,
            });
            onClose();
            setIsFormSubmitting(false);
            setDisabled(true);
          });
      }
    } else {
      setIsFormSubmitting(false);
      return toast.warning(t('forms.Cannot submit an empty form'));
    }
  };

  const submitForm = async (e) => {
    setIsFormSubmitting(true);
    e.preventDefault();
    const tempUserFormFields = [...userFormFeild];
    handleDefaultValues(tempUserFormFields);
    if (hasFilesToLoad) {
      await uploadImage(tempUserFormFields);
      onSubmit(tempUserFormFields);
    } else {
      onSubmit(tempUserFormFields);
    }
  };

  const editForm = () => {
    setDisabled(false);
    setEdit(true);
  };

  const saveButton = () => (
    <Loader isLoading={isFormSubmitting} type="ThreeDots">
      <FormButton
        className={classes.btn}
        name={t('forms.Submit')}
        type="submit"
        onClick={submitForm}
      />
    </Loader>
  );
  const showButtons = () => {
    if (item?.isSubmitted && disabled) {
      return (
        <FormButton
          name={t('forms.Edit')}
          className={classes.editButton}
          onClick={editForm}
        />
      );
    }
    if (!item?.isSubmitted && isRallyFormSubmited) {
      return (
        <>
          <p
            className={classes.link}
            onClick={() => {
              formAutoFill();
            }}
          >
            {isAutoFill && 'Auto fill form data'}
          </p>
          {saveButton()}
        </>
      );
    }
    if (!disabled) {
      return saveButton();
    }
    return null;
  };

  const showFormName = () => {
    if (item?.formName) {
      return item.formName;
    }
    if (item?.formId) {
      return item.formId.formName;
    }
    return 'Form Name';
  };

  return {
    onHandleChangeDate,
    checkBoxValue,
    findDropDownData,
    findTextFields,
    findTextFieldsGeneric,
    getValue,
    handleDropDownChange,
    onHandleChange,
    onHandleChangeCheckbox,
    onImageChange,
    showIcon,
    onClose,
    showFormName,
    showButtons,
    isImageLoading,
    disabled,
    errors,
    userFormFeild,
    rallyFormFieldLayout,
    isFormSubmitting,
  };
};

export default useForms;
