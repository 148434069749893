import { toast } from 'react-toastify';

function WarningToast(toastMsg) {
  toast.warning(toastMsg, {
    position: 'top-center',
    autoClose: 5000,
  });
}

export default WarningToast;
