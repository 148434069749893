import React from 'react';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FieldLabel from './Fields/FieldLabel';
import fieldStyles from './FieldStyle';

function GetParagraphField({ fieldData, mode }: FormFieldType) {
  const classes = fieldStyles();
  const { label, styles } = fieldData;

  return (
    <>
      {/* <p className={classes.paragraph}>{label}</p> */}
      <FieldLabel
        type="paragraph"
        name={label}
        styles={styles}
        deafultClassName={classes.paragraph}
      />
    </>
  );
}

export default GetParagraphField;
