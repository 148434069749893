import React from 'react';
 import { connect } from 'react-redux';
import './Style.css';
import Admin from '../Admin/Admin';
import useStyles from './HeaderStyle'
import LanguageSelectTab from '../LanguageSelect/LanguageSelectTab';

function Header({ title }: { title: any }) {
  const classes = useStyles();

  return (
    <div className="header-area">
      <div className="wrapper">
        <div className="title">
          <h3 className={classes.h3}>{title}</h3>
        </div>
        <div className="right">
          <div className={classes.wrap}>
            <LanguageSelectTab />
          </div>
          <div style={{ zIndex: 3 }}>
            <Admin />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {})(Header);
