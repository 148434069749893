import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import useStyles from './Backarrowstyle';

function BackArrow() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Icon
        icon="mdi:keyboard-backspace"
        className={classes.icon}
        onClick={() => {
          history.goBack();
        }}
      />
    </div>
  );
}

export default BackArrow;
