import React from 'react';
import useStyles from '../../../newEventFormStyle';

function MouseSelection({ y, x, x2, y2 }) {
  const classes = useStyles();
  
  return ( <div
    style={{ top: `${y + 40}px`, left: x, height: `${y2 - y - 10}px`, width: `${x2 -x}px`}}
    className={ classes.selectionStyle }
  > </div>)
}

export default MouseSelection;