/* eslint-disable no-param-reassign */
import moment from 'moment';
import { AttachmentInfoType, AttachmentType } from 'src/Types/AttachmentType';
import { EventType } from 'src/Types/EventType';
import { RallyType } from 'src/Types/RallyType';
import { v4 as uuidv4 } from 'uuid';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { validateDate } from '../../../Services/Auth/Validations';

export const preProcessData = (data: RallyType) => {
  if (data.startDateTime) {
    // eslint-disable-next-line no-param-reassign
    data.startDateTime = moment(data.startDateTime).startOf('day');
  }
  if (data.endDateTime) {
    // eslint-disable-next-line no-param-reassign
    data.endDateTime = moment(data.endDateTime).endOf('day');
  }
};

export const prepareFormData = (data: RallyType, image: any, files: AttachmentType[]) => {
  const formData = new FormData();
  const date = new Date().toLocaleDateString();
  Object.keys(data).map((k) => {
    if (data[k].format) {
      formData.append(k, data[k].format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
    } else {
      formData.append(k, data[k]);
    }
    return k;
  });
  if (image.binary) {
    formData.append('rallyImage', image.binary, image.fileName);
  }
  if (files) {
    const attachmentString: Array<AttachmentType> = [];
    const infoString: Array<AttachmentInfoType> = [];
    files.map((el: AttachmentType) => {
      if (el.files) {
        const uuid = `attachment_${uuidv4()}`;
        attachmentString.push(el);
        formData.append(uuid, el.files);
        infoString.push({
          dropdown: el.dropdown,
          uuid,
          name: el.name,
          size: el.size,
          type: el.type,
          date,
        });
      }
      return el;
    });
    formData.append('info', JSON.stringify(infoString));
  }
  return formData;
};

function validateRally(eventDetail: EventType, data: RallyType, t: Function) {
  const eventStartDate = moment(eventDetail.startDateTime).startOf('day');
  const eventEndDate = moment(eventDetail.endDateTime).endOf('day');
  const rallyStartDate = moment(data.startDateTime).startOf('day');
  const rallyEndDate = moment(data.endDateTime).endOf('day');

  if (rallyStartDate.isBefore(eventStartDate)) {
    ErrorToast('rally start date must be same or after the event start date');
    return false;
  }
  if (rallyEndDate.isAfter(eventEndDate)) {
    ErrorToast('rally end date must be same or before the event end date');
    return false;
  }
  if (!validateDate(data, t)) {
    return false;
  }
  return true;
}
export default validateRally;
