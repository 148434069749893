import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {} from 'src/Libs/DynamicForm/components/FormField';
import { ErrorMessageCustom, FormButton } from 'src/Components/Utils';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid/Grid';
import * as Yup from 'yup';
import {
  getEventsForAutoSelect,
  inviteAllParticipants,
} from 'src/Services/Auth/Actions/UserActions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { toast } from 'react-toastify';
import { commonResponseHandler } from 'src/Services/Utils';
import FieldLabel from 'src/Libs/DynamicForm/components/FormField/Fields/FieldLabel';
import useStyles from './UsersListingStyle';

function InviteAction({
  participant,
  setOpenPopup,
}: {
  participant: Array<string>;
  setOpenPopup: Function;
}) {
  const [eventList, setEventList] = useState([]);
  const role = useSelector((store: RootState) => store.role.role);
  const classes = useStyles();
  const inviteSchema = Yup.object().shape({
    sourceEvent: Yup.string().when('targetEvent', (_targetEvent): any => {
      if (participant.length === 0) {
        return Yup.string().required('validationErrors.Source event is required');
      }
      return null;
    }),
    targetEvent: Yup.string().required('validationErrors.Target event is required'),
  });

  const { t } = useTranslation();

  const reload = () => {
    if (role !== 'participant') {
      getEventsForAutoSelect(role).then((response) => {
        const data = response.data.events;
        setEventList(data);
      });
    }
  };
  useEffect(() => {
    reload();
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          all: !(participant.length > 0),
          targetEvent: '',
          sourceEvent: '',
          participants: participant,
        }}
        validationSchema={inviteSchema}
        onSubmit={(values, { setSubmitting }) => {
          const data: any = {
            ...values,
          };
          if (values.sourceEvent === values.targetEvent) {
            setSubmitting(false);
            toast.error(t('events.Choose different events for Source and Target'), {
              position: toast.POSITION.TOP_CENTER,
            });
            return;
          }
          if (values.sourceEvent.length === 0) {
            delete data.sourceEvent;
          }
          setSubmitting(false);
          commonResponseHandler(inviteAllParticipants(data, role), () => {
            setOpenPopup(false);
            toast.success(t('events.Participants invited Successfully'), {
              position: toast.POSITION.TOP_CENTER,
            });
          });
        }}
      >
        {({ isSubmitting, values, handleChange, errors, touched }) => (
          <Form>
            <Grid item lg={11} xs={11}>
              <h1 style={{ textAlign: 'center', fontWeight: '800' }}>
                {' '}
                {t('users.Invite Action')}
              </h1>
              <div className={classes.selectinput}>
                {participant.length === 0 && (
                  <div>
                    <FieldLabel name="Source Event" />
                    <select
                      name="sourceEvent"
                      value={values.sourceEvent}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="" label={t('forms.Select')} />
                      {eventList.map((item: any) => (
                        <option value={item._id} label={`${item.eventName}`} />
                      ))}
                    </select>
                    <ErrorMessageCustom
                      errors={errors}
                      field="sourceEvent"
                      touched={touched}
                    />
                  </div>
                )}
                <div className={classes.selectinput2}>
                  <FieldLabel name="Target Event" />
                  <select
                    name="targetEvent"
                    value={values.targetEvent}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <option value="" label={t('forms.Select')} />
                    {eventList.map((item: any) => (
                      <option value={item._id} label={`${item.eventName}`} />
                    ))}
                  </select>
                  <ErrorMessageCustom
                    errors={errors}
                    field="targetEvent"
                    touched={touched}
                  />
                </div>
              </div>
              <div className={classes.btnWrap}>
                <div>
                  {!isSubmitting && (
                    <FormButton
                      className={classes.btn1}
                      name={t('forms.Submit')}
                      type="submit"
                      disabled={isSubmitting}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default InviteAction;
