import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import CustomTable from 'src/Components/Table/Table';
import { formateDate } from 'src/Services/Utils';
import useStyles from './NewUsersStyle';
import AVATAR from './AvatarImage.svg';
import { userOverviewListing } from '../../../Services/Auth/Actions/UserActions';
import UserDelete from '../../Users/UsersListing/UserDelete';
import UsersListingActions from '../../Users/UsersListing/UsersListingActions';

function NewUsers({ reloader }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const userRole = useSelector((state: RootState) => state.role.role);

  const reloadList = ({ sort, page, rowsPerPage, search }) => {
    reloader();
    return userOverviewListing({
      userRole,
      role: 'participant',
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
    }).then((response) => {
      const data = response.data.users;
      const { totalCount } = response.data;
      return {
        data,
        totalCount,
      };
    });
  };

  const columns = [
    {
      title: t('forms.First Name'),
      field: 'fullName',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div>
          {/* {row?.username} */}
          <div className={classes.wrap}>
            <img
              src={
                row.profileImage && row.profileImage !== 'null'
                  ? row.profileImage
                  : AVATAR
              }
              className={classes.img}
              alt="avatar"
            />
            <Tooltip title={row.username}>
              <div style={{ width: '170px' }}>
                <div className={classes.tbodyName}>
                  {row?.companyname || row?.lastName
                    ? `${row.lastName} ${row?.firstName}`
                    : row?.firstName}
                </div>
                <p className={classes.grey}>{row.username}</p>
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Phone'),
      field: 'phone',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row) => row?.phone,
      isSortable: true,
    },
    {
      title: t('forms.Email'),
      field: 'email',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => row?.email,
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <p className={classes.grey}>{formateDate(row.createdAt)}</p>,
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row, reload) => (
        <div>
          <div className={classes.wrapper}>
            <UsersListingActions item={row} />
            <UserDelete reload={reload} id={row._id} item={row} />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];

  return (
    <div>
      <CustomTable
        tableName="newUsers"
        columns={columns}
        reload={reloadList}
        heading={t('overview.New Participants')}
        onDoubleClick={(item) =>
          history.push({
            pathname: `users/details/${item._id}/profile`,
            state: { showGoBack: true },
          })
        }
        searchSize="small"
      />
    </div>
  );
}

export default NewUsers;
