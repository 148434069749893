import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';
import { formLayout } from './utils';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 500,
    backgroundColor: '#fff',
    fontFamily: typography.fonts.secondary,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
    height: '300px',
    margin: '0 auto',
    textAlign: 'center',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    // margin:'15px',
    // display: "flex",
    // justifyContent: "flex-start",
  },
  deleteIcon: {
    color: '#ef233c',
    fontSize: '20px',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    // margin:'15px',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '5px',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
  },

  link: {
    textDecoration: 'none',
    fontWeight: 600,
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  boxType: {
    padding: '20px',
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    // boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    height: '100%',
    minHeight: '90vh',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
    },
  },
  wrapper3: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  btnClone: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
    backgroundColor: '#fff',
    padding: '8px 25px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.primary.light,
    },
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  icon: {
    fontSize: '35px',
    marginRight: '10px ',
  },
  leftWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  rightWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
    },
  },
  customButton: {
    border: '2px solid #DFE0EB',
    color: '#333',
    minWidth: '150px',
    boxShadow: 'none',
    backgroundColor: '#fff',
    hover: {
      backgroundColor: 'none',
      width: '100%',
      color: '#fff',
    },
  },
  subwrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  forms: {
    width: '100%',

    // background:"red",
    height: '100% !important',
    border: '1px solid #d4d5de',
    borderRadius: '5px',
    // boxShadow: "10px 10px 15px #ddd",
    overflow: 'auto',
    overflowX: 'hidden',
    // overflow: "hidden",
    padding: '10px',
    // height:'100%',
    '@media all and (max-width: 650px)': {
      marginTop: '10px',
      marginBottom: '20px',
      width: '400px',
      height: '400px',
    },
  },
  subforms: {
    width: '150px',
    height: '100px',
    border: '1px solid #d4d5de',
    borderRadius: '15px',
    boxShadow: '10px 10px 15px #ddd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      marginTop: '10px',
      marginBottom: '20px',
    },
  },
  mainWrap: {
    display: 'flex',
    marginTop: '25px',
    alignItems: 'center',
    // justifyContent: 'center',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
      width: '100%',
    },
    height: '100%',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 0px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '90%',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  popupInput: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  inputlabel: {
    marginLeft: '0px',
    cursor: 'pointer',
    width: '100%',
  },
  selected: {
    background: '#DFE0EB7D',
    marginLeft: '0px',
    border: '1px solid #DFE0EB',
    // transition: "all .2s ease-in-out",
    '&:input': {
      border: '1px solid #DFE0EB',
    },
  },
  arrows: {
    height: '50px',
    width: '50px',
    transition: 'all .3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.21)',
      color: '#342635',
    },
    '&:active': {
      transform: 'scale(0.98)',
    },
  },
  inputName: {
    width: '94%',
    border: '1.5px solid #DFE0EB',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    color: '#000',
    '&:focus': {
      outline: 'none !important',
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#333',
    },
  },
  inputDiv: {
    marginTop: '20px',
  },
  typeSelect: {
    background: '#007AFF',
    color: '#fff',
  },
  inputlabel1: {
    alignItems: 'center',
  },
  errorMessage: {
    color: 'red',
    float: 'left',
    marginLeft: '35px',
    marginBottom: '2px',
  },
  myLink: {
    height: '30px',
    width: '30px',
    transition: 'all .3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.21)',
      fill: '#007AFF',
    },
    fill: 'black',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: '17px',
    width: '40%',
    padding: '30px',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '0',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
  shareIcon: {
    color: '#43A047',
    fontSize: '20px',
    cursor: 'pointer',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading2: {
    margin: '0',
  },
  paragraph2: {
    margin: 0,
    overflowWrap: 'anywhere',
    textAlign: 'left',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  editIcon1: {
    color: colors.primary.color,
    fontSize: '20px',
    margin: '15px',
    fontWeight: 800,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  dPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',

    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  wrapper12: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  accordianStyle: {
    margin: '20px 0',
    border: '1px solid #ced4da',
    boxShadow: 'none',
    borderRadius: '5px',
  },
  leftForm: {
    width: '400px',
    marginRight: '20px',
    height: '100%',
    '@media all and (max-width: 650px)': {
      marginRight: '0',
    },
    // height: "80vh",
  },
  rightForm: {
    height: '100% !important',
    // minHeight:'90vh',
    flex: '2',
    maxWidth: formLayout.width,
    margin: '0 20px',
    '@media all and (max-width: 650px)': {
      marginLeft: '0',
    },
  },
  inputFieldAlignment: {
    display: 'flex',
    alignItems: 'center',
    width: '350px',
    '@media all and (max-width: 650px)': {
      width: '100%',
    },
  },
  inputFieldAlignmentPerson: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    '@media all and (max-width: 650px)': {
      width: '100%',
    },
  },
  upperForm: {
    width: '100%',
    height: '50px',
    backgroundColor: '#EAEAEA',
    display: 'flex',
    alignItems: 'center',
  },
  selectAll: {
    padding: '5px 10px',
    cursor: 'pointer',
  },
  hintText: {
    textAlign: 'left',
    marginLeft: '5px',
    fontSize: '12px',
    color: '#6c757d',
  },
  forms2: {
    width: '100%',
    // height:"100% !important",
    border: '1px solid #d4d5de',
    borderRadius: '5px',
    // boxShadow: "10px 10px 15px #ddd",
    overflow: 'auto',
    overflowX: 'hidden',
    // overflow: "hidden",
    padding: '10px',
    height: '100vh',
    // height:'100%',
    '@media all and (max-width: 650px)': {
      marginTop: '10px',
      marginBottom: '20px',
      width: '400px',
      // height: "400px",
    },
  },
  formFieldMenu: {
    // margin: "15px",
    position: 'absolute',
    right: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
  },
  formHeaderHorizontalLine: {
    border: '.5px solid #03071e',
    backgroundColor: '#03071e',
  },
  chooseFile: {
    padding: '5px 10px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
  idText: {
    textAlign: 'left',
    // marginLeft: "5px",
    fontSize: '12px',
    color: '#6c757d',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0px',
    width: '17rem',
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checkboxInput: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 0px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  EditCheckbox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  totalCount: {
    display: 'flex',
    width: '50%',
    marginTop: '10px',
    justifyContent: 'space-between',
    '&:hover': {
      color: colors.primary.color,
    },
  },
  totalCountCheck: {
    marginTop: '13px',
    marginLeft: '30px',
  },
  formNameDefault: {
    fontSize: '25px',
    fontWeight: 600,
    fontStyle: 'oblique',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '1px',
  },
  editIconPen: {
    color: colors.primary.color,
    fontSize: '30px',
    cursor: 'pointer',
  },
  editFormName: {
    height: '45px',
    fontWeight: 600,
    fontStyle: 'oblique',
    fontSize: '20px',
    marginLeft: '20px',
    borderRadius: '6px',
  },
  tick: {
    marginLeft: '15px',
    cursor: 'pointer',
  },
  eyeIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  fullImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    borderRadius: '20px',
  },
  paperpopup: {
    border: '2px solid transparent',
    boxShadow: '0px 10px 10px rgb(0 0 0 / 30%)',
    borderRadius: '20px',
    width: 'auto',
    height: '50%',
    alignItems: 'center',
    padding: '0px !important',
  },
  outerAccordianRoot: {
    display: 'block',
  },
  innerAccordianRoot: {
    overflowX: 'hidden',
  },
  imgPopupFlexCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgPopupToggleContainer: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  imgPopupBottomContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
  },

  imgPopupButton: {
    cursor: 'pointer',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
  },
  settingsWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  togglewrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    marginTop: '5px',
  },
  selectedField: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rightFormWrapper: {
    minHeight: '582px',
    '@media (max-width: 1440px)': {
      minHeight: '425px',
    },
  },
  menuButton: {
    ...typography.h3,
    color: colors.secondary.color,
    fontSize: '1rem',
    fontFamily: 'Mulish',
    fontWeight: 400,
    cursor: 'pointer',
    '&:hover': {
      color: colors.primary.color,
    },
  },
  selectionStyle: {
    position: 'absolute',
    background: 'rgba(46, 115, 252, 0.11)',
    border: '2px solid rgba(98, 155, 255, 0.81)',
    borderRadius: '0.1em',
    padding: '0px',
  },
  formSettingsWrapperStyle: {
    position: 'fixed',
    width: '820px',
    bottom: '4px',
    backgroundColor: '#bf2331',
    color: 'white',
    borderRadius: '5px',
    maxWidth: '820px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '@media (max-width: 1200px)': {
      width: '500px',
    },
    '@media (min-width: 1200px) and (max-width: 1300px)': {
      width: '700px',
    },
  },
  tableSettingsWrapperStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  deSelectSettingsWrapperStyle: {
    cursor: 'pointer',
  },
}));
