/* eslint-disable react/function-component-definition */
import Moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import SpinnerLoader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import React from 'react';
import { t } from 'i18next';
import { AxiosResponse } from 'axios';
import { CircularLoader } from '../Components/Utils';
import theme from '../Theme';
import { FILETYPE } from './Constants';

export const commonResponseHandler = (promise, successCb, errorCb?) => {
  promise
    .then((response) => {
      SuccessToast(response.data.message);
      if (successCb) {
        successCb(response);
      }
    })
    .catch((error) => {
      ErrorToast(error.response.data.message || error.response.data.error);
      if (errorCb) {
        errorCb();
      }
    });
};
export interface CommonTranslatedResponseHandlerProps {
  promise: Promise<any>;
  successCb: (response: any) => void;
  errorCb?: (error: any) => void;
  successToast?: string;
  errorToast?: string;
}

export const CommonTranslatedResponseHandler: React.FC<
  CommonTranslatedResponseHandlerProps
> = ({ promise, successCb, errorCb, successToast, errorToast }) => {
  promise
    .then((response) => {
      SuccessToast(successToast);
      if (successCb) {
        successCb(response);
      }
    })
    .catch((error) => {
      ErrorToast(errorToast);
      if (errorCb) {
        errorCb(error.response);
      }
    });
  return null;
};
export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...params) => {
    const later = () => {
      timeout = null;
      if (!immediate) func(...params);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...params);
  };
};

// export const changeSort=(field)=> {
//   let sortOrder = "asc";
//     if(field==sort.sortBy){
//        sortOrder = sort.sort=="asc"?"desc":"asc";
//     }
//      setSort({
//          sortBy:field,
//          sort:sortOrder
//      })
//  }

export const formateDateToNonUtcDate = (field) => Moment(field).toDate();
// return Moment(field && field.replace("Z", "")).toDate();

export const formateDate = (field) => Moment(field).format('DD.MM.YYYY hh:mm a');
export const formateDateWithoutTime = (field) => Moment(field).format('DD.MM.YYYY');

export const sortingOptions = ({
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
  filter = '',
}) =>
  `sortBy=${sortBy}&sort=${sort}&search=${search}&page=${
    page + 1
  }&pageSize=${rowsPerPage}&filter=${filter}`;

export const sortingOptionFornest = ({
  sortBy = 'createdAt',
  sort = 'DESC',
  search = '',
  page = 0,
  rowsPerPage = 10,
  field = 'name',
}) => {
  const start = page * rowsPerPage;
  const end = start + rowsPerPage - 1;
  return `_sort=${sortBy}&_order=${sort}&search=${search}&_start=${start}&_end=${end}&filter=${JSON.stringify(
    [{ field, operation: 'equal', value: [search] }],
  )}`;
};

export const createErrorString = (response) => {
  if (response.data.error.length > 0) {
    let errorString = '';
    response.data.error.forEach((element) => {
      errorString =
        `${errorString}\n` +
        `\u2022 ${element.message.charAt(0).toUpperCase()}${element.message.slice(
          1,
        )} at document row ${element.index}`;
    });
    return errorString;
  }
  return null;
};

export const createErrorStringWithTranslation = (response) => {
  const string = (element) => {
    const ele = `${
      element.message.charAt(0).toUpperCase() + element.message.slice(1)
    } at document row `;
    return t(`errors.${ele}`);
  };

  if (response.data.error.length > 0) {
    let errorString = '';
    response.data.error.forEach((element) => {
      errorString =
        `${errorString}\n` +
        `\u2022 ${
          string(element)
          // eslint-disable-next-line no-useless-concat
        }` +
        `${element.index}`;
    });
    return errorString;
  }
  return null;
};

export const downloadDocument = (response, title) => {
  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
};
export const downloadDocumentFromUrl = (url: string, name?: string) => {
  const link = document.createElement('a');
  link.href = url;
  if (name) {
    link.setAttribute('download', name);
  }
  document.body.appendChild(link);
  link.click();
};

export const previewTypes = [
  FILETYPE.pdf,
  FILETYPE.image,
  FILETYPE.video,
  FILETYPE.png,
  FILETYPE.mp4,
];

export const openTab = (type: string, url: string, name?: string) => {
  const pdfWindow: any = window.open('about:blank', name);
  pdfWindow.document.write(
    `<html><head><title>${name}</title></head><body style="margin: 0px;" >`,
  );

  if (type === FILETYPE.image && type === FILETYPE.png) {
    pdfWindow.document.write(`<img src="${url}" alt="Image"></body></html>`);
    pdfWindow.document.close();
  } else {
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${url}' style="border:none;"></iframe></body></html>`,
    );
    pdfWindow.document.close();
  }
};

export function Loader({
  children,
  isLoading,
  type = 'circular',
  height = 50,
  width = 50,
}) {
  if (isLoading) {
    if (type === 'circular') return <CircularLoader />;
    if (type === 'ThreeDots')
      return (
        <SpinnerLoader
          type={type}
          color={theme.palette.primary.main}
          height={height}
          width={width}
        />
      );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export const filesString = ({ files, formData }) => {
  const infoString: Array<any> = [];
  files.forEach((el: any) => {
    if (el.files !== '') {
      const uuid = `files_${uuidv4()}`;
      formData.append(uuid, el.files);
      infoString.push({
        dropdown: el.dropdown,
        uuid,
        name: el.name,
        size: el.size,
        type: el.type,
        date: el.date,
      });
    }
  });
  return infoString;
};

export function AuthorizedUser({ rallyOrgId, success, error, publicView = false }) {
  const role = useSelector((store: RootState) => store.role.role);
  const roleId = useSelector((store: RootState) => store.role.user?.organizationId);

  if (
    (role === EnumUserRoles.superadmin ||
      role === EnumUserRoles.organizer ||
      (role === EnumUserRoles.eventOrganizer && roleId === rallyOrgId)) &&
    !publicView
  ) {
    return success;
  }
  return error;
}

export function axiosResponseToFileDownload({
  fileName,
  response,
}: {
  fileName: string;
  response: AxiosResponse<any>;
}) {
  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function handleMultiCheckboxChange(
  e,
  newValue: string,
  prevSelected: Array<string>,
) {
  const { checked } = e.target;
  if (checked === true) {
    return [...prevSelected, newValue];
  }
  return prevSelected.filter((el) => el !== newValue);
}

export function createFormDataFromFile(fieldName: string, file: File): FormData {
  const formData = new FormData();
  if (
    file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    'text/csv'
  ) {
    formData.append(fieldName, file);
  } else {
    console.error('unsupported file type');
  }
  return formData;
}

export function fileNameWithoutExtension(fileName: string): string {
  return fileName.split('.')[0];
}

export function getFileTypeExtension(fileType: string): string {
  return fileType.split('/')[1];
}

export function getTotalFileSize(fileArray: Array<any>): number {
  return fileArray.reduce((totalSize, file) => totalSize + file.size, 0);
}

/**
 * Takes a word (string) and returns a key-friendly version by:
 * - converting all characters to lower case
 * - replacing any spaces with underscores
 * @param {string} word The input word to convert
 * @return {string}
 */
export function convertWordToKey(word: string): string {
  return word.toLowerCase().split(' ').join('_');
}
