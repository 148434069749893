import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { commonResponseHandler } from '../../../Services/Utils';
import { updateOrganizer } from '../../../Services/Auth/Actions/OrganizerActions';
import AvatarUpload from '../../../Components/AvatarUpload/AvatarUpload';
import { FormLabel, FormButton, ErrorMessageCustom } from '../../../Components/Utils';
import { validator } from '../../../Services/Auth/Validations';
import { loggedUser, logoutUser } from '../../../Services/Auth/Actions/UserActions';
import useStyles from './EditOrganizerStyle';

function EditOrganizer({ details, type, reload, isEventOrganizers = false }) {
  const { t } = useTranslation();
  const AddNewOrganiserSchema = Yup.object().shape({
    organizerType: Yup.string().required('validationErrors.Type is Required'),
    companyname: validator.COMPANYNAME,
    username: validator.USERNAME,
    email: validator.EMAIL,
    phone: validator.PHONE,
    address1: validator.ADDRESS1,
    street: validator.STREET,
    city: validator.CITY,
    state: validator.STATE,
    country: validator.COUNTRY,
    postcode: validator.POSTCODE,
    facebookUrl: validator.FACEBOOKURL,
    instagramUrl: validator.INSTAGRAMURL,
    websiteUrl: validator.WEBSITEURL,
  });

  const classes = useStyles();
  const [image, setImage] = useState({ binary: '', fileName: '' });
  const [disabled, setDisabled] = useState(true);
  const userRole = useSelector((store: RootState) => store.role.user.role);
  const baseRole = useSelector((state: RootState) => state.role.role);
  const role = isEventOrganizers ? EnumUserRoles.eventOrganizer : baseRole;

  useEffect(() => {
    if (role === EnumUserRoles.superadmin || role === EnumUserRoles.organizer) {
      setDisabled(false);
    }
  }, [role]);

  const initialValues = {
    organizerType: type,
    companyname: details.companyname || '',
    email: details.email || '',
    phone: details.phone || '',
    address1: details.address1 || '',
    city: details.city || '',
    street: details.street || '',
    state: details.state || '',
    country: details.country || '',
    postcode: details.postcode || '',
    facebookUrl: details.facebookUrl || '',
    instagramUrl: details.instagramUrl || '',
    username: details.username || '',
    webciteUrl: details.webciteUrl || '',
  };

  const submitForm = (values, setSubmitting) => {
    const data: any = {
      ...values,
    };
    if (data.username) {
      data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
    }
    if (data.email) {
      data.email = data.email.toLowerCase();
    }
    const formData = new FormData();
    if (image.binary) {
      formData.append('file', image.binary, image.fileName);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
    commonResponseHandler(
      updateOrganizer(details._id, formData),
      () => {
        if (reload) {
          reload();
        }
        setSubmitting(false);
        if (details.username !== data.username) {
          logoutUser(details._id).then(() => {
            loggedUser(userRole);
          });
        }
      },
      () => setSubmitting(false),
    );
  };

  const showButton = (isSubmitting) => {
    if (role === EnumUserRoles.superadmin || role === EnumUserRoles.organizer) {
      return <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />;
    }
    return null;
  };

  return (
    <div className={classes.wrapper}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={AddNewOrganiserSchema}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values, setSubmitting);
        }}
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form>
            <div className={classes.topWrap}>
              <h3 className={classes.h3}>{t('overview.ORGANISER DETAILS')}</h3>
              {showButton(isSubmitting)}
            </div>
            <Box sx={{ flexGrow: 1 }} className={classes.box}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <AvatarUpload
                    image={image.binary}
                    defaultImage={details.profileImage}
                    setImage={setImage}
                    role={role}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Company Name')} />
                  <Field
                    className={classes.input}
                    text="companyname"
                    type="text"
                    name="companyname"
                    value={values.companyname}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="companyname"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Username')} />
                  <Field
                    className={classes.input}
                    text="username"
                    type="text"
                    name="username"
                    value={values.username.trim().toLowerCase().replace(/ +/g, '')}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="username"
                    touched={touched}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Email')} />
                  <Field
                    className={classes.input}
                    text="email"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom errors={errors} field="email" touched={touched} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Phone')} />
                  <Field
                    className={classes.input}
                    text="phone"
                    type="phone"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom errors={errors} field="phone" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Address Line 1')} />
                  <Field
                    className={classes.input}
                    text="address1"
                    type="text"
                    name="address1"
                    value={values.address1}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="address1"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Street')} />
                  <Field
                    className={classes.input}
                    text="street"
                    type="text"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom errors={errors} field="street" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Postcode')} />
                  <Field
                    className={classes.input}
                    text="postcode"
                    type="text"
                    name="postcode"
                    value={values.postcode}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="postcode"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.City')} />
                  <Field
                    className={classes.input}
                    text="city"
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom errors={errors} field="city" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.State')} />
                  <Field
                    className={classes.input}
                    text="state"
                    type="text"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom errors={errors} field="state" touched={touched} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Country')} />
                  <Field
                    className={classes.input}
                    text="country"
                    type="text"
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom errors={errors} field="country" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Facebook URL')} />
                  <Field
                    className={classes.input}
                    text="facebookUrl"
                    type="text"
                    name="facebookUrl"
                    value={values.facebookUrl}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="facebookUrl"
                    touched={touched}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Instagram URL')} />
                  <Field
                    className={classes.input}
                    text="instagramUrl"
                    type="text"
                    name="instagramUrl"
                    value={values.instagramUrl}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="instagramUrl"
                    touched={touched}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormLabel name={t('forms.Website URL')} />
                  <Field
                    className={classes.input}
                    text="webciteUrl"
                    type="text"
                    name="webciteUrl"
                    value={values.webciteUrl}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <ErrorMessageCustom
                    errors={errors}
                    field="webciteUrl"
                    touched={touched}
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default EditOrganizer;
