import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import useStyles from './EventListingStyle';

function ParticipantActions({ item }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Link to={`events/details/${item._id}/details`} className={classes.linkText}>
        <Icon icon="feather:edit-3" className={classes.editIcon} />
      </Link>
      <Link to={`events/details/${item._id}/rallies`} className={classes.linkText}>
        <Icon icon="clarity:flag-solid" className={classes.editIcon} />
      </Link>
    </>
  );
}

export default ParticipantActions;
