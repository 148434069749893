import { toast } from 'react-toastify';

function ErrorToast(toastMsg) {
  toast.error(toastMsg, {
    position: 'top-center',
    autoClose: 5000,
  });
}

export default ErrorToast;
