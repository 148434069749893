import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';
import theme from '../../../Theme';

export default makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  paper: {
    backgroundColor: 'white',
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '50%',
    height: '75%',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    overflow: 'auto',
    padding: '35px',
    position: 'relative',
  },

  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    color: '#9FA2B4',
    position: 'absolute',
    top: '5px',
    right: '10px',
  },

  title: {
    ...typography.h5,
    marginBottom: '50px',
    textAlign: 'center',
  },

  link: {
    marginTop: '25px',
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  textarea: {
    borderRadius: '10px',
    borderColor: 'white',
    fontFamily: 'Helvetica,Arial,sans-serif',
  },
}));
