import React, { useState, useCallback, Fragment } from 'react';

import ConfirmContext from './ConfirmContext';
import ConformPopupModal from './ConformPopupModal';

function ConfirmProvider({ children }) {
  const defaultDescription = 'Are you sure?';
  const [options, setOptions] = useState<{ description: string }>({
    description: 'Are you sure?',
  });
  const [resolveReject, setResolveReject] = useState<Array<any>>([]);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback(
    (opt = {}) =>
      new Promise((res, rej) => {
        setOptions(opt);
        setResolveReject([res, rej]);
      }),
    [],
  );

  const handleClose = useCallback(() => {
    setOptions({
      description: defaultDescription,
    });
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve();
    }
    handleClose();
  }, [resolve, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConformPopupModal
        showModel={resolveReject.length === 2}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        description={options.description}
      />
    </>
  );
}

export default ConfirmProvider;
