import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import './Style.css';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useHistory } from 'react-router';
import { getToken } from '../../Services/Auth/Actions/UserActions';
import PersistentDrawerLeft from '../../Components/Drawer/PersistentDrawerLeft';
import NavBar from '../../Components/NavBar/NavBar';
import Logo from '../../Components/Logo/Logo';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // console.log('token expired');
      // window.location.href = "/";
      if (window.location.pathname.split('/')[1] === 'event-details') {
        // do nothing
      } else {
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  },
);

function Dashboard({ children }) {
  const history = useHistory();

  const role = useSelector((store: RootState) => store.role.role);
  if (!getToken()) {
    history.push('/');
  }

  return (
    <div className="dashboard-layout">
      <div className={role === 'participant' ? 'left-sec bg' : 'left-sec'}>
        <div className="desktop-menu">
          <Logo />
          <NavBar />
        </div>
      </div>

      <div className="right-sec">
        <div className="mobile-menu">
          <PersistentDrawerLeft />
        </div>
        {children}
      </div>
    </div>
  );
}

export default Dashboard;
