/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useContext } from 'react';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { Icon } from '@iconify/react';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { CustomToolTip } from 'src/Components/ToolTip/ToolTip';
import { Typography, Grid } from '@mui/material';
import { dynamicFormConfig } from 'src/Libs/DynamicForm/config/dynamicFormConfig';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { tableOnAddCell } from 'src/Components/DynamicTable/utils';
import useStyles from '../../../newEventFormStyle';
import {
  getGenericDateValue,
  getTotalCountField,
  fieldWrapperExtraStyles,
} from '../util';

const getRightComponentValues = (
  data: FormFieldEditType,
  added: FormFieldEditType[],
): string => {
  switch (data.type) {
    case 'date':
      return getGenericDateValue(data);
    case 'totalField':
      return getTotalCountField(data, added);
    case 'checkbox':
      return data?.values[0] === 'checked' ? 'checked' : '';
    case 'textarea':
      return data?.values[0];
    case 'empty':
      return '';
    default:
      return data?.values[0];
  }
};

const FormFieldElements = React.forwardRef<any, any>((props, ref) => {
  const { data, mode, previewMode } = props;
  const classes = useStyles();
  const [isDragged, setIsDragged] = useState(false);
  const { t } = useTranslation();
  const dynamicConfig = dynamicFormConfig[data.type];
  const { RightComponent } = dynamicConfig;
  const {
    removed,
    editFormField,
    deleteCurrentItem,
    addRemove,
    added,
    setAdded,
    fullData,
  } = useContext(DynamicFormContext);
  return (
    <div
      ref={ref}
      key={data.index}
      onDoubleClick={() => {
        if (!previewMode) {
          editFormField(data);
        }
      }}
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        width: '100%',
        zIndex: data.type === 'pin' ? 1000 : 'auto',
        ...fieldWrapperExtraStyles(data.styles),
        ...props.style,
      }}
    >
      <div className={data.type === 'table' ? '' : classes.formFieldMenu}>
        {removed?.includes(data) && (
          <div className={data.type === 'table' ? '' : classes.selectedField}>
            {data.type !== 'line' && data.type !== 'pin' && (
              <Icon
                icon="feather:edit-3"
                className={classes.editIcon}
                onClick={() => {
                  if (!previewMode) {
                    editFormField(data);
                  }
                }}
              />
            )}
            {data.type === 'table' && (
              <div
                className={classes.deleteIcon}
                onClick={() => tableOnAddCell(data.index, added, setAdded, fullData)}
              >
                <Icon icon="feather:plus" />
              </div>
            )}
            {data.type !== 'empty' && (
              <div
                className={classes.deleteIcon}
                onClick={() => deleteCurrentItem(data.index)}
              >
                <DeleteIcon />
              </div>
            )}
          </div>
        )}
      </div>
      <CustomToolTip
        title={
          <div style={{ width: '100%' }}>
            <Typography color="inherit">
              {' '}
              {t(`formFields.Category`)} - {data.category}
            </Typography>
            <Typography color="inherit">
              {t(`formFields.Id`)} - {data.key}
            </Typography>
          </div>
        }
        arrow
        key={data.index}
        disableHoverListener
      >
        <Grid
          item
          className={clsx([
            `grid-item-${data.type}-custom-class`,
            removed?.includes(data)
              ? `${classes.selected} ${classes.inputlabel}`
              : classes.inputlabel,
          ])}
          onMouseUp={() => {
            if (!previewMode && !isDragged) {
              addRemove(data.index);
            }
          }}
          onMouseDown={() => setIsDragged(false)}
        >
          <RightComponent
            fieldData={data}
            mode={mode}
            aditionalProps={{
              values: getRightComponentValues(data, added),
              disableResizeHandler: false,
              showTable: true,
            }}
            isEditable={mode === 'preview'}
          />
        </Grid>
      </CustomToolTip>
    </div>
  );
});

export default FormFieldElements;
