import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/Store';
import ProfileHeader from 'src/Components/ProfileHeader/ProfileHeader';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import EditUser from '../../Containers/Users/UserDetails/EditUser';
import UserProfile from '../../Containers/Users/UserDetails/UserProfile';
import { getUser } from '../../Services/Auth/Actions/UserActions';
import EventListing from '../../Containers/Events/EventListing/EventListing';

function UserDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [details, setDetails] = useState<any>({});
  const [type, setType] = useState<string>('');
  const { id, page } = useParams<any>();
  const [currentPage, setCurrentPage] = useState(page);
  const role = useSelector((store: RootState) => store.role.role);
  const location = useLocation();
  const history = useHistory();

  let goBack;
  if (location.state) {
    goBack = true;
  } else {
    goBack = false;
  }
  useEffect(() => {
    if (id) {
      const userDetailsById = () => {
        getUser(id, EnumUserRoles.participant)
          .then((response) => {
            const data = response.data.user;
            setDetails(data);
            setType('participant');
          })
          .catch((error) => {
            console.log(error);
          });
      };

      userDetailsById();
    }
  }, []);

  const upDateUser = () => {
    getUser(id, EnumUserRoles.participant)
      .then((response) => {
        const data = response.data.user;
        if (role !== 'superadmin' && role !== 'organizer' && role !== 'eventOrganizer') {
          dispatch({
            type: 'SAVE_USER',
            payload: data,
          });
        }
        setDetails(data);
        setType('superadmin');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navTabOnClick = (pageName: string) => {
    history.push({
      pathname: `/users/details/${id}/${pageName}`,
      state: { showGoBack: true },
    });
    setCurrentPage(pageName);
  };
  return (
    <div>
      <Dashboard>
        <Header title={t('users.Participant Profile')} />
        <ProfileHeader
          details={details}
          navTabOnClick={navTabOnClick}
          currentPage={currentPage}
        />

        {currentPage === 'events' ? (
          <EventListing profileDetails={details} showMoreOptions={false} />
        ) : null}
        {currentPage === 'profile' ? (
          <Grid container spacing={1}>
            <Grid item lg={7} xs={12}>
              <EditUser
                reload={upDateUser}
                details={details}
                type={type}
                showGoBack={goBack}
              />
            </Grid>
            <Grid item lg={5} xs={12}>
              <UserProfile details={details} type={type} />
            </Grid>
          </Grid>
        ) : null}
      </Dashboard>
    </div>
  );
}

export default UserDetails;
