import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { RootState } from 'src/Store';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import AVATAR from './AvatarImage.svg';
import { AVATARSIZE } from '../../Services/Constants';
import PLUS from './add_circle_red.svg';
import { fileSizeToMB } from '../Utils/DisplayFileSize';
import AvatarCrop from '../AvatarCrop/AvatarCrop';

const useStyles = makeStyles(() => ({
  container: {
    width: '150px',
    height: '60px',
  },
  wrap: {
    width: '100px',
    height: '50px',
    marginLeft: '25px',
    position: 'relative',
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    position: 'absolute',
    top: '0px',
    left: '0px',
    cursor: 'pointer',
  },
  plus: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '40px',
    left: '40px',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    cursor: 'pointer',
    opacity: '0',
    fontSize: '0',
  },
  imgPlus: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginTop: '5px',
  },
  label: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    cursor: 'pointer',
  },
}));

function AvatarCropModal({ image, setImagePreviewUrl, setImage, name, setOpenPopup }) {
  return (
    <AvatarCrop
      imgSrc={image}
      setImagePreviewUrl={setImagePreviewUrl}
      setImage={setImage}
      name={name}
      setOpenPopup={setOpenPopup}
    />
  );
}

function AvatarUpload({ image, setImage, defaultImage, page }: any) {
  const classes = useStyles();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const role = useSelector((state: RootState) => state.role.role);
  const modal = useModalDialog();
  const setOpenAvatarCropper = useMemo(
    () => modal(AvatarCropModal, 'AvatarCropModal'),
    [],
  );

  useEffect(() => {
    const roles = ['superadmin', 'organizer', 'eventOrganizer'];
    if (roles.includes(role) || page === 'participant profile') {
      setDisabled(false);
    }
  }, [role]);

  const onImageChange = (event: any) => {
    if (_.includes(event.target.files[0].type, 'image/', 0)) {
      if (event.target.files && event.target.files[0]) {
        const fileSize: number = fileSizeToMB(event.target.files[0].size);
        if (fileSize > AVATARSIZE) {
          toast.warning(
            t(`General settings.choose an image file less than ${AVATARSIZE}MB`),
          );
          return;
        }
        const saveImage = event.target.files[0];
        const { name } = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setOpenAvatarCropper(true, {
            image: reader.result?.toString() || '',
            setImagePreviewUrl,
            setImage,
            name,
          }),
        );
        reader.readAsDataURL(saveImage);
      }
    } else {
      toast.warning(t('General settings.Please choose an image file'));
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.wrap}>
        <img
          src={imagePreviewUrl || defaultImage || image || AVATAR}
          className={classes.img}
          alt="avatar"
        />

        <input
          type="file"
          id="img"
          onChange={onImageChange}
          accept="image/*"
          disabled={disabled}
          className={classes.input}
        />

        {disabled ? null : (
          <div className={classes.plus}>
            <img src={PLUS} className={classes.imgPlus} alt="plus" />
            <input
              type="file"
              id="img"
              onChange={onImageChange}
              accept="image/*"
              disabled={disabled}
              className={classes.input}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AvatarUpload;
