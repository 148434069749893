import { useState } from 'react';
import { includes } from 'lodash';

import { toast } from 'react-toastify';
import { SetStateSetterType } from 'src/Types/UseFormTypes';

type Props = {
  setShowImageModal: SetStateSetterType<boolean>;
};

const useShowImagePopup = ({ setShowImageModal }: Props) => {
  const [base64String, setBase64String] = useState('');
  const [imageFileData, setImageFileData] = useState<any>({});
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const onCloseImage = () => {
    setShowImageModal(false);
    setBase64String('');
  };

  const getBase64 = (file: any): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(reject);
      };
    });

  // onchange image file
  const selectImage = async (event: any) => {
    const file = event.target.files[0];
    setImagePreviewUrl(URL.createObjectURL(file));

    if (!includes(file.type, 'image/', 0)) {
      toast.warning('Please choose an image file...');
      return;
    }
    setImageFileData(file);
    const base64Result: any = await getBase64(file);
    setBase64String(base64Result);
  };

  // when submitting the image upload
  const submitImage = (e: any, data: any) => {
    e.preventDefault();
    // eslint-disable-next-line no-param-reassign
    data.imgUrl = base64String;
    // eslint-disable-next-line no-param-reassign
    data.isImage = true;
    // eslint-disable-next-line no-param-reassign
    data.imageName = imageFileData.name;
    // eslint-disable-next-line no-param-reassign
    data.imageType = imageFileData.type;
    // eslint-disable-next-line no-param-reassign
    data.imageBinary = imageFileData;

    // setShowImageModal(false);
    // setBase64String('');
  };

  return {
    onCloseImage,
    submitImage,
    selectImage,
    base64String,
    imagePreviewUrl,
  };
};

export default useShowImagePopup;
