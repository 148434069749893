import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Field } from 'formik';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '95%',
    textTransform: 'none',
  },
}));

function FormInput({ text, type, onChange, onBlur, value }: any) {
  const classes = useStyles();
  return (
    <Field
      className={classes.input}
      text={text}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
}

export default FormInput;
