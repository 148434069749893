import { ONE_KB, ONE_MB, TOTAL_FILE_SIZE_LIMIT } from 'src/Services/Constants';
import { getTotalFileSize } from 'src/Services/Utils';
import { AttachmentDragDropType, AttachmentType } from 'src/Types/AttachmentType';

export const fileSizeToMB = (fileSize: number) => Math.round(fileSize / ONE_MB);

export const fileSizeToKB = (fileSize: number) => Math.round(fileSize / ONE_KB);

function displayFileSize(size: number): string {
  if (size > ONE_MB) {
    return `${fileSizeToMB(size)} MB`;
  }
  return `${fileSizeToKB(size)} KB`;
}
export default displayFileSize;

export function checkTotalFileSize(
  fileArray: Array<AttachmentType | AttachmentDragDropType>,
): {
  isError: boolean;
  totalSize: number;
} {
  const totalSize = getTotalFileSize(fileArray);
  if (totalSize > TOTAL_FILE_SIZE_LIMIT) {
    return { isError: true, totalSize };
  }
  return { isError: false, totalSize };
}
