import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon } from '@iconify/react';
import Moment from 'moment';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';

import AVATAR from './AvatarImage.svg';
import INSTAGRAM from './instagram 1 (1).svg';
import FACEBOOK from './Vector.svg';
import FLAG from './Flag.png';
// import {updateOrganizer} from '../../../Services/Auth/Actions/OrganizerActions'
const useStyles = makeStyles((theme) => ({
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },

  btnWrap: {
    textAlign: 'left',
  },
  btn: {
    float: 'left',
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },

  wrapper: {
    minHeight: '650px',
    maxHeight: '656px',
    backgroundColor: '#fff',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:767px)': {
      padding: 0,
      margin: '30px 0px 20px',
      height: 'auto',
    },
    '@media all and (max-width:2556px)': {
    height: '656px',
    }

  },
  topWrap: {
    height: '91px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 30px 15px',
    background: '#dfe0eb40',
    alignItems: 'center',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #888',
    padding: '13px',
    maxWidth: '250px',
    margin: '0 auto 20px',
    '@media all and (max-width:767px)': {
      flexDirection: 'column',
    },
  },
  box: {
    padding: '15px 30px 15px',
    position: 'relative',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '25px',
  },
  h3: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
  img: {
    width: '100%',
    height: '100px',
    objectFit: 'contain',
  },
  socialImage: {
    width: '33px',
    height: '50px',
  },
  h5: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    color: colors.secondary.color,
    margin: '26px 0 0 0',
    marginTop: '26px !important',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
  },
  h6: {
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    fontWeight: 400,
    color: colors.ternary.color,
    margin: '0 0 20px',
    borderBottom: '1px',
  },
  icon: {
    fontSize: '35px',
    color: colors.primary.color,
    margin: 0,
  },
  p: {
    fontFamily: typography.fonts.secondary,
    fontSize: '14px',
    fontWeight: 400,
    color: colors.secondary.color,
    maxWidth: '250px',
    margin: '5px auto',
  },
  border: {
    padding: '0 20px',
    '&:firt-of-type': {
      borderRight: '1px solid #9FA2B4',
    },
  },
  imgWrap: {
  //  maxWidth: '50%',
  //  maxWidth: '50%',
    maxWidth: '250px',
    margin: '0 auto 20px',
    borderBottom: '1px solid #9FA2B4',
    alignItems: 'center',
  },
  socialIcon: {
    fontSize: '35px',
    margin: '20px 10px',
  },
  socialMedia: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // marginLeft: "14px",
    wordBreak: 'break-all',
  },
  socialMediaWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  urlDetails: {
    marginLeft: '20px',
    textDecoration: 'inherit',
  },
  socialMediaWrap1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  compregLogo: {
    height: '60px',
    width: '60px',
    transform: 'rotate(90deg)',
    position: 'absolute',
    top: '52px',
    right: '7px',
  },
  h31: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '27px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
    position: 'absolute',
    transform: 'rotate(-90deg)',
    top: '157px',
    right: '-28px',
    width: '114px',
    height: '74px',
  },
  urlDetails1: {
    width: '155px',
    fontSize: '12px',
    color: '#000',
    textDecorationLine: 'none',
    overflowWrap: 'anywhere',
  },
}));

function OrganizerProfile({ details, type }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.topWrap}>
        <h3 className={classes.h3}>{t('users.Profile')}</h3>
        {/* <Icon icon="feather:edit-3" className={classes.editIcon}/>  */}
      </div>
      <Box sx={{ flexGrow: 1 }} className={classes.box}>
        <h3 className={classes.h31}>{t('COMPREG')}</h3>
        <img className={classes.compregLogo} src={FLAG} alt="logo" />

        <div>
          <div className={classes.imgWrap}>
            <img
              src={
                details.profileImage
                  ? details.profileImage === 'null'
                    ? AVATAR
                    : details.profileImage
                  : AVATAR
              }
              alt="avatar"
              className={classes.img}
            />

            <h5 className={classes.h5}>
              {details.companyname ||
                (details.firstName && `${details.lastName} ${details.firstName}`)}
            </h5>
            <h6 className={classes.h6}>
              {' '}
              {details.lastLoggedIn ? (
                <>
                  {Moment(details.lastLoggedIn).format('DD.MM.YYYY')}
                  <br />
                  {Moment(details.lastLoggedIn).format('hh:mm:a')}
                </>
              ) : (
                <>{t('organizers.Never')}</>
              )}
            </h6>
          </div>
          <div className={classes.wrap}>
            {/* <div className={classes.border}>
                            <Icon icon="fluent:mail-24-filled" className={classes.icon} />
                            <p className={classes.p}>{details.email}</p>
                        </div> */}
            <a
              style={{ textDecoration: 'none' }}
              href={details.email ? `mailto:${details.email}` : ''}
              target="_blank"
              className={classes.border}
              rel="noreferrer"
            >
              <div className={classes.socialMediaWrap1}>
                <Icon icon="dashicons:email" className={classes.editIcon} />
                {/* <img src={EMAIL} alt="email" className={classes.icon} /> */}
                <div className={classes.urlDetails1}>
                  {details.email ? details.email : '___________'}
                </div>
              </div>
            </a>
            <div
              style={{
                width: '0px',
                height: '50px',
                border: '1px solid #888',
                backgroundColor: '#B7B0B0',
                marginTop: '-18px',
                position: 'relative',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            />

            <a
              style={{ textDecoration: 'none' }}
              href={details.phone ? `tel:${details.phone}` : ''}
              target="_blank"
              className={classes.border}
              rel="noreferrer"
            >
              <div className={classes.socialMediaWrap1}>
                <Icon icon="bytesize:telephone" className={classes.editIcon} />
                {/* <img src={TELE} alt="phone" className={classes.icon} /> */}
                <div className={classes.urlDetails1}>
                  {details.phone ? details.phone : '___________'}
                </div>
              </div>
            </a>

            {/* <div className={classes.border}>
                            <Icon icon="bytesize:telephone" className={classes.icon} />
                            <p className={classes.p}>{details.phone}</p>
                        </div> */}
          </div>
          {/* <div className={classes.address}>
                        <p className={classes.p}>{details.address1}</p>
                        <p className={classes.p}>{details.street}</p>
                        <p className={classes.p}>{details.city}</p>
                        <p className={classes.p}>{details.country}</p>
                        <p className={classes.p}>{details.state}</p>
                    </div> */}
          <div className={classes.socialMedia}>
            {/* {details.facebookUrl && ( */}
            {/* <div className={classes.socialMediaWrap}>
                            <img src={FACEBOOK} alt="facebook" className={classes.socialImage} />
                            <div className={classes.urlDetails}>{details.facebookUrl ? (<a target="_blank" href={details.facebookUrl}>{details.facebookUrl}</a>) : "___________"}</div>
                        </div> */}

            {/* <a
                                href={details.facebookUrl ? details.facebookUrl : null}
                                target="_blank"
                                className={classes.socialMedia}
                            >
                                <div className={classes.socialMediaWrap}>
                                <img src={FACEBOOK} alt="facebook" className={classes.socialImage} />
                                <div className={classes.urlDetails}>
                                {details.facebookUrl ? details.facebookUrl : "___________"}
                                </div>
                                </div>
                            
                            </a> */}
            {/* )} */}
            {/* {details.instagramUrl && ( */}

            <a
              href={details.facebookUrl ? details.facebookUrl : null}
              target="_blank"
              className={classes.socialMedia}
              rel="noreferrer"
            >
              <div className={classes.socialMediaWrap}>
                <img src={FACEBOOK} alt="facebook" className={classes.socialImage} />
                <div className={classes.urlDetails}>
                  {details.facebookUrl ? details.facebookUrl : '___________'}
                </div>
              </div>
            </a>
            <a
              href={details.instagramUrl ? details.instagramUrl : null}
              target="_blank"
              className={classes.socialMedia}
              rel="noreferrer"
            >
              <div className={classes.socialMediaWrap}>
                <img src={INSTAGRAM} alt="instagram" className={classes.socialImage} />
                <div className={classes.urlDetails}>
                  {details.instagramUrl ? details.instagramUrl : '___________'}
                </div>
              </div>
            </a>
            {/* )} */}
            {/* {details.websiteUrl && ( */}
            {/* <a
                            href={details.websiteUrl ? details.websiteUrl : null}
                            target="_blank"
                            className={classes.socialMedia}
                        >
                            <div className={classes.socialMediaWrap}>
                                <img src={WEBSITE} alt="website" className={classes.socialImage} />
                                <div className={classes.urlDetails}>
                                    {details.websiteUrl ? details.websiteUrl : "___________"}
                                </div>
                            </div>
                        </a> */}
            {/* )} */}
          </div>
        </div>
      </Box>
    </div>
  );
}

export default OrganizerProfile;
