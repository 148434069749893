import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Grid, makeStyles, Switch } from '@material-ui/core';
import { ErrorMessageCustom, FormButton, FormLabel } from '../Utils';
import {
  getVersionDetails,
  updateVersion,
} from '../../Services/Auth/Actions/UserActions';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '30%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
    // marginLeft: "10px",
  },
  adminemail: {
    maxWidth: '75%',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchLabel: {
    width: '200px',
  },

  switch: {
    width: 'fit-content',
  },
}));

export default function EmailRestriction() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [state, setState] = useState({
    emailParticipant: false,
    emailOrganizer: false,
    emailOrganizerContact: false,
  });

  const emailSchema = Yup.object().shape({
    testEmail: Yup.string().email('validationErrors.Invalid email'),
  });

  const versionDetails = () => {
    getVersionDetails().then((response) => {
      setData(response.data);
      setState({
        emailParticipant: response.data.sendEmailsToParticipant,
        emailOrganizer: response.data.sendEmailsToOrganizer,
        emailOrganizerContact: response.data.sendEmailsToOrganizerContact,
      });
    });
  };

  const handleChangeToggle = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    versionDetails();
  }, []);
  return (
    <div>
      <div className={classes.wrapper}>
        <Formik
          initialValues={{
            testEmail: data?.testEmail || '',
            emailParticipant: data?.sendEmailsToParticipant || '',
            emailOrganizer: data?.sendEmailsToOrganizer || '',
            emailOrganizerContact: data?.sendEmailsToOrganizerContact || '',
          }}
          enableReinitialize
          validationSchema={emailSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            const formData: any = {
              ...state,
            };
            formData.testEmail = values.testEmail;
            if (
              formData.emailParticipant ||
              formData.emailOrganizer ||
              formData.emailOrganizerContact
            ) {
              if (formData.testEmail === '') {
                setFieldError('testEmail', 'validationErrors.Admin email is required');
                setSubmitting(false);
                return;
              }
            }
            formData.sendEmailsToParticipant = state.emailParticipant;
            formData.sendEmailsToOrganizer = state.emailOrganizer;
            formData.sendEmailsToOrganizerContact = state.emailOrganizerContact;
            delete formData.emailParticipant;
            delete formData.emailOrganizer;
            delete formData.emailOrganizerContact;
            updateVersion(formData).then(() => {
              setSubmitting(false);
              toast.success(t('General settings.Submitted successfully'));
            });
          }}
        >
          {({ errors, touched, isSubmitting, values, handleChange }) => (
            <Form>
              <div className={classes.titleWrapper}>
                <h2 style={{ textAlign: 'center', width: '100%' }}>
                  {t('General settings.Email Restrictions')}
                </h2>
                <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <FormLabel
                      className={classes.adminemail}
                      name={t('General settings.Admin Email')}
                      require
                    />
                    <Field
                      className={classes.input}
                      name="testEmail"
                      type="email"
                      text="testEmail"
                      value={values.testEmail}
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessageCustom
                    errors={errors}
                    field="testEmail"
                    touched={touched}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FormLabel
                      className={classes.switchLabel}
                      name={t('General settings.Email Restriction for Participant')}
                    />
                    <Switch
                      checked={state.emailParticipant}
                      onChange={handleChangeToggle}
                      color="primary"
                      name="emailParticipant"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                    {state.emailParticipant && (
                      <div className={classes.switch}>
                        {t(
                          'General settings.All the participant emails will be forwarded to admin email',
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FormLabel
                      className={classes.switchLabel}
                      name={t('General settings.Email Restriction for Oragnizer')}
                    />
                    <Switch
                      checked={state.emailOrganizer}
                      onChange={handleChangeToggle}
                      color="primary"
                      name="emailOrganizer"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                    {state.emailOrganizer && (
                      <div className={classes.switch}>
                        {t(
                          'General settings.All the Oragniser emails will be forwarded to admin email',
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FormLabel
                      className={classes.switchLabel}
                      name={t('General settings.Email Restriction for Oragnizer Contact')}
                    />
                    <Switch
                      checked={state.emailOrganizerContact}
                      onChange={handleChangeToggle}
                      color="primary"
                      name="emailOrganizerContact"
                      inputProps={{
                        'aria-label': 'primary checkbox',
                      }}
                    />
                    {state.emailOrganizerContact && (
                      <div className={classes.switch}>
                        {t(
                          'General settings.All the Oragniser Contact emails will be forwarded to admin email',
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
