import { Icon } from '@iconify/react';
import { Fade, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import theme from 'src/Theme';
import colors from 'src/Theme/colors';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px 0',
    position: 'relative',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  desc: {
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  closeIcons: {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#bf2331',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
});

function ConformPopupModal({ showModel, onConfirm, onCancel, description }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModel}
        onClose={onCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModel}>
          <div className={classes.paper}>
            <Icon
              icon="carbon:close-outline"
              onClick={onCancel}
              className={classes.closeIcons}
            />
            <h3 id="transition-modal-description" className={classes.desc}>
              {description}
            </h3>
            <div className={classes.sign}>
              <p className={classes.link} onClick={onConfirm}>
                {t('popup.Yes')}
              </p>
              <p className={classes.link} onClick={onCancel}>
                {t('popup.Cancel')}
              </p>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ConformPopupModal;
