import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { Tooltip } from '@material-ui/core';
import AVATAR_IMAGE from './Avatar_1.svg';
import useStyles from './AdminMenuStyle';
import SwitchProfile from './SwitchProfile';
import ChangePasswordWrapper from './ChangePassword';
import ChangeUserNameWrapper from './ChangeUserName';
import CanAccessByRole from '../Utils/CanAccessByRole';

function AdminMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const users = useSelector((store: RootState) => store.role.user);
  const history = useHistory();
  const confirm: any = useConfirm();
  const dispatch = useDispatch();
  const modal = useModalDialog();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const loggoutUser = () => {
    dispatch({
      type: 'SAVE_TOKEN',
      payload: '',
    });
    dispatch({
      type: 'SAVE_ROLE',
      payload: '',
    });
    dispatch({
      type: 'SAVE_USER',
      payload: {},
    });
    history.push('/');
  };

  const handelDelete = () => {
    confirm({
      description: t('profile.Are you sure you want to sign out?'),
    }).then(() => {
      loggoutUser();
    });
  };

  const handleToggle = () => {
    setTooltipOpen(false);
    setOpen((prevOpen) => !prevOpen);
  };
  const handleSetOpen = useMemo(() => modal(SwitchProfile, 'switch-profile'), []);

  const handleOpenPopups = (openPopup) => {
    switch (openPopup) {
      case 'userName':
        break;

      case 'signOut':
        handelDelete();
        break;

      case 'switchProfile':
        handleSetOpen(true, { handleToggle });
        break;

      default:
        break;
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const isSuperAdmin = users.role === EnumUserRoles.superadmin;
  const showProfileImage = () => {
    if (users.profileImage) {
      return <img src={users.profileImage} className={classes.avatar} alt="img" />;
    }
    return <img src={AVATAR_IMAGE} className={classes.avatar} alt="img" />;
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const userName = isSuperAdmin
    ? t('users.Super Admin')
    : users.firstName || users.companyname;

  return (
    <div className={classes.root}>
      <div>
        <Tooltip
          title={userName}
          open={tooltipOpen}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          placement="bottom"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.button}
          >
            <h6 className={classes.userName}>{userName}</h6>

            {isSuperAdmin ? (
              <img src={AVATAR_IMAGE} className={classes.avatar} alt="img" />
            ) : (
              <> {showProfileImage()}</>
            )}
          </Button>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    className="super-admin-menu"
                    autoFocusItem={open}
                    id="menu-list-grow"
                    // eslint-disable-next-line react/jsx-no-bind
                    onKeyDown={handleListKeyDown}
                  >
                    <div>
                      {' '}
                      {isSuperAdmin ? (
                        <img src={AVATAR_IMAGE} className={classes.avatar} alt="img" />
                      ) : (
                        <Link to={`users/details/${users._id}`}>
                          {showProfileImage()}
                        </Link>
                      )}
                    </div>
                    <CanAccessByRole
                      roles={[EnumUserRoles.organizer, EnumUserRoles.eventOrganizer]}
                    >
                      <Link
                        to={`/organizers/details/${users.organizationId}/profile`}
                        style={{ textDecoration: 'none' }}
                      >
                        <p>{t('users.Profile')}</p>
                      </Link>
                    </CanAccessByRole>
                    <CanAccessByRole roles={[EnumUserRoles.participant]}>
                      <Link
                        to={`/users/details/${users._id}/profile`}
                        style={{ textDecoration: 'none' }}
                      >
                        <p>{t('users.Profile')}</p>
                      </Link>
                    </CanAccessByRole>

                    <ChangeUserNameWrapper />

                    <ChangePasswordWrapper />
                    <CanAccessByRole
                      roles={[EnumUserRoles.organizer, EnumUserRoles.eventOrganizer]}
                      switchRole
                    >
                      <p onClick={() => handleSetOpen(true, { handleToggle })}>
                        {t('signUp.Switch Profile')}
                      </p>
                    </CanAccessByRole>

                    <p
                      onClick={() => {
                        handleOpenPopups('signOut');
                      }}
                    >
                      {t('signUp.Signout')}
                    </p>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

export default connect(null, {})(AdminMenu);
