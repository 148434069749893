import { Icon } from '@iconify/react';
import React from 'react';
import useStyles from './SettingsTableStyle';

type ButtonType = {
  icon: string;
  onClick: React.MouseEventHandler<SVGSVGElement>;
  class?: string;
};

function TableActionButton({ button }: { button: Array<ButtonType> }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableicon}>
        {button.map((el) => (
          <Icon
            onClick={el.onClick}
            icon={el.icon}
            className={el.class}
            style={{ marginRight: '20px' }}
          />
        ))}
      </div>
    </div>
  );
}

export default TableActionButton;
