/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useContext, useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import ImageLoader from 'src/Components/Utils/ImageLoader';
import { getRally } from 'src/Services/Auth/Actions/RallyActions';
import { GetRallyTypes } from 'src/Services/Auth/Actions/RallyTypeActions';
import { FormFieldsType } from 'src/Types/FormFieldsType';
import {
  getSingleFormByEventId,
  updateSingleFormByEventId,
  getEvent,
  imageUpload,
} from '../../../Services/Auth/Actions/EventActions';
// Form based components
import { showEmptyFormToast } from './components/DynamicForm/util';
import FormCreateEdit from './FormCreateEdit';
import EventFormPreview from './EventFormPreview';
import { Preview } from '../EventParticipants/FormPreview';
import { loadAutoPickValues } from './AutoPicKFormFieldValues';
import { NewCoordinateType } from './utils';

/**
 * @function EditNewEventForm
 * @description Edit the dynamic form
 * @param Object
 * @author Sam Manual Varghese
 */
function EditNewEventForm() {
  const {
    leftVisibleTabData,
    leftVisibleData,
    selected,
    added,
    coordinates,
    addSelected,
    setLeftVisibleTabData,
    addForm,
    setCoordinates,
    setAdded,
    setLeftVisibleData,
    removed,
    addRemove,
    setFormType,
    deleteCurrentItem,
    selectAllRight,
    editFormField,
    generateLayout,
    fullData,
    tableData,
    headerData,
    setHeaderData,
    setAutoPickValues,
    setRemoved,
  } = useContext(DynamicFormContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [formName, setFormName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { eventId, formId }: any = useParams();
  const [eventDetails, setEventDetails] = useState([]);
  const [formHeaderDetails, setFormHeaderDetails] = useState({});
  const modal = useModalDialog();
  const previewFormSetOpen = useMemo(() => modal(EventFormPreview, 'preview-form'), []);
  const setOpenParticipantFormPreview = useMemo(
    () => modal(Preview, 'preview-particiapnt-form'),
    [],
  );
  const imagesUploadingSetOpen = useMemo(
    () => modal(ImageLoader, 'Images_are_uploading'),
    [],
  );
  type locationType = {
    rallyID: string;
  };
  const location = useLocation<locationType>();
  const rallyId = location.state?.rallyID;
  const showFormPreview = () => {
    previewFormSetOpen(true, {
      eventId,
      formId,
      overrideBody: true,
    });
  };

  const getEditForm = async () => {
    try {
      const response = await getSingleFormByEventId(eventId, formId);
      const result = response.data;
      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Something went wrong');
    }
  };
  useEffect(() => {
    if (fullData.length === 0) {
      return;
    }
    (async () => {
      const {
        formHeaderDetails: headerDetails,
        coordinates: cordinatesNew,
        formFields,
        formName: newFormName,
        headerData: headerDatas,
      } = await getEditForm();
      const newArray = fullData
        .filter((cv) => !formFields.find((e) => e._id === cv.id && !cv.isMultiple))
        .map((val: any) => {
          // eslint-disable-next-line no-param-reassign
          val.index = uuidv4();
          return val;
        });

      const formFieldsWithCoordinates = formFields
        .filter((cv: any, index) => {
          const found = cordinatesNew.find((e): any => {
            if (cv.index === e.i) {
              // eslint-disable-next-line no-param-reassign
              cv.coordinates = e;
              return cv;
            }
          });
          if (!found) {
            // eslint-disable-next-line no-param-reassign
            cv.coordinates = {
              x: 0,
              y: index * 4,
              w: 10,
              h: 2,
              minW: 2,
              minH: 2,
              maxH: 5,
              i: cv.index,
            };
            return cv;
          }
          return found;
        })
        .sort((a, b) => a.order - b.order);

      // Deprecated, this is to support old form header,
      setFormHeaderDetails(headerDetails);
      setFormName(newFormName);
      setCoordinates(cordinatesNew);
      setHeaderData(headerDatas);
      await setLeftVisibleData(newArray);
      await setLeftVisibleTabData(newArray.filter((val) => val.category === 'Pilot'));
      setAdded(formFieldsWithCoordinates);
      setLoading(false);

      const responseData = await getEvent(eventId);
      const eventData = responseData.data.event;
      setEventDetails(eventData);
      if (rallyId) {
        const rallyResponse = await getRally(rallyId);
        const rallyData = rallyResponse.data.Rally;
        const rallyTypesResponse = await GetRallyTypes({ sortBy: 'type', sort: 'asc' });
        const rallyTypesData = rallyTypesResponse.data.rallyType.rallyTypes;
        setAutoPickValues(
          loadAutoPickValues(newFormName, eventData, rallyData, rallyTypesData),
        );
      } else {
        setAutoPickValues(loadAutoPickValues(newFormName, eventData));
      }
    })();
  }, [fullData]);

  const formPreviewParticipant = () => {
    if (formName === '') {
      setIsLoading(false);
      return;
    }
    if (added.length === 0) {
      setIsLoading(false);
      showEmptyFormToast(t("forms.You can't save an empty form"));
      return;
    }
    const form: FormFieldsType[] = added
      .filter((item: { type: string }) => item?.type !== 'pin')
      .map((item: FormFieldsType, i: number) => ({
        ...item,
        _id: item.id || item.index,
        coordinates: coordinates.find(
          (coord: NewCoordinateType) => coord.i === item.index,
        ),
        order: i + 1,
        isImage: item.isImage || false,
        imgUrl: item.imageBinary ? URL.createObjectURL(item.imageBinary) : item.imgUrl,
        imageName: item.imageName,
        imageType: item.imageType,
        defaultDropDownValue: item.defaultDropDownValue,
        values: item.values,
        totalCount: item.totalCount,
        tableRows: item?.tableRows || tableData.rows,
        tableCols: item?.tableCols || tableData.cols,
      }));

    const formData = {
      formName,
      form,
      eventId,
      headerData,
    };
    setOpenParticipantFormPreview(true, {
      item: formData,
      user: '',
      listForms: '',
      eventDetails: '',
      overrideBody: true,
      mode: 'preview',
    });
  };

  const updatePreview = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (formName === '') {
      setIsLoading(false);
      return;
    }
    if (added.length === 0) {
      setIsLoading(false);
      showEmptyFormToast(t("forms.You can't save an empty form"));
      return;
    }

    let imageBinarys = added.map((item) => {
      if (item.imageBinary) {
        return item.imageBinary;
      }
    });
    imageBinarys = _.without(imageBinarys, undefined);

    const keys = imageBinarys.map(async (item) => {
      const formData = new FormData();
      formData.append('file', item);
      imagesUploadingSetOpen(true, { overrideBody: true });
      return (await imageUpload(formData)).data.key;
    });

    Promise.all(keys).then((imageUrls) => {
      imagesUploadingSetOpen(false, { overrideBody: true });
      setIsLoading(false);
      const formFields: FormFieldsType[] = added
        .filter((item) => item?.type !== 'pin')
        .map((itemInArray, i) => {
          const coordinate = itemInArray.coordinate && {
            i: itemInArray.coordinate.i,
            x: itemInArray.coordinate.x,
            y: itemInArray.coordinate.y,
            w: itemInArray.coordinate.w,
            h: itemInArray.coordinate.h,
          };
          const { parentWidgetIndex } = itemInArray;
          return {
            id: itemInArray._id || itemInArray.id,
            label: itemInArray.label,
            hint: itemInArray.hint,
            key: itemInArray.key,
            order: i + 1,
            borderLeft: itemInArray.borderLeft,
            borderTop: itemInArray.borderTop,
            borderRight: itemInArray.borderRight,
            borderBottom: itemInArray.borderBottom,
            index: itemInArray.index,
            imgUrl: itemInArray.imageBinary ? imageUrls.shift() : itemInArray.imgUrl,
            isImage: itemInArray.isImage || false,
            imageName: itemInArray.imageName,
            imageType: itemInArray.imageType,
            defaultDropDownValue: itemInArray.defaultDropDownValue,
            values: itemInArray.values,
            totalCount: itemInArray.totalCount,
            tableRows: itemInArray?.tableRows || tableData.rows,
            tableCols: itemInArray?.tableCols || tableData.cols,
            parentWidgetIndex,
            coordinate, // Include the coordinate object if not empty
            styles: {
              ...itemInArray.styles,
            },
          };
        });

      const filteredCoordinates: NewCoordinateType[] = coordinates.filter((coords) =>
        formFields.some((field) => coords.i === field.index),
      );

      const data = {
        formName,
        formFields,
        eventId,
        coordinates: filteredCoordinates,
        headerData,
      };
      updateSingleFormByEventId(eventId, formId, data)
        .then(() => {
          toast.success(t('forms.Form updated successfully'), {
            position: 'top-center',
            autoClose: 2000,
          });
          setIsLoading(false);
          showFormPreview();
          history.goBack();
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.error || error.response.data.message, {
            position: 'top-center',
            autoClose: 5000,
          });
        });
    });
  };

  return (
    <FormCreateEdit
      setFormType={setFormType}
      setLeftVisibleTabData={setLeftVisibleTabData}
      leftVisibleData={leftVisibleData}
      loading={loading}
      selectAllRight={selectAllRight}
      selected={selected}
      leftVisibleTabData={leftVisibleTabData}
      removed={removed}
      addForm={addForm}
      addSelected={addSelected}
      setCoordinates={setCoordinates}
      added={added}
      formHeaderDetails={formHeaderDetails}
      formName={formName}
      setFormName={setFormName}
      eventDetails={eventDetails}
      generateLayout={generateLayout}
      editFormField={editFormField}
      deleteCurrentItem={deleteCurrentItem}
      addRemove={addRemove}
      isLoading={isLoading}
      preview={updatePreview}
      pathname={`/events/details/${eventId}/forms`}
      formPreviewParticipant={formPreviewParticipant}
      coordinates={coordinates}
      headerData={headerData}
      setHeaderData={setHeaderData}
      setRemoved={setRemoved}
    />
  );
}

export default EditNewEventForm;
