import { useTranslation } from 'react-i18next';
import Header from 'src/Components/Header/Header';
import FileTypeListing from 'src/Containers/FileTypes/FileTypeListing';
import Dashboard from '../../Layouts/Dashboard/Dashboard';

function FileTypes() {
  const { t } = useTranslation();
  return (
    <Dashboard>
      <Header title={t('File Type.File Type Management')} />
      <FileTypeListing />
    </Dashboard>
  );
}

export default FileTypes;
