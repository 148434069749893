import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import WarningToast from 'src/Components/Utils/WarningToast';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';

type isStatusMatchType = {
  status: EnumEventStatus;
  startDate: Moment;
  endDate: Moment;
  startRegDate: Moment;
  lastRegDate: Moment;
};

function useStatusMatch() {
  const { t } = useTranslation();
  const today = moment();

  const isStatusMatch = ({
    status,
    startDate,
    endDate,
    startRegDate,
    lastRegDate,
  }: isStatusMatchType) => {
    if (
      status === EnumEventStatus.Preparation &&
      moment(startDate) <= today.startOf('day')
    ) {
      WarningToast(
        `${t('toast.Please choose valid event status')} \n${t(
          "toast.The chosen event's start date has been reached",
        )}`,
      );
      return false;
    }
    if (status === EnumEventStatus.RegistrationsOpen) {
      if (moment(startRegDate) > today.endOf('day')) {
        WarningToast(
          `${t('toast.Please choose valid event status')} \n${t(
            "toast.The chosen event's registration start date has not yet been reached",
          )}`,
        );
        return false;
      }
      if (moment(lastRegDate) < today.startOf('day')) {
        WarningToast(
          `${t('toast.Please choose valid event status')} \n${t(
            "toast.The chosen event's registration end date has been reached",
          )}`,
        );
        return false;
      }
    }
    if (
      status === EnumEventStatus.RegistrationsCompleted &&
      moment(lastRegDate) >= today.startOf('day')
    ) {
      WarningToast(
        `${t('toast.Please choose valid event status')} \n${t(
          "toast.The chosen event's registration deadline has not yet been reached",
        )}`,
      );
      return false;
    }
    if (status === EnumEventStatus.InProgress) {
      if (moment(startDate) > today.startOf('day')) {
        WarningToast(
          `${t('toast.Please choose valid event status')} \n${t(
            "toast.The chosen event's start date has not yet been reached",
          )}`,
        );
        return false;
      }
      if (moment(endDate) < today.startOf('day')) {
        WarningToast(
          `${t('toast.Please choose valid event status')} \n${t(
            "toast.The chosen event's end date has been reached",
          )}`,
        );
        return false;
      }
    }
    if (
      status === EnumEventStatus.EventFinished &&
      moment(endDate) >= today.startOf('day')
    ) {
      WarningToast(
        `${t('toast.Please choose valid event status')} \n${t(
          "toast.The chosen event's end date has not yet been reached",
        )}`,
      );
      return false;
    }
    if (status === EnumEventStatus.Cancelled) {
      WarningToast(t('toast.The event will be cancelled upon submission'));
      return true;
    }
    return true;
  };

  return { isStatusMatch };
}
export default useStatusMatch;
