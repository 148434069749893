import React from 'react';
// eslint-disable-next-line import/no-cycle
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import pinImage from './pin.png';
import useStyles from './FieldStyle';

// eslint-disable-next-line unused-imports/no-unused-vars
function GetPinField({ fieldData }: FormFieldType) {
  const classes = useStyles();
  return <div><img src={pinImage} alt="Pin" className={classes.pinIcon} /></div>
};

export default GetPinField;