/* eslint-disable import/no-cycle */
import moment from 'moment';
import { toast } from 'react-toastify';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import {
  FieldWrapperExtraStylesType,
  StyleSelectDropdownType,
} from './TextEditor/types/textEditorTypes';

export enum ColorType {
  textColor = 'Text Color',
  bgColor = 'Background Color',
  borderColor = 'Border Color',
}

export enum BorderType {
  top = 'Top',
  right = 'Right',
  bottom = 'Bottom',
  left = 'Left',
}

export const fieldWrapperExtraStyles = (
  styles?: FieldWrapperExtraStylesType,
  type?: string,
): FieldWrapperExtraStylesType => {
  const styleObj = {
    backgroundColor:
      type === 'table' || !styles?.backgroundColor
        ? 'transparent'
        : styles?.backgroundColor,
    borderTopStyle: styles?.borderTopStyle,
    borderLeftStyle: styles?.borderLeftStyle,
    borderRightStyle: styles?.borderRightStyle,
    borderBottomStyle: styles?.borderBottomStyle,
    borderColor: styles?.borderColor,
    borderRadius: styles?.borderRadius,
  };

  return styleObj;
};

export const showEmptyFormToast = (message) =>
  toast.warning(message, {
    position: 'top-center',
    autoClose: 5000,
  });

export const getKeyByValue = (array, value) => {
  const obj = array.find((o: any) => o.value === value);
  return obj.label;
};

export const placeholder = ({ defaultValue, data, array }) => {
  if (data) {
    return getKeyByValue(array, data);
  }
  return defaultValue;
};

export const headingTypes: StyleSelectDropdownType[] = [
  { label: 'Normal', value: '14px', name: 'fontSize' },
  { label: 'Heading 1', value: '24px', name: 'fontSize' },
  { label: 'Heading 2', value: '22px', name: 'fontSize' },
  { label: 'Heading 3', value: '20px', name: 'fontSize' },
  { label: 'Heading 4', value: '18px', name: 'fontSize' },
  { label: 'Heading 5', value: '16px', name: 'fontSize' },
  { label: 'Heading 6', value: '14px', name: 'fontSize' },
];

export const boldTypes: StyleSelectDropdownType[] = [
  { label: '100', value: '100', name: 'fontWeight' },
  { label: '200', value: '200', name: 'fontWeight' },
  { label: '300', value: '300', name: 'fontWeight' },
  { label: '400', value: '400', name: 'fontWeight' },
  { label: '500', value: '500', name: 'fontWeight' },
  { label: '600', value: '600', name: 'fontWeight' },
  { label: '700', value: '700', name: 'fontWeight' },
  { label: '800', value: '800', name: 'fontWeight' },
  { label: '900', value: '900', name: 'fontWeight' },
];

export const textAlignTypes: StyleSelectDropdownType[] = [
  { label: 'left', value: 'left', name: 'textAlign' },
  { label: 'center', value: 'center', name: 'textAlign' },
  { label: 'right', value: 'right', name: 'textAlign' },
  { label: 'justify', value: 'justify', name: 'textAlign' },
];

export const defaultColorPalette: string[] = [
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#FFFFFF',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#EB144C',
  '#F78DA7',
  '#000',
  '#e63946',
  '#7209b7',
  '#283618',
];

export const borderTypes = [
  BorderType.top,
  BorderType.right,
  BorderType.bottom,
  BorderType.left,
];

// Props (style) applied to the Menu element in the MultySelectorDropdown (text editor)
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const fieldsHavingTextAlign = ['paragraph', 'heading'];

export const getGenericDateValue = (data: any) => {
  if (!data.values) {
    return '';
  }
  if (data.values.includes('select')) {
    return 'DD-MM-YYYY';
  }
  if (data.values.includes('today')) {
    return moment().format('YYYY-MM-DD');
  }
  if (data.values.includes('tomorrow')) {
    return moment().add(1, 'days').format('YYYY-MM-DD');
  }
  if (data.values && data.values[0]) {
    return data.values[0];
  }

  // Add a default return statement here if none of the conditions are met
  return '';
};

export const getTotalCountField = (data: any, added: any[]) => {
  const numberField = added?.filter(
    (a) => a.totalCount && a.totalCount.includes(data.index || data.formFieldId),
  );
  const result = numberField?.reduce((total, n) => {
    let newTotal = total;
    if (n.values && n.values.length && !Number.isNaN(parseFloat(n.values[0]))) {
      newTotal += parseFloat(n.values[0]);
    }
    if (n.values && n.values.length && Number.isNaN(parseFloat(n.values[0]))) {
      newTotal += 0;
    }
    return newTotal;
  }, 0);
  const newData = { ...data, values: [result?.toString()] };
  return newData;
};

export const getTotalCountFieldForParticipant = (
  data: any,
  added: any[],
  filledData: any[],
) => {
  const numberField = added.filter((a) => a.totalCount?.includes(data.index));
  const result = numberField.reduce((total, n) => {
    let newTotal = total;
    const filled = filledData.find((f) => f.formFieldId === n.index);

    if (filled && filled.values && filled.values.length) {
      newTotal += parseFloat(filled.values);
    }

    if (n.values && n.values.length && !Number.isNaN(parseFloat(n.values[0]))) {
      newTotal += parseFloat(n.values[0]);
    }

    if (n.values && n.values.length && Number.isNaN(parseFloat(n.values[0]))) {
      newTotal += 0;
    }

    return newTotal;
  }, 0);

  const newData = { ...data, values: [result?.toString()] };
  return { result, newData };
};

// We need to fetch the border details from the editData when we open the edit popup window
export const assignBorderNamesInitial = ({
  editData,
}: {
  editData: FormFieldEditType | undefined;
}): BorderType[] => {
  const returnArray: BorderType[] = [];
  if (editData?.styles?.borderTopStyle === 'solid') {
    returnArray.push(BorderType.top);
  }
  if (editData?.styles?.borderRightStyle === 'solid') {
    returnArray.push(BorderType.right);
  }
  if (editData?.styles?.borderBottomStyle === 'solid') {
    returnArray.push(BorderType.bottom);
  }
  if (editData?.styles?.borderLeftStyle === 'solid') {
    returnArray.push(BorderType.left);
  }
  return returnArray;
};

export const GRID_LAYOUT_COLS = 24;
export const TABLE_GRID_LAYOUT_COLS = 16;
export const GRID_LAYOUT_WIDTH = 820;

export const GRID_LAYOUT_ROWHEIGHT = 20;
// Calculate the width of each grid unit
export const GRID_UNIT_WIDTH = GRID_LAYOUT_WIDTH / GRID_LAYOUT_COLS;

type Rect = { x: number; y: number };

export const calculateGridCoordinates = (x: number, y: number, rect: Rect) => {
  const gridY = Math.round((y - rect.y) / GRID_LAYOUT_ROWHEIGHT);
  const gridX = Math.round((x - rect.x) / GRID_UNIT_WIDTH);
  return { gridX, gridY };
};
