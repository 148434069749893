import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MultiTabs({
  tabs,
}: {
  tabs: {
    id: string;
    title: string;
    component: JSX.Element;
  }[];
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab: { id: string; title: string; component: JSX.Element }) => (
            <Tab label={tab.title} />
          ))}
        </Tabs>
      </Box>
      {tabs.map(
        (
          tab: {
            id: string;
            title: string;
            component: JSX.Element;
          },
          index: number,
        ) => (
          <TabPanel value={value} index={index}>
            {tab.component}
          </TabPanel>
        ),
      )}
    </Box>
  );
}
