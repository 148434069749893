import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    display: 'flex',
    justifyContent: 'flex-end',
    bottom: 0,
  },
  link: {
    display: 'flex',
    color: colors.primary.color,
    fontWeight: 700,
    fontSize: '12px',
    fontFamily: typography.fonts.secondary,
  },
}));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}
function BreadCrumbs() {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      {/* <Link color="inherit" href="/" onClick={handleClick} className={classes.link}>
        Dashboard
      </Link> */}
      {/* <Link
        color="inherit"
        href="/getting-started/installation/"
        onClick={handleClick}
        className={classes.link}
      >
        Core
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        Breadcrumb
      </Typography> */}
    </Breadcrumbs>
  );
}

export default BreadCrumbs;
