import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import CanAccess from 'src/Components/Utils/CanAccess';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import WarningToast from 'src/Components/Utils/WarningToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import {
  deleteEvent,
  cancelEvent,
  eventClone,
  eventBackup,
} from '../../../Services/Auth/Actions/EventActions';
import { commonResponseHandler } from '../../../Services/Utils';

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '0px',
  },
  iconClone: {
    color: '#2b9348',
    fontSize: '20px',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  iconCancel: {
    color: '#E40909',
    fontSize: '20px',
  },
  iconExport: {
    color: '#ADAEB0',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
}));

const ITEM_HEIGHT = 30;

// eslint-disable-next-line react/require-default-props
function EventDelete({ id, reload, item }: { id: string; reload: Function; item?: any }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const cloneEvent = () => {
    eventClone(id)
      .then(({ data }) => {
        toast.success(t('events.Event Cloned Successfully'), {
          position: 'top-center',
          autoClose: 5000,
        });
        reload();
      })
      .catch((err) => {
        toast.error(t('events.Event Clone failed.'), {
          position: 'top-center',
          autoClose: 5000,
        });
      });
  };

  const confirm: any = useConfirm();

  const showModalCancelPopup = () => {
    confirm({
      description: (
        <>
          <p className={classes.desc}>
            {t('popup.Are you sure you want to cancel the event?')}
          </p>
          <p className={classes.desc}>{t('popup.This is an irreversible action.')}</p>
        </>
      ),
    }).then(() => {
      commonResponseHandler(cancelEvent(id), () => reload());
    });
  };

  const showModalPopup = () => {
    confirm({
      description: (
        <>
          <p className={classes.desc}>
            {t('popup.Are you sure you want to delete the event?')}
          </p>
          <p className={classes.desc}>{t('popup.This is an irreversible action.')}</p>
        </>
      ),
    }).then(() => {
      commonResponseHandler(deleteEvent(id), () => {
        reload();
      });
    });
  };

  const showBackupPopup = () => {
    confirm({
      description: (
        <>
          <p className={classes.desc}>{t('popup.Backup event')}?</p>
          <p className={classes.desc}>
            {t('popup.It will take time, continue in background')}?
          </p>
        </>
      ),
    }).then(() => {
      eventBackup(id)
        .then(({ data }) => {
          WarningToast(t(data.message));
        })
        .catch((err) => {
          ErrorToast(t('restore failed'));
        });
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'max-content',
            padding: 0,
          },
        }}
      >
        {item && item.isCancelled ? (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper} onClick={showModalPopup}>
              <Icon icon="ant-design:delete-twotone" className={classes.icon} />
              <p className={classes.option}>{t('forms.Delete')} </p>
            </div>
          </MenuItem>
        ) : (
          <>
            <MenuItem key="" onClick={handleClose}>
              <div className={classes.wrapper} onClick={() => showModalCancelPopup()}>
                <Icon icon="websymbol:cancel" className={classes.iconCancel} />
                <p className={classes.option}>{t('others.Cancel')} </p>
              </div>
            </MenuItem>
            <CanAccess permission={EnumPermissions.DeleteEvent}>
              <MenuItem key="" onClick={handleClose}>
                <div className={classes.wrapper} onClick={() => showModalPopup()}>
                  <Icon icon="ant-design:delete-twotone" className={classes.icon} />
                  <p className={classes.option}>{t('forms.Delete')} </p>
                </div>
              </MenuItem>
            </CanAccess>
            <MenuItem key="" onClick={handleClose}>
              <div className={classes.wrapper} onClick={cloneEvent}>
                <Icon icon="ant-design:copy-outlined" className={classes.iconClone} />
                <p className={classes.option}>Clone </p>
              </div>
            </MenuItem>
            <MenuItem key="" onClick={handleClose}>
              <div className={classes.wrapper} onClick={() => showBackupPopup()}>
                <Icon icon="mdi:backup-outline" className={classes.iconClone} />
                <p className={classes.option}>{t('Backup')} </p>
              </div>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
}

export default EventDelete;
