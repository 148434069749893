/* eslint-disable import/no-cycle */
import React from 'react';
import { Icon } from '@iconify/react';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { NewCoordinateType } from 'src/Containers/Events/Rally/utils';
import FormFieldElement from 'src/Containers/Events/Rally/components/DynamicForm/RightForm/FormFieldElement';
import { DynamicFormContextType } from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { SetStateSetterType } from 'src/Types/UseFormTypes';
import {
  addButtonStyle,
  cellStyle,
  cloneButtonStyle,
  mainContainer,
  removeButtonStyle,
} from './DynamicTableStyle';
import { tableOnAddCell } from './utils';

type TableElementProps = {
  field: FormFieldEditType;
  shoWPopUp: (coordinate: NewCoordinateType) => void;
  tableMode: string;
  currentTable: () => void;
  deleteCurrentItem: DynamicFormContextType['deleteCurrentItem'];
  addRemove: DynamicFormContextType['addRemove'];
  added: FormFieldEditType[];
  fullData: FormFieldEditType[];
  setAdded: SetStateSetterType<FormFieldEditType[]>;
};

function TableElement({
  field,
  shoWPopUp,
  tableMode,
  currentTable,
  deleteCurrentItem,
  addRemove,
  added,
  fullData,
  setAdded,
}: TableElementProps) {
  const currentCoordinate = field.coordinate;
  const editMode = tableMode === 'right';
  const currentCoordinateIndex = field.index;
  const backgroundColor = currentTable()[0].styles?.backgroundColor || 'white';
  const borderColor = currentTable()[0].styles?.borderColor || 'black';
  const borderWidth = currentTable()[0].styles?.borderWidth || '1px';
  const deleteItem = async () => {
    try {
      addRemove?.(currentCoordinateIndex);
      await deleteCurrentItem(currentCoordinateIndex);
    } catch (error) {
      console.error('Error during deletion:', error);
    }
  };

  const closeButton = () => (
    <span className="remove" onClick={deleteItem} style={removeButtonStyle}>
      x
    </span>
  );
  const addButton = () => (
    <Icon
      icon="feather:plus"
      onClick={() => shoWPopUp(currentCoordinate)}
      style={addButtonStyle}
    />
  );
  const cloneButton = () => (
    <Icon
      icon="feather:copy"
      onClick={() =>
        tableOnAddCell(
          field.parentWidgetIndex || '',
          added,
          setAdded,
          fullData,
          field.coordinate.w,
          field.coordinate.h,
          field.coordinate.y,
          field.coordinate.x,
        )
      }
      style={cloneButtonStyle}
    />
  );
  const datagrid = { ...currentCoordinate, static: !editMode };
  return (
    <div
      key={currentCoordinateIndex}
      className="cell"
      style={cellStyle(borderColor, backgroundColor, borderWidth)}
      data-grid={datagrid}
    >
      {field && (
        <div className="cell-key">
          {editMode && (
            <div>
              {closeButton()}
              {cloneButton()}
            </div>
          )}
          <div className="main-container" style={mainContainer}>
            {field.type === 'empty' && editMode && addButton()}
            {field.type !== 'empty' && (
              <FormFieldElement
                data={field}
                mode={tableMode}
                key={field.index}
                previewMode={!editMode}
              />
            )}
          </div>
        </div>
      )}

      {!field && editMode && (
        <>
          {addButton()}
          {closeButton()}
          {cloneButton()}
        </>
      )}
    </div>
  );
}

export default TableElement;
