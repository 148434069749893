import { combineReducers } from 'redux';
// session
import sessionReducer from './sessionReducer';
import roleReducer from './roleReducer';
import languageReducer from './languageReducer';

// item per page
import sortReducer from './sortReducer';
import rowsPerPageReducer from './rowsPerPageReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  role: roleReducer,
  language: languageReducer,
  // organizerPag: organizerPagReducer,
  // eventPag: eventPagReducer,
  // eventParticipantPag: eventPagParticipantReducer,
  // participantPag: participantPagReducer,
  // formFieldPag: formFieldPagReducer,
  // rallyPag: rallyPagReducer,
  // partPag: participantReducer,
  table: sortReducer,
  rowsPerPage: rowsPerPageReducer,
  user: userReducer
});

export default rootReducer;
