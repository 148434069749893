import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import Button from 'src/Components/Button/Button';
import { useTranslation } from 'react-i18next';
import { SetStateType } from 'src/Types/SetStateType';
import usePageBar from './usePageBar';

type DragAndDropTypes = {
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  handleFileSelection: (files: FileList) => void;
  handleDragLeave: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragEnter: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  acceptedTypes: string;
  role: string;
  isDragging: boolean;
  selectedFile: File | null;
  setSelectedFile: SetStateType<File | null>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  reset: () => void;
  renderMobileMenu: (
    onClick: (file: File | null) => void,
    loading: boolean,
    sampleCsvHandleClick: () => void,
    selectedFile: File | null,
  ) => JSX.Element;
};

const useDragAndDrop = (): DragAndDropTypes => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const role = useSelector((store: RootState) => store.role.role);
  const acceptedTypes = '.xlsx,.xls,.csv';
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const { handleMobileMenuClose, mobileMoreAnchorEl, mobileMenuId, isMobileMenuOpen } =
    usePageBar();

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const reset = () => {
    setSelectedFile(null);
  };

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleFileSelection = (files: FileList) => {
    const file = files[0]; // For simplicity, let's take the first file only
    setSelectedFile(file);
  };

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleFileSelection(e.dataTransfer.files);
      }
    },
    [handleFileSelection, setIsDragging],
  );

  const renderMobileMenu = (
    onClick: (file: File | null | any) => void,
    loading: boolean,
    sampleCsvHandleClick: () => void,
    selectedFile: File | null,
  ): JSX.Element => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button onClick={() => onClick(selectedFile)} label="Upload" loading={loading} />
      </MenuItem>
      <MenuItem>
        <Button onClick={reset} label="Reset" />
      </MenuItem>
      <MenuItem>
        <Tooltip title={t('General settings.Download Sample CSV')}>
          <Button onClick={sampleCsvHandleClick} label={t('others.Sample CSV')} />
        </Tooltip>
      </MenuItem>
    </Menu>
  );

  return {
    handleDrop,
    handleFileSelection,
    handleDragLeave,
    handleDragEnter,
    handleDragOver,
    acceptedTypes,
    role,
    isDragging,
    selectedFile,
    setSelectedFile,
    fileInputRef,
    reset,
    renderMobileMenu,
  };
};

export default useDragAndDrop;
