import { Icon } from '@iconify/react';
import { Fade, Modal } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'src/Theme';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    padding: '30px 20px',
    height: '40rem',
    overflow: 'auto',
    width: '50%',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    position: 'relative',
  },
  closeIcons: {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#bf2331',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
});

function PopupModal({ showModel, onCancel, description }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModel}
        onClose={onCancel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModel}>
          <div className={classes.paper}>
            <Icon
              icon="carbon:close-outline"
              onClick={onCancel}
              className={classes.closeIcons}
            />
            {description}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default PopupModal;
