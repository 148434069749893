import React, { useContext } from 'react';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import ShowImage from './Fields/ImageField';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetImageField({ fieldData, mode }: FormFieldType) {
  const { findTextFields } = useContext(ParticipantFormPreviewContext);
  const { label } = fieldData;

  const showValue = () => {
    switch (mode) {
      case 'left':
        return <h2>{label}</h2>;

      case 'right':
        if (fieldData?.isImage) {
          return <ShowImage imageUrl={fieldData?.imgUrl} styles={fieldData?.styles} />;
        }
        return <h2>{label}</h2>;

      case 'preview':
        if (fieldData?.isImage) {
          return <ShowImage imageUrl={fieldData?.imgUrl} styles={fieldData?.styles} />;
        }
        return <h2>{label}</h2>;

      case 'readOnlyPreview':
        if (fieldData?.isImage) {
          return <ShowImage imageUrl={fieldData?.imgUrl} styles={fieldData?.styles} />;
        }
        return <h2>{label}</h2>;

      default:
        return <h2>{label}</h2>;
    }
  };
  return <div style={{ width: 'auto', height: 'auto' }}>{showValue()}</div>;
}

export default GetImageField;
