import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '50px',
    height: '50px',
  },
}));
function ImageUpload({ image, setImage, profileImage }) {
  const classes = useStyles();
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  // const onFileChange=(e)=>{
  //     setImage(e.target.files[0])
  // }
  // const onFileUpload=()=>{
  //     // Create an object of formData
  //     const formData = new FormData();
  //     // Update the formData object
  //     formData.append(
  //         "myFile",
  //         props.setImage
  //     );
  //     // Details of the uploaded file
  //     console.log("setImage",formData);
  //     //axios.post("api/uploadfile", formData);
  // }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImagePreviewUrl(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div>
      {image ? (
        <img src={imagePreviewUrl} className={classes.avatar} alt="img" />
      ) : (
        <img src={profileImage} className={classes.avatar} alt="img" />
      )}
      <input type="file" onChange={onImageChange} />
    </div>
  );
}

export default ImageUpload;
