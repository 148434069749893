import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AppDropDownprops } from '../../types/docType';
import useStyles from '../../style';

function AppDropDown({
  placeholder,
  options,
  value,
  onChange,
  formListing,
  translation = false,
}: AppDropDownprops) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.flexColumn}>
      <h5>{placeholder}</h5>
      <select
        name={placeholder}
        placeholder={placeholder}
        value={value}
        onChange={(e: SyntheticEvent) => {
          onChange(e);
        }}
        // onBlur={handleBlur}
        style={{ marginBottom: '0px', width: '150px', height: '35px' }}
      >
        <option label={placeholder} disabled />
        {options &&
          options?.map((item) => (
            <option
              key={item._id}
              value={formListing && formListing.length > 0 ? item._id : item.label}
              label={
                translation ? t(`AppDropDown.${item.displayName}`) : item.displayName
              }
              style={{ padding: '10px 15px' }}
            />
          ))}
      </select>
    </div>
  );
}

export default AppDropDown;
