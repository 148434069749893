import React, { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import useTable from 'src/Hooks/useTable';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import topActions from 'src/Types/Enums/EnumTopActions';
import CustomTable, { TopActionNormalButtonType } from 'src/Components/Table/Table';
import {
  CommonTranslatedResponseHandler,
  formateDateWithoutTime,
} from 'src/Services/Utils';
import {
  DeleteRallyType,
  GetRallyTypes,
} from '../../Services/Auth/Actions/RallyTypeActions';
import AddEditRallyType from './AddEditRallyType';
import useStyles from './RallyTypeListingStyle';

type RallyType = {
  _id: string;
  type: string;
  key: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};
type Data = { rallyTypes: RallyType; count: number };
type RallyList = { rallyType: Data };
type TopActionButtonType = TopActionNormalButtonType;

function addEditRallyType({ updateId, reload, setOpenPopup }) {
  return (
    <AddEditRallyType updateId={updateId} reload={reload} setOpenPopup={setOpenPopup} />
  );
}

function RallyTypeListing() {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm: any = useConfirm();
  const modal = useModalDialog();
  const { addToSortableField } = useTable({
    tableName: 'rallyType',
    sortableFields: ['type'],
  });

  const reloadTable = async ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{ data: RallyList; totalCount: number }> => {
    try {
      const response = await GetRallyTypes({
        sortBy: sort.sortBy,
        sort: sort.sort,
        page,
        rowsPerPage,
        search,
      });
      const { rallyTypes, count } = response.data.rallyType;
      return {
        data: rallyTypes,
        totalCount: count,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteRallyTypeById = (deleteId, reload) => {
    confirm({
      description: t('popup.Do you want to delete ?'),
    }).then(() => {
      const responseHandlerProps = {
        promise: DeleteRallyType(deleteId),
        successCb: () => {
          reload();
        },
        successToast: t('rallyType.deleted'),
      };
      CommonTranslatedResponseHandler(responseHandlerProps);
    });
  };
  const AddEditModalSetOpen = useMemo(() => modal(addEditRallyType, 'rally-type'), []);

  const showAddModalPopup = (reload) => {
    AddEditModalSetOpen(true, { reload });
  };

  const showEditModalPopup = (updateId, reload) => {
    AddEditModalSetOpen(true, { updateId, reload });
  };

  const columns = [
    {
      title: t('rallyTypes.Rally Type'),
      field: 'type',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <>
          {row?.type && addToSortableField('type')}
          <div className={classes.wrap}>
            <div>
              <div className={classes.tbodyName}>{row.type}</div>
            </div>
          </div>
        </>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('rallyTypes.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row, reload) => (
        <div>
          <div className={classes.wrapper}>
            <Icon
              onClick={() => showEditModalPopup(row._id, reload)}
              icon="feather:edit-3"
              className={classes.editIcon}
              style={{ marginRight: '20px' }}
            />
            <Icon
              onClick={() => deleteRallyTypeById(row._id, reload)}
              icon="ant-design:delete-twotone"
              className={classes.icon}
              style={{
                cursor: 'pointer',
                marginRight: 'auto',
              }}
            />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];

  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('rallyTypes.Add New'),
        onClick: async ({ reload }) => showAddModalPopup(reload),
      },
    },
  ];
  return (
    <div>
      <CustomTable
        tableName="rallyType"
        columns={columns}
        onDoubleClick={(item, reload) => showEditModalPopup(item._id, reload)}
        heading={t('rallyTypes.Rally Type Listing')}
        topActionButtons={topActionButton}
        reload={reloadTable}
      />
    </div>
  );
}

export default RallyTypeListing;
