import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import { commonResponseHandler } from 'src/Services/Utils';
import makeAnimated from 'react-select/animated';
import { useState } from 'react';
import { rallyInvite } from 'src/Services/Auth/Actions/EventActions';
import theme from 'src/Theme';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import EmailDraft from './EmailDraft';

const useStyles = makeStyles(() => ({
  formBtn: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: '7px 6px',
    width: '100%',
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '1.6',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  btn: {
    width: '100% !important',
    marginBottom: '15px',
  },
  outerbox: {
    position: 'relative',
  },
  FormButton: {
    width: '100% !important',
  },
}));

function AddExistingParticipant({
  selectedRallys,
  participants,
  eventId,
  rallyId,
  reload,
  onClose,
  rallyOptions,
  handleChanges,
  participantList,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const animatedComponents = makeAnimated();
  const [openEmailDraft, setOpenEmailDraft] = useState(false);
  const [rallyInviteData, setRallyInviteData] = useState({});
  const [username, setData] = useState('');
  const [participantName, setParticipantName] = useState('');
  const [userId, setUserId] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [customError, setCustomError] = useState(false);

  const selectData = (e, value) => {
    if (value && value._id) {
      setData(value.username);
      setParticipantName(`${value.firstName} ${value.lastName}`);
      setUserId(value._id);
      setCustomError(false);
      if (value.isActive === true) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
    if (!value) {
      setData('');
      setCustomError(true);
    }
  };

  const EmailConfirm = (emailDraft) => {
    commonResponseHandler(rallyInvite({ ...rallyInviteData, emailDraft }), () => {
      reload();
      onClose();
    });
  };

  const onSubmit = (setSubmitting) => {
    if (!username) {
      setCustomError(true);
      setSubmitting(false);
    } else {
      setSubmitting(true);
      const rallyIds = _.uniq(selectedRallys);
      let data: any = {};
      if (participants) {
        data = {
          eventId,
          username,
          rallyId: rallyIds.map((r: any) => r.id),
          userId,
        };
      } else {
        data = {
          eventId,
          username,
          rallyId: [rallyId],
          userId,
        };
      }
      if (data.rallyId.length <= 0) {
        setSubmitting(false);
        toast.error(t('rally.Select atleast one Race'));
        return;
      }
      if (isActive === false) {
        setSubmitting(false);
        toast.error(t('rally.Select Active participant'));
        return;
      }
      setRallyInviteData(data);
      setOpenEmailDraft(true);
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(setSubmitting);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Box sx={{ flexGrow: 1 }} className={classes.outerbox}>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  {participants && (
                    <Grid item lg={12} xs={12}>
                      <FormLabel name={t('forms.Select a rally')} require />
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        defaultValue={rallyOptions[0]}
                        isMulti
                        onChange={handleChanges}
                        options={rallyOptions}
                        name="rallyName"
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="rallyName"
                        touched={touched}
                      />
                    </Grid>
                  )}
                  <FormLabel name={t('forms.Add existing participant')} require />
                  <Autocomplete
                    id="asynchronous-demo"
                    options={participantList}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={(params: any) => <TextField {...params} />}
                    onChange={selectData}
                    getOptionLabel={(option: any) =>
                      `${option.companyname ? option.companyname : option.lastName} ${
                        option.companyname ? '' : option.firstName
                      }`
                    }
                    getOptionSelected={(option, value) => option._id === value._id}
                    style={{ width: '100% !important' }}
                  />
                  {customError && (
                    <h2
                      style={{
                        fontSize: '12px',
                        fontWeight: 'normal',
                        color: 'red',
                      }}
                    >
                      {t('validationErrors.Select a participant')}
                    </h2>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                  <FormButton
                    name={t('forms.Submit')}
                    isLoading={isSubmitting}
                    className={classes.FormButton}
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
      <EmailDraft
        username={participantName}
        EmailConfirm={EmailConfirm}
        openEmailDraft={openEmailDraft}
        setOpenEmailDraft={setOpenEmailDraft}
        rallyId={selectedRallys[0]?.id}
        eventId={eventId}
      />
    </>
  );
}

export default AddExistingParticipant;
