import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import colors from 'src/Theme/colors';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { groupDataByRallyId } from 'src/Containers/Events/EventParticipants/utils';
import {
  FormList,
  GroupedDataArray,
} from 'src/Containers/Events/EventParticipants/EventFormsListing';
import { SetStateSetterType } from 'src/Types/UseFormTypes';

const ITEM_HEIGHT = 60;

const useStyles = makeStyles(() => ({
  filterButton: {
    padding: '0px',
    marginLeft: '10px',
  },
  icon: {
    color: colors.primary.color,
    cursor: 'pointer',
    fontSize: '35px',
    marginRight: '10px ',
  },
  titleFilter: {
    fontSize: '14px',
    fontFamily: 'Mulish',
    padding: '0 15px 20px',
    fontWeight: 500,
    borderBottom: '1px solid #D4D5DE',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0',
    fontFamily: 'Mulish',
  },
}));

function FilterList({
  allList,
  setLoading,
  setList,
  setRallyFilterId,
}: {
  allList: Array<FormList>;
  setLoading: Function;
  setList: SetStateSetterType<GroupedDataArray[]>;
  setRallyFilterId: Function;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const optionItems: Array<GroupedDataArray> = groupDataByRallyId(allList);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (id) => {
    setLoading(true);
    setRallyFilterId(id);
    if (id === 'all') {
      setList(groupDataByRallyId(allList));
      setAnchorEl(null);
      setLoading(false);
      return;
    }
    setList(groupDataByRallyId(allList).filter(([rallyId, _]) => rallyId === id));
    setAnchorEl(null);
    setLoading(false);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleFilterClick}
        className={classes.filterButton}
      >
        <Icon icon="bx:bx-filter" className={classes.icon} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleFilterClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
            padding: 0,
            lineHeight: '10px',
          },
        }}
      >
        <p className={classes.titleFilter}>{t('General settings.filter forms')}</p>
        <MenuItem onClick={() => handleMenuClick('all')}>
          <p className={classes.option}>{t('forms.All forms')} </p>
        </MenuItem>

        {optionItems &&
          optionItems.map(([rallyId, data]) => (
            <MenuItem key={data[0]._id} onClick={() => handleMenuClick(rallyId)}>
              <p className={classes.option}>{data[0].rallyName}</p>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

export default FilterList;
