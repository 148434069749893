import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import useStyles from '../../style';
import { DraggableColumnProps } from '../../types/docType';
import DraggableContainer from './DraggableContainer';

function DraggableColumn({ column, columnId }: DraggableColumnProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.columnContainer} key={columnId}>
      <h2>{t(`Exporter.${column.name}`)}</h2>
      <div style={{ margin: 8 }}>
        <Droppable droppableId={columnId} key={columnId}>
          {(droppableProvided, dropppableSnapshot) => (
            <DraggableContainer
              droppableProvided={droppableProvided}
              dropppableSnapshot={dropppableSnapshot}
              column={column}
            />
          )}
        </Droppable>
      </div>
    </div>
  );
}

export default DraggableColumn;
