/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import {
  GetCheckboxField,
  GetDateField,
  GetDropdownField,
  GetFileField,
  GetGenericAmount,
  GetHeadingField,
  GetHorizontalLine,
  GetImageField,
  GetParagraphField,
  GetTextAreaField,
  GetTextField,
  GetTotalField,
  GetPinField,
} from '../components/FormField';
import GetTablefield from '../components/FormField/table/GetTableField';
import GetEmptyField from '../components/GetEmptyField';

export const dynamicFormConfig = {
  text: {
    LeftComponent: GetTextField,
    RightComponent: GetTextField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  file: {
    LeftComponent: GetFileField,
    RightComponent: GetFileField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  heading: {
    LeftComponent: GetHeadingField,
    RightComponent: GetHeadingField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  paragraph: {
    LeftComponent: GetParagraphField,
    RightComponent: GetParagraphField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  image: {
    LeftComponent: GetImageField,
    RightComponent: GetImageField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  line: {
    LeftComponent: GetHorizontalLine,
    RightComponent: GetHorizontalLine,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  checkbox: {
    LeftComponent: GetCheckboxField,
    RightComponent: GetCheckboxField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  textarea: {
    LeftComponent: GetTextAreaField,
    RightComponent: GetTextAreaField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  dropdown: {
    LeftComponent: GetDropdownField,
    RightComponent: GetDropdownField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  date: {
    LeftComponent: GetDateField,
    RightComponent: GetDateField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  totalField: {
    LeftComponent: GetTotalField,
    RightComponent: GetTotalField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  number: {
    LeftComponent: GetGenericAmount,
    RightComponent: GetGenericAmount,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  table: {
    LeftComponent: GetTablefield,
    RightComponent: GetTablefield,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  pin: {
    LeftComponent: GetPinField,
    RightComponent: GetPinField,
    editableFields: [
      { type: 'fontSize' },
      { type: 'color' },
      { type: 'fontWeight' },
      { type: 'textAlign' },
    ],
  },
  empty: {
    LeftComponent: GetEmptyField,
    RightComponent: GetEmptyField,
  },
};
