import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import topActions from 'src/Types/Enums/EnumTopActions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { formFieldMainGroups } from 'src/Services/Constants';
import makeStyles from './FieldGroupStyle';
import AddFieldGroup from './AddFieldGroup';
import {
  DeleteFormFieldGroup,
  FormFieldGroupListing,
} from '../../Services/Auth/Actions/FormFieldActions';
import CustomTable, {
  TopActionFilterButtonType,
  TopActionNormalButtonType,
} from '../Table/Table';
import TableFilter from '../Table/TableFilter';

type TopActionButtonType = TopActionNormalButtonType | TopActionFilterButtonType;

function AddEditFieldGroup({ reload, editValues, setOpenPopup }) {
  return <AddFieldGroup reload={reload} item={editValues} setOpenPopup={setOpenPopup} />;
}
function FieldGroup() {
  const classes = makeStyles();
  const { t } = useTranslation();
  const role = useSelector((state: RootState) => state.role.role);
  const confirm: any = useConfirm();
  const modal = useModalDialog();
  const [groupName, setGroupName] = useState('');

  const reloadList = ({ sort, page, rowsPerPage, search }) =>
    FormFieldGroupListing({
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      rowsPerPage,
      search,
      category: groupName,
    }).then((response) => {
      const { data } = response.data;
      const totalCount = response.data.count;
      return { data, totalCount };
    });

  const handleDelete = (id, reload) => {
    confirm({
      description: t('popup.Are you sure you want to delete the Form Field Group?'),
    }).then(() => {
      DeleteFormFieldGroup(id).then(() => {
        toast.success('Form Field Group Deleted Successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };

  const AddEditModalSetOpen = useMemo(
    () => modal(AddEditFieldGroup, 'add-edit-field-group'),
    [],
  );

  const handleAddButtonClick = (reload) => {
    AddEditModalSetOpen(true, { reload });
  };

  const handleEditButtonClick = (editValues, reload) => {
    AddEditModalSetOpen(true, { editValues, reload });
  };

  const topActionButton: TopActionButtonType[] = [
    {
      type: topActions.button,
      extraProps: {
        name: t('formFields.Add New'),
        onClick: async ({ reload }) => handleAddButtonClick(reload),
      },
    },
    {
      type: topActions.filter,
      extraProps: {
        id: groupName,
        setId: setGroupName,
        filterOptions: formFieldMainGroups,
        component: TableFilter,
      },
    },
  ];

  const columns = [
    {
      title: t('formFields.ParentName'),
      field: 'parentName',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.wrap}>
          <div>{row.parentName}</div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('formFields.GroupName'),
      field: 'groupName',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.wrap}>
          <div>{row.groupName}</div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('formFields.Actions'),
      field: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row, reload) => (
        <>
          <Icon
            onClick={() => handleEditButtonClick(row, reload)}
            icon="feather:edit-3"
            className={classes.editIcon}
            style={{ marginRight: '20px' }}
          />
          <Icon
            onClick={() => handleDelete(row._id, reload)}
            icon="ant-design:delete-twotone"
            className={classes.icon}
            style={{
              cursor: 'pointer',
              marginRight: 'auto',
            }}
          />
        </>
      ),
      isSortable: false,
    },
  ];

  return (
    <CustomTable
      forceReload={groupName}
      tableName="formField"
      columns={columns}
      reload={reloadList}
      heading={t('formFields.Form Field Listing')}
      onDoubleClick={(item, reload) => handleEditButtonClick(item, reload)}
      role={role}
      topActionButtons={topActionButton}
    />
  );
}
export default FieldGroup;
