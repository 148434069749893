import React, { useContext } from 'react';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';
import FieldLabel from './Fields/FieldLabel';
import HintField from './Fields/HintField';
import fieldStyles from './FieldStyle';
import FILEUPLOAD from './fileUpload.svg';

function GetFileField({
  isEditable = false,
  disabled = true,
  onChange,
  fieldData,
  mode,
}: FormFieldType) {
  const { isPreviewDisabled, onImageChange, showIcon } = useContext(
    ParticipantFormPreviewContext,
  );
  const classes = fieldStyles();
  const { label, hint, styles, values, index, type } = fieldData;

  return (
    <>
      <FieldLabel name={label} className={classes.inputlabel} styles={styles} />
      <div style={{ position: 'relative' }}>
        {isEditable ? (
          <div>
            <img
              src={FILEUPLOAD || values}
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                right: '0px',
                bottom: '0px',
              }}
              alt="file uploader"
            />
            <input
              type="file"
              name="image"
              className={classes.imgCls2}
              onChange={
                mode === 'preview'
                  ? onImageChange({ index, type, id: fieldData._id })
                  : onChange
              }
              disabled={
                mode === 'preview' || mode === 'readOnlyPreview'
                  ? isPreviewDisabled
                  : disabled
              }
            />
            <HintField hint={hint} />
            {showIcon(index, hint)}
          </div>
        ) : (
          <div>
            <img
              src={FILEUPLOAD}
              style={{
                marginRight: '20px',
                // marginLeft: "20px",
              }}
              alt="file uploader"
            />
            <HintField hint={hint} />
          </div>
        )}
      </div>
    </>
  );
}

export default GetFileField;
