import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import _ from 'lodash';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import ViewUploads from '../Rally/ViewUploads';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '40px 20px 20px',
    display: 'flex',
    flexWrap: 'wrap',
    '@media all and (max-width:767px)': {
      padding: 0,
      margin: '30px 0px 20px',
      flexWrap: 'wrap',
    },
  },
  heading: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#363740',
    textAlign: 'left',
  },
  eventValue: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#363740',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  subwrap: {
    width: '25%',
    height: '20px',
    textAlign: 'left',
    marginBottom: '50px',
    paddingLeft: '40px',
    '@media all and (max-width:767px)': {
      width: '50%',
      height: '20px',
    },
  },
}));

function ParticipantEventDetails({ details }) {
  const classes = useStyles();
  const { t } = useTranslation();

  /**
   * @function websiteNavigate
   * @description Used for webcite link naviage next page
   */
  const websiteNavigate = () => {
    const website = details?.website || '';
    let websiteCondtion;
    if (!website) {
      return '-------';
    }
    try {
      if (website.startsWith('https://') || website.startsWith('http://')) {
        websiteCondtion = (
          <a href={details?.website} target="_blank" rel="noopener noreferrer">
            {details?.website}
          </a>
        );
        return websiteCondtion;
      }
      if (website) {
        const https = 'https://';
        websiteCondtion = (
          <a href={https.concat(website)} target="_blank" rel="noopener noreferrer">
            {https.concat(website)}
          </a>
        );
        return websiteCondtion;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  type eventDetailItem = {
    title: String;
    value: String | ReactElement;
    isTooltip: boolean;
    tooltipValue: String;
  };
  const displayDetail = (eventDetailItems: Array<eventDetailItem>) => (
    <div className={classes.wrapper}>
      {eventDetailItems.map((item: eventDetailItem) => (
        <div className={classes.subwrap}>
          <div className={classes.heading}>{item.title}</div>
          <Tooltip
            title={item.tooltipValue}
            disableHoverListener={item.tooltipValue ? !item.isTooltip : false}
          >
            <div className={classes.eventValue}>{item.value}</div>
          </Tooltip>
        </div>
      ))}
    </div>
  );

  return displayDetail([
    {
      title: t('forms.Event Name'),
      value: details.eventName || '-----------',
      isTooltip: true,
      tooltipValue: details.eventName,
    },
    {
      title: t('forms.Event Type'),
      value: _.get(details, 'eventType.type'),
      isTooltip: true,
      tooltipValue: _.get(details, 'eventType.type'),
    },
    {
      title: t('forms.Start Date'),
      value: Moment(details.startDateTime).format('DD MMM YYYY'),
      isTooltip: false,
      tooltipValue: details.startDateTime,
    },
    {
      title: t('forms.End Date'),
      value: Moment(details.endDateTime).format('DD MMM YYYY'),
      isTooltip: false,
      tooltipValue: details.endDateTime,
    },
    {
      title: t('forms.Event Organiser'),
      value: _.get(details, 'organizer.userId.companyname'),
      isTooltip: true,
      tooltipValue: _.get(details, 'organizer.userId.companyname'),
    },
    {
      title: t('forms.Organiser Contact'),
      value: _.get(details, 'organizer.eventOrganizers[0].phone') || '-----------',
      isTooltip: true,
      tooltipValue: _.get(details, 'organizer.eventOrganizers[0].phone'),
    },
    {
      title: t('forms.Enquiry Number'),
      value: details.enquiryPhone ? (
        <a href={`tel:${details.enquiryPhone}`}>{details.enquiryPhone}</a>
      ) : (
        '-----------'
      ),
      isTooltip: true,
      tooltipValue: details.enquiryPhone,
    },
    {
      title: t('forms.Enquiry Email'),
      value: details.enquiryEmail ? (
        <a href={`mailto:${details.enquiryEmail}`}>{details.enquiryEmail}</a>
      ) : (
        '-----------'
      ),
      isTooltip: true,
      tooltipValue: details.enquiryEmail,
    },
    {
      title: t('forms.Venue'),
      value: details.venue || '-----------',
      isTooltip: true,
      tooltipValue: details.venue,
    },
    {
      title: t('forms.Start Date of Registration'),
      value: Moment(details.startDateOfReg).format('DD MMM YYYY'),
      isTooltip: false,
      tooltipValue: details.startDateOfReg,
    },
    {
      title: t('forms.Last Date of Registration'),
      value: Moment(details.lastDateOfReg).format('DD MMM YYYY'),
      isTooltip: false,
      tooltipValue: details.lastDateOfReg,
    },
    {
      title: t('forms.Website'),
      value: websiteNavigate(),
      isTooltip: true,
      tooltipValue: details?.website,
    },
    {
      title: `Instagram Url`,
      value: details.instagramUrl ? (
        <a href={details.instagramUrl} target="_blank" rel="noreferrer">
          {details.instagramUrl}
        </a>
      ) : (
        '-----------'
      ),
      isTooltip: true,
      tooltipValue: details.instagramUrl,
    },
    {
      title: t('events.Facebook Url'),
      value: details.facebookUrl ? (
        <a href={details.facebookUrl} target="_blank" rel="noreferrer">
          {details.facebookUrl}
        </a>
      ) : (
        '-----------'
      ),
      isTooltip: true,
      tooltipValue: details.facebookUrl,
    },
    {
      title: t('forms.Memo'),
      value: details.memo || '-----------',
      isTooltip: true,
      tooltipValue: details.memo,
    },
    {
      title: t('forms.Uploaded Files'),
      value: <ViewUploads attachment={details?.attachment} fileIcon />,
      isTooltip: false,
      tooltipValue: details.attachment,
    },
  ]);
}
export default ParticipantEventDetails;
