import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import displayFileSize from 'src/Components/Utils/DisplayFileSize';
import { TOTAL_FILE_SIZE_LIMIT } from 'src/Services/Constants';
import SimpleButton from 'src/Components/Utils/SimpleButton';
import { AttachmentDragDropType, AttachmentType } from 'src/Types/AttachmentType';
import { FilterOptionType } from 'src/Types/FilterOptionType';
import { SetStateType } from 'src/Types/SetStateType';
import useStyles from './AddFileButtonStyle';
import DragDrop from './DragDrop';
import AttachmentTable from './AttachmentTable';

type SetFilesType = SetStateType<Array<AttachmentType | AttachmentDragDropType>>;
type DragAndDropType = {
  files: Array<AttachmentType | AttachmentDragDropType>;
  setFiles: SetFilesType;
  fileTypes: Array<FilterOptionType>;
  setOpenPopup: Function;
};

function DragAndDrop({ files, setFiles, fileTypes, setOpenPopup }: DragAndDropType) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] =
    useState<Array<AttachmentType | AttachmentDragDropType>>(files);
  const [totalFileSize, setTotalFileSize] = useState<{
    isError: boolean;
    totalSize: number;
  }>({ isError: false, totalSize: 0 });

  const showValidation = () => {
    if (totalFileSize.isError) {
      return (
        <div className={classes.isErrorMsg}>
          {`${displayFileSize(totalFileSize.totalSize)} 
              ${t(`validationErrors.total file size, Pleas choose  less than`)} 
              ${displayFileSize(TOTAL_FILE_SIZE_LIMIT)}...!!`}
        </div>
      );
    }
    return null;
  };

  const uplodButtonClick = () => {
    setOpenPopup(false);
    setFiles(uploadFile);
    setUploadFile([]);
  };
  return (
    <div className={classes.paper}>
      <DragDrop
        uploadFile={uploadFile}
        setUploadFile={setUploadFile}
        setTotalFileSize={setTotalFileSize}
        fileTypes={fileTypes}
      />
      {uploadFile.length > 0 && (
        <div className={classes.uploadTable}>
          <AttachmentTable
            files={uploadFile}
            setFiles={setUploadFile}
            setTotalFileSize={setTotalFileSize}
            fileTypes={fileTypes}
          />
          {showValidation()}
          {!totalFileSize.isError && (
            <SimpleButton
              onClick={() => {
                uplodButtonClick();
              }}
              label={t('forms.Upload')}
              className={classes.uplodButton}
            />
          )}
        </div>
      )}
    </div>
  );
}

type AddFileButtonType = {
  files: Array<AttachmentType>;
  setFiles: SetFilesType;
  fileTypes: Array<FilterOptionType>;
};

function AddFileButton({ files, setFiles, fileTypes }: AddFileButtonType) {
  const classes = useStyles();
  const { t } = useTranslation();
  const modal = useModalDialog();
  const setDragDropModal = useMemo(() => modal(DragAndDrop, 'drag-and-drop'), []);

  const showDragDrop = () => {
    setDragDropModal(true, {
      files,
      setFiles,
      fileTypes,
      overrideBody: true,
    });
  };

  return (
    <div className={classes.root}>
      <SimpleButton
        label={t('forms.Add File')}
        onClick={showDragDrop}
        className={classes.btnWrap}
      />
      {files.length > 0 && (
        <AttachmentTable files={files} setFiles={setFiles} fileTypes={fileTypes} />
      )}
    </div>
  );
}
export default AddFileButton;
