import { makeStyles } from '@material-ui/core';
import theme from 'src/Theme';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  body: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    alignItems: 'center',
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '20px',
    width: '150px',
    height: '150px',
    transition: 'background-color 0.3s ease-in-out',
    borderRadius: '14px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#8080802e',
    },
  },
  avatarStyle: {
    width: '75px',
    height: '75px',
    objectFit: 'contain',
  },
  selection: {
    border: `1px solid ${theme.palette.primary.light}`,
  },
  userRoleText: {
    textAlign: 'center',
  },
}));
