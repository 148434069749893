import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/Store';
import useStyles from './SwitchProfileStyle';
import AVATAR_IMAGE from './Avatar_1.svg';
import AVATAR_IMAGE_ACTIVE from './Avatar.svg';
import { loggedUser, switchProfile } from '../../Services/Auth/Actions/UserActions';

function SwitchProfile({ handleToggle, setOpenPopup }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const participantMode = useSelector(
    (store: RootState) => store.role.user.participantMode,
  );
  const userRole = useSelector((store: RootState) => store.role.user.role);
  const userImage = useSelector((store: RootState) => store.role.user.profileImage);
  const Avatar = () => {
    if (userImage) {
      return userImage;
    }
    return AVATAR_IMAGE_ACTIVE;
  };

  const handleSwitchProfileChange = (mode) => {
    switchProfile({ participantMode: mode }).then(() => {
      loggedUser(userRole).then((response) => {
        const { user } = response.data;
        dispatch({
          type: 'SAVE_USER',
          payload: user,
        });
        dispatch({
          type: 'SAVE_USERPERMISSIONS',
          payload: user.permissions,
        });
        dispatch({
          type: 'SAVE_ROLE',
          payload: user.participantMode ? 'participant' : user.role,
        });
        history.push('/');
        setOpenPopup(false);
        handleToggle();
      });
    });
  };
  return (
    <div>
      <div className={classes.wrapper}>
        <h2 id="transition-modal-title" className={classes.title}>
          {t('signUp.Switch Profile')}
        </h2>
      </div>
      <div className={classes.body}>
        <div
          className={`${classes.avatarWrapper} , ${
            participantMode ? '' : classes.selection
          }`}
          onClick={() => {
            handleSwitchProfileChange(false);
          }}
        >
          <img
            src={participantMode ? AVATAR_IMAGE : Avatar()}
            alt="img"
            className={classes.avatarStyle}
          />
          <div className={classes.userRoleText}>{t(`signUp.${userRole}`)}</div>
        </div>
        <div
          className={`${classes.avatarWrapper} , ${
            participantMode ? classes.selection : ''
          }`}
          onClick={() => {
            handleSwitchProfileChange(true);
          }}
        >
          <img
            src={participantMode ? Avatar() : AVATAR_IMAGE}
            alt="img"
            className={classes.avatarStyle}
          />
          <div>{t('signUp.Participant')}</div>
        </div>
      </div>
    </div>
  );
}

export default SwitchProfile;
