import React from 'react';

function SubmitOnEnter({ children, onSubmit }) {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <div
      onKeyDown={(event) => {
        handleKeyPress(event);
      }}
    >
      {children}
    </div>
  );
}

export default SubmitOnEnter;
