/* eslint-disable import/no-cycle */
import React, { Dispatch, SetStateAction } from 'react';
import { startCase, toLower } from 'lodash';
import { AxiosResponse } from 'axios';
import { downloadDocumentPdfOrCsv } from 'src/Services/Auth/Actions/OrganizerActions';
import { downloadDocument } from 'src/Services/Utils';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { t } from 'i18next';
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { DocField, DropDown } from './types/docType';

export const typeOpt: Array<DocField> = [
  {
    label: 'csv',
    displayName: 'CSV',
    _id: 'csv',
  },
  {
    label: 'pdf',
    displayName: 'PDF',
    _id: 'pdf',
  },
];
export const sortOrderOpt: Array<DocField> = [
  {
    label: 'asc',
    displayName: 'ASCENDING',
    _id: 'asc',
  },
  {
    label: 'desc',
    displayName: 'DESCENDING',
    _id: 'desc',
  },
];
export const orientationOpt: Array<DocField> = [
  {
    label: 'landscape',
    displayName: 'LANDSCAPE',
    _id: 'landscape',
  },
  {
    label: 'portrait',
    displayName: 'PORTRAIT',
    _id: 'portrait',
  },
];

// eslint-disable-next-line no-shadow
export enum fileResource {
  rally = 'Rally',
  event = 'Event',
  organiser = 'Organizer',
  organiserContact = 'organizerContact',
  formFields = 'formFields',
  eventParticipant = 'eventParticipants',
  rallyParticipant = 'rallyParticipants',
}

// eslint-disable-next-line no-shadow
export enum columnType {
  source = 'Source',
  destination = 'Destination',
}

export const formatDisplayName: Function = (fields: DocField[]): DocField[] => {
  const duplicateArray = [...fields];
  const data: DocField[] = duplicateArray.map((field) => {
    const trimmedWord = field.label.replace(/([A-Z])/g, ' $1').trim();
    // eslint-disable-next-line no-param-reassign
    field.label =
      (trimmedWord && trimmedWord[0].toUpperCase() + trimmedWord.slice(1)) || '';

    return field;
  });

  return data;
};

function filterForSourceColumn(items: DocField[], destinationFields: DocField[]) {
  return items.filter((item: DocField) => {
    const { id, rallyId, formId } = item;
    if (rallyId) {
      return !destinationFields.some(
        (field: DocField) =>
          field.id === id && field.formId === formId && field.rallyId === rallyId,
      );
    }
    return !destinationFields.some((field: DocField) => field.id === id);
  });
}

export const findDefaultSourceColumn = (
  itemsFromBackend: DocField[], // export fields from backend
  sourceColumnFields: DocField[], // source fieds from redux or somewhere else
  destinationColumnFields: DocField[], // destination fieds from redux or somewhere else
): DocField[] => {
  const sourceColumn = filterForSourceColumn(itemsFromBackend, destinationColumnFields);
  if (sourceColumn.length > 0) {
    return sourceColumn;
  }
  if (sourceColumn.length === 0 && destinationColumnFields.length > 0) {
    return [];
  }

  return itemsFromBackend;
};

// This function focues only eventsParticipants. It returns an array of items in the destination column array, based on matchesFormId, matchesRallyId and default fields
export const updateDestinationCoumn = (formId, destinationColumn): DocField[] => {
  if (!formId) {
    return destinationColumn;
  }
  const filteredDestColumn = destinationColumn.filter((column) => {
    const matchesFormId = formId.some(
      (form) => form._id === column.formId && form.rallyId === column.rallyId,
    );

    return matchesFormId || !column.formId || !column.rallyId;
  });
  return filteredDestColumn;
};

// function used to format camelCase words
// ex: eventListing => Event listing
export const camelCaseToTitleCase = (text: string): string => {
  const formatedCamelCasing: string = text.replace(/([A-Z])/g, ' $1').trim();
  const formatedName: string = startCase(toLower(formatedCamelCasing));
  return `${formatedName} Exporter`;
};

// This can be used to create custom components.
export const createComponenets = (
  dropDownValues: DropDown,
  formListing: DocField[],
  setDropDownValues: Dispatch<SetStateAction<DropDown>>,
  refreshDefaultFields: (formId: DocField[]) => Promise<void>,
): JSX.Element[] => {
  const confirm: any = useConfirm();
  const handleSubmit = () =>
    confirm({
      description: t('popup.Unsaved changes will be discarded'),
    });
  const handleSelect = (event: React.ChangeEvent<any>) => {
    const selectedForm = event.target.value;
    if (selectedForm.length === 1) {
      handleSubmit().then(() => {
        refreshDefaultFields(selectedForm);
        setDropDownValues({ ...dropDownValues, formId: selectedForm });
      });
    }
    if (selectedForm && selectedForm.length !== 1) {
      refreshDefaultFields(selectedForm);
      setDropDownValues({ ...dropDownValues, formId: selectedForm });
    } else {
      // eslint-disable-next-line
      console.error('No option selected');
    }
  };

  // let all = selected.map(optionValue => formListing.find(option => option._id === optionValue)
  return [
    <>
      <h5 style={{ width: '100%' }}>{t('events.Select form')} </h5>
      <div
        style={{
          paddingLeft: '10px',
          height: '37px',
          display: 'flex',
          backgroundColor: '',
          alignItems: 'center',
          width: '150px',
          border: '1px solid black',
          borderRadius: '5px',
          overflow: 'hidden',
        }}
      >
        <FormControl style={{ height: 'fit-content', flexShrink: 1 }}>
          <Select
            multiple
            label="Select Form"
            value={formListing.filter((e) =>
              dropDownValues.formId.some(
                (el) => el._id === e._id && el.rallyId === e.rallyId,
              ),
            )}
            onChange={handleSelect}
            renderValue={(select: Array<any> | unknown) =>
              Array.isArray(select) ? select.map((option) => option.label).join(', ') : ''
            }
            MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }}
          >
            {formListing.map((option: DocField) => (
              <MenuItem key={option._id} value={option as any}>
                <Checkbox
                  checked={dropDownValues.formId.some(
                    (form: DocField) =>
                      form._id === option._id && form.rallyId === option.rallyId,
                  )}
                />
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontSize: '14px' }}
                    >{`${option.label} (${option.rallyName})`}</Typography>
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>,
  ];
};

export const onSubmitPdf: Function = async (
  destinationColumnData,
  sort,
  sortBy,
  resource,
  type,
  eventId,
  formId,
  organizerId,
  setIsLoading,
  rallyId,
  orientation,
) => {
  // e.preventDefault();
  // Pdf with more than PDFColumLimit feilds are un readbale. So, restrict them from download / or choose csv
  const response: AxiosResponse<any> = await downloadDocumentPdfOrCsv({
    sort,
    sortBy,
    selectedFields: destinationColumnData,
    resource,
    type,
    eventId,
    formId,
    organizerId,
    rallyId,
    orientation,
  });
  let outputFilename: string;
  if (type === 'pdf') {
    outputFilename = `${resource} list.pdf`;
  } else {
    outputFilename = `${resource} list.xlsx`;
  }
  downloadDocument(response, outputFilename);
  setIsLoading(false);
};
