/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import './style.css';
import _ from 'lodash';
import EventDetailForm from 'src/Containers/Events/EventDetails/EventDetailForm';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { EventRallytypesType } from 'src/Types/EventRallytypesType';
import { EventType } from 'src/Types/EventType';
import { eventOrganizerType } from 'src/Types/eventOrganizerType';
import { getAutocompleteFileTypes } from 'src/Services/Auth/Actions/RallyTypeActions';
import { FilterOptionType } from 'src/Types/FilterOptionType';
import Autocomplete from '../../Components/Utils/Autocomplete';
import { commonResponseHandler } from '../../Services/Utils';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import { createNewEvent, getEventTypes } from '../../Services/Auth/Actions/EventActions';
import useStyles from './AddNewEventStyle';
import validateEvent, {
  prepareFormData,
  preProcessData,
} from '../../Containers/Events/EventUtils';

function AddNewEvent() {
  const { t } = useTranslation();
  const { organizerId }: { organizerId: ObjectIdType } = useParams();
  const [eventTypes, setEventTypes] = useState<Array<EventRallytypesType>>([]);
  const [fileTypes, setFileTypes] = useState<Array<FilterOptionType>>([]);
  const [orgData, setOrgData] = useState<{ email: string; phone: string }>({
    email: '',
    phone: '',
  });
  const [files, setFiles] = useState([]);
  const [eventOrganizersData, setEventOrganizersData] = useState<
    Array<eventOrganizerType>
  >([]);
  const classes = useStyles();
  const history = useHistory();
  const role = useSelector((state: RootState) => state.role.role);
  const user = useSelector((state: RootState) => state.role.user);
  const [image, setImage] = useState('');
  const [initialValues, setInitialValues] = useState({
    eventType: '',
    eventName: '',
    startDateTime: new Date(),
    endDateTime: new Date(),
    enquiryPhone:
      role === EnumUserRoles.eventOrganizer ? user.phone : orgData.phone || '',
    enquiryEmail:
      role === EnumUserRoles.eventOrganizer ? user.email : orgData.email || '',
    startDateOfReg: new Date(),
    lastDateOfReg: new Date(),
    organizer:
      role === EnumUserRoles.eventOrganizer ? user.organizationId : organizerId || '',
    eventOrganizer: role === EnumUserRoles.eventOrganizer ? user._id : '',
    venue: '',
    memo: '',
    eventDescription: '',
    website: '',
    facebookUrl: '',
    instagramUrl: '',
    status: '',
  });

  const setData = (data) => {
    const result = _.get(data, 'data.organizers');
    if (role !== EnumUserRoles.eventOrganizer && result && result.length > 0) {
      const tempOrgData = result[0];
      setEventOrganizersData(tempOrgData.eventOrganizersData);
      setOrgData(tempOrgData.organizer);
      setInitialValues({
        ...initialValues,
        enquiryPhone: tempOrgData.organizer.phone || '',
        enquiryEmail: tempOrgData.organizer.email || '',
        organizer: tempOrgData._id,
      });
    }
  };

  const getEventTypesDetailsById = (userId) => {
    getEventTypes(userId).then((response) => {
      setEventTypes(response.data.eventTypes.eventTypes);
    });
  };

  const getFileTypesById = (userId) => {
    getAutocompleteFileTypes(userId).then((response) => {
      const fileTypesOption = response.data.data.map((option: any) => ({
        value: option._id,
        label: option.type,
        id: option._id,
      }));
      setFileTypes(fileTypesOption);
    });
  };

  const onAutocompleteChange = (event, data, setFieldValue) => {
    if (!data) {
      setFieldValue('organizer', '');
      setEventOrganizersData([]);
      setFieldValue('enquiryPhone', '');
      setFieldValue('enquiryEmail', '');
      return;
    }
    if (data && data.eventOrganizersData && data.eventOrganizersData.length > 0) {
      getFileTypesById(data.userId);
      getEventTypesDetailsById(data.userId);
      setFieldValue('organizer', data._id);
      setEventOrganizersData(data.eventOrganizersData);
    } else {
      getFileTypesById(data.userId);
      getEventTypesDetailsById(data.userId);
      setFieldValue('organizer', data._id);
      setEventOrganizersData([]);
    }
  };

  const onSubmit = (
    values: EventType,
    { setSubmitting }: { setSubmitting: (Boolean: Boolean) => void },
  ) => {
    setSubmitting(true);
    const data: EventType = {
      ...values,
    };

    preProcessData(data);
    const today = moment(Date()).startOf('day');
    moment(Date()).startOf('day');
    if (data.startDateTime < today || data.lastDateOfReg < today) {
      setSubmitting(false);
      ErrorToast(t('toast.Past date is not allowed'));
      return;
    }
    if (!validateEvent(data, t)) {
      setSubmitting(false);
      return;
    }

    const formData = prepareFormData(data, image, files);

    commonResponseHandler(
      createNewEvent(formData),
      () => {
        if (organizerId) {
          history.push(`/organizers/details/${organizerId}/events`);
        } else {
          history.push('/events');
        }
      },
      () => setSubmitting(false),
    );
  };

  return (
    <div>
      <Dashboard>
        <Header title={t('events.Events')} />
        <div className={classes.wrapper}>
          <EventDetailForm
            setFiles={setFiles}
            isLoading={false}
            files={files}
            setDateEdit=""
            eventTypes={eventTypes}
            fileTypes={fileTypes}
            setImage={setImage}
            details=""
            organizerId={organizerId}
            setData={setData}
            setEventTypes={setEventTypes}
            getFileTypesById={getFileTypesById}
            onAutocompleteChange={onAutocompleteChange}
            eventOrganiser=""
            eventOrganizersData={eventOrganizersData}
            onSubmit={onSubmit}
            initialValues={initialValues}
            newEvent
            Autocomplete={Autocomplete}
          />
        </div>
      </Dashboard>
    </div>
  );
}
export default AddNewEvent;
