import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  fieldLabel: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    // lineHeight: "13px",
    // marginBottom: "10px",
    textAlign: 'left',
    overflow: 'hidden',
    // whiteSpace: 'nowrap',
    wordBreak: 'break-word',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    width: '100%',
  },
  leftFieldLabel: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    // lineHeight: "13px",
    // marginBottom: "10px",
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    width: '17rem',
  },
  inputlabel: {
    marginLeft: '0px',
    cursor: 'pointer',
    width: '100%',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE', //     border: '1px solid #D4D5DE',
    padding: '6px 0px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  checkboxInput: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 0px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  hintContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  hintText: {
    textAlign: 'left',
    // marginLeft: "5px",
    fontSize: '12px',
    color: '#6c757d',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0px',
  },
  heading: {
    margin: '0',
    overflowWrap: 'anywhere',
    textAlign: 'left',
  },
  paragraph: {
    margin: 0,
    overflowWrap: 'anywhere',
    textAlign: 'left',
    fontSize: '16px',
  },
  formHeaderHorizontalLine: {
    border: '.5px solid #03071e',
    backgroundColor: '#03071e',
  },
  imgCls1: {
    // position: "absolute",
    opacity: '0',
    width: '173px',
    height: '40px',
  },
  imgCls2: {
    // position: "absolute",
    opacity: '0',
    width: '200px',
    // height: "38px",
  },
  avatar: {
    width: '70px',
    height: '50px',
    margin: 'auto',
  },
  avatarTwo: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    marginLeft: '70px',
    position: 'relative',
    top: '-64px',
    bottom: '0px',
  },
  check: {
    marginLeft: '40px',
  },
  textAreaInput: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 0px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    // height: "100%",
    cursor: 'pointer',
    resize: 'none',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  commonHeadingStyle: {
    fontFamily: 'Mulish',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
    fontSize: '24px',
  },
  pinIcon: {
    height: '35px',
  },
}));
