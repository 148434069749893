import { makeStyles } from "@material-ui/core";
import colors from "src/Theme/colors";
import typography from "src/Theme/typography";

export default makeStyles(() => ({
    h3: {
      ...typography.h3,
      color: colors.secondary.color,
      textAlign: 'left',
    },
    wrap: {
      display: 'flex',
      alignItems: 'center',
      color: colors.primary.color,
      fontSize: '14px',
      fontWeight: 500,
      // textDecoration: 'underline',
      borderRight: '1px solid #DFE0EB',
      height: '35px',
      fontFamily: typography.fonts.secondary,
      outline: 'none',
    },
    wrapalign: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 500,
      color: colors.primary.color,
    },
  }));