import axios from 'axios';
import store from '../../../Store';
import { baseUrl } from '../../Constants';
import { sortingOptions } from '../../Utils';

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const FormFieldListSort = ({
  sortBy = 'createdAt',
  sort = 'asc',
  page = 0,
  pageSize = 10,
  search = '',
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(`${baseUrl}/api/form/form-fields?&${options}`, getHeaders());
};

// export const formFieldFilter = ({ sortBy = "createdAt", sort, page = 0, pageSize = 10, search = "", filter = "" }) => {
//     return axios.get(
//         baseUrl +
//             `/api/form/form-fields?page=${
//                 page + 1
//             }&pageSize=${pageSize}&sortBy=${sortBy}&sort=${sort}&search=${search}&filter=${filter}`,
//         getHeaders()
//     );
// };

export const AddFormField = (data) =>
  axios.post(`${baseUrl}/api/form/create/form-field`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const DeleteFormField = (id) =>
  axios.delete(`${baseUrl}/api/form/delete/form-field/${id}`, getHeaders());

export const FindFormFieldById = (id) =>
  axios.get(`${baseUrl}/api/form/find/form-field/${id}`, getHeaders());

export const UpdateFormField = (data, id) =>
  axios.put(`${baseUrl}/api/form/update/form-field/${id}`, data, getHeaders());

export const getFormFeildCSV = ({
  sortBy = 'sortBy',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
  filter = 'all',
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage, filter });
  return axios.get(`${baseUrl}/api/form/csv-export?${options}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const AddFormFieldGroup = (data) =>
  axios.post(`${baseUrl}/api/formfield-group/create`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const DeleteFormFieldGroup = (id) =>
  axios.delete(`${baseUrl}/api/formfield-group/${id}`, getHeaders());

export const UpdateFormFieldGroup = ({ id, data }) => {
  console.log(id, 'UpdateFormFieldGroup ', data);
  return axios.put(`${baseUrl}/api/formfield-group/${id}`, data, getHeaders());
};

export const FormFieldGroupListing = ({
  sortBy = 'createdAt',
  sort = 'desc',
  page = 0,
  rowsPerPage = 10,
  search = '',
  category = '',
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(
    `${baseUrl}/api/formfield-group/list?${options}&category=${category}`,
    getHeaders(),
  );
};
