import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  wrapper: {
    backgroundColor: '#fff',
    padding: '20px 60px 40px',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:767px)': {
      padding: '20px 20px 20px',
      margin: '30px 0px 20px',
    },
    position: 'relative',
  },
}));
