import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  profile: {
    backgroundColor: '#f2f2f2',
    border: '1px solid #dfe0eb',
    borderRadius: '15px',
    margin: '50px 20px 0',
    '@media all and (max-width: 767px)': {
      margin: '50px 0 25px',
    },
  },
  wrapper: {
    display: 'flex',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    '@media all and (max-width: 767px)': {
      display: 'block',
    },
    borderRadius: '15px',
    position: 'relative',
    height: 'auto',
  },
  imageSec: {
    flex: 1,
    marginTop: '-30px',
  },
  countSec: {
    flex: 2,
    display: 'flex',
    alignItems: 'flex-end',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
    },
  },
  contactSec: {
    // flex: 1,
  },
  img: {
    // border: "1px solid #DFE0EB",
    borderRadius: '5px',
    maxWidth: '200px',
    margin: '0 auto 10px',
    objectFit: 'cover',
  },
  avatar: {
    // width: "70px",
    borderRadius: '10px',
    height: '70px',
    objectFit: 'cover',
  },
  h5: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    color: colors.secondary.color,
    margin: '0',
    textTransform: 'capitalize',
  },
  p: {
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
  },

  count: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    color: colors.primary.color,
    margin: '0',
  },
  text: {
    fontFamily: typography.fonts.secondary,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#00000075',
    margin: '0 0 20px',
    textTransform: 'capitalize',
  },
  email: {
    fontSize: '12px',
    fontFamily: 'Mulish',
    margin: '0 5px 0',
    wordBreak: 'break-all',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  cntSec: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px auto',
  },
  wrap: {
    marginRight: '30px',
  },
  btnSec: {
    position: 'absolute',
    right: '30px',
    bottom: '-25px',
    width: 'max-content',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      width: 0,
    },
  },
  contactSecWrap: {
    marginLeft: '250px',
    '@media all and (max-width: 767px)': {
      justifyContent: 'center',
      marginLeft: '0',
      paddingLeft: '25px',
      alignItems: 'center',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
}));
