import { Icon } from '@iconify/react';
import React from 'react';
import { TableCell } from '@material-ui/core';
import Share from 'src/Components/Utils/Share';
import { sendUserRallyInvitation } from 'src/Services/Auth/Actions/RallyActions';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import { RallyType } from 'src/Types/RallyType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import RallyDelete from '../RallyDelete';
import useStyles from '../RallyListingStyle';
import { AuthorizedUser } from '../../../../Services/Utils';

type RallyListingActionsType = {
  item: RallyType;
  reload: Function;
  eventDetailsById?: Function;
  rallyOrgId?: ObjectIdType;
  setEditingItemId: Function;
  eventStatus: EnumEventStatus;
};

function RallyListingActions({
  item,
  reload,
  eventDetailsById,
  rallyOrgId,
  setEditingItemId,
  eventStatus,
}: RallyListingActionsType) {
  const classes = useStyles();
  const showActionButtons = () => {
    if (item.isCancelled) {
      return (
        <RallyDelete
          reload={reload}
          id={item._id}
          eventDetailsById={eventDetailsById}
          item={item}
          editPopup={setEditingItemId}
        />
      );
    }
    return (
      <>
        <Icon
          style={{ cursor: 'pointer' }}
          icon="feather:edit-3"
          onClick={() => setEditingItemId(item._id)}
          className={classes.editIcon}
        />
        {!(eventStatus === EnumEventStatus.EventFinished) && (
          <Share item={item} load={reload} sendUserInvitation={sendUserRallyInvitation} />
        )}
        <RallyDelete
          reload={reload}
          id={item._id}
          eventDetailsById={eventDetailsById}
          item={item}
          editPopup={setEditingItemId}
        />
      </>
    );
  };

  return (
    <AuthorizedUser
      rallyOrgId={rallyOrgId}
      success={
        <TableCell
          className={classes.tbody}
          style={{ cursor: 'context-menu', padding: '0px' }}
        >
          <div>
            <div className={classes.wrapper1}>{showActionButtons()}</div>
          </div>
        </TableCell>
      }
      error={null}
    />
  );
}

export default RallyListingActions;
