import { useState } from 'react';
import { CircularLoader } from '.';

type InfiniteScrollType = {
  children: JSX.Element[];
  list: Array<any>;
  totalLength: number;
  fetchList: (page: any) => any;
  classes: string;
};

function InfiniteScroll({
  children,
  list,
  totalLength,
  fetchList,
  classes,
}: InfiniteScrollType) {
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const onScrollEndsHandle = async () => {
    if (totalLength !== list.length) {
      if (!loadingMore) {
        setLoadingMore(true);
        setPage(page + 1);
        await fetchList(page).then(() => setLoadingMore(false));
      }
    }
  };

  const onScrollEnd = (event) => {
    const { target } = event;
    const srcollEndonRight =
      target.scrollLeft + target.clientWidth > target.scrollWidth - 1;
    const scrollEndOnBottom =
      target.scrollHeight - target.scrollTop < target.clientHeight + 1;

    if (
      (scrollEndOnBottom && target.scrollLeft === 0) ||
      (srcollEndonRight && target.scrollTop === 0)
    ) {
      onScrollEndsHandle();
    }
  };

  return (
    <div className={classes} onScroll={(event) => onScrollEnd(event)}>
      {children}
      {loadingMore && <CircularLoader />}
    </div>
  );
}

export default InfiniteScroll;
