import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    width: '40%',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
    width: '30%',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  shareIcon: {
    color: '#43A047',
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
  },

  link: {
    color: '#007AFF',
    textDecoration: 'none',
    fontWeight: 600,
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  boxType: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  iconEye: {
    color: '#43A047',
    fontSize: '20px',
  },
  completed: {
    backgroundColor: '#0C8E64',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '100px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  pending: {
    backgroundColor: '#ADAEB0',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '100px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  form: {
    width: '100%',
    border: '1px solid #e0e0e0',
    minHeight: 100,
    margin: 10,
    borderRadius: 10,
  },
  actions: {
    textAlign: 'left',
  },

  download: { textAlign: 'left', cursor: 'pointer' },
  subTitle: {
    overflow: 'hidden',
    width: '28rem',
    textOverflow: 'ellipsis',
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 30px 10px',
    background: '#dfe0eb40',
    alignItems: 'center',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
}));
