import React from 'react';
import ViewUploads from '../ViewUploads';

function RallyFileUploadCount({ item }) {
  return item.isCancelled ? (
    item?.attachment?.length
  ) : (
    <ViewUploads attachment={item.attachment} />
  );
}

export default RallyFileUploadCount;
