type DoubleClickButtonType = {
  label: string;
  onDoubleClick: Function;
  disabled?: boolean;
};

function DoubleClickButton({
  label,
  onDoubleClick,
  disabled = false,
}: DoubleClickButtonType) {
  return (
    <div
      onDoubleClick={() => {
        if (!disabled) {
          onDoubleClick();
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {label}
    </div>
  );
}

export default DoubleClickButton;
