import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  tbody: {
    fontFamily: typography.fonts.secondary,
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    color: '#ADAEB0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    width: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
    height: '60px',
    padding: '5px 0px 0 5px',
    width: '25px',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  arrowIcon: {
    position: 'relative',
    top: '2px',
    left: '5px',
    cursor: 'pointer',
  },
  arrowIconActive: {
    position: 'relative',
    top: '2px',
    left: '5px',
    cursor: 'pointer',
    color: colors.primary.color,
  },
  link: {
    color: '#007AFF',
    textDecoration: 'none',
    fontWeight: 600,
  },
  img: {
    borderRadius: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imgWrap: {
    borderRadius: '100%',
    height: '50px',
    width: '50px',
    marginRight: '15px',
  },
  boxType: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  tbodyName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
    width: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  participantName: {
    width: '200px',
    wordBreak: 'break-all',
  },
  InviteButton: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    width: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
    position: 'relative',
  },
  paperinner: {
    padding: theme.spacing(2, 4, 2),
    backgroundColor: 'white',
    height: '400px',
    // overflow: 'auto',
  },
  checkinput: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  checkboxInput: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 0px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  btn1: {
    width: '40%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  btnWrap: {
    textAlign: 'center',
    // marginLeft:"90px"
  },

  selectinput: {
    marginTop: '35px',
    // marginLeft:"60px"
  },
  selectinput2: {
    marginTop: '35px',
  },

  wrapper2: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
  },
  chooseCvsInput: {
    opacity: 0,
    fontSize: '0px',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  ChooseCSVButton: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    width: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '10px',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
    position: 'relative',
  },
  sampleCsv: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  uploadbtn: {
    '@media only screen and (max-width: 768px)': {
      padding: '0px',
    },
    padding: '0 90px 0 90px',
    marginTop: '30px',
    display: 'flex',
    alignContent: 'space-evenly',
  },
}));
