import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import WarningToast from 'src/Components/Utils/WarningToast';
import { sendUserEventInvitation } from 'src/Services/Auth/Actions/EventActions';
import { sendUserRallyInvitation } from 'src/Services/Auth/Actions/RallyActions';
import { getToken } from 'src/Services/Auth/Actions/UserActions';
import { RootState } from 'src/Store';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';

const useEventEnrollNow = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userRole = useSelector((state: RootState) => state.role.role);

  const enrollNowValidation = (status: string) => {
    if (userRole === EnumUserRoles.superadmin && getToken()) {
      WarningToast(t('popup.You cannot participate in this rally / event'));
      return false;
    }

    switch (status) {
      case EnumEventStatus.Preparation:
        WarningToast(t('popup.eventRegistrationNotOpen'));
        break;
      case EnumEventStatus.EventFinished:
        WarningToast(t('popup.eventFinished'));
        break;
      case EnumEventStatus.Cancelled:
        WarningToast(t('popup.eventCancelled'));
        break;
      case EnumEventStatus.InProgress:
        WarningToast(t('popup.eventRegistrationNotOpen'));
        break;
      case EnumEventStatus.RegistrationsCompleted:
        WarningToast(t('popup.eventRegistrationClosed'));
        break;
      default:
        return true;
    }

    return false;
  };

  const sendInvitations = (user, eventId, rallyId) => {
    sendUserEventInvitation({ username: user.username, eventId });
    if (rallyId) {
      sendUserRallyInvitation({
        username: user.username,
        eventId,
        rallyId: [rallyId],
      });
    }
  };

  const enrollNowToEvent = (user, eventId, rallyId) => {
    let toastMessage = '';
    let toastType: Function;
    let redirectPath = '';
    if (user.role === EnumUserRoles.superadmin) {
      toastType = WarningToast;
      toastMessage = 'You cannot participate in this rally / event';
      redirectPath = '/dashboard';
    } else {
      sendInvitations(user, eventId, rallyId);
      if (
        user.role === EnumUserRoles.organizer ||
        user.role === EnumUserRoles.eventOrganizer
      ) {
        toastType = SuccessToast;
        redirectPath = user.participantMode
          ? `/events/details/${eventId}/details`
          : `/dashboard`;
        toastMessage = user.participantMode
          ? 'You have been enrolled successfully. Please start filling the event forms'
          : 'You have been enrolled successfully. Please see the event details from participant dashboard';
      } else {
        toastType = SuccessToast;
        redirectPath = `/events/details/${eventId}/details`;
        toastMessage =
          'You have been enrolled successfully. Please start filling the event forms';
      }
    }

    toastType(t(`popup.${toastMessage}`));
    return history.push(redirectPath);
  };

  return { enrollNowToEvent, enrollNowValidation };
};

export default useEventEnrollNow;
