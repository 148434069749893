import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import CustomTable from 'src/Components/Table/Table';
import { userListingSort } from 'src/Services/Auth/Actions/UserActions';
import { RootState } from 'src/Store';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import useStyles from './UsersListingStyle';
import AVATAR from './AvatarImage.svg';
import AllUsersListActions from './AllUsersListActions';
import AllUserDelete from './AllUserDelete';

// type TopActionButtonType = TopActionAddButtonType;

function AllUsersListing() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const role = useSelector((store: RootState) => store.role.role);

  const columns = [
    {
      title: t('forms.Full Name'),
      field: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <div className={classes.imgWrap}>
              <img
                src={row.profileImage || AVATAR}
                className={classes.img}
                alt="avatar"
              />
            </div>
            <div className={classes.participantName}>
              {row?.companyname || `${row?.lastName} ${row?.firstName}`}
            </div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Username'),
      field: 'username',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div
            style={{ width: '170px', textOverflow: 'ellipsis', overflow: 'hidden' }}
            className={classes.wrap}
          >
            <div>{row.username}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('users.Role'),
      field: 'role',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <p className={classes.grey}>{row.role}</p>,
      isSortable: false,
    },
    {
      title: t('forms.Last login'),
      field: 'date',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>
          {row.lastLoggedIn ? (
            <>
              {moment(row.lastLoggedIn).format('DD.MM.YYYY')}
              <br />
              {moment(row.lastLoggedIn).format('hh:mm:a')}
            </>
          ) : (
            <>{t('organizers.Never')}</>
          )}
        </p>
      ),
      isSortable: false,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{moment(row.createdAt).format('DD.MM.YYYY')}</p>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row, reload) => (
        <div className={classes.wrapper1}>
          <AllUsersListActions item={row} />
          {role === 'superadmin' && <AllUserDelete reload={reload} item={row} />}
        </div>
      ),
      isSortable: false,
    },
  ];

  // const topActionButton: TopActionButtonType[] = [
  //   {
  //     type: topActions.add,
  //     extraProps: {
  //       to: '/users/add-new',
  //       name: t('organizers.Add New'),
  //     },
  //   },
  // ];

  const reload = ({ sort, page, rowsPerPage, search }) =>
    userListingSort({
      role,
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
      isAllUsers: true,
    }).then((response) => {
      const data = response.data.users;
      const { totalCount } = response.data;
      return {
        data,
        totalCount,
      };
    });

  return (
    <div>
      <CustomTable
        tableName="All Users Listing"
        columns={columns}
        reload={reload}
        heading={t('users.All Users Listing')}
        // topActionButtons={topActionButton}
        onDoubleClick={(item) => {
          switch (item.role) {
            case EnumUserRoles.participant:
              history.push({
                pathname: `users/details/${item._id}/profile`,
                state: { showGoBack: true },
              });
              break;
            case EnumUserRoles.organizer:
              history.push({
                pathname: `organizers/details/${item.organizationId}/profile`,
                state: { showGoBack: true },
              });
              break;
            case EnumUserRoles.eventOrganizer:
              history.push({
                pathname: `eventorganizers/details/${item.organizationId}/${item._id}/profile`,
                state: { showGoBack: true },
              });
              break;
            default:
              console.error(`Unexpected user role: ${item.role}`);
              break;
          }
        }}
        role={role}
        rowPadding="0px 15px"
      />
    </div>
  );
}

export default AllUsersListing;
