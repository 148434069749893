import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { CircularLoader } from '../../../../../../Components/Utils';
import useStyles from '../../../newEventFormStyle';
import LeftFormGroups from './leftFormGroups';
import DrawFormFields from './DrawFormFields';

function LeftForm({
  expanded,
  loading,
  selectAllRight,
  selected,
  leftVisibleTabData,
  removed,
  addForm,
  addSelected,
  formTypeData,
  changeFormType,
  handleChange,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectAllTab = () => {
    const selectedFieldGroupLength = selected.filter((data) => !data.fieldGroup).length;
    const totalFieldGroupLength = leftVisibleTabData.filter(
      (data) => !data.fieldGroup,
    ).length;
    if (totalFieldGroupLength === 0 && selectedFieldGroupLength === 0) {
      return 'Nothing to select';
    }
    if (selectedFieldGroupLength !== totalFieldGroupLength) {
      return 'SELECT ALL';
    }
    return 'DESELECT ALL';
  };

  const selectAllInnerTab = (_id: ObjectIdType) => {
    const selectedFieldGroupLength = selected.filter(
      (data) => data.fieldGroup?._id === _id,
    ).length;
    const totalFieldGroupLength = leftVisibleTabData.filter(
      (data) => data.fieldGroup?._id === _id,
    ).length;
    if (totalFieldGroupLength === 0 && selectedFieldGroupLength === 0) {
      return 'Nothing to select';
    }
    if (selectedFieldGroupLength !== totalFieldGroupLength) {
      return 'SELECT ALL';
    }
    return 'DESELECT ALL';
  };

  if (loading) {
    return (
      <div style={{ marginTop: '150px' }}>
        <CircularLoader />
      </div>
    );
  }
  return formTypeData.map((item) => (
    <Accordion
      onClick={() => changeFormType(item.type)}
      expanded={expanded === item.type}
      onChange={handleChange(item.type)}
      key={item.id}
      className={classes.accordianStyle}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t(`addNewForm.${item.label}`)}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.outerAccordianRoot }}>
        <Typography>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              <div
                className={classes.selectAll}
                onClick={() => {
                  selectAllRight();
                }}
              >
                {selectAllTab()}
              </div>
              <LeftFormGroups
                leftVisibleTabData={leftVisibleTabData}
                changeFormType={changeFormType}
                selectAllRight={selectAllRight}
                selectAllInnerTab={selectAllInnerTab}
                addForm={addForm}
                addSelected={addSelected}
                selected={selected}
                removed={removed}
              />
              {leftVisibleTabData
                .filter((data) => !data.fieldGroup)
                .map((data: any) => (
                  <DrawFormFields
                    data={data}
                    addForm={addForm}
                    addSelected={addSelected}
                    removed={removed}
                    selected={selected}
                  />
                ))}
            </Form>
          </Formik>
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));
}

export default LeftForm;
