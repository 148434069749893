import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import showFileSize, { checkTotalFileSize } from 'src/Components/Utils/DisplayFileSize';
import clsx from 'clsx';
import TableFilter from 'src/Components/Table/TableFilter';
import TableContent from 'src/Components/Table/TableContent';
import { AttachmentDragDropType, AttachmentType } from 'src/Types/AttachmentType';
import { FilterOptionType } from 'src/Types/FilterOptionType';
import { Select } from 'src/Components/Utils';
import useConfirmationDialog from 'src/Hooks/useConfirmationDialog';
import IconButton from 'src/Components/Button/IconButton';
import EditableTextBox from 'src/Components/Button/EditableTextBox';
import { SetStateType } from 'src/Types/SetStateType';
import useStyles from './Attachmenttablestyle';
import { ImageConfig } from './ImageConfig/ImageConfig';
import {
  downloadDocumentFromUrl,
  fileNameWithoutExtension,
  formateDateWithoutTime,
  getFileTypeExtension,
  openTab,
  previewTypes,
} from '../../../Services/Utils';

type SetFilesType = SetStateType<Array<AttachmentType | AttachmentDragDropType>>;
type SetTotalFileSizeType = SetStateType<{
  isError: boolean;
  totalSize: number;
}>;

type AttachmentTableType = {
  files: Array<AttachmentType | AttachmentDragDropType>;
  setFiles: SetFilesType;
  setTotalFileSize?: SetTotalFileSizeType;
  fileTypes: Array<FilterOptionType>;
  disabled?: boolean;
};

function AttachmentTable({
  files,
  setFiles,
  setTotalFileSize,
  fileTypes,
  disabled = false,
}: AttachmentTableType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterId, setFilterId] = useState<string>('');
  const [list, setList] = useState<Array<any>>(files);
  const [loading, setLoading] = useState<boolean>(true);
  const confirmationDialog = useConfirmationDialog();

  const onSaveFileNameChange = (filename, file) => {
    const currentFile = files.find((f) => f === file);
    if (currentFile) {
      currentFile.name = filename;
    }
    setFiles([...files]);
  };

  const handleDropDownChange = (event, file) => {
    const currentDropDown = files.find((f) => f.uuid === file.uuid);
    if (currentDropDown) {
      currentDropDown.dropdown = event.target.value;
    }
    setFiles([...files]);
  };

  const handleView = (item) => {
    const url = item.BlobURL || item.files;
    const name = item.name || item.dropdown;
    if (item.type && previewTypes.includes(item.type)) {
      openTab(item.type, url, name);
    } else {
      downloadDocumentFromUrl(url, name);
    }
  };

  const deleteFile = (item) => {
    const description = t('Are you sure you want to delete the file?');
    const onSuccess = () => {
      const filterFiles: Array<AttachmentType | AttachmentDragDropType> = [
        ...files.filter((file) => file.uuid !== item.uuid),
      ];
      setFiles(filterFiles);
      if (setTotalFileSize) {
        setTotalFileSize(checkTotalFileSize(filterFiles));
      }
    };
    confirmationDialog(description, () => onSuccess());
  };

  useEffect(() => {
    if (filterId === '') {
      setList(files);
      setLoading(false);
      return;
    }
    setList(files.filter((file) => file.dropdown === filterId));
    setLoading(false);
  }, [filterId, files]);

  const showFileIcon = (item) => (
    <img
      style={{ height: '35px' }}
      src={ImageConfig[getFileTypeExtension(item?.type)] || ImageConfig.default}
      alt="fileTypeImage"
    />
  );

  const showFileName = (item) => (
    <EditableTextBox
      label={fileNameWithoutExtension(item.name)}
      onDoubleClick={() => {}}
      onSaveChange={(filename: string) => onSaveFileNameChange(filename, item)}
      disabled={disabled}
    />
  );

  const showDropdown = (item) => (
    <Select
      name="dropdown"
      value={item?.dropdown}
      onChange={(event) => handleDropDownChange(event, item)}
      placeholder="select"
      options={fileTypes.map((item) => ({
        key: item.value,
        value: item.value,
        label: item.label,
        disabled: false,
      }))}
      style={{ width: '160px' }}
    />
  );

  const showActionButtons = (item) => (
    <div className={classes.actionIconWrapper}>
      <IconButton icon="feather:eye" onClick={() => handleView(item)} />
      {!disabled && (
        <IconButton icon="ant-design:delete-twotone" onClick={() => deleteFile(item)} />
      )}
    </div>
  );

  const actionsAndFilterIcon = () => (
    <div className={classes.actionWrapper}>
      {t('formFields.Actions')}
      <TableFilter
        id={filterId}
        setId={setFilterId}
        filterOptions={fileTypes}
        colour="white"
      />
    </div>
  );

  return (
    <TableContainer
      className={clsx(classes.tablecontainer, disabled && classes.tablecontainerModal)}
    >
      <Table aria-label="simple table" className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow>
            <TableCell className={classes.tablehead} />
            <TableCell className={classes.tablehead}>{t('events.File Name')}</TableCell>
            <TableCell className={classes.tablehead}>{t('events.File Size')}</TableCell>
            <TableCell className={classes.tablehead}>{t('events.Date')}</TableCell>
            <TableCell className={classes.tablehead}>{t('events.File Type')}</TableCell>
            <TableCell className={classes.tablehead}>{actionsAndFilterIcon()}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableContent
            loading={loading}
            list={list}
            emptyText={t('others.No items found')}
          >
            {list.map((item: AttachmentType, index: number) => (
              <TableRow>
                <TableCell>{showFileIcon(item)}</TableCell>
                <TableCell>{showFileName(item)}</TableCell>
                <TableCell>{showFileSize(item?.size)}</TableCell>
                <TableCell>{formateDateWithoutTime(item.date)}</TableCell>
                <TableCell>{showDropdown(item)}</TableCell>
                <TableCell>{showActionButtons(item)}</TableCell>
              </TableRow>
            ))}
          </TableContent>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default AttachmentTable;
