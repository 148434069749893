import React, { useEffect, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import './style.css';
import { fileResource } from 'src/Components/DocDownload/util';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import TableFilter from 'src/Components/Table/TableFilter';
import CustomTable, {
  TopActionButtonPopupType,
  TopActionFilterButtonType,
  TopActionNormalButtonType,
} from 'src/Components/Table/Table';
import { RallyType } from 'src/Types/RallyType';
import topActions from 'src/Types/Enums/EnumTopActions';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import WarningToast from 'src/Components/Utils/WarningToast';
import { EventType } from 'src/Types/EventType';
import useFetchExportDetails from 'src/Components/DocDownload/hooks/useFetchExportDetails';
import { commonResponseHandler, formateDateWithoutTime } from '../../../Services/Utils';
import AVATAR from '../EventListing/AvatarImage.svg';
import AddEventParticipantsPopup from './AddEventParticipantsPopup';

import {
  FilterParticipantsByRally,
  rallyListing,
} from '../../../Services/Auth/Actions/RallyActions';
import {
  approveUser,
  updateRacingNumber,
  eventParticipantsListSort,
} from '../../../Services/Auth/Actions/EventActions';
import ParticipantDelete from './DeleteParticipants';
import useStyles from './EventParticipantsStyle';
import AddRallyWrapper from './AddRally';

type TopActionButtonType =
  | TopActionFilterButtonType
  | TopActionNormalButtonType
  | TopActionButtonPopupType;

function EventParticipants({ event }: { event: EventType }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [rallyOptions, setRallyOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [rallyId, setRallyId] = useState<string>('');
  const { id }: any = useParams();

  const modal = useModalDialog();
  const setOpenAddEventParticipants = useMemo(
    () => modal(AddEventParticipantsPopup, 'event-participant'),
    [],
  );

  const confirm: any = useConfirm();

  const filterParticipants = ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{ data: any; count: number }> =>
    FilterParticipantsByRally({
      rallyId,
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
      id,
    }).then((response) => {
      const data = response.data.participants;
      const { count } = response.data;
      return {
        data,
        count,
      };
    });

  const reloadTable = async ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{ data: any; totalCount: number }> => {
    if (rallyId) {
      const filterData = await filterParticipants({ sort, page, rowsPerPage, search });
      const { data, count: totalCount } = filterData;
      return {
        data,
        totalCount,
      };
    }
    return eventParticipantsListSort({
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      id,
      search,
    })
      .then((response) => {
        const data = response.data.participants;
        const { totalCount } = response.data;
        return {
          data,
          totalCount,
        };
      })
      .catch((error) => {
        console.error(error);
        throw error; // handle the error here or rethrow it for the caller to handle
      });
  };

  const showModalPopup = (itemId: String, reload) => {
    confirm({
      description: (
        <p id="transition-modal-description" className={classes.desc}>
          {t('popup.Are you sure you want to approve the participant?')}
        </p>
      ),
    }).then(() => {
      approveUser(id, itemId, { isApproved: true })
        .then((res) => {
          reload();
          toast.success(res.data.message, {
            position: 'top-center',
            autoClose: 5000,
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-center',
            autoClose: 5000,
          });
        });
    });
  };

  const showBlockModalPopup = (itemId: String, reload) => {
    confirm({
      description: (
        <p id="transition-modal-description" className={classes.desc}>
          {t('popup.Are you sure you want to cancel the participant?')}
        </p>
      ),
    }).then(() => {
      approveUser(id, itemId, { isApproved: false })
        .then(() => {
          reload();
          toast.success(t('users.Users Participation Declined'), {
            position: 'top-center',
            autoClose: 5000,
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-center',
            autoClose: 5000,
          });
        });
    });
  };

  const handleOpenPopup = (reload) => {
    if (event.status === EnumEventStatus.RegistrationsCompleted) {
      WarningToast(t('events.Registrations Completed'));
      return;
    }
    if (event.status === EnumEventStatus.EventFinished) {
      WarningToast(t('popup.eventFinished'));
      return;
    }
    setOpenAddEventParticipants(true, {
      details: id,
      reload,
      participants: true,
    });
  };

  const getRallyList = () => {
    rallyListing({ id, rowsPerPage: 20 * 1000 }).then(async (res) => {
      let Rallies = res.data.Rallies.filter((rally: RallyType) => rally.isActive);
      Rallies = Rallies.map(
        (rally: RallyType) =>
          rally.isActive && { value: rally._id, label: rally.rallyName },
      );
      setRallyOptions(Rallies);
    });
  };

  useEffect(() => {
    getRallyList();
  }, [event._id]);

  const changeRacingNumber = (itemId: String, reload) => async (e) => {
    commonResponseHandler(
      updateRacingNumber(id, itemId, { racingNumber: e.target.value, rallyId }),
      () => {
        reload();
      },
    );
  };

  const participantApprove = (item, reload) => {
    if (item.isApproved) {
      return <div className={classes.approved}>{t('events.Approved')}</div>;
    }
    if (item.isApproved === false) {
      return <div className={classes.cancelled}>{t('events.Declined')}</div>;
    }
    return (
      <>
        <Icon
          icon="subway:tick"
          className={classes.editIcon}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            showModalPopup(item.user._id, reload);
          }}
        />
        <Icon
          icon="websymbol:cancel"
          className={classes.iconCancel}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            showBlockModalPopup(item.user._id, reload);
          }}
        />
      </>
    );
  };
  const columns = [
    {
      title: t('forms.Pilot'),
      field: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.wrap}>
          <img
            src={row.user.profileImage || AVATAR}
            className={classes.img}
            alt="avatar"
          />
          <p className={classes.grey1}>
            {row.user?.companyname || `${row.user?.lastName} ${row.user?.firstName}`}
          </p>
        </div>
      ),
      isSortable: true,
    },
    ...(rallyId
      ? [
          {
            title: t('forms.Racing No'),
            field: 'racingNumber',
            // eslint-disable-next-line react/no-unstable-nested-components, consistent-return
            data: (row, reload) => (
              <p className={classes.grey}>
                <input
                  className={classes.racingNumberInput}
                  placeholder={row?.racingNumber}
                  type="text"
                  onChange={debounce(changeRacingNumber(row.user._id, reload), 1000)}
                />
              </p>
            ),
            isSortable: true,
          },
        ]
      : []),

    {
      title: t('forms.Contact'),
      field: 'contact',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <>
          <p className={classes.grey}>{row.user.email} </p>
          <p className={classes.grey}>{row.user.phone}</p>
        </>
      ),
      isSortable: false,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row, reload) => (
        <div>
          <div className={!rallyId ? classes.wrapper1 : classes.wrapper2}>
            {!rallyId && participantApprove(row, reload)}
            <ParticipantDelete
              userId={row.user._id}
              eventId={id}
              reload={reload}
              status={row.isApproved}
            />
          </div>
        </div>
      ),

      isSortable: false,
    },
    ...(rallyId
      ? []
      : [
          {
            title: t('forms.Rally'),
            field: 'createdAt',
            // eslint-disable-next-line react/no-unstable-nested-components
            data: (row) => (
              <div>
                <div className={classes.wrapper1}>
                  <AddRallyWrapper
                    item={row.user}
                    eventId={id}
                    status={row.isApproved}
                    eventStatus={event.status}
                  />
                </div>
              </div>
            ),
            isSortable: true,
          },
        ]),

    {
      title: t('events.Manage Participants'),
      field: 'participants',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div>
          <div className={classes.wrapper1}>
            <Link to={`/events/forms/participants/${event._id}/${row.user._id}`}>
              {t('events.View Details')}
            </Link>
          </div>
        </div>
      ),
      isSortable: true,
    },
  ];
  const data = {
    resource: fileResource.eventParticipant,
    eventId: id,
    rallyId,
    organizerId: '',
    initialSortApi: undefined, // Provide a default value
    id: '',
    reportName: '',
    reload: undefined, // Provide a default value
  };
  const { handleClick } = useFetchExportDetails(
    data.eventId,
    data.rallyId,
    data.resource,
    data.organizerId,
    data.initialSortApi,
    data.id,
    data.reportName,
    data.reload,
  );

  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('forms.Add Participants'),
        onClick: async ({ reload }) => handleOpenPopup(reload),
      },
    },
    {
      type: topActions.button,
      extraProps: {
        name: t('events.Export'),
        onClick: handleClick,
      },
    },
    {
      type: topActions.filter,
      extraProps: {
        id: rallyId,
        setId: setRallyId,
        filterOptions: rallyOptions,
        component: TableFilter,
      },
    },
  ];

  return (
    <div>
      <div>
        <CustomTable
          tableName="manageParticipants"
          columns={columns}
          reload={reloadTable}
          heading={t('events.Manage Participants')}
          onDoubleClick={(item) =>
            history.push({
              pathname: `/events/forms/participants/${event._id}/${item.user._id}`,
              state: { showGoBack: true },
            })
          }
          topActionButtons={topActionButton}
          searchSize="small"
          forceReload={rallyId}
        />
      </div>
    </div>
  );
}

export default EventParticipants;
