import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import theme from 'src/Theme';

interface PageBarBaseProps {
  popUp: boolean;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  mobileContent?: React.ReactNode;
  handleMobileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
}

const PageBarBase: React.FC<PageBarBaseProps> = ({
  leftContent,
  rightContent,
  mobileContent,
  popUp,
  handleMobileMenuOpen,
  style = {},
}) => (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar style={{ boxShadow: 'none', ...style }} position="static">
      <Toolbar
        style={{ padding: 0, margin: 0, backgroundColor: popUp ? 'white' : '#f2f2f2' }}
      >
        {leftContent}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {rightContent}
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          {mobileContent || (
            <IconButton
              size="large"
              aria-label="show more"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              {/* Default icon, can be replaced */}
              <Icon icon="cil:hamburger-menu" color={theme.palette.primary.main} />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  </Box>
);

export default PageBarBase;
