/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { getGenericDateValue } from 'src/Containers/Events/Rally/components/DynamicForm/util';
import { dynamicFormConfig } from './dynamicFormConfig';
import { RenderMode } from '../types/FormFieldType';

// Some of the fieds having values assigned to different keys.
// This function will fetch the exact values based on some conditions
const getRightComponentValues = (data: any): any => {
  switch (data.type) {
    case 'date':
      return getGenericDateValue(data);
    case 'totalField':
      return data?.values[0];
    case 'checkbox':
      return data?.values[0] === 'checked' ? 'checked' : '';
    case 'textarea':
      return data?.values[0];
    default:
      return data?.values[0];
  }
};

// This function renders each fields for the dynamicForm in the leftSidePanel
export const renderFields = (data: any, renderMode: RenderMode) => {
  const { LeftComponent, RightComponent } = dynamicFormConfig[data.type];
  switch (renderMode) {
    case 'left':
      return (
        <LeftComponent
          fieldData={data}
          mode="left"
          aditionalProps={{
            values: data?.values,
            disableResizeHandler: true,
            showTable: false,
          }}
        />
      );
    case 'right':
      return (
        <RightComponent
          fieldData={data}
          mode="right"
          aditionalProps={{
            values: getRightComponentValues(data),
            disableResizeHandler: true,
            showTable: true,
            defaultValue: data?.defaultDropDownValue,
          }}
          isEditable={data?.type === 'totalField'}
        />
      );
    case 'readOnlyPreview':
      return (
        <RightComponent
          fieldData={data}
          mode="readOnlyPreview"
          aditionalProps={{
            disableResizeHandler: true,
            showTable: true,
            values: data.values,
          }}
        />
      );
    default:
      return (
        <RightComponent
          fieldData={data}
          mode="preview"
          aditionalProps={{
            disableResizeHandler: true,
            showTable: true,
            values: data.values,
          }}
          isEditable
        />
      );
  }
};
