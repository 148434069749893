import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  flex: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  dropDownSpacing: {
    width: '100%',
    marginRight: '5px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  menuItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'transparent',
  },
  menu: {
    boxShadow: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  inputLabelFormControl: {
    transform: 'translate(0, 16px) scale(1)',
  },
  inputLabelShrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
  },
  colorPickerButton: {
    fontSize: '10px',
    marginTop: '10px',
    marginRight: '10px',
    border: '1px solid #cccccc',
  },
  colorPickerTitle: {
    margin: '0px',
    textAlign: 'center',
    paddingTop: '5px',
    fontSize: '14px',
    fontWeight: 700,
  },
  popperBody: {
    border: '1px solid #cccccc',
    borderRadius: '5px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: 200,
  },
}));
