import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import WarningToast from 'src/Components/Utils/WarningToast';
import { useTranslation } from 'react-i18next';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { EventType } from 'src/Types/EventType';
import PopupButton from 'src/Components/Button/PopupButton';
import DragAndDrop from 'src/Components/DragAndDrop/DragAndDrop';
import { useSelector } from 'react-redux';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import useApiCall from 'src/Hooks/useApiCall';
import { importRallyCSV, sampleRallyCSV } from 'src/Services/Auth/Actions/RallyActions';
import { RootState } from 'src/Store';
import Button from 'src/Components/Button/Button';
import RallyAddPopup from '../RallyAddPopup';
import {
  AuthorizedUser,
  axiosResponseToFileDownload,
  createErrorString,
} from '../../../../Services/Utils';

const useStyles = makeStyles((theme) => ({
  tableBtn: {
    '@media only screen and (max-width: 651px)': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
}));

type RallyListingPanalButtonsType = {
  rallyOrgId?: ObjectIdType;
  event: EventType;
  reload: Function;
  eventDetailsById?: Function;
  publicView?: boolean;
};

function RallyListingPanalButtons({
  rallyOrgId,
  event,
  reload,
  eventDetailsById,
  publicView = false,
}: RallyListingPanalButtonsType) {
  const id = event._id;
  const classes = useStyles();
  const modal = useModalDialog();
  const setOpenAddRally = useMemo(() => modal(RallyAddPopup, 'Rally-Add-Popup'), []);
  const { t } = useTranslation();

  const handleOpenPopup = () => {
    if (event.status === EnumEventStatus.EventFinished) {
      WarningToast(t('popup.eventFinished'));
      return;
    }
    setOpenAddRally(true, {
      event,
      eventDetailsById,
      reload,
      overrideBody: true,
    });
  };

  const rowsPerPage = useSelector((state: RootState) => state.rowsPerPage.rally);
  const [callApi, importLoader, data, error] = useApiCall();
  const language = useSelector((state: RootState) => state.language.value);
  const importHandleClick = async (file) => {
    if (!file) {
      WarningToast(t('others.Please select a valid Excel file'));
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    await callApi(importRallyCSV({ formData, eventId: id, language }), {
      onSuccess: (response) => {
        if (response.data.error.length !== 0) {
          const error = createErrorString(response);
          ErrorToast(error);
        } else {
          SuccessToast(t('Rally Imported'));
        }
      },
      onError: (error) => {
        ErrorToast(error || 'Failed to retrieve data!');
      },
    });
  };
  const sampleCsvHandleClick = () => {
    sampleRallyCSV()
      .then((response) => {
        axiosResponseToFileDownload({
          fileName: `Rally list.xlsx`,
          response,
        });
      })
      .catch(() => {
        ErrorToast(t('others.Something went wrong'));
      });
  };
  return (
    <div className={classes.tableBtn}>
      <AuthorizedUser
        rallyOrgId={rallyOrgId}
        success={
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              height: '20px',
            }}
          >
            <Button
              label={t('organizers.Add New')}
              onClick={() => {
                handleOpenPopup();
              }}
            />

            <PopupButton
              component={DragAndDrop}
              onClick={importHandleClick}
              label="others.Import CSV"
              loading={importLoader}
              modalName="rally-csv"
              sampleCsvHandleClick={sampleCsvHandleClick}
            />
          </div>
        }
        error={null}
        publicView={publicView}
      />
    </div>
  );
}

export default RallyListingPanalButtons;
