import { useState } from 'react';

type Callbacks = {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
};

type ApiCallHookReturnType = [
  (apiPromise: Promise<any>, callbacks?: Callbacks) => Promise<void>,
  boolean,
  any | null,
  any | null,
];

function useApiCall(): ApiCallHookReturnType {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const call = async (
    apiPromise: Promise<any>,
    { onSuccess, onError }: Callbacks = {},
  ): Promise<void> => {
    setLoading(true);
    setError(null); // reset the error before every call
    try {
      const response = await apiPromise;
      setData(response);
      onSuccess?.(response);
    } catch (e) {
      setError(e);
      onError?.(e);
    } finally {
      setLoading(false);
    }
  };

  return [call, loading, data, error];
}

export default useApiCall;
