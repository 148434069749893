import React, { useContext } from 'react';
import Select from 'react-select';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FieldLabel from './Fields/FieldLabel';
import HintField from './Fields/HintField';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetDropdownField({
  disabled = true,
  onChange,
  aditionalProps,
  fieldData,
  mode,
}: FormFieldType) {
  const { isPreviewDisabled, handleDropDownChange, findDropDownData } = useContext(
    ParticipantFormPreviewContext,
  );
  const { label, hint, styles, values, defaultDropDownValue, index, type, _id } =
    fieldData;

  const mapValuesToOptions = (data) => {
    if (data?.length > 0 && Array.isArray(data)) {
      return data.map((value, index) => ({
        label: value,
        value: index,
      }));
    }
    return [];
  };

  const options =
    mode === 'preview'
      ? mapValuesToOptions(values)
      : mapValuesToOptions(aditionalProps?.values);

  const handleChange = (option) => {
    if (mode === 'preview') {
      handleDropDownChange(option, index, type, _id);
    } else {
      onChange(option);
    }
  };

  const placeholder =
    mode === 'preview'
      ? findDropDownData(index) || defaultDropDownValue
      : defaultDropDownValue;

  return (
    <>
      <FieldLabel name={label} styles={styles} />
      <Select
        options={options}
        isDisabled={
          mode === 'preview' || mode === 'readOnlyPreview' ? isPreviewDisabled : disabled
        }
        onChange={handleChange}
        placeholder={placeholder}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      <HintField hint={hint} />
    </>
  );
}

export default GetDropdownField;
