import React from 'react';
import Container from '@material-ui/core/Container';
import './Style.css';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import PasswordField from 'src/Components/PasswordField/PasswordField';
import theme from '../../Theme';
import { validator } from '../../Services/Auth/Validations';
import Auth from '../../Layouts/Auth/Auth';
import RESET_PASSWORD_IMAGE from './Images/social.png';
import { passwordReset, logoutUser } from '../../Services/Auth/Actions/UserActions';
import ErrorMessageCustom from '../../Components/Utils/ErrorMessageCustom';
import { commonResponseHandler } from '../../Services/Utils';
import { FilledButton } from '../../Components/Utils';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
  },
}));

function ForgotPasswordCompleteView() {
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.language.value);
  const ForgotPasswordSchema = Yup.object().shape({
    password: validator.PASSWORD,
    confirmPassword: validator.CONFIRMPASSWORD,
  });

  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const { id }: any = params;
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const handleSubmit = (values: any, { setSubmitting }: any) => {
    // Same shape as initial values
    const modifiedValues = { ...values };
    delete modifiedValues.confirmPassword;

    const data: any = {
      ...modifiedValues,
      token,
    };

    commonResponseHandler(
      passwordReset({ values: data, id, token, language }),
      () => {
        setSubmitting(false);
        logoutUser(id);
        history.push('/');
      },
      () => setSubmitting(false),
    );
  };
  return (
    <div className="reset-password">
      <Auth
        heading={t('signUp.RESET PASSWORD')}
        varient="h2"
        image={RESET_PASSWORD_IMAGE}
      >
        <Container maxWidth="xs" className="container-wrap">
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, errors, touched }) => (
              <Form>
                <Field
                  className={classes.input}
                  text="password"
                  component={PasswordField}
                  name="password"
                  value={values.password}
                  placeholder={t('signUp.New Password*')}
                />
                <ErrorMessageCustom errors={errors} field="password" touched={touched} />

                <Field
                  className={classes.input}
                  text="password"
                  component={PasswordField}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  placeholder={t('signUp.Confirm Password*')}
                />
                <ErrorMessageCustom
                  errors={errors}
                  field="confirmPassword"
                  touched={touched}
                />

                <div>
                  {!isSubmitting && (
                    <FilledButton
                      name={t('forms.SUBMIT')}
                      type="submit"
                      disabled={isSubmitting}
                    />
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={theme.palette.primary.main}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
                <div className="text-wrap">
                  <p>
                    <span>
                      <Link to="/">{t('signUp.Sign in')}</Link>
                    </span>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Auth>
    </div>
  );
}

export default connect(null, { passwordReset })(ForgotPasswordCompleteView);
