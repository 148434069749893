import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  headingWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  paper: {
    backgroundColor: 'white',
    padding: '20px 40px',
    borderRadius: '10px',
    width: '50%',
    height: '450px',
    position: 'relative',
  },
  closeIcons: {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#bf2331',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 6px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '5px',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-50px',
    marginRight: '-20px',
    color: '#9FA2B4',
  },
  btn: {
    width: '100% !important',
    marginBottom: '15px',
    boxShadow: 'none',
  },
  imgWrap: {
    position: 'absolute',
    right: '20px',
    top: '-35px',
  },
  formBtn: {
    backgroundColor: 'transparent',
    border: '2px solid #007AFF',
    padding: '7px 6px',
    width: '100%',
    color: '#007AFF',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '1.6',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));
