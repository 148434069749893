import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    fontFamily: typography.fonts.secondary,
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },

  btnWrap: {
    textAlign: 'left',
  },
  btn: {
    float: 'left',
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },

  wrapper: {
    backgroundColor: '#fff',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:767px)': {
      padding: 0,
      margin: '30px 0px 20px',
    },
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 30px 15px',
    background: '#dfe0eb40',
    alignItems: 'center',
    height: '100px',
  },
  box: {
    padding: '15px 30px 15px',
    height: '525px',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '25px',
  },
  h3: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
}));
