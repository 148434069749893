import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FILE_SIZE_LIMIT } from 'src/Services/Constants';
import { toast } from 'react-toastify';
import displayFileSize, {
  checkTotalFileSize,
} from 'src/Components/Utils/DisplayFileSize';
import './AddFileInput.css';
import { AttachmentDragDropType, AttachmentType } from 'src/Types/AttachmentType';
import { FilterOptionType } from 'src/Types/FilterOptionType';
import { SetStateType } from 'src/Types/SetStateType';
import uploadImg from './ImageConfig/cloud-upload-regular-240.png';

type SetUploadFileType = SetStateType<Array<AttachmentType | AttachmentDragDropType>>;
type SetTotalFileSizeType = SetStateType<{
  isError: boolean;
  totalSize: number;
}>;

type DragDropTypeType = {
  uploadFile: Array<AttachmentType | AttachmentDragDropType>;
  setUploadFile: SetUploadFileType;
  setTotalFileSize: SetTotalFileSizeType;
  fileTypes: Array<FilterOptionType>;
};

function DragDrop({
  uploadFile,
  setUploadFile,
  setTotalFileSize,
  fileTypes,
}: DragDropTypeType) {
  const { t } = useTranslation();
  const wrapperRef = useRef<any>(null);
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (event) => {
    const dropingFiles: Array<File> = [...event.target.files];
    const dropedFiles: Array<AttachmentDragDropType> = [];
    // eslint-disable-next-line array-callback-return
    dropingFiles.map((file: File) => {
      const { size } = file;
      if (size > FILE_SIZE_LIMIT) {
        toast.warning(
          `${displayFileSize(file.size)} ${file.name} ${t(
            `validationErrors.file is not in the file size limit, Pleas choose less than`,
          )} ${displayFileSize(FILE_SIZE_LIMIT)}...!!`,
          {
            position: 'top-center',
          },
        );
        return;
      }
      const fileData: AttachmentDragDropType = {
        files: file,
        blobURL: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
        size,
        dropdown: fileTypes[0].value,
        uuid: uuidv4(),
      };
      dropedFiles.push(fileData);
      setTotalFileSize(checkTotalFileSize([...uploadFile, ...dropedFiles]));
      setUploadFile([...uploadFile, ...dropedFiles]);
    });
  };

  return (
    <div
      ref={wrapperRef}
      className="drop-file-input"
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="drop-file-input__label">
        <img style={{}} src={uploadImg} alt="" />
        <p>{t(`events.Drag or Drop your files here`)}</p>
      </div>
      <input type="file" value="" onChange={onFileDrop} multiple />
    </div>
  );
}

export default DragDrop;
