import { useCallback, useContext } from 'react';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { NewCoordinateType } from 'src/Containers/Events/Rally/utils';
import PreviewContext from 'src/Libs/DynamicForm/context/PreviewContext';
import ParticipantFormPreviewContext from 'src/Libs/DynamicForm/context/ParticipantFormPreviewContext';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import CloneFormPreviewContext from 'src/Libs/DynamicForm/context/CloneContext';

type useTableEditDataType = {
  tableIndex: string;
  tableMode?: string;
};

const useTableEditData = ({ tableIndex, tableMode }: useTableEditDataType) => {
  // Destructure context values for better readability
  const { added, editData, setEditData, setTableCellPopUp, tableCellPopUp } =
    useContext(DynamicFormContext);
  // ParticipantFormPreview
  const { rallyFormFieldLayout } = useContext(ParticipantFormPreviewContext);
  // save and pireview
  const { addedPreview } = useContext(PreviewContext);
  // clone form
  const { cloneAdded } = useContext(CloneFormPreviewContext);
  // Extracted function to show the table cell pop-up
  const shoWPopUp = (coordinate: NewCoordinateType) => {
    setTableCellPopUp(true);
    // Set the edit data with the given coordinate and table index
    setEditData({ ...editData, coordinate, index: tableIndex });
  };

  const getFilteredData = () => {
    if (tableMode === 'preview') {
      return rallyFormFieldLayout;
    }

    if (tableMode === 'readOnlyPreview') {
      return addedPreview !== undefined ? addedPreview : cloneAdded;
    }

    return added;
  };
  const tableAddedData = useCallback((): FormFieldEditType[] => {
    const filteredData = getFilteredData();
    return filteredData?.filter((add) => add.parentWidgetIndex === tableIndex);
  }, [tableMode, addedPreview, rallyFormFieldLayout, added, tableIndex]);

  // Extracted function to get the current table data based on the mode
  const currentTable = () => {
    const filteredData =
      tableMode === 'preview'
        ? rallyFormFieldLayout
        : addedPreview ?? added ?? cloneAdded;

    return filteredData?.filter((add) => add.index === tableIndex);
  };
  // Extracted function to close the table cell pop-up
  const closePop = () => {
    setTableCellPopUp(false);
  };
  return {
    tableAddedData,
    tableCellPopUp,
    closePop,
    shoWPopUp,
    tableMode,
    currentTable,
    added,
  };
};

export default useTableEditData;
