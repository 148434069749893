import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';

import {
  downloadFormDataPDF,
  downloadPDFDoc,
  getEvent,
} from 'src/Services/Auth/Actions/EventActions';
import { downloadDocument, Loader } from 'src/Services/Utils';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { Icon } from '@iconify/react';
import { FormType } from 'src/Types/FormListType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { FormFieldAutoPickType } from 'src/Types/FormFieldAutoPickType';
import useStyles from './ParticipantFormListStyle';
import EventFormsListing from '../EventParticipants/EventFormsListing';
import FormPreview from '../EventParticipants/FormPreview';

function ParticipantFormList({ details }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id }: any = useParams(); // eventId
  const eventId = details._id || id;
  const user = useSelector((store: RootState) => store.role.user);
  const [pdfLoad, setPdfLoad] = useState({});
  const [eventDetails, setEventDetails] = useState<Array<any>>([]);
  const role = useSelector((store: RootState) => store.role.role);

  useEffect(() => {
    getEvent(eventId)
      .then((response) => {
        setEventDetails(response.data.event);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [role]);

  const downloadPDF = (formId, formName) => {
    const downLoading = {};
    downLoading[formId] = true;
    setPdfLoad({ ...pdfLoad, ...downLoading });
    downloadPDFDoc(eventId, formId)
      .then((response) => {
        downloadDocument(response, `${formName}.pdf`);
        downLoading[formId] = false;
        setPdfLoad({ ...pdfLoad, ...downLoading });
      })
      .catch(() => {
        downLoading[formId] = false;
        setPdfLoad({ ...pdfLoad, ...downLoading });
        ErrorToast(t('General settings.Pdf download failed'));
      });
  };

  const formPDFDownload = (item, key, rallyId) => {
    const loadPDF = {};
    loadPDF[key] = true;
    setPdfLoad({ ...pdfLoad, ...loadPDF });
    downloadFormDataPDF(eventId, item._id, user._id, rallyId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        const formName = `${item.formName}`;
        link.setAttribute('download', `${formName}.pdf`);
        document.body.appendChild(link);
        link.click();
        loadPDF[key] = false;
        setPdfLoad({ ...pdfLoad, ...loadPDF });
      })
      .catch(() => {
        loadPDF[key] = false;
        setPdfLoad({ ...pdfLoad, ...loadPDF });
        ErrorToast(t('General settings.Pdf download failed'));
      });
  };

  const formActionButtons = ({
    form,
    rallyId,
    listForms,
    isEnrolled,
    autoPickValues,
  }: {
    form: FormType;
    rallyId: ObjectIdType;
    listForms: () => void;
    autoPickValues: FormFieldAutoPickType;
    isEnrolled: Boolean;
  }) => {
    if (isEnrolled) {
      return (
        <>
          <FormPreview
            item={form}
            user={user}
            rallyId={rallyId}
            listForms={listForms}
            eventDetails={eventDetails}
            autoPickValues={autoPickValues}
            mode="preview"
          />
          {form.isSubmitted && (
            <Loader isLoading={pdfLoad[form._id]} type="ThreeDots">
              <Icon
                icon="fe:download"
                className={classes.editIcon}
                onClick={() => formPDFDownload(form, form._id, rallyId)}
              />
            </Loader>
          )}
        </>
      );
    }
    return (
      <Loader isLoading={pdfLoad[form._id]}>
        <div
          onClick={() => {
            downloadPDF(form._id, form.formName);
          }}
          className={classes.download}
        >
          {t('events.Download')}
        </div>
      </Loader>
    );
  };

  return (
    <div>
      <div className={classes.boxType}>
        <div className={classes.topWrap}>
          <div className={classes.h3}>
            {t('events.Fill Up The Below Forms To Participate')}
          </div>
        </div>
        <EventFormsListing
          eventId={eventId}
          userId={user._id}
          status
          actionButtons={formActionButtons}
        />
      </div>
    </div>
  );
}

export default ParticipantFormList;
