import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px solid #343a40',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    height: '50px',
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: '5px',
  },
}));
