import React from 'react';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FieldLabel from './Fields/FieldLabel';
import fieldStyles from './FieldStyle';

function GetHeadingField({ fieldData, mode }: FormFieldType) {
  const classes = fieldStyles();
  const { label, styles } = fieldData;

  return (
    <>
      {/* <h2 className={classes.heading}>{label}</h2> */}
      <FieldLabel
        name={label}
        styles={styles}
        deafultClassName={classes.heading}
        type="heading"
      />
    </>
  );
}

export default GetHeadingField;
