import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
/* eslint-disable react/jsx-props-no-spreading */
function PasswordField({ field, ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  const useStyles = makeStyles(() => ({
    icon: {
      fontSize: '20px',
      cursor: 'pointer',
      position: 'absolute',
      right: '10px',
      top: '52%',
      transform: 'translateY(-50%)',
    },
    mainDiv: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      alignContent: 'center',
      width: '100%',
    },
  }));
  const classes = useStyles();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.mainDiv}>
      <input type={showPassword ? 'text' : 'password'} {...field} {...props} />
      <div className={classes.icon}>
        <Icon
          icon={showPassword ? 'akar-icons:eye-slashed' : 'akar-icons:eye-open'}
          onClick={togglePasswordVisibility}
        />
      </div>
    </div>
  );
}

export default PasswordField;
