export default {
  fonts: {
    primary: 'Montserrat',
    secondary: 'Mulish',
  },
  body: {
    fontFamily: 'Montserrat !important',
  },
  h1: {
    fontWeight: 400,
    fontSize: 44,
    letterSpacing: '0.5',
    color: '#2148C0',
    textTransform: 'uppercase' as const,
    fontFamily: 'Montserrat !important',
  },

  h2: {
    fontWeight: 400,
    fontSize: 35,
    letterSpacing: '0.5',
    color: '#2148C0',
    textTransform: 'uppercase' as const,
    fontFamily: 'Montserrat !important',
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: 'Mulish',
  },
  h4: {
    fontWeight: 700,
    fontSize: 17,
    fontFamily: 'Mulish',
  },
  h5: {
    fontWeight: 600,
    fontSize: 18,
    fontFamily: 'Mulish',
  },
  // h6: {
  //   fontWeight: 500,
  //   fontSize: 14,
  //   letterSpacing: '-0.05px'
  // },
  overline: {
    fontWeight: 500,
  },
  h6: {
    fontWeight: 500,
    fontSize: 25,
    letterSpacing: '0.5',
    color: '#2148C0',
    fontFamily: 'Montserrat !important',
  },
};
