import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { Grid, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import SigInLink from 'src/Components/Auth/SigInLink';
import PasswordField from 'src/Components/PasswordField/PasswordField';
import { registerValidation, getToken } from '../../Services/Auth/Actions/UserActions';
import SocialMediaView from './SocialMediaView';
import ErrorMessageCustom from '../../Components/Utils/ErrorMessageCustom';
import { FormLabel, FilledButton } from '../../Components/Utils';
import { validator } from '../../Services/Auth/Validations';
import SOCIALMEDIA_IMAGE from './Images/socialMediaImage.png';
import REGISTER_IMAGE from './Images/register.png';
import Auth from '../../Layouts/Auth/Auth';
import theme from '../../Theme';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
}));
function RegisterView() {
  const { t } = useTranslation();
  const SignupSchema = Yup.object().shape({
    firstName: validator.FIRSTNAME,
    lastName: validator.LASTNAME,
    username: validator.USERNAME,
    email: validator.EMAIL,
    phone: validator.PHONE,
    password: validator.PASSWORD,
    confirmPassword: validator.CONFIRMPASSWORD,
  });

  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = React.useState<string>();
  const [eventId, setEventId] = React.useState<string>('');
  const [rallyId, setRallyId] = React.useState<string>('');
  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (token) {
        history.push('/dashboard');
      }
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const email1 = params.get('email') || '';
      const eventId1 = params.get('eventId') || '';
      const rallyId1 = params.get('rallyId') || '';
      setEmail(email1);
      setEventId(eventId1);
      setRallyId(rallyId1);
    })();
  }, []);
  const [step, setStep] = useState(1);
  const [registerData, setRegisterData] = useState<any>();
  const language = useSelector((state: RootState) => state.language);
  return (
    <div>
      <div>
        {step === 1 && (
          <Auth heading={t('signUp.Register')} image={REGISTER_IMAGE} varient="h2">
            <Container maxWidth="xs" className="container-wrap">
              <Formik
                initialValues={
                  registerData || {
                    firstName: '',
                    lastName: '',
                    username: '',
                    email: email || '',
                    phone: '',
                    password: '',
                    confirmPassword: '',
                  }
                }
                enableReinitialize
                validationSchema={SignupSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                  const data: any = {
                    ...values,
                    language: language.value,
                  };
                  if (data.username) {
                    data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
                  }
                  if (data.email) {
                    data.email = data.email.toLowerCase();
                  }
                  if (eventId) {
                    data.eventId = eventId;
                  }
                  if (rallyId) {
                    data.rallyId = rallyId;
                  }

                  setRegisterData({ ...data });
                  delete data.confirmPassword;
                  registerValidation(data)
                    .then(() => {
                      setSubmitting(false);
                      setStep(2);
                    })
                    .catch((error) => {
                      error.response.data.errors.forEach((err) => {
                        setFieldError(err.fieldname, err.message);
                      });
                      const Errors = (
                        <div>
                          {error.response.data.errors.map((err) => (
                            <div>{err.message}</div>
                          ))}
                        </div>
                      );
                      toast.error(<div>{Errors}</div>, {
                        position: 'bottom-right',
                        autoClose: 5000,
                      });
                      // complete submission
                      setSubmitting(false);
                    });
                }}
              >
                {({ isSubmitting, values, errors, touched }) => (
                  <Form>
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <FormLabel name={t('signUp.First Name')} require />
                        <Field
                          className={classes.input}
                          type="text"
                          name="firstName"
                          value={values.firstName}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="firstName"
                          touched={touched}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel name={t('signUp.Last Name')} />
                        <Field
                          className={classes.input}
                          type="text"
                          name="lastName"
                          value={values.lastName}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="lastName"
                          touched={touched}
                        />
                      </Grid>
                    </Grid>

                    <FormLabel name={t('signUp.Username')} require />
                    <Field
                      className={classes.input}
                      type="text"
                      name="username"
                      value={values.username.trim().toLowerCase().replace(/ +/g, '')}
                    />

                    <ErrorMessageCustom
                      errors={errors}
                      field="username"
                      touched={touched}
                    />
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <FormLabel name={t('forms.Email')} />
                        <Field
                          className={classes.input}
                          type="email"
                          name="email"
                          value={email || values.email}
                          disabled={!!email}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="email"
                          touched={touched}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel name={t('signUp.Telephone')} />
                        <Field
                          className={classes.input}
                          type="telephone"
                          name="phone"
                          value={values.phone}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="phone"
                          touched={touched}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <FormLabel name={t('forms.Password')} require />
                        <Field
                          className={classes.input}
                          name="password"
                          value={values.password}
                          component={PasswordField}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="password"
                          touched={touched}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel name={t('signUp.Confirm Password')} require />
                        <Field
                          className={classes.input}
                          component={PasswordField}
                          name="confirmPassword"
                          value={values.confirmPassword}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="confirmPassword"
                          touched={touched}
                        />
                      </Grid>
                    </Grid>

                    {!isSubmitting && (
                      <FilledButton
                        name={t('signUp.Next')}
                        type="submit"
                        disabled={isSubmitting}
                      />
                    )}
                    {isSubmitting && (
                      <Loader
                        type="ThreeDots"
                        color={theme.palette.primary.main}
                        height={50}
                        width={50}
                      />
                    )}
                    <SigInLink eventId={eventId} rallyId={rallyId} />
                  </Form>
                )}
              </Formik>
            </Container>
          </Auth>
        )}
        {step === 2 && (
          <Auth
            heading={t('signUp.ADD SOCIAL MEDIA')}
            image={SOCIALMEDIA_IMAGE}
            varient="h2"
          >
            <Container maxWidth="xs" className="container-wrap">
              <div>
                <SocialMediaView
                  registerData={registerData}
                  eventId={eventId}
                  rallyId={rallyId}
                />
                <p className="link" onClick={() => setStep(1)}>
                  <span>{t('signUp.Back')}</span>
                </p>
              </div>
            </Container>
          </Auth>
        )}
      </div>
    </div>
  );
}

export default connect(null, { registerValidation })(RegisterView);
