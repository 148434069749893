/* eslint-disable react/require-default-props */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';
import theme from 'src/Theme';

const useStyles = makeStyles((theme) => ({
  button: {
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    width: '100%',
    marginTop: '30px',
    background: '#43A047',
    color: 'white !important',
    marginLeft: '-40px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
  },
}));
type ApproveRejectButtonProp = {
  onClick: Function;
  name: String;
  type?: 'greenBtn' | null;
};

function ApproveRejectButton({ onClick, name, type }: ApproveRejectButtonProp) {
  const classes = useStyles();
  return (
    <button
      type="submit"
      className={classes.button}
      onClick={() => onClick()}
      style={{ background: !type ? theme.palette.primary.main : '#43A047' }}
    >
      {name}
    </button>
  );
}

export default ApproveRejectButton;
