/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './NavBar.css';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { Icon } from '@iconify/react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import AVATAR from '../AvatarUpload/AvatarImage.svg';
import theme, { typography } from '../../Theme';
import VersionUpdates from '../Utils/VersionUpdates';
import CanAccess from '../Utils/CanAccess';
import CanAccessByRole from '../Utils/CanAccessByRole';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#363740',
    color: '#dedfe8',
    fontFamily: 'Montserrat !important',
  },
  root1: {
    width: '100%',
    backgroundColor: 'rgb(0 0 0 / 31%) !important',
    color: '#dedfe8',
    fontFamily: 'Montserrat !important',
  },
  link: {
    color: '#dedfe8 !important',
    textDecoration: 'none !important',
    fontFamily: typography.fonts.secondary,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
});

function NavBar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const user = useSelector((state: RootState) => state.role.user);
  const role = useSelector((state: RootState) => state.role?.role);
  const { t } = useTranslation();

  const adminMenu = [
    {
      name: t('overview.Overview'),
      icon: 'ic:baseline-dashboard',
      to: '/dashboard',
    },
    {
      name: t('organizers.Organisers'),
      icon: 'raphael:employee',
      to: '/organizers',
    },
    {
      name: t('events.Events'),
      icon: 'ic:outline-event-note',
      to: '/events',
    },
    {
      name: t('users.Users'),
      icon: 'fa-solid:users',
      to: '/users',
    },
    {
      name: t('others.Table Reports'),
      icon: 'material-symbols:table-chart',
      to: '/table-reports',
    },
    {
      name: t('formFields.Settings'),
      icon: 'clarity:settings-solid',
      to: `${window.location.pathname}`,
    },
  ];
  const organizerMenu = [
    {
      name: t('overview.Overview'),
      icon: 'ic:baseline-dashboard',
      to: '/dashboard',
    },
    {
      name: t('events.Events'),
      icon: 'ic:outline-event-note',
      to: '/events',
    },
    {
      name: t('users.Users'),
      icon: 'fa-solid:users',
      to: '/users',
    },
    {
      name: t('others.Table Reports'),
      icon: 'material-symbols:table-chart',
      to: '/table-reports',
    },
    {
      name: t('formFields.Settings'),
      icon: 'clarity:settings-solid',
      to: `${window.location.pathname}`,
    },
  ];
  const participantMenu = [
    {
      name: t('overview.Overview'),
      icon: 'ic:baseline-dashboard',
      to: '/dashboard',
    },
    {
      name: t('events.Events'),
      icon: 'ic:outline-event-note',
      to: '/events',
    },
    {
      name: t('users.Profile'),
      icon: 'ri:user-3-line',
      to: `/users/details/${user._id}/profile`,
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const currentRoute = useHistory().location.pathname.toLowerCase();
  let currentMenu = adminMenu;

  if (role === 'superadmin') {
    currentMenu = adminMenu;
  } else if (role === 'participant') {
    currentMenu = participantMenu;
  } else if (role === 'organizer' || role === 'eventOrganizer') {
    currentMenu = organizerMenu;
  }

  return (
    <>
      <div className="navbar">
        <Paper className={role === 'participant' ? classes.root1 : classes.root}>
          {role === 'participant' ? (
            <MenuList autoFocusItem={open}>
              {currentMenu.map((item) => (
                <MenuItem
                  key={item.name}
                  className={currentRoute === item.to ? 'active' : ''}
                >
                  <Link to={item.to} className={classes.link}>
                    {item.name === `${t('users.Profile')}` ? (
                      <div className="userImage">
                        <img
                          src={user.profileImage || AVATAR}
                          className="img-cls"
                          alt="AVATAR"
                        />
                        <div className="img-text">
                          <div className="img-main-head">{t('users.Hello,')}</div>
                          <div className="img-name">
                            {user.firstName || user.companyname}!
                          </div>
                          <div className="img-sub">{t('users.Welcome')}</div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {' '}
                        <ListItemIcon>
                          <Icon icon={item.icon} className="icon" />
                        </ListItemIcon>
                        <Typography variant="inherit">{item.name}</Typography>
                      </>
                    )}
                  </Link>
                </MenuItem>
              ))}
            </MenuList>
          ) : (
            <MenuList>
              {currentMenu.map((item) =>
                item.name === t('formFields.Settings') ? (
                  <React.Fragment key={item.name}>
                    <MenuItem
                      key={item.name}
                      onClick={handleToggle}
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                    >
                      <Link to={item.to} className={classes.link}>
                        <ListItemIcon>
                          <Icon icon={item.icon} className="icon" />
                        </ListItemIcon>
                        <Typography variant="inherit">{item.name}</Typography>
                        <div style={{ height: '10px', position: 'relative' }} />
                      </Link>
                    </MenuItem>
                    <div className="version">
                      <VersionUpdates />
                    </div>
                  </React.Fragment>
                ) : (
                  <MenuItem
                    key={item.name}
                    className={currentRoute === item.to ? 'active' : ''}
                  >
                    <Link to={item.to} className={classes.link}>
                      <ListItemIcon>
                        <Icon icon={item.icon} className="icon" />
                      </ListItemIcon>
                      <Typography variant="inherit">{item.name}</Typography>
                    </Link>
                  </MenuItem>
                ),
              )}
            </MenuList>
          )}
        </Paper>
      </div>
      <div className="sub-nav">
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="top-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                width: '100% !important',
                backgroundColor: '#363740',
              }}
            >
              <Paper className="paper">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={(event) => {
                      handleListKeyDown(event);
                    }}
                  >
                    <MenuItem>
                      <Link to="/event-types" className={classes.link}>
                        <Typography variant="inherit">
                          {t('eventTypes.Event Type')}
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/rally-types" className={classes.link}>
                        <Typography variant="inherit">
                          {t('rallyTypes.Rally Type')}
                        </Typography>
                      </Link>
                    </MenuItem>
                    <CanAccess permission={EnumPermissions.ViewFormFieldGroup}>
                      <MenuItem>
                        <Link to="/form-field-group" className={classes.link}>
                          <Typography variant="inherit">
                            {t('formFields.Form Field Group')}
                          </Typography>
                        </Link>
                      </MenuItem>
                    </CanAccess>
                    <MenuItem>
                      <Link to="/form-fields" className={classes.link}>
                        <Typography variant="inherit">
                          {t('formFields.Form Field')}
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/forms" className={classes.link}>
                        <Typography variant="inherit">{t('events.Forms')}</Typography>
                      </Link>
                    </MenuItem>
                    <CanAccess permission={EnumPermissions.Restore}>
                      <MenuItem>
                        <Link to="/restore" className={classes.link}>
                          <Typography variant="inherit">{t('Restore')}</Typography>
                        </Link>
                      </MenuItem>
                    </CanAccess>
                    <CanAccessByRole
                      roles={[
                        EnumUserRoles.organizer,
                        EnumUserRoles.eventOrganizer,
                        EnumUserRoles.participant,
                      ]}
                    >
                      <MenuItem>
                        <Link
                          to={`/organizers/details/${
                            user && user.organizationId
                          }/profile`}
                          className={classes.link}
                        >
                          <Typography variant="inherit">{t('users.Profile')}</Typography>
                        </Link>
                      </MenuItem>
                    </CanAccessByRole>
                    <CanAccess permission={EnumPermissions.GetAllUsers}>
                      <MenuItem>
                        <Link to="/all-users" className={classes.link}>
                          <Typography variant="inherit">
                            {t('users.All Users')}
                          </Typography>
                        </Link>
                      </MenuItem>
                    </CanAccess>
                    <CanAccess permission={EnumPermissions.GetFileType}>
                      <MenuItem>
                        <Link to="/file-types" className={classes.link}>
                          <Typography variant="inherit">
                            {t('File Type.File Type')}
                          </Typography>
                        </Link>
                      </MenuItem>
                    </CanAccess>
                    <CanAccess permission={EnumPermissions.ManageEventStatus}>
                      <MenuItem>
                        <Link to="/event-status" className={classes.link}>
                          <Typography variant="inherit">
                            {t('Event Status.Event Status')}
                          </Typography>
                        </Link>
                      </MenuItem>
                    </CanAccess>
                    <CanAccess permission={EnumPermissions.GetGeneralSettings}>
                      <MenuItem>
                        <Link to="/general-settings" className={classes.link}>
                          <Typography variant="inherit">
                            {t('General settings.General settings')}
                          </Typography>
                        </Link>
                      </MenuItem>
                    </CanAccess>
                    <MenuItem onClick={handleClose} style={{ display: 'none' }} />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
}

export default NavBar;
