import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  errorCode: {
    color: 'red',
    fontSize: '12px',
    marginBottom: '10px',
    textAlign: 'left',
    fontWeight: 500,
  },
}));
function ErrorMessageCustom({ errors, field, touched, testid = `error-${field}` }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      {errors && errors[field] && touched[field] ? (
        <div data-testid={testid} className={classes.errorCode}>
          {t(errors[field])}
        </div>
      ) : null}
    </div>
  );
}

export default ErrorMessageCustom;
