import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import RallyTypeListing from '../../Containers/RallyTypes/RallyTypeListing';

function RallyTypes() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('rallyTypes.Rally Type Management')} />

        <RallyTypeListing />

        {/* <BreadCrumbs/> */}
      </Dashboard>
    </div>
  );
}

export default RallyTypes;
