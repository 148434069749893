import React from 'react';
import './Style.css';
import LanguageSelectTab from 'src/Components/LanguageSelect/LanguageSelectTab';
import compregLogo from './compregLogo.png';
import useStyles from '../../Components/Header/HeaderStyle'

function Auth({
  heading,
  children,
  varient,
  image,
}: {
  heading: String;
  children: any;
  varient: string;
  image: string;
}) {
  const classes = useStyles();
 
  return (
    <div className="auth-layout">
      <div
        className="left-sec"
        style={{ background: `url(${image})`, backgroundPosition: 'bottom' }}
      >
        {/* <img src={props.image}></img> */}
      </div>
      <div className="right-sec">
        <div className="language-sec">
          <div className={classes.wrapalign}>
            <LanguageSelectTab />
          </div>
        </div>
        <div className="wrap">
          <img src={compregLogo} alt="compregLogo" style={{ width: '400px' }} />
          {/* <Typography variant={props.varient}>{props.heading}</Typography> */}
          {children}
        </div>
      </div>
    </div>
  );
}

export default Auth;
