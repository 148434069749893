/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import ErrorToast from 'src/Components/Utils/ErrorToast';

const userNameMinLimit = 1;
const userNameMaxLimit = 100;

const passwordMinLimit = 1;
const passwordMaxLimit = 100;

export const validator = {
  USERNAME: Yup.string()
    .min(userNameMinLimit, 'validationErrors.Too Short!')
    .max(userNameMaxLimit, 'validationErrors.Too Long!')
    .trim('validationErrors.The username cannot include leading and trailing spaces')
    .strict(true)
    .required('validationErrors.Username is Required'),
  PASSWORD: Yup.string()
    .min(passwordMinLimit, 'validationErrors.Too Short!')
    .max(passwordMaxLimit, 'validationErrors.Too Long!')
    .trim('validationErrors.The password cannot include leading and trailing spaces')
    .strict(true)
    .required('validationErrors.Password is Required'),
  CONFIRMPASSWORD: Yup.string()
    .oneOf([Yup.ref('password'), null], 'validationErrors.Passwords must match')
    .trim('validationErrors.The password cannot include leading and trailing spaces')
    .strict(true)
    .required('validationErrors.Confirm Password is Required'),
  CHANGECONFIRMPASSWORD: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'validationErrors.Passwords must match')
    .trim('validationErrors.The password cannot include leading and trailing spaces')
    .strict(true)
    .required('validationErrors.Confirm Password is Required'),
  EMAIL: Yup.string()
    .email('validationErrors.Invalid email')
    .lowercase('validationErrors.The Email cannot include uppercase alphabets')
    .trim('validationErrors.The Email cannot include leading and trailing spaces')
    .strict(true),
  FIRSTNAME: Yup.string()
    .matches(/[a-z-A-Z]+/g, 'validationErrors.use at least one alphabet')
    .trim('validationErrors.The firstname cannot include leading and trailing spaces')
    .strict(true)
    .required('validationErrors.First name is Required'),
  LASTNAME: Yup.string()
    .matches(/[a-z-A-Z]+/g, 'validationErrors.use at least one alphabet')
    .trim('validationErrors.The lastname cannot include leading and trailing spaces')
    .strict(true),
  PHONE: Yup.string()
    .matches(/^\+?[\d\s()-]{5,25}$/, 'validationErrors.Invalid Phone Number')
    .trim('validationErrors.Phone Number cannot include leading and trailing spaces')
    .strict(true),
  ADDRESS1: Yup.string(),
  STREET: Yup.string(),
  CITY: Yup.string(),
  STATE: Yup.string(),
  COUNTRY: Yup.string(),
  POSTCODE: Yup.string(),
  FACEBOOKURL: Yup.string(),
  INSTAGRAMURL: Yup.string(),
  WEBSITEURL: Yup.string(),
  DESIGNATION: Yup.string(),
  COMPANYNAME: Yup.string().required('validationErrors.Company name is Required'),
};

export const validateDate = (data, t) => {
  if (data.endDateTime < data.startDateTime) {
    ErrorToast(t('toast.End Date must be greater than or equal to Start Date'));
    return false;
  }
  if (data.lastDateOfReg > data.endDateTime) {
    ErrorToast(
      t('toast.Last Date of Registration must be less than or equal to End Date'),
    );
    return false;
  }
  if (data.lastDateOfReg < data.startDateOfReg) {
    ErrorToast(
      t(
        'toast.Last Date of Registration must be greater than or equal to Start Date of Registration',
      ),
    );
    return false;
  }
  if (data.startDateOfReg > data.startDateTime) {
    ErrorToast(
      t('toast.Start Date of Registration must be less than or equal to Start Date'),
    );
    return false;
  }
  return true;
};
