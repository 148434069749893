import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import moment from 'moment';
import WarningToast from 'src/Components/Utils/WarningToast';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import useEventEnrollNow from 'src/Hooks/useEventEnrollNow';
import StatusLabel from '../../Components/Utils/StatusLabel';
import AVATAR from './eventimgAvatar.svg';
import ParticipantEventDetails from '../../Containers/Events/EventDetails/ParticipantEventDetails';
import {
  checkUserEnroll,
  getEvent,
  getToken,
} from '../../Services/Auth/Actions/EventActions';
import RallyListing from '../../Containers/Events/Rally/RallyListing';
import EVENTAVATAR from './eventProfile.png';
import DATE from './Date1.svg';
import ParticipantFormList from '../../Containers/Events/Forms/ParticipantFormList';
import useStyles from './EventViewStyle';
import ToggleText from './ToggleText';
import ContactSectionWrap from './EventDetails/ContactSectionWrap';
import FLAG from './Flag2.svg';
import MAP from './map3.svg';
import USER from './user4.svg';
import EventCards from './EventDetails/EventCards';

function EventView() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [details, setDetails] = useState<any>([]);
  const [participantCount, setParticipantCount] = useState<any>();
  const [isEnroll, setIsEnroll] = useState(false);
  const [currentPage, setCurrentPage] = useState('details');
  const role = useSelector((state: RootState) => state.role);
  const { eventId, rallyId }: any = useParams();
  const { enrollNowToEvent, enrollNowValidation } = useEventEnrollNow();

  const eventOrganizerName =
    details.organizer && details.organizer.name ? details.organizer.name : '';

  const handleViewEvent = () => {
    if (
      [EnumUserRoles.organizer, EnumUserRoles.eventOrganizer].includes(role.role) &&
      role.user.participantMode === false
    ) {
      WarningToast(t('toast.View event details from participant dashboard'));
      return history.push(`/dashboard`);
    }
    return history.push(`/events/details/${eventId}/details`);
  };

  const redirectToEvent = () => {
    if (enrollNowValidation(details.status)) {
      if (getToken()) {
        enrollNowToEvent(role.user, eventId, rallyId);
        return;
      }
      history.push(
        `/register${eventId ? `/?eventId=${eventId}` : ''}${
          rallyId ? `&rallyId=${rallyId}` : ''
        }`,
      );
    }
  };

  const checkUserEnrollOrNot = () => {
    checkUserEnroll(eventId).then((response) => {
      setIsEnroll(response.data.enroll);
    });
  };

  const eventDetailsById = () => {
    getEvent(eventId)
      .then((response) => {
        const data = response.data.event;
        setParticipantCount(response.data.participants);
        setDetails(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (getToken()) {
      checkUserEnrollOrNot();
    }
  }, [role]);

  useEffect(() => {
    eventDetailsById();
  }, []);
  const showDetailsPage = () => {
    switch (currentPage) {
      case 'details':
        return <ParticipantEventDetails details={details} />;

      case 'rallies':
        return (
          <RallyListing
            event={details}
            isEventEnrolled={isEnroll}
            eventStatus={details.status}
            publicView
          />
        );

      case 'forms':
        return <ParticipantFormList details={details} />;

      default:
        return <ParticipantEventDetails details={details} />;
    }
  };
  return (
    <div style={{ marginLeft: '50px', marginRight: '60px' }}>
      <div className={classes.participantSection}>
        <div className={classes.eventDetail}>
          <div className={classes.heading}>
            {details.eventImage ? (
              <img src={details.eventImage} className={classes.avatar} alt="img" />
            ) : (
              <img src={AVATAR} className={classes.avatar} alt="img" />
            )}
            <h2 className={classes.head}>{details.eventName}</h2>
            {isEnroll ? (
              <div style={{ height: '5px !important' }}>
                <button
                  type="button"
                  className={`${classes.enrollBtn} ${classes.btnOne} `}
                  onClick={handleViewEvent}
                >
                  {t('forms.View Event')}{' '}
                </button>
              </div>
            ) : (
              <div style={{ height: '5px !important' }}>
                <button
                  type="button"
                  className={`${classes.enrollBtn} ${classes.btnOne} `}
                  onClick={() => {
                    redirectToEvent();
                  }}
                >
                  {t('forms.Enroll Now')}{' '}
                </button>
              </div>
            )}
          </div>
          <div>
            <p className={classes.p} style={{}}>
              {t('others.by')} <span className={classes.span}>{eventOrganizerName}</span>
            </p>
          </div>
          <div>
            <ToggleText details={details?.eventDescription} />
          </div>
          <ContactSectionWrap
            details={details.enquiryEmail}
            icon="dashicons:email"
            linkto={`tel:${details.enquiryEmail}`}
          />
          <ContactSectionWrap
            details={details.enquiryPhone}
            icon="bytesize:telephone"
            linkto={`tel:${details.enquiryPhone}`}
          />
          <div className={classes.eventStatus}>
            <div className={classes.eventStatusText}>{t('others.Event Status')}</div>
            <div className={classes.eventStatusCls}>
              <StatusLabel data={details} />
            </div>
          </div>
          <div className={classes.eventMoreDetails}>
            <EventCards
              details={moment(details.startDateTime).format('DD MMM YYYY')}
              img={DATE}
            />
            <EventCards
              details={details.rallies ? details.rallies.length : 0}
              img={FLAG}
              name={t('forms.Rally')}
            />
            <EventCards details={details.venue} img={MAP} toolTip />
            <EventCards
              details={participantCount === 0 ? null : participantCount}
              img={USER}
              name={t('forms.Participants')}
            />
          </div>
        </div>
        <div className={classes.eventImage}>
          <img
            src={details.eventImage || EVENTAVATAR}
            className={classes.eventImageParticipant}
            alt="EVENTAVATAR"
          />
        </div>
      </div>
      <div className={classes.eventTabDetails} id="Element">
        <div className={classes.eventTabs}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <h3
              onClick={() => setCurrentPage('details')}
              className={
                currentPage === 'details'
                  ? `${classes.eventTab} ${classes.eventTabActive}`
                  : `${classes.eventTab}`
              }
            >
              {t('forms.Event Details')}
            </h3>
            <h3
              onClick={() => setCurrentPage('rallies')}
              className={
                currentPage === 'rallies'
                  ? `${classes.eventTab} ${classes.eventTabActive}`
                  : `${classes.eventTab}`
              }
            >
              {t('overview.Rally Details')}
            </h3>
            <h3
              onClick={() => setCurrentPage('forms')}
              className={
                currentPage === 'forms'
                  ? `${classes.eventTab} ${classes.eventTabActive}`
                  : `${classes.eventTab}`
              }
            >
              {t('forms.Event Forms')}
            </h3>
          </div>
        </div>
        {showDetailsPage()}
      </div>
    </div>
  );
}

export default EventView;
