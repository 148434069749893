import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import typography from 'src/Theme/typography';
import SpinnerLoader from 'react-loader-spinner';
import theme from 'src/Theme';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
  },
}));

function FormButton({ className, onClick, name, isLoading = false }: any) {
  const classes = useStyles();
  if (isLoading) {
    return (
      <div className={clsx(className)}>
        <SpinnerLoader
          type="ThreeDots"
          color={theme.palette.primary.main}
          height={50}
          width={50}
        />
      </div>
    );
  }
  return (
    <button type="submit" className={clsx(classes.button, className)} onClick={onClick}>
      {name}
    </button>
  );
}

export default FormButton;
