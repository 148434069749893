import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Loader } from 'src/Services/Utils';
import theme from 'src/Theme';
import typography from 'src/Theme/typography';
import { useEffect, useState } from 'react';
import { rallyListing } from 'src/Services/Auth/Actions/RallyActions';
import { listingAllUser } from 'src/Services/Auth/Actions/UserActions';
import { FormLabel } from 'src/Components/Utils';
import PopupModal from 'src/Libs/ConfirmationDialog/PopupModal';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '25%',
    height: '75%',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
    overflow: 'auto',
  },
  paperinner: {
    padding: theme.spacing(2, 4, 2),
    height: '400px',
    overflow: 'auto',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-50px',
    marginRight: '-20px',
    color: '#9FA2B4',
  },

  outerbox: {
    position: 'relative',
  },
  btn: {
    width: '100% !important',
    marginBottom: '15px',
  },
  formBtn: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: '7px 6px',
    width: '100%',
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '1.6',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 6px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '5px',
    width: '100%',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  btnWrap: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'center',
  },
}));

function AddParticipantFormWrapper({
  children,
  participants,
  details,
  reload,
  onClose,
  rally,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [rallyOptions, setRallyOptions] = useState([]);
  const [selectedRallys, setSelectedRallys] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState(true);
  const [participantList, setParticipantList] = useState([]);
  const [currentForm, setCurrentForm] = useState<
    'existingParticipants' | 'newParticipants'
  >('existingParticipants');

  useEffect(() => {
    if (participants) {
      rallyListing({
        id: details,
        sortBy: 'RallyName',
        sort: 'asc',
        search: '',
        page: 0,
        rowsPerPage: 10,
      })
        .then((response) => {
          const rallyFilterOptions = response.data.Rallies.filter(
            (el) => !el.isCancelled,
          ).map((item) => {
            const newObject = {
              value: item.rallyName,
              label: item.rallyName,
              id: item._id,
            };
            return newObject;
          });
          setRallyOptions(rallyFilterOptions);
          if (rallyFilterOptions.length > 0) {
            setSelectedRallys([rallyFilterOptions[0]]);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
      listingAllUser({})
        .then((resp) => {
          setParticipantList(resp.data.users);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      listingAllUser({})
        .then((resp) => {
          setParticipantList(resp.data.users);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const handelShowForm = () => {
    setCurrentForm(
      currentForm === 'existingParticipants' ? 'newParticipants' : 'existingParticipants',
    );
  };

  const handleChanges = (selectedOption, type) => {
    if (type.action === 'select-option') {
      setSelectedRallys([...selectedOption]);
    } else if (type.action === 'clear') {
      setSelectedRallys([]);
    } else if (type.action === 'remove-value') {
      const tempSelectedRallys = selectedRallys.filter(
        (r) => r.id !== type.removedValue.id,
      );
      setSelectedRallys(tempSelectedRallys);
    }
  };

  return (
    <div>
      <PopupModal
        showModel
        onCancel={onClose}
        description={
          <div className={classes.paperinner}>
            <div className={classes.wrapper}>
              <h2 id="transition-modal-title" className={classes.title}>
                {t('forms.Add New Participant')}
              </h2>
            </div>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <FormLabel name={t('')} />
                <div
                  onClick={handelShowForm}
                  className={`${classes.btn} ${classes.formBtn}`}
                >
                  {currentForm === 'existingParticipants'
                    ? t('forms.Add New Participant')
                    : t('forms.Add existing participant')}
                </div>
              </Grid>
            </Grid>
            <Loader isLoading={isLoading}>
              {children({
                selectedRallys,
                participants,
                details,
                rally,
                reload,
                onClose,
                rallyOptions,
                handleChanges,
                participantList,
                currentForm,
              })}
            </Loader>
          </div>
        }
      />
    </div>
  );
}

export default AddParticipantFormWrapper;
