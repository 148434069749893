import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useStyles from './SigInLinkStyle';

function SigInLink({ eventId, rallyId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.textWrap}>
      <p className={classes.p}>
        <span className={classes.span}>
          <Link
            to={`/${eventId ? `?eventId=${eventId}` : ''}${
              rallyId ? `&rallyId=${rallyId}` : ''
            }`}
          >
            {t('signUp.Sign in')}
          </Link>
        </span>
      </p>
    </div>
  );
}

export default SigInLink;
