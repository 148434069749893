import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import ModeEditOutlineIcon from '@material-ui/icons/EditRounded';
import COMPREG_SVG from './compreg2-logo.png';

import useStyles from './style';

function FormHeader({
  eventDetails,
  formHeaderDetails,
  formName,
  openHeaderModal,
  isEditable,
}) {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.imageContainer}>
          <img
            src={eventDetails.eventImage ? eventDetails.eventImage : COMPREG_SVG}
            alt="cxc"
            className={classes.image}
          />
        </div>
        <div>
          <h4 style={{ textAlign: 'left' }}>{formName}</h4>
          {(eventDetails?.eventName || formHeaderDetails.eventName) && (
            <h4>{`${formHeaderDetails?.eventName || eventDetails?.eventName}`}</h4>
          )}{' '}
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <h4>
          {formHeaderDetails?.enquiryEmail ||
            (formHeaderDetails?.enquiryEmail === '' ? '' : eventDetails.enquiryEmail)}
        </h4>
        <h4>
          {formHeaderDetails?.enquiryPhone ||
            (formHeaderDetails?.enquiryPhone === '' ? '' : eventDetails.enquiryPhone)}
        </h4>
        {isEditable && (
          <Tooltip title="Edit Header">
            <IconButton onClick={openHeaderModal}>
              <ModeEditOutlineIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default FormHeader;
