/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PopupModal from 'src/Libs/ConfirmationDialog/PopupModal';
import {
  GRID_LAYOUT_ROWHEIGHT,
  GRID_LAYOUT_WIDTH,
  TABLE_GRID_LAYOUT_COLS,
} from 'src/Containers/Events/Rally/components/DynamicForm/util';
import useTableEditData from 'src/Libs/DynamicForm/components/FormField/table/hooks/useTableEditData';
import { NewCoordinateType } from 'src/Containers/Events/Rally/utils';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import LeftFormWrapper from 'src/Containers/Events/Rally/components/DynamicForm/LeftForm/LeftFormWrapper';
import LeftForm from 'src/Containers/Events/Rally/components/DynamicForm/LeftForm/LeftForm';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import TableElement from './DynamicTableElements';

const ResponsiveGridLayout = WidthProvider(Responsive);
type DynamicTableProps = {
  children?: React.ReactNode;
  tableMode: string;
  tableIndex: string;
};
function DynamicTable({ tableMode, tableIndex }: DynamicTableProps) {
  const { tableCellPopUp, closePop, tableAddedData, shoWPopUp, currentTable, added } =
    useTableEditData({
      tableIndex,
      tableMode,
    });
  const [tableExpand, setTableExpand] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    leftVisibleTabData,
    leftVisibleData,
    selected,
    addSelected,
    setLeftVisibleTabData,
    addForm,
    deleteCurrentItem,
    addRemove,
    removed,
    setFormType,
    selectAllRight,
    setAdded,
    fullData,
  } = useContext(DynamicFormContext);

  const updatedCoordinates = (newLayout: NewCoordinateType[]): FormFieldEditType[] =>
    added?.map((co: FormFieldEditType): FormFieldEditType => {
      const newCor = newLayout.find((newCord) => newCord.i === co.coordinate?.i);
      if (newCor) {
        return {
          ...co,
          coordinate: newCor, // Update properties using newCoordinates
        };
      }
      return co;
    });
  return (
    <div key={tableIndex}>
      <ResponsiveGridLayout
        key={tableIndex}
        className="layout"
        containerPadding={[0, 0]}
        margin={[0, 0]}
        width={GRID_LAYOUT_WIDTH}
        rowHeight={GRID_LAYOUT_ROWHEIGHT}
        cols={{
          lg: TABLE_GRID_LAYOUT_COLS,
          md: TABLE_GRID_LAYOUT_COLS,
          sm: TABLE_GRID_LAYOUT_COLS,
          xs: TABLE_GRID_LAYOUT_COLS,
          xxs: TABLE_GRID_LAYOUT_COLS,
        }}
        onLayoutChange={(newLayout) => {
          window.dispatchEvent(new Event('resize'));
          if (setAdded) {
            setTimeout(() => {
              setAdded(updatedCoordinates(newLayout));
            }, 500);
          }
        }}
      >
        {tableAddedData()?.map((field) =>
          TableElement({
            field,
            shoWPopUp,
            tableMode,
            currentTable,
            deleteCurrentItem,
            addRemove,
            added,
            fullData,
            setAdded,
          }),
        )}
      </ResponsiveGridLayout>
      <PopupModal
        showModel={tableCellPopUp}
        onCancel={closePop}
        description={
          <LeftFormWrapper
            setFormType={setFormType}
            setLeftVisibleTabData={setLeftVisibleTabData}
            leftVisibleData={leftVisibleData}
            setExpanded={setTableExpand}
            expanded={tableExpand}
            loading={loading}
            selectAllRight={selectAllRight}
            selected={selected}
            leftVisibleTabData={leftVisibleTabData}
            removed={removed}
            addForm={addForm}
            addSelected={addSelected}
          >
            {(props) => <LeftForm {...props} />}
          </LeftFormWrapper>
        }
      />
    </div>
  );
}
export default DynamicTable;
