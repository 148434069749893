import React from 'react';
import fieldStyles from '../FieldStyle';

function HintField({ hint }) {
  const classes = fieldStyles();
  return hint ? (
    <div className={classes.hintContainer}>
      <p className={classes.hintText}>{hint}</p>
    </div>
  ) : (
    <div></div>
  );
}

export default HintField;
