import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';
import { CircularLoader } from '../Utils';

const useStyles = makeStyles(() => ({
  emptyTableCell: {
    borderBottom: 'none',
  },
  tablebody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
}));

function TableContent({
  emptyText,
  list,
  loading,
  children,
}: {
  emptyText: string;
  list: Array<any>;
  loading: boolean;
  children: any;
}) {
  const classes = useStyles();
  if (loading) {
    return (
      <TableRow>
        <TableCell className={classes.emptyTableCell} />
        <TableCell className={classes.emptyTableCell} />
        <CircularLoader />
        <TableCell className={classes.emptyTableCell} />
        <TableCell className={classes.emptyTableCell} />
        <TableCell className={classes.emptyTableCell} />
      </TableRow>
    );
  }
  if (list.length === 0) {
    return (
      <TableRow key={0} hover role="checkbox" tabIndex={-1}>
        <TableCell className={classes.tablebody}>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>{emptyText}</div>
        </TableCell>
      </TableRow>
    );
  }
  return children;
}

export default TableContent;
