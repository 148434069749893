import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useAuthorizedUserClick from 'src/Hooks/useAuthorizedUserClick';
import { EventType } from 'src/Types/EventType';
import { RallyType } from 'src/Types/RallyType';
import useStyles from './EventListingStyle';

type EventListRedirectionType = {
  item: EventType | RallyType;
  pathname: 'rallies' | 'participants' | 'forms';
  count: number | null;
  icon: ReactElement<any, any> | null;
  forceOpen?: boolean;
};

function EventListRedirection({
  item,
  pathname,
  count,
  icon,
  forceOpen = false,
}: EventListRedirectionType) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { authorizedUserClick } = useAuthorizedUserClick();

  const click = () => {
    if (item && item._id) {
      history.push({
        pathname: `/events/details/${item._id}/${pathname}`,
        state: { showGoBack: true, goBackpath: history.location.pathname },
      });
    }
  };

  return (
    <div
      className={classes.subcounts}
      onClick={() => authorizedUserClick(item, click, forceOpen)}
    >
      {icon || (
        <div>
          {t(`events.${pathname}`)}: {count || `0`}
        </div>
      )}
    </div>
  );
}

export default EventListRedirection;
