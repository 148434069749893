import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';

const useStyles = makeStyles({
  labelContainer: {
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: 'max-content',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  orange: {
    backgroundColor: '#ff00ff',
  },
  yellow: {
    backgroundColor: '#FEC400',
  },
  ash: {
    backgroundColor: '#C4C4C4',
  },
  green: {
    backgroundColor: '#29CC97',
  },
  blue: {
    backgroundColor: '#1c73ff',
  },
  red: {
    backgroundColor: '#F12B2C',
  },
});

const statusMap = {
  [EnumEventStatus.Preparation]: 'orange',
  [EnumEventStatus.RegistrationsOpen]: 'yellow',
  [EnumEventStatus.RegistrationsCompleted]: 'ash',
  [EnumEventStatus.InProgress]: 'blue',
  [EnumEventStatus.EventFinished]: 'green',
  [EnumEventStatus.Cancelled]: 'red',
};

function StatusLabel({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const getStatusClass = (status) => {
    const colorClass = statusMap[status];
    return classes[colorClass] || classes.labelContainer;
  };

  const getStatusLabel = (status) => t(`eventStatus.${status}`);

  const statusClass = getStatusClass(data.status);
  const statusLabel = getStatusLabel(data.status);

  return (
    <div className={`${classes.labelContainer} ${statusClass}`}>
      <span>{statusLabel}</span>
    </div>
  );
}

export default StatusLabel;
