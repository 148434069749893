/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import WarningToast from 'src/Components/Utils/WarningToast';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import UserType from 'src/Types/UserType';
import {
  rallyListing,
  getUserRallies,
  addUserRallies,
} from '../../../Services/Auth/Actions/RallyActions';
import './style.css';
import useStyles from './AddRallyStyle';

type RallyType = {
  map(arg0: (vl: any) => any): RallyType;
  length: number;
  attachment: String;
  endDateTime: String;
  eventId: String;
  _id: String;
  id: String;
  isActive: boolean;
  isCancelled: boolean;
  isDeleted: boolean;
  memo: String;
  name: String;
  order: Number;
  organizerId: String;
  participants: [];
  rallyName: String;
  rallyType: String;
  startDateTime: String;
};

type AddRallycomponentType = {
  setOpenPopup: Function;
  item: UserType;
  eventId: ObjectIdType;
  status: boolean;
  eventStatus: EnumEventStatus;
};

function AddRallycomponent({
  setOpenPopup,
  item,
  eventId,
  status,
  eventStatus,
}: AddRallycomponentType) {
  const classes = useStyles();
  const [list, setList] = useState<RallyType[]>([]);
  const [existingRally, setExistingRally] = useState<RallyType[]>([]);
  const { t } = useTranslation();
  const [rally, setRally] = useState<RallyType[]>([]);

  useEffect(() => {
    let existingArray: RallyType[] = [];
    rallyListing({ id: eventId })
      .then(async (res) => {
        let Rallies = res.data.Rallies.filter((vl) => vl.isActive);
        if (Rallies.length > 0) {
          Rallies = Rallies.map((vl) => {
            // eslint-disable-next-line no-param-reassign
            vl.id = vl._id;
            vl.name = vl.rallyName;
            return vl;
          });
        }
        await setList(Rallies);
        getUserRallies(eventId, item._id).then(async (response) => {
          if (response.data.rallies.length > 0) {
            existingArray = response.data.rallies.map((rallyId) =>
              Rallies.find((ral) => {
                if (ral._id === rallyId) {
                  return ral;
                }
                return null;
              }),
            );
          }
          if (existingArray && existingArray.length > 0) {
            existingArray = existingArray.map((existing: RallyType) => ({
              ...existing,
              id: existing?._id,
              name: existing?.rallyName,
            }));
          }
          await setExistingRally(existingArray);
          await setRally(existingArray);
        });
      })
      .catch(() => {});
  }, []);

  const onRemove = (value) => {
    setRally([...value]);
  };

  const onSelect = (value) => {
    setRally([...value]);
  };

  const addRallyToParticipant = (e) => {
    e.preventDefault();
    let selectedList;
    let unSelectedList;
    let data = {};

    if (eventStatus === EnumEventStatus.EventFinished) {
      WarningToast(t('popup.eventFinished'));
      return;
    }
    if (status === false) {
      ErrorToast(
        t("others.The participant has been cancelled.Can't be share the rallys"),
      );
      return;
    }
    selectedList = rally.map((value: any) => {
      if (value) {
        return value._id;
      }
      return null;
    });
    selectedList = selectedList.reduce((key, value) => ({ ...key, [value]: true }), {});

    const deSelectedRows = rally;
    let selectedRows = list;
    const ids = new Set(deSelectedRows.map(({ _id }) => _id));
    selectedRows = selectedRows.filter(({ _id }) => !ids.has(_id));
    unSelectedList = selectedRows.map((value: any) => {
      if (value) {
        return value._id;
      }
      return null;
    });
    unSelectedList = unSelectedList.reduce(
      (key, value) => ({ ...key, [value]: false }),
      {},
    );
    data = {
      ...selectedList,
      ...unSelectedList,
    };
    if (Object.keys(selectedList).length === 0) {
      toast.error(t('Please select atleast one rally.'), {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    addUserRallies(eventId, item._id, data)
      .then(() => {
        setOpenPopup(false);
        SuccessToast(t('rally.rallyUpdate'));
      })
      .catch(() => {
        ErrorToast(t('others.Something went wrong'));
      });
  };
  return (
    <div className={classes.paper}>
      <form onSubmit={addRallyToParticipant}>
        <div style={{ display: 'flex' }}>
          <h3 id="transition-modal-description" className={classes.desc}>
            {t('General settings.Assign Participants To Rally')}
          </h3>
          <button type="submit" className={classes.saveButton}>
            {t('participant.Save')}
          </button>
          {/* <Icon icon="mdi:content-save-check" color="#0096fb" className={classes.saveIcon} /> */}
        </div>
        <div className={classes.name}>
          <p style={{ width: '200px' }}>{t('participant.Participant Name')} :</p>
          <p>
            {item.companyname ? item.companyname : `${item.lastName} ${item.firstName}`}
          </p>
        </div>
        <div className={classes.name}>
          <p style={{ width: '100px' }}>{t('participant.Select Rally')} :</p>
          <div className={classes.root}>
            <Multiselect
              options={list} // Options to display in the dropdown
              selectedValues={existingRally.filter((el) => el !== undefined)} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
        </div>
      </form>
    </div>
  );
}
function AddRallyWrapper({ item, eventId, status, eventStatus }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const modal = useModalDialog();

  const addRallyPopup = useMemo(() => modal(AddRallycomponent, 'addRally'), []);

  return (
    <div>
      <h4
        className={classes.count}
        onClick={() => {
          addRallyPopup(true, { item, eventId, status, eventStatus, overrideBody: true });
        }}
      >
        {t('others.View Rally')}
      </h4>
    </div>
  );
}
export default AddRallyWrapper;
