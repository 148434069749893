import { createContext } from 'react';
import {
  FormFieldTypeWithCoordinatesType,
  NewCoordinateType,
} from 'src/Containers/Events/Rally/utils';
import { SetStateSetterType } from 'src/Types/UseFormTypes';

export type DynamicFormContextType = {
  leftVisibleTabData: any;
  selected: any;
  addSelected: any;
  setSelected: any;
  added: any;
  setLeftVisibleTabData: any;
  addForm: any;
  setCoordinates: any;
  setAdded: any;
  coordinates: any;
  leftVisibleData: any;
  setLeftVisibleData: any;
  removed: any;
  setRemoved: any;
  addRemove: (index: string) => void;
  fullData: any;
  formType: any;
  setFormType: any;
  deleteCurrentItem: (index: string) => void;
  selectAllRight: any;
  setEditData: any;
  editData: any;
  label: any;
  showModal: any;
  onClose: any;
  setShowModal: any;
  setLabel: any;
  onCancel: any;
  changeLabel: any;
  changeHint: any;
  submitEdit: any;
  showImageModal: any;
  editFormField: any;
  setShowImageModal: any;
  generateLayout: any;
  changeDefaultValue: any;
  totalCountCheckbox: any;
  tableData: any;
  setTableData: any;
  headerData: any;
  setHeaderData: any;
  setAutoPickValues: any;
  setPinSelected: SetStateSetterType<FormFieldTypeWithCoordinatesType | null>;
  pinSelected: FormFieldTypeWithCoordinatesType | null;
  addSelectedItems: (group: NewCoordinateType[]) => void;
  setTableCellPopUp: any;
  tableCellPopUp: boolean;
  isDraggable: boolean;
  setIsDraggable: SetStateSetterType<boolean>;
};
const DynamicFormContext = createContext({} as DynamicFormContextType);

export default DynamicFormContext;
