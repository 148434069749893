enum EnumFormFieldAutoPick {
  organiser_name = 'organiser_name',
  organiser_logo = 'organiser_logo',
  person_organiser_title = 'person_organiser_title',
  person_organiser_first_name = 'person_organiser_first_name',
  person_organiser_last_name = 'person_organiser_last_name',
  person_organiser_company = 'person_organiser_company',
  person_organiser_email = 'person_organiser_email',
  person_organiser_mobile = 'person_organiser_mobile',
  person_organiser_Picture = 'person_organiser_Picture',
  pilot_title = 'pilot_title',
  pilot_name = 'pilot_name',
  pilot_person_lastname = 'pilot_person_lastname',
  pilot_company = 'pilot_company',
  pilot_address = 'pilot_address',
  pilot_address_2 = 'pilot_address_2',
  pilot_postal_code = 'pilot_postal_code',
  pilot_city = 'pilot_city',
  pilot_country = 'pilot_country',
  pilot_mobile = 'pilot_mobile',
  pilot_fixed = 'pilot_fixed',
  pilot_email = 'pilot_email',
  pilot_web = 'pilot_web',
  pilot_Picture = 'pilot_Picture',
  pilot_emergency_phone = 'pilot_emergency_phone',
  pilot_emergency_email = 'pilot_emergency_email',
  rally_event_name = 'rally_event_name',
  rally_name = 'rally_name',
  rally_logo = 'rally_logo',
  rally_venue = 'rally_venue',
  rally_start_date = 'rally_start_date',
  rally_end_date = 'rally_end_date',
  rally_category = 'rally_category',
  Event_Name = 'event_name',
  Event_Category = 'Event_Category',
  Event_Logo = 'event_image',
  Event_Venue = 'Event_Venue',
  Event_Start_Date = 'Start_Date',
  Event_End_Date = 'End_Date',
  Event_Registration_Last_Date = 'Registration_Last_Date',
  Enquiry_Phone = 'enquiry_phone',
  Enquiry_Email = 'enquiry_email',
  Form_Name = 'form_name',
}
export default EnumFormFieldAutoPick;
