import React, { useCallback, useState, useRef, useContext } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { Typography, Grid } from '@material-ui/core';
// eslint-disable-next-line import/no-duplicates
import { Layout } from 'react-grid-layout';
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { dynamicFormConfig } from 'src/Libs/DynamicForm/config/dynamicFormConfig';
import DynamicFormBuilder from 'src/Libs/DynamicForm/DynamicFormBuilder';
import { FormFieldType } from 'src/Types/FormFieldType';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { useTranslation } from 'react-i18next';
import useDynamicFormContextMenu from 'src/Hooks/useDynamicFormContextMenu';
import useDragAndSelect from 'src/Hooks/useDragAndSelect';
import {
  filterAddedFieldsByParentWidgetIndex,
  tableOnAddCell,
} from 'src/Components/DynamicTable/utils';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import useStyles from '../../../newEventFormStyle';
// react grid layout for dragging and resizing
import { CustomToolTip } from '../../../../../../Components/ToolTip/ToolTip';
import {
  fieldWrapperExtraStyles,
  getGenericDateValue,
  getTotalCountField,
} from '../util';
import CustomContextMenu from './CustomContextMenu';
import MouseSelection from './MouseSelection';
import FormSettings from './FormSettings';

// const ResponsiveGridLayout = WidthProvider(Responsive);

function RightForm({
  setCoordinates,
  added,
  searchForm,
  generateLayout,
  editFormField,
  removed,
  deleteCurrentItem,
  addRemove,
  coordinates,
  headerData,
  setHeaderData,
}) {
  const classes = useStyles();
  const { setAdded, fullData, setRemoved, isDraggable, setIsDraggable } =
    useContext(DynamicFormContext);
  const { t } = useTranslation();
  const [oldLayouts, setOldLayouts] = useState<Object[]>(coordinates);
  const [isDragging, setIsDragging] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const isCustomeLayoutChange = useRef(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { contextMenu, handleRightClick, handleOptionClick } = useDynamicFormContextMenu({
    elementRef,
  });
  const {
    isMouseDown,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    dragStartX,
    dragStartY,
    draglastX,
    draglastY,
  } = useDragAndSelect({ coordinates, elementRef });

  const onDragStart = useCallback(() => {
    setIsDragging(true);
    setOldLayouts(coordinates);
  }, [coordinates]);

  const onDragStop = async (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    if (isDragging && removed.length > 0) {
      setIsDragged(true);
      const yDiff = newItem.y - oldItem.y;
      const xDiff = newItem.x - oldItem.x;
      const selectedLayoutIds = removed.map((l: FormFieldType) => l.index);

      const newLayouts = layout
        .map((layoutItem) => {
          if (selectedLayoutIds.includes(layoutItem.i)) {
            if (layoutItem.i === newItem.i) {
              return { ...newItem };
            }
            const { x, y, w, h } = layoutItem;
            return { ...layoutItem, x: x + xDiff, y: y + yDiff, w, h };
          }
          return layoutItem;
        })
        .map((layoutItem) => {
          if (layoutItem.i === newItem.i) {
            return { ...newItem };
          }
          return layoutItem;
        });
      const outOfBounds = await newLayouts.find((l) => l.x < 0 || l.x >= 24 || l.y < 0);
      if (outOfBounds) {
        setCoordinates([]);
        setTimeout(() => {
          setCoordinates([...oldLayouts.map((o) => ({ ...o }))]);
        });
      } else {
        isCustomeLayoutChange.current = true;
        setCoordinates(newLayouts);
      }
    }
    setIsDragging(false);
  };
  // Some of the fieds having values assigned to different keys.
  // This function will fetch the exact values based on some conditions
  const getRightComponentValues = (data: FormFieldEditType): String => {
    switch (data.type) {
      case 'date':
        return getGenericDateValue(data);
      case 'totalField':
        return getTotalCountField(data, added);
      case 'checkbox':
        return data?.values[0] === 'checked' ? 'checked' : '';
      case 'textarea':
        return data?.values[0];
      case 'empty':
        return '';
      default:
        return data?.values[0];
    }
  };

  // This function renders each fields for the dynamicForm
  const renderFields = (data: FormFieldEditType, mode: 'right' | 'left' | 'preview') => {
    const { RightComponent } = dynamicFormConfig[data.type];
    return (
      <div
        data-grid={generateLayout(data)}
        key={data.index}
        onDoubleClick={() => {
          editFormField(data);
        }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          width: '100%',
          zIndex: data.type === 'pin' ? 1000 : 'auto',
          ...fieldWrapperExtraStyles(data.styles, data.type),
        }}
      >
        <div className={data.type === 'table' ? '' : classes.formFieldMenu}>
          {removed.includes(data) && (
            <div className={data.type === 'table' ? '' : classes.selectedField}>
              {data.type !== 'line' && data.type !== 'pin' && (
                <Icon
                  icon="feather:edit-3"
                  className={classes.editIcon}
                  onClick={() => {
                    editFormField(data);
                  }}
                />
              )}
              {data.type !== 'empty' && (
                <div
                  className={classes.deleteIcon}
                  onClick={() => deleteCurrentItem(data.index)}
                >
                  <DeleteIcon />
                </div>
              )}
              {data.type === 'table' && (
                <div
                  className={classes.deleteIcon}
                  onClick={() => tableOnAddCell(data.index, added, setAdded, fullData)}
                >
                  <Icon icon="feather:plus" />
                </div>
              )}
            </div>
          )}
        </div>
        <CustomToolTip
          title={
            <div style={{ width: '100%' }}>
              <Typography color="inherit">
                {' '}
                {t(`formFields.Category`)} - {data.category}
              </Typography>
              <Typography color="inherit">
                {t(`formFields.Id`)} - {data.key}
              </Typography>
            </div>
          }
          arrow
          key={data.index}
        >
          <Grid
            item
            className={clsx([
              `grid-item-${data.type}-custom-class`,
              removed.includes(data)
                ? `${classes.selected} ${classes.inputlabel}`
                : classes.inputlabel,
            ])}
            onMouseUp={() => (isDragged ? '' : addRemove(data.index))}
            onMouseDown={() => setIsDragged(false)}
          >
            <RightComponent
              fieldData={data}
              mode={mode}
              aditionalProps={{
                values: getRightComponentValues(data),
                disableResizeHandler: false,
                showTable: true,
              }}
            />
          </Grid>
        </CustomToolTip>
      </div>
    );
  };

  const addedFields = (mode: 'right' | 'left' | 'preview') => {
    if (searchForm.length === 0) {
      return filterAddedFieldsByParentWidgetIndex(added).map((data: FormFieldEditType) =>
        renderFields(data, mode),
      );
    }
    const filteredArray = filterAddedFieldsByParentWidgetIndex(added).filter((val) =>
      val.label.toLowerCase().includes(searchForm.toLowerCase()),
    );
    return filteredArray.map((data: FormFieldEditType) => renderFields(data, mode));
  };

  return (
    <div>
      <div
        className={classes.rightFormWrapper}
        onContextMenu={handleRightClick}
        ref={elementRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {contextMenu && (
          <CustomContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            handleOptionClick={handleOptionClick}
            contextMenu={contextMenu}
          />
        )}
        {isMouseDown && (
          <MouseSelection x={dragStartX} y={dragStartY} x2={draglastX} y2={draglastY} />
        )}
        <DynamicFormBuilder
          isCustomeLayoutChange={isCustomeLayoutChange}
          isDraggable={isDraggable}
          mode="right"
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          searchForm={searchForm}
          setIsDraggable={setIsDraggable}
        >
          {addedFields('right')}
        </DynamicFormBuilder>
      </div>
      <FormSettings
        isDraggable={isDraggable}
        setIsDraggable={setIsDraggable}
        headerData={headerData}
        setHeaderData={setHeaderData}
        setRemoved={setRemoved}
      />
    </div>
  );
}

export default RightForm;
