/* eslint-disable react/jsx-props-no-spreading */
import useStyles from './style';
import {
  headingTypes,
  boldTypes,
  placeholder,
  fieldsHavingTextAlign,
  textAlignTypes,
  ColorType,
} from '../util';
import { ColorPicker, StyleSelectorDropDown, MultySelectorDropDown } from './components';
import useTextEditor from './hooks/useTextEditor';
import SliderController from './components/SliderController';

function TextEditor(props) {
  const { editData, setEditData } = props;
  const {
    handleClick,
    handleClose,
    onHandleChangeColor,
    anchorEl,
    bgAnchorEl,
    borderAnchorEl,
    color,
    bgColor,
    borderColor,
    onDropDownChange,
    handleChangeBorder,
    borderNames,
    handleChangeSlider,
    borderRadius,
    borderWidth,
  } = useTextEditor({ editData, setEditData });
  const colorPickerProps = { handleClick, handleClose, onHandleChangeColor };
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <div className={classes.flex}>
        {editData.type !== 'table' && (
          <>
            <StyleSelectorDropDown
              options={headingTypes}
              handleChange={onDropDownChange}
              placeholder="Font size"
              value={placeholder({
                defaultValue: 'Font size',
                data: editData.styles?.fontSize,
                array: headingTypes,
              })}
            />
            <StyleSelectorDropDown
              options={boldTypes}
              handleChange={onDropDownChange}
              placeholder="Font weight"
              value={editData.styles?.fontWeight}
            />
          </>
        )}
        {fieldsHavingTextAlign.includes(editData.type) && (
          <StyleSelectorDropDown
            options={textAlignTypes}
            handleChange={onDropDownChange}
            placeholder="Text align"
            value={editData.styles?.textAlign}
          />
        )}
      </div>
      <div className={classes.flex}>
        {editData.type !== 'table' && (
          <ColorPicker
            {...colorPickerProps}
            anchorEl={anchorEl}
            title={ColorType.textColor}
            color={editData.styles?.color || color}
          />
        )}
        <ColorPicker
          {...colorPickerProps}
          anchorEl={bgAnchorEl}
          title={ColorType.bgColor}
          color={editData.styles?.backgroundColor || bgColor}
        />
        <ColorPicker
          {...colorPickerProps}
          anchorEl={borderAnchorEl}
          title={ColorType.borderColor}
          color={editData.styles?.borderColor || borderColor}
        />
        {editData.type !== 'table' && (
          <MultySelectorDropDown
            handleChangeBorder={handleChangeBorder}
            borderNames={borderNames}
          />
        )}
      </div>
      <div className={classes.flex}>
        {editData.type !== 'table' ? (
          <SliderController
            controllerName="Border Radius"
            handleChangeSlider={(event: any, newRadius: number | number[]) =>
              handleChangeSlider('Border Radius', newRadius)
            }
            value={borderRadius}
            max={fieldsHavingTextAlign.includes(editData.type) ? 100 : 10}
          />
        ) : (
          <SliderController
            controllerName="Border Width"
            handleChangeSlider={(event: any, newRadius: number | number[]) =>
              handleChangeSlider('Border Width', newRadius)
            }
            value={borderWidth}
            max={10}
          />
        )}
      </div>
    </div>
  );
}

export default TextEditor;
