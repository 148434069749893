import React from 'react';
import { CustomToolTip } from 'src/Components/ToolTip/ToolTip';
import useStyles from './styles';

function EventCards({
  details,
  img,
  name = '',
  toolTip = false,
}: {
  details: any;
  img: string;
  // eslint-disable-next-line react/require-default-props
  name?: string;
  toolTip?: boolean;
}) {
  const classes = useStyles();
  return (
    <div>
      {details !== 0 && !!details && (
        <>
          <img src={img} alt="" />
          {toolTip && details.length > 20 ? (
            <CustomToolTip title={details}>
              <div className={classes.eventStatusTextData}>
                {details} {name}
              </div>
            </CustomToolTip>
          ) : (
            <div className={classes.eventStatusTextData}>
              {details} {name}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EventCards;
