import React from 'react';

import fieldStyles from '../FieldStyle';
import { generatestyle } from '../util';

function FieldLabel(props) {
  const classes = fieldStyles();
  const { styles, name, deafultClassName, type } = props;
  const newStyle = generatestyle(styles);
  switch (type) {
    case 'paragraph':
      return (
        <p style={newStyle} className={deafultClassName || classes.fieldLabel}>
          {name}
        </p>
      );
    case 'heading':
      return (
        <h1 style={newStyle} className={classes.commonHeadingStyle}>
          {name}
        </h1>
      );
    case 'table':
      return (
        <h1 style={newStyle} className={classes.commonHeadingStyle}>
          {name}
        </h1>
      );
    // return (
    //   <h2 style={newStyle} className={deafultClassName ? deafultClassName : classes.fieldLabel}>
    //     {name}
    //   </h2>
    // );
    default:
      return (
        <p style={newStyle} className={deafultClassName || classes.fieldLabel}>
          {name}
        </p>
      );
  }
}

export default FieldLabel;
