import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-named-as-default
import DocButton from 'src/Components/DocDownload/DocButton';
import { onSubmitPdf } from 'src/Components/DocDownload/util';
import CustomTable from 'src/Components/Table/Table';
import TableActionButton from 'src/Components/Table/TableActionButton';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import {
  TablereportListing,
  deleteTableReports,
} from 'src/Services/Auth/Actions/UserActions';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    width: '115px',
    justifyContent: 'space-between',
  },
}));

function TableReportslisting() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const confirm: any = useConfirm();

  const deleteReportById = (id, reload) => {
    confirm({
      description: t('forms.Form Delete Warning'),
    }).then(() => {
      deleteTableReports(id).then(() => {
        toast.success(t('eventForm.formDeleted'), {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };

  const reload = ({ sort, page, rowsPerPage, search }) =>
    TablereportListing({
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
    }).then((response) => {
      const { data } = response;
      const totalCount = response.headers['x-total-count'];
      return {
        data,
        totalCount,
      };
    });

  const pdfDownload = (row, i) => {
    setIsLoading(i);
    const formId = row.data.formId ? [...row.data.formId] : [];
    onSubmitPdf(
      row.data.destinationColumn,
      row.data.sort,
      row.data.sortBy,
      row.data.resource,
      row.data.exportDocType,
      row.data.eventId,
      formId,
      row.data.organizerId,
      setIsLoading,
      row.data.rallyId,
      row.data.orientation,
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  function EditDoc(row, reload) {
    return (
      <DocButton
        name={t('events.Download')}
        // eslint-disable-next-line react/destructuring-assignment
        resource={row?.data?.resource}
        // eslint-disable-next-line react/destructuring-assignment
        eventId={row?.data?.eventId}
        // eslint-disable-next-line react/destructuring-assignment
        rallyId={row?.data?.rallyId}
        // eslint-disable-next-line react/destructuring-assignment
        initialSortApi={row?.data}
        className="report"
        // eslint-disable-next-line react/destructuring-assignment
        id={row?.id}
        // eslint-disable-next-line react/destructuring-assignment
        reportName={row?.name}
        reload={reload}
      />
    );
  }

  const columns = [
    {
      title: t('others.Report Name'),
      field: 'name',
      data: (row) => row.name,
      isSortable: true,
    },
    {
      title: t('others.Resource Name'),
      field: 'resourceName',
      data: (row) => row?.data?.resource,
      isSortable: false,
    },

    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
      data: (row, reload, index) => (
        <div className={classes.actions}>
          {EditDoc(row, reload)}
          <TableActionButton
            button={[
              {
                icon:
                  isLoading === index
                    ? 'line-md:loading-twotone-loop'
                    : 'ic:baseline-download',
                onClick: () => isLoading !== index && pdfDownload(row, index),
                class: '',
              },
              {
                icon: 'ant-design:delete-twotone',
                onClick: () => deleteReportById(row.id, reload),
                class: '',
              },
            ]}
          />
        </div>
      ),

      isSortable: false,
    },
  ];
  return (
    <div>
      <CustomTable
        columns={columns}
        heading={t('others.Table Reports Listing')}
        tableName="tablereports"
        reload={reload}
        isNest
      />
    </div>
  );
}

export default TableReportslisting;
