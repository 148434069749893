const initialState = {
  language: {},
};

export default function languageReducer(state = initialState, action?) {
  switch (action.type) {
    case 'CHANGE_LANG': {
      return action.payload;
    }

    default:
      return state;
  }
}
