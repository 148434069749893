/* eslint-disable react/no-unstable-nested-components */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CustomTable from 'src/Components/Table/Table';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import {
  deleteSingleFormByEventId,
  getAllForms,
} from 'src/Services/Auth/Actions/EventActions';
import { RootState } from 'src/Store';
import TableActionButton from 'src/Components/Table/TableActionButton';
import { useHistory } from 'react-router';
import { formateDateWithoutTime } from 'src/Services/Utils';
import useStyles from './FormPopupStyle';

function FormsListing() {
  const userRole = useSelector((state: RootState) => state.role.role);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const confirm: any = useConfirm();

  const deleteFormById = (eventId, formId, reload) => {
    confirm({
      description: t('forms.Form Delete Warning'),
    }).then(() => {
      deleteSingleFormByEventId(eventId, formId).then(() => {
        toast.success(t('eventForm.formDeleted'), {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };

  const columns = [
    {
      title: t('forms.Form'),
      field: 'formName',
      data: (row) => row.formName,
      isSortable: true,
    },
    {
      title: t('events.Event'),
      field: 'eventName',
      data: (row) => row.eventName,
      isSortable: true,
    },
    {
      title: t('menuItems.Rally'),
      field: 'rallyName',
      data: (row) => row.rallyName,
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      data: (row) => <>{formateDateWithoutTime(row.createdAt)}</>,
      isSortable: true,
    },
    {
      title: t('formFields.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row: { eventId: string; rallyId: string; _id: string }, reload) => (
        <TableActionButton
          button={[
            {
              icon: 'feather:edit-3',
              onClick: () =>
                history.push({
                  pathname: `/events/forms/editForm/${row.eventId}/${row._id}?from=forms`,
                  state: { showGoBack: true, rallyID: row.rallyId },
                }),
              class: '',
            },
            {
              icon: 'ant-design:delete-twotone',
              onClick: () => deleteFormById(row.eventId, row._id, reload),
              class: '',
            },
          ]}
        />
      ),
      isSortable: false,
    },
  ];

  const reload = ({ sort, page, rowsPerPage, search }) =>
    getAllForms({
      role: userRole,
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
    }).then((response) => {
      const data = response.data.result?.data;
      const totalCount = response.data.result.totalCount || response.data.result.count;
      return {
        data,
        totalCount,
      };
    });

  return (
    <div>
      <CustomTable
        tableName="rallyType"
        columns={columns}
        reload={reload}
        heading={t('forms.Form Listing')}
        onDoubleClick={(item) =>
          history.push({
            pathname: `/events/forms/editForm/${item.eventId}/${item._id}?from=forms`,
            state: { showGoBack: true, rallyID: item.rallyId },
          })
        }
      />
    </div>
  );
}

export default FormsListing;
