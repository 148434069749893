import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import typography from 'src/Theme/typography';
import PasswordField from 'src/Components/PasswordField/PasswordField';
import { addNewOrganizer } from '../../Services/Auth/Actions/OrganizerActions';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import Header from '../../Components/Header/Header';
import { commonResponseHandler } from '../../Services/Utils';
import AvatarUpload from '../../Components/AvatarUpload/AvatarUpload';
import {
  FormLabel,
  FormButton,
  ErrorMessageCustom,
  SubTitle,
} from '../../Components/Utils';
import { validator } from '../../Services/Auth/Validations';

const useStyles = makeStyles(() => ({
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },

  btnWrap: {
    textAlign: 'left',
  },
  btn: {
    float: 'left',
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  avatar: {
    width: '50px',
    height: '50px',
  },
  wrapper: {
    backgroundColor: '#fff',
    padding: '20px 60px 40px',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:650px)': {
      padding: '20px 20px 20px',
      margin: '30px 0px 20px',
    },
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function AddNewOrganizer() {
  const { t } = useTranslation();
  const AddNewOrganiserSchema = Yup.object().shape({
    organizerType: Yup.string().required('validationErrors.Type is Required'),
    companyname: Yup.string().required('validationErrors.Company name is Required'),
    username: validator.USERNAME,
    password: validator.PASSWORD,
    email: Yup.string().email('validationErrors.Invalid email').lowercase(),
    phone: validator.PHONE,
    address1: validator.ADDRESS1,
    street: validator.STREET,
    city: validator.CITY,
    state: validator.STATE,
    country: validator.COUNTRY,
    postcode: validator.POSTCODE,
    facebookUrl: validator.FACEBOOKURL,
    instagramUrl: validator.INSTAGRAMURL,
    websiteUrl: validator.WEBSITEURL,
  });

  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState({ binary: '', fileName: '' });

  const initialValues = {
    organizerType: 'company',
    companyname: '',
    username: '',
    password: '',
    email: '',
    phone: '',
    city: '',
    street: '',
    country: '',
    postcode: '',
    webciteUrl: '',
    instagramUrl: '',
    facebookUrl: '',
    state: '',
    address1: '',
  };

  const submitForm = (values, setSubmitting) => {
    const data: any = {
      ...values,
    };
    if (!data.email) {
      delete data.email;
    }
    if (!data.phone) {
      delete data.phone;
    }
    if (data.username) {
      data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
    }
    if (data.email) {
      data.email = data.email.toLowerCase();
    }
    const formData = new FormData();
    if (image.binary) {
      formData.append('file', image.binary, image.fileName);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
    commonResponseHandler(
      addNewOrganizer(formData),
      () => history.push('/organizers'),
      () => setSubmitting(false),
    );
  };

  return (
    <div>
      <Dashboard>
        <Header title={t('organizers.Organisers')} />
        <div className={classes.wrapper}>
          <Formik
            initialValues={initialValues}
            validationSchema={AddNewOrganiserSchema}
            onSubmit={(values: any, { setSubmitting }) => {
              submitForm(values, setSubmitting);
            }}
          >
            {({ isSubmitting, handleChange, values, errors, touched }) => (
              <Form>
                <div className={classes.topWrap}>
                  <SubTitle title={t('organizers.Add new Organiser')} />
                  <FormButton name={t('forms.Submit')} isLoading={isSubmitting} />
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <AvatarUpload image={image.binary} setImage={setImage} />
                      <ErrorMessageCustom
                        errors={errors}
                        field="image"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Company Name')} require />
                      <Field
                        className={classes.input}
                        text="companyname"
                        type="text"
                        name="companyname"
                        value={values.companyname}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="companyname"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Username')} require />
                      <Field
                        className={classes.input}
                        text="username"
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="username"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Password')} require />
                      <Field
                        className={classes.input}
                        text="password"
                        component={PasswordField}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="password"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Email')} />
                      <Field
                        className={classes.input}
                        text="email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="email"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Phone')} />
                      <Field
                        className={classes.input}
                        text="phone"
                        type="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="phone"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Address Line 1')} />
                      <Field
                        className={classes.input}
                        text="address1"
                        type="text"
                        name="address1"
                        value={values.address1}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="address1"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Street')} />
                      <Field
                        className={classes.input}
                        text="street"
                        type="text"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="street"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Postcode')} />
                      <Field
                        className={classes.input}
                        text="postcode"
                        type="text"
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="postcode"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.City')} />
                      <Field
                        className={classes.input}
                        text="city"
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="city"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.State')} />
                      <Field
                        className={classes.input}
                        text="state"
                        type="text"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="state"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Country')} />
                      <Field
                        className={classes.input}
                        text="country"
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="country"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Facebook URL')} />
                      <Field
                        className={classes.input}
                        text="facebookUrl"
                        type="text"
                        name="facebookUrl"
                        value={values.facebookUrl}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="facebookUrl"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Instagram URL')} />
                      <Field
                        className={classes.input}
                        text="instagramUrl"
                        type="text"
                        name="instagramUrl"
                        value={values.instagramUrl}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="instagramUrl"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormLabel name={t('forms.Website URL')} />
                      <Field
                        className={classes.input}
                        text="webciteUrl"
                        type="text"
                        name="webciteUrl"
                        value={values.webciteUrl}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="webciteUrl"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Dashboard>
    </div>
  );
}
export default connect(null, { addNewOrganizer })(AddNewOrganizer);
