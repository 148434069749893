import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { lowercaseValue } from 'src/Containers/Events/EventParticipants/utils';
import PasswordField from 'src/Components/PasswordField/PasswordField';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import Header from '../../Components/Header/Header';
import { createParticipant } from '../../Services/Auth/Actions/UserActions';
import AvatarUpload from '../../Components/AvatarUpload/AvatarUpload';
import {
  FormLabel,
  ErrorMessageCustom,
  SubTitle,
  FormButton,
} from '../../Components/Utils';
import { validator } from '../../Services/Auth/Validations';
import { typography } from '../../Theme';

const useStyles = makeStyles(() => ({
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },

  btnWrap: {
    textAlign: 'left',
  },
  saveButton: {
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    position: 'absolute',
    right: '0px',
  },
  avatar: {
    width: '50px',
    height: '50px',
  },
  wrapper: {
    backgroundColor: '#fff',
    padding: '20px 60px 40px',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
  },
  topWrap: {
    display: 'flex',
    position: 'relative',
  },
}));
function AddNewUsers() {
  const { t } = useTranslation();
  const AddNewUserSchema = Yup.object().shape({
    firstName: validator.FIRSTNAME,

    lastName: validator.LASTNAME,

    username: validator.USERNAME,

    email: validator.EMAIL,

    password: validator.PASSWORD,

    address1: validator.ADDRESS1,
    street: validator.STREET,
    city: validator.CITY,
    state: validator.STATE,
    country: validator.COUNTRY,
    postcode: validator.POSTCODE,
    facebookUrl: validator.FACEBOOKURL,
    instagramUrl: validator.INSTAGRAMURL,
    phone: validator.PHONE,
  });

  const classes = useStyles();
  const history = useHistory();
  const [image, setImage] = useState({ binary: '', fileName: '' });
  return (
    <div>
      <Dashboard>
        <Header title="Participants" />
        <div className={classes.wrapper}>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              username: '',
              email: '',
              password: '',
              phone: '',
              address1: '',
              city: '',
              street: '',
              state: '',
              country: '',
              postcode: '',
              facebookUrl: '',
              instagramUrl: '',
            }}
            validationSchema={AddNewUserSchema}
            onSubmit={(values, { setSubmitting }) => {
              const data: any = {
                ...values,
              };
              if (data.username) {
                data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
              }
              if (data.email) {
                data.email = data.email.toLowerCase();
              }
              const formData = new FormData();

              if (image.binary) {
                formData.append('file', image.binary, image.fileName);
              }
              Object.keys(data).map((k) => formData.append(k, data[k]));
              createParticipant(formData)
                .then((response) => {
                  history.push('/users');
                  toast.success(response.data.message, {
                    position: 'top-center',
                    autoClose: 5000,
                  });
                  setSubmitting(false);
                })
                .catch((error) => {
                  toast.error(error.response.data.message, {
                    position: 'top-center',
                    autoClose: 5000,
                  });
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, setFieldValue, handleChange, values, errors, touched }) => (
              <Form>
                <div className={classes.topWrap}>
                  <SubTitle title={t('users.Add New Participants')} />
                  <FormButton
                    className={classes.saveButton}
                    name={t('forms.Submit')}
                    isLoading={isSubmitting}
                  />
                  <div />
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                      <AvatarUpload image={image.binary} setImage={setImage} />
                      <ErrorMessageCustom
                        errors={errors}
                        field="image"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.First Name')} require />
                      <Field
                        className={classes.input}
                        text="firstName"
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="firstName"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Last Name')} />
                      <Field
                        className={classes.input}
                        text="lastName"
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="lastName"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Username')} require />
                      <Field
                        className={classes.input}
                        text="username"
                        type="text"
                        name="username"
                        value={values.username.trim().toLowerCase().replace(/ +/g, '')}
                        onChange={(e) => {
                          const lowercase = lowercaseValue(e.target.value);
                          handleChange(setFieldValue('username', lowercase));
                        }}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="username"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Password')} require />
                      <Field
                        className={classes.input}
                        text="password"
                        component={PasswordField}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="password"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Email')} />
                      <Field
                        className={classes.input}
                        text="email"
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="email"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Phone')} />
                      <Field
                        className={classes.input}
                        text="phone"
                        type="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="phone"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Address Line 1')} />
                      <Field
                        className={classes.input}
                        text="address1"
                        type="text"
                        name="address1"
                        value={values.address1}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="address1"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Street')} />
                      <Field
                        className={classes.input}
                        text="street"
                        type="text"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="street"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Postcode')} />
                      <Field
                        className={classes.input}
                        text="postcode"
                        type="text"
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="postcode"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.City')} />
                      <Field
                        className={classes.input}
                        text="city"
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="city"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.State')} />
                      <Field
                        className={classes.input}
                        text="state"
                        type="text"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="state"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Country')} />
                      <Field
                        className={classes.input}
                        text="country"
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="country"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Facebook URL')} />
                      <Field
                        className={classes.input}
                        text="facebookUrl"
                        type="text"
                        name="facebookUrl"
                        value={values.facebookUrl}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="facebookUrl"
                        touched={touched}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <FormLabel name={t('forms.Instagram URL')} />
                      <Field
                        className={classes.input}
                        text="instagramUrl"
                        type="text"
                        name="instagramUrl"
                        value={values.instagramUrl}
                        onChange={handleChange}
                      />
                      <ErrorMessageCustom
                        errors={errors}
                        field="instagramUrl"
                        touched={touched}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </div>
      </Dashboard>
    </div>
  );
}
export default AddNewUsers;
// export default connect(null,{addNewOrganizer})(AddNewOrganizer)
