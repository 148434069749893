/* eslint-disable no-unused-expressions */
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import TableActionButton from 'src/Components/Table/TableActionButton';
import CustomTable, {
  TopActionButtonPopupType,
  TopActionLinkedButtonType,
  TopActionNormalButtonType,
} from 'src/Components/Table/Table';
import { toast } from 'react-toastify';
import { RootState } from 'src/Store';
import { useSelector } from 'react-redux';
import topActions from 'src/Types/Enums/EnumTopActions';
import { EventType } from 'src/Types/EventType';
import DragAndDrop from 'src/Components/DragAndDrop/DragAndDrop';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import {
  axiosResponseToFileDownload,
  createErrorString,
  createFormDataFromFile,
} from 'src/Services/Utils';
import useApiCall from 'src/Hooks/useApiCall';
import { fileResource } from 'src/Components/DocDownload/util';
import useFetchExportDetails from 'src/Components/DocDownload/hooks/useFetchExportDetails';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import WarningToast from 'src/Components/Utils/WarningToast';
import { useState } from 'react';
import useStyles from './OrganizerListingStyle';
import AVATAR from './compregLogo.png';
import {
  organizerListingSort,
  blockOrganizer,
  sampleOrganiserCSV,
  importOrganiserCSV,
} from '../../../Services/Auth/Actions/OrganizerActions';
import OrganizerDelete from './OrganizerDelete';

type TopActionButtonType =
  | TopActionLinkedButtonType
  | TopActionButtonPopupType
  | TopActionNormalButtonType;

function OrganizerListing() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const role = useSelector((store: RootState) => store.role.role);

  const confirm: any = useConfirm();

  const isActiveClick = (organizer, reload) => {
    confirm({
      description: t('popup.Are you sure you want to change the block status?'),
    }).then(() => {
      blockOrganizer(organizer._id, { isActive: !organizer.isActive }).then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };
  const [reloadTable, setReloadTable] = useState(0);
  const lastLogin = (row) => {
    if (row.organizer.lastLoggedIn) {
      return (
        <>
          <p className={classes.grey}>
            {Moment(row.organizer.lastLoggedIn).format('hh:mm a')}
          </p>
          <p className={classes.grey}>
            {Moment(row.organizer.lastLoggedIn).format('DD.MM.YYYY')}
          </p>
        </>
      );
    }
    return <>{t('organizers.Never')}</>;
  };

  const blockIcon = (row) => {
    if (row.organizer.isActive === true) {
      return 'ic:round-block';
    }
    return 'gg:unblock';
  };

  const iconClass = (row) => {
    if (row.organizer.isActive === true) {
      return classes.blockIcon;
    }
    return classes.unblockIcon;
  };

  const columns = [
    {
      title: t('organizers.Company/Full Name'),
      field: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <div className={classes.imgWrap}>
              <img
                src={row.organizer.profileImage || AVATAR}
                className={classes.img}
                alt="avatar"
              />
            </div>
            <div style={{ width: '170px' }}>
              <div className={classes.tbodyName}>
                <div className={classes.orgName}>
                  {row.organizer.companyname ||
                    (row.organizer.firstName &&
                      `${row.organizer.lastName} ${row.organizer.firstName}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('Username'),
      field: 'username',
      data: (row) => row?.organizer?.username,
      isSortable: true,
    },
    {
      title: t('events.Events'),
      field: 'events',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row) => (
        <>
          <div className={classes.tbodyName} style={{ fontWeight: 600 }}>
            {t('forms.Active')}: {row.activeEvents}
          </div>
          <p className={classes.grey}>
            {t('forms.Completed')}: {row.completedEvents}
          </p>
        </>
      ),
      isSortable: false,
    },
    {
      title: t('organizers.Contact'),
      field: 'emailSrt',
      data: (row) => row?.email,
      isSortable: true,
    },
    {
      title: t('forms.Last login'),
      field: 'login',
      data: (row) => lastLogin(row),
      isSortable: false,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row) => (
        <p className={classes.grey}>{Moment(row.createdAt).format('DD.MM.YYYY')}</p>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row: { organizer: any; _id: string; events: Array<EventType> }, reload) => (
        <div style={{ display: 'flex' }}>
          <TableActionButton
            button={[
              {
                icon: 'feather:edit-3',
                onClick: () =>
                  history.push({
                    pathname: `organizers/details/${row._id}/profile`,
                    state: { showGoBack: true },
                  }),
                class: '',
              },
              {
                icon: blockIcon(row),
                onClick: () => isActiveClick(row.organizer, reload),
                class: iconClass(row),
              },
            ]}
          />
          <div>
            <OrganizerDelete
              reload={reload}
              id={row.organizer._id}
              eventCount={row.events?.length}
            />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];
  const [callApi, importLoader, data, error] = useApiCall();
  const exportDesignerData = {
    eventId: '',
    rallyId: '',
    resource: fileResource.organiser,
    organizerId: '',
    initialSortApi: '',
    id: '',
    reportName: '',
    reload: '',
  };
  const { handleClick } = useFetchExportDetails(
    exportDesignerData.eventId,
    exportDesignerData.rallyId,
    exportDesignerData.resource,
    exportDesignerData.organizerId,
    exportDesignerData.initialSortApi,
    exportDesignerData.id,
    exportDesignerData.reportName,
    exportDesignerData.reload,
  );
  const reload = ({ sort, page, rowsPerPage, search }) =>
    organizerListingSort({
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
    }).then((response) => {
      const data = response.data.organizers;
      const { totalCount } = response.data;
      return {
        data,
        totalCount,
      };
    });
  const topActionButton: TopActionButtonType[] = [
    {
      type: topActions.linkedButton,
      extraProps: {
        to: '/organizers/add-new',
        name: t('organizers.Add New'),
      },
    },
    {
      type: topActions.buttonPopup,
      extraProps: {
        label: 'others.Import CSV',
        loading: importLoader,
        component: DragAndDrop,
        onClick: async ({ selectedFile, setOpenPopup }) => {
          if (!selectedFile) {
            WarningToast(t('others.Please select a valid Excel file'));
            return;
          }
          const formData = createFormDataFromFile('file', selectedFile);
          await callApi(importOrganiserCSV({ formData }), {
            onSuccess: (response) => {
              if (response.data.error.length !== 0) {
                const error = createErrorString(response);
                ErrorToast(error);
              } else {
                setOpenPopup(false);
                setReloadTable(reloadTable + 1);
                SuccessToast(t('users.Organiser Imported Successfully'));
              }
            },
            onError: (error) => {
              ErrorToast(error || t('others.Something went wrong'));
            },
          });
        },
        sampleCsvHandleClick: () => {
          sampleOrganiserCSV()
            .then((response) => {
              axiosResponseToFileDownload({
                fileName: `Organiser list Sample.xlsx`,
                response,
              });
            })
            .catch(() => {
              ErrorToast(t('others.Something went wrong'));
            });
        },
        modalName: 'eventImport',
      },
    },
    {
      type: topActions.button,
      extraProps: {
        onClick: () => {
          handleClick();
        },
        name: t('events.Export'),
      },
    },
  ];

  return (
    <CustomTable
      tableName="organiser"
      columns={columns}
      reload={reload}
      heading={t('organizers.Organisers Listing')}
      onDoubleClick={(item) =>
        history.push({
          pathname: `organizers/details/${item._id}/profile`,
          state: { showGoBack: true },
        })
      }
      topActionButtons={topActionButton}
      role={role}
      forceReload={reloadTable.toString()}
    />
  );
}

export default OrganizerListing;
