import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AvatarUpload from 'src/Components/AvatarUpload/AvatarUpload';
import { toast } from 'react-toastify';
import TextField from 'src/Components/Formik/Fields/TextField';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';
import EnumSubmitActions from 'src/Types/Enums/EnumSubmitActions';
import { eventOrganizerType } from 'src/Types/eventOrganizerType';
import { FormButton, ErrorMessageCustom } from '../../../Components/Utils';
import {
  AddNewOrganizerContact,
  getOrganizerContact,
  updateOrganizerContact,
} from '../../../Services/Auth/Actions/OrganizerActions';
import { validator } from '../../../Services/Auth/Validations';
import theme from '../../../Theme';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:767px)': {
      padding: 0,
      margin: '30px 0px 20px',
    },
    position: 'relative',
  },
  wrapperModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 30px 15px',
    background: '#dfe0eb40',
    height: '100px',
  },
  closeIcons: {
    fontSize: '30px',
    cursor: 'pointer',
    color: '#bf2331',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  title: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
  box: {
    padding: '15px 30px 15px',
    height: '525px',
  },
}));

function OrganizerContactAddEdit({
  tittle,
  details,
  reload,
  setOpenPopup = () => {},
  submitAction,
  isModal = false,
}: {
  tittle: String;
  details: String;
  reload: (eventManager?: eventOrganizerType) => void;
  setOpenPopup?: (newState: boolean) => void;
  submitAction: String;
  isModal: Boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const OrganizerContactSchema = Yup.object().shape({
    firstName: validator.FIRSTNAME,
    lastName: validator.LASTNAME,
    email: validator.EMAIL,
    username: validator.USERNAME,
    phone: validator.PHONE,
    designation: validator.DESIGNATION,
  });
  const [image, setImage] = useState<{ binary: string | Blob; fileName: string }>({
    binary: '',
    fileName: '',
  });
  const [orgDetails, setOrgDetails] = useState<eventOrganizerType>();

  const fetchdata = () => {
    getOrganizerContact(details)
      .then((response) => {
        const data = response.data.EventOrganizer;
        setOrgDetails(data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (details && EnumSubmitActions.update === submitAction) {
      fetchdata();
    }
  }, [details]);

  const initialValues = {
    firstName: orgDetails?.firstName || '',
    lastName: orgDetails?.lastName || '',
    email: orgDetails?.email || '',
    username: orgDetails?.username || '',
    phone: orgDetails?.phone || '',
    designation: orgDetails?.designation || '',
    memo: orgDetails?.memo || '',
  };

  type EventOrganizerValuesType = {
    firstName: String;
    lastName: String;
    email: String;
    username: String;
    phone: String;
    designation: String;
    memo: String;
  };

  type setSubmittingType = (isSubmitting: boolean) => void;

  const submitForm = (
    values: EventOrganizerValuesType,
    setSubmitting: setSubmittingType,
  ) => {
    const data: any = {
      ...values,
      organizationId: details,
    };
    if (!details) {
      toast.error(t('toast.Please choose an Organizer'));
      return;
    }
    // if (!data.email) {
    //   delete data.email;
    // }
    // if (!data.phone) {
    //   delete data.phone;
    // }
    if (EnumSubmitActions.update === submitAction) {
      delete data.organizationId;
    }
    const formData = new FormData();
    if (image.binary) {
      formData.append('file', image.binary, image.fileName);
    }
    Object.keys(data).map((k) => formData.append(k, data[k]));
    if (EnumSubmitActions.create === submitAction) {
      AddNewOrganizerContact(formData)
        .then(async (response) => {
          SuccessToast(
            t(
              'toast.Organiser Contact created successfully, credentials will email to you',
            ),
          );
          setSubmitting(false);
          setOpenPopup(false);
          reload(response.data.eventManager);
        })
        .catch((error) => {
          ErrorToast(
            t(`toast.${error.response.data.message || error.response.data.error}`),
          );
          setSubmitting(false);
        });
    }
    if (EnumSubmitActions.update === submitAction) {
      updateOrganizerContact(orgDetails?._id, formData)
        .then(() => {
          SuccessToast(t('toast.Organiser Contact updated successfully'));
          setSubmitting(false);
          reload();
          setOpenPopup(false);
        })
        .catch((error) => {
          ErrorToast(
            t(`toast.${error.response.data.message || error.response.data.error}`),
          );
          setSubmitting(false);
        });
    }
  };

  return (
    <div className={isModal ? '' : classes.paper}>
      <Formik
        initialValues={initialValues}
        validationSchema={OrganizerContactSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values, setSubmitting);
        }}
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form>
            <div className={isModal ? classes.wrapperModal : classes.wrapper}>
              <h2 id="transition-modal-title" className={isModal ? '' : classes.title}>
                {t(`organizers.${tittle}`)}
              </h2>
              <FormButton
                name={t('forms.Submit')}
                type="submit"
                isLoading={isSubmitting}
              />
            </div>
            <Box sx={{ flexGrow: 1 }} className={isModal ? '' : classes.box}>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <AvatarUpload
                    image={image.binary}
                    setImage={setImage}
                    defaultImage={orgDetails?.profileImage}
                  />
                  <ErrorMessageCustom errors={errors} field="image" touched={touched} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="forms.First Name"
                    name="firstName"
                    value={values.firstName}
                    type="text"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    require
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="forms.Last Name"
                    name="lastName"
                    value={values.lastName}
                    type="text"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="forms.Email"
                    name="email"
                    value={values.email}
                    type="text"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="signUp.Username"
                    name="username"
                    value={values.username}
                    type="text"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    require
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="forms.Phone"
                    name="phone"
                    value={values.phone}
                    type="phone"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="forms.Designation"
                    name="designation"
                    value={values.designation}
                    type="text"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="forms.Memo"
                    name="memo"
                    value={values.memo}
                    type="textarea"
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    component="textarea"
                    rows="4"
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OrganizerContactAddEdit;
