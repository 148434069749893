import { Icon } from '@iconify/react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import useStyles from './UsersListingStyle';

function UsersListingActions({ item }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Link to={`users/details/${item._id}/profile`}>
        <Icon icon="feather:edit-3" className={classes.editIcon} />
      </Link>

      {/* <div onClick={(pageName)=> {
    history.push({
      pathname: `/users/details/${item._id}/profile`,
      state: { showGoBack: true },
    });
  }
}
  ><Icon icon="feather:edit-3" className={classes.editIcon} />

  </div> */}
      <Link to={`users/details/${item._id}/events`}>
        <Icon icon="ic:outline-event-note" className={classes.editIcon} />
      </Link>
      {/* <div onClick={(pageName)=> {
    history.push({
      pathname: `/users/details/${item._id}/events`,
      state: { showGoBack: true },
    });
  }
}>
     <Icon icon="ic:outline-event-note" className={classes.editIcon} /> 
</div> */}
    </>
  );
}

export default UsersListingActions;
