import axios from 'axios';
import store from '../../../Store';
import { baseUrl } from '../../Constants';
import { sortingOptions } from '../../Utils';

export const getToken = () => {
  const { token } = store.store.getState().session;
  return token;
};

export const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

export const createNewRally = (credentials) =>
  axios.post(`${baseUrl}/api/rally/createRally`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const userEnrolledRallies = ({ eventId }) =>
  axios.get(`${baseUrl}/api/rally/user-enrolled-rallies/${eventId}`, getHeaders());

export const rallyEnroll = (credentials) =>
  axios.post(`${baseUrl}/api/rally/rally-enroll`, credentials, getHeaders());

export const cloneNewRally = (id, credentials) =>
  axios.post(`${baseUrl}/api/rally/rally-clone/${id}`, credentials, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const rallyListing = ({
  id,
  sortBy = 'order',
  sort = 'asc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(`${baseUrl}/api/rally/listRallies/${id}?${options}`, getHeaders());
};

export const authorizedUserRallyListing = ({
  id,
  sortBy = 'order',
  sort = 'asc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(
    `${baseUrl}/api/rally/listRallies/protected/${id}?${options}`,
    getHeaders(),
  );
};

export const participantsListing = ({
  eventId,
  rallyId,
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(
    `${baseUrl}/api/rally/rally-participants/${eventId}/${rallyId}?${options}`,
    getHeaders(),
  );
};

export const rallyListingSort = (
  { sortBy = 'rallyName', sort = 'asc' },
  role,
  search = '',
) => {
  const options = sortingOptions({ sortBy, sort, search });
  return axios.get(`${baseUrl}/api/event/list/${role}?${options}`, getHeaders());
};

export const deleteRally = (id) =>
  axios.delete(`${baseUrl}/api/rally/${id}`, getHeaders());

export const cancelRally = (id, credentials) =>
  axios.patch(`${baseUrl}/api/rally/cancelRally/${id}`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getRally = (id) => axios.get(`${baseUrl}/api/rally/${id}`, getHeaders());

export const updateRally = (id, credentials) =>
  axios.put(`${baseUrl}/api/rally/${id}`, credentials, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const blockEvent = (id, credentials) =>
  axios.patch(`${baseUrl}/api/event/${id}`, credentials, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });
export const rallyTypes = (organizer) =>
  axios.get(`${baseUrl}/api/rally/types/rally-types/${organizer}`, getHeaders());

export const getUserRallies = (eventId, userId) =>
  axios.get(`${baseUrl}/api/rally/user-rallies/${eventId}/${userId}`, getHeaders());

export const addUserRallies = (eventId, userId, data) =>
  axios.post(`${baseUrl}/api/rally/add-user/${eventId}/${userId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const downloadParticipentList = ({
  eventId,
  rallyId,
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(
    `${baseUrl}/api/rally/rally-participants-pdf/${eventId}/${rallyId}?${options}`,
    {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
};

export const downloadParticipentZip = ({
  eventId,
  rallyId,
  sortBy = 'createdAt',
  sort = 'desc',
  search = '',
  page = 0,
  rowsPerPage = 10,
}) => {
  const options = sortingOptions({ sortBy, sort, search, page, rowsPerPage });
  return axios.get(`${baseUrl}/api/rally/zip/${eventId}/${rallyId}?${options}`, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/zip',
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const FilterParticipantsByRally = ({
  id,
  rallyId,
  sort = 'asc',
  pageSize = 10,
  search = '',
  sortBy = 'firstName',
  page = 0,
  filter = '',
}) => {
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: pageSize || 10,
    filter,
  });
  return axios.get(
    `${baseUrl}/api/rally/rally-participants/${id}/${rallyId}?${options}`,
    getHeaders(),
  );
};

export const sampleRallyCSV = () =>
  // let data = {
  //     token: getToken(),
  // };
  // const formData = new FormData();
  // Object.keys(data).map(k => formData.append(k, data[k]));

  axios.get(`${baseUrl}/api/event/csv-sample/rally`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const exportRallyCSV = ({
  id,
  sortBy = 'order',
  sort = 'asc',
  search = '',
  page = 0,
}) => {
  const data: any = {
    token: getToken(),
  };
  const formData = new FormData();
  Object.keys(data).map((k) => formData.append(k, data[k]));
  const options = sortingOptions({
    sortBy,
    sort,
    search,
    page,
    rowsPerPage: 100,
  });
  return axios.post(`${baseUrl}/api/rally/csv-export/${id}?${options}`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
    },
  });
};

export const importRallyCSV = ({ formData, eventId, language }) =>
  axios.post(`${baseUrl}/api/rally/csv-import/${eventId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
      language,
    },
  });

export const sendUserRallyInvitation = (data) =>
  axios.post(`${baseUrl}/api/rally/rally-invite`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const rallyParticipantDelete = (rallyId, userId) =>
  axios.delete(`${baseUrl}/api/rally/remove/${userId}/${rallyId}`);

export const rallySortOrder = (data, eventId) =>
  axios.patch(`${baseUrl}/api/rally/rally-sort/${eventId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const importRallyParticipant = ({ eventId, rallyId, formData }) =>
  axios.post(`${baseUrl}/api/rally/csv-import-users/${eventId}/${rallyId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const sampleParticipantCSV = () =>
  axios.get(`${baseUrl}/api/event/csv-sample/participant`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'blob',
      Authorization: `Bearer ${getToken()}`,
    },
  });
// http://localhost:9000/api/rally/csv-export/61d58e13c3cced0023a6a996
