import { createContext } from 'react';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';

type CloneFormPreviewContextType = {
  cloneAdded: FormFieldEditType[];
};

const CloneFormPreviewContext = createContext({} as CloneFormPreviewContextType);

export default CloneFormPreviewContext;
