import { createTheme } from '@material-ui/core';
import typographyDefault from './typography';

export const typography = typographyDefault;

const theme = createTheme({
  palette: {
    primary: {
      light: '#fa4848',
      main: '#bf2331',
      dark: '#990a05',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: typographyDefault,
  // colors,
  // fonts: {
  //   primary: 'Montserrat',
  //   secondary: 'Mulish',
  // },
  // custom: {
  //   heading: {
  //     color: '#2148C0',
  //   },
  // },
});

export default theme;
