/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Multiselect from 'multiselect-react-dropdown';
import { get } from 'lodash';
import {
  FormLabel,
  FormButton,
  ErrorMessageCustom,
  CircularLoader,
} from '../../../Components/Utils';
import useStyles from './FormPopupStyle';
import { rallyListing } from '../../../Services/Auth/Actions/RallyActions';

interface FormValuesType {
  formName: string;
  rallys: string[];
}

interface RallyOptionType {
  label: string;
  value: string;
}

function FormPopup({ details, formFor, setOpenPopup }) {
  const { t } = useTranslation();
  const EventParticipantsSchema = Yup.object().shape({
    formName: Yup.string().trim().required(t('validationErrors.Form name is required')),
    rallys: Yup.array()
      .of(Yup.string().trim())
      .min(1, t('validationErrors.Rally name is Required'))
      .required(t('validationErrors.Rally name is Required')),
  });

  const classes = useStyles();
  const [rallyList, setRallyList] = useState<Array<RallyOptionType>>([]);
  const [selectedValues, setSelectedValues] = useState<Array<RallyOptionType>>([]);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const initialValues: FormValuesType = {
    formName: '',
    rallys: get(selectedValues, '[0].value')
      ? [get(selectedValues, '[0].value', '')]
      : [],
  };
  const [formHeading, setFormHeading] = useState(formFor);
  const [formApi, setFormApi] = useState('');

  useEffect(() => {
    if (formFor === 'Add New Form') {
      setFormHeading(formFor);
      setFormApi('createForm');
    } else {
      setFormHeading(formFor);
      setFormApi('cloneForm');
    }
    rallyListing({
      id: details,
      sortBy: 'createdAt',
      sort: 'desc',
      search: '',
      page: 0,
      rowsPerPage: 10,
    })
      .then((response) => {
        const rallyOptions = response.data.Rallies.filter(
          (rally) => rally.isCancelled === false,
        ).map((el) => ({
          label: el.name,
          value: el._id,
        }));
        setRallyList(rallyOptions);
        setSelectedValues(rallyOptions[0] ? [rallyOptions[0]] : []);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChange = (
    value: Array<RallyOptionType>,
    setFieldValue: (field: 'rallys', value: Array<string>) => void,
  ) => {
    setFieldValue(
      'rallys',
      value.map((ele: RallyOptionType) => ele.value),
    );
  };

  const onSubmit = (values: FormValuesType, setSubmitting) => {
    setSubmitting(true);
    setOpenPopup(false);
    history.push({
      pathname: `/events/forms/${formApi}/${details}`,
      state: { rallyID: values.rallys, formName: values.formName },
    });
  };
  return (
    <div className={classes.paper}>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <Icon
            icon="carbon:close-outline"
            onClick={() => setOpenPopup(false)}
            className={classes.closeIcons}
          />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={EventParticipantsSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              onSubmit(values, setSubmitting);
            }}
          >
            {({
              isSubmitting,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <Form>
                <div className={classes.headingWrapper}>
                  <h2 id="transition-modal-title" className={classes.title}>
                    {t(`addNewForm.${formHeading}`)}
                  </h2>
                  <FormButton name={t('forms.Submit')} disabled={isSubmitting} />
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <>
                    <Grid container spacing={3}>
                      <Grid item lg={12} xs={12}>
                        <FormLabel name={t('forms.Select a rally')} require />
                        <Multiselect
                          options={rallyList}
                          onRemove={(value) => {
                            onChange(value, setFieldValue);
                          }}
                          onSelect={(value) => {
                            onChange(value, setFieldValue);
                          }} // Function will trigger on remove event
                          displayValue="label" // Property name to display in the dropdown options
                          avoidHighlightFirstOption
                          selectedValues={selectedValues}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="rallys"
                          touched={touched}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item lg={12} xs={12}>
                        <FormLabel name={t('events.New form name')} require />
                        <Field
                          className={classes.input}
                          text="formName"
                          type="text"
                          name="formName"
                          value={values.formName}
                          onChange={handleChange}
                        />
                        <ErrorMessageCustom
                          errors={errors}
                          field="formName"
                          touched={touched}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

export default FormPopup;
