/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { FormFieldType } from 'src/Types/FormFieldType';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { TableData } from 'src/Libs/DynamicForm/components/FormField/table/types/table';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { useTranslation } from 'react-i18next';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { AutopickFormType } from 'src/Types/FormFieldAutoPickType';
import _ from 'lodash';
import {
  addEmptyFieldsToFormFields,
  filterItemsToRemove,
  tableFilterAddedEmptyFields,
} from 'src/Components/DynamicTable/utils';
import {
  FormFieldTypeWithCoordinatesType,
  findType,
  formFieldsWithInfiniteHeight,
  getCoordinates,
  getCoordinatesPin,
  isItemTypePresent,
  sortArray,
  NewCoordinateType,
  FIELD_HEGIHT,
} from './utils';
import DynamicormConstants from './DynamicormConstants';
import { getForms } from '../../../Services/Auth/Actions/EventActions';
//  for the fields
import EditPopup from './components/DynamicForm/FormPopups/EditPopup';
import ShowImagePopup from './components/DynamicForm/FormPopups/ShowImagePopup';
import { fillAutoPickedFormFieldValues } from './AutoPicKFormFieldValues';

/**
 * @function FormWrapper
 * @description Wrapper for the dynamic forms, contains the common functions and states
 * @param Object
 * @author Sam Manual Varghese
 */

// eslint-disable-next-line no-unused-vars
function FormWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  // This the list of all the available data on the left side filtered by the accordion title
  const [leftVisibleTabData, setLeftVisibleTabData] = React.useState<
    Array<FormFieldEditType>
  >([]);
  // This the list of all the available data on the left side
  const [leftVisibleData, setLeftVisibleData] = useState<Array<FormFieldEditType>>([]);
  const [selected, setSelected] = React.useState<Array<FormFieldEditType>>([]);
  const [pinSelected, setPinSelected] = useState<FormFieldTypeWithCoordinatesType | null>(
    null,
  );
  const [tableCellPopUp, setTableCellPopUp] = useState<boolean>(false);
  const [isDraggable, setIsDraggable] = useState<boolean>(true);
  const [coordinates, setCoordinates] = useState<Array<NewCoordinateType>>([]);
  const [added, setAdded] = React.useState<Array<FormFieldEditType>>([]);
  const [removed, setRemoved] = React.useState<Array<FormFieldEditType>>([]);
  const [fullData, setFullData] = useState<Array<FormFieldEditType>>([]);
  const [formType, setFormType] = React.useState('Pilot');
  const [label, setLabel] = useState('');
  const [editData, setEditData] = useState<FormFieldEditType>();
  const [showModal, setShowModal] = React.useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [headerData, setHeaderData] = useState(false);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<TableData>({
    rows: 2,
    cols: 2,
  });
  const [autoPickValues, setAutoPickValues] = useState<AutopickFormType>();
  const modal = useModalDialog();

  const setOpenFieldEditPopup = useMemo(
    () => modal(EditPopup, 'Edit-Popup-Modal'),
    [],
  ); /* Form field edit popup */
  const setOpenImageFieldEditPopup = useMemo(
    () => modal(ShowImagePopup, 'Show-Image-Popup'),
    [],
  ); /* show image edit modal when double click */

  const confirm: any = useConfirm();
  useEffect(() => {
    const loadFullData = async () => {
      const data = await getForms();
      const formDataArray = [
        ...(data.data?.Entrant || []),
        ...data.data.Pilot,
        ...data.data.Copilot,
        ...data.data.Car,
        ...data.data.Reco,
        ...data.data.Assistance,
        ...data.data.Other,
        ...(data.data.Customs || []),
        ...(data.data.FormHeader || []),
        ...(data.data?.Organiser || []),
        ...(data.data?.Rally || []),
        ...(data.data?.Participant || []),
      ];

      const formDataArraySorted = sortArray(formDataArray);
      setFullData(formDataArraySorted);
    };
    loadFullData();
    return () => {};
  }, []);
  /**
   * @function addForm
   * @description Add the formfield into the 'selected' array when you select them
   * @param {string} i index of the selected field
   * @author Sam Manual Varghese
   */
  const addForm = (i: String) => {
    let data;
    if (pinSelected) {
      data = fullData.find((d) => d.type === 'pin');
    } else {
      data = leftVisibleTabData.find((d) => d.index === i);
    }
    if (!data) {
      throw new Error(`Unable to find formfield with index:${i} from leftVisibleTabData`);
    }

    if (selected.includes(data)) {
      const newArray = selected.filter((val) => val !== data);
      setSelected(newArray);
    } else {
      selected.push(data);
      setSelected([...selected]);
    }
  };
  const CheckTable = () => {
    if (selected.find((d) => d.type === 'table')) {
      return false;
    }
    return true;
  };
  /**
   * @function addSelectedItems
   * @description Push the selected field by drag into 'removed' array
   * @param group[] array of the selected field
   * @author Sanil PS
   */
  const addSelectedItems = (group: NewCoordinateType[]) => {
    const selectedFields: Array<FormFieldEditType> = [];
    // eslint-disable-next-line array-callback-return
    group.map((item) => {
      const data = added.find((d: FormFieldEditType) => d.index === item.i);
      if (data) {
        selectedFields.push(data);
      }
    });
    setRemoved([...selectedFields]);
  };

  /**
   * @function addRemove
   * @description Push the selected field into 'removed' array
   * @param {string} i index of the selected field
   * @author Sam Manual Varghese
   */
  const addRemove = (i: string) => {
    const removedData = removed.find((data) => data.index === i);
    if (removedData) {
      const newArray = removed.filter((val) => val !== removedData);
      setRemoved(newArray);
    } else {
      const data = added.find((d: FormFieldEditType) => d.index === i);
      if (data) {
        removed.push(data);
        setRemoved([...removed]);
      }
      // removeAdded(); //dont delete this line
    }
  };

  /**
   * @description Updates the coordinates of the pin after adding new Fields. Pin automatically moved to the bottom of the field list
   * @param pinIndex - The index of the pin to update.
   * @param maxX - The maximum X value for filtering.
   * @param maxY - The maximum Y value for filtering.
   * @returns The updated array of coordinates.
   * @author Sanil PS
   */
  const updateCoordinates = (
    pinIndex: string | undefined,
    maxX: number,
    maxY: number,
  ): NewCoordinateType[] =>
    coordinates.map((field) => {
      if (pinIndex && field.i === pinIndex) {
        const calculatedPositionY: number = selected.length * FIELD_HEGIHT + field.y;

        return { ...field, y: calculatedPositionY };
      }
      return field;
    });
  /**
   * @function addCountToLabelForGenericTotalField
   * @param combined
   * @description combined array get new label for Generic Total field with count eg: Generic Total field (1)
   * @author nithin m raj
   */
  const addCountToLabelForGenericTotalField = (combined: Array<FormFieldEditType>) => {
    let genericTotalCount = 0;
    return combined.map((item) => {
      const itemLabel = item.label.replace(/\s?\(\d+\)/, '');
      if (item.type === 'totalField' && itemLabel === 'Generic Total field') {
        // eslint-disable-next-line no-param-reassign
        item.label =
          genericTotalCount === 0
            ? `${itemLabel}`
            : `${itemLabel} (${genericTotalCount})`;
        genericTotalCount += 1;
      }
      return item;
    });
  };

  /**
   * @function addSelected
   * @description Add the selected form field into the dynamic form
   * @author Sam Manual Varghese
   */
  const addSelected = async () => {
    if (tableCellPopUp) {
      const defaultCoordinate = {
        x: editData?.coordinate?.x || 0,
        y: editData?.coordinate?.y || 0,
        i: editData?.coordinate?.i || '',
        w: editData?.coordinate?.w || 1,
        h: editData?.coordinate?.h || 1,
      };
      selected.forEach((field: FormFieldEditType) => {
        field.parentWidgetIndex = editData?.index;
        field.coordinate = defaultCoordinate;
      });
    }
    if (selected.length > 0) {
      setIsDraggable(CheckTable());
      let newCoordinates: Array<NewCoordinateType> = [...coordinates];
      let maxY = Math.max(...coordinates.map((o: any) => o.y));
      if (maxY < 0) {
        maxY = 0;
      }
      let maxX = 1;

      if (!pinSelected && isItemTypePresent(added)) {
        const pinIndex = fullData.find(
          (field: FormFieldEditType) => field.type === 'pin',
        )?.index;
        maxX = Math.max(...coordinates.map((o) => (pinIndex === o.i ? o.x : 0)));
        maxY = Math.max(
          ...coordinates.map((o) => (pinIndex === o.i ? o.y - FIELD_HEGIHT : 0)),
        );

        newCoordinates = updateCoordinates(pinIndex, maxX, maxY);
      }
      let autoFilledSelected = autoPickValues
        ? fillAutoPickedFormFieldValues(selected, autoPickValues)
        : selected;
      autoFilledSelected = addEmptyFieldsToFormFields(autoFilledSelected, fullData);
      let combined = [
        ...added,
        ...autoFilledSelected.map((item) => {
          maxY += 5;
          // function for set pin coordinates
          if (pinSelected && findType(item.type)) {
            const { x, y } = pinSelected;

            // Handling Click Add pin in diffrent position
            const pinExists = newCoordinates.find((f) => f.i === pinSelected.index);
            if (pinExists) {
              const updatedPin = newCoordinates.map((f) => {
                if (f.i === pinSelected.index) {
                  return { ...f, x, y };
                }
                return f;
              });

              newCoordinates = _.uniqBy(updatedPin, 'i');
              // eslint-disable-next-line no-prototype-builtins
              if (
                !item.hasOwnProperty('index') ||
                item.index === null ||
                item.index === undefined
              ) {
                return { ...item, index: pinSelected.index };
              }
            } else {
              const uuid = pinSelected.index ?? uuidv4();
              newCoordinates.push(
                getCoordinatesPin({ item, maxX: x, maxY: y, maxHeight: true, uuid }),
              );
            }
            setPinSelected(null);
            return item;
          }
          if (isItemTypePresent(added)) {
            if (formFieldsWithInfiniteHeight.includes(item.type) && !item.isMultiple) {
              newCoordinates.push(
                getCoordinatesPin({ item, maxX, maxY, maxHeight: false }),
              );
              return item;
            }
            if (!item.isMultiple) {
              newCoordinates.push(
                getCoordinatesPin({ item, maxX, maxY, maxHeight: true }),
              );
              return item;
            }
            if (formFieldsWithInfiniteHeight.includes(item.type)) {
              newCoordinates.push(
                getCoordinatesPin({
                  uuid: item.index,
                  maxX,
                  maxY,
                  maxHeight: false,
                }),
              );
            } else {
              newCoordinates.push(
                getCoordinatesPin({ uuid: item.index, maxX, maxY, maxHeight: true }),
              );
            }
            return item;
          }
          if (formFieldsWithInfiniteHeight.includes(item.type) && !item.isMultiple) {
            newCoordinates.push(getCoordinates({ item, maxY, maxHeight: false }));
            return item;
          }
          if (!item.isMultiple) {
            newCoordinates.push(getCoordinates({ item, maxY, maxHeight: true }));
            return item;
          }

          if (formFieldsWithInfiniteHeight.includes(item.type)) {
            newCoordinates.push(
              getCoordinates({ maxY, uuid: item.index, maxHeight: false, item }),
            );
          } else {
            newCoordinates.push(
              getCoordinates({ maxY, uuid: item.index, maxHeight: true, item }),
            );
          }
          return item;
        }),
      ];
      combined = addCountToLabelForGenericTotalField(combined);

      setCoordinates([...newCoordinates]);

      const newFormData = leftVisibleData.filter((item: FormFieldEditType) => {
        const existingItem = combined.find((data) => data.index === item.index);
        if (!item.isMultiple && existingItem) {
          return false;
        }
        return true;
      });
      const getLabel = (v: { type: string; label: string }) => {
        if (v.type === 'heading') {
          return 'Heading';
        }
        if (v.type === 'paragraph') {
          return 'Paragraph';
        }
        // if (v.label === 'Generic Text') {
        //   return val;
        // }
        // if (v.label === 'Generic Date') {
        //   return val;
        // }
        // if (v.label === 'Generic Checkbox') {
        //   return val;
        // }
        if (v.type === 'image') {
          return 'Image';
        }
        if (v.type === 'table') {
          return 'Table';
        }
        return v.label;
      };

      const newArray = leftVisibleTabData.filter((val: FormFieldEditType) => {
        if (!val.isMultiple) {
          return !combined.find((field: FormFieldEditType) => field.index === val.index);
        }

        return {
          ...val,
          label: getLabel(val),
        };
      });
      const data = tableFilterAddedEmptyFields(combined);
      await setAdded(_.uniqBy(data, 'index'));
      await setSelected([]);
      await setLeftVisibleData(newFormData);
      await setLeftVisibleTabData(newArray);
      await setTableCellPopUp(false);
    }
  };
  /**
   * @function deleteCurrentItem
   * @description delete the selected item from the dynamic form
   * @param {string} index index of the selected field
   * @author Sam Manual Varghese
   */
  const deleteCurrentItem = async (index: string) => {
    try {
      await confirm({
        description: t('popup.Are you sure you want to delete the form fields?'),
      });

      const { deleteFields, itemsToRemove } = filterItemsToRemove(index, added, removed);
      if (itemsToRemove.length > 0) {
        const removableItems = itemsToRemove
          .filter((val) => !val.isMultiple)
          .filter((val) => val?.index === index);

        setCoordinates(
          coordinates.filter(
            (item) => !removableItems.find((data) => data?.index === item.i),
          ),
        );
        let newLeftVisibleData: Array<FormFieldEditType> = [
          ...leftVisibleData,
          ...removableItems,
        ].map((el) => {
          const actual = fullData.find((e) => e?.id === (el?.id || el?._id));
          return {
            ...el,
            label: actual?.label || '',
            hint: actual?.hint || '',
            order: actual?.order || 0,
            imgUrl: '',
            isImage: false,
            imageName: '',
            imageType: '',
            imageBinary: {},
            styles: {
              color: '',
              fontSize: '',
              fontWeight: '',
              textAlign: '',
              borderColor: '',
              backgroundColor: '',
              borderRadius: '',
              objectFit: 'inherit',
              borderTopStyle: 'none',
              borderRightStyle: 'none',
              borderBottomStyle: 'none',
              borderLeftStyle: 'none',
              gridTemplateColumns: `repeat(${tableData.cols}, 1fr)`,
            },
            // defaultDate: actual?.defaultDate,
            values: actual?.values || [],
          };
        });

        newLeftVisibleData = sortArray(newLeftVisibleData);

        const tempLeftVisibleTabData = [
          ...newLeftVisibleData.filter((val) => val.category === formType),
        ];

        setAdded(deleteFields);
        setRemoved([]);
        setLeftVisibleTabData(tempLeftVisibleTabData);
        setLeftVisibleData(newLeftVisibleData);
      }
      return true;
    } catch (error) {
      console.log('Error:', error);
      return false;
    }
  };

  /**
   * @function selectAllRight
   * @description Select the all fields from the left form, from under a particular accordian
   * @author Sam Manual Varghese
   */
  const selectAllRight = (groupId?: string) => {
    const dataWithFieldGroup = leftVisibleTabData.filter(
      (data) => data.fieldGroup && data?.fieldGroup?._id === groupId,
    );
    const dataWithOutFieldGroup = leftVisibleTabData.filter((data) => !data.fieldGroup);
    if (groupId) {
      if (selected.length === dataWithFieldGroup.length) {
        setSelected([]);
      } else {
        setSelected(dataWithFieldGroup);
      }
    } else if (selected.length === dataWithOutFieldGroup.length) {
      setSelected([]);
    } else {
      setSelected(dataWithOutFieldGroup);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onCancel = () => {
    if (!editData) {
      // @ts-ignore
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }
    setLeftVisibleTabData(
      leftVisibleTabData.map((o: any) => {
        if (o.id === editData.id) return { ...o, label };
        return o;
      }),
    );
  };

  const changeLabel = (e: any) => {
    if (!editData) {
      // @ts-ignore
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }
    setEditData({ ...editData, label: e.target.value });
  };

  const changeHint = (e: any) => {
    if (!editData) {
      // @ts-ignore
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }
    setEditData({ ...editData, hint: e.target.value });
  };

  const changeDefaultValue = (e: any) => {
    if (!editData) {
      // @ts-ignore
      console.error('nothing to changeDefaultValue. Edit data is empty');
      return;
    }
    if (editData.type === 'checkbox') {
      setEditData({
        ...editData,
        values: e.target.checked === true ? ['checked'] : [''],
      });
    } else if (editData.type === 'dropdown') {
      setEditData({
        ...editData,
        defaultDropDownValue: e.target.value,
      });
    } else {
      setEditData({ ...editData, values: e.target.value < 0 ? [0] : [e.target.value] });
    }
  };

  /**
   * @function submitEdit
   * @description Save the edited label and hint of the particular field
   * @author Sam Manual Varghese
   */
  const submitEdit = async (data) => {
    // const newEditData = data || editData - call this directly inside mouseUp
    // pass the whole data and replace it

    const newEditData = data || editData;
    const checkFontSize = (e) => {
      const coordinate = e;

      if (
        data.styles?.fontSize &&
        // eslint-disable-next-line radix
        parseInt(data.styles?.fontSize) > 20 &&
        coordinate.h < 4
      ) {
        coordinate.h += 1;
        coordinate.maxH += 1;
      }
      return coordinate;
    };
    if (!newEditData) {
      console.error('nothing to edit. Edit data is empty');
      return;
    }
    setCoordinates(
      coordinates.map((e) => (e.i === newEditData.index ? checkFontSize(e) : e)),
    );
    setTableData({ cols: 2, rows: 2 });

    if (newEditData.values) {
      setAdded(
        added.map((o: any) => {
          if (o.index === newEditData.index) {
            return {
              ...o,
              values: newEditData.values,
              totalCount: newEditData.totalCount,
              label: newEditData.label,
              hint: newEditData.hint,
              imgUrl: newEditData?.imgUrl || o.imgUrl,
              isImage: newEditData?.isImage || false,
              imageType: newEditData?.imageType || '',
              imageName: newEditData?.imageName || '',
              imageBinary: newEditData?.imageBinary || '',
              styles: newEditData?.styles,
              tableRows: newEditData?.tableRows || tableData.rows,
              tableCols: newEditData?.tableCols || tableData.cols,
              borderWidth: newEditData?.borderWidth,
            };
          }
          return o;
        }),
      );
    }
    if (newEditData.defaultDropDownValue) {
      setAdded(
        added.map((o: any) => {
          if (o.index === newEditData.index)
            return {
              ...o,
              defaultDropDownValue: newEditData?.defaultDropDownValue,
              label: newEditData.label,
              hint: newEditData.hint,
              styles: newEditData?.styles,
            };
          return o;
        }),
      );
    }
    if (newEditData.label && newEditData.hint) {
      setAdded(
        added.map((o: any) => {
          if (o.index === newEditData.index)
            return {
              ...o,
              label: newEditData.label,
              values: newEditData.values,
              defaultDropDownValue: newEditData?.defaultDropDownValue,
              hint: newEditData.hint,
              styles: newEditData?.styles,
            };
          return o;
        }),
      );
    }
  };

  const generateLayout = (data: FormFieldType) => {
    const coordinate = coordinates.find((c: any) => c.i === data.index);
    if (coordinate) {
      return coordinate;
    }
    let returnData = {};
    if (
      data.type === 'heading' ||
      data.type === 'paragraph' ||
      data.type === 'image' ||
      data.type === 'table'
    ) {
      returnData = {
        x: 1,
        y: data.coordinate?.y || 1, // y=0
        w: DynamicormConstants.w,
        h: 4,
        minW: 2,
        minH: 4,
        isBounded: false,
      };
    } else {
      returnData = {
        x: 1,
        y: data.coordinate?.y || 1, // y=0
        w: DynamicormConstants.w,
        h: 4,
        minW: 2,
        minH: 2,
        maxH: 4,
        isBounded: false,
      };
    }

    return returnData;
  };

  const totalCountCheckbox = (e: any, index: string) => {
    if (!editData) {
      return;
    }
    let { totalCount = [] } = editData;
    if (e.target.checked === true) {
      totalCount.push(index);
    } else {
      // eslint-disable-next-line no-shadow
      totalCount = totalCount.filter((t) => t !== index);
    }
    setEditData({
      ...editData,
      totalCount,
    });
  };

  const editFormField = (data: FormFieldEditType) => {
    if (data.type === 'image') {
      setEditData(data);
      setOpenImageFieldEditPopup(true, {
        editData: data,
        submitEdit,
      });
    } else {
      setEditData(data);
      setLabel(data.label);
      setOpenFieldEditPopup(true, {
        submitEdit,
        editData: data,
        totalCountCheckbox,
        added,
        tableData,
        setTableData,
      });
    }
  };
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DynamicFormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        leftVisibleTabData,
        selected,
        addSelected,
        setSelected,
        added,
        setLeftVisibleTabData,
        addForm,
        setCoordinates,
        setAdded,
        coordinates,
        leftVisibleData,
        setLeftVisibleData,
        removed,
        setRemoved,
        addRemove,
        fullData,
        formType,
        setFormType,
        deleteCurrentItem,
        selectAllRight,
        setEditData,
        editData,
        label,
        showModal,
        onClose,
        setShowModal,
        setLabel,
        onCancel,
        changeLabel,
        changeHint,
        submitEdit,
        showImageModal,
        editFormField,
        setShowImageModal,
        generateLayout,
        changeDefaultValue,
        totalCountCheckbox,
        tableData,
        setTableData,
        headerData,
        setHeaderData,
        setAutoPickValues,
        setPinSelected,
        pinSelected,
        addSelectedItems,
        setTableCellPopUp,
        tableCellPopUp,
        isDraggable,
        setIsDraggable,
      }}
    >
      {children}
    </DynamicFormContext.Provider>
  );
}

export default FormWrapper;
