
import React, { RefObject, useContext, useState } from "react";
import { calculateSelectedFields, getFieldByCoordinates } from "src/Containers/Events/Rally/components/DynamicForm/gridUtils";
import { NewCoordinate } from "src/Containers/Events/Rally/utils";
import DynamicFormContext from "src/Libs/DynamicForm/context/DynamicFormContext";

type DragAndSelectType = {
  coordinates: NewCoordinate[],
  elementRef: RefObject<HTMLDivElement | null>,
};

type DragAndSelect = {
  isMouseDown: React.SetStateAction<boolean>;
  handleMouseDown: (event: React.MouseEvent) => void;
  handleMouseUp: () => void;
  handleMouseMove: (event: React.MouseEvent) => void;
  dragStartX: React.SetStateAction<number>;
  dragStartY: React.SetStateAction<number>;
  draglastX: React.SetStateAction<number>;
  draglastY: React.SetStateAction<number>;
};

const useDragAndSelect = ({ coordinates, elementRef }: DragAndSelectType): DragAndSelect => {
  const box = elementRef.current;
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [dragStartX, setDragStartX] = useState<number>(0);
  const [dragStartY, setDragStartY] = useState<number>(0);
  const [draglastX, setDragLastX] = useState<number>(0);
  const [draglastY, setDragLastY] = useState<number>(0);
  const { addSelectedItems } = useContext(DynamicFormContext);

   // Once the user clicks
  const handleMouseDown = (event) => {
    event.preventDefault();
    // Check if it was a right-click, and if so, return early to prevent further execution
    if (event.button === 2) {
      return;
    }
    const clickedX = event.clientX;
    const clickedY = event.clientY;

    // Check if the click is inside any field
    const clickedField = getFieldByCoordinates(clickedX, clickedY, coordinates, box);
    
    if (clickedField) {      
      setIsMouseDown(false);
    } else {
      setDragStartX(clickedX);
      setDragStartY(clickedY);
      setDragLastX(clickedX);
      setDragLastY(clickedY);
      setIsMouseDown(true);
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  
  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    // Calculate the selection rectangle coordinates    
    const dragEndX = event.clientX;
    const dragEndY = event.clientY;

    const startX = Math.min(dragStartX, dragEndX);
    const startY = Math.min(dragStartY, dragEndY);
    const endX = Math.max(dragStartX, dragEndX);
    const endY = Math.max(dragStartY, dragEndY);
    setDragStartX(startX);
    setDragStartY(startY);
    setDragLastX(Math.max(endX));
    setDragLastY(Math.max(endY));

    // Iterate over the items in the cooordinates and check if they are within the selection rectangle  
    if (box) {
      const selectedFields: NewCoordinate[] = calculateSelectedFields(startX, startY, endX, endY, coordinates, box);      
      addSelectedItems(selectedFields);
    }
  };
  
  return {
    isMouseDown,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    dragStartX,
    dragStartY,
    draglastX,
    draglastY
  }
}

export default useDragAndSelect;