import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  title: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
  icon: {
    color: colors.primary.color,
    cursor: 'pointer',
    fontSize: '35px',
    marginRight: '10px ',
  },
  container: {
    maxHeight: 440,
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    textAlign: 'center',
  },
  theadLeft: {
    textAlign: 'left',
    paddingLeft: '40px',
  },
  theadEnd: {
    textAlign: 'end',
    paddingRight: '40px',
  },
  form: {
    width: 'auto',
    border: '1px solid #e0e0e0',
    minHeight: 100,
    margin: 10,
    borderRadius: 10,
  },
  rallyName: {
    textAlign: 'left',
    paddingLeft: '20px',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
    textAlign: 'center',
  },
  subTitle: {
    width: '200px',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  completed: {
    backgroundColor: '#0C8E64',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '100px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  pending: {
    backgroundColor: '#ADAEB0',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '100px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '125px',
  },
  download: {
    textAlign: 'left',
    cursor: 'pointer',
  },
  viewForm: {
    overflow: 'auto',
    maxHeight: '525px',
    // '@media all and (min-width: 1440px) and (min-height: 800px)': {
    //   maxHeight: '525px',
    // },
    '@media all and (min-width: 1920px)': {
      maxHeight: '52vh',
      height: '710px',
    },
  },
  viewIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  deleteIcon: {
    fontSize: '20px',
    color: '#E40909',
    margin: 0,
    marginLeft: '20px',
    cursor: 'pointer',
  },
  viewFormWrapper: {
    height: '475px',
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '475px',
    },
    '@media all and (min-width: 1920px)': {
      maxHeight: '580px',
      height: '580px',
    },
  },
}));
