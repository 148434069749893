const initialState = {
  role: '',
  user: {
    username: '',
  },
  userPermissions: {
    all: false,
    permission: [''],
  },
};

// eslint-disable-next-line default-param-last
export default function roleReducer(state = initialState, action?) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions

    case 'SAVE_ROLE': {
      return {
        ...state,
        role: action.payload,
      };
    }
    case 'SAVE_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'SAVE_USERNAME': {
      const user = { ...state.user, username: action.payload };
      return {
        ...state,
        user: { ...user },
      };
    }
    case 'SAVE_USERPERMISSIONS': {
      return {
        ...state,
        userPermissions: action.payload,
      };
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
