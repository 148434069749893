import moment from 'moment';
import { validator } from 'src/Services/Auth/Validations';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import * as Yup from 'yup';

const EventValidations = () => {
  const validEventStatus = ({ startDate, endDate, startRegDate, lastRegDate }) => {
    const today = moment();
    let eventStatus = [
      EnumEventStatus.Cancelled,
      EnumEventStatus.EventFinished,
      EnumEventStatus.InProgress,
      EnumEventStatus.Preparation,
      EnumEventStatus.RegistrationsCompleted,
      EnumEventStatus.RegistrationsOpen,
    ];
    if (moment(startDate) <= today.startOf('day')) {
      eventStatus = eventStatus.filter(
        (status) => status !== EnumEventStatus.Preparation,
      );
    }
    if (moment(startRegDate) > today.endOf('day')) {
      eventStatus = eventStatus.filter(
        (status) => status !== EnumEventStatus.RegistrationsOpen,
      );
    }
    if (moment(lastRegDate) < today.startOf('day')) {
      eventStatus = eventStatus.filter(
        (status) => status !== EnumEventStatus.RegistrationsOpen,
      );
    }
    if (moment(lastRegDate) >= today.startOf('day')) {
      eventStatus = eventStatus.filter(
        (status) => status !== EnumEventStatus.RegistrationsCompleted,
      );
    }
    if (moment(startDate) > today.startOf('day')) {
      eventStatus = eventStatus.filter((status) => status !== EnumEventStatus.InProgress);
    }
    if (moment(endDate) < today.startOf('day')) {
      eventStatus = eventStatus.filter((status) => status !== EnumEventStatus.InProgress);
    }
    if (moment(endDate) >= today.startOf('day')) {
      eventStatus = eventStatus.filter(
        (status) => status !== EnumEventStatus.EventFinished,
      );
    }
    return eventStatus;
  };

  return Yup.object().shape({
    eventName: Yup.string()
      .matches(/[a-z-A-Z]+/g, 'validationErrors.use at least one alphabet')
      .min(3, 'validationErrors.Event name is too Short!')
      .required('validationErrors.Event name is Required'),
    organizer: Yup.string().required('validationErrors.Organiser is Required'),
    eventOrganizer: Yup.string(),
    enquiryEmail: validator.EMAIL,
    enquiryPhone: validator.PHONE,
    eventType: Yup.string().required('validationErrors.Event Type is Required'),
    venue: Yup.string().matches(
      /[a-z-A-Z]+/g,
      'validationErrors.use at least one alphabet',
    ),
    startDateTime: Yup.string().required('validationErrors.Start Date Time is Required'),
    endDateTime: Yup.string().required('validationErrors.End Date Time is Required'),
    startDateOfReg: Yup.string().required(
      'validationErrors.Start Date of Registration is Required',
    ),
    lastDateOfReg: Yup.string().required(
      'validationErrors.Last Date of Registration is Required',
    ),
    website: Yup.string(),
    facebookUrl: Yup.string(),
    instagramUrl: Yup.string(),
    status: Yup.mixed()
      .required('validationErrors.Status Type is Required')
      .test(
        'isValidEventStatus',
        'validationErrors.choose a valid event status',
        function (status) {
          const validStatuses = validEventStatus({
            startDate: this.resolve(Yup.ref('startDateTime')),
            endDate: this.resolve(Yup.ref('endDateTime')),
            startRegDate: this.resolve(Yup.ref('startDateOfReg')),
            lastRegDate: this.resolve(Yup.ref('lastDateOfReg')),
          });
          return validStatuses.includes(status as EnumEventStatus);
        },
      ),
    eventDescription: Yup.string(),
    memo: Yup.string(),
  });
};
export default EventValidations;
