import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  AddFileType,
  FindFileTypeById,
  UpdateFileType,
} from 'src/Services/Auth/Actions/RallyTypeActions';
import { convertWordToKey } from 'src/Services/Utils';
import { ErrorMessageCustom, FormButton, FormLabel } from 'src/Components/Utils';
import useCreateUpdateForm from 'src/Hooks/useCreateUpdateForm';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { OpenPopupFunction } from 'src/Types/FileType';
import useStyles from './FileTypeListingStyle';

function AddEditFileType({
  updateId,
  reload,
  setOpenPopup,
}: {
  updateId: ObjectIdType;
  reload: () => void;
  setOpenPopup: OpenPopupFunction;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileTypeSchema = Yup.object().shape({
    type: Yup.string().required(t('File Type.File type is required')),
  });

  const successResponseHandler = (response) => {
    setOpenPopup(false);
    reload();
    SuccessToast(updateId ? t('File Type.updated') : t('File Type.created'));
  };
  const errorResponseHandler = (error) => {
    const message = error.response.data.error;
    if (message === 'File type already exists') {
      ErrorToast(t('File Type.existingError'));
    } else {
      ErrorToast(t('other.error'));
    }
    console.error('Error occurred during add or update File type:', error);
  };

  const { formData, setFormData, isLoading, handleSubmit } = useCreateUpdateForm({
    updateId,
    fetchById: FindFileTypeById,
    createFn: AddFileType,
    updateFn: UpdateFileType,
    onSuccess: successResponseHandler,
    onFailure: errorResponseHandler,
    initialFormState: { type: '' },
  });

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={fileTypeSchema}
        onSubmit={(values, { setSubmitting }) => {
          const updateData = {
            ...values,
            key: convertWordToKey(values.type),
          };
          handleSubmit(updateData, { setSubmitting });
        }}
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form>
            <div className={classes.titleWrapper}>
              <h3>{updateId ? t('File Type.update') : t('File Type.addNew')}</h3>
              <FormButton
                className="btn"
                name={t('forms.Submit')}
                isLoading={isSubmitting}
              />
            </div>
            <div className="input-1">
              <FormLabel name={t('forms.Type')} require />
              <Field
                className={classes.input}
                text="type"
                type="text"
                name="type"
                value={values.type}
                onChange={handleChange}
              />
              <ErrorMessageCustom errors={errors} field="type" touched={touched} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddEditFileType;
