import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import useEventEnrollNow from 'src/Hooks/useEventEnrollNow';
import WarningToast from 'src/Components/Utils/WarningToast';
import EnumEventStatus from 'src/Types/Enums/EnumEventStatus';
import AVATAR from '../eventimgAvatar.svg';
import { Button } from '../../../Components/Utils';
import {
  participantGetEvent,
  sendUserEventInvitation,
} from '../../../Services/Auth/Actions/EventActions';
import ParticipantEventDetails from '../../../Containers/Events/EventDetails/ParticipantEventDetails';
import StatusLabel from '../../../Components/Utils/StatusLabel';
import ParticipantStatus from '../../../Components/Utils/ParticipantStatus';
import EVENTAVATAR from '../eventProfile.png';
import DATE from '../Date1.svg';
import RallyListing from '../../../Containers/Events/Rally/RallyListing';
import ParticipantFormList from '../../../Containers/Events/Forms/ParticipantFormList';
import useStyles from './styles';
import ToggleText from '../ToggleText';
import ContactSectionWrap from './ContactSectionWrap';
import USER from '../user4.svg';
import FLAG from '../Flag2.svg';
import MAP from '../map3.svg';
import EventCards from './EventCards';

function ParticipantView({ id, page }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [participant, setParticipant] = useState<any>([]);
  const [statusData, setStatusData] = useState<any>([]);
  const [participantCount, setCount] = useState(0);
  const [details, setDetails] = useState<any>({});
  const [type, setType] = useState<string>('');
  const [isCancelled, setIsCancelled] = useState(false);
  const [currentPage, setCurrentPage] = useState(page);
  const role = useSelector((state: RootState) => state.role);
  const { enrollNowValidation } = useEventEnrollNow();

  const participantEventDetails = () => {
    participantGetEvent(id)
      .then((response) => {
        const data = response.data.event;
        const { organizerType } = response.data.event;
        setParticipant(response.data?.eventstatus);
        setStatusData(response.data?.participant);
        setCount(response.data?.participantsCount);
        setDetails(data);
        setType(organizerType);
        setIsCancelled(data.isCancelled);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const enrollSuccessCall = () => {
    SuccessToast(
      t(
        'popup.You have been enrolled successfully. Please start filling the event forms',
      ),
    );
    setCurrentPage('forms');
  };

  const enrollNowClick = () => {
    if (enrollNowValidation(details.status))
      sendUserEventInvitation({
        username: role.user.username,
        eventId: details._id,
      }).then(() => {
        participantEventDetails();
        enrollSuccessCall();
      });
  };

  const viewRegistrationClick = () => {
    if (details.status === EnumEventStatus.Preparation) {
      WarningToast(t('popup.eventRegistrationNotOpen'));
      return;
    }
    if (details.status !== EnumEventStatus.RegistrationsOpen) {
      WarningToast(t('popup.eventRegistrationClosed'));
      return;
    }
    if (statusData?.isApproved === false) {
      WarningToast(t('toast.You were declined by Organiser'));
      return;
    }
    setCurrentPage('forms');
  };

  const eventOrganizerId = (details.organizer && details.organizer._id) || '';
  const eventOrganizerName =
    (details.organizer &&
      details.organizer.userId &&
      details.organizer.userId.firstName) ||
    (details.organizer &&
      details.organizer.userId &&
      details.organizer.userId.companyname) ||
    '';

  useEffect(() => {
    participantEventDetails();
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const tab = params.get('type');
    if (tab === 'forms') {
      setCurrentPage('forms');
      window.scroll(0, 0);
    }
    if (tab === 'participants') {
      setCurrentPage('participants');
    }
    if (tab === 'rallies') {
      setCurrentPage('rallies');
    }
  }, []);
  const showEnrollSection = () => {
    if (isCancelled) {
      return null;
    }
    if (participant && participant.isAccept === true) {
      return (
        <div style={{ height: '5px !important' }}>
          <Scroll.Link to="Element" smooth>
            <Button
              name={t('participant.View Registration')}
              className={`${classes.enrollBtn} `}
              onClick={viewRegistrationClick}
            />
          </Scroll.Link>
        </div>
      );
    }
    return (
      <div style={{ height: '5px !important' }}>
        <Button
          name={t('forms.Enroll Now')}
          className={`${classes.enrollBtn} `}
          onClick={enrollNowClick}
        />
      </div>
    );
  };
  const showDetailPages = () => {
    switch (currentPage) {
      case 'details':
        return <ParticipantEventDetails details={details} />;

      case 'rallies':
        return (
          <RallyListing
            event={details}
            isEventEnrolled={participant && participant.isAccept}
            isApproved={statusData?.isApproved}
            eventStatus={details.status}
          />
        );

      case 'forms':
        return <ParticipantFormList details={details} />;

      default:
        return <ParticipantEventDetails details={details} />;
    }
  };
  return (
    <>
      <div className={classes.participantSection}>
        <div className={classes.eventDetail}>
          <div className={classes.heading}>
            {details.eventImage ? (
              <img src={details.eventImage} className={classes.avatar} alt="img" />
            ) : (
              <img src={AVATAR} className={classes.avatar} alt="img" />
            )}
            <h2 className={classes.eventName}>{details.eventName}</h2>
            {showEnrollSection()}
          </div>
          <div className={classes.eventOrganizerName}>
            <p className={classes.p} style={{}}>
              {t('others.by')}{' '}
              <Link
                to={`/organizers/details/${eventOrganizerId}/profile`}
                className={classes.span}
                style={{ textDecoration: 'none' }}
              >
                <span className={classes.span}>{eventOrganizerName}</span>
              </Link>
            </p>
          </div>
          <div>
            <ToggleText details={details?.eventDescription} />
          </div>
          <ContactSectionWrap
            details={details.enquiryEmail}
            icon="dashicons:email"
            linkto={`tel:${details.enquiryEmail}`}
          />
          <ContactSectionWrap
            details={details.enquiryPhone}
            icon="bytesize:telephone"
            linkto={`tel:${details.enquiryPhone}`}
          />
          <div className={classes.eventStatus}>
            <div className={classes.eventStatusText}>{t('others.Event Status')}</div>
            <div className={classes.eventStatusCls}>
              <StatusLabel data={details} />
            </div>
          </div>
          {isCancelled ? null : (
            <div className={classes.eventStatus} style={{ marginTop: '2px' }}>
              <div className={classes.eventStatusText}>
                {t('events.Participant Status')}
              </div>
              <div className={classes.eventStatusCls}>
                <ParticipantStatus statusData={statusData} participant={participant} />
              </div>
            </div>
          )}

          <div className={classes.eventMoreDetails}>
            <EventCards
              details={Moment(details.startDateTime).format('DD MMM YYYY')}
              img={DATE}
            />
            <EventCards
              details={details.rallies ? details.rallies.length : 0}
              img={FLAG}
              name={t('forms.Rally')}
            />

            <EventCards details={details.venue} img={MAP} toolTip />
            <EventCards
              details={participantCount === 0 ? null : participantCount}
              img={USER}
              name={t('forms.Participants')}
            />
          </div>
        </div>

        <div className={classes.eventImage}>
          <img
            src={details.eventImage || EVENTAVATAR}
            className={classes.eventImageParticipant}
            alt="EVENTAVATAR"
          />
        </div>
      </div>
      <div className={classes.eventTabDetails} id="Element">
        <div className={classes.eventTabs}>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <h3
              onClick={() => setCurrentPage('details')}
              className={
                currentPage === 'details'
                  ? `${classes.eventTab} ${classes.eventTabActive}`
                  : `${classes.eventTab}`
              }
            >
              {t('forms.Event Details')}
            </h3>
            <h3
              onClick={() => setCurrentPage('rallies')}
              className={
                currentPage === 'rallies'
                  ? `${classes.eventTab} ${classes.eventTabActive}`
                  : `${classes.eventTab}`
              }
            >
              {t('overview.Rally Details')}
            </h3>
            <h3
              onClick={viewRegistrationClick}
              className={
                currentPage === 'forms'
                  ? `${classes.eventTab} ${classes.eventTabActive}`
                  : `${classes.eventTab}`
              }
            >
              {t('forms.Event Forms')}
            </h3>
          </div>
        </div>
        {showDetailPages()}
      </div>
    </>
  );
}

export default ParticipantView;
