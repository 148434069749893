import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import GeneralSettingsTabs from './GeneralSettingsTabs';

function GeneralSettings() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('General settings.General settings')} />
        <GeneralSettingsTabs />
      </Dashboard>
    </div>
  );
}

export default GeneralSettings;
