import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import Tick from '../DocDownload/DragAndDrop/Form/tick.png';
import useStyles from '../DocDownload/style';
import ErrorToast from './ErrorToast';

function EditableHeading({ initialValue, onUpdate }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [heading, setHeading] = useState(initialValue);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (heading.trim() === '') {
      // Prevent saving empty heading
      ErrorToast(t('others.Report Name field is required'));
      return;
    }
    onUpdate(heading);
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <div className={classes.editing}>
          <img
            className={classes.tick}
            src={Tick}
            onClick={handleSaveClick}
            // {onReportNameChangeTick}
            alt="tick"
          />
          <input
            type="text"
            name="reportName"
            value={heading}
            className={classes.editReportName}
            onChange={(e) => setHeading(e.target.value)}
          />
        </div>
      ) : (
        <div className={classes.editView}>
          <Icon
            className={classes.editIconPen}
            icon="feather:edit-3"
            onClick={handleEditClick}
          />
          <h2 className={classes.exporterHeading}>{heading}</h2>
        </div>
      )}
    </div>
  );
}

export default EditableHeading;
