/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useMemo } from 'react';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import { fileResource } from 'src/Components/DocDownload/util';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import CustomTable, {
  TopActionButtonPopupType,
  TopActionLinkedButtonType,
  TopActionNormalButtonType,
} from 'src/Components/Table/Table';
import topActions from 'src/Types/Enums/EnumTopActions';
import useFetchExportDetails from 'src/Components/DocDownload/hooks/useFetchExportDetails';
import DragAndDrop from 'src/Components/DragAndDrop/DragAndDrop';
import {
  axiosResponseToFileDownload,
  createErrorString,
  createFormDataFromFile,
} from 'src/Services/Utils';
import WarningToast from 'src/Components/Utils/WarningToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import useApiCall from 'src/Hooks/useApiCall';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import theme from '../../../Theme';
import useStyles from './RallyParticipantStyle';
import Header from '../../../Components/Header/Header';
import Dashboard from '../../../Layouts/Dashboard/Dashboard';
import AddEventParticipantsPopup from '../EventParticipants/AddEventParticipantsPopup';
import AVATAR from '../../Users/UsersListing/AvatarImage.svg';
import { downloadZip, getEvent } from '../../../Services/Auth/Actions/EventActions';
import {
  getRally,
  rallyParticipantDelete,
  participantsListing,
  importRallyParticipant,
  sampleParticipantCSV,
} from '../../../Services/Auth/Actions/RallyActions';

type TopActionButtonType =
  | TopActionButtonPopupType
  | TopActionNormalButtonType
  | TopActionLinkedButtonType;

function AddRally() {
  const { rallyId, eventId }: any = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const [ziploader, setZipLoader] = useState<Array<string>>([]);
  const [rallyName, setRallyName] = useState(['Rally']);
  const [eventName, setEventName] = useState(['Event']);
  const history = useHistory();

  const reloadTable = async ({
    sort,
    page,
    rowsPerPage,
    search,
  }): Promise<{ data: any; totalCount: any }> =>
    participantsListing({
      eventId,
      rallyId,
      sortBy: sort.sortBy,
      sort: sort.sort,
      search,
      page,
      rowsPerPage,
    })
      .then((response) => {
        const data = response.data.participants;
        const totalCount = response.data.count;
        return {
          data,
          totalCount,
        };
      })
      .catch((error) => {
        console.error(error);
        return {
          data: [], // You can use null or an empty array depending on your data type
          totalCount: 0,
        };
      });

  const setNames = () => {
    getEvent(eventId).then(({ data }) => {
      setEventName(data.event.eventName);
    });
    getRally(rallyId).then(({ data }) => {
      setRallyName(data.Rally.rallyName);
    });
  };

  const modal = useModalDialog();

  const setAddContactOpen = useMemo(
    () => modal(AddEventParticipantsPopup, 'addparticipant'),
    [],
  );

  const handleAddContactClick = (reload) => {
    setAddContactOpen(true, { details: eventId, rally: rallyId, reload });
  };

  useEffect(() => {
    setNames();
  }, []);

  const handleDownloadZipIndividual = (row) => {
    const { userId, firstName, lastName, companyname } = row;
    const name = companyname || `${lastName} ${firstName}`;
    downloadZip(eventId, rallyId, userId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${eventName}_${rallyName}_${name}_details.zip`);
        document.body.appendChild(link);
        link.click();
        setZipLoader([]);
      })
      .catch(() => {
        setZipLoader([]);
        toast.error(t('General settings.Zip download failed'), {
          position: 'top-center',
          autoClose: 5000,
        });
      });
  };

  const confirm: any = useConfirm();

  const handleDelete = (userId, reload) => {
    confirm({
      description: (
        <p id="transition-modal-description" className={classes.desc}>
          {t('popup.Are you sure you want to delete the participant?')}
        </p>
      ),
    }).then(() => {
      rallyParticipantDelete(rallyId, userId)
        .then(({ data }) => {
          reload();
          toast.success(data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    });
  };

  const columns = [
    {
      title: t('forms.Full Name'),
      field: 'name',
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <img
              src={row?.profileImage.length > 0 ? row.profileImage : AVATAR}
              className={classes.img}
              alt="avatar"
            />
            <div>
              {row?.lastName && row?.firstName
                ? `${row?.lastName} ${row?.firstName}`
                : row?.companyname || row?.name}
            </div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Username'),
      field: 'username',
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <div>{row.username}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Contact'),
      field: 'emailSrt',
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <div>{row.email}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      data: (row) => (
        <div className={classes.tbody}>
          <p className={classes.grey}>{moment(row.createdAt).format('DD.MM.YYYY')}</p>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      data: (row, reload) => (
        <div className={classes.tbody}>
          <div className={classes.wrapperActions}>
            <Link to={`/users/details/${row.userId}/profile`}>
              <Icon icon="feather:edit-3" className={classes.editIcon} />
            </Link>

            {!ziploader.includes(row.userId) ? (
              <Icon
                icon="fe:download"
                className={classes.editIcon}
                onClick={() => {
                  const abc: Array<string> = [];
                  abc.push(row.userId);
                  setZipLoader(abc);

                  handleDownloadZipIndividual(row);
                }}
              />
            ) : (
              <Loader
                type="ThreeDots"
                color={theme.palette.primary.main}
                height={50}
                width={50}
              />
            )}

            <Icon
              icon="ant-design:delete-twotone"
              className={classes.deleteIcon}
              onClick={() => handleDelete(row.userId, reload)}
            />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];
  const data = {
    resource: fileResource.rallyParticipant,
    eventId,
    rallyId,
    organizerId: '',
    initialSortApi: undefined, // Provide a default value
    id: '',
    reportName: '',
    reload: undefined, // Provide a default value
  };
  const [callApi, importLoader, error] = useApiCall();
  const { handleClick } = useFetchExportDetails(
    data.eventId,
    data.rallyId,
    data.resource,
    data.organizerId,
    data.initialSortApi,
    data.id,
    data.reportName,
    data.reload,
  );
  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('events.Export'),
        onClick: handleClick,
      },
    },
    {
      type: topActions.button,
      extraProps: {
        onClick: async ({ reload }) => handleAddContactClick(reload),
        name: t('forms.Add Participant'),
      },
    },
    {
      type: topActions.buttonPopup,
      extraProps: {
        label: 'others.Import CSV',
        loading: importLoader,
        component: DragAndDrop,
        onClick: async (file) => {
          if (!file) {
            WarningToast(t('others.Please select a valid Excel file'));
            return;
          }
          const formData = createFormDataFromFile('file', file);
          Object.keys(data).map((k) => formData.append(k, data[k]));

          await callApi(importRallyParticipant({ eventId, rallyId, formData }), {
            onSuccess: (response) => {
              if (response.data.error.length !== 0) {
                const error = createErrorString(response);
                ErrorToast(error);
              } else {
                SuccessToast(t('Participant Imported Successfully'));
              }
            },
            onError: () => {
              ErrorToast(t('others.Something went wrong'));
            },
          });
        },
        sampleCsvHandleClick: () => {
          sampleParticipantCSV()
            .then((response) => {
              axiosResponseToFileDownload({
                fileName: `Participant list.xlsx`,
                response,
              });
            })
            .catch(() => {
              ErrorToast(t('others.Something went wrong'));
            });
        },
        modalName: 'participantImport',
      },
    },
  ];
  return (
    <Dashboard>
      <Header title={rallyName} />
      <CustomTable
        tableName="Participants Listing"
        columns={columns}
        reload={reloadTable}
        heading={t('users.Participants Listing')}
        onDoubleClick={(item) =>
          history.push({
            pathname: `/users/details/${item.userId}/profile`,
            state: { showGoBack: true },
          })
        }
        topActionButtons={topActionButton}
      />
    </Dashboard>
  );
}
export default AddRally;
