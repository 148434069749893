/* eslint-disable react/no-array-index-key */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import React, { useEffect, useState, useCallback } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useTable from 'src/Hooks/useTable';
import Table from '@material-ui/core/Table';
import { TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import topActions from 'src/Types/Enums/EnumTopActions';
import SortType from 'src/Types/SortType';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import { SetStateSetterType } from 'src/Types/UseFormTypes';
import useStyles from './SettingsTableStyle';
import { Search, SubTitle } from '../Utils';
import TableContent from './TableContent';
import TableHeader from './TableHeader';
import PageBar from '../PageBar';
import Button from '../Button/Button';
import PopupButton, { PopupButtonProps } from '../Button/PopupButton';

export type ColumnType = {
  title: string;
  field: string;
  data: Function;
  isSortable: boolean;
};
type onClick = (_e: any) => void;

export type TopActionNormalButtonType = {
  type: topActions.button;
  extraProps: {
    name: string;
    onClick: onClick;
  };
};
export type TopActionLinkedButtonType = {
  type: topActions.linkedButton;
  extraProps: {
    to: string;
    name: string;
    permission?: EnumPermissions;
  };
};
export type TopActionFilterButtonType = {
  type: topActions.filter;
  extraProps: {
    id?: string;
    setId?: SetStateSetterType<string>;
    filterOptions?: { value: string; label: string }[];
    component?: any;
  };
};
export type TopActionButtonPopupType = {
  type: topActions.buttonPopup;
  extraProps: PopupButtonProps;
};
export type TopActionButtonType =
  | TopActionButtonPopupType
  | TopActionNormalButtonType
  | TopActionLinkedButtonType
  | TopActionFilterButtonType;
function CustomTable({
  columns,
  reload,
  tableName,
  heading,
  onDoubleClick,
  topActionButtons = [],
  searchSize = '',
  isNest,
  role,
  forceReload,
  rowPadding = '15px',
}: {
  tableName: string;
  columns: Array<ColumnType>;
  onDoubleClick?: any;
  isNest?: Boolean;
  forceReload?: string;
  // eslint-disable-next-line react/require-default-props
  topActionButtons?: TopActionButtonType[];
  // eslint-disable-next-line react/require-default-props
  participant?: string[];
  // eslint-disable-next-line no-shadow
  reload: ({
    sort,
    page,
    rowsPerPage,
    search,
  }: {
    sort: SortType;
    page: number;
    rowsPerPage: number;
    search: string;
  }) => Promise<{
    data: any;
    totalCount: number;
  }>;
  heading: string;
  // eslint-disable-next-line react/require-default-props
  searchSize?: string;
  setReloader?: Function;
  role?: string;
  rowPadding?: string;
}) {
  const sortableFieldArray = columns
    .filter((c) => c.isSortable === true)
    .map((c) => c.field);
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    debouncedSearch,
    rowsPerPage,
    sort,
    setSearch,
    changeSort,
  } = useTable({ tableName, sortableFields: sortableFieldArray, isNest });

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setData([]);
    setLoading(true);
    Promise.resolve(reload({ sort, page, rowsPerPage, search: debouncedSearch }))
      .then((response) => {
        setLoading(false);
        if (response && response.data && response.totalCount) {
          const { data: responseData, totalCount } = response;
          setTotalRecords(totalCount);
          setData(responseData);
        } else {
          setTotalRecords(0);
          setData([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [
    sort,
    page,
    rowsPerPage,
    debouncedSearch,
    setTotalRecords,
    setData,
    setLoading,
    topActionButtons,
    forceReload,
  ]);

  useEffect(() => {
    loadData();
  }, [sort, page, rowsPerPage, debouncedSearch, role, forceReload]);

  // if (setReloader) {
  //   useEffect(() => {
  //     setReloader(() => loadData);
  //   }, [loadData]);
  // }
  // eslint-disable-next-line consistent-return
  const showTopActionButton = (button: TopActionButtonType) => {
    // eslint-disable-next-line no-shadow
    if (!button) {
      return null;
    }

    switch (button.type) {
      case topActions.button: {
        return (
          <Button
            label={button.extraProps.name}
            onClick={() =>
              button.extraProps.onClick
                ? button.extraProps.onClick({ reload: loadData })
                : {}
            }
          />
        );
      }
      case topActions.buttonPopup: {
        return (
          <PopupButton
            component={button.extraProps.component}
            modalName={button.extraProps.modalName}
            label={button.extraProps.label}
            onClick={button.extraProps.onClick}
            sampleCsvHandleClick={button.extraProps.sampleCsvHandleClick}
            participant={button.extraProps.participant}
            loading={button.extraProps.loading}
          />
        );
      }
      case topActions.linkedButton: {
        return (
          <Link to={button.extraProps.to ?? ''} style={{ textDecoration: 'none' }}>
            <Button label={button.extraProps.name} onClick={() => {}} />
          </Link>
        );
      }
      case topActions.filter: {
        const { component: TableFilter, ...rest } = button.extraProps;
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <TableFilter {...rest} reload={loadData} />;
      }
      default: {
        console.log('Not implemented', button);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  const showSearchBar = (type: 'small' | 'large') => {
    switch (type) {
      case 'small':
        return (
          <Search
            className=""
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        );
      case 'large':
        return (
          <Search
            className={classes.search}
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
          />
        );
    }
  };

  return (
    <>
      <div className={classes.headerWrap}>
        <PageBar
          topActionButtons={topActionButtons}
          showTopActionButton={showTopActionButton}
          showSearchBar={showSearchBar}
        />
      </div>
      <div className={classes.boxType}>
        <div className={classes.wrapper}>
          <SubTitle title={heading} />
        </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((c) => {
                  if (c.isSortable === true) {
                    return (
                      <TableHeader
                        changeSort={changeSort}
                        sort={sort}
                        field={c.field}
                        label={c.title}
                        key={c.title}
                      />
                    );
                  }
                  return (
                    <TableCell
                      className={`${classes.theading} importantFontWeight`}
                      key={c.title}
                    >
                      {c.title}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableContent
                loading={loading}
                list={data}
                emptyText={t('others.No items found')}
              >
                {data.map((d, index) => (
                  <TableRow
                    hover
                    key={index}
                    onDoubleClick={() => onDoubleClick(d, loadData)}
                    style={{ cursor: 'pointer' }}
                  >
                    {columns.map((c) => (
                      <TableCell
                        className={classes.tablebody}
                        style={{ padding: rowPadding }}
                      >
                        {c.data(d, loadData, index)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableContent>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500, 999]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('others.Rows per page')}
        />
      </div>
    </>
  );
}

export default CustomTable;
