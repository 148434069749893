import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import {
  blockOrganizer,
  deleteOrganizer,
  deleteOrganizerContact,
} from 'src/Services/Auth/Actions/OrganizerActions';
import WarningToast from 'src/Components/Utils/WarningToast';
import useConfirmationDialog from 'src/Hooks/useConfirmationDialog';
import { commonResponseHandler } from 'src/Services/Utils';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import { ObjectType } from 'typescript';
import { blockUser, deleteUser } from '../../../Services/Auth/Actions/UserActions';

const useStyles = makeStyles(() => ({
  btn: {
    padding: '0px',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  blocks: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
}));

const ITEM_HEIGHT = 30;

type itemType = {
  _id: ObjectType;
  role: EnumUserRoles;
  isActive: boolean;
  activeEvents: number;
  completedEvents: number;
};

function AllUserDelete({ reload, item }: { reload: Function; item: itemType }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const confirmationDialog = useConfirmationDialog();

  const confirm: any = useConfirm();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showModalPopup = () => {
    let description;
    let apiFunction;
    switch (item.role) {
      case EnumUserRoles.organizer:
        if (item?.activeEvents > 0 || item?.completedEvents > 0) {
          WarningToast(
            t(
              "toast.It's not possible to delete this organiser since there are events to be organised",
            ),
          );
          return;
        }
        description = t('popup.Are you sure you want to delete the organiser?');
        apiFunction = () => {
          commonResponseHandler(deleteOrganizer(item._id), () => reload());
        };
        confirmationDialog(description, apiFunction, () => {});
        break;

      case EnumUserRoles.eventOrganizer:
        if (item?.activeEvents > 0 || item?.completedEvents > 0) {
          description = t(
            'popup.This event organizer is assigned to an event. Are you sure you want to delete?',
          );
        } else {
          description = t('popup.Are you sure you want to delete the event organiser?');
        }
        apiFunction = () => {
          commonResponseHandler(deleteOrganizerContact(item._id), () => reload());
        };
        confirmationDialog(description, apiFunction, () => {});
        break;

      default:
        if (item?.activeEvents > 0 || item?.completedEvents > 0) {
          description = `${t('toast.This participant active in event')} 
          ${t('popup.Are you sure you want to delete the participant?')}`;
        } else {
          description = `${t('toast.This participant may active in event')} ${t(
            'popup.Are you sure you want to delete the participant?',
          )}`;
        }
        apiFunction = () => {
          commonResponseHandler(deleteUser(item._id), () => reload());
        };
        confirmationDialog(description, apiFunction, () => {});
        break;
    }
  };

  const isActiveClick = () => {
    switch (item.role) {
      case EnumUserRoles.organizer:
        confirm({
          description: t('popup.Are you sure you want to change the block status?'),
        }).then(() => {
          blockOrganizer(item._id, { isActive: !item.isActive }).then((res) => {
            SuccessToast(res.data.message);
            reload();
          });
        });
        break;

      case EnumUserRoles.eventOrganizer:
        confirm({
          description: t('popup.Are you sure you want to change the block status?'),
        }).then(() => {
          blockOrganizer(item._id, { isActive: !item.isActive }).then((res) => {
            SuccessToast(res.data.message);
            reload();
          });
        });
        break;

      default:
        confirm({
          description: t('popup.Are you sure you want to change the block status?'),
        }).then(() => {
          blockUser(item._id, { isActive: !item.isActive }).then((res) => {
            SuccessToast(res.data.message);
            reload();
          });
        });
        break;
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'max-content',
            padding: 0,
          },
        }}
      >
        {item.isActive === true ? (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper1} onClick={() => isActiveClick()}>
              <Icon icon="ic:round-block" className={classes.blockIcon} />
              <p className={classes.blocks}>Block</p>{' '}
            </div>
          </MenuItem>
        ) : (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper1} onClick={() => isActiveClick()}>
              <Icon icon="gg:unblock" className={classes.unblockIcon} />
              <p className={classes.blocks}>Unblock</p>
            </div>
          </MenuItem>
        )}
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper1} onClick={showModalPopup}>
            <Icon icon="ant-design:delete-twotone" className={classes.icon} />
            <p className={classes.option}>{t('forms.Delete')} </p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AllUserDelete;
