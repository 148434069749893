import React, { useEffect, useState } from 'react';
import FieldLabel from 'src/Libs/DynamicForm/components/FormField/Fields/FieldLabel';
import {
  getEventsForAutoSelect,
  importParticipant,
} from 'src/Services/Auth/Actions/UserActions';
import { createErrorStringWithTranslation } from 'src/Services/Utils';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import WarningToast from 'src/Components/Utils/WarningToast';
import { FILETYPE } from 'src/Services/Constants';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import PageBarBase from 'src/Components/PageBar/PageBarBase';
import Button from 'src/Components/Button/Button';
import { sampleParticipantCSV } from 'src/Services/Auth/Actions/RallyActions';
import useDragAndDrop from 'src/Hooks/useDragandDrop';
import usePageBar from 'src/Hooks/usePageBar';
import useStyles from './UsersListingStyle';

function ParicipantImportCsv({ setOpenPopup, reloadlist }) {
  const classes = useStyles();
  const [eventList, setEventList] = useState([]);
  const [eventId, setEventId] = useState('');
  const { t } = useTranslation();
  const {
    handleDrop,
    handleFileSelection,
    handleDragLeave,
    handleDragEnter,
    handleDragOver,
    acceptedTypes,
    role,
    isDragging,
    selectedFile,
    fileInputRef,
    reset,
    renderMobileMenu,
  } = useDragAndDrop();

  const reload = () => {
    if (role !== 'participant') {
      getEventsForAutoSelect(role).then((response) => {
        const data = response.data.events;
        setEventList(data);
      });
    }
  };
  useEffect(() => {
    reload();
  }, []);

  const handleOptionChange = (e) => {
    setEventId(e.target.selectedOptions[0].value);
  };
  const sampleCsvHandleClick = () => {
    sampleParticipantCSV()
      .then((response) => {
        const outputFilename = `Participant list.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        ErrorToast(t('others.Something went wrong'));
      });
  };
  const submitCsv = (dataFile) => {
    if (
      (dataFile != null && dataFile.type === FILETYPE.XLSXFileType) ||
      (dataFile != null && dataFile.type === FILETYPE.CSVFileType)
    ) {
      if (!eventId) {
        ErrorToast(t('events.Choose an event!!'));
        return;
      }
      const data: any = {
        file: dataFile,
      };
      const formData = new FormData();
      Object.keys(data).map((k) => formData.append(k, data[k]));

      importParticipant(eventId, formData)
        .then((response) => {
          setOpenPopup(false);
          if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
          }

          if (response.data.error.length !== 0) {
            const error = createErrorStringWithTranslation(response);
            ErrorToast(error);
          } else {
            reloadlist();
            SuccessToast(t('Participant Imported'));
          }
          reload();
        })
        .catch(() => {
          ErrorToast(t('others.Something went wrong'));
        });
    } else {
      WarningToast(t('others.Please select a valid Excel file'));
    }
  };

  const handleChooseCSVChange = (e) => {
    submitCsv(e);
  };
  const { handleMobileMenuOpen } = usePageBar();

  return (
    <div>
      <PageBarBase
        popUp
        handleMobileMenuOpen={handleMobileMenuOpen}
        rightContent={
          <>
            {selectedFile && (
              <span style={{ marginRight: '20px', color: 'black' }}>
                {selectedFile.name}
              </span>
            )}
            <Button onClick={() => handleChooseCSVChange(selectedFile)} label="Upload" />
            <Button onClick={() => reset()} label="Reset" />
            <Tooltip title={t('General settings.Download Sample CSV')}>
              <Button
                onClick={() => sampleCsvHandleClick()}
                label={t('others.Sample CSV')}
              />
            </Tooltip>
          </>
        }
      />
      {renderMobileMenu(handleChooseCSVChange, false, sampleCsvHandleClick, selectedFile)}
      <div className={classes.selectinput2}>
        <FieldLabel name="Target Event" />
        <select
          name="targetEvent"
          onChange={(e) => {
            handleOptionChange(e);
          }}
        >
          <option value="" label={t('forms.Select')} />
          {eventList.map((item: any) => (
            <option key={item._id} value={item._id} label={`${item.eventName}`} />
          ))}
        </select>
      </div>
      <div
        style={{
          border: '2px dashed gray',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: isDragging ? 'lightyellow' : 'white',
          cursor: 'pointer',
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files) {
              handleFileSelection(e.target.files);
            }
          }}
          accept={acceptedTypes}
        />
        {!selectedFile ? (
          <p>Drag & drop an Excel file here or click to select one</p>
        ) : (
          <p>Selected: {selectedFile.name}</p>
        )}
      </div>
    </div>
  );
}

export default ParicipantImportCsv;
