import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import theme from '../../../Theme';

export default makeStyles(() => ({
  thead: {
    background: '#bf2331',
    position: 'sticky',
    top: '0px',
    zIndex: 1,
  },
  tcell: {
    width: '1px',
  },
  table: {
    borderColor: '#bf2331',
  },
  tablecontainer: {
    width: '100%',
    border: '1px solid #bf2331',
    height: 'auto',
    maxHeight: '400px',
  },
  tablecontainerModal: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '17px',
    width: '75%',
    overflow: 'auto',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  eyeIcon: {
    color: colors.primary.color,
    fontSize: '20px',
  },
  tablehead: {
    color: '#fff',
  },
  tablehead1: {
    color: '#fff',
    paddingLeft: '3rem',
  },
  actionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px 0',
    position: 'relative',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  link: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    color: '#9FA2B4',
    flex: 1,
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  fileName: {
    cursor: 'pointer',
    height: '40px',
    padding: '10px',
  },
}));
