import { MutableRefObject } from 'react';
import { TableHeaders } from './table/types/table';

export const generatestyle = (styles) => {
  const returnValue: any = {};
  if (styles?.fontSize) {
    returnValue.fontSize = styles?.fontSize;
  }
  if (styles?.color) {
    returnValue.color = styles?.color;
  }
  if (styles?.fontWeight) {
    returnValue.fontWeight = styles?.fontWeight;
  }
  if (styles?.textAlign) {
    returnValue.textAlign = styles?.textAlign;
  }
  return returnValue;
};

export const getCheckBoxValue = (data) =>
  data.values && data.values[0] && data.values[0] === 'checked' ? 'checked' : '';

// It will create header section for the GetTableField component
export const createHeaders = (
  headers: string[],
  ref: MutableRefObject<any[]>,
): TableHeaders[] =>
  headers.map((item: string, index: number) => ({
    text: item,
    ref: ref.current[index],
  }));

// Min cell width of a table
export const MIN_CELL_WIDTH: number = 120;

// Temp table headers
export const TABLE_HEADERS: string[] = ['COL1', 'COL2'];

export const tableExtraStyles = (totalColumns: number, styles) => {
  // console.log(styles, 'stylesstyles');
  const styleObj = {
    gridTemplateColumns: styles?.gridTemplateColumns
      ? styles?.gridTemplateColumns
      : `repeat(${totalColumns}, 1fr)`,
    borderRadius: styles?.borderRadius,
    border: `2px solid ${styles?.borderColor ? styles?.borderColor : 'black'}`,
  };

  return styleObj;
};
