import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'src/Components/Header/Header';
import TableReportslisting from 'src/Containers/TableReports/TableReportslisting';
import Dashboard from 'src/Layouts/Dashboard/Dashboard';

function TableReport() {
  const { t } = useTranslation();

  return (
    <Dashboard>
      <Header title={t('others.Table Reports')} />
      <TableReportslisting />

      {/* <FormsListing /> */}

      {/* <BreadCrumbs/> */}
    </Dashboard>
  );
}

export default TableReport;
