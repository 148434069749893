import { makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import typography from 'src/Theme/typography';
import { FC } from 'react';
import { Loader } from 'src/Services/Utils';

const styles = makeStyles((theme: Theme) => ({
  inviteButton: {
    margin: '0px',
    padding: '10px',
    minWidth: '100px',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    width: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
    position: 'relative',
  },
}));

export type ButtonProps = {
  onClick: (_e: any) => void;
  label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: boolean;
};

const Button: FC<ButtonProps> = ({ onClick, label, loading }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <div>
      <Loader isLoading={loading} type="ThreeDots">
        <button
          type="button"
          name="Choose CSV"
          onClick={onClick}
          className={classes.inviteButton}
        >
          {t(label)}
        </button>
      </Loader>
    </div>
  );
};

export default Button;
