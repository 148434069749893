import * as Yup from 'yup';

const RallyValidations = Yup.object().shape({
  rallyName: Yup.string()
    .matches(/[a-z-A-Z]+/g, 'validationErrors.use at least one alphabet')
    .min(3, 'validationErrors.Rally name is too Short!')
    .required('validationErrors.Rally name is Required'),
  rallyType: Yup.string().required('validationErrors.Rally Type is Required'),
  startDateTime: Yup.date()
    .required('validationErrors.Start Date Time is Required')
    .nullable(true),
  endDateTime: Yup.date()
    .min(Yup.ref('startDateTime'), 'validationErrors.End date cant be before Start date')
    .required('validationErrors.End Date Time is Required')
    .nullable(true),
  memo: Yup.string(),
});
export default RallyValidations;
