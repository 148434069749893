import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/Store';

function RallyParticipantCount({ item, id, rallyOrgId }) {
  const role = useSelector((store: RootState) => store.role.role);
  const roleId = useSelector((store: RootState) => store.role.user?.organizationId);
  return role === 'superadmin' || (role === 'organizer' && roleId === rallyOrgId) ? (
    <>
      {item.isCancelled ? (
        <div>{item.participants.length}</div>
      ) : (
        <Link
          to={{
            pathname: `/events/rally/add-participant/${id}/${item._id}`,
          }}
        >
          <div>{item.participants.length}</div>
        </Link>
      )}
    </>
  ) : (
    <div>{item.participants.length}</div>
  );
}

export default RallyParticipantCount;
