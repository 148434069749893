import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { Form, Formik } from 'formik';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import useStyles from '../../../newEventFormStyle';
import DrawFormFields from './DrawFormFields';

function LeftFormGroups({
  leftVisibleTabData,
  changeFormType,
  selectAllRight,
  selectAllInnerTab,
  addForm,
  addSelected,
  removed,
  selected,
}: {
  leftVisibleTabData: FormFieldEditType[];
  changeFormType: Function;
  selectAllRight: Function;
  selectAllInnerTab: Function;
  addForm: Function;
  addSelected: Function;
  removed: FormFieldEditType[];
  selected: FormFieldEditType[];
}) {
  const classes = useStyles();

  type FieldGroup = {
    formFields: Array<FormFieldEditType>;
    groupName: string;
    _id: ObjectIdType;
  };
  const childGroupMap: {
    [key: string]: {
      groupName: string;
      _id: ObjectIdType;
    };
  } = {};

  leftVisibleTabData.forEach((data) => {
    if (data.fieldGroup) {
      childGroupMap[data.fieldGroup._id] = data.fieldGroup;
    }
  });

  const groupData: Array<FieldGroup> = Object.keys(childGroupMap).map((key) => {
    const { _id, groupName } = childGroupMap[key];
    return {
      formFields: leftVisibleTabData.filter(
        (data) => data.fieldGroup && data.fieldGroup._id === _id,
      ),
      groupName,
      _id,
    };
  });

  return (
    <>
      {groupData.map((gData) => (
        <Accordion
          onClick={() => changeFormType(gData.groupName, gData._id)}
          key={gData._id}
          className={classes.accordianStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{gData.groupName}</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.innerAccordianRoot }}>
            <Typography>
              <Formik initialValues={{}} onSubmit={() => {}}>
                <Form>
                  <div
                    className={classes.selectAll}
                    onClick={() => {
                      selectAllRight(gData._id);
                    }}
                  >
                    {selectAllInnerTab(gData._id)}
                  </div>
                  {gData.formFields.map((data: any) => (
                    <DrawFormFields
                      data={data}
                      addForm={addForm}
                      addSelected={addSelected}
                      removed={removed}
                      selected={selected}
                    />
                  ))}
                </Form>
              </Formik>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default LeftFormGroups;
