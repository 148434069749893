import { ThemeProvider } from '@material-ui/core';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';
// import { Loader } from './Components/Utils';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './Store';
import theme from './Theme';
import Routes from './routes';
import './App.css';
import ConfirmProvider from './Libs/ConfirmationDialog/ConfirmProvider';
import ModalPopupProvider from './Libs/ModalPopup/ModalPopupProvider';
import LanguageProvider from './Services/LanguageProvider';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('persist:root');
      store.store.dispatch({
        type: 'SAVE_TOKEN',
        payload: '',
      });
      store.store.dispatch({
        type: 'SAVE_ROLE',
        payload: '',
      });
      store.store.dispatch({
        type: 'SAVE_USER',
        payload: {},
      });
      if (window.location.pathname.split('/')[1] !== 'event-details') {
        localStorage.removeItem('persist:root');
        store.store.dispatch({
          type: 'SAVE_TOKEN',
          payload: '',
        });
        window.location.href = '/';
      }
    }
    throw error;
  },
);

function App() {
  toast.configure();
  return (
    <div className="App">
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <LanguageProvider>
            <ThemeProvider theme={theme}>
              <Router>
                <ConfirmProvider>
                  <ModalPopupProvider>
                    <Routes />
                  </ModalPopupProvider>
                </ConfirmProvider>
              </Router>
            </ThemeProvider>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
