import { makeStyles } from '@material-ui/core';
import theme from 'src/Theme';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  leftWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media all and (max-width: 650px)': {
      flexDirection: 'column',
    },
  },
  boxType: {
    padding: '20px',
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    // boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 750px)': {
      margin: '0',
      display: 'flex',
      flexDirection: 'column',
    },
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
    '@media all and (min-width: 1920px)': {
      height: '80vh', // Custom height for big screens (e.g., 32 inches or higher)
    },
  },
  mainWrap: {
    backgroundColor: '#fff',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media only screen and (max-width: 450px)': {
      margin: '40px 0px 0px',
      width: '330px',
    },
    '@media all and (max-width:767px)': {
      padding: 0,
      // margin: "30px 0px 20px",
      width: 'auto',
    },
    width: '50%',
  },

  main: {
    display: 'flex',
    justifyContent: 'space-around',
    maxHeight: '636px',
    '@media all and (max-width: 450px)': {
      maxHeight: 'fit-content',
      margin: 0,
      flexDirection: 'column',
    },
    '@media all and (min-width: 450px) and (max-width:767px)': {
      flexDirection: 'column',
      margin: 'auto',
      maxHeight: 'fit-content',
    },
    '@media all and (min-width: 1920px)': {
      maxHeight: '68vh',
      height: '90%',
    },
  },
  imgWrap: {
    // maxWidth: "300px",
    margin: '0 auto 0 auto',
    // borderBottom: "1px solid #9FA2B4",
    '@media all and (min-width: 1920px)': {
      height: '60%',
    },
  },
  submitBox: {
    maxHeight: '200px',
    minHeight: '150px',
    overflowY: 'auto',
    '@media all and (min-width: 1920px)': {
      height: '315px',
    },
  },
  img: {
    width: '85px',
    height: '80px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DFE0EB',
    boxShadow: '0 12px 15px -20px #333',
    maxWidth: '400px',
    margin: '0 auto 20px',
    '@media all and (max-width:767px)': {
      flexDirection: 'column',
    },
  },
  border: {
    padding: '0 20px',
    '&:firt-of-type': {
      borderRight: '1px solid #9FA2B4',
    },
  },
  p: {
    fontFamily: typography.fonts.secondary,
    fontSize: '14px',
    fontWeight: 400,
    color: colors.secondary.color,
    maxWidth: '250px',
    margin: '5px auto',
    width: '150px',
    wordBreak: 'break-word',
  },
  h5: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 600,
    fontSize: '15px',
    color: '#252733',
    margin: 0,
  },
  tables: {
    paddingLeft: '25px',
    paddingRight: '25px',
    maxHeight: '100px',
  },
  status: {
    fontFamily: typography.fonts.secondary,
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 400,
    color: colors.secondary.color,
    paddingLeft: '5px',
  },
  statusWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 40px 0 0',
    '@media all and (min-width: 1920px)': {
      padding: '0 35px 0 0',
    },
  },
  btnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  btnReinstate: {
    width: '128px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    cursor: 'pointer',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },
  inputlabel: {
    marginLeft: '13px',
    cursor: 'pointer',
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
    marginTop: '10px',
    width: 'auto',
    gap: '100px',
    '@media all and (max-width: 450px)': {
      flexWrap: 'wrap',
      width: 'auto',
      gap: 'unset',
      padding: '0 43px !important',
    },
  },
  btn: {
    width: '40%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    background: 'white',
    color: '#43A047 !important',
    border: '1px solid #43A047 !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  btnA: {
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    background: '#43A047',
    color: 'white !important',
    fontWeight: 700,
    boxShadow: 'none',
    '&:hover': {
      background: '#43A047',
    },
    marginLeft: '-40px',
  },
  btnB: {
    width: '95%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    background: '#E40909',
    color: 'white !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#E40909',
    },
    marginLeft: '-40px',
    fontWeight: 700,
  },
  btnRed: {
    width: '40%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    background: 'white',
    color: '#E40909 !important',
    border: '1px solid #E40909 !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  icon: {
    color: colors.primary.color,
    cursor: 'pointer',
    fontSize: '35px',
    marginRight: '10px ',
  },
  title: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: colors.ternary.color,
    textTransform: 'uppercase',
    margin: 0,
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 30px 10px',
    background: '#dfe0eb40',
    alignItems: 'center',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    '@media all and (max-width: 750px)': {
      padding: '10px',
    },
  },
  notFound: { alignItems: 'center', marginTop: '20px' },
  btnC: {
    width: '95%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    background: '#43A047',
    color: 'white !important',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#43A047',
    },
    marginLeft: '-40px',
    fontWeight: 'bold',
  },
  cancelled: {
    fontWeight: 'bold',
    height: '35px',
    width: '91%',
    backgroundColor: '#E40909',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '100px',
    color: '#fff',
    textTransform: 'uppercase',
    marginTop: '30px',
    fontSize: '14px',
    padding: '6px',
  },
  approved: {
    fontWeight: 'bold',
    height: '35px',
    width: '91%',
    backgroundColor: '#29CC97',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '100px',
    color: '#fff',
    textTransform: 'uppercase',
    marginTop: '30px',
    fontSize: '14px',
    padding: '6px',
  },
  inputContainer: {
    '@media all and (max-width: 750px)': {
      paddingLeft: '32px !important',
    },
    paddingLeft: '42px !important',
  },
  downloadButton: {
    boxShadow: 'none',
  },
  // filterButton: {
  //   padding: '0px',
  //   marginLeft: '10px',
  // },
  // titleFilter: {
  //   fontSize: '14px',
  //   fontFamily: 'Mulish',
  //   padding: '0 15px 20px',
  //   fontWeight: 500,
  //   borderBottom: '1px solid #D4D5DE',
  // },
  // option: {
  //   fontSize: '14px',
  //   lineHeight: '20px',
  //   margin: '0',
  //   fontFamily: 'Mulish',
  // },
  editIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '20px',
  },
}));
