import React from 'react';

type PageBarTypes = {
  handleMobileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMobileMenuClose: (e: React.DragEvent<HTMLDivElement>) => void;
  mobileMoreAnchorEl: HTMLElement | null;
  mobileMenuId: string;
  isMobileMenuOpen: boolean;
};
const usePageBar = (): PageBarTypes => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const mobileMenuId = 'primary-search-account-menu-mobile';
  return {
    handleMobileMenuOpen,
    handleMobileMenuClose,
    mobileMoreAnchorEl,
    mobileMenuId,
    isMobileMenuOpen,
  };
};

export default usePageBar;
