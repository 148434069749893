import React from 'react';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import ManageParticipants from '../../Containers/Events/EventParticipants/ManageParticipants';
// import RallyFormPreview from '../../Containers/Rallies/RalliesForm';
// ManageParticipants

function ManageParticipant() {
  return (
    <div>
      <Dashboard>
        <>
          <Header title="Events" />
          <ManageParticipants />
        </>
      </Dashboard>
    </div>
  );
}

export default ManageParticipant;
