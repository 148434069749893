import React, { useContext } from 'react';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { SetStateSetterType } from 'src/Types/UseFormTypes';
import useStyles from '../../../newEventFormStyle';

function FormSettings({
  isDraggable,
  setIsDraggable,
  headerData,
  setHeaderData,
  setRemoved,
}: {
  isDraggable: boolean;
  setIsDraggable: SetStateSetterType<boolean>;
  headerData: boolean;
  setHeaderData: SetStateSetterType<boolean>;
  setRemoved: SetStateSetterType<FormFieldEditType[]>;
}) {
  const handleChange = () => {
    setIsDraggable((prev) => !prev);
  };
  const classes = useStyles();
  const { removed } = useContext(DynamicFormContext);
  const { t } = useTranslation();

  const changeHeaderSwitch = (e) => {
    setHeaderData(e.target.checked);
  };
  const currentTable = removed.filter((data: FormFieldEditType) => data.type === 'table');

  return (
    <div className={classes.formSettingsWrapperStyle}>
      <div>
        <label htmlFor="inputField">{t('forms.Set headers for each page')} </label>{' '}
        <Switch
          checked={headerData}
          onChange={(e) => {
            changeHeaderSwitch(e);
          }}
          name="header"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
      {currentTable.length !== 0 || !isDraggable ? (
        <div className={classes.tableSettingsWrapperStyle}>
          &nbsp;&nbsp;
          <label htmlFor="inputField">{t('forms.To adjust tables')}</label>
          <Switch
            checked={!isDraggable}
            onChange={handleChange}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
      ) : null}
      <div
        onClick={() => setRemoved([])}
        className={classes.deSelectSettingsWrapperStyle}
      >
        {removed.length > 0 &&
          `${t('others.Deselect')} ${removed.length} ${t('others.items')}`}
      </div>
    </div>
  );
}

export default FormSettings;
