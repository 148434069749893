import React from 'react';
import { Icon } from '@iconify/react';
import useStyles from './EventListingStyle';
import EventListRedirection from './EventListRedirection';

function AuthActions({ item }) {
  const classes = useStyles();

  const createAction = (pathname, icon) => (
    <div key={pathname}>
      <EventListRedirection
        pathname={pathname}
        item={item}
        icon={<Icon icon={icon} className={classes.editIcon} />}
        count={null}
      />
    </div>
  );

  const actionData = [
    { pathname: 'details', icon: 'feather:edit-3' },
    { pathname: 'rallies', icon: 'clarity:flag-solid' },
    { pathname: 'participants', icon: 'akar-icons:people-group' },
  ];

  return (
    <div className={classes.tableaction01}>
      {actionData.map(({ pathname, icon }) => createAction(pathname, icon))}
    </div>
  );
}

export default AuthActions;
