import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/Store';
import { useState } from 'react';
import { Loader } from 'src/Services/Utils';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import moment from 'moment';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import WarningToast from 'src/Components/Utils/WarningToast';
import { RallyType } from 'src/Types/RallyType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import useEventEnrollNow from 'src/Hooks/useEventEnrollNow';
import { getToken, rallyEnroll } from '../../../../Services/Auth/Actions/RallyActions';
import theme from '../../../../Theme';

const useStyles = makeStyles({
  lightred: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
  },
  lightgreen: {
    backgroundColor: '#43a047',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#008000',
      color: '#fff',
    },
  },
  ash: {
    backgroundColor: '#C4C4C4',
    color: '#4c4c4c',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
    cursor: 'pointer',
  },
});

type EnrollStatusLabelType = {
  item: RallyType;
  isEventEnrolled: boolean;
  reload: Function;
  isApproved: boolean | undefined;
  eventStatus: string;
};

function EnrollStatusLabel({
  item,
  isEventEnrolled,
  reload,
  isApproved,
  eventStatus,
}: EnrollStatusLabelType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const roleId = useSelector((store: RootState) => store.role.user?._id);
  const role = useSelector((store: RootState) => store.role.role);
  const history = useHistory();
  const [isEnrolling, setIsEnrolling] = useState(false);
  const { enrollNowValidation } = useEventEnrollNow();

  // eslint-disable-next-line no-shadow
  enum enumEnrollStatus {
    EnrollDecline = 'Enroll Decline',
    Enrolled = 'Enrolled',
    EnrollNow = 'Enroll Now',
  }

  const enrollNowRally = () => {
    setIsEnrolling(true);
    const currentDateTime = moment();
    if (!enrollNowValidation(eventStatus)) {
      setIsEnrolling(false);
      return;
    }
    if (moment(item.endDateTime) < currentDateTime) {
      setIsEnrolling(false);
      WarningToast(t('Enroll Decline, This rally is completed'));
      return;
    }
    if (item.isCancelled === true) {
      setIsEnrolling(false);
      WarningToast(t('toast.Enroll Decline, This rally is Cancelled'));
      return;
    }
    if (
      [
        EnumUserRoles.participant,
        EnumUserRoles.organizer,
        EnumUserRoles.eventOrganizer,
      ].includes(role) &&
      getToken()
    ) {
      const data: {
        eventId: ObjectIdType;
        userId: ObjectIdType;
        rallyId: [ObjectIdType];
      } = {
        eventId: item.eventId,
        userId: roleId,
        rallyId: [item._id],
      };
      const enrollDetail = JSON.stringify(data);

      if (isEventEnrolled) {
        if (isApproved === true) {
          rallyEnroll(enrollDetail).then(() => {
            reload();
          });
          return;
        }
        if (isApproved === false) {
          setIsEnrolling(false);
          WarningToast(t('toast.You were declined by Organiser'));
          return;
        }
        setIsEnrolling(false);
        WarningToast(t('toast.Your approval is in pending'));
        return;
      }
      setIsEnrolling(false);
      WarningToast(t('toast.Enroll in Event First'));
      return;
    }
    if (role === EnumUserRoles.superadmin && getToken()) {
      setIsEnrolling(false);
      ErrorToast(t('popup.You cannot participate in this rally / event'));
      setTimeout(() => history.push(`/dashboard`), 2000);
      return;
    }
    if (enrollNowValidation(eventStatus)) {
      history.push(`/register?eventId=${item.eventId}&rallyId=${item._id}`);
    }
  };

  switch (item.enrollStatus) {
    case enumEnrollStatus.EnrollDecline:
      return <div className={classes.ash}>{t(`${enumEnrollStatus.EnrollDecline}`)}</div>;

    case enumEnrollStatus.Enrolled:
      return (
        <div className={classes.lightgreen}>{t(`${enumEnrollStatus.Enrolled}`)}</div>
      );

    case enumEnrollStatus.EnrollNow:
      return (
        <Loader isLoading={isEnrolling} type="ThreeDots">
          <div onClick={enrollNowRally} className={classes.lightred}>
            {t(`${enumEnrollStatus.EnrollNow}`)}
          </div>
        </Loader>
      );
    default:
      return (
        <Loader isLoading={isEnrolling} type="ThreeDots">
          <div onClick={enrollNowRally} className={classes.lightred}>
            {t(`${enumEnrollStatus.EnrollNow}`)}
          </div>
        </Loader>
      );
  }
}
export default EnrollStatusLabel;
