import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'src/Components/Header/Header';
import FormsListing from 'src/Containers/Events/Forms/FormsListing';
import Dashboard from 'src/Layouts/Dashboard/Dashboard';

function Forms() {
  const { t } = useTranslation();

  return (
    <Dashboard>
      <Header title={t('events.Forms')} />

      <FormsListing />

      {/* <BreadCrumbs/> */}
    </Dashboard>
  );
}

export default Forms;
