/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

import TableActionButton from 'src/Components/Table/TableActionButton';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import CustomTable from 'src/Components/Table/Table';
import { EventType } from 'src/Types/EventType';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import { formateDate } from 'src/Services/Utils';
import OrganizerDelete from '../../Organizers/OrganizerListing/OrganizerDelete';
import {
  newOrganiserListing,
  blockOrganizer,
  newOrganiserContactListing,
} from '../../../Services/Auth/Actions/OrganizerActions';
import useStyles from '../../Organizers/OrganizerListing/OrganizerListingStyle';
import AVATAR from '../../Organizers/OrganizerListing/compregLogo.png';

//   root: {
//     width: '100%',
//   },
//   container: {
//     maxHeight: 440,
//     '@media all and (min-width: 1440px) and (min-height: 800px)': {
//       maxHeight: '65vh !important',
//     },
//   },
//   thead: {
//     color: '#9FA2B4',
//     fontWeight: 700,
//     backgroundColor: '#fff',
//     fontSize: '14px',
//     fontFamily: typography.fonts.secondary,
//   },
//   FilledButton: {
//     padding: '7px 7px',
//     fontSize: '14px',
//   },
//   tbody: {
//     padding: '20px 15px',
//     fontFamily: typography.fonts.secondary,
//     textOverflow: 'ellipsis',
//     width: '400px',
//     wordBreak: 'break-word',
//   },
//   editIcon: {
//     color: colors.primary.color,
//     fontSize: '20px',
//     cursor: 'pointer',
//   },
//   deleteIcon: {
//     color: '#E40909',
//     fontSize: '20px',
//     cursor: 'pointer',
//   },
//   arrowIcon: {
//     position: 'relative',
//     top: '2px',
//     left: '5px',
//     cursor: 'pointer',
//   },
//   arrowIconActive: {
//     position: 'relative',
//     top: '2px',
//     left: '5px',
//     cursor: 'pointer',
//     color: colors.primary.color,
//   },
//   boxType: {
//     padding: '20px',
//     margin: '20px',
//     border: '1px solid #DFE0EB',
//     boxSizing: 'border-box',
//     boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
//     borderRadius: '18px',
//     background: '#FFFFFF',
//     '@media all and (max-width: 650px)': {
//       margin: '0',
//     },
//     '@media all and (min-width: 1440px)': {
//       maxHeight: '100vh',
//     },
//   },
//   wrapper: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   wrapper1: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-around',
//   },
//   grey: {
//     margin: '0',
//     fontFamily: typography.fonts.secondary,
//     fontSize: '10px',
//     color: '#ADAEB0',
//     fontStyle: 'normal',
//     fontWeight: 'normal',
//     lineHeight: '16px',
//     letterSpacing: '0.1px',
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: '2px solid transparent',
//     boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
//     borderRadius: '17px',
//     width: '30%',
//     padding: '30px 0',
//     '@media all and (max-width: 1200px)': {
//       width: '50%',
//     },
//     '@media all and (max-width: 650px)': {
//       width: '97%',
//     },
//   },
//   title: {
//     ...typography.h5,
//     marginBottom: '25px',
//   },

//   sign: {
//     display: 'flex',
//     justifyContent: 'space-around',
//     fontSize: '14px',
//     fontWeight: 700,
//     textAlign: 'center',
//     maxWidth: '230px',
//     margin: '0 auto',
//   },
//   desc: {
//     fontWeight: 700,
//     textAlign: 'center',
//     maxWidth: '230px',
//     margin: '0 auto 32px',
//     '&a': {
//       color: colors.primary.color,
//     },
//   },
//   icons: {
//     fontSize: '20px',
//     cursor: 'pointer',
//     marginTop: '-30px',
//     marginRight: '10px',
//     color: '#9FA2B4',
//     flex: 1,
//     left: '45%',
//     position: 'relative',
//   },
//   link: {
//     cursor: 'pointer',
//     color: colors.secondary.color,
//     '&:hover': {
//       color: colors.primary.color,
//     },
//   },
//   wrapper12: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   blockIcon: {
//     color: '#E15405',
//     fontSize: '20px',
//     cursor: 'pointer',
//   },
//   unblockIcon: {
//     color: '#155729',
//     fontSize: '24px',
//     cursor: 'pointer',
//   },
//   button: {
//     margin: 0,
//     border: 'none',
//     backgroundColor: 'transparent',
//   },
// });

function NewOrganizersListing({ reloader, type }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const confirm: any = useConfirm();
  const [header, setheader] = useState<string>('New Organisers Listing');

  useEffect(() => {
    if (type === 'eventOrganizers') {
      setheader('New Organiser Contacts Listing');
    }
  }, [type]);

  // eslint-disable-next-line no-shadow
  const isActiveClick = (organizer, reload) => {
    confirm({
      description: t('popup.Are you sure you want to change the block status?'),
    }).then(() => {
      blockOrganizer(organizer._id, { isActive: !organizer.isActive }).then(() => {
        if (organizer.isActive) {
          SuccessToast(t('organizers.organizerContactBlocked'));
        } else {
          SuccessToast(t('organizers.organizerContactUnBlocked'));
        }
        reload();
      });
    });
  };

  // eslint-disable-next-line no-shadow
  const functionBlock = (row, reload) => {
    if (row.isActive === true) {
      return isActiveClick(row, reload);
    }
    return isActiveClick(row, reload);
  };

  const blockIcon = (row) => {
    if (row.isActive === true) {
      return 'gg:unblock';
    }
    return 'ic:round-block';
  };

  const iconClass = (row) => {
    if (row.isActive === true) {
      return classes.unblockIcon;
    }
    return classes.blockIcon;
  };

  const columns = [
    {
      title: t('organizers.Company/Full Name'),
      field: 'fullName',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <div className={classes.imgWrap}>
              <img
                src={row.profileImage || AVATAR}
                className={classes.img}
                alt="avatar"
              />
            </div>
            <Tooltip title={row.username}>
              <div style={{ width: '170px' }}>
                <div className={classes.tbodyName}>
                  <div className={classes.orgName}>
                    {row.companyname ||
                      (row.firstName && `${row.lastName} ${row.firstName}`)}
                  </div>
                  <p className={classes.grey}>{row.username}</p>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Phone'),
      field: 'phone',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (row) => row?.phone,
      isSortable: true,
    },
    {
      title: t('forms.Email'),
      field: 'emailSrt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => row?.email,
      isSortable: true,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => <p className={classes.grey}>{formateDate(row.createdAt)}</p>,
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components, no-return-assign
      data: (
        row: {
          organizer: any;
          _id: string;
          organizationId: string;
          events: Array<EventType>;
        },
        reload,
      ) => (
        <div style={{ display: 'flex' }}>
          <TableActionButton
            button={[
              {
                icon: 'feather:edit-3',
                onClick: () =>
                  history.push({
                    pathname: `organizers/details/${row.organizationId}/profile`,
                    state: { showGoBack: true },
                  }),
                class: '',
              },
              {
                icon: blockIcon(row),
                onClick: () => functionBlock(row, reload),
                class: iconClass(row),
              },
            ]}
          />
          <div>
            <OrganizerDelete
              reload={reload}
              id={row._id}
              eventCount={row.events?.length}
            />
          </div>
        </div>
      ),
      isSortable: false,
    },
  ];

  const reload = async ({ sort, page, rowsPerPage, search }) => {
    reloader();
    let result;
    if (type === 'eventOrganizers') {
      result = await newOrganiserContactListing({
        sortBy: sort.sortBy,
        sort: sort.sort,
        page,
        pageSize: rowsPerPage,
        search,
      });
    } else {
      result = await newOrganiserListing({
        sortBy: sort.sortBy,
        sort: sort.sort,
        page,
        pageSize: rowsPerPage,
        search,
      });
    }
    const data = result.data.users;
    const { totalCount } = result.data;
    return {
      data,
      totalCount,
    };
  };

  return (
    <div>
      <CustomTable
        tableName="neworganisers"
        columns={columns}
        reload={reload}
        heading={t(`${header}`)}
        onDoubleClick={(item) =>
          history.push({
            pathname: `organizers/details/${item.organizationId}/profile`,
            state: { showGoBack: true },
          })
        }
        searchSize="small"
      />
    </div>
  );
}

export default NewOrganizersListing;
