import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  textWrap: {
    width: '100%',
  },

  p: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#bf2331',
    textAlign: 'left',
    width: '100%',
  },

  span: {
    float: 'right',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
