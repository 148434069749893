import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { listingAllUser } from 'src/Services/Auth/Actions/UserActions';
import theme from 'src/Theme';
import colors from 'src/Theme/colors';
import moment from 'moment';
import Select from 'react-select';
import CopyToClipboard from 'react-copy-to-clipboard';
import { url } from 'src/Services/Constants';
import AddEventParticipantsPopup from 'src/Containers/Events/EventParticipants/AddEventParticipantsPopup';
import useAuthorizedUserClick from 'src/Hooks/useAuthorizedUserClick';
import ErrorToast from './ErrorToast';
import SuccessToast from './SuccessToast';
import AVATAR from './AvatarImage.svg';
import COPY from './CopyLink.svg';
import MAIL from './Email.svg';
import FormLabel from './FormLabel';
import FormButton from './FormButton';

const useStyles = makeStyles(() => ({
  shareIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    cursor: 'pointer',
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  shareName: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.2px',
    color: '#252733',
    marginTop: '5px',
    wordBreak: 'break-word',
  },
  time: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    color: '#ADAEB0',
    marginTop: '5px',
  },
  venue: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#252733',
  },
  options: {
    '&__value-container': {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '20px',
      width: '180px',
    },
    '&__menu-list': {
      width: '220px !important',
      maxHeight: '235px !important',
    },
  },
  btn: {
    alignSelf: 'flex-start',
    margin: '0px',
    marginLeft: '12px',
    marginTop: '37px',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userNameSelect: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    marginTop: '37px',
    textTransform: 'none',
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
  },
}));

const boxStyles = {
  option: (provided, state) => ({
    ...provided,
    maxWidth: '220px',
  }),
};

function ShareModal({ item, load, sendUserInvitation, setOpenPopup }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [noUsername, setNoUsername] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [usernameList, setUsernameList] = useState([]);

  const modal = useModalDialog();
  const OpenAddParticipantsPopup = useMemo(
    () => modal(AddEventParticipantsPopup, 'share'),
    [],
  );

  const copyLink = item?.eventId
    ? `${url}/event-details/${item.eventId}/${item._id}`
    : `${url}/event-details/${item._id}`;
  useEffect(() => {
    listingAllUser({
      search: '',
    }).then((response) => {
      let user = response.data.users;
      user = user.map((data) => ({ label: data.username, value: data.username }));
      setUsernameList(user);
    });
  }, []);

  const copyToClipboard = () => async (e) => {
    e.preventDefault();
    SuccessToast(t('forms.Text copied to clipboard'));
    setOpenPopup(false);
  };

  const OnClose = () => {
    setOpenPopup(false);
  };

  const reload = () => {
    load();
    OnClose();
  };

  const sendInvitation = (e) => {
    e.preventDefault();
    if (!username) {
      setNoUsername(true);
      return;
    }
    setLoading(true);
    const data: any = item?.eventId
      ? {
          username,
          eventId: item.eventId,
          rallyId: [item._id],
        }
      : {
          username,
          eventId: item._id,
        };
    sendUserInvitation(data)
      .then((response) => {
        SuccessToast(`${t(response.data.message)}`);
        load();
        setOpenPopup(false);
      })
      .catch(() => {
        ErrorToast(t(`events.userEventExist`));
        OpenAddParticipantsPopup(true, {
          details: item?.eventId ? item?.eventId : item._id,
          rally: item?.eventId ? item._id : null,
          reload,
        });
        setOpenPopup(false);
      });
  };
  const onChange = (e) => {
    setUsername(e.value);
    setNoUsername(false);
  };

  return (
    <div>
      <h3 id="transition-modal-description" className={classes.title}>
        {t('others.Share')}
      </h3>
      <div className={classes.container}>
        <img
          src={item.eventImage || item.rallyImage || AVATAR}
          className={classes.img}
          alt="avatar"
        />
        <div className={classes.shareName}>{item.rallyName}</div>
        <div className={classes.time}>
          {moment(item.startDateTime).format('DD.MM.YYYY')}
        </div>
        <p className={classes.venue}>{item.firstAddress}</p>
        {showIcon && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowCopy(true);
                setShowIcon(false);
              }}
            >
              <img src={COPY} alt="copyLink" />
              <p style={{ textAlign: 'center', marginLeft: '-20px' }}>
                {t('others.Copy Link')}
              </p>
            </div>
            <div
              style={{ marginLeft: '40px', cursor: 'pointer' }}
              onClick={() => {
                setShowInvite(true);
                setShowIcon(false);
              }}
            >
              <img src={MAIL} alt="mail" />
              <p style={{ marginTop: '20px', textAlign: 'center' }}>
                {t('forms.Username')}
              </p>
            </div>
          </div>
        )}
        {showInvite && (
          <form onSubmit={sendInvitation} className={classes.form} autoComplete="off">
            <div className={classes.userNameSelect}>
              <FormLabel name={t('others.Enter username')} require />
              <Select
                options={usernameList}
                onChange={onChange}
                classNamePrefix={classes.options}
                styles={boxStyles}
              />
              {noUsername && (
                <div style={{ color: 'red' }}>
                  {t('validationErrors.Username is Required')}
                </div>
              )}
            </div>
            <FormButton
              className={classes.btn}
              onClick=""
              name={t('others.Invite')}
              isLoading={loading}
            />
          </form>
        )}
        {showCopy && (
          <form className={classes.form}>
            <input
              type="text"
              value={copyLink}
              disabled
              id=""
              className={classes.input}
            />
            <CopyToClipboard text={copyLink}>
              <FormButton
                className={classes.btn}
                name={t('others.Copy')}
                onClick={copyToClipboard()}
              />
            </CopyToClipboard>
          </form>
        )}
      </div>
    </div>
  );
}

function Share({ item, load, sendUserInvitation }) {
  const classes = useStyles();
  const modal = useModalDialog();
  const setOpenShareModal = useMemo(() => modal(ShareModal, 'Share-Modal'), []);
  const { authorizedUserClick } = useAuthorizedUserClick();

  const handleShareClick = () => {
    setOpenShareModal(true, { item, load, sendUserInvitation });
  };
  const handleClickAuthorized = () => authorizedUserClick(item, handleShareClick);
  return (
    <Icon
      icon="bx:bx-share-alt"
      className={classes.shareIcon}
      onClick={handleClickAuthorized}
    />
  );
}

export default Share;
