import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'src/I18n/i18n';
import { RootState } from 'src/Store';

export default function LanguageProvider({ children }) {
  const language = useSelector((state: RootState) => state.language);

  useEffect(() => {
    i18n.changeLanguage(language.value);
  }, [language]);

  return children;
}
