import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import UsersListing from '../../Containers/Users/UsersListing/UsersListing';

function Users() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t(`users.Participants`)} />
        <UsersListing />
        {/* <Loader />
                <BreadCrumbs/> */}
      </Dashboard>
    </div>
  );
}

export default Users;
