import { Backdrop, Fade, Modal } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import useStyles from './EmailDraftStyle';
import EmailCompose from '../../../Components/EmailDraft/EmailCompose';
import { url } from '../../../Services/Constants';

function EmailDraft({
  username,
  EmailConfirm,
  openEmailDraft,
  setOpenEmailDraft,
  eventId,
  rallyId,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [emailContent, setEmailContent] = useState<any>();

  useEffect(() => {
    setEmailContent(
      '<p>' +
        `Hey <%= username %> </p>
      <p>Welcome to Compreg </p>
      <p>You have been invited to the <%= eventName %> event.</p>
      <p>Please click the link below to know more 
      <a href=${url}/event-details/${eventId}/${rallyId}>Click here</a></p>
      <p>Regards,</p>
      <p>Compreg team` +
        '</p>',
    );
  }, [username, eventId, rallyId, openEmailDraft]);
  const handleOnOpen = () => {
    setOpenEmailDraft(true);
  };
  const handleOnClose = () => {
    setOpenEmailDraft(false);
  };
  const confirmClick = () => {
    if (emailContent === null || emailContent.length === 0) {
      toast.error(t('toast.Cant submit empty email draft'), {
        position: 'top-center',
        autoClose: 5000,
      });
      return false;
    }
    EmailConfirm(emailContent);
    handleOnClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openEmailDraft}
      onClose={handleOnClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEmailDraft}>
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Icon
              icon="carbon:close-outline"
              onClick={handleOnClose}
              className={classes.icon}
            />
          </div>
          <h3 id="transition-modal-description" className={classes.title}>
            {t('Email Draft')}
          </h3>
          <EmailCompose
            username={username}
            setEmailContent={setEmailContent}
            emailContent={emailContent}
          />
          <div className={classes.sign}>
            <p className={classes.link} onClick={confirmClick}>
              {t('others.Yes')}
            </p>
            <p className={classes.link} onClick={handleOnClose}>
              {' '}
              {t('others.Cancel')}
            </p>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default EmailDraft;
