import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles({
  yellow: {
    backgroundColor: '#FEC400',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  red: {
    backgroundColor: '#F12B2C',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  ash: {
    backgroundColor: '#C4C4C4',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#29CC97',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  lightgreen: {
    backgroundColor: '#43a047',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
  lightred: {
    backgroundColor: '#FF0000',
    color: '#fff',
    padding: '4px 15px',
    borderRadius: '25px',
    maxWidth: '130px',
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 700,
    textAlign: 'center',
  },
});

export function RallyStatusLabel({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentDateTime = moment();

  if (data.isActive) {
    if (moment(data.endDateTime) >= currentDateTime) {
      return <p className={classes.lightgreen}>{t('forms.Active')}</p>;
    }
    if (moment(data.endDateTime) < currentDateTime) {
      return <p className={classes.green}>{t('forms.Completed')}</p>;
    }
  } else if (data.isCancelled === true) {
    return <p className={classes.lightred}>{t('events.Cancelled')}</p>;
  } else if (data.isDeleted === true) {
    return <p className={classes.green}>{t('forms.Deleted')}</p>;
  }
  return <p className={classes.green}>{t('events.Registration Progressing')}</p>;
}
