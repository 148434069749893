/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import {
  submitTableReport,
  updateReport,
  saveExportlog,
} from 'src/Services/Auth/Actions/OrganizerActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { uniqBy } from 'lodash';
import { PDFColumLimit } from 'src/Services/Constants';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import SuccessToast from 'src/Components/Utils/SuccessToast';
import {
  columnType,
  createComponenets,
  fileResource,
  findDefaultSourceColumn,
  onSubmitPdf,
  updateDestinationCoumn,
} from '../util';
import { DocField, DropDown, ExportProps, UseDragAndDropProps } from '../types/docType';

const useDragAndDrop = ({
  defaultFields,
  resource,
  eventId,
  formListing,
  refreshDefaultFields,
  organizerId,
  initialSortApi,
  reload,
  formId,
  rallyId,
  tableReportName,
}: UseDragAndDropProps) => {
  const initialSort = useSelector(
    (state: RootState) => state.table[`${resource}Exporter`],
  );

  const [dropDownValues, setDropDownValues] = useState<DropDown>({
    sortBy: initialSort?.sortBy || 'createdAt',
    sortOrder: initialSort?.sort || 'asc',
    type: initialSort?.exportDocType || 'pdf',
    formId: !formId ? initialSort?.formId : formId || [],
    rallyId: initialSort?.rallyId || '',
    orientation: initialSortApi
      ? initialSortApi?.orientation
      : initialSort.orientation || 'landscape',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const itemsFromBackend: Array<DocField> = defaultFields; // data coming from backend
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reportName, setReportName] = useState<string>(tableReportName || '');
  // source and destination columns are defined in here (for the react-beautiful-dnd package)
  const sourceDestColumns = {
    sourceColumn: {
      name: t(`events.${columnType.source}`),
      items: findDefaultSourceColumn(
        itemsFromBackend,
        initialSort?.sourceColumn || [],
        initialSort?.destinationColumn || [],
      ),
    },
    destinatioColumn: {
      name: t(`events.${columnType.destination}`),
      items: initialSort?.destinationColumn || [],
    },
  };

  const [columns, setColumns] = useState(sourceDestColumns);

  useEffect(() => {
    setDropDownValues({
      ...dropDownValues,
      sortBy: dropDownValues.sortBy || columns.destinatioColumn.items[0]?.label,
    });
  }, [columns]);

  const destinationColumnData: DocField[] = columns.destinatioColumn.items;
  const sourceColumnData: DocField[] = columns.sourceColumn.items;
  // User selecting multiple forms this function prevnets destisnation column data empty
  initialSort.destinationColumn = updateDestinationCoumn(formId, destinationColumnData);
  // This function is responsible for the drag & drop operation
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  // function used to download the pdf/csv files
  // function used for save the export log based on the user's ide's
  const onSubmit: Function = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // Pdf with more than PDFColumLimit feilds are un readbale. So, restrict them from download / or choose csv
      if (destinationColumnData.length > PDFColumLimit && dropDownValues.type === 'pdf') {
        ErrorToast(t('exportDesigner.exportWarning'));
        setIsLoading(false);
        return;
      }
      onSubmitPdf(
        destinationColumnData,
        dropDownValues.sortOrder,
        dropDownValues.sortBy,
        resource,
        dropDownValues.type,
        eventId,
        dropDownValues.formId,
        organizerId,
        setIsLoading,
        rallyId,
        dropDownValues.orientation,
      );

      const sort: ExportProps = {
        sort: dropDownValues.sortOrder,
        sortBy: dropDownValues.sortBy,
        exportDocType: dropDownValues.type,
        sourceColumn: sourceColumnData,
        destinationColumn: destinationColumnData,
        orientation: dropDownValues.orientation,
      };
      if (resource === fileResource.eventParticipant) {
        sort.formId = dropDownValues.formId;
      }
      dispatch({
        type: 'TABLE_SORT',
        payload: {
          table: `${resource}Exporter`,
          sort,
        },
      });

      if (!initialSortApi) {
        saveExportlog({ data: sort, name: resource, eventId });
      }
    } catch (error) {
      setIsLoading(false);
      ErrorToast(t('General settings.Empty list could not convert to csv'));
    }
  };

  const onReportSubmit = (
    name,
    newdestinationColumnData,
    newsourceColumnData,
    dropDownValuesUpdate,
  ) => {
    setDropDownValues({
      ...dropDownValues,
      type: dropDownValuesUpdate.type,
    });
    if (
      destinationColumnData.length > PDFColumLimit &&
      dropDownValuesUpdate.type === 'pdf'
    ) {
      ErrorToast(t('exportDesigner.exportWarning'));
      return;
    }
    submitTableReport({
      data: {
        sort: dropDownValuesUpdate.sortOrder,
        sortBy: dropDownValuesUpdate.sortBy,
        destinationColumn: newdestinationColumnData,
        resource,
        exportDocType: dropDownValuesUpdate.type,
        sourceColumn: newsourceColumnData,
        eventId,
        formId: dropDownValues.formId,
        organizerId,
        orientation: dropDownValuesUpdate.orientation,
      },
      name,
    })
      .then(() => {
        SuccessToast(t('others.Report saved successfully'));
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          ErrorToast(t('message.name is already exist'));
        } else {
          ErrorToast(t('others.Something went wrong'));
        }
      });
  };

  const updateReportSubmit = async (
    name,
    newdestinationColumnData,
    newsourceColumnData,
    id,
  ) => {
    if (destinationColumnData.length > PDFColumLimit && dropDownValues.type === 'pdf') {
      ErrorToast(t('exportDesigner.exportWarning'));
      return;
    }
    try {
      await updateReport({
        data: {
          sort: dropDownValues.sortOrder,
          sortBy: dropDownValues.sortBy,
          destinationColumn: newdestinationColumnData,
          resource,
          exportDocType: dropDownValues.type,
          sourceColumn: newsourceColumnData,
          eventId,
          formId: dropDownValues.formId,
          organizerId,
          orientation: dropDownValues.orientation,
        },
        name: reportName,
        id,
      }).then(() => {
        SuccessToast(t('others.Report updated successfully'));
        reload();
      });
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        ErrorToast(t('message.name is already exist'));
      } else {
        ErrorToast(t('others.Something went wrong'));
      }
    }
  };

  // Function used to select all the fields from the source column & move them into the destination column
  const selectAllFields = (isUnselect): void => {
    setColumns({
      sourceColumn: {
        name: columnType.source,
        items: isUnselect
          ? uniqBy([...destinationColumnData, ...sourceColumnData], '_id')
          : [],
      },
      destinatioColumn: {
        name: columnType.destination,
        items: isUnselect
          ? []
          : uniqBy([...destinationColumnData, ...sourceColumnData], '_id'),
      },
    });
  };

  // This functoin is used to create custom components & use it anywhere
  const components: JSX.Element[] = createComponenets(
    dropDownValues,
    formListing,
    setDropDownValues,
    refreshDefaultFields,
  );

  return {
    onDragEnd,
    isLoading,
    columns,
    destinationColumnData,
    onSubmit,
    dropDownValues,
    setDropDownValues,
    setColumns,
    sourceColumnData,
    selectAllFields,
    components,
    onReportSubmit,
    updateReportSubmit,
    setReportName,
  };
};

export default useDragAndDrop;
