import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #D4D5DE',
    padding: '6px 8px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    textTransform: 'none',
    fontFamily: typography.fonts.secondary,
    '&:focus-visible': {
      outlineColor: 'none !important',
    },
    '&:focus:not(.focus-visible)': {
      outline: 'none !important',
    },
  },

  options: {
    background: 'red',
    color: 'white',
    hover: {
      background: 'black !important',
    },
  },

  btnWrap: {
    textAlign: 'left',
  },
  btn: {
    float: 'left',
    width: '100%',
    marginTop: '30px',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },

  wrapper: {
    backgroundColor: '#fff',
    padding: '20px 60px 40px',
    margin: '40px 20px 20px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    '@media all and (max-width:767px)': {
      padding: '20px 20px 20px',
      margin: '30px 0px 20px',
    },
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DFE0EB',
    marginBottom: '20px',
  },
  box: {
    padding: '15px 30px 15px',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '25px',
  },
  h3: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '22px',
    letterPpacing: '4.4px',
    color: '#252733',
    margin: 0,
  },
  button: {
    backgroundColor: '#007AFF',
    width: '50%',
    color: '#fff',
    fontStyle: 'normal',
    fontSize: '14px',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#007AFF',
      color: '#fff',
    },
    marginTop: '35px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid transparent',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '17px',
    width: '30%',
    padding: '30px 0',
    '@media all and (max-width: 1200px)': {
      width: '50%',
    },
    '@media all and (max-width: 650px)': {
      width: '97%',
    },
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  sign: {
    display: 'flex',
    justifyContent: 'space-around',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto',
  },
  linkPopup: {
    cursor: 'pointer',
    color: colors.secondary.color,
    '&:hover': {
      color: colors.primary.color,
    },
  },
}));
