import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { useSelector } from 'react-redux';
import CustomTable, { TopActionButtonType } from 'src/Components/Table/Table';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import topActions from 'src/Types/Enums/EnumTopActions';
import { handleMultiCheckboxChange } from 'src/Services/Utils';
import useApiCall from 'src/Hooks/useApiCall';
import AVATAR from './AvatarImage.svg';

import { userListingSort } from '../../../Services/Auth/Actions/UserActions';
import UserDelete from './UserDelete';
import { RootState } from '../../../Store';
import useStyles from './UsersListingStyle';
import UsersListingActions from './UsersListingActions';
import InviteAction from './InviteAction';
import ParicipantImportCsv from './ParicipantImportCsv';

function UsersListing() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [forceReload, setForceReload] = useState<string>('');
  const role = useSelector((store: RootState) => store.role.role);

  const [selectedParticipants, setSelectedParticipants] = useState<Array<string>>([]);
  const [callApi, importLoader, data, error] = useApiCall();
  const language = useSelector((state: RootState) => state.language.value);

  const columns = [
    {
      title: '',
      field: 'checkbox',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <Checkbox
          onChange={(e) => {
            setSelectedParticipants(
              handleMultiCheckboxChange(e, row._id, selectedParticipants),
            );
          }}
        />
      ),
      isSortable: false,
    },
    {
      title: t('forms.Full Name'),
      field: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.wrap}>
            <div className={classes.imgWrap}>
              <img
                src={row.profileImage || AVATAR}
                className={classes.img}
                alt="avatar"
              />
            </div>
            <div className={classes.participantName}>
              {row?.companyname || `${row?.lastName} ${row?.firstName}`}
            </div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Username'),
      field: 'username',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div
            style={{ width: '170px', textOverflow: 'ellipsis', overflow: 'hidden' }}
            className={classes.wrap}
          >
            <div>{row.username}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('events.Events'),
      field: 'events',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.tbodyName}>
            {t('forms.Active')}: {row.activeEvents}
          </div>
          <p className={classes.grey}>
            {t('forms.Completed')}: {row.completedEvents}
          </p>
        </div>
      ),
      isSortable: false,
    },
    {
      title: t('organizers.Contact'),
      field: 'email',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbody}>
          <div className={classes.tbodyName}>{row.email}</div>
          <p className={classes.grey}>{row.phone}</p>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Last login'),
      field: 'date',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>
          {row.lastLoggedIn ? (
            <>
              {Moment(row.lastLoggedIn).format('DD.MM.YYYY')}
              <br />
              {Moment(row.lastLoggedIn).format('hh:mm:a')}
            </>
          ) : (
            <>{t('organizers.Never')}</>
          )}
        </p>
      ),
      isSortable: false,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <p className={classes.grey}>{Moment(row.createdAt).format('DD.MM.YYYY')}</p>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row, reload) => (
        <div className={classes.wrapper1}>
          <UsersListingActions item={row} />
          {role === 'superadmin' && (
            <UserDelete reload={reload} id={row._id} item={row} />
          )}
        </div>
      ),
      isSortable: false,
    },
  ];
  const topActionButton: TopActionButtonType[] = [
    {
      type: topActions.linkedButton,
      extraProps: {
        to: '/users/add-new',
        name: t('organizers.Add New'),
      },
    },
    {
      type: topActions.buttonPopup,
      extraProps: {
        label: 'users.Invite',
        loading: importLoader,
        component: InviteAction,
        onClick: () => {},
        participant: selectedParticipants,
        modalName: 'invite',
      },
    },
    {
      type: topActions.buttonPopup,
      extraProps: {
        label: 'others.Import CSV',
        loading: importLoader,
        component: ParicipantImportCsv,
        onClick: () => {},
        modalName: 'participantImport',
      },
    },
  ];

  const reload = ({ sort, page, rowsPerPage, search }) =>
    userListingSort({
      role,
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
    }).then((response) => {
      const data = response.data.users;
      const { totalCount } = response.data;
      return {
        data,
        totalCount,
      };
    });

  return (
    <div>
      <CustomTable
        tableName="Participants Listing"
        columns={columns}
        reload={reload}
        heading={t('users.Participants Listing')}
        onDoubleClick={(item) =>
          history.push({
            pathname: `users/details/${item._id}/profile`,
            state: { showGoBack: true },
          })
        }
        topActionButtons={topActionButton}
        role={role}
        forceReload={forceReload}
      />
    </div>
  );
}

export default UsersListing;
