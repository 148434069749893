/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Icon } from '@iconify/react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { Tooltip } from '@material-ui/core';
import CanAccess from 'src/Components/Utils/CanAccess';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import CustomTable, { TopActionLinkedButtonType } from 'src/Components/Table/Table';
import topActions from 'src/Types/Enums/EnumTopActions';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { formateDateWithoutTime } from 'src/Services/Utils';
import AVATAR from './compregLogo.png';
import { OrganizerEventsListing } from '../../../Services/Auth/Actions/EventActions';
import StatusLabel from '../../../Components/Utils/StatusLabel';
import EventDelete from '../../Events/EventListing/EventDelete';
import useStyles from './OrganizerEventListingStyle';

type TopActionButtonType = TopActionLinkedButtonType;

function OrganizerEventListing({
  details,
  forceRole = undefined,
}: {
  details: ObjectIdType;
  forceRole?: EnumUserRoles | undefined;
}) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  // eslint-disable-next-line camelcase
  const role =
    forceRole !== undefined
      ? forceRole
      : useSelector((store: RootState) => store.role.role);

  const reloading = ({ sort, page, rowsPerPage, search }) =>
    OrganizerEventsListing({
      role: role === 'superadmin' ? 'organizer' : role,
      id: details,
      sortBy: sort.sortBy,
      sort: sort.sort,
      search,
      page,
      rowsPerPage,
    }).then((response) => {
      const data = response.data.events;
      const { totalCount } = response.data;
      return {
        data,
        totalCount,
      };
    });

  const showIcons = (item, reload) => {
    if (role === 'participant') {
      return (
        <Link
          to={`/events/details/${item._id}`}
          style={{ textDecoration: 'none' as const }}
        >
          View details
        </Link>
      );
    }
    if (item.isCancelled) {
      return <EventDelete reload={reload} id={item._id} item={item} />;
    }
    return (
      <>
        <Icon
          icon="feather:edit-3"
          className={classes.editIcon}
          onClick={() => {
            history.push({
              pathname: `/events/details/${item._id}`,
              state: { showGoBack: true, goBackpath: history.location.pathname },
            });
          }}
        />
        <EventDelete reload={reload} id={item._id} />
      </>
    );
  };

  const topActionButton: TopActionButtonType[] = [
    {
      type: topActions.linkedButton,
      extraProps: {
        to: `/events/add-new/${details}`,
        name: t('events.Add New'),
        permission: EnumPermissions.CreateEvent,
      },
    },
  ];
  const columns = [
    {
      title: t('events.Event'),
      field: 'eventName',
      data: (row) => (
        <div className={classes.wrap}>
          <div className={classes.imgWrap}>
            <img src={row.eventImage || AVATAR} className={classes.img} alt="avatar" />
          </div>
          <div className={classes.eventName}>{row.eventName}</div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Start Date'),
      field: 'startDateTime',
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.startDateTime)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('forms.End Date'),
      field: 'endDateTime',
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.endDateTime)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('events.Event Place'),
      field: 'venue',
      data: (row) => (
        <div>
          {row.venue ? (
            <Tooltip title={row.venue}>
              <p className={classes.grey}>{row.venue}</p>
            </Tooltip>
          ) : (
            <>_____________</>
          )}
        </div>
      ),
      isSortable: false,
    },
    {
      title: t('organizers.Created at'),
      field: 'createdAt',
      data: (row) => (
        <p className={classes.grey}>{formateDateWithoutTime(row.createdAt)}</p>
      ),
      isSortable: true,
    },
    {
      title: t('forms.Status'),
      field: 'Status',
      data: (row) => <StatusLabel data={row} />,
      isSortable: false,
    },
    {
      title: t('forms.Actions'),
      field: 'actions',
      data: (row, reload) => (
        <CanAccess permission={EnumPermissions.ShowTableActions}>
          <TableCell className={classes.tbody}>
            <div>
              <div className={classes.wrapper1}>{showIcons(row, reload)}</div>
            </div>
          </TableCell>
        </CanAccess>
      ),
      isSortable: false,
    },
  ];
  return (
    <div>
      <CustomTable
        tableName="orgEvent"
        columns={columns}
        reload={reloading}
        heading={t('events.Events')}
        topActionButtons={topActionButton}
        onDoubleClick={(item) => {
          !item.isCancelled &&
            history.push({
              pathname: `/events/details/${item._id}`,
              state: { showGoBack: true, goBackpath: history.location.pathname },
            });
        }}
        role={role}
        searchSize="small"
      />
    </div>
  );
}

export default OrganizerEventListing;
