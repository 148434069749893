import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { commonResponseHandler } from 'src/Services/Utils';
import {
  deleteRally,
  cloneNewRally,
  cancelRally,
} from '../../../Services/Auth/Actions/RallyActions';
import useStyles from './RallyDeleteStyle';

const ITEM_HEIGHT = 30;

function RallyDelete({ id, reload, eventDetailsById, item, editPopup }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const confirm: any = useConfirm();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRallyClick = () => {
    confirm({
      description: t('popup.Are you sure you want to delete the rally?'),
    }).then(() => {
      commonResponseHandler(deleteRally(id), () => {
        reload();
        eventDetailsById();
      });
    });
  };

  const cancelRallyClick = () => {
    confirm({
      description: t('popup.Are you sure you want to cancel the rally?'),
    }).then(() => {
      commonResponseHandler(cancelRally(id, item), () => reload());
    });
  };

  const cloneRally = () => {
    const data: any = {
      rallyName: `${item.rallyName}_copy` || '',
      rallyType: item.rallyType || '',
      startDateTime: item.startDateTime || '',
      endDateTime: item.endDateTime || '',
      memo: item.memo || '',
      firstAddress: item.firstAddress || '',
      secondAddress: item.secondAddress || '',
      street: item.street || '',
      city: item.city || '',
      state: item.state || '',
      postCode: item.postCode || '',
      country: item.country || '',
      eventId: item.eventId || '',
      rallyImage: item.rallyImage || '',
    };
    const formData = new FormData();

    Object.keys(data).map((k) => formData.append(k, data[k]));
    cloneNewRally(id, formData)
      .then((res) => {
        setAnchorEl(null);
        toast.success(t('forms.Rally Cloned Successfully'), {
          position: 'top-center',
          autoClose: 5000,
        });
        reload();
        eventDetailsById();
        editPopup(res.data.cloneRally._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'max-content',
            padding: 0,
          },
        }}
      >
        {!item.isCancelled && (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper} onClick={cancelRallyClick}>
              <Icon icon="websymbol:cancel" className={classes.iconCancel} />
              <p className={classes.option}>{t('others.Cancel')} </p>
            </div>
          </MenuItem>
        )}
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper} onClick={deleteRallyClick}>
            <Icon icon="ant-design:delete-twotone" className={classes.icon} />
            <p className={classes.option}>{t('forms.Delete')} </p>
          </div>
        </MenuItem>
        {!item.isCancelled && (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper} onClick={cloneRally}>
              <Icon icon="ant-design:copy-outlined" className={classes.iconClone} />
              <p className={classes.option}>{t('forms.Clone')}</p>
            </div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default RallyDelete;
