import React, { useContext } from 'react';
import { Field } from 'formik';
import FormError from 'src/Components/FormError/FormError';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import FieldLabel from './Fields/FieldLabel';
import fieldStyles from './FieldStyle';
import HintField from './Fields/HintField';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetGenericAmount({
  isEditable = false,
  disabled = true,
  onChange,
  error,
  value,
  fieldData,
  mode,
  aditionalProps,
}: FormFieldType) {
  const classes = fieldStyles();
  const { isPreviewDisabled, onHandleChange, findTextFields } = useContext(
    ParticipantFormPreviewContext,
  );
  const { label, type, hint, styles, index } = fieldData;

  const renderGenericAmountField = () => (
    <>
      <Field
        className={classes.input}
        type={type}
        name={label}
        value={
          mode === 'preview' ? findTextFields(index) || aditionalProps.values : value
        }
        disabled={
          mode === 'preview' || mode === 'readOnlyPreview' ? isPreviewDisabled : disabled
        }
        onChange={(event) => {
          if (mode === 'preview') {
            onHandleChange({ event, index, type, id: fieldData._id });
          } else {
            onChange(event);
          }
        }}
      />
      <FormError error={error} />
      {hint && <HintField hint={hint} />}
    </>
  );

  return (
    <>
      <FieldLabel name={label} styles={styles} />
      {isEditable ? (
        renderGenericAmountField()
      ) : (
        <Field
          className={classes.input}
          name={label}
          value={aditionalProps.values}
          readOnly
        />
      )}
    </>
  );
}

export default GetGenericAmount;
