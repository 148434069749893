import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    lineHeight: '13px',
    marginBottom: '10px',
    textAlign: 'left',
  },
  fcolor: {
    color: 'red',
    fontSize: '15px',
    fontWeight: 800,
    paddingLeft: '2px',
  },
}));

function FormLabel({ name, require = false, className = {} }) {
  const classes = useStyles();
  return (
    <p className={classes.label}>
      {name}
      {require && <span className={classes.fcolor}>*</span>}
    </p>
  );
}

export default FormLabel;
