import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    color: '#252a5a',
    border: '1px solid #D4D5DE',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '20px',
    height: '20px',
    color: colors.primary.color,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    textTransform: 'initial',
    fontFamily: typography.fonts.secondary,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  clearButton: {
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export default function Search({ className, onChange }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const handleClearClick = (e) => {
    setSearchValue('');
    onChange(e);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    onChange(e);
  };
  return (
    <div className={clsx(classes.search, className)}>
      <div className={classes.searchIcon}>
        <SearchIcon className={classes.icon} />
      </div>
      <InputBase
        placeholder={t('others.Search…')}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleInputChange}
        value={searchValue}
      />
      {searchValue && (
        <IconButton
          className={classes.clearButton}
          onClick={handleClearClick}
          size="small"
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
}
