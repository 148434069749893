import React from 'react';
import Select from 'react-select';
import useStyles from '../style';
import { StyleSelectDropdownType } from '../types/textEditorTypes';

type Props = {
  options: StyleSelectDropdownType[];
  handleChange: (selectedOption: any) => void;
  placeholder: string;
  value: string;
};
export const StyleSelectorDropDown = ({
  options,
  handleChange,
  placeholder,
  value,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.dropDownSpacing}>
      <Select
        options={options}
        onChange={handleChange}
        placeholder={value || placeholder}
      />
    </div>
  );
};
