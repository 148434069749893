import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import SigInLink from 'src/Components/Auth/SigInLink';
import { FormLabel, FilledButton } from '../../Components/Utils';
import { register } from '../../Services/Auth/Actions/UserActions';
import theme from '../../Theme';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
  },
  button: {
    backgroundColor: 'transparent',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: '10px 10px',
    width: '100%',
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '1.6',
    textTransform: 'uppercase',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '4px',
    cursor: 'pointer',
  },
}));

function SocialMediaView({
  registerData,
  eventId,
  rallyId,
}: {
  registerData: any;
  eventId: String;
  rallyId: String;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const language = useSelector((store: RootState) => store.language);
  const [isSkip, setIsSkip] = useState(false);

  const onSubmit = (values, { setSubmitting }) => {
    const data: any = {
      ...values,
      ...registerData,
      language: language.value,
    };
    if (data.username) {
      data.username = data.username.trim().toLowerCase().replace(/ +/g, '');
    }
    if (data.email) {
      data.email = data.email.toLowerCase();
    }
    if (isSkip) {
      data.facebookUrl = '';
      data.instagramUrl = '';
      setSubmitting(false);
    }
    delete data.confirmPassword;
    register(data)
      .then((response) => {
        toast.success(response.data.message, {
          position: 'bottom-right',
          autoClose: 5000,
        });
        if (eventId) {
          return history.push(
            `/${eventId ? `?eventId=${eventId}` : ''}${
              rallyId ? `&rallyId=${rallyId}` : ''
            }`,
          );
        }
        return history.push('/');
      })
      .catch((error) => {
        setSubmitting(false);
        toast.error(error.response.data.message, {
          position: 'bottom-right',
          autoClose: 5000,
        });
      });
  };
  const skipAndComplete = () => {
    setIsSkip(true);
  };

  return (
    <div className="add-social-media">
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {({ isSubmitting, values, errors, touched }: any) => (
          <Form>
            <FormLabel name={`Facebook URL (${t('others.Optional')})`} />
            <Field
              className={classes.input}
              type="text"
              name="facebookUrl"
              value={values.facebookUrl}
            />
            {errors.facebookUrl && touched.facebookUrl ? (
              <div className="error-code">{errors.facebookUrl}</div>
            ) : null}
            <FormLabel name={`Instagram URL (${t('others.Optional')})`} />
            <Field
              className={classes.input}
              type="text"
              name="instagramUrl"
              value={values.instagramUrl}
            />
            {errors.instagramUrl && touched.instagramUrl ? (
              <div className="error-code">{errors.instagramUrl}</div>
            ) : null}

            <div>
              {!isSubmitting && (
                <>
                  <button
                    type="submit"
                    className={classes.button}
                    disabled={isSubmitting}
                    onClick={skipAndComplete}
                  >
                    {t('signUp.SKIP & COMPLETE REGISTRATION')}
                  </button>
                  <FilledButton
                    name={t('signUp.SAVE & Complete registration')}
                    type="submit"
                    disabled={isSubmitting}
                  />
                </>
              )}
              {isSubmitting && (
                <Loader
                  type="ThreeDots"
                  color={theme.palette.primary.main}
                  height={50}
                  width={50}
                />
              )}
            </div>
            <SigInLink eventId={eventId} rallyId={rallyId} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default connect(null, { register })(SocialMediaView);
