import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import Header from '../../../Components/Header/Header';
import Dashboard from '../../../Layouts/Dashboard/Dashboard';
// ParticipantFormList
import AdminOrganizerView from './AdminOrganizerView';
import ParticipantView from './ParticipantView';

function EventDetails(props) {
  const { t } = useTranslation();
  const { id, page }: any = useParams();
  const role = useSelector((state: RootState) => state.role.role);
  return (
    <div>
      <Dashboard>
        <Header title={t('events.Events')} />
        {role === 'participant' ? (
          <ParticipantView id={id} page={page} />
        ) : (
          <AdminOrganizerView id={id} page={page} />
        )}
      </Dashboard>
    </div>
  );
}

export default EventDetails;
