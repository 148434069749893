import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles(() => ({
  boxType: {
    padding: '20px',
    margin: '10px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
  },
  container: {
    maxHeight: 440,

    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  wrapper: {
    margin: '10px',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
}));
