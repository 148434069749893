import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import {
  newOrganiserListing,
  newOrganiserContactListing,
} from 'src/Services/Auth/Actions/OrganizerActions';
import CanAccess from 'src/Components/Utils/CanAccess';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';
import CanAccessByRole from 'src/Components/Utils/CanAccessByRole';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import { SimpleCard } from '../../../Components/Utils';
import NewOrganizersListing from '../NewOrganizersListing/NewOrganizersListing';
import {
  pastOverview,
  upcomingOverview,
  userOverview,
} from '../../../Services/Auth/Actions/UserActions';
import './Style.css';
import NewUsers from '../NewUsers/NewUsers';
import EventListing from '../../../Components/EventListing/EventListing';
import ChangeUserNameWrapper from '../../../Components/Admin/ChangeUserName';

function DashboardCard(props) {
  const [newOrganizers, setNewOrganizers] = React.useState(false);
  const [newEventOrganizer, setNewEventOrganizer] = React.useState(false);
  const [newUsers, setNewUsers] = React.useState(false);
  const [upcomingEvents, setUpcomingEvents] = React.useState(true);
  const [pastEvents, setPastEvents] = React.useState(false);
  const [newOrganisersCount, setNewOrganisersCount] = React.useState();
  const [newOrganiserContactsCount, setNewOrganiserContactsCount] = React.useState();
  const [newUsersCount, setNewUsersCount] = React.useState();
  const [upcomingEventsCount, setUpcomingEventsCount] = React.useState();
  const [pastEventsCount, setPastEventsCount] = React.useState();

  const [loading, setLoading] = useState(false);
  const [adminUser, setAdminUser] = useState(false);
  const [checkCount, setCheckCount] = useState(true);
  const role = useSelector((store: RootState) => store.role.role);
  const user = useSelector((store: RootState) => store.role.user);
  const userRole = useSelector((store: RootState) => store.role.user.role);
  const { t } = useTranslation();

  const fetchPast = () => {
    pastOverview({ role })
      .then((response) => {
        setPastEventsCount(response.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchNewParticipant = () => {
    userOverview({ role })
      .then((response) => {
        setNewUsersCount(response.data.totalCount);
        setLoading(false);
        setCheckCount(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchNewOrganisers = () => {
    newOrganiserListing({})
      .then((response) => {
        setNewOrganisersCount(response.data.totalCount);
        setLoading(false);
        setCheckCount(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchNewOrganiserContacts = () => {
    newOrganiserContactListing({})
      .then((response) => {
        setNewOrganiserContactsCount(response.data.totalCount);
        setLoading(false);
        setCheckCount(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetChUpcoming = () => {
    upcomingOverview({ role })
      .then((response) => {
        setUpcomingEventsCount(response.data.count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickNewOrganizers = (e?) => {
    fetchNewOrganisers();
    setNewOrganizers(true);
    setNewUsers(false);
    setUpcomingEvents(false);
    setPastEvents(false);
  };
  const handleClickNewOrganizerContacts = (e?) => {
    fetchNewOrganiserContacts();
    setNewEventOrganizer(true);
    setNewOrganizers(false);
    setNewUsers(false);
    setUpcomingEvents(false);
    setPastEvents(false);
  };
  const handleClickNewUsers = (e?) => {
    fetchNewParticipant();
    setNewUsers(true);
    setNewOrganizers(false);
    setUpcomingEvents(false);
    setPastEvents(false);
    setNewEventOrganizer(false);
  };

  // upcoming and past events
  const handleClickUpcomingEvents = (e?) => {
    fetChUpcoming();
    setUpcomingEvents(true);
    setNewOrganizers(false);
    setNewUsers(false);
    setPastEvents(false);
    setNewEventOrganizer(false);
  };
  const handleClickPastEvents = (e?) => {
    fetchPast();
    setPastEvents(true);
    setUpcomingEvents(false);
    setNewOrganizers(false);
    setNewUsers(false);
    setNewEventOrganizer(false);
  };

  useEffect(() => {
    setLoading(true);
    if (checkCount === true) {
      fetchNewParticipant();
      fetChUpcoming();
      fetchPast();
      if (role === 'superadmin') fetchNewOrganisers();
      if (role === 'organizer') fetchNewOrganiserContacts();
    }
    if (role === 'superadmin' || role === 'organizer') {
      setAdminUser(true);
    }
    setCheckCount(false);
  });
  return (
    <div>
      <div
        className={role !== 'participant' ? 'dashboard-card-sec' : 'participant-card-sec'}
      >
        <div onClick={(e) => handleClickUpcomingEvents()}>
          <SimpleCard
            title={t('overview.Upcoming Events')}
            count={upcomingEventsCount || '0'}
            active={upcomingEvents}
          />
        </div>
        <div onClick={(e) => handleClickPastEvents()}>
          <SimpleCard
            title={t('overview.Past Events')}
            count={pastEventsCount || '0'}
            active={pastEvents}
          />
        </div>
        {role !== 'participant' ? (
          <>
            <CanAccess permission={EnumPermissions.ViewNewOrganizers}>
              <div onClick={(e) => handleClickNewOrganizers()}>
                <SimpleCard
                  title={t('overview.New Organisers')}
                  count={newOrganisersCount || '0'}
                  active={newOrganizers}
                />
              </div>
            </CanAccess>

            <CanAccessByRole roles={[EnumUserRoles.organizer]}>
              <div onClick={(e) => handleClickNewOrganizerContacts()}>
                <SimpleCard
                  title={t('overview.New Organiser Contacts')}
                  count={newOrganiserContactsCount || '0'}
                  active={newEventOrganizer}
                />
              </div>
            </CanAccessByRole>

            <div onClick={(e) => handleClickNewUsers()}>
              <SimpleCard
                title={t('overview.New Participants')}
                count={newUsersCount || '0'}
                active={newUsers}
              />
            </div>
          </>
        ) : (
          <ChangeUserNameWrapper user={user} from="dashboard" />
        )}
      </div>

      {upcomingEvents && <EventListing eventType="upcoming" />}
      {pastEvents && <EventListing eventType="past" />}
      {newOrganizers && (
        <NewOrganizersListing reloader={fetchNewOrganisers} type="organizer" />
      )}
      {newEventOrganizer && (
        <NewOrganizersListing
          reloader={fetchNewOrganiserContacts}
          type="eventOrganizers"
        />
      )}
      {newUsers && <NewUsers reloader={fetchNewParticipant} />}
    </div>
  );
}

export default DashboardCard;
