import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import typography from 'src/Theme/typography';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import WarningToast from 'src/Components/Utils/WarningToast';
import colors from 'src/Theme/colors';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import {
  OrganiserBackup,
  deleteOrganizer,
} from '../../../Services/Auth/Actions/OrganizerActions';
import { commonResponseHandler } from '../../../Services/Utils';

const useStyles = makeStyles(() => ({
  btn: {
    padding: '0px',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  icons: {
    fontSize: '20px',
    cursor: 'pointer',
    marginTop: '-30px',
    marginRight: '10px',
    color: '#9FA2B4',
    flex: 1,
    left: '45%',
    position: 'relative',
  },
  iconClone: {
    color: '#2b9348',
    fontSize: '20px',
  },
  toast: {
    margin: '0px',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
}));

const ITEM_HEIGHT = 30;
type OrganizerDeleteType = { id: ObjectIdType; reload: Function; eventCount: number };

function OrganizerDelete({ id, reload, eventCount }: OrganizerDeleteType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirm: any = useConfirm();
  const confirmDelete = () => {
    confirm({
      description: (
        <>
          <p className={classes.toast}>
            {t('popup.Are you sure you want to delete the organiser?')}
          </p>
          <p className={classes.toast}>{t('popup.This is an irreversible action.')}</p>
        </>
      ),
    }).then(() => {
      commonResponseHandler(deleteOrganizer(id), () => reload());
    });
  };

  const showModalPopup = () => {
    if (eventCount > 0) {
      WarningToast(
        t(
          "toast.It's not possible to delete this organiser since there are events to be organised",
        ),
      );
      return;
    }
    confirmDelete();
  };
  const showBackupPopup = () => {
    confirm({
      description: (
        <>
          <p className={classes.desc}>{t('popup.Backup Organiser')}?</p>
          <p className={classes.desc}>
            {t('popup.It will take time, continue in background')}?
          </p>
        </>
      ),
    }).then(() => {
      OrganiserBackup(id)
        .then(({ data }) => {
          WarningToast(t(data.message));
        })
        .catch((err) => {
          ErrorToast(t('restore failed'));
        });
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'max-content',
            padding: 0,
          },
        }}
      >
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper} onClick={() => showModalPopup()}>
            <Icon icon="ant-design:delete-twotone" className={classes.icon} />
            <p className={classes.option}>{t('forms.Delete')} </p>
          </div>
        </MenuItem>
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper} onClick={() => showBackupPopup()}>
            <Icon icon="mdi:backup-outline" className={classes.iconClone} />
            <p className={classes.option}>{t('Backup')} </p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default OrganizerDelete;
