import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { EventType } from 'src/Types/EventType';
import moment from 'moment';
import { commonResponseHandler } from '../../../Services/Utils';
import { createNewRally } from '../../../Services/Auth/Actions/RallyActions';
import './style.css';
import { getEvent } from '../../../Services/Auth/Actions/EventActions';
import useStyles from './RallyAddPopupStyle';
import validateRally, { prepareFormData, preProcessData } from './RallyUtils';
import RallyValidations from './RallyValidations';
import RallyAddEditForm from './RallyAddEditForm';

type RallyAddPopupType = {
  event: EventType;
  eventDetailsById: Function;
  reload: Function;
  setOpenPopup: Function;
};

function RallyAddPopup({
  event,
  eventDetailsById,
  reload,
  setOpenPopup,
}: RallyAddPopupType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [image, setImage] = useState('');
  const [eventDetail, setEventDetail] = useState<EventType>();
  const [files, setFiles] = useState([]);
  const startDate = moment(event.startDateTime);
  const endDate = moment(event.endDateTime);

  useEffect(() => {
    getEvent(event._id).then((resp) => {
      setEventDetail(resp.data.event);
    });
  }, [event._id]);

  const OnClose = () => {
    setOpenPopup(false);
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const formValue: any = {
      ...values,
      eventId: event._id,
    };
    preProcessData(formValue);
    if (eventDetail && !validateRally(eventDetail, formValue, t)) {
      setSubmitting(false);
      return;
    }
    const formData = prepareFormData(formValue, image, files);
    commonResponseHandler(
      createNewRally(formData),
      () => {
        setSubmitting(false);
        reload();
        eventDetailsById();
        OnClose();
      },
      () => {
        setSubmitting(false);
      },
    );
  };

  const initialValues = {
    rallyName: '',
    rallyType: '',
    startDateTime: startDate,
    endDateTime: endDate,
    memo: '',
  };

  return (
    <div className={classes.paper}>
      <Icon
        icon="carbon:close-outline"
        onClick={OnClose}
        className={classes.closeIcons}
      />
      <div id="transition-modal-description" className={classes.paperinner}>
        <RallyAddEditForm
          title="forms.Add Rally"
          initialValues={initialValues}
          RallyValidations={RallyValidations}
          onSubmit={onSubmit}
          setImage={setImage}
          details={event._id}
          eventDetail={eventDetail}
          files={files}
          setFiles={setFiles}
          rallyTypeId={event?.eventOrganizer || event.organizer?.userId?._id}
        />
      </div>
    </div>
  );
}

export default RallyAddPopup;
