import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getToken, logoutUser } from 'src/Services/Auth/Actions/UserActions';
import { RootState } from 'src/Store';
import EnumPermissions from 'src/Types/Enums/EnumPermissions';

function CanAccess({
  permission,
  children,
}: {
  permission: EnumPermissions;
  children: JSX.Element;
}) {
  const userPermissions = useSelector((store: RootState) => store.role.userPermissions);
  const user = useSelector((store: RootState) => store.role.user._id);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (token) {
        if (!userPermissions?.permission) {
          logoutUser(user);
        }
      }
    })();
  }, []);

  if (userPermissions?.all || userPermissions?.permission.includes(permission)) {
    return children;
  }

  return null;
}

export default CanAccess;
