import React from 'react';
import { ObjectIdType } from 'src/Types/ObjectIdType';

function LeftFormWrapper({
  setFormType,
  setLeftVisibleTabData,
  leftVisibleData,
  setExpanded,
  expanded,
  loading,
  selectAllRight,
  selected,
  leftVisibleTabData,
  removed,
  addForm,
  addSelected,
  children,
}) {
  const formTypeData = [
    {
      label: 'Organiser',
      type: 'Organiser',
      id: 'organiser',
    },
    {
      label: 'Rally',
      type: 'Rally',
      id: 'rally',
    },
    {
      label: 'Participant',
      type: 'Participant',
      id: 'participant',
    },
    {
      label: 'Entrant',
      type: 'Entrant',
      id: 'entrant',
    },
    {
      label: 'Pilot',
      type: 'Pilot',
      id: 'piolot',
    },
    {
      label: 'Co-Pilot',
      type: 'Copilot',
      id: 'copilot',
    },
    {
      label: 'Car',
      type: 'Car',
      id: 'car',
    },
    {
      label: 'Reco (Reconotering)',
      type: 'Reco',
      id: 'reco',
    },
    {
      label: 'Assistance (Service)',
      type: 'Assistance',
      id: 'assistance',
    },
    {
      label: 'Customs',
      type: 'Customs',
      id: 'customs',
    },
    {
      label: 'Others',
      type: 'Other',
      id: 'others',
    },
    // {
    //   label: "FormHeader",
    //   type: "FormHeader",
    //   id: "formheader",
    // },
  ];

  const changeFormType = (name: string, groupId?: ObjectIdType) => {
    setFormType(name);
    if (name === 'Entrant') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Entrant'));
    } else if (name === 'Copilot') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Copilot'));
    } else if (name === 'Pilot') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Pilot'));
    } else if (name === 'Car') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Car'));
    } else if (name === 'Reco') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Reco'));
    } else if (name === 'Assistance') {
      setLeftVisibleTabData(
        leftVisibleData.filter((val) => val.category === 'Assistance'),
      );
    } else if (name === 'Other') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Other'));
    } else if (name === 'Organiser') {
      setLeftVisibleTabData(
        leftVisibleData.filter((val) => val.category === 'Organiser'),
      );
    } else if (name === 'Rally') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Rally'));
    } else if (name === 'Participant') {
      setLeftVisibleTabData(
        leftVisibleData.filter((val) => val.category === 'Participant'),
      );
    } else if (name === 'Customs') {
      setLeftVisibleTabData(leftVisibleData.filter((val) => val.category === 'Customs'));
    } else if (groupId) {
      setLeftVisibleTabData(
        leftVisibleData.filter((val) => val.fieldGroup?._id === groupId),
      );
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {children({
        setFormType,
        setLeftVisibleTabData,
        leftVisibleData,
        setExpanded,
        expanded,
        loading,
        selectAllRight,
        selected,
        leftVisibleTabData,
        removed,
        addForm,
        addSelected,
        formTypeData,
        changeFormType,
        handleChange,
      })}
    </>
  );
}

export default LeftFormWrapper;
