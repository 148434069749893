import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Icon } from '@iconify/react';
import CloseIcon from '@material-ui/icons/Close';
import { Responsive, WidthProvider } from 'react-grid-layout';
import clsx from 'clsx';
import 'react-grid-layout/css/styles.css';
import { useTranslation } from 'react-i18next';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import { Loader } from 'src/Services/Utils';
import ParticipantFormPreviewContext from 'src/Libs/DynamicForm/context/ParticipantFormPreviewContext';
import { renderFields } from 'src/Libs/DynamicForm/config/renderDynamicFormFields';
import { FormType } from 'src/Types/FormListType';
import UserType from 'src/Types/UserType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { EventType } from 'src/Types/EventType';
import { FormFieldAutoPickType } from 'src/Types/FormFieldAutoPickType';
import {
  getFormsByRallyIdAndUserId,
  getSingleFormByEventId,
} from 'src/Services/Auth/Actions/EventActions';
import { FormFields } from 'src/Types/UseFormTypes';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { filterAddedFieldsByParentWidgetIndex } from 'src/Components/DynamicTable/utils';
import FormHeader from '../../../Components/FormHeader/FormHeader';
import {
  GRID_LAYOUT_COLS,
  GRID_LAYOUT_ROWHEIGHT,
  GRID_LAYOUT_WIDTH,
  GRID_UNIT_WIDTH,
  fieldWrapperExtraStyles,
} from '../Rally/components/DynamicForm/util';
import useStyles from './FromPreviewStyle';
import useForms from '../Hooks/useForms';
import { NewCoordinate } from '../Rally/utils';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function Preview({
  item,
  user,
  rallyId,
  listForms,
  eventDetails,
  autoPickValues,
  setOpenPopup,
  mode,
}) {
  const classes = useStyles();
  const {
    onHandleChangeDate,
    checkBoxValue,
    findDropDownData,
    findTextFields,
    findTextFieldsGeneric,
    getValue,
    handleDropDownChange,
    onHandleChange,
    onHandleChangeCheckbox,
    onImageChange,
    showIcon,
    onClose,
    showFormName,
    showButtons,
    disabled,
    errors,
    userFormFeild,
    rallyFormFieldLayout,
    isFormSubmitting,
  } = useForms({ item, user, rallyId, listForms, setOpenPopup, autoPickValues });
  const forms = () => (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <Form>
        <ResponsiveGridLayout
          className="layout"
          rowHeight={GRID_LAYOUT_ROWHEIGHT}
          margin={[0, 0]}
          allowOverlap
          containerPadding={[0, 0]}
          width={GRID_UNIT_WIDTH}
          breakpoints={{ md: GRID_LAYOUT_WIDTH }}
          cols={{ md: GRID_LAYOUT_COLS }}
          isDraggable={false}
          isResizable={false}
          verticalCompact={false}
        >
          {rallyFormFieldLayout.length > 0 &&
            filterAddedFieldsByParentWidgetIndex(rallyFormFieldLayout).map(
              (data: FormFieldEditType) => (
                <div
                  data-grid={{
                    x: data.coordinates ? data.coordinates.x : '',
                    y: data.coordinates ? data.coordinates.y : '',
                    w: data.coordinates ? data.coordinates.w : '',
                    h: data.coordinates ? data.coordinates.h : '',
                    minW: 2,
                    minH: 2,
                  }}
                  key={data.index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    width: '100%',
                    ...fieldWrapperExtraStyles(data.styles),
                  }}
                >
                  <Grid
                    item
                    className={clsx([
                      `grid-item-${data.type}-custom-class ${classes.inputlabel}`,
                    ])}
                  >
                    {renderFields(data, mode)}
                  </Grid>
                </div>
              ),
            )}
        </ResponsiveGridLayout>
      </Form>
    </Formik>
  );

  const closeAction = () => {
    setOpenPopup(false);
    onClose();
  };
  const contextValue = useMemo(
    () => ({
      checkBoxValue,
      findDropDownData,
      findTextFields,
      findTextFieldsGeneric,
      getValue,
      handleDropDownChange,
      onHandleChange,
      onHandleChangeCheckbox,
      onHandleChangeDate,
      onImageChange,
      rallyFormFieldLayout,
      showIcon,
      userFormFeild,
      errors,
      isPreviewDisabled: disabled,
    }),
    [
      checkBoxValue,
      findDropDownData,
      findTextFields,
      findTextFieldsGeneric,
      getValue,
      handleDropDownChange,
      onHandleChange,
      onHandleChangeCheckbox,
      onHandleChangeDate,
      onImageChange,
      rallyFormFieldLayout,
      showIcon,
      userFormFeild,
      errors,
      disabled,
    ],
  );

  return (
    <ParticipantFormPreviewContext.Provider value={contextValue}>
      <div className={classes.mainFormContainer}>
        <div className={classes.paper}>
          <div className={classes.desc}>
            <div>{showFormName()}</div>
            <div className={classes.submitCloseContainer}>
              {user && showButtons()}
              <div className={classes.closeIcon} onClick={closeAction}>
                <CloseIcon />
              </div>
            </div>
          </div>
          <Loader isLoading={isFormSubmitting} type="ThreeDots">
            {item?.formHeaderDetails && (
              <FormHeader
                eventDetails={eventDetails}
                formHeaderDetails={item.formHeaderDetails}
                formName={item.formName}
                openHeaderModal={() => {}}
                isEditable={false}
              />
            )}
            <div className={classes.forms}>{forms()}</div>
          </Loader>
        </div>
      </div>
    </ParticipantFormPreviewContext.Provider>
  );
}

function FormPreview({
  item,
  user,
  rallyId,
  listForms,
  eventDetails,
  autoPickValues,
  mode,
}: {
  item: FormType;
  user: UserType;
  rallyId: ObjectIdType;
  listForms: () => void;
  eventDetails: Array<EventType>;
  autoPickValues: FormFieldAutoPickType;
  mode: 'right' | 'left' | 'preview';
}) {
  const { t } = useTranslation();
  const modal = useModalDialog();
  const classes = useStyles();
  const { eventId } = item;
  const userId = user?._id;
  const formId = item._id;
  const FormPreviewModalSetOpen = useMemo(() => modal(Preview, 'preview'), []);

  const openAction = async () => {
    try {
      let newItems: object = {};
      const mergeFormFieldsWithCoordinates = (
        formFields: FormFields[],
        coordinates: NewCoordinate[],
      ): FormFields[] =>
        formFields.map((field) => {
          const matchingCoordinate: NewCoordinate | undefined = coordinates.find(
            (coord) => coord.i === field.index,
          );
          if (matchingCoordinate) {
            return {
              ...field,
              coordinates: matchingCoordinate,
            };
          }
          return {
            ...field,
          };
        });

      if (item.isSubmitted) {
        const submitedFormLayout = await getFormsByRallyIdAndUserId(
          eventId,
          userId,
          formId,
        );
        if (submitedFormLayout) {
          const foundForm = submitedFormLayout.data.data.find(
            (data: { rallyId: string }) => data.rallyId === rallyId,
          );
          const { formFields, coordinates, data } = foundForm;
          const mergedFormData: FormFields[] = mergeFormFieldsWithCoordinates(
            formFields,
            coordinates,
          );
          newItems = { ...item, form: mergedFormData, formData: data };
        }
      } else {
        const formLayout = await getSingleFormByEventId(eventId, formId);
        if (formLayout) {
          const { formFields, coordinates } = formLayout.data;
          const mergedFormData: FormFields[] = mergeFormFieldsWithCoordinates(
            formFields,
            coordinates,
          );
          newItems = { ...item, form: mergedFormData };
        }
      }

      FormPreviewModalSetOpen(true, {
        item: newItems,
        user,
        rallyId,
        listForms,
        eventDetails,
        autoPickValues,
        overrideBody: true,
        mode,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const clickButton = () => {
    if (!item?.isSubmitted) {
      return (
        <div className={classes.formViewEdit} onClick={openAction}>
          {t('forms.View Form')}
        </div>
      );
    }
    if (item?.isSubmitted) {
      return (
        <div className={classes.formViewEdit} onClick={openAction}>
          {t('addNewForm.Edit Form')}
        </div>
      );
    }
    return (
      <Icon icon="feather:edit-3" className={classes.editIcon} onClick={openAction} />
    );
  };

  return <div>{clickButton()}</div>;
}

export default FormPreview;
