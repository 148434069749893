/* eslint-disable no-restricted-syntax */
import { NewCoordinate } from "../../utils";
import { calculateGridCoordinates } from "./util";

export const calculateSelectedFields = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  coordinates: NewCoordinate[],
  box: HTMLDivElement,
): NewCoordinate[] => {
  const rect: DOMRect = box.getBoundingClientRect();
  const selectedFields: NewCoordinate[] = [];
  const start = calculateGridCoordinates(startX, startY, rect);
  const end = calculateGridCoordinates(endX, endY, rect);

  for (const field of coordinates) {
    const fieldLeft = field.x;
    const fieldTop = field.y;
    const fieldRight = field.x + field.w;
    const fieldBottom = field.y + field.h;

    if (
      start.gridX <= fieldRight && end.gridX >= fieldLeft &&
      start.gridY <= fieldBottom && end.gridY >= fieldTop
    ) {
      selectedFields.push(field);
    }
  }

  return selectedFields;
};

export const getFieldByCoordinates = (x: number, y: number, coordinates: NewCoordinate[], box: HTMLDivElement | null):
  NewCoordinate | null => {
  if (box) {
    const rect: DOMRect = box.getBoundingClientRect();
    const { gridX, gridY } = calculateGridCoordinates(x, y, rect);

    for (const field of coordinates) {
      const fieldLeft = field.x;
      const fieldTop = field.y;
      const fieldRight = field.x + field.w;
      const fieldBottom = field.y + field.h;

      if (gridX >= fieldLeft && gridX <= fieldRight && gridY >= fieldTop && gridY <= fieldBottom) {
        return field;
      }
    }
  }
  return null;
};
