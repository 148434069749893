/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import FormHeader from 'src/Components/FormHeader/FormHeader';
import { Formik, Form } from 'formik';
import LanguageSelectTab from 'src/Components/LanguageSelect/LanguageSelectTab';
import { useTranslation } from 'react-i18next';
import LeftForm from './components/DynamicForm/LeftForm/LeftForm';
import LeftFormWrapper from './components/DynamicForm/LeftForm/LeftFormWrapper';
import useStyles from './editNewEventFormStyle';
import RightForm from './components/DynamicForm/RightForm/RightForm';
import FormMenu from './FormMenu';

function FormCreateEdit({
  setFormType,
  setLeftVisibleTabData,
  leftVisibleData,
  loading,
  selectAllRight,
  selected,
  leftVisibleTabData,
  removed,
  addForm,
  addSelected,
  setCoordinates,
  added,
  formHeaderDetails,
  eventDetails,
  generateLayout,
  editFormField,
  deleteCurrentItem,
  addRemove,
  isLoading,
  preview,
  pathname,
  formName,
  setFormName,
  formPreviewParticipant,
  coordinates,
  headerData,
  setHeaderData,
  setRemoved,
}) {
  const classes = useStyles();
  const [searchForm, setSearchForm] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);

  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.langtab}>
        <LanguageSelectTab />
      </div>
      <div className={classes.boxType}>
        <FormMenu
          isLoading={isLoading}
          setSearchForm={setSearchForm}
          preview={preview}
          pathname={pathname}
          formName={formName}
          setFormName={setFormName}
          formPreviewParticipant={formPreviewParticipant}
        />

        <div className={classes.mainWrap}>
          <div className={classes.leftForm}>
            <div className={classes.forms}>
              <LeftFormWrapper
                setFormType={setFormType}
                setLeftVisibleTabData={setLeftVisibleTabData}
                leftVisibleData={leftVisibleData}
                setExpanded={setExpanded}
                expanded={expanded}
                loading={loading}
                selectAllRight={selectAllRight}
                selected={selected}
                leftVisibleTabData={leftVisibleTabData}
                removed={removed}
                addForm={addForm}
                addSelected={addSelected}
              >
                {(props) => <LeftForm {...props} />}
              </LeftFormWrapper>
            </div>
          </div>

          <div className={classes.rightForm}>
            <div className={classes.forms}>
              <Formik initialValues={{}} onSubmit={() => {}}>
                <Form>
                  <div>
                    {formHeaderDetails && (
                      <FormHeader
                        eventDetails={eventDetails}
                        formHeaderDetails={formHeaderDetails}
                        formName={formName}
                        openHeaderModal={() => {}}
                        isEditable={false}
                      />
                    )}

                    <RightForm
                      setCoordinates={setCoordinates}
                      added={added}
                      searchForm={searchForm}
                      generateLayout={generateLayout}
                      editFormField={editFormField}
                      removed={removed}
                      deleteCurrentItem={deleteCurrentItem}
                      addRemove={addRemove}
                      coordinates={coordinates}
                      headerData={headerData}
                      setHeaderData={setHeaderData}
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormCreateEdit;
