import React from 'react';
import { FilledButton } from '../../Components/Utils';
import Container from '@material-ui/core/Container';
import './Style.css';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import theme from '../../Theme';
import { validator } from '../../Services/Auth/Validations';
import Auth from '../../Layouts/Auth/Auth';
import RESET_PASSWORD_IMAGE from './Images/social.png';
import { forgotPassword } from '../../Services/Auth/Actions/UserActions';
import ErrorMessageCustom from '../../Components/Utils/ErrorMessageCustom';

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
  },
}));

function ForgotPasswordView() {
  const { t } = useTranslation();

  const language = useSelector((state: RootState) => state.language.value);
  const ForgotPasswordSchema = Yup.object().shape({
    username: validator.USERNAME,
  });
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className="reset-password">
      <Auth
        heading={t('signUp.RESET PASSWORD')}
        varient="h2"
        image={RESET_PASSWORD_IMAGE}
      >
        <Container maxWidth="xs" className="container-wrap">
          <Formik
            initialValues={{
              username: '',
            }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              // same shape as initial values
              if (values.username) {
                values.username = values.username.toLowerCase().replace(/ +/g, '');
              }
              forgotPassword(values, language)
                .then((response) => {
                  const data = response;
                  setSubmitting(false);
                  toast.success(response.data.message, {
                    position: 'bottom-right',
                    autoClose: 2000,
                  });

                  history.push('/');
                })
                .catch((error) => {
                  setSubmitting(false);
                  toast.error(error.response.data.message, {
                    position: 'bottom-right',
                    autoClose: 5000,
                  });
                });
            }}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <Form>
                <Field
                  className={classes.input}
                  type="text"
                  name="username"
                  placeholder={t('signUp.username')}
                  value={values.username}
                />
                <ErrorMessageCustom errors={errors} field="username" touched={touched} />
                <div>
                  {!isSubmitting && (
                    <FilledButton
                      name={t('signUp.SEND RESET PASSWORD LINK')}
                      type="submit"
                      disabled={isSubmitting}
                    />
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={theme.palette.primary.main}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
                <div className="text-wrap">
                  <p>
                    <span>
                      <Link to="/">{t('signUp.Sign in')}</Link>
                    </span>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Auth>
    </div>
  );
}

export default connect(null, { forgotPassword })(ForgotPasswordView);
