import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ActionIcons, { ActionIconsType } from './ActionIcons';

type RowActionsProps = {
  actions: ActionIconsType[];
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const RowActions: React.FC<RowActionsProps> = ({ actions }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {actions.map((action, index) => (
        <ActionIcons
          // eslint-disable-next-line react/no-array-index-key
          key={`actions${index}`}
          onClick={() => action.onClick()}
          icon={action.icon}
          className={`${action.className || ''}`}
          style={action.style || {}}
        />
      ))}
    </div>
  );
};

export default RowActions;
