import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { fileResource } from 'src/Components/DocDownload/util';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import topActions from 'src/Types/Enums/EnumTopActions';
import CustomTable, {
  TopActionFilterButtonType,
  TopActionNormalButtonType,
} from 'src/Components/Table/Table';
import TableFilter from 'src/Components/Table/TableFilter';
import { formFieldMainGroups } from 'src/Services/Constants';
import useFetchExportDetails from 'src/Components/DocDownload/hooks/useFetchExportDetails';
import RowActions from 'src/Components/TableActions/RowActions';
import {
  DeleteFormField,
  FormFieldListSort,
} from '../../Services/Auth/Actions/FormFieldActions';
import { RootState } from '../../Store';
import useStyles from './FormFieldListingStyle';
import AddUpdateFormField from './AddUpdateFormField';

type TopActionButtonType = TopActionNormalButtonType | TopActionFilterButtonType;

function FormFieldListing() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [category, setCategory] = useState('');
  const language = useSelector((state: RootState) => state.language);
  const role = useSelector((state: RootState) => state.role.role);
  const confirm: any = useConfirm();

  const reloadList = ({ sort, page, rowsPerPage, search }) =>
    FormFieldListSort({
      sortBy: sort.sortBy,
      sort: sort.sort,
      page,
      pageSize: rowsPerPage,
      search,
      filter: category,
    }).then((response) => {
      const data = response.data.formFields;
      const totalCount = response.data.totalFormFields;
      return { data, totalCount };
    });

  const deleteField = (id, reload) => {
    confirm({
      description: t('popup.Do you want to delete ?'),
    }).then(() => {
      DeleteFormField(id).then(() => {
        toast.success(t('formField.deleted'), {
          position: toast.POSITION.TOP_CENTER,
        });
        reload({ sort: '', page: '', rowsPerPage: '', search: '' });
      });
    });
  };

  const modal = useModalDialog();
  const showAddEditModalPopup = useMemo(
    () => modal(AddUpdateFormField, 'form-field'),
    [],
  );

  const showAddEditModal = (id, reload) => {
    showAddEditModalPopup(true, { reload, updateId: id });
  };

  useEffect(() => {
    reloadList({ sort: '', page: '', rowsPerPage: '', search: '' });
  }, [language]);
  const data = {
    resource: fileResource.formFields,
    eventId: '',
    rallyId: '',
    organizerId: '',
    className: '',
    initialSortApi: undefined, // Provide a default value
    id: '',
    reportName: '',
    reload: undefined, // Provide a default value
  };
  const { handleClick } = useFetchExportDetails(
    data.eventId,
    data.rallyId,
    data.resource,
    data.organizerId,
    data.initialSortApi,
    data.id,
    data.reportName,
    data.reload,
  );

  const topActionButton: Array<TopActionButtonType> = [
    {
      type: topActions.button,
      extraProps: {
        name: t('formFields.Add New'),
        onClick: async ({ reload }) => showAddEditModal('', reload),
      },
    },
    {
      type: topActions.button,
      extraProps: {
        name: t('events.Export'),
        onClick: handleClick,
      },
    },
    {
      type: topActions.filter,
      extraProps: {
        id: category,
        setId: setCategory,
        filterOptions: formFieldMainGroups,
        component: TableFilter,
      },
    },
  ];

  const columns = [
    {
      title: t('formFields.Form Field'),
      field: 'label',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.wrap}>
          <div>
            <div className={classes.tbodyName}>{row.label}</div>
          </div>
        </div>
      ),
      isSortable: true,
    },
    {
      title: t('formFields.Id'),
      field: 'key',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbodyName} style={{ fontWeight: 600 }}>
          {row.key}
        </div>
      ),
      isSortable: false,
    },
    {
      title: t('formFields.Form Type'),
      field: 'type',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => (
        <div className={classes.tbodyName} style={{ fontWeight: 600 }}>
          {row.type}
        </div>
      ),
      isSortable: false,
    },
    {
      title: t('formFields.Hint'),
      field: 'hint',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => row.hint,
      isSortable: false,
    },
    {
      title: t('formFields.Category'),
      field: 'category',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row) => row.category,
      isSortable: true,
    },
    {
      title: t('formFields.Actions'),
      field: '',
      // eslint-disable-next-line react/no-unstable-nested-components
      data: (row, reload) => (
        <RowActions
          actions={[
            {
              onClick: () => showAddEditModal(row._id, reload),
              icon: 'feather:edit-3',
              className: classes.editIcon,
              style: { marginRight: '20px' },
            },
            {
              onClick: () => deleteField(row._id, reload),
              icon: 'ant-design:delete-twotone',
              className: classes.icon,
              style: {
                cursor: 'pointer',
                marginRight: 'auto',
              },
            },
          ]}
        />
      ),
      isSortable: false,
    },
  ];

  return (
    <CustomTable
      forceReload={category}
      tableName="formField"
      columns={columns}
      reload={reloadList}
      heading={t('formFields.Form Field Listing')}
      onDoubleClick={(item, reload) => showAddEditModal(item._id, reload)}
      role={role}
      topActionButtons={topActionButton}
    />
  );
}
export default FormFieldListing;
