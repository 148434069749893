import React from 'react';
import useDragAndDrop from 'src/Hooks/useDragandDrop';
import usePageBar from 'src/Hooks/usePageBar';
import Button from '../Button/Button';
import PageBarBase from '../PageBar/PageBarBase';

interface DragAndDropProps {
  // onDrop: (files: FileList) => void;
  acceptedTypes?: string; // MIME types, e.g. '.xlsx,.xls'
  onClick: any;
  sampleCsvHandleClick: () => void;
  loading: boolean;
  setOpenPopup: any;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
  acceptedTypes = '.xlsx,.xls,.csv',
  onClick,
  sampleCsvHandleClick,
  loading,
  setOpenPopup,
}) => {
  const {
    handleDrop,
    handleFileSelection,
    handleDragLeave,
    handleDragEnter,
    handleDragOver,
    isDragging,
    selectedFile,
    fileInputRef,
    reset,
    renderMobileMenu,
  } = useDragAndDrop();

  const { handleMobileMenuOpen } = usePageBar();

  return (
    <>
      <PageBarBase
        popUp
        handleMobileMenuOpen={handleMobileMenuOpen}
        rightContent={
          <>
            {selectedFile && (
              <span style={{ marginRight: '20px', color: 'black' }}>
                {selectedFile.name}
              </span>
            )}
            <Button
              onClick={() => onClick({ selectedFile, setOpenPopup })}
              label="Upload"
              loading={loading}
            />
            <Button
              onClick={() => {
                reset();
              }}
              label="Reset"
            />
            <Button onClick={sampleCsvHandleClick} label="Sample CSV" />
          </>
        }
      />
      {renderMobileMenu(onClick, loading, sampleCsvHandleClick, selectedFile)}
      <div
        style={{
          border: '2px dashed gray',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: isDragging ? 'lightyellow' : 'white',
          cursor: 'pointer',
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files) {
              handleFileSelection(e.target.files);
            }
          }}
          accept={acceptedTypes}
        />
        {!selectedFile ? (
          <p>Drag & drop an Excel file here or click to select one</p>
        ) : (
          <p>Selected: {selectedFile.name}</p>
        )}
      </div>
    </>
  );
};

export default DragAndDrop;
