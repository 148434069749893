import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import colors from 'src/Theme/colors';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { toast } from 'react-toastify';
import { eventOrganizerType } from 'src/Types/eventOrganizerType';
import { deleteOrganizerContact } from '../../../Services/Auth/Actions/OrganizerActions';

const useStyles = makeStyles(() => ({
  btn: {
    padding: '0px',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  wrapper: {
    display: 'flex',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    marginRight: '5px',
  },
}));

const ITEM_HEIGHT = 30;

function OrganizerContactDelete({
  item,
  reload,
  handleOpenEditPopup,
}: {
  item: eventOrganizerType;
  reload: Function;
  handleOpenEditPopup: React.MouseEventHandler<HTMLDivElement>;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirm: any = useConfirm();
  const showModalPopup = () => {
    const confirmationMessage = item.isAssigned
      ? 'popup.This event organizer is assigned to an event. Are you sure you want to delete?'
      : 'popup.Do you want to delete ?';

    confirm({
      description: t(confirmationMessage),
    }).then(() => {
      deleteOrganizerContact(item._id).then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'max-content',
            padding: 0,
          },
        }}
      >
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper} onClick={handleOpenEditPopup}>
            <Icon icon="feather:edit-3" className={classes.editIcon} />
            <p className={classes.option}>{t('forms.Edit')} </p>
          </div>
        </MenuItem>
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper} onClick={() => showModalPopup()}>
            <Icon icon="ant-design:delete-twotone" className={classes.icon} />
            <p className={classes.option}>{t('forms.Delete')} </p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default OrganizerContactDelete;
