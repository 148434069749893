import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 1,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginRight: '20px !important',
    '@media only screen and (max-width: 650px)': {
      margin: '0px',
      marginRight: '0px !important',
      padding: '6px 0px',
    },
  },
  avatar: {
    width: '50px',
    height: '50px',
    // border: "2px solid #2148c0",
    // borderRadius: "100%",
    objectFit: 'contain',
    pointerEvents: 'none',
  },
  userName: {
    height: '20px',
    width: '120px',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',
    '@media only screen and (max-width: 450px)': {
      margin: '0px !important',
    },
  },
  tooltip: {
    fontSize: '12px',
    font: typography.fonts.secondary,
  },
}));
