import React, { useCallback, useEffect, useState } from 'react';
import { debounce, get } from 'lodash';
import { Grid } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { Formik, Field } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApproveRejectButton from 'src/Components/Utils/ApproveRejectButton';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { rallyListing } from 'src/Services/Auth/Actions/RallyActions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import FilterList from 'src/Components/Utils/FilterList';
import { EventType } from 'src/Types/EventType';
import { FormType } from 'src/Types/FormListType';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { FormFieldAutoPickType } from 'src/Types/FormFieldAutoPickType';
import BackArrow from 'src/Components/BackButton/BackArrow';
import { commonResponseHandler, Loader } from '../../../Services/Utils';
import {
  listParticipantsByEventId,
  approveUser,
  updateRacingNumber,
  reinstateParticipant,
  downloadZip,
  getEvent,
  downloadFormDataPDF,
} from '../../../Services/Auth/Actions/EventActions';
import AVATAR from '../Rally/AvatarImage.svg';
import {
  SubTitle,
  FormLabel,
  FormButton,
  CircularLoader,
} from '../../../Components/Utils';
import useStyles from './ManageParticipantsStyle';
import EventFormsListing from './EventFormsListing';
import FormPreview from './FormPreview';

type Participant = {
  _id: string;
  racingNumber: string;
  name: string;
  email: string;
  rallyIds: string[];
  userId: string;
  rallyId?: string;
};
type ParticipantInfo = {
  pRallyId: string;
  racingNumber: string;
  rallyName: string;
};
type FormTypes = FormType & { formId: string };

function ManageParticipants() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState<any>([]);
  const [load, setLoad] = useState(true);
  const { eventId, userId }: any = useParams();
  const [zipLoad, setZipLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<Array<any>>([]);
  const [allList, setAllList] = useState<Array<any>>([]);
  const [rallyFilterId, setRallyFilterId] = useState('all');
  const [rallyOptions, setRallyOptions] = useState<Array<any>>([]);
  const role = useSelector((store: RootState) => store.role.role);
  const [eventDetails, setEventDetails] = useState<Array<EventType>>([]);
  const [pdfLoad, setPdfLoad] = useState({});
  const [participant, setParticipant] = useState<Array<Participant>>([]);

  const reload = () => {
    listParticipantsByEventId(eventId, userId)
      .then((res) => {
        const { data } = res.data;
        setParticipant(data);
        setUserDetails({ ...data[0] });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
        setUserDetails([]);
      });
  };

  const isApproveTrue = () => {
    commonResponseHandler(
      approveUser(eventId, userId, { isApproved: true }),
      reload,
      () => {},
    );
  };

  const isApproveFalse = () => {
    commonResponseHandler(
      approveUser(eventId, userId, { isApproved: false }),
      reload,
      () => {},
    );
  };

  const updateRacingNum = async (rn: string, rallyId: string) => {
    let valuse = {};
    if (rn) {
      valuse = { racingNumber: rn, rallyId };
    }
    commonResponseHandler(
      updateRacingNumber(eventId, userId, valuse),
      () => {
        reload();
      },
      () => {},
    );
  };

  const updateFolderNum = (fnd) => {};

  const debouncedReload = useCallback(
    debounce((value) => updateFolderNum(value), 2000),
    [],
  );

  const debouncedReloadRacingNumber = useCallback(
    debounce((value, rallyId) => updateRacingNum(value, rallyId), 2000),
    [],
  );

  const changeRacingNumber = (rallyId: string, e: { target: { value: string } }) => {
    const racingNum = e.target.value.trim();
    const updatedData: Participant[] = participant.map((participants) => {
      if (participants.rallyId === rallyId) {
        return {
          ...participants,
          racingNumber: racingNum,
        };
      }
      return participants;
    });

    setParticipant(updatedData);
    if (racingNum !== '') {
      if (debouncedReloadRacingNumber) {
        debouncedReloadRacingNumber(racingNum, rallyId);
      }
    }
  };

  const participantReinstate = () => {
    commonResponseHandler(reinstateParticipant(eventId, userId), () => reload());
  };

  const approvalStatus = () => {
    if (userDetails?.isApproved === true) {
      return <div className={classes.approved}>{t('events.Approved')}</div>;
    }
    if (userDetails?.isApproved === false) {
      return (
        <>
          <div className={classes.statusWrapper}>
            <div className={classes.cancelled}>{t('events.Declined')}</div>
          </div>
          <div className={classes.btnWrapper}>
            <div className={classes.btnReinstate}>
              <ApproveRejectButton
                onClick={() => {
                  participantReinstate();
                }}
                name={t('events.Reinstate')}
                type="greenBtn"
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <div className={classes.btnWrap}>
        <ApproveRejectButton
          type="greenBtn"
          name={t('events.Approve')}
          onClick={() => isApproveTrue()}
        />
        <ApproveRejectButton
          name={t('events.Decline')}
          onClick={() => isApproveFalse()}
        />
      </div>
    );
  };

  const getRallyList = () => {
    rallyListing({ id: eventId, rowsPerPage: 20 * 1000 }).then(async (res) => {
      const Rallies = res.data.Rallies.filter((vl: { isActive: boolean }) => vl.isActive);
      setRallyOptions([...Rallies]);
    });
  };

  const handleDownloadZip = (rallyIds: string) => {
    setZipLoad(true);
    downloadZip(eventId, rallyIds, userId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Participant details.zip');
        document.body.appendChild(link);
        link.click();
        setZipLoad(false);
      })
      .catch(() => {
        setZipLoad(false);
        ErrorToast(t('General settings.Zip download failed'));
      });
  };

  useEffect(() => {
    reload();
    getRallyList();
    getEvent(eventId)
      .then((response) => {
        setEventDetails(response.data.event);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [role]);

  const formPDFDownload = (item, key, rallyId) => {
    const loadPDF = {};
    loadPDF[key] = true;
    setPdfLoad({ ...pdfLoad, ...loadPDF });
    downloadFormDataPDF(eventId, item._id, userId, rallyId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        const { formName } = item;
        link.setAttribute('download', `${formName}.pdf`);
        document.body.appendChild(link);
        link.click();
        loadPDF[key] = false;
        setPdfLoad({ ...pdfLoad, ...loadPDF });
      })
      .catch(() => {
        loadPDF[key] = false;
        setPdfLoad({ ...pdfLoad, ...loadPDF });
        ErrorToast(t('General settings.Pdf download failed'));
      });
  };

  const formActionButtons = ({
    form,
    rallyId,
    listForms,
    autoPickValues,
  }: {
    form: FormTypes;
    rallyId: ObjectIdType;
    listForms: () => void;
    autoPickValues: FormFieldAutoPickType;
  }) => (
    <>
      <FormPreview
        item={form}
        user={userDetails.user}
        rallyId={rallyId}
        listForms={listForms}
        eventDetails={eventDetails}
        autoPickValues={autoPickValues}
        mode="preview"
      />
      {form.isSubmitted && (
        <Loader isLoading={pdfLoad[form._id]} type="ThreeDots">
          <Icon
            icon="fe:download"
            className={classes.editIcon}
            onClick={() => formPDFDownload(form, form._id, rallyId)}
          />
        </Loader>
      )}
    </>
  );

  const participantInfoList: ParticipantInfo[] = participant.map((p) => {
    const matchedRally: {
      _id: string;
      rallyId: string;
      racingNumber: string;
      rallyName: string;
    } = rallyOptions?.find((option) => option._id === p.rallyId);
    const rally = matchedRally ? matchedRally.rallyName : '';
    return {
      pRallyId: matchedRally?._id,
      racingNumber: p.racingNumber,
      rallyName: rally,
    };
  });

  const racingNumberList = participantInfoList.map((p: ParticipantInfo) => (
    <Grid item lg={11} xs={11} key={p.pRallyId}>
      <FormLabel
        name={`${p.rallyName} ${t('events.Racing Number')}`}
        className={classes.inputlabel}
      />
      <Field
        className={classes.input}
        text="Racing Number"
        name="Racing Number"
        value={p.racingNumber}
        onChange={(event: { target: { value: string } }) =>
          changeRacingNumber(p.pRallyId, event)
        }
        minlength="4"
      />
    </Grid>
  ));

  return (
    <div>
      <div className={classes.boxType}>
        <div className={classes.wrapper}>
          <div className={classes.leftWrap}>
            <BackArrow />
            <SubTitle title={t('participant.Manage Participant')} />
          </div>
        </div>
        <div className={classes.main}>
          <div className={classes.mainWrap}>
            <div className={classes.topWrap}>
              <h3 className={classes.title}>{t('events.PARTICIPANT DETAILS')}</h3>
            </div>
            {load ? (
              <CircularLoader />
            ) : (
              <>
                <div>
                  <div className={classes.imgWrap}>
                    <img
                      src={get(userDetails, 'user.profileImage', AVATAR)}
                      alt="avatar"
                      className={classes.img}
                    />
                    <h5 className={classes.h5}>
                      {get(userDetails, 'user.lastName', '')}{' '}
                      {get(userDetails, 'user.firstName', '')}
                    </h5>
                    <div
                      onClick={() => {
                        history.push({
                          pathname: `/users/details/${userDetails.user._id}/profile`,
                          state: { showGoBack: true },
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {t('events.View profile')}
                    </div>
                  </div>
                  <div className={classes.wrap}>
                    <div style={{ display: 'flex' }}>
                      <Icon icon="fluent:mail-24-filled" className={classes.icon} />
                      <a href={`mailto:${get(userDetails, 'user.email', '')}`}>
                        <p className={classes.p} style={{ padding: '6px 0 0 0' }}>
                          {get(userDetails, 'user.email', '')}
                        </p>
                      </a>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <Icon icon="bytesize:telephone" className={classes.icon} />
                      <a href={`tel:${get(userDetails, 'user.phone', '')}`}>
                        <p className={classes.p} style={{ padding: '6px 0 0 0' }}>
                          {get(userDetails, 'user.phone', '')}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <Formik initialValues={{}} onSubmit={() => {}}>
                    <>
                      <div className={classes.submitBox}>{racingNumberList}</div>
                      <Grid
                        spacing={3}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '116px',
                        }}
                      >
                        {approvalStatus()}
                      </Grid>
                    </>
                  </Formik>
                </div>
              </>
            )}
          </div>
          <div className={classes.mainWrap}>
            <div className={classes.topWrap}>
              <h3 className={classes.title}>{t('events.SUBMITTED FORMS')}</h3>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {' '}
                <Loader isLoading={zipLoad} type="ThreeDots">
                  <div
                    onClick={() =>
                      handleDownloadZip(rallyFilterId === 'all' ? 'all' : rallyFilterId)
                    }
                  >
                    <FormButton name="Download" className={classes.downloadButton} />
                  </div>
                </Loader>
                <FilterList
                  allList={allList}
                  setLoading={setLoading}
                  setList={setList}
                  setRallyFilterId={setRallyFilterId}
                />
              </div>
            </div>
            <Loader isLoading={loading} type="circular">
              <EventFormsListing
                eventId={eventId}
                userId={userId}
                actionButtons={formActionButtons}
                listProp={list}
                setAllList={setAllList}
              />
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageParticipants;
