/* eslint-disable react/jsx-props-no-spreading */
import { Draggable } from 'react-beautiful-dnd';
import { DocField, DraggableContainerProps } from '../../types/docType';
import DraggableExportField from './DraggableExportField';
import useStyles from '../../style';

function DraggableContainer({
  droppableProvided,
  dropppableSnapshot,
  column,
}: DraggableContainerProps) {
  const classes = useStyles();
  return (
    <div
      {...droppableProvided.droppableProps}
      ref={droppableProvided.innerRef}
      style={{
        background: dropppableSnapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
      }}
      className={classes.droppableContainer}
    >
      {column.items.map((item: DocField, index: number) => (
        <Draggable key={item._id} draggableId={item._id} index={index}>
          {(draggableProvided, draggableSnapshot) => (
            <DraggableExportField
              draggableProvided={draggableProvided}
              draggableSnapshot={draggableSnapshot}
              column={column}
              item={item}
              key={item._id}
            />
          )}
        </Draggable>
      ))}
      {droppableProvided.placeholder}
    </div>
  );
}

export default DraggableContainer;
