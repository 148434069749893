import React from 'react';
import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';

const useStyles = makeStyles(() => ({
  h4: {
    ...typography.h4,
    color: colors.secondary.color,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '205px',
  },
}));

function SubTitle({ title }: { title: string }) {
  const classes = useStyles();
  return <h4 className={classes.h4}>{title}</h4>;
}

export default SubTitle;
