import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Icon } from '@iconify/react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { commonResponseHandler } from '../../../Services/Utils';
import { blockUser, deleteUser } from '../../../Services/Auth/Actions/UserActions';

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '0px',
  },
  icon: {
    color: '#E40909',
    fontSize: '20px',
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  unblockIcon: {
    color: '#155729',
    fontSize: '24px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  blocks: {
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '6px',
    fontFamily: 'Mulish',
    margin: '0',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
}));

const ITEM_HEIGHT = 30;

function UserDelete({ id, reload, item }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const confirm: any = useConfirm();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showModalPopup = () => {confirm({
    description:
      item?.activeEvents > 0 || item?.completedEvents > 0
        ? `${t('toast.This participant active in event')} ${t(
            'popup.Are you sure you want to delete the participant?',
          )}`
        : t('popup.Are you sure you want to delete the participant?'),
  }).then(() => {
    commonResponseHandler(deleteUser(id), () => reload());
  });
  };

  const isActiveClick = (user) => {
    confirm({
      description: t('popup.Are you sure you want to change the block status?'),
    }).then(() => {
      blockUser(user._id, { isActive: !user.isActive }).then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reload();
      });
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.btn}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'max-content',
            padding: 0,
          },
        }}
      >
        {item.isActive === true ? (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper1} onClick={() => isActiveClick(item)}>
              <Icon icon="ic:round-block" className={classes.blockIcon} />
              <p className={classes.blocks}>Block</p>{' '}
            </div>
          </MenuItem>
        ) : (
          <MenuItem key="" onClick={handleClose}>
            <div className={classes.wrapper1} onClick={() => isActiveClick(item)}>
              <Icon icon="gg:unblock" className={classes.unblockIcon} />
              <p className={classes.blocks}>Unblock</p>
            </div>
          </MenuItem>
        )}
        <MenuItem key="" onClick={handleClose}>
          <div className={classes.wrapper1} onClick={showModalPopup}>
            <Icon icon="ant-design:delete-twotone" className={classes.icon} />
            <p className={classes.option}>{t('forms.Delete')} </p>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserDelete;
