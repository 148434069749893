// react grid layout for dragging and resizing
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import PreviewWrapper from './PreviewWrapper';
import EventFormPreviewContent from './EventFormPreviewContent';

function EventFormPreview({
  eventId,
  formId,
  setOpenPopup,
  rallyIds,
}: {
  eventId: String;
  formId: String;
  setOpenPopup: Function;
  rallyIds?: string;
}) {
  return (
    <PreviewWrapper
      formId={formId}
      eventId={eventId}
      rallyIds={rallyIds}
      setOpenPopup={setOpenPopup}
    >
      <EventFormPreviewContent />
    </PreviewWrapper>
  );
}

export default EventFormPreview;
