import React from 'react';
import { TableCell , makeStyles } from "@material-ui/core";
import colors from 'src/Theme/colors';
import SortType from 'src/Types/SortType';
import typography from 'src/Theme/typography';
import { Icon } from '@iconify/react';

const useStyles = makeStyles(() => ({
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  arrowIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
    color:'#9FA2B4'
  },
  arrowIconActive: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
    color: colors.primary.color,
  },
}));

function TableHeader({
    changeSort,
    sort,
    field,
    label
  }:{
    changeSort:any,
    sort: SortType,
    field: string,
    label: string
  }) {
    const classes = useStyles();
    return <TableCell
        onClick={() => changeSort(field)}
        className={classes.thead}
      >
        {label}{' '}
        <Icon
          icon="akar-icons:arrow-up-down"
          className={
            sort.sortBy === field
              ? classes.arrowIconActive
              : classes.arrowIcon
          }
        />
      </TableCell>;
  }

  export default TableHeader;