import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import { useTranslation } from 'react-i18next';
import { getToken, userEnrolledRallies } from 'src/Services/Auth/Actions/RallyActions';
import useStyles from './EventFormsListingStyle';
// eslint-disable-next-line import/no-cycle
import { FormList } from './EventFormsListing';

function RallyFormsListing({
  forms,
  eventId,
  rallyId,
  status,
  listForms,
  actionButtons,
  autoPickValues,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEnrolled, setIsEnrolled] = useState<any>();
  const role = useSelector((store: RootState) => store.role.role);

  const enrolledRallies = () => {
    userEnrolledRallies({
      eventId,
    })
      .then((response) => {
        setIsEnrolled(response.data.rally.find((id: number) => id === rallyId));
      })
      .catch((error) => {
        console.log('error :', error);
      });
  };

  const showFormName = (form) => {
    if (form?.formId?.formName) {
      return form.formId.formName;
    }
    if (form?.formName) {
      return form.formName;
    }
    return 'Form Name';
  };

  useEffect(() => {
    if (getToken()) {
      enrolledRallies();
    }
  }, [role]);

  return (
    <div>
      {forms.length > 0 &&
        forms.map((form: FormList) => (
          <div
            className={classes.tbody}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '5px 10px',
            }}
          >
            <div className={classes.subTitle}>{showFormName(form)}</div>
            {status && (
              <div className={classes.tbody}>
                {form?.isSubmitted ? (
                  <div className={classes.completed}>{t('forms.completed')}</div>
                ) : (
                  <div className={classes.pending}>{t('forms.pending')}</div>
                )}
              </div>
            )}
            <div className={classes.iconContainer}>
              {actionButtons({
                form,
                rallyId,
                listForms,
                isEnrolled,
                autoPickValues,
              })}
            </div>
          </div>
        ))}
    </div>
  );
}

export default RallyFormsListing;
