import React, { useContext } from 'react';
import { Field } from 'formik';
import FormError from 'src/Components/FormError/FormError';
import { FormFieldType } from 'src/Libs/DynamicForm/types/FormFieldType';
import { getGenericDateValue } from 'src/Containers/Events/Rally/components/DynamicForm/util';
import FieldLabel from './Fields/FieldLabel';
import fieldStyles from './FieldStyle';
import HintField from './Fields/HintField';
import ParticipantFormPreviewContext from '../../context/ParticipantFormPreviewContext';

function GetDateField({
  isEditable = true,
  disabled = true,
  onChange,
  error,
  fieldData,
  aditionalProps,
  mode,
}: FormFieldType) {
  const { isPreviewDisabled, getValue, onHandleChangeDate } = useContext(
    ParticipantFormPreviewContext,
  );
  const classes = fieldStyles();
  const { label, type, hint, styles } = fieldData;
  const previewMode = mode === 'preview' || mode === 'readOnlyPreview';
  return (
    <>
      <FieldLabel name={label} styles={styles} />
      {!isEditable ? (
        <Field
          className={classes.input}
          text={label}
          type={type}
          name={label}
          value={getGenericDateValue(fieldData)}
          disabled={disabled}
          placeholder={getGenericDateValue(fieldData)}
        />
      ) : (
        <>
          <Field
            className={classes.input}
            text={label}
            type={type}
            name={label}
            value={
              mode === 'preview'
                ? getValue && getValue(fieldData.index, fieldData)
                : aditionalProps?.values
            }
            readOnly={previewMode ? isPreviewDisabled : disabled}
            onChange={
              mode === 'preview'
                ? (event) =>
                    onHandleChangeDate(
                      event,
                      fieldData.values,
                      fieldData.index,
                      fieldData.type,
                      fieldData._id,
                    )
                : onChange
            }
          />
          <FormError error={error} />
        </>
      )}
      <HintField hint={hint} />
    </>
  );
}

export default GetDateField;
