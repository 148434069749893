import React, { useMemo, useContext } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import {
  GRID_LAYOUT_COLS,
  GRID_LAYOUT_ROWHEIGHT,
  GRID_LAYOUT_WIDTH,
  GRID_UNIT_WIDTH,
} from 'src/Containers/Events/Rally/components/DynamicForm/util';
import { SetStateSetterType } from 'src/Types/UseFormTypes';
import DynamicFormContext from './context/DynamicFormContext';
import { RenderMode } from './types/FormFieldType';

type DynamicFormBuilderProps = {
  children: string | JSX.Element | JSX.Element[];
  onDragStart: () => void;
  onDragStop: (
    layout: Layout[],
    oldItem: Layout,
    newItem: Layout,
    placeholder: Layout,
    e: MouseEvent,
    element: HTMLElement,
  ) => void;
  isDraggable: boolean;
  isCustomeLayoutChange: React.MutableRefObject<boolean>;
  mode: RenderMode;
  searchForm: string;
  setIsDraggable: SetStateSetterType<boolean>;
};

function DynamicFormBuilder({
  children,
  onDragStart,
  onDragStop,
  isDraggable,
  isCustomeLayoutChange,
  mode,
  searchForm,
  setIsDraggable,
}: DynamicFormBuilderProps) {
  const ResponsiveGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const { coordinates, setCoordinates } = useContext(DynamicFormContext);
  return (
    <ResponsiveGridLayout
      className="layout"
      rowHeight={GRID_LAYOUT_ROWHEIGHT}
      margin={[0, 0]}
      containerPadding={[0, 0]}
      width={GRID_UNIT_WIDTH}
      breakpoints={{ md: GRID_LAYOUT_WIDTH }}
      cols={{ md: GRID_LAYOUT_COLS }}
      onLayoutChange={(l) => {
        if (isCustomeLayoutChange.current === true) {
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            isCustomeLayoutChange.current = false;
          }, 500);
        }
        if (searchForm.length <= 0) {
          setCoordinates(l);
        }
      }}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      layouts={{ md: coordinates }}
      compactType={null}
      isDraggable={isDraggable}
      // isResizable={false}
      allowOverlap
    >
      {children}
    </ResponsiveGridLayout>
  );
}

export default DynamicFormBuilder;
