import useConfirm from 'src/Libs/ConfirmationDialog/UseConfirm';

type ConfirmFunction = (
  description: string | JSX.Element,
  successCb: Function,
  errorCb?: Function,
) => Promise<void>;

const useConfirmationDialog = (): ConfirmFunction => {
  const confirm: any = useConfirm();

  return (
    description: string | JSX.Element,
    successCb: Function,
    errorCb: Function = () => {},
  ) =>
    new Promise<void>((resolve, reject) => {
      confirm({ description })
        .then(() => {
          resolve();
          successCb();
        })
        .catch(() => {
          reject();
          errorCb();
        });
    });
};

export default useConfirmationDialog;
