import React from 'react';
import Container from '@material-ui/core/Container';
import './Style.css';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core';
import PasswordField from 'src/Components/PasswordField/PasswordField';
import { commonResponseHandler } from '../../Services/Utils';
import ErrorMessageCustom from '../../Components/Utils/ErrorMessageCustom';
import { organizerReset } from '../../Services/Auth/Actions/OrganizerActions';
import RESET_PASSWORD_IMAGE from './Images/ResetPassword.svg';
import Auth from '../../Layouts/Auth/Auth';
import { validator } from '../../Services/Auth/Validations';
import theme from '../../Theme';
import { FilledButton } from '../../Components/Utils';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: 'transparent',
    border: '1px solid #363740',
    padding: '10px 10px',
    borderRadius: '5px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '10px',
    width: '100%',
  },
}));

function OrganizerResetView() {
  const OrganizerResetSchema = Yup.object().shape({
    username: validator.USERNAME,
    password: validator.PASSWORD,
    confirmPassword: validator.CONFIRMPASSWORD,
  });

  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const { id }: any = params;
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');

  return (
    <div className="reset-password">
      <Auth
        heading="Create your Username & Password"
        varient="h6"
        image={RESET_PASSWORD_IMAGE}
      >
        <Container maxWidth="xs" className="container-wrap">
          <Formik
            initialValues={{
              username: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={OrganizerResetSchema}
            onSubmit={(values, { setSubmitting }) => {
              const data: any = {
                ...values,
                token,
              };
              if (data.username) {
                data.username = data.username.toLowerCase().replace(/ +/g, '');
              }
              delete data.confirmPassword;

              commonResponseHandler(
                organizerReset(data, id, token),
                () => {
                  setSubmitting(false);
                  history.push('/');
                },
                () => setSubmitting(false),
              );
              // organizerReset(data,id,token)
              // .then((response)=>{

              //   const data = response;

              //       toast.success(response.data.message, {
              //               position: "bottom-right",
              //               autoClose: 2000,
              //           },
              //       );
              //       console.log("FORGOT PASSWORD success",data)
              //       history.push("/");

              // }).catch(function (error){
              //   alert("hiiii")
              //   console.log("forgot error",error.response.data.message)

              //     toast.error(error.response.data.message, {
              //             position: "bottom-right",
              //             autoClose: 5000,
              //         },
              //     );
              // })
            }}
          >
            {({ isSubmitting, values, errors, touched }) => (
              <Form>
                <Field
                  className={classes.input}
                  text="username"
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={values.username}
                />
                <ErrorMessageCustom errors={errors} field="username" touched={touched} />
                <Field
                  className={classes.input}
                  text="password"
                  component={PasswordField}
                  name="password"
                  placeholder="Password"
                  value={values.password}
                />
                <ErrorMessageCustom errors={errors} field="password" touched={touched} />
                <Field
                  className={classes.input}
                  text="confirmPassword"
                  component={PasswordField}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                />
                <ErrorMessageCustom
                  errors={errors}
                  field="confirmPassword"
                  touched={touched}
                />
                <div>
                  <FilledButton name="submit" type="submit" />
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={theme.palette.primary.main}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
                <div className="text-wrap">
                  <p>
                    <span>
                      <Link to="/">Sign in</Link>
                    </span>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Auth>
    </div>
  );
}

export default connect(null, { organizerReset })(OrganizerResetView);
