// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Autocomplete.css';

export default function Asynchronous({ search, onChange }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading || !open) {
      return undefined;
    }

    (async () => {
      const response = await search(searchText);
      const organizers = await response.data.organizers;
      if (active) {
        setOptions(organizers);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchText, open]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setSearchText('');
        setOpen(false);
      }}
      onChange={onChange}
      getOptionSelected={(option: any, value) => option._id === value._id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          onChange={(e) => setSearchText(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>
                <div style={{ background: 'white' }}>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </div>
              </div>
            ),
          }}
        />
      )}
    />
  );
}
