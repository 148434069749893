import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import FormFieldListing from '../../Containers/FormFields/FormFieldListing';

function FormFields() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('formFields.Form Field Management')} />

        <FormFieldListing />

        {/* <BreadCrumbs/> */}
      </Dashboard>
    </div>
  );
}

export default FormFields;
