import { makeStyles } from '@material-ui/core';
import typography from 'src/Theme/typography';
import colors from 'src/Theme/colors';
import theme from 'src/Theme';

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
    '@media all and (min-width: 1440px) and (min-height: 800px)': {
      maxHeight: '65vh !important',
    },
    // height:'auto',
    // ["@media all and (max-width: 1500px)"]: {

    // },
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    margin: 'auto',
    textAlign: 'center',
  },
  thead1: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    // margin: "auto",
    textAlign: 'center',
  },
  wrap: {
    display: 'flex',
    // justifyContent: "center",
  },
  grey: {
    margin: 'auto',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
    width: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  grey1: {
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
  },
  editIcon: {
    color: '#2ED82A',
    fontSize: '20px',
    cursor: 'pointer',
  },
  iconCancel: {
    color: '#E40909',
    fontSize: '19px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
  },
  arrowIconActive: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
    color: colors.primary.color,
  },
  img: {
    borderRadius: '50%',
    marginRight: '15px',
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  boxType: {
    padding: '20px',
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (min-width: 1440px)': {
      maxHeight: '100vh',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 650px)': {
      display: 'block',
    },
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  wrapper2: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-start;',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginLeft: '10px',
    '@media only screen and (max-width: 450px)': {
      display: 'block',
      minWidth: '250px',
      marginBottom: '5px',
    },
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  tbody: {
    height: '300px',
    margin: '0 auto',
    padding: '15px',
    fontFamily: typography.fonts.secondary,
    textAlign: 'center',
  },
  title: {
    ...typography.h5,
    marginBottom: '25px',
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  desc: {
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: '230px',
    margin: '0 auto 32px',
    '&a': {
      color: colors.primary.color,
    },
  },
  approved: {
    height: 'auto',
    width: 'auto',
    backgroundColor: '#29CC97',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '100px',
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  cancelled: {
    fontWeight: 700,
    height: 'auto',
    width: 'auto',
    backgroundColor: '#E40909',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '100px',
    color: '#fff',
    textTransform: 'uppercase',
  },
  racingNumberInput: {
    width: '50px',
    border: 'none',
    borderBottom: '1px solid #020202',
    background: 'transparent',
    outline: 'none',
  },
  count: {
    fontFamily: typography.fonts.secondary,
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '20px',
    color: colors.primary.color,
    margin: '0',
  },
  editIconR: {
    color: colors.primary.color,
    fontSize: '25px',
    cursor: 'pointer',
  },
  btnFilter: {
    padding: '0px',
    marginLeft: '10px',
  },
  icon: {
    color: colors.primary.color,
    fontSize: '30px',
    cursor: 'pointer',
    // marginLeft:'10px'
  },
  option: {
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0',
    fontFamily: 'Mulish',
    padding: '0 15px 20px',
  },
  titleFilter: {
    fontSize: '14px',
    fontFamily: 'Mulish',
    padding: '0 15px 20px',
    fontWeight: 500,
    borderBottom: '1px solid #D4D5DE',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  btnWrap: {
    '@media only screen and (max-width: 450px)': {
      alignItems: 'center',
      display: 'grid !important',
      padding: '0px 9px 7px 0px',
      justifyItems: 'center',
      height: '175px',
    },
  },
  btnOne: {
    '@media only screen and (max-width: 450px)': {
      minWidth: '250px',
      margin: '0px',
      marginLeft: '10px',
    },
  },
}));
