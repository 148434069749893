import { makeStyles } from '@material-ui/core';
import colors from 'src/Theme/colors';
import typography from 'src/Theme/typography';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  thead: {
    color: '#9FA2B4',
    fontWeight: 700,
    backgroundColor: '#fff',
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
  },
  FilledButton: {
    padding: '7px 7px',
    fontSize: '14px',
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  imgWrap: {
    borderRadius: '10px',
    height: '50px',
    width: '100px',
    marginRight: '15px',
  },
  tbody: {
    padding: '15px',
    fontFamily: typography.fonts.secondary,
    maxHeight: '300px',
    margin: '0 auto',
    textAlign: 'center',
  },
  grey: {
    margin: '0',
    fontFamily: typography.fonts.secondary,
    fontSize: '12px',
    color: colors.secondary.color,
    width: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editIcon: {
    color: colors.primary.color,
    fontSize: '20px',
    cursor: 'pointer',
  },
  blockIcon: {
    color: '#E15405',
    fontSize: '20px',
    cursor: 'pointer',
  },
  arrowIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
  },
  arrowIconActive: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
    left: '5px',
    color: colors.primary.color,
  },

  link: {
    color: '#007AFF',
    textDecoration: 'none',
    fontWeight: 600,
  },
  img: {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  boxType: {
    padding: '20px',
    margin: '50px 20px 20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerWrap: {
    display: 'flex',
    padding: '20px 20px',
    alignItems: 'center',
    '@media all and (max-width: 650px)': {
      padding: '20px 0',
    },
  },
  h3: {
    ...typography.h3,
    color: colors.secondary.color,
    textAlign: 'left',
  },
  search: {
    flex: 5,
    display: 'flex',
    width: '100%',
  },
  btn: {
    display: 'flex',
    marginTop: '6px',
    marginLeft: '10px',
  },
  button: {
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },

  eventName: {
    width: '200px',
    wordBreak: 'break-word',
  },
}));
