import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  boxType: {
    padding: '20px',
    margin: '20px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
    borderRadius: '18px',
    background: '#FFFFFF',
    maxWidth: '100%',
    height: '80%',
    '@media all and (max-width: 650px)': {
      margin: '0',
    },
  },
  container: {
    width: '100%',
    height: '90%',
  },
  headerwrap: {
    width: '100%',
    height: '50px',
  },
  chooseWrap: {
    width: '100px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    '&:hover': {
      background: '#ececec',
      borderRadius: '10px',
    },
  },
  choose: {
    width: '100px',
  },
  plusWrap: {
    width: '20px',
    height: '20px',
  },
  imgPlus: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    opacity: '0',
    fontSize: '0',
    position: 'absolute',
    top: '0px',
    left: '0px',
  },
  fileWrapper: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#ececec',
    borderRadius: '10px',
  },
}));
