import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/Store';
import SortType from 'src/Types/SortType';
import useDebounce from './useDebounce';

type TableType = {
  tableName: string;
  sortableFields?: Array<string>;
  isNest?: Boolean;
};
const useTable = ({ tableName, sortableFields, isNest = false }: TableType) => {
  const [page, setPage] = useState<number>(0);
  const initialSort = useSelector((state: RootState) => state.table[tableName]);
  const [sort, setSort] = useState<SortType>(initialSort || {});
  const initialRows = useSelector((state: RootState) => state.rowsPerPage[tableName]);
  const [rowsPerPage, setRowsPerPage] = useState(initialRows || 10);
  const [search, setSearch] = useState('');
  const [participant, setParticipant] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const sortableField: Array<string> = sortableFields || [];

  const addToSortableField = (fieldToSort) => {
    if (!sortableField.find((field: any) => field === fieldToSort)) {
      sortableField.push(fieldToSort);
    }
  };

  const dispatch = useDispatch();

  const changeSorts = (field) => {
    setLoading(true);
    if (sortableField.find((item) => item === field)) {
      let sortOrder: 'asc' | 'desc' = 'asc';
      if (field === sort.sortBy) {
        sortOrder = sort.sort === 'asc' ? 'desc' : 'asc';
      }
      setSort({
        sortBy: field,
        sort: sortOrder,
      });

      dispatch({
        type: 'TABLE_SORT',
        payload: {
          table: tableName,
          sort: {
            sort: sortOrder,
            sortBy: field,
          },
        },
      });
    }
  };

  const changeSortNest = (field) => {
    setLoading(true);
    if (sortableField.find((item) => item === field)) {
      let sortOrder: 'ASC' | 'DESC' = 'ASC';
      if (field === sort.sortBy) {
        sortOrder = sort.sort === 'ASC' ? 'DESC' : 'ASC';
      }
      setSort({
        sortBy: field,
        sort: sortOrder,
      });

      dispatch({
        type: 'TABLE_SORT',
        payload: {
          table: tableName,
          sort: {
            sort: sortOrder,
            sortBy: field,
          },
        },
      });
    }
  };

  const changeSort = (field) => {
    if (isNest) {
      changeSortNest(field);
    } else changeSorts(field);
  };

  const handleChangePage = (event, newPage: number) => {
    setLoading(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    dispatch({
      type: 'TABLE_ROWS',
      payload: {
        table: tableName,
        rows: +event.target.value,
      },
    });
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const debouncedSearch = useDebounce<string>(search, 500);

  const selectParticipants = (e, item) => {
    const { checked } = e.target;
    if (checked === true) {
      setParticipant([...participant, item?._id]);
    } else if (checked === false) {
      setParticipant(participant.filter((el) => el !== item._id));
    }
  };
  return {
    changeSort,
    addToSortableField,
    handleChangePage,
    handleChangeRowsPerPage,
    debouncedSearch,
    setSearch,
    setPage,
    sort,
    page,
    rowsPerPage,
    selectParticipants,
    participant,
    loading,
    setLoading,
  };
};

export default useTable;
