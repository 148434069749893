import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const FilesToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 'auto',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    placement: 'top',
  },
}))(Tooltip);
