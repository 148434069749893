/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { EventType } from 'src/Types/EventType';
import { RallyType } from 'src/Types/RallyType';
import useConfirmationDialog from 'src/Hooks/useConfirmationDialog';
import { getRally, updateRally } from '../../../Services/Auth/Actions/RallyActions';
import { getEvent } from '../../../Services/Auth/Actions/EventActions';
import useStyles from './RallyEditPopupStyle';
import validateRally, { prepareFormData, preProcessData } from './RallyUtils';
import RallyValidations from './RallyValidations';
import RallyAddEditForm from './RallyAddEditForm';

type RallyEditPopupType = {
  event: EventType;
  editingItemId: Function;
  setEditingItemId: Function;
  reload: Function;
  setOpenPopup: Function;
};

function RallyEditPopup({
  event,
  editingItemId,
  setEditingItemId,
  reload,
  setOpenPopup,
}: RallyEditPopupType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [image, setImage] = useState('');
  const [details, setDetails] = useState<RallyType>();
  const [eventDetail, setEventDetail] = useState<EventType>();
  const [files, setFiles] = useState([]);
  const confirmationDialog = useConfirmationDialog();

  const fetchdata = () => {
    getRally(editingItemId)
      .then((response) => {
        const result = response.data.Rally;
        if (result.attachment) {
          setFiles(result.attachment);
        }
        if (Array.isArray(result)) {
          result.forEach((el) => {
            if (!el.attachment) {
              el.attachment = [];
            }
          });
        }

        setDetails(result);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (editingItemId) {
      fetchdata();
      getEvent(event._id).then((resp) => {
        setEventDetail(resp.data.event);
      });
    }
  }, [editingItemId]);

  const OnClose = () => {
    setEditingItemId('');
    setOpenPopup(false);
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const formValues: any = {
      ...values,
    };
    preProcessData(formValues);
    if (eventDetail && !validateRally(eventDetail, formValues, t)) {
      setSubmitting(false);
      return;
    }

    const formData = prepareFormData(formValues, image, files);
    if (details) {
      const description = t('popup.Are you sure you want to update Rally details?');

      const handleUpdateRally = async () => {
        try {
          await updateRally(details._id, formData);
        } catch (error) {
          console.log('Rally details update API call failed:', error);
        } finally {
          setSubmitting(false);
          reload();
          OnClose();
        }
      };

      const confirmEditRallyDetails = () => {
        confirmationDialog(description, handleUpdateRally, setSubmitting(false));
      };

      confirmEditRallyDetails();
    }
  };

  const initialValues = {
    rallyName: details?.rallyName || '',
    rallyType: details?.rallyType || '',
    startDateTime: details?.startDateTime
      ? moment(details.startDateTime).toDate()
      : new Date(),
    endDateTime: details?.endDateTime ? moment(details.endDateTime).toDate() : new Date(),
    memo: details?.memo || '',
    eventId: details?.eventId || '',
  };

  return (
    <div className={classes.paper}>
      <Icon icon="carbon:close-outline" onClick={OnClose} className={classes.icon} />
      <div id="transition-modal-description" className={classes.paperinner}>
        <RallyAddEditForm
          title="forms.Edit Rally"
          initialValues={initialValues}
          RallyValidations={RallyValidations}
          onSubmit={onSubmit}
          setImage={setImage}
          details={details}
          eventDetail={eventDetail}
          files={files}
          setFiles={setFiles}
          rallyTypeId={event?.eventOrganizer || event.organizer?.userId?._id}
        />
      </div>
    </div>
  );
}

export default RallyEditPopup;
