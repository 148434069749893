import { Grid } from '@material-ui/core';
import ArrowForWardSharp from '@material-ui/icons/ArrowForwardSharp';
import clsx from 'clsx';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { renderFields } from 'src/Libs/DynamicForm/config/renderDynamicFormFields';
import useStyles from '../../../newEventFormStyle';

function DrawFormFields({
  data,
  addForm,
  addSelected,
  removed,
  selected,
}: {
  data: FormFieldEditType;
  addForm: Function;
  addSelected: Function;
  removed: FormFieldEditType[];
  selected: FormFieldEditType[];
}) {
  const classes = useStyles();
  return (
    <div className={classes.inputFieldAlignmentPerson}>
      <Grid
        item
        lg={11}
        key={data.index}
        xs={11}
        className={clsx([
          `grid-item-${data.type}-custom-class`,
          removed.includes(data)
            ? `${classes.selected} ${classes.inputlabel}`
            : classes.inputlabel,
        ])}
        onClick={() => addForm(data.index)}
      >
        {renderFields(data, 'left')}
        {selected.includes(data) && (
          <div onClick={() => addSelected()} className={classes.editIcon}>
            <ArrowForWardSharp />
          </div>
        )}
      </Grid>
    </div>
  );
}

export default DrawFormFields;
