import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormStrokedButton } from 'src/Components/Utils';
import FieldLabel from 'src/Libs/DynamicForm/components/FormField/Fields/FieldLabel';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { Icon } from '@iconify/react';
import useStyles from '../../style';
import { SaveTableReportProps } from '../../types/docType';

function TableReportsComponent({
  setOpenPopup,
  id,
  updateReportSubmit,
  onReportSubmit,
  dropDownValues,
  destinationColumnData,
  sourceColumnData,
  tablereportName,
}) {
  const [reportName, setReportName] = useState(tablereportName);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleChange = (e) => {
    setReportName(e.target.value);
  };
  const handleSubmit = async () => {
    if (reportName.trim() === '') {
      ErrorToast(t('others.Report Name field is required'));
      return;
    }
    if (id) {
      updateReportSubmit(reportName, destinationColumnData, sourceColumnData, id);
    } else {
      onReportSubmit(reportName, destinationColumnData, sourceColumnData, dropDownValues);
    }
    handleClosePopup();
  };

  const handleCloseClick = () => {
    handleClosePopup();
  };

  return (
    <div className={classes.paperPopup}>
      <Icon
        icon="carbon:close-outline"
        onClick={() => {
          handleCloseClick();
        }}
        className={classes.icons}
      />
      <div>
        <FieldLabel name={t('others.Report Name')} require />
        <input
          className={classes.inputname}
          type="text"
          onChange={(event) => {
            handleChange(event);
          }}
          value={reportName}
        />
      </div>
      <div className={classes.reportSubmit}>
        <FormStrokedButton
          className=""
          name={t('forms.Submit')}
          onClick={(e) => {
            handleSubmit();
          }}
        />
      </div>
    </div>
  );
}

function TableReportWrapper({
  onReportSubmit,
  destinationColumnData,
  sourceColumnData,
  id,
  tablereportName,
  updateReportSubmit,
  reload,
  dropDownValues,
}: SaveTableReportProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const modal = useModalDialog();
  const saveModalSetOpen = useMemo(() => modal(TableReportsComponent, 'reports'), []);

  const SaveandupdateReport = async (e) => {
    e.preventDefault();
    if (id) {
      await updateReportSubmit(
        tablereportName,
        destinationColumnData,
        sourceColumnData,
        id,
      );
    } else {
      saveModalSetOpen(true, {
        id,
        updateReportSubmit,
        onReportSubmit,
        dropDownValues,
        destinationColumnData,
        sourceColumnData,
        tablereportName,
        overrideBody: true,
      });
    }
  };

  return (
    <FormStrokedButton
      className={destinationColumnData.length > 0 ? classes.button : classes.disabledBtn}
      name={id ? t('others.Save Changes') : t('others.Save as Table report')}
      onClick={(e) => {
        SaveandupdateReport(e);
      }}
    />
  );
}

export default TableReportWrapper;
