import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import theme from 'src/Theme';
import typography from 'src/Theme/typography';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#fff',
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: 'fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
  },
}));

function SimpleButton({ onClick, label, className }) {
  const classes = useStyles();
  return (
    <button type="button" onClick={onClick} className={clsx(classes.button, className)}>
      {label}
    </button>
  );
}

export default SimpleButton;
