/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import DynamicFormContext from 'src/Libs/DynamicForm/context/DynamicFormContext';
import ImageLoader from 'src/Components/Utils/ImageLoader';
import { getRally } from 'src/Services/Auth/Actions/RallyActions';
import { GetRallyTypes } from 'src/Services/Auth/Actions/RallyTypeActions';
import { FormDataType, FormFieldsType } from 'src/Types/FormFieldsType';
import {
  createNewEventForm,
  getEvent,
  imageUpload,
} from '../../../Services/Auth/Actions/EventActions';

// style for the page
import useStyles from './editNewEventFormStyle';

// Form based components;
import { showEmptyFormToast } from './components/DynamicForm/util';
import FormCreateEdit from './FormCreateEdit';
import EventFormPreview from './EventFormPreview';
import { Preview } from '../EventParticipants/FormPreview';
import { loadAutoPickValues } from './AutoPicKFormFieldValues';
import { NewCoordinateType } from './utils';

/**
 * @function NewEventForms
 * @description Create a new dynamic form
 * @param Object
 * @author Sam Manual Varghese
 */
function NewEventForms() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [formName, setFormName] = useState<String>('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation<{ formName: String; rallyID: Array<String> }>();
  const form = location.state.formName;
  const rallyId = location.state.rallyID;
  const {
    eventId,
  }: {
    eventId: string;
  } = useParams();
  const modal = useModalDialog();
  const previewFormSetOpen = useMemo(() => modal(EventFormPreview, 'preview'), []);
  const setOpenParticipantFormPreview = useMemo(
    () => modal(Preview, 'participant-preview'),
    [],
  );
  const imagesUploadingSetOpen = useMemo(
    () => modal(ImageLoader, 'Images_are_uploading'),
    [],
  );

  // eslint-disable-next-line no-shadow
  const showFormPreview = (eventId: string, formId: string) => {
    previewFormSetOpen(true, {
      eventId,
      rallyIds: rallyId,
      formId,
      overrideBody: true,
    });
  };

  const {
    leftVisibleData,
    leftVisibleTabData,
    selected,
    added,
    coordinates,
    removed,
    fullData,
    addSelected,
    setLeftVisibleTabData,
    addForm,
    setCoordinates,
    setAdded,
    addRemove,
    setFormType,
    deleteCurrentItem,
    selectAllRight,
    editFormField,
    generateLayout,
    setLeftVisibleData,
    tableData,
    headerData,
    setHeaderData,
    setAutoPickValues,
    setRemoved,
  } = useContext(DynamicFormContext);

  const getInitialHeaderFields = async (newFormData: any[]) => {
    // Get Event Details
    const response = await getEvent(eventId);
    const eventData = response.data.event;
    if (rallyId?.length === 1) {
      const rallyResponse = await getRally(rallyId);
      const rallyData = rallyResponse.data.Rally;
      const rallyTypesResponse = await GetRallyTypes({ sortBy: 'type', sort: 'asc' });
      const rallyTypesData = rallyTypesResponse.data.rallyType.rallyTypes;
      setAutoPickValues(loadAutoPickValues(form, eventData, rallyData, rallyTypesData));
    } else {
      setAutoPickValues(loadAutoPickValues(form, eventData));
    }
    const { eventName, enquiryEmail, enquiryPhone, eventImage } = eventData;
    const newCoordinates = [...coordinates];
    let formHeaderFields = newFormData.filter(
      (el: { category: string }) => el.category === 'FormHeader',
    );
    formHeaderFields.map(
      (ele: { key: string; index: any; imgUrl: any; isImage: boolean }) => {
        if (ele.key === 'form_name') {
          formHeaderFields = formHeaderFields.map((elem: { index: any }) => {
            if (elem.index === ele.index) {
              return {
                ...elem,
                label: form || 'Form',
              };
            }
            return elem;
          });
          newCoordinates.push({
            x: 5,
            y: 0,
            w: 5,
            h: 2,
            minW: 2,
            minH: 2,
            i: ele.index,
          });
        } else if (ele.key === 'enquiry_phone') {
          formHeaderFields = formHeaderFields.map((elem: { index: any }) => {
            if (elem.index === ele.index) {
              return {
                ...elem,
                label: enquiryPhone || 'Enquiry Phone',
              };
            }
            return elem;
          });
          newCoordinates.push({
            x: 18,
            y: 2,
            w: 6,
            h: 2,
            minW: 2,
            minH: 2,
            i: ele.index,
          });
        } else if (ele.key === 'event_name') {
          formHeaderFields = formHeaderFields.map((elem: { index: any }) => {
            if (elem.index === ele.index) {
              return {
                ...elem,
                label: eventName,
              };
            }
            return elem;
          });
          newCoordinates.push({
            x: 5,
            y: 2,
            w: 5,
            h: 2,
            minW: 2,
            minH: 2,
            i: ele.index,
          });
        } else if (ele.key === 'event_image') {
          ele.imgUrl =
            eventImage ||
            'https://compreg-live.s3.amazonaws.com/7a309c03-8ada-4138-b68c-9a723fd562d9-compregLogo.png';
          ele.isImage = true;
          formHeaderFields = formHeaderFields.map((elem: { index: any }) => {
            if (elem.index === ele.index) {
              return {
                ...elem,
                // imgUrl: eventImageOrg,
              };
            }
            return elem;
          });
          newCoordinates.push({
            x: 0,
            y: 0,
            w: 5,
            h: 4,
            minW: 2,
            minH: 2,
            i: ele.index,
          });
        } else if (ele.key === 'enquiry_email') {
          // ele.label = formHeaderDetails.enquiryEmail;
          formHeaderFields = formHeaderFields.map((elem: { index: any }) => {
            if (elem.index === ele.index) {
              return {
                ...elem,
                label: enquiryEmail || 'Enquiry Email',
              };
            }
            return elem;
          });
          newCoordinates.push({
            x: 18,
            y: 0,
            w: 6,
            h: 2,
            minW: 2,
            minH: 2,
            i: ele.index,
          });
        } else if (ele.key === 'horizontal_line') {
          newCoordinates.push({
            x: 0,
            y: 4,
            w: 24,
            h: 2,
            minW: 2,
            minH: 2,
            i: ele.index,
          });
        }
        return ele;
      },
    );
    return { formHeaderFields, newCoordinates };
  };
  useEffect(() => {
    if (fullData.length === 0) {
      return;
    }
    (async () => {
      const newFormData = fullData.map((val: { index: string }, index: any) => {
        val.index = uuidv4();
        return val;
      });
      const { formHeaderFields, newCoordinates } = await getInitialHeaderFields(
        newFormData,
      );
      setCoordinates([...newCoordinates]);
      setAdded([...formHeaderFields]);
      setFormName(form);
      setLoading(false);
      await setLeftVisibleData(newFormData);
      await setLeftVisibleTabData(
        newFormData.filter((val: { category: string }) => val.category === 'Pilot'),
      );
    })();
  }, [fullData]);

  const formPreviewParticipant = () => {
    if (added.length === 0) {
      setIsLoading(false);
      showEmptyFormToast(t("forms.You can't save an empty form"));
      return;
    }
    const formFields: FormFieldsType[] = added
      .filter((field: { type: string }) => field.type !== 'pin')
      .map((item: FormFieldsType, i: number) => ({
        ...item,
        _id: item.id || item.index,
        coordinates: coordinates.find(
          (coordinate: NewCoordinateType) => coordinate.i === item.index,
        ),
        order: i + 1,
        isImage: item.isImage || false,
        imgUrl: item.imageBinary
          ? URL.createObjectURL(item.imageBinary)
          : item.imgUrl || '',
        imageName: item.imageName || '',
        imageType: item.imageType || '',
        defaultDropDownValue: item.defaultDropDownValue || '',
        values: item.values || '',
        totalCount: item.totalCount || '',
        tableRows: item?.tableRows || tableData.rows,
        tableCols: item?.tableCols || tableData.cols,
      }));

    const formData: FormDataType = {
      formName,
      form: formFields,
      eventId,
      rallyId: rallyId || null,
    };

    setOpenParticipantFormPreview(true, {
      item: formData,
      user: '',
      listForms: '',
      eventDetails: '',
      overrideBody: true,
    });
  };

  const savePreview = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (added.length === 0) {
      setIsLoading(false);
      showEmptyFormToast(t("forms.You can't save an empty form"));
      return;
    }

    let imageBinarys = added
      .filter((field) => field.imageBinary)
      .map((item) => {
        if (item.imageBinary) {
          return item.imageBinary;
        }
      });

    imageBinarys = _.without(imageBinarys, undefined);

    const keys = imageBinarys.map(async (item) => {
      const formData = new FormData();
      formData.append('file', item);
      imagesUploadingSetOpen(true, { overrideBody: true });
      return (await imageUpload(formData)).data.key;
    });

    Promise.all(keys).then((imageUrls) => {
      imagesUploadingSetOpen(false, { overrideBody: true });
      setIsLoading(true);
      const formFields: FormFieldsType[] = added
        .filter((item: { type: string }) => item.type !== 'pin')
        .map(
          (
            itemInArray: {
              id: any;
              label: any;
              key: any;
              hint: any;
              borderLeft: any;
              borderTop: any;
              borderRight: any;
              borderBottom: any;
              index: any;
              imageBinary: any;
              imgUrl: any;
              isImage: any;
              imageName: any;
              imageType: any;
              defaultDropDownValue: any;
              values: any;
              totalCount: any;
              tableRows: any;
              tableCols: any;
              parentWidgetIndex: any;
              coordinate: any;
              styles: {
                color: any;
                fontSize: any;
                fontWeight: any;
                textAlign: any;
                backgroundColor: any;
                borderTopStyle: any;
                borderLeftStyle: any;
                borderRightStyle: any;
                borderBottomStyle: any;
                borderColor: any;
                borderRadius: any;
                objectFit: any;
                gridTemplateColumns: any;
              };
            },
            i: number,
          ) => {
            const coordinate = itemInArray.coordinate && {
              i: itemInArray.coordinate.i,
              x: itemInArray.coordinate.x,
              y: itemInArray.coordinate.y,
              w: itemInArray.coordinate.w,
              h: itemInArray.coordinate.h,
            };
            const parentWidgetIndex =
              itemInArray.parentWidgetIndex && itemInArray.parentWidgetIndex;

            return {
              id: itemInArray.id,
              label: itemInArray.label,
              key: itemInArray.key,
              hint: itemInArray.hint,
              order: i + 1,
              borderLeft: itemInArray.borderLeft,
              borderTop: itemInArray.borderTop,
              borderRight: itemInArray.borderRight,
              borderBottom: itemInArray.borderBottom,
              index: itemInArray.index,
              imgUrl: itemInArray.imageBinary
                ? imageUrls.shift()
                : itemInArray.imgUrl || '',
              isImage: itemInArray.isImage || false,
              imageName: itemInArray.imageName || '',
              imageType: itemInArray.imageType || '',
              defaultDropDownValue: itemInArray.defaultDropDownValue || '',
              values: itemInArray.values || '',
              totalCount: itemInArray.totalCount || '',
              tableRows: itemInArray.tableRows || tableData.rows,
              tableCols: itemInArray.tableCols || tableData.cols,
              parentWidgetIndex,
              coordinate,
              styles: {
                color: itemInArray.styles?.color,
                fontSize: itemInArray.styles?.fontSize,
                fontWeight: itemInArray.styles?.fontWeight,
                textAlign: itemInArray.styles?.textAlign,
                backgroundColor: itemInArray.styles?.backgroundColor,
                borderTopStyle: itemInArray.styles?.borderTopStyle,
                borderLeftStyle: itemInArray.styles?.borderLeftStyle,
                borderRightStyle: itemInArray.styles?.borderRightStyle,
                borderBottomStyle: itemInArray.styles?.borderBottomStyle,
                borderColor: itemInArray.styles?.borderColor,
                borderRadius: itemInArray.styles?.borderRadius,
                objectFit: itemInArray.styles?.objectFit,
                gridTemplateColumns: itemInArray.styles?.gridTemplateColumns,
              },
            };
          },
        );

      const filteredCoordinates: NewCoordinateType[] = coordinates.filter(
        (coords: { i: string }) => formFields.some((field) => coords.i === field.index),
      );

      const data: any = {
        formName,
        formFields,
        eventId,
        coordinates: filteredCoordinates,
        rallyId: rallyId || null,
        headerData,
      };
      createNewEventForm(data)
        .then((response) => {
          toast.success(t('forms.New form added successfully'), {
            position: 'top-center',
            autoClose: 2000,
          });
          setIsLoading(false);
          showFormPreview(eventId, response.data._id);
          history.push(`/events/details/${eventId}/forms`);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error, {
            position: 'top-center',
            autoClose: 5000,
          });
        });
    });
  };

  return (
    <FormCreateEdit
      setFormType={setFormType}
      setLeftVisibleTabData={setLeftVisibleTabData}
      leftVisibleData={leftVisibleData}
      loading={loading}
      selectAllRight={selectAllRight}
      selected={selected}
      leftVisibleTabData={leftVisibleTabData}
      removed={removed}
      addForm={addForm}
      addSelected={addSelected}
      setCoordinates={setCoordinates}
      added={added}
      formHeaderDetails={undefined}
      eventDetails={undefined}
      generateLayout={generateLayout}
      editFormField={editFormField}
      deleteCurrentItem={deleteCurrentItem}
      addRemove={addRemove}
      isLoading={isLoading}
      preview={savePreview}
      pathname={`/events/details/${eventId}/forms`}
      formName={formName}
      setFormName={setFormName}
      formPreviewParticipant={formPreviewParticipant}
      coordinates={coordinates}
      headerData={headerData}
      setHeaderData={setHeaderData}
      setRemoved={setRemoved}
    />
  );
}

export default NewEventForms;
