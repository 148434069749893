import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../style';
import { borderTypes, MenuProps } from '../../util';
import { MultySelectorDropDownType } from '../types/textEditorTypes';

export const MultySelectorDropDown = ({
  handleChangeBorder,
  borderNames,
}: MultySelectorDropDownType) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="demo-mutiple-checkbox-label"
          classes={{
            formControl: classes.inputLabelFormControl,
            shrink: classes.inputLabelShrink,
          }}
        >
          Border
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={borderNames}
          onChange={handleChangeBorder}
          input={<Input />}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={MenuProps}
        >
          {borderTypes.map((name) => (
            <MenuItem
              key={name}
              value={name}
              className={classes.menuItem}
              style={{ backgroundColor: 'transparent' }}
            >
              <Checkbox checked={borderNames.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
