// defaults to localStorage for web

const initialState = {
  token: '',
};

// Use the initialState as a default value
export default function sessionReducer(state = initialState, action?) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions

    case 'SAVE_TOKEN': {
      return {
        ...state,
        token: action.payload,
      };
    }
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
}
