import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import EnumUserRoles from 'src/Types/Enums/EnumUserRoles';
import React from 'react';
import useStyles from './UsersListingStyle';

type Itemtype = {
  _id: string;
  role: EnumUserRoles;
  organizationId?: string;
};

function AllUsersListActions({ item }: { item: Itemtype }) {
  const classes = useStyles();

  if (!item._id || !item.role) {
    return null; // or render error or a placeholder
  }

  let link;
  switch (item.role) {
    case EnumUserRoles.participant:
      link = `users/details/${item._id}`;
      break;
    case EnumUserRoles.organizer:
      link = `organizers/details/${item.organizationId}`;
      break;
    case EnumUserRoles.eventOrganizer:
      link = `eventorganizers/details/${item.organizationId}/${item._id}`;
      break;
    default:
      return null; // or render an error
  }

  return (
    <>
      <Link to={`${link}/profile`}>
        <Icon icon="feather:edit-3" className={classes.editIcon} />
      </Link>
      <Link to={`${link}/events`}>
        <Icon icon="ic:outline-event-note" className={classes.editIcon} />
      </Link>
    </>
  );
}

export default AllUsersListActions;
