/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import useStyles from '../style';

type Props = {
  handleChangeSlider: (event: any, newRadius: number | number[]) => void;
  value: number;
  max?: number;
  controllerName: string;
};

function SliderController({
  handleChangeSlider,
  value,
  max = 100,
  controllerName,
}: Props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.colorPickerButton}
        onClick={handleClick}
      >
        {`${controllerName}: ${value}`}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        style={{ zIndex: '1400' }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className={classes.popperBody}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Slider
                    value={value}
                    onChange={handleChangeSlider}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={max}
                  />
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
export default SliderController;
