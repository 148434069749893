import React from 'react'
import { useTranslation } from 'react-i18next';
import Header from 'src/Components/Header/Header'
import AllUsersListing from 'src/Containers/Users/UsersListing/AllUsersListing';
import Dashboard from 'src/Layouts/Dashboard/Dashboard'

function AllUsers() {
    const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t(`users.All Users`)} />
        <AllUsersListing />
      </Dashboard>
    </div>
  )
}

export default AllUsers