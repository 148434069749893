import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import typography from 'src/Theme/typography';

const useStyles = makeStyles((theme) => ({
  button: {
    // backgroundColor: 'transparent',
    // border: '2px solid #2148C0',
    // padding: '8px 25px',
    // maxWidth: '100%',
    // color: '#2148C0',
    // fontStyle: 'normal',
    // fontWeight: 600,
    // fontSize: '12px',
    // boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    // borderRadius: '4px',
    // cursor: 'pointer',

    // "&:hover": {
    //     backgroundColor: '#2148C0',
    //     color: '#fff',
    // }
    backgroundColor: theme.palette.primary.main,
    border: '1px solid #A7ACB1',
    padding: '8px 25px',
    maxWidth: '100%',
    color: '#fff',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: typography.fonts.secondary,
    boxShadow: '0px 4px 4px rgb(0 0 0 / 30%)',
    borderRadius: '6px',
    cursor: 'pointer',
    margin: '0',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

function FormStrokedButton({ className, onClick, name }) {
  const classes = useStyles();
  return (
    <button type="submit" className={clsx(classes.button, className)} onClick={onClick}>
      {name}
    </button>
  );
}

export default FormStrokedButton;
