import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const columns: Array<{
  id: string;
  label: string;
  minWidth: number;
  align?: 'right';
  format?: any;
}> = [
  { id: 'event', label: 'Event', minWidth: 170 },
  { id: 'startDate', label: 'Start Date', minWidth: 50 },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 50,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'eventPlace',
    label: 'Event Place',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'organizer',
    label: 'Organiser',
    minWidth: 100,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'manageEvents',
    label: 'Manage Events',
    minWidth: 100,
    align: 'right',
    // format: (value) => value.toFixed(2),
    format: (value) => value.toLocaleString('en-US'),
  },
];

function createData(event, startDate, endDate, eventPlace, organizer, manageEvents) {
  return { event, startDate, endDate, eventPlace, organizer, manageEvents };
}

const rows = [
  createData(
    'Raid De Himalaya',
    '24.08.2021',
    '24.08.2021',
    'Jammu, India',
    'Tom Cruise',
    'Tom Cruise',
  ),
  createData(
    'Monte Carlo',
    '24.08.2021',
    '24.08.2021',
    'Jammu, India',
    'Tom Cruise',
    'Tom Cruise',
  ),
  createData(
    'Tour de corse',
    '24.08.2021',
    '24.08.2021',
    'Jammu, India',
    'Tom Cruise',
    'Tom Cruise',
  ),
  createData(
    'Tulip Rally',
    '24.08.2021',
    '24.08.2021',
    'Jammu, India',
    'Tom Cruise',
    'Tom Cruise',
  ),
  createData(
    'Canada',
    '24.08.2021',
    '24.08.2021',
    'Jammu, India',
    'Tom Cruise',
    'Tom Cruise',
  ),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function SimpleTable() {
  const classes = useStyles();
  const [page] = React.useState<number>(0);
  const [rowsPerPage] = React.useState<number>(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={() => {}}
      />
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
