import React, { useState } from 'react';
import PLUS from 'src/Components/AvatarUpload/add_circle_red.svg';
import { toast } from 'react-toastify';
import { eventRestore } from 'src/Services/Auth/Actions/EventActions';
import { t } from 'i18next';
import { FormButton, SubTitle } from 'src/Components/Utils';
import WarningToast from 'src/Components/Utils/WarningToast';
import ErrorToast from 'src/Components/Utils/ErrorToast';
import { FileType } from 'src/Types/FileType';

import EnumRestoreType from 'src/Types/Enums/EnumRestoreType';
import { OrganiserRestore } from 'src/Services/Auth/Actions/OrganizerActions';
import useStyles from './RestoreViewStyle';

function RestoreView({ restoreType }) {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState<Array<FileType>>([]);

  const restoreEvent = async (item) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('file', item);
    try {
      let data;

      switch (restoreType) {
        case EnumRestoreType.Event:
          data = await eventRestore(formData);
          break;
        case EnumRestoreType.Organiser:
          data = await OrganiserRestore(formData);
          break;
        default:
          ErrorToast(t('toast.restore failed'));
          break;
      }

      if (data) {
        WarningToast(t('popup.It will take time, continue in background'));
      }
      setIsSubmitting(false);
      setFiles([]);
    } catch (err) {
      ErrorToast(t('toast.restore failed'));
      setIsSubmitting(false);
      setFiles([]);
    }
  };

  const onChange = (e) => {
    const file = e.target.files[0];
    if (file.type !== 'application/x-zip-compressed') {
      toast.warning('choose only Zip file');
      return;
    }
    setFiles([file]);
  };

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headerwrap}>
          {SubTitle({ title: t(`restore.Restore ${restoreType}`) })}
        </div>
        {files?.length > 0 ? (
          <div className={classes.fileWrapper}>
            <div>{files[0].name}</div>
            <FormButton
              name={t(`restore.Restore ${restoreType}`)}
              isLoading={isSubmitting}
              onClick={() => restoreEvent(files[0])}
            />
          </div>
        ) : (
          <div className={classes.chooseWrap}>
            <div className={classes.choose}>{t(`others.Choose File`)}</div>
            <img src={PLUS} className={classes.imgPlus} alt="plus" />
            <input
              type="file"
              id="img"
              onChange={onChange}
              accept="application/*"
              className={classes.input}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default RestoreView;
