import { Icon } from '@iconify/react';
import React from 'react';
import { CustomToolTip } from 'src/Components/ToolTip/ToolTip';
import handleLongWords from 'src/Components/Utils/HandelLongWords';
import useStyles from './styles';

function AdminOrganiserCounts({
  details,
  icon,
  venue,
}: {
  details: string;
  icon: string;
  venue?: string;
}) {
  const classes = useStyles();
  return (
    <div className={classes.wrap}>
      <h4 className={classes.count}>
        <Icon icon={icon} className={classes.editIcon} />
      </h4>
      <CustomToolTip title={venue ? <div>{venue}</div> : ''}>
        <h6 className={classes.text}>{handleLongWords(details)}</h6>
      </CustomToolTip>
    </div>
  );
}

export default AdminOrganiserCounts;
