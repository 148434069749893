/* eslint-disable react/jsx-props-no-spreading */
import { Icon } from '@iconify/react';
import React from 'react';

export type ActionIconsType = {
  key?: string;
  onClick: () => void;
  icon: string;
  className: string;
  style?: React.CSSProperties;
};

const ActionIcons: React.FC<ActionIconsType> = ({
  icon,
  key,
  onClick,
  className,
  style,
}) => (
  <Icon
    key={key}
    onClick={() => onClick()}
    icon={icon}
    className={className}
    style={style || {}}
  />
);

export default ActionIcons;
