import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import Dashboard from '../../Layouts/Dashboard/Dashboard';
import FieldGroup from './FieldGroup';

function FormFieldGroup() {
  const { t } = useTranslation();
  return (
    <div>
      <Dashboard>
        <Header title={t('formFields.Form Field Group')} />
        <FieldGroup />
      </Dashboard>
    </div>
  );
}

export default FormFieldGroup;
