import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/Store';
import useModalDialog from 'src/Libs/ModalPopup/UseModalPopup';
import {
  changeUserPassword,
  logoutUser,
  loggedUser,
} from '../../Services/Auth/Actions/UserActions';
import { FormLabel, FilledButton, ErrorMessageCustom } from '../Utils';
import useStyles from './ChangePasswordStyle';
import { validator } from '../../Services/Auth/Validations';
import theme from '../../Theme';
import PasswordField from '../PasswordField/PasswordField';

function ChangePasswordWrapper() {
  const { t } = useTranslation();
  function ChangePasswordComponent() {
    const classes = useStyles();
    const user = useSelector((store: RootState) => store.role.user);
    const userRole = useSelector((store: RootState) => store.role.user.role);
    const ChangePasswordSchema = Yup.object().shape({
      oldPassword: Yup.string().trim().required('validationErrors.Password is Required'),
      newPassword: validator.PASSWORD,
      confirmPassword: validator.CHANGECONFIRMPASSWORD,
    });

    return (
      <div>
        <div className={classes.wrapper}>
          <h2 id="transition-modal-title" className={classes.title}>
            {t('signUp.Change Password')}
          </h2>
        </div>
        <div id="transition-modal-description">
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={ChangePasswordSchema}
            // validateOnChange={false}
            onSubmit={(values, { setSubmitting }) => {
              // same shape as initial values
              setSubmitting(true);
              const data: any = {
                ...values,
              };
              delete data.confirmPassword;
              changeUserPassword(data)
                .then((response) => {
                  toast.success(response.data.message, {
                    position: 'top-center',
                    autoClose: 2000,
                  });
                  setSubmitting(false);
                  logoutUser(user._id).then(() => {
                    loggedUser(userRole);
                  });
                })
                .catch((error) => {
                  toast.error(error.response.data.message, {
                    position: 'top-center',
                    autoClose: 5000,
                  });
                  setSubmitting(false);
                });
            }}
          >
            {({ isSubmitting, values, errors, touched }) => (
              <Form>
                <FormLabel name={t('signUp.Old Password')} require />
                <Field
                  className={classes.input}
                  text="oldPassword"
                  component={PasswordField}
                  name="oldPassword"
                  value={values.oldPassword}
                />
                <ErrorMessageCustom
                  errors={errors}
                  field="oldPassword"
                  touched={touched}
                />
                <FormLabel name={t('signUp.New Password')} require />
                <Field
                  className={classes.input}
                  text="newPassword"
                  component={PasswordField}
                  name="newPassword"
                  value={values.newPassword}
                />
                <ErrorMessageCustom
                  errors={errors}
                  field="newPassword"
                  touched={touched}
                />
                <FormLabel name={t('signUp.New Password Confirmation')} require />
                <Field
                  className={classes.input}
                  text="password"
                  component={PasswordField}
                  name="confirmPassword"
                  value={values.confirmPassword}
                />
                <ErrorMessageCustom
                  errors={errors}
                  field="confirmPassword"
                  touched={touched}
                />
                <div className={classes.btnWrap}>
                  {!isSubmitting && (
                    <FilledButton
                      className={classes.FilledButton}
                      name={t('forms.Submit')}
                      type="submit"
                      disabled={isSubmitting}
                    />
                  )}
                  {isSubmitting && (
                    <Loader
                      type="ThreeDots"
                      color={theme.palette.primary.main}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
  function ChangePassword() {
    const modal = useModalDialog();

    const ChangePasswordSetOpen = useMemo(
      () => modal(ChangePasswordComponent, 'changePassword'),
      [],
    );

    return (
      <p
        onClick={() => {
          ChangePasswordSetOpen(true);
        }}
      >
        {t('signUp.Change Password')}
      </p>
    );
  }
  return ChangePassword();
}
export default ChangePasswordWrapper;
