/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import _ from 'lodash';
import { ObjectIdType } from 'src/Types/ObjectIdType';
import { UUIDType } from 'src/Types/UUIDTYpe';
import { FormFieldEditType } from 'src/Types/FormFieldEditType';
import { listSubmittedFormsByOfParticipant } from '../../../Services/Auth/Actions/EventActions';
// eslint-disable-next-line import/no-cycle
import { FormList, GroupedDataArray } from './EventFormsListing';

// find the particluar item return it
export const checkFormFieldExistence = (array, index) => {
  try {
    if (Array.isArray(array)) {
      const data = array.find((item) => {
        if (item.formFieldId === index) return item;
      });
      return data;
    }
    console.log('Not An Array');
  } catch (error) {
    console.log('something went wrong at checkFormFieldExistence', error);
  }
};

// find data and return particular item form the array
// eslint-disable-next-line consistent-return
export const findFormFieldValues = (array, index) => {
  try {
    if (Array.isArray(array)) {
      const data = array.find((item) => {
        if (item?.formFieldId === index) {
          return item;
        }
      });
      return data;
    }
    console.log('Not An Array');
  } catch (error) {
    console.log('something went wrong at findFormFieldValues', error);
  }
};

// find the submitted forms
// eslint-disable-next-line consistent-return
export const checkSubmittedForms = async (userId) => {
  try {
    const { data } = await listSubmittedFormsByOfParticipant(userId);
    return data.data;
  } catch (error) {
    console.log('Something went wrong');
  }
};

// converts to lowercase, and removes any spaces
export const lowercaseValue = (values) => {
  const lowercase = values.toLowerCase().replace(/ +/g, '');
  return lowercase;
};

// It return a string that we can assign to the className prop
export const joinClassNames = (...classNames) => classNames.filter(Boolean).join(' ');

// Helper function to group data by 'rallyId'
export function groupDataByRallyId(
  data: FormList[],
  rallyId: string | null = null,
): GroupedDataArray[] {
  if (rallyId === null) {
    const groupedData = _.groupBy(data, 'rallyId');
    const groupedDataArray = Object.entries(groupedData);
    return groupedDataArray;
  }
  const filteredData = data.filter((item) => item.rallyId === rallyId);
  return Object.entries(_.groupBy(filteredData, 'rallyId'));
}

export type UserFormFeild = {
  values: string | Array<string>;
  fileKey?: File;
  formFieldId: UUIDType;
  type: String;
  contentType?: String;
  contentName?: String;
  id: ObjectIdType;
};

type Coordinates = {
  x: number;
  w: number;
  y: number;
  h: number;
};
export type FormFieldsLayout = FormFieldEditType & {
  order: number;
  coordinates: Coordinates;
  coords?: Coordinates;
  borderBottom: boolean;
  borderLeft: boolean;
  borderRight: boolean;
  borderTop: boolean;
  x: number;
  w: number;
  y: number;
  h: number;
};

export type ItemType = {
  formData: UserFormFeild[];
  form: FormFieldsLayout[];
  isSubmitted?: boolean;
  formId: string | any;
  eventId: string;
  _id: ObjectIdType;
  formName: string;
};
