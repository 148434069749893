import React, { useState } from 'react';
import { OutlinedInput } from '@material-ui/core';
import IconButton from './IconButton';
import DoubleClickButton from './DoubleClickButton';

type EditableTextBoxType = {
  label: string;
  onDoubleClick: Function;
  onSaveChange: Function;
  disabled?: boolean;
};
type ChangeEventType = React.ChangeEvent<HTMLInputElement>;

function EditableTextBox({
  label,
  onDoubleClick,
  onSaveChange,
  disabled = false,
}: EditableTextBoxType) {
  const [value, setValue] = useState<string>(label);
  const [edit, setEdit] = useState<boolean>(false);

  const onButtonDoubleClick = () => {
    setValue(label);
    setEdit(true);
    onDoubleClick();
  };
  const onValueChange = (event: ChangeEventType) => {
    setValue(event.target.value);
  };
  const onClickSaveChange = () => {
    if (value && value.length > 0) {
      setEdit(false);
      onSaveChange(value);
    }
    setEdit(false);
  };

  return (
    <div>
      {edit ? (
        <OutlinedInput
          type="text"
          name="fileName"
          value={value}
          onChange={(event: ChangeEventType) => onValueChange(event)}
          startAdornment={
            <IconButton
              icon="mdi:tick"
              onClick={() => onClickSaveChange()}
              colour="green"
            />
          }
          endAdornment={
            <IconButton icon="mdi:close" onClick={() => setEdit(false)} colour="red" />
          }
          notched
          autoFocus
        />
      ) : (
        <DoubleClickButton
          label={label}
          onDoubleClick={onButtonDoubleClick}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default EditableTextBox;
