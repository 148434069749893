import { createContext } from 'react';

type ParticipantFormPreviewContextType = {
  onHandleChange: any;
  findTextFields: any;
  findTextFieldsGeneric: any;
  onImageChange: any;
  showIcon: any;
  findDropDownData: any;
  handleDropDownChange: any;
  checkBoxValue: any;
  onHandleChangeCheckbox: any;
  onHandleChangeDate: any;
  getValue: any;
  rallyFormFieldLayout: any;
  userFormFeild: any;
  errors: any;
  isPreviewDisabled: boolean;
};

const ParticipantFormPreviewContext = createContext(
  {} as ParticipantFormPreviewContextType,
);

export default ParticipantFormPreviewContext;
