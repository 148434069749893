import React, { useState } from 'react';
import useStyles from './EventViewStyle';

function ToggleText({ details }: { details: any }) {
  const classes = useStyles();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div>
      {details ? (
        <p className={`${classes.p} ${classes.memoSect}`}>
          {isReadMore ? details.slice(0, 350) : details}
          {details?.length > 350 && (
            <span onClick={toggleReadMore} style={{ cursor: 'pointer' }}>
              {isReadMore ? '...read more' : ' ...show less'}
            </span>
          )}
        </p>
      ) : null}
    </div>
  );
}

export default ToggleText;
