/* eslint-disable import/order */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useContext } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import clsx from 'clsx';
import { renderFields } from 'src/Libs/DynamicForm/config/renderDynamicFormFields';
import { Button, FormStrokedButton } from '../../../Components/Utils';
import theme from '../../../Theme';

// react grid layout for dragging and resizing
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import FormHeader from '../../../Components/FormHeader/FormHeader';
import { formLayout } from './utils';
import {
  GRID_LAYOUT_COLS,
  GRID_LAYOUT_ROWHEIGHT,
  GRID_LAYOUT_WIDTH,
  GRID_UNIT_WIDTH,
  fieldWrapperExtraStyles,
} from './components/DynamicForm/util';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PreviewContext from 'src/Libs/DynamicForm/context/PreviewContext';
import { filterAddedFieldsByParentWidgetIndex } from 'src/Components/DynamicTable/utils';

function EventFormPreviewContent() {
  const useStyles = makeStyles({
    forms: {
      maxWidth: formLayout.width,
      background: '#FFFFFF',
      height: '100%',
      borderRadius: '18px',
      overflow: 'scroll',
      overflowX: 'hidden',
      '@media all and (max-width: 650px)': {
        marginTop: '10px',
        marginBottom: '20px',
        width: '300px',
      },
    },
    inputlabel: {
      marginLeft: '0px',
      cursor: 'pointer',
      width: '100%',
      lineHeight: 'normal',
    },
    subTitle: {
      overflow: 'hidden',
      width: '28rem',
      textOverflow: 'ellipsis',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid transparent',
      borderRadius: '17px',
      width: formLayout.width,
      height: '500px',
      position: 'fixed',
      top: '130px',

      '@media all and (max-width: 1200px)': {
        width: formLayout.width,
        height: '512px',
        marginTop: '50px',
      },
      '@media all and (max-width: 650px)': {
        width: formLayout.width,
      },
      overflow: 'auto',
      overflowX: 'hidden',
    },
    desc: {
      position: 'fixed',
      top: '10px',
      display: 'flex',
      alignItems: 'center',
      background: '#FFFFFF',
      padding: '20px',
      borderRadius: '18px',
      width: '100%',
      maxWidth: formLayout.width,
      justifyContent: 'space-between',
      '@media all and (max-width: 650px)': {
        flexDirection: 'column',
      },
    },
    submitCloseContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const {
    formData,
    eventDetails,
    formHeaderDetail,
    addedPreview,
    pdfLoad,
    downloadPDF,
    editClick,
  } = useContext(PreviewContext);
  /**
   * Filters an array of form fields, removing those with a parentWidgetIndex.
   * @returns {FormFieldEditType[]} - The filtered array containing only fields without a parent widget index.
   * @author Azhar
   * removing table inner fields
   */

  const forms = () => (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <Form>
        <ResponsiveGridLayout
          className="layout"
          rowHeight={GRID_LAYOUT_ROWHEIGHT}
          margin={[0, 0]}
          containerPadding={[0, 0]}
          width={GRID_UNIT_WIDTH}
          breakpoints={{ md: GRID_LAYOUT_WIDTH }}
          cols={{ md: GRID_LAYOUT_COLS }}
          isDraggable={false}
          isResizable={false}
          verticalCompact={false}
          allowOverlap
        >
          {filterAddedFieldsByParentWidgetIndex(addedPreview).map((data: any) => (
            <div
              data-grid={{
                x: data.coordinates.x,
                y: data.coordinates.y,
                w: data.coordinates.w,
                h: data.coordinates.h,
                minW: 2,
                minH: 1,
                isBounded: false,
              }}
              key={data.index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: '100%',
                ...fieldWrapperExtraStyles(data.styles, data.type),
              }}
            >
              <Grid
                item
                className={clsx([
                  `grid-item-${data.type}-custom-class ${classes.inputlabel}`,
                ])}
              >
                {renderFields(data, 'readOnlyPreview')}
              </Grid>
            </div>
          ))}
        </ResponsiveGridLayout>
      </Form>
    </Formik>
  );

  return (
    <div className={classes.paper}>
      <div className={classes.desc}>
        <h3 className={classes.subTitle}>{formData?.formName || 'Form name'}</h3>
        <div className={classes.submitCloseContainer}>
          {pdfLoad ? (
            <Loader
              type="ThreeDots"
              color={theme.palette.primary.main}
              height={50}
              width={50}
            />
          ) : (
            <Button name={t('events.Download PDF')} onClick={downloadPDF} />
          )}
          <FormStrokedButton onClick={editClick} name={t('forms.Edit')} className="" />
        </div>
      </div>
      {formHeaderDetail && (
        <FormHeader
          eventDetails={eventDetails}
          formHeaderDetails={formHeaderDetail}
          formName={formData.formName}
          openHeaderModal={() => {}}
          isEditable={false}
        />
      )}
      <div className={classes.forms}>{forms()}</div>
    </div>
  );
}
export default EventFormPreviewContent;
