import React from 'react';

function ShowImage({ imageUrl, styles }) {
  return (
    <img
      src={imageUrl}
      style={{
        width: '100%',
        height: '100%',
        objectFit: styles?.objectFit,
      }}
      alt="image"
    />
  );
}

export default ShowImage;
