/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-constructed-context-values */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import PreviewContext from 'src/Libs/DynamicForm/context/PreviewContext';
import {
  downloadPDFDoc,
  getEvent,
  getSingleFormByEventId,
} from 'src/Services/Auth/Actions/EventActions';

function PreviewWrapper({
  children,
  eventId,
  formId,
  rallyIds,
  setOpenPopup,
}: {
  children: React.ReactNode;
  formId: String;
  eventId: String;
  rallyIds?: String;
  setOpenPopup: any;
}): JSX.Element {
  const [addedPreview, setAddedPreview] = React.useState([]);
  const [formData, setFormData] = React.useState<any>({});
  const [eventDetails, setEventDetails] = useState([]);
  const [formHeaderDetail, setFormHeaderDetails] = useState({});
  const [pdfLoad, setPdfLoad] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getSingleFormByEventId(eventId, formId)
      .then((response) => {
        const { formHeaderDetails, formFields, coordinates } = response.data;

        // Create a map for quicker coordinate lookups
        const coordinateMap = new Map(coordinates.map((coord) => [coord.i, coord]));

        // Use map and filter to process the formFields
        const newArray2 = formFields.map((cv) => {
          const coordinate = coordinateMap.get(cv.index);
          if (coordinate) {
            cv.coordinates = coordinate;
          }
          return cv;
        });

        // Sort the newArray2 by the 'order' property
        newArray2.sort((a, b) => a.order - b.order);

        // // Update the index property
        // newArray2.forEach((val, index) => {
        //   val.index = index;
        // });

        // Set the state variables
        setFormHeaderDetails(formHeaderDetails);
        setFormData(response.data);
        setAddedPreview(newArray2);
      })
      .catch((err) => {
        console.error(err); // Use console.error for errors
      });

    getEvent(eventId)
      .then((response) => {
        setEventDetails(response.data.event);
      })
      .catch((e) => {
        // @ts-ignore
        console.log(e);
      });
  }, []);
  const downloadPDF = () => {
    setPdfLoad(true);
    downloadPDFDoc(eventId, formId)
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formData.formName}.pdf`);
        document.body.appendChild(link);
        link.click();
        setPdfLoad(false);
      })
      .catch((err) => {
        setPdfLoad(false);
        toast.error(t('General settings.Pdf download failed'), {
          position: 'top-center',
          autoClose: 5000,
        });
      });
  };

  const editClick = () => {
    setOpenPopup(false);
    history.push({
      pathname: `/events/forms/editForm/${eventId}/${formId}`,
      state: { rallyID: rallyIds },
    });
  };
  return (
    <PreviewContext.Provider
      value={{
        addedPreview,
        formData,
        eventDetails,
        formHeaderDetail,
        editClick,
        downloadPDF,
        pdfLoad,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
}

export default PreviewWrapper;
