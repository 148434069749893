import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './Services/Auth/Reducers/rootReducer';
import UserType from './Types/UserType';
import { DocField } from './Components/DocDownload/types/docType';
import EnumPermissions from './Types/Enums/EnumPermissions';
import EnumUserRoles from './Types/Enums/EnumUserRoles';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'role', 'table', 'rowsPerPage', 'language'], // only these will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {
  session: {
    token: '',
  },
  role: {
    role: '',
    user: {
      organizationId: '',
    },
    userPermissions: {
      all: false,
      permission: [''],
    },
  },
  language: {
    name: 'english',
    value: 'en',
  },
  rowsPerPage: {
    event: 10,
    rally: 10,
    rallyParticipant: 10,
    eventParticipant: 10,
    organizer: 10,
    newOrganizer: 10,
    newParticipant: 10,
    participant: 10,
    formField: 10,
    orgEvent: 10,
  },
  table: {
    event: { sort: 'desc', sortBy: 'createdAt' },
    rallyParticipant: { sort: 'desc', sortBy: 'createdAt' },
    eventParticipant: { sort: 'desc', sortBy: 'createdAt' },
    organizer: { sort: 'desc', sortBy: 'createdAt' },
    newOrganizer: { sort: 'desc', sortBy: 'createdAt' },
    newParticipant: { sort: 'desc', sortBy: 'createdAt' },
    participant: { sort: 'desc', sortBy: 'createdAt' },
    formField: { sort: 'asc', sortBy: 'label' },
    orgEvent: { sort: 'desc', sortBy: 'createdAt' },
    tablereports: { sort: 'DESC', sortBy: 'createdAt' },
    EventExporter: {
      sort: 'desc',
      sortBy: 'createdAt',
      exportDocType: '',
      sourceColumn: [],
      destinationColumn: [],
    },
    OrganizerExporter: {
      sort: 'desc',
      sortBy: 'createdAt',
      exportDocType: '',
      sourceColumn: [],
      destinationColumn: [],
    },
    RallyExporter: {
      sort: 'desc',
      sortBy: 'createdAt',
      exportDocType: '',
      sourceColumn: [],
      destinationColumn: [],
    },
    eventParticipantsExporter: {
      sort: 'desc',
      sortBy: 'createdAt',
      exportDocType: '',
      formId: [],
      sourceColumn: [],
      destinationColumn: [],
    },
    rallyParticipantsExporter: {
      sort: 'desc',
      sortBy: 'createdAt',
      exportDocType: '',
      sourceColumn: [],
      destinationColumn: [],
    },
  },
};
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk];
// const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)) )

const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);
const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = {
  session: {
    token: string;
  };
  user: UserType;
  role: {
    role: EnumUserRoles;
    user: UserType;
    userPermissions: {
      all: Boolean;
      permission: Array<EnumPermissions>;
    };
  };
  language: {
    value: string;
  };

  table: {
    participant: {
      sort: string;
      sortBy: string;
    };
    newOrganizer: {
      sort: string;
      sortBy: string;
    };
    newParticipant: {
      sort: string;
      sortBy: string;
    };
    orgEvent: {
      sort: string;
      sortBy: string;
    };
    organizer: {
      sort: string;
      sortBy: string;
    };
    formField: {
      sort: string;
      sortBy: string;
    };
    rallyParticipant: {
      sort: string;
      sortBy: string;
    };
    event: {
      sort: string;
      sortBy: string;
    };
    tablereports: {
      _order: string;
      _sort: string;
    };
    eventParticipant: {
      sort: string;
      sortBy: string;
    };
    EventExporter: {
      sort: string;
      sortBy: string;
      exportDocType: string;
      sourceColumn: DocField[];
      destinationColumn: DocField[];
    };
    OrganizerExporter: {
      sort: string;
      sortBy: string;
      exportDocType: string;
      sourceColumn: DocField[];
      destinationColumn: DocField[];
    };
    RallyExporter: {
      sort: string;
      sortBy: string;
      exportDocType: string;
      sourceColumn: DocField[];
      destinationColumn: DocField[];
    };
    eventParticipantsExporter: {
      sort: string;
      sortBy: string;
      exportDocType: string;
      formId: string[];
      sourceColumn: DocField[];
      destinationColumn: DocField[];
    };
    rallyParticipantsExporter: {
      sort: string;
      sortBy: string;
      exportDocType: string;
      sourceColumn: DocField[];
      destinationColumn: DocField[];
    };
  };
  rowsPerPage: {
    participant: number;
    newOrganizer: number;
    newParticipant: number;
    orgEvent: number;
    organizer: number;
    formField: number;
    rallyParticipant: number;
    rally: number;
    event: number;
    eventParticipant: number;
  };
} & { dispatch: AppDispatch }; // ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

export default { store, persistor };
