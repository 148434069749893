import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'src/Services/Utils';
import { FormStrokedButton } from 'src/Components/Utils';
import { orientationOpt, sortOrderOpt, typeOpt } from '../../util';
import AppDropDown from './AppDropDown';
import useStyles from '../../style';
import { DragAndDropFormProps } from '../../types/docType';
import TableReportWrapper from './SaveTableReports';

function DragAndDropForm({
  dropDownValues,
  destinationColumnData,
  isLoading,
  onSubmit,
  setDropDownValues,
  formListing,
  components,
  onReportSubmit,
  sourceColumnData,
  id,
  reportName,
  updateReportSubmit,
  reload,
}: DragAndDropFormProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.head}>
      <form>
        <div className={classes.formStyle}>
          <AppDropDown
            placeholder={t('other.Sort By')}
            value={dropDownValues.sortBy}
            options={destinationColumnData}
            onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
              setDropDownValues({
                ...dropDownValues,
                sortBy: e.currentTarget.value,
              });
            }}
          />
          <AppDropDown
            placeholder={t('other.Sort Order')}
            value={dropDownValues.sortOrder}
            options={sortOrderOpt}
            onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
              setDropDownValues({
                ...dropDownValues,
                sortOrder: e.currentTarget.value,
              });
            }}
            translation
          />
          <AppDropDown
            placeholder={t('other.Download Type')}
            value={dropDownValues.type}
            options={typeOpt}
            onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
              setDropDownValues({
                ...dropDownValues,
                type: e.currentTarget.value,
              });
            }}
          />
          {formListing.length > 0 && components.map((component) => component)}
          {dropDownValues.type === 'pdf' && (
            <AppDropDown
              placeholder={t('other.orientation')}
              value={dropDownValues.orientation}
              options={orientationOpt}
              onChange={(e: SyntheticEvent<HTMLSelectElement>) => {
                setDropDownValues({
                  ...dropDownValues,
                  orientation: e.currentTarget.value,
                });
              }}
            />
          )}
        </div>
        <div className={classes.actions}>
          <Loader isLoading={isLoading} type="ThreeDots">
            <FormStrokedButton
              className={
                destinationColumnData.length > 0 ? classes.button : classes.disabledBtn
              }
              name={t('events.Download')}
              onClick={(e: any) => onSubmit(e)}
            />
          </Loader>
          <TableReportWrapper
            onReportSubmit={onReportSubmit}
            destinationColumnData={destinationColumnData}
            sourceColumnData={sourceColumnData}
            id={id}
            updateReportSubmit={updateReportSubmit}
            tablereportName={reportName}
            reload={reload}
            dropDownValues={dropDownValues}
          />
        </div>
      </form>
    </div>
  );
}

export default DragAndDropForm;
